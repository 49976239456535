import React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { LegalPhaseRow } from './LegalPhaseRow';

import { ILegalPhaseTemplateFullObject } from '../../../../Interfaces/ILegalPhaseTemplate';

interface IProps {
  legalPhaseTemplateArray: ILegalPhaseTemplateFullObject[];
  choosenPhases: ILegalPhaseTemplateFullObject[];
  setChoosenPhases: Function;
}

export const LegalPhaseTable: React.FC<IProps> = props => {
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Bezeichnung</TableCell>
            <TableCell>Vorgänger</TableCell>
            <TableCell></TableCell>
            <TableCell>Upload Mandanten</TableCell>
            <TableCell>Upload Mitarbeiter</TableCell>
            <TableCell>
              Auto. <span style={{ whiteSpace: 'nowrap' }}>E-Mail</span>
            </TableCell>
            <TableCell>Aktionen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.legalPhaseTemplateArray
            .sort((a, b) => (a.Position < b.Position ? -1 : 1))
            .map(x => (
              <LegalPhaseRow
                key={`idLegalPhaseTemplate-${x.idLegalPhaseTemplate}`}
                currentPhase={x}
                legalPhaseTemplateArray={props.legalPhaseTemplateArray}
                choosenPhases={props.choosenPhases}
                setChoosenPhases={props.setChoosenPhases}
              />
            ))}
        </TableBody>
      </Table>
    </>
  );
};
