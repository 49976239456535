import React, { useEffect, useState } from 'react';
import { IDocument } from '../../Interfaces/IDocument';
import {
  Grid,
  IconButton,
  Typography,
  Box,
  Card,
  Button,
  Switch,
  FormControl,
  FormControlLabel,
  useTheme,
  Alert,
  Dialog,
  DialogContent,
  CircularProgress,
} from '@mui/material';
import { FullScreenDialog } from '../../core/FullScreenDialog';
import { getFetch, uploadFetch } from '../../hooks/useFetch';
import { CustomCircularProgress } from '../../generic/CustomCircularProgress';
import { IDocumentAnalysisSubsectionEntryFullObject } from '../../Interfaces/IDocumentAnalysisSubsectionEntry';
import {
  ArrowBackIos,
  ArrowForwardIos,
  ZoomIn,
  ZoomOut,
} from '@mui/icons-material';
import {
  useDocumentAnalysisAttributes,
  useDocumentAnalysisSections,
  useDocumentAnalysisSubsections,
  useDocumentAnalysisTypes,
} from '../../hooks/data';
import { PdfDocument } from './PdfDocument';
import { DocumentAnalysisMain } from '../DocumentAnalysis/DocumentAnalysisMain';
import { DocumentAssistentCell } from './DocumentAssistentCell';
import {
  CellObject,
  DocumentAssistantProvider,
} from './DocumentAssistantContext';
interface IProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  documentObject: IDocument;
  setDocumentObject: (document: IDocument) => void;
}

export interface OcrDictionary {
  [key: number]: string[][];
}

export interface BoolDictionary {
  [key: number]: boolean[][];
}

interface ILayoutProps {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export const Layout = ({ children, isOpen, setIsOpen }: ILayoutProps) => {
  return (
    <FullScreenDialog
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title='Dokumentenassistent'
    >
      <Box sx={{ overflow: 'hidden', maxHeight: '90vh' }}>{children}</Box>
    </FullScreenDialog>
  );
};

export const DocumentAssistentMain = ({
  isOpen,
  setIsOpen,
  documentObject,
  setDocumentObject,
}: IProps) => {
  const theme = useTheme();
  const { documentAnalysisTypes, isLoadingDocumentAnalysisTypes } =
    useDocumentAnalysisTypes();

  const { documentAnalysisSections, isLoadingDocumentAnalysisSections } =
    useDocumentAnalysisSections();

  const { documentAnalysisSubsections, isLoadingDocumentAnalysisSubsections } =
    useDocumentAnalysisSubsections();

  const { documentAnalysisAttributes, isLoadingDocumentAnalysisAttributes } =
    useDocumentAnalysisAttributes();

  const [resultDict, setResultDict] = useState<OcrDictionary | null>(null);
  const [wasSuccessfullyUpload, setWasSuccessfullyUpload] = useState(true);
  const [selectDict, setSelectDict] = useState<BoolDictionary | null>(null);
  const [sectionEntries, setSectionEntries] = useState<
    IDocumentAnalysisSubsectionEntryFullObject[]
  >([]);

  const [showLandRegistry, setShowLandRegistry] = useState(true);

  const [numPages, setNumPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [scale, setScale] = useState<number>(1.0);

  const [selectedCells, setSelectedCells] = useState<CellObject[]>([]);

  const [isOpenLoadExport, setIsOpenLoadExport] = useState(false);

  const handleSelectChange = (updatedCell: CellObject) => {
    setSelectedCells(prevCells => {
      if (prevCells.find(cell => cell.id === updatedCell.id)) {
        return prevCells.filter(cell => cell.id !== updatedCell.id);
      } else {
        return [...prevCells, updatedCell];
      }
    });
  };

  const handleDeselectAll = () => {
    setSelectedCells([]);
  };

  const castBlobToFile = (documentObject: IDocument | undefined) => {
    if (documentObject != undefined && documentObject.Data != undefined) {
      const currentType = 'application/xml';
      // Link -> https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
      // decode a Base64-encoded string into a new string with a character for each byte of the binary data.
      const byteCharacters = atob(documentObject?.Data);
      // Each character's code point (charCode) will be the value of the byte.
      // We can create an array of byte values by applying this using the .charCodeAt method for each character in the string.
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      //You can convert this array of byte values into a real typed byte array by passing it to the Uint8Array constructor.
      const byteArray = new Uint8Array(byteNumbers);
      // This in turn can be converted to a BLOB by wrapping it in an array and passing it to the Blob constructor.
      const blob = new Blob([byteArray], { type: currentType });
      // Erstelle Link zum BLOB
      const blobUrl = URL.createObjectURL(blob);
      // Erstelle html-Objekt für den Download
      const a = document.createElement('a');
      // setze link auf den neu erstellen Link zum Blolb
      a.href = blobUrl;
      // Setze Downloadtitle für den Datenname
      a.download = documentObject.FileName;
      // Click
      a.click();
    }
  };

  const handleExport = (res: IDocument) => {
    castBlobToFile(res);
  };

  const exportToXML = () => {
    uploadFetch(
      '/document/export_xml',
      true,
      sectionEntries,
      handleExport,
      () => {},
      () => {},
      setIsOpenLoadExport
      //setWasSuccessfullyUpload
    );
  };

  // ID for Grundbuch
  const documentAnalysisTypeId = 1;

  const zoomIn = () => setScale(prevScale => Math.min(prevScale + 0.25, 3.0));
  const zoomOut = () => setScale(prevScale => Math.max(prevScale - 0.25, 0.5));

  useEffect(() => {
    if (documentObject.Data) {
      uploadFetch(
        '/document/ocr',
        true,
        documentObject,
        setResultDict,
        setWasSuccessfullyUpload
      );
    } else {
      getFetch('/document/', documentObject.idDocument, setDocumentObject);
    }
  }, [documentObject.Data]);

  useEffect(() => {
    if (resultDict) {
      const tmpSelectDict: BoolDictionary = {};

      Object.keys(resultDict).map(currentPage => {
        const key = Number(currentPage);
        tmpSelectDict[key] = [];
        resultDict[key].map(currentRow => {
          const tmpRowSelectArray: boolean[] = [];

          currentRow.map(_ => tmpRowSelectArray.push(false));
          tmpSelectDict[key].push(tmpRowSelectArray);
        });
      });
      setSelectDict(tmpSelectDict);
    }
  }, [resultDict]);

  if (
    isLoadingDocumentAnalysisTypes ||
    isLoadingDocumentAnalysisSections ||
    isLoadingDocumentAnalysisSubsections ||
    isLoadingDocumentAnalysisAttributes
  ) {
    return (
      <Layout isOpen={isOpen} setIsOpen={setIsOpen}>
        <CustomCircularProgress />
      </Layout>
    );
  }

  if (
    !documentAnalysisTypes ||
    !documentAnalysisSections ||
    !documentAnalysisSubsections ||
    !documentAnalysisAttributes
  ) {
    return (
      <Layout isOpen={isOpen} setIsOpen={setIsOpen}>
        <Typography>
          Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.
        </Typography>
      </Layout>
    );
  }

  return (
    <>
      <Dialog open={isOpenLoadExport}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>

      <DocumentAssistantProvider
        selectedCells={selectedCells}
        sectionEntries={sectionEntries}
        documentAnalysisTypes={documentAnalysisTypes}
        documentAnalysisSections={documentAnalysisSections}
        documentAnalysisSubsections={documentAnalysisSubsections}
        documentAnalysisAttributes={documentAnalysisAttributes}
        setSelectedCells={setSelectedCells}
        clearSelectedCells={handleDeselectAll}
        setSectionEntries={setSectionEntries}
      >
        <Layout isOpen={isOpen} setIsOpen={setIsOpen}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  px: 2,
                  py: 1,
                }}
                variant='outlined'
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Typography variant='h5'>
                    {
                      documentAnalysisTypes.find(
                        documentAnalysisType =>
                          documentAnalysisType.idDocumentAnalysisType ===
                          documentAnalysisTypeId
                      )?.DocumentAnalysisType
                    }
                  </Typography>

                  <FormControl
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      background: theme.palette.grey[50],
                      borderRadius: 12,
                      px: 2,
                      py: 0.5,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showLandRegistry}
                          onChange={() =>
                            setShowLandRegistry(!showLandRegistry)
                          }
                        />
                      }
                      label='Dokument anzeigen'
                    />
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    onClick={() => setPageNumber(pageNumber - 1)}
                    disabled={pageNumber === 1}
                  >
                    <ArrowBackIos />
                  </IconButton>
                  <Typography>
                    Seite {pageNumber} von {numPages}
                  </Typography>

                  <IconButton
                    onClick={() => setPageNumber(pageNumber + 1)}
                    disabled={pageNumber === numPages}
                  >
                    <ArrowForwardIos />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 2,
                  }}
                >
                  <Button variant='contained' onClick={() => exportToXML()}>
                    XML-Export
                  </Button>
                </Box>
              </Card>
            </Grid>

            <Grid
              item
              xs={4}
              sx={{ display: showLandRegistry ? 'block' : 'none' }}
            >
              <Card variant='outlined' sx={{ height: '81vh' }}>
                {!documentObject.Data ? (
                  <CustomCircularProgress />
                ) : (
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 2,
                        alignItems: 'center',
                        p: 1,
                        borderBottom: '1px solid #e0e0e0',
                      }}
                    >
                      <IconButton onClick={zoomOut} disabled={scale === 0.5}>
                        <ZoomOut />
                      </IconButton>
                      <Typography variant='body1'>
                        {(scale * 100).toFixed(0)}%
                      </Typography>
                      <IconButton onClick={zoomIn} disabled={scale === 3.0}>
                        <ZoomIn />
                      </IconButton>
                    </Box>
                    <Box sx={{ height: '80vh', overflow: 'auto', p: 2 }}>
                      <PdfDocument
                        pdfFile={`data:application/pdf;base64,${documentObject.Data}`}
                        numPages={numPages}
                        setNumPages={setNumPages}
                        pageNumber={pageNumber}
                        scale={scale}
                      />
                    </Box>
                  </Box>
                )}
              </Card>
            </Grid>
            <Grid item xs={showLandRegistry ? 4 : 6}>
              <Card
                variant='outlined'
                sx={{ p: 2, overflowY: 'auto', height: '81vh' }}
              >
                {!wasSuccessfullyUpload ? (
                  <>FEHLER!</>
                ) : !resultDict || !selectDict ? (
                  <CustomCircularProgress />
                ) : resultDict[Number(pageNumber - 1)]?.length === 0 ? (
                  <Typography>
                    Für Seite {pageNumber} sind keine Ergebnisse vorhanden.
                  </Typography>
                ) : (
                  <Box>
                    <Alert severity='info'>
                      Um Textabschnitte zu übertragen, wählen Sie die
                      gewünschten Textabschnitte aus und klicken Sie dann auf
                      das Feld, in dass Sie die Daten übertragen möchten.
                    </Alert>
                    <Grid container spacing={2} mt={2} direction='column'>
                      {resultDict[Number(pageNumber - 1)].map(
                        (rowContent, outerIndex) => (
                          <Grid item key={outerIndex}>
                            <Grid
                              container
                              spacing={1}
                              columns={rowContent.length}
                            >
                              {rowContent.map((cellContent, innerIndex) => (
                                <DocumentAssistentCell
                                  key={`dw-cell-${outerIndex}-${innerIndex}`}
                                  id={`dw-cell-${outerIndex}-${innerIndex}`}
                                  selectDict={selectDict}
                                  setSelectDict={setSelectDict}
                                  currentCell={cellContent}
                                  onSelectChange={handleSelectChange}
                                  selectedCells={selectedCells}
                                />
                              ))}
                            </Grid>
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Box>
                )}
              </Card>
            </Grid>
            <Grid item xs={showLandRegistry ? 4 : 6}>
              <Card
                variant='outlined'
                sx={{ overflowY: 'auto', height: '81vh', p: 2 }}
              >
                <DocumentAnalysisMain />
              </Card>
            </Grid>
          </Grid>
        </Layout>
      </DocumentAssistantProvider>
    </>
  );
};
