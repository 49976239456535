import React, { useEffect, useState } from 'react';
import { IInheritFullObject } from '../../Interfaces/IInherit';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Typography,
} from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { IPersonHasDocument } from '../../Interfaces/IPersonHasDocument';
import { IInheritHasPerson } from '../../Interfaces/IPerson';
import { IMarriage } from '../../Interfaces/IMarriage';
import { IChildren } from '../../Interfaces/IChildren';
import { Inherit } from './Childs/Dialog/Inherit';
import { FamilyTree } from './Childs/Components/FamilyTree';
import { Parents } from './Childs/Dialog/Parents';
import { ExportPersons } from './Childs/Components/ExportPersons';
import { Strangers } from './Childs/Dialog/Strangers';
import { Add, ExpandMore } from '@mui/icons-material';
import { IDocument } from '../../Interfaces/IDocument';
import { AddParents } from './Childs/Dialog/AddParents';
import { MarriageOverview } from './Childs/Components/Marriage/MarriageOverview';
import {
  getAddButtonTitleOfDescendants,
  getAddButtonTitleOfSiblings,
  getDeathParentArray,
  getTitleOfDescendants,
  getTitleOfSiblings,
} from '../../services/dialog.services.ts/inheritDialog.service';
import { ChildrenDriver } from './Childs/Components/Children/ChildrenDriver';

interface IProps {
  inheritObject: IInheritFullObject;
  setInheritObject: Function;
}

export const InheritMain: React.FC<IProps> = ({
  inheritObject,
  setInheritObject,
}) => {
  const [familyTreeData, setFamilyTreeData] = useState<undefined | IDocument>(
    inheritObject.FamilyTreeData
  );
  const [inheritPersonArray, setInheritPersonArray] = useState<
    IInheritHasPerson[]
  >(inheritObject.PersonArray);
  const [inheritMarriageArray, setInheritMarriageArray] = useState<IMarriage[]>(
    inheritObject.MarriageArray
  );
  const [childrenArray, setChildrenArray] = useState<IChildren[]>(
    inheritObject.ChildrenArray
  );
  //
  const [personHasDocument, setPersonHasDocument] = useState<
    IPersonHasDocument[]
  >(inheritObject.PersonHasDocument);
  //
  const [openParentDialog, setOpenParentDialog] = useState(false);

  useEffect(() => {
    const updatedObject = {
      ...inheritObject,
      hasTestament: Boolean(inheritObject.hasTestament),
      ChildrenArray: childrenArray,
      MarriageArray: inheritMarriageArray,
      PersonArray: inheritPersonArray,
      PersonHasDocument: personHasDocument,
      FamilyTreeData: familyTreeData,
    } as IInheritFullObject;
    setInheritObject(updatedObject);
  }, [
    inheritPersonArray,
    personHasDocument,
    inheritMarriageArray,
    childrenArray,
    familyTreeData,
  ]);

  if (inheritObject.PersonArray[0] === undefined) {
    return (
      <Alert severity='error'>
        Fehler!
        <br />
        Erblasser konnte nicht bestimmt werden.
      </Alert>
    );
  } else {
    return (
      <>
        <Box sx={{ float: 'right', display: 'flex', gap: '0.5rem' }}>
          <ExportPersons inheritObject={inheritObject} />
        </Box>

        <FamilyTree
          personArray={inheritPersonArray}
          childrenArray={childrenArray}
          marriageArray={inheritMarriageArray}
          setPersonArray={setInheritPersonArray}
          setChildrenArray={setChildrenArray}
          setMarriageArray={setInheritMarriageArray}
          personHasDocument={personHasDocument}
          setPersonHasDocument={setPersonHasDocument}
          setFamilyTreeData={setFamilyTreeData}
          familyTreeData={familyTreeData}
        />

        <Accordion>
          <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
            <Typography>Erblasser</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Inherit
              inheritObject={inheritObject}
              setInheritObject={setInheritObject}
              personArray={inheritPersonArray}
              setPersonArray={setInheritPersonArray}
              personHasDocument={personHasDocument}
              setPersonHasDocument={setPersonHasDocument}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
            <Typography>Ehe(n)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MarriageOverview
              inheritObject={inheritObject}
              marriageArray={inheritMarriageArray}
              personArray={inheritPersonArray}
              personHasDocument={personHasDocument}
              setMarriageArray={setInheritMarriageArray}
              setPersonArray={setInheritPersonArray}
              setPersonHasDocument={setPersonHasDocument}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography>{'Nachkommen'}</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Typography variant='h5'>Auflistung der Nachkommen</Typography>
            <ChildrenDriver
              children={childrenArray.filter(
                children =>
                  children.Parent1_idPerson ===
                  inheritObject.PersonArray[0].idPerson
              )}
              parents={[]}
              inheritObject={inheritObject}
              idPersonInherit={inheritObject.PersonArray[0].idPerson}
              firstParentArray={[inheritObject.PersonArray[0]]}
              personArray={inheritPersonArray}
              setPersonArray={setInheritPersonArray}
              personHasDocument={personHasDocument}
              setPersonHasDocument={setPersonHasDocument}
              childrenArray={childrenArray}
              setChildrenArray={setChildrenArray}
              marraigeArray={inheritMarriageArray}
              getTitle={getTitleOfDescendants}
              getAddButtonTitle={getAddButtonTitleOfDescendants}
              title='Bitte erfassen Sie alle weiteren Nachkommen vom Erblasser'
            />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
            <Typography>Eltern</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant='h5'>Eltern des Erblassers</Typography>
            {openParentDialog && (
              <AddParents
                inheritObject={inheritObject}
                setIsOpenNew={setOpenParentDialog}
                personArray={inheritPersonArray}
                setPersonArray={setInheritPersonArray}
                childrenArray={childrenArray}
                setChildrenArray={setChildrenArray}
                personHasDocument={personHasDocument}
                setPersonHasDocument={setPersonHasDocument}
              />
            )}
            {childrenArray.find(
              x => x.Children_idPerson === inheritObject.PersonArray[0].idPerson
            ) !== undefined ? (
              <Parents
                inheritObject={inheritObject}
                personHasDocument={personHasDocument}
                setPersonHasDocument={setPersonHasDocument}
                personArray={inheritPersonArray}
                setPersonArray={setInheritPersonArray}
                childrenArray={childrenArray}
                setChildrenArray={setChildrenArray}
              />
            ) : (
              <>
                <Typography variant='body1' sx={{ mt: 2, mb: 3 }}>
                  Es sind keine Daten vorhanden.
                </Typography>
                <Button
                  variant='contained'
                  onClick={() => setOpenParentDialog(true)}
                  sx={{
                    float: 'left',
                    '& .MuiButton-startIcon': {
                      marginRight: '2px', // Adjust the margin-right here
                    },
                    marginBottom: '16px',
                  }}
                  startIcon={
                    <Add
                      sx={{
                        fontSize: 22,
                        position: 'relative',
                        bottom: 1,
                        marginRight: '0px',
                      }}
                    />
                  }
                >
                  Eltern
                </Button>
              </>
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<GridExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography>Nahe Verwandte</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Typography variant='h5'>
              Auflistung der nahen Verwandten
            </Typography>
            {childrenArray.find(
              x => x.Children_idPerson === inheritObject.PersonArray[0].idPerson
            ) !== undefined ? (
              <ChildrenDriver
                children={childrenArray.filter(
                  children =>
                    (children.Parent1_idPerson ===
                      childrenArray.find(
                        children =>
                          children.Children_idPerson ===
                          inheritObject.PersonArray[0].idPerson
                      )?.Parent1_idPerson ||
                      children.Parent1_idPerson ===
                        childrenArray.find(
                          children =>
                            children.Children_idPerson ===
                            inheritObject.PersonArray[0].idPerson
                        )?.Parent2_idPerson) &&
                    children.Children_idPerson !==
                      inheritObject.PersonArray[0].idPerson
                )}
                parents={childrenArray.filter(
                  children =>
                    children.Children_idPerson ===
                    inheritObject.PersonArray[0].idPerson
                )}
                inheritObject={inheritObject}
                idPersonInherit={inheritObject.PersonArray[0].idPerson}
                firstParentArray={getDeathParentArray(
                  inheritObject.PersonArray[0],
                  childrenArray,
                  inheritPersonArray
                )}
                personArray={inheritPersonArray}
                setPersonArray={setInheritPersonArray}
                personHasDocument={personHasDocument}
                setPersonHasDocument={setPersonHasDocument}
                childrenArray={childrenArray}
                setChildrenArray={setChildrenArray}
                marraigeArray={inheritMarriageArray}
                getTitle={getTitleOfSiblings}
                getAddButtonTitle={getAddButtonTitleOfSiblings}
                title='Bitte erfassen Sie alle weiteren Nachkommen von den Eltern des
        Erblassers'
              />
            ) : (
              <>
                <Typography variant='body1' sx={{ mt: 2, mb: 3 }}>
                  Fügen Sie die Eltern des Erblassers hinzu um die nahen
                  Verwandten anzugeben.
                </Typography>
              </>
            )}
          </AccordionDetails>
        </Accordion>

        {Boolean(inheritObject.hasTestament) && (
          <Accordion>
            <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
              <Typography>Sonstige Personen</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Strangers
                inheritObject={inheritObject}
                personArray={inheritPersonArray}
                setPersoArray={setInheritPersonArray}
                personHasDocument={personHasDocument}
                setPersonHasDocument={setInheritPersonArray}
              />
            </AccordionDetails>
          </Accordion>
        )}
      </>
    );
  }
};
