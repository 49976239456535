export const getIsEmail = (currentEmail: string | null | undefined) => {
  if (currentEmail === undefined || currentEmail === null) {
    return false;
  } else {
    const splitetByAd = currentEmail.split('@');

    if (splitetByAd.length !== 2) {
      return false;
    }

    const splitetByPoint = splitetByAd[1].split('.');

    return splitetByPoint.length === 2 && splitetByPoint[1].length > 0;
  }
};

export const checkIsUnderTen = (currentValue: number) => {
  if (currentValue < 10) {
    return `0${currentValue}`;
  } else {
    return `${currentValue}`;
  }
};

export const getStringdateFromDate = (dateObject: Date) => {
  const y = dateObject.getFullYear();
  const m = dateObject.getMonth() + 1;
  const d = dateObject.getDate();
  return `${y}-${checkIsUnderTen(m)}-${checkIsUnderTen(d)}`;
};

export const castWithZeroTimezone = (targetValue: string | null) => {
  if (targetValue === null) {
    return null;
  } else {
    if (targetValue.includes('Z')) {
      return new Date(targetValue);
    } else {
      return new Date(targetValue.replace(' ', 'T') + 'Z');
    }

    //return new Date(`${dateSting}T${targetValue}Z`)
  }
};

export const getZeroTimezoneStringFromDate = (dataObject: Date) => {
  if (isNaN(dataObject.getTime())) {
    return null;
  }

  //let hours = dataObject.getUTCHours();
  //let minutes = dataObject.getMinutes();

  const isoString = dataObject.toISOString();

  const returnString = isoString.replace('T', ' ').split('.')[0];

  return `${returnString}`;

  /*
    let splittedArray = dataObject.toISOString().split("T")

    if (splittedArray.length !== 2) {
        console.warn(`Can't cast Date ${dataObject} to string`);
        return null;
    } else {
        let minutes = dataObject.getUTCHours();
        return splittedArray[1].split(".")[0]
    }
    */
};

export const getHfromMinutes = (diffMin: number) => {
  const minutes = diffMin % 60;
  const hours = (diffMin - minutes) / 60;

  return `${checkIsUnderTen(hours)}:${checkIsUnderTen(minutes)} Std.`;
};

export const compageDateFirstOneSmallerEqual = (
  dateOne: Date,
  dateTwo: Date
) => {
  const newDateOne = new Date(dateOne);
  const newDateTwo = new Date(dateTwo);
  //
  newDateOne.setHours(0, 0, 0, 0);
  newDateTwo.setHours(0, 0, 0, 0);

  return newDateOne <= newDateTwo;
};
