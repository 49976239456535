import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material';
import { IRealEstate } from '../../../../Interfaces/IRealEstate';
import { IRealEstateHasPropertyType } from '../../../../Interfaces/IRealEstateHasPropertyType';
import { IRealEstateHasInventoryOption } from '../../../../Interfaces/IRealEstateHasInventoryOption';
import { IPropertyType } from '../../../../Interfaces/IPropertyType';
import { IInventoryOption } from '../../../../Interfaces/IInventoryOption';
import { checkString } from '../../../../core/standardFunctions';
import { IPurchaseContractRelationType } from '../../../../Interfaces/IPurchaseContractRelationType';
import { PurchaseContractRealEstate } from '../../Dialog/PurchaseContractRealEstate';
import { IRealEstateUseType } from '../../../../Interfaces/IRealEstateUseType';
import { IPurchaseContractHasPerson } from '../../../../Interfaces/IPerson';

interface IProps {
  realEstateArray: IRealEstate[];
  setRealEstateArray: Function;
  realEstateHasPropertyTypeArray: IRealEstateHasPropertyType[];
  setRealEstateHasPropertyTypeArray: Function;
  realEstateHasInventroyOptionArray: IRealEstateHasInventoryOption[];
  setRealEstateHasInventroyOptionArray: Function;
  propertyTypeArray: IPropertyType[];
  inventoryOptionArray: IInventoryOption[];
  purchaseContractRelationTypeArray: IPurchaseContractRelationType[];
  realEstateUseTypeArray: IRealEstateUseType[];
  personArray: IPurchaseContractHasPerson[];
  setPersonArray: Function;
}

export const RealEstateSummary: React.FC<IProps> = props => {
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const getHasPropertyType = (idRealEstate: number) => {
    const filteresId = props.realEstateHasPropertyTypeArray
      .filter(x => x.idRealEstate === idRealEstate)
      .map(x => x.idPropertyType);
    return (
      <>
        {props.propertyTypeArray
          .filter(x => filteresId.indexOf(x.idPropertyType) > -1)
          .map(x => x.PropertyType)
          .join(', ')}
      </>
    );
  };

  const getRelationName = (idPurchaseRealtionType: number | null) => {
    const foundedObject = props.purchaseContractRelationTypeArray.find(
      x => x.idPurchaseContractRelationType === Number(idPurchaseRealtionType)
    );

    if (foundedObject === undefined) {
      return 'k.A.';
    } else {
      return foundedObject.PurchaseContractRelationType;
    }
  };

  const getPropertyTsx = (idRealEstate: number) => {
    const filteredElements = props.realEstateHasInventroyOptionArray.filter(
      x => x.idRealEstate === idRealEstate
    );

    return (
      <>
        {filteredElements.map(x => (
          <>
            <Grid item sm={2}></Grid>
            <Grid item sm={2}>
              {x.Amount}
            </Grid>
            <Grid item sm={4}>
              {x.Title}
            </Grid>
            <Grid item sm={4}>
              {' '}
              {checkString(x.Description)}{' '}
            </Grid>
          </>
        ))}
      </>
    );
  };

  return (
    <>
      <Dialog
        open={isOpenEdit}
        onClose={() => setIsOpenEdit(false)}
        maxWidth='lg'
        fullWidth
      >
        <DialogContent>
          <PurchaseContractRealEstate
            realEstateArray={props.realEstateArray}
            setRealEstateArray={props.setRealEstateArray}
            realEstateHasPropertyTypeArray={
              props.realEstateHasPropertyTypeArray
            }
            setRealEstateHasPropertyTypeArray={
              props.setRealEstateHasPropertyTypeArray
            }
            propertyTypeArray={props.propertyTypeArray}
            realEstateHasInventroyOptionArray={
              props.realEstateHasInventroyOptionArray
            }
            setRealEstateHasInventroyOptionArray={
              props.setRealEstateHasInventroyOptionArray
            }
            inventoryOptionArray={props.inventoryOptionArray}
            realEstateUseTypeArray={props.realEstateUseTypeArray}
            personArray={props.personArray}
            setPersonArray={props.setPersonArray}
            purchaseContractRelationTypeArray={
              props.purchaseContractRelationTypeArray
            }
          />
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => setIsOpenEdit(false)}>
            Schließen
          </Button>
        </DialogActions>
      </Dialog>

      <Typography variant='h6'>
        Zusammenfassung
        <Button
          onClick={() => setIsOpenEdit(true)}
          variant='outlined'
          sx={{ float: 'right' }}
        >
          Editieren
        </Button>
      </Typography>

      <Grid container spacing={2} sx={{ ml: 5, mt: 2 }}>
        {props.realEstateArray.map((x, i) => (
          <>
            {i !== 0 && (
              <Grid item sm={12}>
                <hr />
              </Grid>
            )}

            <Grid item sm={4}>
              <b>Nr.:</b>
            </Grid>
            <Grid item sm={8}>
              {' '}
              {i + 1}
            </Grid>

            <Grid item sm={4}>
              <b>Preis:</b>
            </Grid>
            <Grid item sm={8}>
              {x.Price !== null && x.Price !== undefined
                  ? x.Price.toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                  })
                  : 'k.A.'}
            </Grid>

            {props.realEstateHasInventroyOptionArray.filter(
              y => y.idRealEstate === x.idRealEstate
            ).length > 0 && (
              <>
                <Grid item sm={4}>
                  <b>Preis Anlage:</b>
                </Grid>
                <Grid item sm={8}>
                  {x.PriceInventoryOption === null ? (
                    <>0,00 €</>
                  ) : (
                    x.PriceInventoryOption.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                    })
                  )}
                </Grid>
              </>
            )}

            <Grid item sm={4}>
              <b>Adresse:</b>
            </Grid>
            <Grid item sm={8}>
              {checkString(x.Street)} {checkString(x.StreetNr)}
            </Grid>
            <Grid item sm={4}></Grid>
            <Grid item sm={8}>
              {checkString(x.Postcode)} {checkString(x.City)}
            </Grid>

            <Grid item sm={4}>
              <b>Wohn- oder Teileigentum:</b>
            </Grid>
            <Grid item sm={8}>
              {x.hasApartment ? 'Ja' : 'Nein'}
            </Grid>

            <Grid item sm={4}>
              <b>Grundbuch und Blatt:</b>
            </Grid>
            <Grid item sm={8}>
              {' '}
              {checkString(x.LandRegister)} / {checkString(x.LandRegister)}
            </Grid>

            <Grid item sm={4}>
              <b>Flurstück und Flur</b>
            </Grid>
            <Grid item sm={8}>
              {' '}
              {checkString(x.LandParcel)} / {checkString(x.Parcel)}
            </Grid>

            <Grid item sm={4}>
              <b>Grundstücksform(en):</b>
            </Grid>
            <Grid item sm={8}>
              {' '}
              {getHasPropertyType(x.idRealEstate)}{' '}
            </Grid>

            {(x.isBuild ||
              props.realEstateHasPropertyTypeArray
                .filter(y => y.idPropertyType === 1)
                .filter(y => y.idRealEstate === x.idRealEstate).length > 0) && (
              <>
                <Grid item sm={4}>
                  <b>Bauten:</b>
                </Grid>
                <Grid item sm={8}>
                  {' '}
                  {checkString(x.BuildWith)}{' '}
                </Grid>
              </>
            )}

            <Grid item sm={4}>
              <b>Teilfläche:</b>
            </Grid>
            <Grid item sm={8}>
              {x.hasPartialArea ? 'Ja' : 'Nein'}
            </Grid>

            {Boolean(x.hasPartialArea) && (
              <>
                <Grid item sm={4}>
                  <b>Teilfläche vermessen:</b>
                </Grid>
                <Grid item sm={8}>
                  {x.isMeasured ? 'Ja' : 'Nein'}
                </Grid>

                {x.isMeasured ? (
                  <>
                    <Grid item sm={4}>
                      <b>Fläche des Teilbereichs:</b>
                    </Grid>
                    <Grid item sm={8}>
                      {x.Area} qm
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item sm={4}>
                      <b>Beauftragung Vermessung:</b>
                    </Grid>
                    <Grid item sm={8}>
                      {getRelationName(
                        x.OrderMeasurement_idPurchaseContractRelationType
                      )}{' '}
                    </Grid>
                  </>
                )}

                <Grid item sm={4}>
                  <b>Kostenträger Vermessung:</b>
                </Grid>
                <Grid item sm={8}>
                  {getRelationName(
                    x.PaymentMeasurement_idPurchaseContractRelationType
                  )}{' '}
                </Grid>

                <Grid item sm={4}>
                  <b>Dienstbarkeiten:</b>
                </Grid>
                <Grid item sm={8}>
                  {x.hasEasement ? 'Ja' : 'Nein'}
                </Grid>

                {x.hasEasement && (
                  <>
                    <Grid item sm={4}>
                      <b>Erfasste Dienstbarkeiten:</b>
                    </Grid>
                    <Grid item sm={8}>
                      {' '}
                      {checkString(x.EasementText)}{' '}
                    </Grid>
                  </>
                )}
              </>
            )}

            <Grid item sm={4}>
              <b>Anlagen:</b>
            </Grid>
            <Grid item sm={8}></Grid>
            {getPropertyTsx(x.idRealEstate)}

            <Grid item sm={4}>
              <b>Mängel / Einschränkungen:</b>
            </Grid>
            <Grid item sm={8}>
              {' '}
              {checkString(x.Defect)}{' '}
            </Grid>

            <Grid item sm={4}>
              <b>Besonderheiten:</b>
            </Grid>
            <Grid item sm={8}>
              {' '}
              {checkString(x.Particularities)}{' '}
            </Grid>
          </>
        ))}
      </Grid>
    </>
  );
};
