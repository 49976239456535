import React, { useState } from 'react';
import empty from '../assets/empty.jpg';
import { Avatar } from '@mui/material';

const AvatarPicture: React.FC = () => {
  const getAvatarFromBase64 = () => {
    const pictureBase64 = localStorage.getItem('picture');

    if (pictureBase64 !== null) {
      const byteCharacters = atob(pictureBase64);
      // Each character's code point (charCode) will be the value of the byte.
      // We can create an array of byte values by applying this using the .charCodeAt method for each character in the string.
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      //You can convert this array of byte values into a real typed byte array by passing it to the Uint8Array constructor.
      const byteArray = new Uint8Array(byteNumbers);
      // This in turn can be converted to a BLOB by wrapping it in an array and passing it to the Blob constructor.
      const blob = new Blob([byteArray], { type: 'text/csv' });
      // Erstelle Link zum BLOB
      const blobUrl = URL.createObjectURL(blob);
      return blobUrl;
    } else {
      return empty;
    }
  };

  const [currentImage, setCurrentImage] = useState(getAvatarFromBase64());
  //const [bloclPicture, setBloblPicture] = useState<Blob | null>(getAvatarFromBase64());

  return (
    <>
      <Avatar src={currentImage} />
    </>
  );
};
export default AvatarPicture;
