import { IChildren } from '../../Interfaces/IChildren';
import { IMarriage } from '../../Interfaces/IMarriage';
import { IInheritHasPerson } from '../../Interfaces/IPerson';
import { IPersonHasDocument } from '../../Interfaces/IPersonHasDocument';
import { IRequiredFiles } from '../../LegalTransaction/Inherit/Childs/Components/InheritDocument';

export const getDeathParentArray = (
  inherit: IInheritHasPerson,
  childrenArray: IChildren[],
  inheritPersonArray: IInheritHasPerson[]
) => {
  if (inherit === undefined) {
    return [];
  } else {
    const foundChildren = childrenArray.find(
      x => x.Children_idPerson === inherit.idPerson
    );

    if (foundChildren === undefined) {
      return [] as IInheritHasPerson[];
    } else {
      const returnArray: IInheritHasPerson[] = [];
      const foundFirstParent = inheritPersonArray.find(
        x => x.idPerson === foundChildren?.Parent1_idPerson
      );
      const foundSecondParent = inheritPersonArray.find(
        x => x.idPerson === foundChildren?.Parent2_idPerson
      );

      if (
        foundFirstParent !== undefined &&
        foundFirstParent.DateOfDeath !== null &&
        foundSecondParent !== undefined &&
        foundSecondParent.DateOfDeath !== null
      ) {
        returnArray.push(foundFirstParent);
      } else {
        if (
          foundFirstParent !== undefined &&
          foundFirstParent.DateOfDeath !== null
        ) {
          returnArray.push(foundFirstParent);
        }

        if (
          foundSecondParent !== undefined &&
          foundSecondParent.DateOfDeath !== null
        ) {
          returnArray.push(foundSecondParent);
        }
      }

      return returnArray;
    }
  }
};

export const getInheritPerson = (
  idPerson: number,
  personArray: IInheritHasPerson[],
  isNew?: boolean
) => {
  const testObject = personArray.find(x => x.idPerson === idPerson);
  if ((isNew === undefined || isNew === false) && testObject !== undefined) {
    return testObject;
  } else {
    let minId = Math.min(...personArray.map(x => x.idPerson)) - 1;
    if (minId >= -1) {
      minId = -2;
    }

    return {
      idPerson: minId,
      Birthday: '',
      Birthplace: '',
      DateOfDeath: null,
      FirstName: '',
      idAcademicTitle: 1,
      idCountry: 1,
      idGender: -1,
      idPostcode: null,
      LastName: '',
      PlaceOfDeath: null,
      Street: '',
      Relation_idPerson: null,
      StreetAditional: '',
      StreetNr: '',
      Surname: null,
      idBusinessForm: 1,
      isStranger: false,
      idInherit: -1,
      Bank: null,
      BIC: null,
      Email: null,
      IBAN: null,
      PhoneNumber: null,
      TaxNumber: null,
    } as IInheritHasPerson;
  }
};

export const getMarriage = (
  isNew: boolean,
  idMarriage: number,
  marriageArray: IMarriage[],
  idPerson: number
) => {
  const testObject = marriageArray.find(x => x.idMarriage === idMarriage);

  if (isNew === false && testObject !== undefined) {
    return testObject;
  } else {
    let minId = Math.min(...marriageArray.map(x => x.idMarriage)) - 1;
    if (minId > 0) {
      minId = -1;
    }
    return {
      idMarriage: minId,
      Person1_idPerson: idPerson,
      Person2_idPerson: -1,
      DateOfMarriage: null,
      Devorce_idDocument: null,
      DateOfDevorce: null,
      Marriage_idDocument: -1,
      isDivorced: false,
    } as IMarriage;
  }
};

export const getRequiredMarriageFilesArray = (editMarriage: IMarriage) => {
  if (editMarriage.isDivorced) {
    return [
      { Title: 'Eheurkunde', isOptional: false, idPersonDocumentType: 2 },
      {
        Title: 'Scheidungsurkunde',
        isOptional: false,
        idPersonDocumentType: 3,
      },
    ] as IRequiredFiles[];
  } else {
    return [
      { Title: 'Eheurkunde', isOptional: false, idPersonDocumentType: 2 },
    ] as IRequiredFiles[];
  }
};

export const getRequiredFilesArray = (isDeath: boolean) => {
  if (isDeath) {
    return [
      { Title: 'Geburtsurkunde', isOptional: false, idPersonDocumentType: 4 },
      { Title: 'Sterbeurkunde', isOptional: false, idPersonDocumentType: 1 },
    ] as IRequiredFiles[];
  } else {
    return [
      { Title: 'Geburtsurkunde', isOptional: false, idPersonDocumentType: 4 },
    ] as IRequiredFiles[];
  }
};

export const getChildren = (
  isNew: boolean,
  idChildren: number,
  childrenArray: IChildren[]
) => {
  const testObject = childrenArray.find(x => x.idChildren === idChildren);
  if (isNew === false && testObject !== undefined) {
    return testObject;
  } else {
    let minId = Math.min(...childrenArray.map(x => x.idChildren)) - 1;
    if (minId > 0) {
      minId = -1;
    }
    return {
      idChildren: minId,
      Parent1_idPerson: -1,
      Parent2_idPerson: null,
      Children_idPerson: -1,
      Marriage_idMarriage: null,
      isAdopted: false,
      isPutUpForAdoption: false,
    } as IChildren;
  }
};

export const getNextGeneration = (
  childrens: IChildren[],
  childrenArray: IChildren[]
) => {
  const childIds = childrens.map(child => child.Children_idPerson);
  return childrenArray.filter(child =>
    childIds.includes(child.Parent1_idPerson)
  );
};

export const hasDeceasedChildren = (
  personArray: IInheritHasPerson[],
  childrens: IChildren[]
) => {
  return personArray.some(
    person =>
      childrens
        .map(child => child.Children_idPerson)
        .includes(person.idPerson) && person.DateOfDeath
  );
};

export const getTitleOfDescendants = (degreeOfKinship: number) => {
  const genderMapping: Record<number, string> = {
    1: 'Kinder',
    2: 'Enkelkiner',
    3: 'Urenkelkinder',
    4: 'Ururenkelkinder',
  };

  return genderMapping[degreeOfKinship];
};

export const getAddButtonTitleOfDescendants = (degreeOfKinship: number) => {
  const genderMapping: Record<number, string> = {
    1: 'Kind',
    2: 'Enkelkind',
    3: 'Urenkelkind',
    4: 'Ururenkelkind',
  };

  return genderMapping[degreeOfKinship];
};

export const getTitleOfSiblings = (degreeOfKinship: number) => {
  const genderMapping: Record<number, string> = {
    1: 'Geschwister',
    2: 'Nichten/ Neffen',
    3: 'Großnichten/ Großneffen',
    4: 'Urgroßnichten/ Urgroßneffen',
  };

  return genderMapping[degreeOfKinship];
};

export const getAddButtonTitleOfSiblings = (degreeOfKinship: number) => {
  const genderMapping: Record<number, string> = {
    1: 'Geschwister',
    2: 'Nichte/ Neffe',
    3: 'Großnichte/ Großneffe',
    4: 'Urgroßnichte/ Urgroßneffe',
  };

  return genderMapping[degreeOfKinship];
};

export const handleRemoveRecursive = (
  idPerson: number,
  personArray: IInheritHasPerson[],
  childrenArray: IChildren[],
  personHasDocument: IPersonHasDocument[]
): [IInheritHasPerson[], IChildren[], IPersonHasDocument[]] => {
  const currentChildren = childrenArray.find(
    x => x.Children_idPerson === idPerson
  );

  personHasDocument = [
    ...personHasDocument.filter(x => x.idPerson !== idPerson),
  ];
  personArray = [...personArray.filter(x => x.idPerson !== idPerson)];
  childrenArray = [
    ...childrenArray.filter(
      x => x.idChildren !== Number(currentChildren?.idChildren)
    ),
  ];

  childrenArray
    .filter(x => x.Parent1_idPerson === idPerson)
    .map(x => {
      const [localPersonArray, localChildrenArray, localPersonHasDocument] =
        handleRemoveRecursive(
          x.Children_idPerson,
          personArray,
          childrenArray,
          personHasDocument
        );
      personArray = [...localPersonArray];
      childrenArray = [...localChildrenArray];
      personHasDocument = [...localPersonHasDocument];
    });

  return [personArray, childrenArray, personHasDocument];
};

export const initializeSecondParent = (
  currentChildren: IChildren,
  personArray: IInheritHasPerson[]
): IInheritHasPerson | null => {
  if (currentChildren.Parent2_idPerson) {
    return (
      getInheritPerson(currentChildren.Parent2_idPerson, personArray) || null
    );
  }
  return null;
};
