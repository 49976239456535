import { ICourt } from '../Interfaces/IAemter';
import { ICompany } from '../Interfaces/ICompany';
import { IPerson } from '../Interfaces/IPerson';
import { IPostcode } from '../Interfaces/IPostcode';
import { IRealEstate } from '../Interfaces/IRealEstate';

export const getNewPerson = {
  idPerson: -1,
  idGender: 4,
  idAcademicTitle: 1,
  FirstName: '',
  LastName: '',
  Surname: null,
  Birthday: '',
  Birthplace: '',
  Street: '',
  StreetNr: '',
  StreetAditional: null,
  idPostcode: 1,
  idCountry: 1,
  DateOfDeath: null,
  PlaceOfDeath: null,
  idBusinessForm: 1,
  Email: null,
  PhoneNumber: null,
  TaxNumber: null,
  IBAN: null,
  BIC: null,
  Bank: null,
} as IPerson;

export const getNewCompany = {
  idCompany: -1,
  Company: '',
  idBusinessForm: 1,
  Street: '',
  StreetNr: '',
  StreetAditional: '',
  idPostcode: -1,
  RegisterNumber: null,
  RegisterCourt: null,
  TaxNumber: null,
  SalesTaxNumber: null,

  PhoneNumber: '',
  Website: '',
  Email: '',

  Postcode: '',
  City: '',
} as ICompany;

export const getNewPostcodeObject = {
  idPostcode: null,
  City: '',
  Postcode: '',
  idCountry: null,
} as IPostcode;

export const handlePostcode = (localObject: IRealEstate) => {
  if (
    localObject.idPostcode &&
    localObject.idPostcode > 0 &&
    localObject.City &&
    localObject.Postcode
  ) {
    return {
      idPostcode: localObject.idPostcode,
      City: localObject.City,
      Postcode: localObject.Postcode,
      idCountry: 1,
    } as IPostcode;
  } else {
    return null;
  }
};

export const getAmtsgerichtLabel = (amtsgericht: ICourt) => {
  let label = '';
  label = `${amtsgericht.Court} |  ${amtsgericht.FederalState}`;
  return label;
};

export const deepEqual = (obj1: any, obj2: any): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
