import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { InheritPerson } from '../Components/InheritPerson';
import { IInherit } from '../../../../Interfaces/IInherit';
import { IInheritHasPerson } from '../../../../Interfaces/IPerson';
import { IChildren } from '../../../../Interfaces/IChildren';
import { IPersonHasDocument } from '../../../../Interfaces/IPersonHasDocument';
import {
  getInheritPerson,
  getRequiredFilesArray,
} from '../../../../services/dialog.services.ts/inheritDialog.service';

interface IProps {
  inheritObject: IInherit;
  setIsOpenNew: Function;
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  childrenArray: IChildren[];
  setChildrenArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
}

export const getParentChildren = (
  isNew: boolean,
  idChildren: number,
  childrenArray: IChildren[]
) => {
  const testObject = childrenArray.find(x => x.idChildren === idChildren);
  if (isNew === false && testObject !== undefined) {
    return testObject;
  } else {
    let minId = Math.min(...childrenArray.map(x => x.idChildren)) - 1;
    if (minId > 0) {
      minId = -1;
    }
    return {
      idChildren: minId,
      Parent1_idPerson: -1,
      Parent2_idPerson: null,
      Children_idPerson: -1,
      Marriage_idMarriage: null,
      isAdopted: false,
      isPutUpForAdoption: false,
    } as IChildren;
  }
};

const foundParent = (
  isFirstParent: boolean,
  personArray: IInheritHasPerson[],
  childrenArray: IChildren[]
) => {
  const foundChildren = childrenArray.find(x => x.Children_idPerson === -1);

  if (foundChildren === undefined) {
    return getInheritPerson(-1, personArray, true);
  } else {
    const idPerson = isFirstParent
      ? foundChildren.Parent1_idPerson
      : Number(foundChildren.Parent2_idPerson);
    return getInheritPerson(idPerson, personArray, false);
  }
};

export const AddParents: React.FC<IProps> = props => {
  const formRef = useRef<HTMLFormElement>(null);
  const [firstParent, setFirstParent] = useState(
    foundParent(true, props.personArray, props.childrenArray)
  );
  const [secondParent, setSecondParent] = useState(
    foundParent(false, [...props.personArray, firstParent], props.childrenArray)
  );

  const [documentArray, setDocumentArray] = useState<IPersonHasDocument[]>([]);
  const [isDeathFirstParent, setIsDeathFirstParent] = useState(false);
  const [isDeathSecondParent, setIsDeathSecondParent] = useState(false);
  const [isPageFirstParent, setIsPageFirstParent] = useState(true);
  const [currentChildren, setCurrentChildren] = useState(
    getParentChildren(true, -1, props.childrenArray)
  );

  /**
   * Save Function
   */
  const handleSave = () => {
    if (isPageFirstParent) {
      setIsPageFirstParent(false);
    } else {
      const newParentChild = {
        ...currentChildren,
        Parent1_idPerson: firstParent.idPerson,
        Parent2_idPerson: secondParent.idPerson,
        Children_idPerson: props.personArray[0].idPerson,
        isAdopted: false,
        Marriage_idMarriage: null,
      } as IChildren;
      props.setChildrenArray([...props.childrenArray, newParentChild]);
      props.setPersonArray([...props.personArray, firstParent, secondParent]);
      props.setPersonHasDocument([
        ...props.personHasDocument,
        ...documentArray,
      ]);
      props.setIsOpenNew(false);
    }
  };

  /**
   * Form Function
   */
  const handleOnSave = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      handleSave();
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements);
      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={() => props.setIsOpenNew(false)}
        maxWidth='lg'
        fullWidth
      >
        <DialogTitle variant='h4'>
          Erfassen der Eltern der erbschafthinterlassenden Person
        </DialogTitle>
        <DialogContent>
          <form ref={formRef}>
            <Box sx={{ mt: 5 }} />
            <Collapse in={isPageFirstParent} appear={false}>
              <InheritPerson
                personArray={props.personArray}
                key='firstParent-1'
                currentPerson={firstParent}
                setCurrentPerson={setFirstParent}
                inheritObject={props.inheritObject}
                personHasDocument={documentArray}
                setPersonHasDocument={setDocumentArray}
                title='Erstes Elternteil'
                showDeathSettings
                requiredFiles={getRequiredFilesArray(isDeathFirstParent)}
                setIsDeath={setIsDeathFirstParent}
              />
            </Collapse>

            {!isPageFirstParent && (
              <Collapse in={!isPageFirstParent}>
                <InheritPerson
                  personArray={props.personArray}
                  key='firstParent-2'
                  currentPerson={secondParent}
                  setCurrentPerson={setSecondParent}
                  inheritObject={props.inheritObject}
                  personHasDocument={documentArray}
                  setPersonHasDocument={setDocumentArray}
                  title='Zweites Elternteil'
                  showDeathSettings
                  requiredFiles={getRequiredFilesArray(isDeathSecondParent)}
                  setIsDeath={setIsDeathSecondParent}
                />
              </Collapse>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={() => !isPageFirstParent && setIsPageFirstParent(true)}
            sx={{ mr: 1 }}
            disabled={isPageFirstParent}
          >
            Zurück
          </Button>

          <Box sx={{ flex: '1 1 auto' }} />
          <Button variant='outlined' onClick={() => props.setIsOpenNew(false)}>
            Abbruch
          </Button>
          <Button onClick={handleOnSave} variant='contained'>
            {isPageFirstParent ? 'Weiter' : 'Speichern'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
