import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Box, FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import draftToHtml from 'draftjs-to-html';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { TextModuleSelector } from '../TextModule/TextModuleSelector';
import { DictionaryHelper } from '../Dictionary/DictionaryHelper';

interface IProps {
  txt: string;
  setTxt: Function;
  idTextModuleCategory?: number;
  //
  dictonaryIdsArray?: number[];
}

export const CustomEditor: React.FC<IProps> = props => {
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(htmlToDraft(props.txt).contentBlocks)
    )
  );
  const [htmlEditorOpen, setHtmlEditorOpen] = useState(false);

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    props.setTxt(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const handleChangeNew = () => {
    if (!htmlEditorOpen) {
      props.setTxt(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    } else {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(props.txt).contentBlocks
          )
        )
      );
    }
    setHtmlEditorOpen(!htmlEditorOpen);
  };

  const textModuleWriteTxt = (txt: string) => {
    props.setTxt(txt);

    if (!htmlEditorOpen) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(htmlToDraft(txt).contentBlocks)
        )
      );
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          value='start'
          control={
            <Switch
              checked={htmlEditorOpen}
              onChange={handleChangeNew}
              inputProps={{ 'aria-label': 'controlled' }}
              sx={{ float: 'right' }}
            />
          }
          label='HTML Ansicht'
          labelPlacement='start'
          sx={{ float: 'right' }}
        />

        <TextModuleSelector
          key={`customeditor`}
          floatRight
          idTextModuleCategory={props.idTextModuleCategory}
          txt={props.txt}
          setTxt={(txt: string) => textModuleWriteTxt(txt)}
        />

        {props.dictonaryIdsArray !== undefined && (
          <DictionaryHelper
            txt={props.txt}
            setTxt={(txt: string) => textModuleWriteTxt(txt)}
            dictionaryIdsArray={props.dictonaryIdsArray}
            floatRight
          />
        )}
      </Grid>

      {!htmlEditorOpen && (
        <Grid item xs={12}>
          <Box sx={{ minHeight: 100 }}>
            <Editor
              editorState={editorState}
              toolbarClassName='toolbarClassName'
              wrapperClassName='wrapperClassName'
              editorClassName='editorClassName'
              onEditorStateChange={onEditorStateChange}
            />
          </Box>
        </Grid>
      )}

      {htmlEditorOpen && (
        <Grid item xs={12}>
          <TextField
            id='outlined-multiline-static'
            label='HTML Editor'
            multiline
            fullWidth={true}
            rows={10}
            value={props.txt}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setTxt(event.target.value)
            }
            //defaultValue={(props.txt !== undefined) && props.txt}
          />
        </Grid>
      )}
    </>
  );
};
