import React from 'react';
import { IDocumentAnalysisSection } from '../../Interfaces/IDocumentAnalysisSection';
import { IDocumentAnalysisSubsection } from '../../Interfaces/IDocumentAnalysisSubsection';
import { IDocumentAnalysisAttribute } from '../../Interfaces/IDocumentAnalysisAttribute';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { IDocumentAnalysisSubsectionEntryFullObject } from '../../Interfaces/IDocumentAnalysisSubsectionEntry';
import { Add } from '@mui/icons-material';
import { IDocumentAnalysisAttributeValue } from '../../Interfaces/IDocumentAnalysisAttributeValue';
import { DocumentAnalysisSubsectionEntry } from './DocumentAnalysisSubsectionEntry';
import { useDocumentAssistantContext } from '../DocumentAssistant/DocumentAssistantContext';

interface IProps {
  documentAnalysisSection: IDocumentAnalysisSection;
  disableBtn?: boolean;
}

export const getEmptyDocumentAnalysisSubsectionEntryFullObject = (
  section: IDocumentAnalysisSubsection,
  sectionEntryArray: IDocumentAnalysisSubsectionEntryFullObject[],
  documentAnalysisAttributeArray: IDocumentAnalysisAttribute[]
) => {
  let tmpId = -1;
  const tmpArray: IDocumentAnalysisAttributeValue[] = [];

  if (sectionEntryArray.length > 0) {
    const tmpIdArray =
      Math.min(
        ...sectionEntryArray.map(x => x.idDocumentAnalysisSubsectionEntry)
      ) - 1;

    if (tmpIdArray <= tmpId) {
      tmpId = tmpIdArray - 1;
    }
  }

  documentAnalysisAttributeArray
    .filter(
      x =>
        x.idDocumentAnalysisSubsection === section.idDocumentAnalysisSubsection
    )
    .map((attr, idx) => {
      const newObject: IDocumentAnalysisAttributeValue = {
        idDocumentAnalysisAttribute: attr.idDocumentAnalysisAttribute,
        idDocumentAnalysisSubsectionEntry: tmpId,
        idDocumentAnalysisAttributeValue: idx * -1 - 1,
        Value: '',
      };
      tmpArray.push(newObject);
    });

  const newObject: IDocumentAnalysisSubsectionEntryFullObject = {
    idDocumentAnalysisSubsection: section.idDocumentAnalysisSubsection,
    idDocumentAnalysisSubsectionEntry: tmpId,
    DocumentAnalysisAttributeValueArray: tmpArray,
    isDeleted: false,
  };
  return newObject;
};

export const DocumentAnalysisSectionView = ({
  documentAnalysisSection,
  disableBtn,
}: IProps) => {
  const theme = useTheme();
  const {
    documentAnalysisSubsections,
    documentAnalysisAttributes,
    sectionEntries,
    setSectionEntries,
  } = useDocumentAssistantContext();

  const addSectionEntry = (subsection: IDocumentAnalysisSubsection) => {
    const newObject = getEmptyDocumentAnalysisSubsectionEntryFullObject(
      subsection,
      sectionEntries,
      documentAnalysisAttributes
    );

    setSectionEntries(prevSectionEntries => [...prevSectionEntries, newObject]);
  };

  return (
    <Box>
      <Typography variant='h6'>
        {documentAnalysisSection.DocumentAnalysisSection}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {documentAnalysisSubsections
          .filter(
            subsection =>
              subsection.idDocumentAnalysisSection ===
              documentAnalysisSection.idDocumentAnalysisSection
          )
          .map(subsection => (
            <TableContainer>
              <Table size='small' sx={{ mt: 1 }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        border: `1px solid ${theme.palette.divider}`,
                      }}
                      colSpan={
                        2 +
                        documentAnalysisAttributes.filter(
                          documentAnalysisAttribute =>
                            documentAnalysisAttribute.idDocumentAnalysisSubsection ===
                            subsection.idDocumentAnalysisSubsection
                        ).length
                      }
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        <Typography variant='body2'>
                          {subsection.DocumentAnalysisSubsection}
                        </Typography>
                        {!disableBtn && (
                          <IconButton
                            size='small'
                            onClick={() => addSectionEntry(subsection)}
                          >
                            <Tooltip title='Neuer Eintrag'>
                              <Add />
                            </Tooltip>
                          </IconButton>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ border: `1px solid ${theme.palette.divider}` }}
                  >
                    <TableCell
                      sx={{ border: `1px solid ${theme.palette.divider}` }}
                    >
                      Gestr.
                    </TableCell>
                    {documentAnalysisAttributes
                      .filter(
                        documentAnalysisAttribute =>
                          documentAnalysisAttribute.idDocumentAnalysisSubsection ===
                          subsection.idDocumentAnalysisSubsection
                      )
                      .map(attr => (
                        <TableCell
                          key={`attr-${attr.idDocumentAnalysisAttribute}`}
                          sx={{ border: `1px solid ${theme.palette.divider}` }}
                        >
                          {attr.DocumentAnalysisAttribute}
                        </TableCell>
                      ))}
                    <TableCell
                      sx={{ border: `1px solid ${theme.palette.divider}` }}
                    >
                      Aktionen
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sectionEntries
                    .filter(
                      sectionEntry =>
                        sectionEntry.idDocumentAnalysisSubsection ===
                        subsection.idDocumentAnalysisSubsection
                    )
                    .map(entryValue => (
                      <DocumentAnalysisSubsectionEntry
                        key={`subsectionentry-${entryValue.idDocumentAnalysisSubsectionEntry}`}
                        sectionEntry={entryValue}
                        disableBtn={disableBtn}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ))}
      </Box>
    </Box>
  );
};
