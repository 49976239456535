import React from 'react';
import { ILegalTransactionMeeting } from '../../Interfaces/ILegalTransactionMeeting';
import { IMeetingEmailTemplate } from '../../Interfaces/IMeetingEmailTemplate';
import { Box, Button, Grid, TextField } from '@mui/material';
import { ILegalTransactionMeetingEmail } from '../../Interfaces/ILegalTransactionMeetingEmail';
import { LegalTransactionMeetingEmailOverview } from './Childs/LegalTransactionMeetingEmailOverview';
import { CustomeDateTimePicker } from '../../core/CustomeDateTimePicker';
import { castWithZeroTimezone } from '../../core/generic_helper';

interface IProps {
  meetingObject: ILegalTransactionMeeting;
  setMeetingObject: Function;
  meetingEmailArray: ILegalTransactionMeetingEmail[];
  setMeetingEmailArray: Function;
  meetingEmailTemplateArray: IMeetingEmailTemplate[];
  handleSave: Function;
  setAddNew?: Function;
}

export const LegalTransactionMeetingEdit: React.FC<IProps> = props => {
  const handleClose = () => {
    if (props.setAddNew) {
      props.setAddNew(false);
    }
  };

  const getTillDate = () => {
    if (
      props.meetingObject.Meeting_at &&
      props.meetingObject.Meeting_at !== '' &&
      props.meetingObject.DurationInMin &&
      props.meetingObject.DurationInMin > 0
    ) {
      const meeting_time = new Date(
        castWithZeroTimezone(props.meetingObject.Meeting_at!)!
      );
      const returnValie = new Date(
        meeting_time.setMinutes(
          meeting_time.getMinutes() + props.meetingObject.DurationInMin
        )
      );

      return returnValie.toISOString();
    } else {
      return '';
    }
  };

  const wrapperToDuration = (newValueAsString: string) => {
    const meeting_time = new Date(props.meetingObject.Meeting_at);
    const newValueAsDate = new Date(newValueAsString);

    const diffInMil = Math.abs(
      newValueAsDate.getTime() - meeting_time.getTime()
    );
    props.setMeetingObject({
      ...props.meetingObject,
      DurationInMin: diffInMil / 1000 / 60,
    });
  };

  return (
    <>
      <Box sx={{ mt: 2 }} />
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <TextField
            label='Terminbezeichnung'
            size='small'
            value={props.meetingObject.LegalTransactionMeeting}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setMeetingObject({
                ...props.meetingObject,
                LegalTransactionMeeting: event.target.value,
              })
            }
            error={props.meetingObject.LegalTransactionMeeting === ''}
            fullWidth
          />
        </Grid>

        <Grid item sm={5}>
          <CustomeDateTimePicker
            dateValue={props.meetingObject.Meeting_at}
            setDateValue={(newValue: string) =>
              props.setMeetingObject({
                ...props.meetingObject,
                Meeting_at: newValue,
              })
            }
            allowNull={false}
            allowPast={false}
          />
        </Grid>

        <Grid item sm={2}>
          <TextField
            label='Dauer (in Min.)'
            size='small'
            type='number'
            InputProps={{ inputProps: { min: 0 } }}
            value={props.meetingObject.DurationInMin}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setMeetingObject({
                ...props.meetingObject,
                DurationInMin:
                  Number(event.target.value) < 0
                    ? null
                    : Number(event.target.value),
              })
            }
            fullWidth
          />
        </Grid>

        <Grid item sm={5}>
          <CustomeDateTimePicker
            dateValue={getTillDate()}
            setDateValue={(newValue: string) => wrapperToDuration(newValue)}
            allowNull={false}
            allowPast={false}
          />
        </Grid>
      </Grid>

      <LegalTransactionMeetingEmailOverview
        idLegalTransactionMeeting={
          props.meetingObject.idLegalTransactionMeeting
        }
        meetingEmailArray={props.meetingEmailArray}
        setMeetingEmailArray={props.setMeetingEmailArray}
      />

      <Box sx={{ mt: 5 }}>
        <Button
          sx={{ float: 'right' }}
          disabled={
            props.meetingObject.Meeting_at === '' ||
            props.meetingObject.LegalTransactionMeeting === ''
          }
          variant='contained'
          onClick={() => props.handleSave()}
        >
          Übernehmen
        </Button>
        <Button
          sx={{ mr: 2, float: 'right' }}
          variant='outlined'
          onClick={handleClose}
        >
          Abbruch
        </Button>
      </Box>
    </>
  );
};
