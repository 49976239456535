import React from 'react';
import { IFSDirectory } from '../../Interfaces/IFSDirectory';
import { IFSDirectoryType } from '../../Interfaces/IFSDirectoryType';
import { IFSDirectoryProtocol } from '../../Interfaces/IFSDirectoryProtocol';
import { IFSDirectoryAuthentication } from '../../Interfaces/IFSDirectoryAuthentication';
import { Box, Collapse, Grid, MenuItem } from '@mui/material';
import { CustomeTextField } from '../../generic/CustomeTextField';

interface IProps {
  fsDirObject: IFSDirectory;
  setFsDirObject: Function;
  fsDirTypeArray: IFSDirectoryType[];
  fsDirProtocollArray: IFSDirectoryProtocol[];
  fsDirAuthArray: IFSDirectoryAuthentication[];
}

export const FSDirectoryEdit: React.FC<IProps> = ({
  fsDirObject,
  setFsDirObject,
  fsDirTypeArray,
  fsDirProtocollArray,
  fsDirAuthArray,
}) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <CustomeTextField
            label='Art'
            attr='idFSDirectoryType'
            object={fsDirObject}
            setObject={setFsDirObject}
            type='select'
          >
            {fsDirTypeArray.map(x => (
              <MenuItem
                key={`fsdirtype-${x.idFSDirectoryType}`}
                value={x.idFSDirectoryType}
              >
                {x.FSDirectoryType}
              </MenuItem>
            ))}
          </CustomeTextField>
        </Grid>
        <Grid item sm={4}>
          <CustomeTextField
            label='Protokoll'
            attr='idFSDirectoryProtocol'
            object={fsDirObject}
            setObject={setFsDirObject}
            type='select'
          >
            {fsDirProtocollArray.map(x => (
              <MenuItem
                disabled={x.idFSDirectoryProtocol > 1}
                key={`fsdirprotocol-${x.idFSDirectoryProtocol}`}
                value={x.idFSDirectoryProtocol}
              >
                {x.FSDirectoryProtocol}
              </MenuItem>
            ))}
          </CustomeTextField>
        </Grid>
        <Grid item sm={4}>
          <CustomeTextField
            label='Authentifizierung'
            attr='idFSDirectoryAuthentication'
            object={fsDirObject}
            setObject={setFsDirObject}
            type='select'
          >
            {fsDirAuthArray.map(x => (
              <MenuItem
                disabled={x.idFSDirectoryAuthentication > 2}
                key={`fsdirtAuthentication-${x.idFSDirectoryAuthentication}`}
                value={x.idFSDirectoryAuthentication}
              >
                {x.FSDirectoryAuthentication}
              </MenuItem>
            ))}
          </CustomeTextField>
        </Grid>

        <Grid item sm={6}>
          <CustomeTextField
            label='Server'
            attr='Server'
            object={fsDirObject}
            setObject={setFsDirObject}
            type='string'
          />
        </Grid>
        <Grid item sm={6}>
          <CustomeTextField
            label='Pfad zum Verzeichnis'
            attr='OriginPath'
            object={fsDirObject}
            setObject={setFsDirObject}
            type='string'
          />
        </Grid>
        <Grid item sm={12}>
          <CustomeTextField
            label='Ordner Bezeichnung'
            attr='Name'
            object={fsDirObject}
            setObject={(e: IFSDirectory) =>
              setFsDirObject({
                ...fsDirObject,
                Name: e.Name.replace(/[^\w\s_-]/g, '').toLocaleLowerCase(),
              })
            }
            type='string'
          />
        </Grid>
      </Grid>

      <Collapse in={fsDirObject.idFSDirectoryAuthentication !== 1}>
        <Box sx={{ mt: 5 }} />
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <CustomeTextField
              label='Benutzer'
              attr='User'
              object={fsDirObject}
              setObject={setFsDirObject}
              type='string'
            />
          </Grid>
          <Grid item sm={6}>
            <CustomeTextField
              label='Passwort'
              attr='Password'
              object={fsDirObject}
              setObject={setFsDirObject}
              type='password'
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};
