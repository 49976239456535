import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { IRealEstate } from '../../../../Interfaces/IRealEstate';
import { IPostcode } from '../../../../Interfaces/IPostcode';
import { PostcodeSearch } from '../../../../generic/PostcodeSearch';
import { MapSearch } from '../../../PurchaseContract/Components/RealEstate/MapSearch';
import { usePostCodes } from '../../../../Aemter/hooks';
import { handlePostcode } from '../../../../services/dialog.service';

interface IProps {
  currentObject: IRealEstate;
  setCurrentObject: Function;
  marginTop?: number;
}

export const MortgageRealEstateAddress: React.FC<IProps> = ({
  currentObject,
  setCurrentObject,
  marginTop,
}) => {
  const { postcodes, isLoadingPostcodes } = usePostCodes();

  const setPostcodeObjectWrapper = (
    postcodeObject: IPostcode | null | undefined
  ) => {
    if (postcodeObject) {
      setCurrentObject({
        ...currentObject,
        idPostcode: postcodeObject.idPostcode,
        Postcode: postcodeObject.Postcode,
        City: postcodeObject.City,
      } as IRealEstate);
    }
  };

  return (
    <>
      <Typography
        variant='h5'
        sx={{ mt: marginTop === undefined ? 2 : marginTop, mb: 1 }}
      >
        Anschrift des Objektes
        <Typography mt={2}>
          Bitte geben Sie die Adresse des Objektes an.
        </Typography>
      </Typography>
      <Grid container spacing={2}>
        <Grid item sm={5}>
          <TextField
            label='Straße'
            size='small'
            required
            fullWidth
            value={currentObject.Street === null ? '' : currentObject.Street}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setCurrentObject({
                ...currentObject,
                Street: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item sm={5}>
          <TextField
            label='Adresszusatz'
            size='small'
            fullWidth
            value={
              currentObject.StreetAditional === null
                ? ''
                : currentObject.StreetAditional
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setCurrentObject({
                ...currentObject,
                StreetAditional: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item sm={2}>
          <TextField
            label='Hausnummer'
            size='small'
            required
            fullWidth
            value={
              currentObject.StreetNr === null ? '' : currentObject.StreetNr
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setCurrentObject({
                ...currentObject,
                StreetNr: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item sm={12}>
          <PostcodeSearch
            key={handlePostcode(currentObject)?.idPostcode}
            postcodeObject={handlePostcode(currentObject)}
            setPostcodeObject={setPostcodeObjectWrapper}
            required
          />
        </Grid>

        <Grid item sm={12}>
          <Box sx={{ mt: 1 }} />
        </Grid>

        <Grid item sm={12}>
          <Box sx={{ mt: 1 }} />
        </Grid>

        <Grid item sm={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<GridExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography>Adresse / Koordinaten suchen</Typography>
            </AccordionSummary>

            <AccordionDetails>
              {!isLoadingPostcodes && (
                <MapSearch
                  options={postcodes}
                  realEstate={currentObject}
                  setRealEstate={setCurrentObject}
                />
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};
