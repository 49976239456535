import React, { useEffect, useState } from 'react';
import {
  IMortgage,
  IMortgageFullObject,
  MortgageDocument,
} from '../../Interfaces/IMortgage';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { Owners } from './Childs/Dialog/Owners';
import { IRealEstate } from '../../Interfaces/IRealEstate';
import { RealEstate } from './Childs/Dialog/RealEstate';
import { MortgageDetails } from './Childs/Dialog/MortgageDetails';
import { DocumentOverview } from '../../Document/DocumentOverview';
import { IPerson } from '../../Interfaces/IPerson';
import { Beneficiary } from './Childs/Components/Beneficiary';

interface IProps {
  isSavePossibleValue: Record<string, boolean>;
  setIsSavePossible: Function;
  mortgageObject: IMortgageFullObject;
  setMortgageObject: Function;
}

export const MortgageMain: React.FC<IProps> = ({
  isSavePossibleValue,
  setIsSavePossible,
  mortgageObject,
  setMortgageObject,
}) => {
  const [currentMortgageObject, setCurrentMortgageObject] =
    useState<IMortgage>(mortgageObject);
  const [personArray, setPersonArray] = useState<IPerson[]>(
    mortgageObject.PersonArray
  );
  const [documentArray, setDocumentArray] = useState<MortgageDocument[]>(
    mortgageObject.DocumentArray
  );
  const [realEstate, setRealEstate] = useState<IRealEstate>(
    mortgageObject.RealEstate
  );

  useEffect(() => {
    const updatedObject = {
      ...currentMortgageObject,
      PersonArray: personArray,
      DocumentArray: documentArray,
      RealEstate: realEstate,
    } as IMortgageFullObject;
    setMortgageObject(updatedObject);
  }, [personArray, documentArray, realEstate]);

  useEffect(() => {
    const updatedObject = {
      ...currentMortgageObject,
      PersonArray: personArray,
      DocumentArray: documentArray,
      RealEstate: realEstate,
    } as IMortgageFullObject;
    setMortgageObject(updatedObject);
  }, [currentMortgageObject]);

  // Funktion zur Überprüfung des Begünstigten
  const isValidBeneficiary = () => {
    if ('Company' in currentMortgageObject.BeneficiaryDetails) {
      return currentMortgageObject.BeneficiaryDetails.Company === '';
    } else if ('Postcode' in currentMortgageObject.BeneficiaryDetails) {
      return currentMortgageObject.BeneficiaryDetails.Postcode === '';
    }
    return false;
  };

  return (
    <>
      {(personArray.length < 1 || isValidBeneficiary()) && (
        <Alert severity='warning' sx={{ width: '100%' }}>
          Bitte legen sie einen Eigentümer und einen Begünstigten fest um mit
          der Speicherung fortfahren zu können!
        </Alert>
      )}
      <Accordion>
        <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
          <Typography variant='h6'>Eigentümer</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Owners
            personArray={personArray}
            setPersonArray={setPersonArray}
          ></Owners>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
          <Typography variant='h6'>Objekt / Grundbuchinformationen</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RealEstate
            isSavePossibleValue={isSavePossibleValue}
            setIsSavePossible={setIsSavePossible}
            realEstate={realEstate}
            setRealEstate={setRealEstate}
          ></RealEstate>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
          <Typography variant='h6'>Details Grundschuld</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MortgageDetails
            isSavePossibleValue={isSavePossibleValue}
            setIsSavePossible={setIsSavePossible}
            mortgageObject={currentMortgageObject}
            setMortgageObject={setCurrentMortgageObject}
          ></MortgageDetails>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
          <Typography variant='h6'>Begünstigter</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Beneficiary
            isSavePossibleValue={isSavePossibleValue}
            setIsSavePossible={setIsSavePossible}
            mortgageObject={currentMortgageObject}
            setMortgageObject={setCurrentMortgageObject}
          ></Beneficiary>
        </AccordionDetails>
      </Accordion>

      {mortgageObject.Remarks && mortgageObject.Remarks !== '' && (
        <>
          <Box sx={{ mt: 6 }} />
          <Grid item xs={6} sm={4}>
            <TextField
              label='Bemerkungen'
              size='small'
              rows={5}
              multiline
              fullWidth
              value={mortgageObject.Remarks}
              disabled
            />
          </Grid>
        </>
      )}

      <DocumentOverview
        allowRemove
        allowUpload
        documentArray={documentArray}
        setDocumentArray={setDocumentArray}
        title='Bereitgestellte Daten'
        titleVariant='h6'
        marginTop={6}
      />
    </>
  );
};
