import React, { useState } from 'react';
import { ICompanyFormation } from '../../../../Interfaces/ICompanyFormation';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { IShareHolderFullObject } from '../../../../Interfaces/IShareHolder';
import { ICompanyFormationHasShareHolder } from '../../../../Interfaces/ICompanyFormationHasShareHolder';
import { Representation } from '../gmbh/Representation';

export interface IProps {
  companyFormationObject: ICompanyFormation;
  shareHolderArray: IShareHolderFullObject[];
  setShareHolderArray: Function;
  shareHolderRelation: ICompanyFormationHasShareHolder[];
  setShareHolderRelation: Function;
}

export const DialogEditRepresentation: React.FC<IProps> = props => {
  const [editShareHolderArray, setEditShareHolderArray] = useState(
    props.shareHolderArray
  );
  const [editShareHolderRelationArray, setEditShareHolderRelationArray] =
    useState(props.shareHolderRelation);

  //
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setEditShareHolderArray(props.shareHolderArray);
    setEditShareHolderRelationArray(props.shareHolderRelation);

    setIsOpen(true);
  };

  const getIsDisabled = () => {
    return false;
  };

  const handleSave = () => {
    props.setShareHolderArray(editShareHolderArray);
    props.setShareHolderRelation(editShareHolderRelationArray);
    setIsOpen(false);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen} maxWidth='lg' fullWidth>
        <DialogContent>
          <>
            <Representation
              companyFormationObject={props.companyFormationObject}
              shareHolderArray={editShareHolderArray}
              setShareHolderArray={setEditShareHolderArray}
              shareHolderRelation={editShareHolderRelationArray}
              setShareHolderRelation={setEditShareHolderRelationArray}
            />
          </>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' onClick={() => setIsOpen(false)}>
            Abbruch
          </Button>
          <Button variant='contained' onClick={handleSave}>
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>

      <Button variant='outlined' sx={{ float: 'right' }} onClick={handleOpen}>
        Editieren
      </Button>
    </>
  );
};
