import React, { useState, useRef, useEffect } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Popover,
  TextField,
  Typography,
  debounce,
} from '@mui/material';
import { Add, Tune } from '@mui/icons-material';
import { DataGrid, GridColDef, deDE } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import { ISearchOption } from '../Interfaces/ISearchOption';
import { SearchType } from '../Interfaces/ISearchType';
import { LegalTransactionEdit } from './LegalTransactionEdit';
import {
  useGetUsers,
  useGetUserGroups,
  useSearchLegalTransactions,
  useGetContacts,
  useGetLegalTransactionTypes,
} from '../hooks/data';
import { LegalTransactionNew } from './LegalTransactionNew';

export const columns: GridColDef[] = [
  { field: 'idLegalTransaction', headerName: 'Nr.', width: 90 },
  { field: 'LegalTransactionType', headerName: 'Art', width: 200 },
  { field: 'LegalTransactionProgress', headerName: 'Phase', width: 150 },
  { field: 'LegalTransactionState', headerName: 'Status', width: 150 },
  { field: 'Title', headerName: 'Bezeichnung', flex: 1 },
];

const possibleSearchOptions: ISearchOption[] = [
  { SearchOptionName: 'Titel', SearchOptionKey: 'Title' },
  {
    SearchOptionName: 'Vorgangsnummer',
    SearchOptionKey: 'TransactionNumberEntry',
  },
  { SearchOptionName: 'Urkundennummer', SearchOptionKey: 'RecordNumberEntry' },
];

const initialRowsPerPage = 25;

const LoadingOverlay = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        backdropFilter: 'blur(1px)',
        zIndex: 1,
        height: '100%',
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export const LegalTransactionOverview = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [idLegalTransaction, setIdLegalTransaction] = useState<number | null>(
    params.idLegalTransaction ? Number(params.idLegalTransaction) : null
  );

  const [showSearchOptions, setShowSearchOptions] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(initialRowsPerPage);
  const [searchType, setSearchType] = useState(SearchType.INCLUDES);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [searchOptions, setSearchOptions] = useState(possibleSearchOptions);

  let searchQuery = '';

  for (let i = 0; i < searchOptions.length; i++) {
    searchQuery += `${searchOptions[i].SearchOptionKey}:${debouncedSearchTerm};`;
  }

  const searchUri =
    debouncedSearchTerm === ''
      ? `page=${page}&rowsPerPage=${pageSize}`
      : `search=${searchQuery}&searchtype=${searchType}&page=${page}&rowsPerPage=${pageSize}`;

  const {
    legalTransactions,
    isLoadingLegalTransactions,
    abortSearchLegalTransactions,
  } = useSearchLegalTransactions(searchUri);

  const { users, isLoadingUsers } = useGetUsers();
  const { userGroups, isLoadingUserGroups } = useGetUserGroups();
  const { legalTransactionTypes, isLoadingLegalTransactionTypes } =
    useGetLegalTransactionTypes();

  const { contacts, isLoadingContacts } = useGetContacts();

  const actualLegalTransactions = legalTransactions || [];

  const [showCreateNewDialog, setShowCreateNewDialog] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const textFieldRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setIdLegalTransaction(
      params.idLegalTransaction ? Number(params.idLegalTransaction) : null
    );
  }, [params.idLegalTransaction]);

  const handleClose = (isEdited: boolean) => {
    if (isEdited) {
      setShowConfirmationDialog(true);
    } else {
      setShowConfirmationDialog(false);
      navigate('/legaltransaction');
    }
  };

  const handleLegalTransactionFinished = (idLegalTransaction: number) => {
    navigate(`/legaltransaction/${idLegalTransaction}`);
    setIdLegalTransaction(idLegalTransaction);
    setShowCreateNewDialog(false);
  };

  const handleFinalOpen = () => {
    setShowConfirmationDialog(false);
    navigate('/legaltransaction');
  };

  const handleSearchTermChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);

    const debouncedSearchTerm = debounce(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    debouncedSearchTerm();
  };

  if (
    isLoadingUsers ||
    isLoadingUserGroups ||
    isLoadingLegalTransactionTypes ||
    isLoadingContacts
  ) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          height: '80vh',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!users || !userGroups || !legalTransactionTypes || !contacts) {
    return (
      <Box sx={{ m: 2 }}>
        <Typography>
          Ein Fehler ist aufgetreten! Bitte versuchen Sie es später erneut.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ m: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant='h4'>Vorgänge</Typography>
          <IconButton onClick={() => setShowCreateNewDialog(true)}>
            <Add />
          </IconButton>
        </Box>

        <Grid container mt={1} spacing={1}>
          <Grid item sm={2}>
            <TextField
              sx={{ mt: 2, mb: 2 }}
              label='Suchbedingung'
              value={searchType}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                abortSearchLegalTransactions();

                setSearchType(event.target.value as SearchType);
                setPage(1);
              }}
              fullWidth
              select
            >
              <MenuItem value={SearchType.STARTS_WITH}>
                Beginnt mit Sucheingabe
              </MenuItem>
              <MenuItem value={SearchType.INCLUDES}>
                Enthält Sucheingabe
              </MenuItem>
              <MenuItem value={SearchType.EXACT_MATCH}>
                Identisch mit Sucheingabe
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item sm={10}>
            <TextField
              ref={textFieldRef}
              sx={{ mt: 2, mb: 2 }}
              label='Suche'
              fullWidth
              value={searchTerm}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.value !== searchTerm) {
                  abortSearchLegalTransactions();
                  handleSearchTermChange(event.target.value);
                  setPage(1);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      edge='end'
                      onClick={() => setShowSearchOptions(prev => !prev)}
                    >
                      <Tune />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Popover
            id={'filter'}
            open={showSearchOptions}
            anchorEl={textFieldRef.current}
            onClose={() => {
              setShowSearchOptions(false);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Autocomplete
              multiple
              options={possibleSearchOptions}
              getOptionLabel={option => option.SearchOptionName}
              value={searchOptions}
              onChange={(_, newValue) => {
                setSearchOptions(newValue);
                setPage(1);
              }}
              renderTags={(value: ISearchOption[], getTagProps) =>
                value.map((option: ISearchOption, index: number) => (
                  <Chip
                    label={option.SearchOptionName}
                    {...getTagProps({ index })}
                  />
                ))
              }
              style={{ padding: 10, width: textFieldRef?.current?.clientWidth }}
              isOptionEqualToValue={(
                option: ISearchOption,
                value: ISearchOption
              ) => {
                return option.SearchOptionKey === value.SearchOptionKey;
              }}
              renderInput={params => (
                <TextField {...params} variant='outlined' label='Suche nach' />
              )}
            />
          </Popover>
        </Grid>

        <Grid container spacing={2}>
          <Grid item sm={12}>
            <DataGrid
              loading={isLoadingLegalTransactions}
              localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
              rows={actualLegalTransactions}
              columns={columns}
              getRowId={row => row.idLegalTransaction}
              onRowClick={params =>
                navigate(`/legaltransaction/${params.row.idLegalTransaction}`)
              }
              autoHeight
              pagination
              paginationModel={{ page, pageSize }}
              rowCount={actualLegalTransactions.length}
              onPaginationModelChange={model => {
                setPage(model.page);
                setPageSize(model.pageSize);
              }}
              paginationMode='server'
              pageSizeOptions={[initialRowsPerPage, 50, 100]}
              slots={{
                loadingOverlay: () => <LoadingOverlay />,
              }}
            />
          </Grid>
          <Grid
            item
            sm={12}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          ></Grid>
        </Grid>
      </Box>

      {idLegalTransaction && (
        <LegalTransactionEdit
          key={`idLegalTransaction-${idLegalTransaction}`}
          idLegalTransaction={idLegalTransaction}
          handleClose={handleClose}
          users={users}
          userGroups={userGroups}
          legalTransactionTypes={legalTransactionTypes}
          contacts={contacts}
        />
      )}

      {showCreateNewDialog && (
        <LegalTransactionNew
          users={users}
          userGroups={userGroups}
          legalTransactionTypes={legalTransactionTypes}
          contacts={contacts}
          handleClose={() => setShowCreateNewDialog(false)}
          handleFinished={handleLegalTransactionFinished}
        />
      )}

      <Dialog open={showConfirmationDialog}>
        <DialogTitle>Warnung</DialogTitle>
        <DialogContent>
          Es gibt unbearbeitete Änderungen in diesem Vorgang. Sind Sie sicher,
          dass Sie den Vorgang ohne Speichern schließen wollen?
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={() => setShowConfirmationDialog(false)}
          >
            Abbrechen
          </Button>
          <Button variant='contained' onClick={handleFinalOpen}>
            Schließen ohne Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
