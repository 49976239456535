import { AlarmOn, HourglassFull } from '@mui/icons-material';
import {
  Alert,
  Box,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { IDocumentDownloaded } from '../../Interfaces/IDocumentDownloaded';
import {
  ILegalRequirement,
  ILegalRequirementFullObject,
} from '../../Interfaces/ILegalRequirement';
import { ILegalRequirementDeadline } from '../../Interfaces/ILegalRequirement_deadlines';
import { ILegalRequirementState } from '../../Interfaces/ILegalRequirementState';
import { LegalInfoText } from './LegalInfoText';
import { LegalRequirementDocuments } from './LegalRequirementDocuments';

interface IProps {
  legalRequirement: ILegalRequirementFullObject;
  legalRequirementArray: ILegalRequirement[];
  setLegalRequirementArray: Function;
  isNotDisabled: boolean;
  legalReqState: ILegalRequirementState[];
  downloadedArray?: IDocumentDownloaded[];
  startDeadlineDate: Date | undefined;
}

export const LegalRequirementRow: React.FC<IProps> = props => {
  //const [legalRequirement, setLegalRequirement] = useState(props.legalRequirement);

  const setLegalRequirement = (updatedLegalReq: ILegalRequirement) => {
    props.setLegalRequirementArray([
      ...props.legalRequirementArray.map(x =>
        x.idLegalRequirement === updatedLegalReq.idLegalRequirement
          ? updatedLegalReq
          : x
      ),
    ]);
  };

  const getCurrentValue = () => {
    if (props.legalRequirement.isComplete) {
      return 1;
    } else if (props.legalRequirement.hasError) {
      return 2;
    } else {
      return 0;
    }
  };

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLegalRequirement({
      ...props.legalRequirement,
      idLegalRequirementState: Number(event.target.value),
    });
    /*
        let tmpValue = Number(event.target.value)

        if (tmpValue === 0) {
            setLegalRequirement({...legalRequirement, isComplete: false, hasError: false})
        }
        else if (tmpValue === 2 && legalRequirement.enableError) {
            setLegalRequirement({...legalRequirement, 
                isComplete: false, 
                hasError: true
            })
        }
        else {
            setLegalRequirement({...legalRequirement, 
                isComplete: true
            })
        }
        */
  };

  /*
    useEffect(() => {
        props.setLegalRequirementArray([
            ...props.legalRequirementArray.map(x => 
                x.idLegalRequirement === legalRequirement.idLegalRequirement
                ? legalRequirement : x
            )
        ])
    },[legalRequirement])
    */

  const addDaysToDate = (date: Date, days: number): Date => {
    const millisecondsPerDay = 1000 * 60 * 60 * 24; // Anzahl der Millisekunden in einem Tag
    const result = new Date(date.getTime() + days * millisecondsPerDay);
    return result;
  };

  const getEarliestTriggerDate = useCallback(
    (deadlineArray: ILegalRequirementDeadline[]) => {
      const highestWeighting = deadlineArray
        .filter(
          deadline =>
            deadline.DeadlineTriggeredAt !== null &&
            deadline.DeadlineTriggeredAt !== undefined
        )
        .reduce((maxWeighting, current) => {
          return current.Weighting > maxWeighting
            ? current.Weighting
            : maxWeighting;
        }, 0);

      const earliestTriggerDate = deadlineArray
        .filter(deadline => {
          return (
            deadline.DeadlineTriggeredAt !== null &&
            deadline.DeadlineTriggeredAt !== undefined
          );
        }) //get all with the highest weighting
        .filter(x => x.Weighting == highestWeighting)
        .reduce((earliestTriggerDate, current) => {
          return new Date(current.DeadlineTriggeredAt!) < earliestTriggerDate
            ? new Date(current.DeadlineTriggeredAt!)
            : earliestTriggerDate;
        }, new Date());

      return earliestTriggerDate;
    },
    []
  );

  const getMaxExceededDays = useCallback(() => {
    const { DeadlineArray, initialProcessingTime } = props.legalRequirement;

    if (DeadlineArray && DeadlineArray.length > 0) {
      const earliestTriggerDate = getEarliestTriggerDate(DeadlineArray);

      const differenceInTime =
        new Date().getTime() -
        addDaysToDate(
          earliestTriggerDate,
          props.legalRequirement.initialProcessingTime || 0
        ).getTime();
      return Math.floor(differenceInTime / (1000 * 3600 * 24));
    } else if (props.startDeadlineDate) {
      // Prüfe, ob das startDate ein gültiges Datum ist
      const startDateObject = new Date(props.startDeadlineDate);
      if (isNaN(startDateObject.getTime())) {
        return props.legalRequirement.initialProcessingTime || 0;
      }

      // Hier wird initialProcessingTime korrekt zum Datum addiert
      const adjustedStartDate = addDaysToDate(
        startDateObject,
        initialProcessingTime || 0
      );

      const currentDate = new Date();
      const differenceInTime =
        currentDate.getTime() - adjustedStartDate.getTime();
      return Math.floor(differenceInTime / (1000 * 3600 * 24)); // Kein Math.max, damit negative Werte berücksichtigt werden
    }

    return 0;
  }, [getEarliestTriggerDate, props.legalRequirement, props.startDeadlineDate]);

  const hasAtLeastOneDeadlineTriggered = useCallback(() => {
    const { DeadlineArray } = props.legalRequirement;
    if (DeadlineArray && DeadlineArray.length > 0) {
      return DeadlineArray.some(deadline => {
        if (deadline.Startdate !== null && deadline.Startdate !== undefined) {
          if (new Date(deadline.Startdate) <= new Date()) {
            deadline.DeadlineTriggeredAt = deadline.Startdate;
            return true;
          }
        }
        return (
          deadline.DeadlineTriggeredAt !== null &&
          deadline.DeadlineTriggeredAt !== undefined
        );
      });
    }
    if (
      props.startDeadlineDate !== null &&
      props.startDeadlineDate !== undefined
    ) {
      if (new Date(props.startDeadlineDate) <= new Date()) {
        return true;
      }
    }
    return false;
  }, [props.legalRequirement, props.startDeadlineDate]);

  const getDaysAfterDeadlineForFinishedRequirement = useCallback(() => {
    if (props.legalRequirement.idLegalRequirementState >= 15) {
      if (
        props.legalRequirement.DeadlineArray &&
        props.legalRequirement.DeadlineArray.length > 0
      ) {
        let deadlineFullfilledDate = new Date();

        if (props.legalRequirement.DeadlineFullfilledAt) {
          deadlineFullfilledDate = new Date(
            props.legalRequirement.DeadlineFullfilledAt
          );
        }
        const earliestTriggerDate = addDaysToDate(
          getEarliestTriggerDate(props.legalRequirement.DeadlineArray),
          props.legalRequirement.initialProcessingTime || 0
        );

        const differenceInTime =
          deadlineFullfilledDate.getTime() - earliestTriggerDate.getTime();
        return Math.floor(differenceInTime / (1000 * 3600 * 24));
      } else {
        let deadlineFullfilledDate = new Date();

        if (props.legalRequirement.DeadlineFullfilledAt) {
          deadlineFullfilledDate = new Date(
            props.legalRequirement.DeadlineFullfilledAt
          );
        }
        const daysAfter = addDaysToDate(
          props.startDeadlineDate ? props.startDeadlineDate : new Date(),
          props.legalRequirement.initialProcessingTime || 0
        );
        const differenceInTime =
          deadlineFullfilledDate.getTime() - daysAfter.getTime();
        return Math.floor(differenceInTime / (1000 * 3600 * 24));
      }
    }
    return 0;
  }, [props.legalRequirement, props.startDeadlineDate, getEarliestTriggerDate]);

  const [exceededDays, setExceededDays] = useState(getMaxExceededDays());
  const [atLeastOneDeadlineTriggered, setAtLeastOneDeadlineTriggered] =
    useState(hasAtLeastOneDeadlineTriggered());

  const [daysAfterDeadlineForFinish, setDaysAfterDeadlineForFinish] = useState(
    getDaysAfterDeadlineForFinishedRequirement()
  );

  useEffect(() => {
    setExceededDays(getMaxExceededDays());
    setAtLeastOneDeadlineTriggered(hasAtLeastOneDeadlineTriggered());
    setDaysAfterDeadlineForFinish(getDaysAfterDeadlineForFinishedRequirement());
  }, [
    getDaysAfterDeadlineForFinishedRequirement,
    getMaxExceededDays,
    hasAtLeastOneDeadlineTriggered,
    props.legalRequirement,
    props.startDeadlineDate,
  ]);

  return (
    <>
      <Grid item sm={3} lg={1}></Grid>

      <Grid item sm={9} lg={11}>
        <Alert
          variant={props.isNotDisabled ? 'standard' : 'outlined'}
          severity={
            props.legalRequirement.idLegalRequirementState === 40
              ? 'success'
              : props.legalRequirement.enableError &&
                  props.legalRequirement.idLegalRequirementState === 30
                ? 'error'
                : props.legalRequirement.idLegalRequirementState === 15 ||
                    props.legalRequirement.idLegalRequirementState === 20
                  ? 'warning'
                  : 'info'
          }
          action={
            <>
              <LegalInfoText currentObject={props.legalRequirement} />
              {props.isNotDisabled ? (
                <>
                  <TextField
                    label='Status'
                    size='small'
                    value={props.legalRequirement.idLegalRequirementState}
                    onChange={handleChangeValue}
                    sx={{ width: 200 }}
                    select
                  >
                    {props.legalReqState.map(x => (
                      <MenuItem
                        key={`idLegalRequirementState-${x.idLegalRequirementState}`}
                        value={x.idLegalRequirementState}
                        disabled={
                          x.idLegalRequirementState === 30 &&
                          !props.legalRequirement.enableError
                        }
                      >
                        {x.LegalRequirementState}
                      </MenuItem>
                    ))}
                    {/*
                                    <MenuItem value={0}>Offen</MenuItem>
                                    <MenuItem value={1}>Erledigt</MenuItem>
                                    <MenuItem value={2} disabled={!legalRequirement.enableError}>Fehler</MenuItem>
                                     */}
                  </TextField>
                </>
              ) : (
                <i style={{ marginTop: 2 }}>Keine Änderungen möglich</i>
              )}
            </>
          }
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <Box width={'100%'} display={'flex'} alignItems={'center'}>
            {props.legalRequirement.LegalRequirement}
            {exceededDays > 0 &&
              props.legalRequirement.idLegalRequirementState < 15 && (
                <>
                  <Tooltip
                    title={`${
                      props.legalRequirement.Court !== null &&
                      props.legalRequirement.Court !== undefined
                        ? `Die Maßnahme liegt bei ${props.legalRequirement.CourtRole} ${props.legalRequirement.Court}.`
                        : ''
                    } Die Frist dieser Maßnahme wurde um ${exceededDays !== 1 ? `${exceededDays} Tage überschritten` : `${exceededDays} Tag überschritten`}`}
                  >
                    <Box
                      display='flex'
                      alignItems='center'
                      sx={{ width: 150 }}
                      ml={4}
                      mr={2}
                    >
                      <LinearProgress
                        variant='determinate'
                        color='error'
                        value={100}
                        sx={{ flexGrow: 1, marginRight: 2 }} // Responsive width with flexGrow
                      />
                    </Box>
                  </Tooltip>
                </>
              )}
            {exceededDays <= 0 &&
              atLeastOneDeadlineTriggered &&
              props.legalRequirement.idLegalRequirementState < 15 && (
                <Tooltip
                  title={`${
                    props.legalRequirement.Court !== null &&
                    props.legalRequirement.Court !== undefined
                      ? `Die Maßnahme liegt bei ${props.legalRequirement.CourtRole} ${props.legalRequirement.Court}.`
                      : ''
                  } Die Frist dieser Maßnahme wurde noch nicht überschritten. ${Math.abs(exceededDays) !== 1 ? `Es sind noch ${Math.abs(exceededDays)} Tage übrig` : `Es ist noch ${Math.abs(exceededDays)} Tag übrig`}`}
                >
                  <Box
                    display='flex'
                    alignItems='center'
                    sx={{ width: 150 }}
                    ml={4}
                    mr={2}
                  >
                    {' '}
                    {Math.abs(exceededDays) <= 5 && (
                      <LinearProgress
                        variant='determinate'
                        color='warning'
                        value={
                          (Math.abs(
                            props.legalRequirement.initialProcessingTime +
                              exceededDays
                          ) /
                            props.legalRequirement.initialProcessingTime) *
                          100
                        }
                        sx={{ flexGrow: 1, marginRight: 2 }} // Responsive width with flexGrow
                      />
                    )}
                    {Math.abs(exceededDays) > 5 && (
                      <LinearProgress
                        variant='determinate'
                        color='success'
                        value={
                          (Math.abs(
                            props.legalRequirement.initialProcessingTime +
                              exceededDays
                          ) /
                            props.legalRequirement.initialProcessingTime) *
                          100
                        }
                        sx={{ flexGrow: 1, marginRight: 2 }} // Responsive width with flexGrow
                      />
                    )}
                  </Box>
                </Tooltip>
              )}
            {!atLeastOneDeadlineTriggered &&
              props.legalRequirement.idLegalRequirementState < 15 && (
                <Tooltip title='Die Frist dieser Maßnahme wurde noch nicht ausgelöst'>
                  <IconButton>
                    <HourglassFull color='disabled'></HourglassFull>
                  </IconButton>
                </Tooltip>
              )}
            {props.legalRequirement.idLegalRequirementState >= 15 &&
              daysAfterDeadlineForFinish <= 0 && (
                <Tooltip
                  title={
                    'Die Maßnahme wurde ' +
                    Math.abs(daysAfterDeadlineForFinish) +
                    ' Tage vor Ende der Frist abgeschlossen. ' +
                    (props.legalRequirement.actualProcessingTime !== null &&
                    props.legalRequirement.actualProcessingTime !== undefined
                      ? props.legalRequirement.actualProcessingTime !== 1
                        ? 'Die Bearbeitungszeit betrug ' +
                          props.legalRequirement.actualProcessingTime +
                          ' Tage'
                        : 'Die Bearbeitungszeit betrug ' +
                          props.legalRequirement.actualProcessingTime +
                          ' Tag'
                      : 'Der Vorgang wurde noch nicht gespeichert. Aus diesem Grund wurde die Bearbeitungszeit noch nicht berechnet.')
                  }
                >
                  <IconButton>
                    <AlarmOn color='success'></AlarmOn>
                  </IconButton>
                </Tooltip>
              )}

            {props.legalRequirement.idLegalRequirementState >= 15 &&
              daysAfterDeadlineForFinish > 0 && (
                <Tooltip
                  title={
                    `Die Maßnahme wurde ${daysAfterDeadlineForFinish !== 1 ? `${daysAfterDeadlineForFinish} Tage nach der Frist abgeschlossen` : `${daysAfterDeadlineForFinish} Tag nach der Frist abgeschlossen`}. Die Bearbeitungszeit für die Maßnahme betrug: ` +
                    (props.legalRequirement.actualProcessingTime !== null &&
                    props.legalRequirement.actualProcessingTime !== undefined
                      ? props.legalRequirement.actualProcessingTime !== 1
                        ? props.legalRequirement.actualProcessingTime + ' Tage'
                        : props.legalRequirement.actualProcessingTime + ' Tag'
                      : props.legalRequirement.initialProcessingTime !== 1
                        ? props.legalRequirement.initialProcessingTime + ' Tage'
                        : props.legalRequirement.initialProcessingTime + ' Tag')
                  }
                >
                  <IconButton>
                    <AlarmOn color='error'></AlarmOn>
                  </IconButton>
                </Tooltip>
              )}
            <LegalRequirementDocuments
              legalRequirementObject={props.legalRequirement}
              setLegalRequirementObject={setLegalRequirement}
              isNotDisabled={props.isNotDisabled}
              downloadedArray={props.downloadedArray}
            />
            {props.legalRequirement.sendMail === true && (
              <Typography variant='caption'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(E-Mail wird versendet)
              </Typography>
            )}
          </Box>
        </Alert>
      </Grid>
    </>
  );
};
