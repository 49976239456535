import React, { useEffect, useState } from 'react';
import { ITimeRecording } from '../../Interfaces/ITimeRecording';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  castWithZeroTimezone,
  getHfromMinutes,
} from '../../core/generic_helper';

interface IProps {
  timeRecordArray: ITimeRecording[];
}

export const TimeRecordAccount: React.FC<IProps> = props => {
  //
  const [filteredThisMonth, setFilteredThisMonth] = useState<ITimeRecording[]>(
    []
  );

  const getThisWeek = () => {
    let totalWekk = 0;

    const today = new Date();
    const dayOfWeek = today.getDay(); // Aktueller Wochentag (0 = Sonntag, 1 = Montag, ..., 6 = Samstag)
    const daysUntilMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Anzahl der Tage bis Montag
    const start = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - daysUntilMonday
    );
    start.setHours(0, 0, 0, 0);

    const end = new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate() + 6
    );
    end.setHours(23, 59, 59, 59);

    props.timeRecordArray
      .filter(
        x =>
          new Date(x.DateOfRecord) >= start && new Date(x.DateOfRecord) <= end
      )
      .map(x => (totalWekk = totalWekk + Number(x.Total)));

    return getHfromMinutes(totalWekk);
  };

  const getThisMonth = () => {
    let totalWekk = 0;

    filteredThisMonth.map(x => (totalWekk = totalWekk + Number(x.Total)));

    return getHfromMinutes(totalWekk);
  };

  useEffect(() => {
    const totalWekk = 0;

    const today = new Date();
    const start = new Date(today.getFullYear(), today.getMonth(), 1);
    start.setHours(0, 0, 0, 0);

    const end = new Date(start.getFullYear(), start.getMonth() + 1);
    end.setDate(end.getDate() - 1);
    end.setHours(23, 59, 59, 59);

    setFilteredThisMonth([
      ...props.timeRecordArray.filter(
        x =>
          new Date(x.DateOfRecord) >= start && new Date(x.DateOfRecord) <= end
      ),
    ]);
  }, [props.timeRecordArray]);

  return (
    <>
      <Typography>
        Sie haben diese Woche <u>{getThisWeek()}</u> gebucht.
      </Typography>

      <Typography sx={{ mt: 5 }}>Dieser Monat</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Von</TableCell>
            <TableCell>Bis</TableCell>
            <TableCell>
              <Box sx={{ float: 'right' }}>Total</Box>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {filteredThisMonth.map(x => {
            const fromTime = castWithZeroTimezone(x.FromTime);
            const toTime = castWithZeroTimezone(x.ToTime);

            return (
              <TableRow key={`-account-idTimeRecording-${x.idTimeRecording}`}>
                <TableCell sx={{ maxWidth: 100 }}>
                  {fromTime === null ? '-' : fromTime.toLocaleString()}
                </TableCell>
                <TableCell sx={{ maxWidth: 100 }}>
                  {toTime === null ? '-' : toTime.toLocaleString()}
                </TableCell>
                <TableCell>
                  <Box sx={{ float: 'right' }}>
                    {x.Total === null ? '-' : getHfromMinutes(x.Total)}
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell colSpan={2}>
              <b>Total</b>
            </TableCell>
            <TableCell>
              <Box sx={{ float: 'right' }}>
                <b>{getThisMonth()}</b>
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};
