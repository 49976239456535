import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { GridBaseData } from '../../../../Person/GridBaseData';
import { IPerson } from '../../../../Interfaces/IPerson';
import { Delete, Edit } from '@mui/icons-material';
import { getNewPerson } from '../../../../services/dialog.service';

interface IProps {
  personArray: IPerson[];
  setPersonArray: Function;
}

export const Owner: React.FC<IProps> = ({ personArray, setPersonArray }) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editOwner, setEditOwner] = useState<IPerson>(getNewPerson);
  const formRef = useRef<HTMLFormElement>(null);

  const handleRemove = (id: number) => {
    const updatedPersonArray = personArray.filter(
      person => person.idPerson !== id
    );
    setPersonArray(updatedPersonArray);
  };

  const handleOpen = (person: IPerson) => {
    setEditOwner(person);
    setIsEditOpen(true);
  };

  const updateOwner = () => {
    if (editOwner) {
      const updatedPersonArray = personArray.map(person =>
        person.idPerson === editOwner.idPerson ? editOwner : person
      );
      setPersonArray(updatedPersonArray);
      setIsEditOpen(false);
    }
  };

  /**
   * Form Function
   */
  const handleOnSave = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      updateOwner();
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements).reverse();

      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  return (
    <>
      <Dialog
        open={isEditOpen}
        maxWidth='md'
        fullWidth
        onClose={() => setIsEditOpen(false)}
      >
        <DialogContent>
          <>
            <Typography variant='h5'>Eigentümer bearbeiten</Typography>
            <form ref={formRef}>
              <GridBaseData
                personObject={editOwner}
                personArray={personArray}
                setPersonObject={setEditOwner}
                showDeathSettings={false}
                forceDeathSettings={false}
                titleAdress='Anschrift'
                setIsDeath={() => {}}
                disableEdit={false}
                forceAdress={true}
                disableDeathDateWarning={true}
                disableContactInformations
              />
            </form>
          </>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={() => {
              setIsEditOpen(false);
            }}
          >
            Abbruch
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              handleOnSave();
            }}
          >
            Spreichern
          </Button>
        </DialogActions>
      </Dialog>
      {personArray.map((person, index) => (
        <TableRow>
          <TableCell width={'25%'}>{index + 1}</TableCell>
          <TableCell width={'25%'}>{person.FirstName}</TableCell>
          <TableCell width={'25%'}>{person.LastName}</TableCell>
          <TableCell width={'25%'}>
            <IconButton onClick={() => handleOpen(person)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => handleRemove(person.idPerson)}>
              <Delete />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}

      <Box sx={{ mt: 2 }} />
    </>
  );
};
