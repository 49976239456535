import React, {useEffect} from 'react';
import { IRealEstate } from '../../../../Interfaces/IRealEstate';
import { Grid, TextField, Typography } from '@mui/material';
import { ICourt } from '../../../../Interfaces/IAemter';
import { CourtSearch } from '../../../../generic/CourtSearch';

interface IProps {
  currentObject: IRealEstate;
  setCurrentObject: Function;
  marginTop?: number;
}

export const RealEstateLandRegister: React.FC<IProps> = props => {
    const setCourtObjectWrapper = (CourtObject: ICourt | null | undefined) => {
        props.setCurrentObject({
            ...props.currentObject,
            idCourt: CourtObject?.idCourt,
            Court: CourtObject,
        } as IRealEstate);
    };

  return (
    <>
      <Typography
        variant='h5'
        sx={{ mt: props.marginTop === undefined ? 2 : props.marginTop, mb: 2 }}
      >
        Grundbuchinformationen
      </Typography>
      <Typography variant='body1' mb={2}>
        Falls Ihnen die Grundbuchinformationen bereits vorliegen, tragen Sie
        diese gerne ein. Andernfalls können diese Informationen zu einem
        späteren Zeitpunkt erfasst werden.
      </Typography>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <CourtSearch
            setCourtObjectWrapper={setCourtObjectWrapper}
            courtId={props.currentObject.idCourt}
          />
        </Grid>
        <Grid item sm={8}>
          <TextField
            label='Grundbuch'
            size='small'
            fullWidth
            value={props.currentObject.LandRegister}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                LandRegister: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            label='Blatt'
            size='small'
            fullWidth
            value={props.currentObject.SheetLandRegister}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                SheetLandRegister: event.target.value,
              })
            }
          />
        </Grid>

        <Grid item sm={6}>
          <TextField
            label='Flurstück'
            size='small'
            fullWidth
            value={props.currentObject.LandParcel}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                LandParcel: event.target.value,
              })
            }
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            label='Flur'
            size='small'
            fullWidth
            value={props.currentObject.Parcel}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                Parcel: event.target.value,
              })
            }
          />
        </Grid>
      </Grid>
    </>
  );
};
