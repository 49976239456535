import React, { useState } from 'react';
import { IMeetingEmailTemplate } from '../../Interfaces/IMeetingEmailTemplate';
import { ILegalTransactionMeeting } from '../../Interfaces/ILegalTransactionMeeting';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { LegalTransactionMeetingEdit } from './LegalTransactionMeetingEdit';
import { ILegalTransactionMeetingEmail } from '../../Interfaces/ILegalTransactionMeetingEmail';
import { LegalTransactionMeetingRow } from './LegalTransactionMeetingRow';
import { castWithZeroTimezone } from '../../core/generic_helper';

interface IProps {
  idLegalTransactionType: number;
  meetingEmailTemplateArray: IMeetingEmailTemplate[];
  meetingArray: ILegalTransactionMeeting[];
  setMeetingArray: Function;
  meetingEmailArray: ILegalTransactionMeetingEmail[];
  setMeetingEmailArray: Function;
}

const getNewMeeting = (meetingArray: ILegalTransactionMeeting[]) => {
  let tmpId = -1;

  if (meetingArray.length > 0) {
    const tmpIdArray =
      Math.min(...meetingArray.map(x => x.idLegalTransactionMeeting)) - 1;

    if (tmpIdArray <= tmpId) {
      tmpId = tmpIdArray - 1;
    }
  }

  return {
    idLegalTransactionMeeting: tmpId,
    idLegalTransaction: -1,
    LegalTransactionMeeting: '',
    Meeting_at: '',
  } as ILegalTransactionMeeting;
};

export const LegalTransactionMeetingOverview: React.FC<IProps> = props => {
  const [addNew, setAddNew] = useState(false);
  const [newObject, setNewObject] = useState(getNewMeeting(props.meetingArray));
  const [newObjectEmailArray, setNewObjectEmailArray] = useState<
    ILegalTransactionMeetingEmail[]
  >([]);

  const handleAddNew = () => {
    const localNewObject = getNewMeeting(props.meetingArray);
    const localNewObjectEmailArray: ILegalTransactionMeetingEmail[] = [];

    let tmpId = -1;

    if (props.meetingEmailArray.length > 0) {
      const tmpIdArray =
        Math.min(
          ...props.meetingEmailArray.map(x => x.idLegalTransactionMeetingEmail)
        ) - 1;

      if (tmpIdArray <= tmpId) {
        tmpId = tmpIdArray - 1;
      }
    }

    props.meetingEmailTemplateArray
      .filter(x => x.idLegalTransactionType === props.idLegalTransactionType)
      .map((emailTempObject, idx) => {
        const newObject: ILegalTransactionMeetingEmail = {
          ...emailTempObject,
          idLegalTransaction: -1,
          idLegalTransactionMeeting: localNewObject.idLegalTransactionMeeting,
          idLegalTransactionMeetingEmail: tmpId - idx,
        };
        localNewObjectEmailArray.push(newObject);
      });

    setNewObject({ ...localNewObject });
    setNewObjectEmailArray([...localNewObjectEmailArray]);
    setAddNew(true);
  };

  const handleSave = () => {
    props.setMeetingArray([...props.meetingArray, newObject]);
    props.setMeetingEmailArray([
      ...props.meetingEmailArray,
      ...newObjectEmailArray,
    ]);
    setAddNew(false);
  };

  return (
    <>
      <Dialog
        open={addNew}
        onClose={() => setAddNew(false)}
        maxWidth='lg'
        fullWidth
      >
        <DialogTitle>Neuer Termin</DialogTitle>
        <DialogContent>
          <LegalTransactionMeetingEdit
            meetingObject={newObject}
            setMeetingObject={setNewObject}
            meetingEmailTemplateArray={props.meetingEmailTemplateArray}
            meetingEmailArray={newObjectEmailArray}
            setMeetingEmailArray={setNewObjectEmailArray}
            setAddNew={setAddNew}
            handleSave={handleSave}
          />
        </DialogContent>
      </Dialog>

      <Typography variant='h5'>
        Zukünftige Termine
        <Button
          variant='outlined'
          sx={{ float: 'right' }}
          onClick={handleAddNew}
        >
          Neuer Termin
        </Button>
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>Vom</TableCell>
            <TableCell>Bis</TableCell>
            <TableCell>Terminbezeichnung</TableCell>
            <TableCell>(Geplante) E-Mails</TableCell>
            <TableCell>Aktionen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.meetingArray.filter(x => new Date(x.Meeting_at) >= new Date())
            .length === 0 ? (
            <TableRow>
              <TableCell colSpan={6}>
                <i>Keine offene Termine.</i>
              </TableCell>
            </TableRow>
          ) : (
            props.meetingArray
              .filter(x => new Date(x.Meeting_at) >= new Date())
              .sort((a, b) =>
                new Date(a.Meeting_at) < new Date(b.Meeting_at) ? -1 : 1
              )
              .map(x => (
                <LegalTransactionMeetingRow
                  key={`idLegalTransactionMeeting-${x.idLegalTransactionMeeting}`}
                  meetingObject={x}
                  meetingArray={props.meetingArray}
                  setMeetingArray={props.setMeetingArray}
                  meetingEmailArray={props.meetingEmailArray}
                  setMeetingEmailArray={props.setMeetingEmailArray}
                  meetingEmailTemplateArray={props.meetingEmailTemplateArray}
                />
              ))
          )}
        </TableBody>
      </Table>

      <Typography sx={{ mt: 5 }} variant='h6'>
        Zurückliegende Termine
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Datum</TableCell>
            <TableCell>Bezeichnung</TableCell>
            <TableCell>(Versendete) E-Mails</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.meetingArray.filter(x => new Date(x.Meeting_at) <= new Date())
            .length === 0 ? (
            <TableRow>
              <TableCell colSpan={4}>
                <i>Keine zurückliegende Termine vorhanden.</i>
              </TableCell>
            </TableRow>
          ) : (
            props.meetingArray
              .filter(x => new Date(x.Meeting_at) <= new Date())
              .sort((a, b) =>
                new Date(a.Meeting_at) < new Date(b.Meeting_at) ? -1 : 1
              )
              .map(x => (
                <TableRow>
                  <TableCell>
                    {new Date(
                      castWithZeroTimezone(x.Meeting_at!)!
                    ).toLocaleString()}
                  </TableCell>
                  <TableCell>{x.LegalTransactionMeeting}</TableCell>
                  <TableCell>
                    {
                      props.meetingEmailArray.filter(
                        y =>
                          y.idLegalTransactionMeeting ===
                          x.idLegalTransactionMeeting
                      ).length
                    }
                  </TableCell>
                </TableRow>
              ))
          )}
        </TableBody>
      </Table>
    </>
  );
};
