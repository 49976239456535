import React, { useRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Owner } from '../Components/OwnerRow';
import { GridBaseData } from '../../../../Person/GridBaseData';
import { IPerson } from '../../../../Interfaces/IPerson';
import { getNewPerson } from '../../../../services/dialog.service';

interface IProps {
  personArray: IPerson[];
  setPersonArray: Function;
}

export const Owners: React.FC<IProps> = ({ personArray, setPersonArray }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [newPerson, setNewPerson] = React.useState<IPerson>(getNewPerson);
  const formRef = useRef<HTMLFormElement>(null);

  const createNew = () => {
    setNewPerson(getNewPerson);
    setIsOpen(true);
  };

  const handleSave = () => {
    if (newPerson.idPerson === -1) {
      setPersonArray([...personArray, newPerson]);
    }
    setIsOpen(false);
  };

  /**
   * Form Function
   */
  const handleOnSave = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      handleSave();
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements).reverse();

      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        maxWidth='lg'
        fullWidth
      >
        <DialogContent>
          <>
            <Typography variant='h5'>Eigentümer hinzufügen</Typography>
            <form ref={formRef}>
              <GridBaseData
                personObject={newPerson}
                personArray={personArray}
                setPersonObject={setNewPerson}
                showDeathSettings={false}
                forceDeathSettings={false}
                titleAdress='Anschrift'
                setIsDeath={() => {}}
                disableEdit={false}
                forceAdress={true}
                disableDeathDateWarning={true}
                disableContactInformations
              />
            </form>
          </>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setIsOpen(false)}>
            Abbruch
          </Button>
          <Button variant='contained' onClick={handleOnSave}>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={'25%'}>Nr.:</TableCell>
            <TableCell width={'25%'}>Vorname</TableCell>
            <TableCell width={'25%'}>Nachname</TableCell>
            <TableCell width={'25%'}>Aktionen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Owner
            personArray={personArray}
            setPersonArray={setPersonArray}
          ></Owner>
        </TableBody>
        <Button
          variant='contained'
          onClick={() => createNew()}
          sx={{ float: 'left' }}
        >
          Eigentümer Hinzufügen
        </Button>
      </Table>
    </>
  );
};
