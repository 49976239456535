import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILegalRequirementState } from '../../Interfaces/ILegalRequirementState';

const initialState = {
  storeLegalRequirementState: [] as ILegalRequirementState[],
};

const sliceLegalRequirementState = createSlice({
  name: 'LEGALREQUIREMENTSTATE',
  initialState,
  reducers: {
    setLegalRequirementState: (
      state,
      action: PayloadAction<ILegalRequirementState[]>
    ) => {
      state.storeLegalRequirementState = [...action.payload];
    },
    addUpdateLegalRequirementState: (
      state,
      action: PayloadAction<ILegalRequirementState>
    ) => {
      const testObject = state.storeLegalRequirementState.find(
        x =>
          x.idLegalRequirementState === action.payload.idLegalRequirementState
      );

      if (testObject) {
        state.storeLegalRequirementState = [
          ...state.storeLegalRequirementState.map(x =>
            x.idLegalRequirementState === action.payload.idLegalRequirementState
              ? action.payload
              : x
          ),
        ];
      } else {
        state.storeLegalRequirementState = [
          action.payload,
          ...state.storeLegalRequirementState,
        ];
      }
    },
  },
});
export const { setLegalRequirementState, addUpdateLegalRequirementState } =
  sliceLegalRequirementState.actions;
export default sliceLegalRequirementState.reducer;
