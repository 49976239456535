import React, { useState } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { MarriageRow } from './MarriageRow';
import { IInheritHasPerson } from '../../../../../Interfaces/IPerson';
import { IPersonHasDocument } from '../../../../../Interfaces/IPersonHasDocument';
import { IMarriage } from '../../../../../Interfaces/IMarriage';
import { IInherit } from '../../../../../Interfaces/IInherit';

interface IProps {
  inheritObject: IInherit;
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  marriageArray: IMarriage[];
  setMarriageArray: Function;
}

export const MarriageOverview: React.FC<IProps> = ({
  inheritObject,
  personArray,
  setPersonArray,
  personHasDocument,
  setPersonHasDocument,
  marriageArray,
  setMarriageArray,
}) => {
  const [openMarriageDialog, setOpenMarriageDialog] = useState(false);
  return (
    <>
      <Typography variant='h5'>Auflistung der Ehe(n)</Typography>

      <Typography variant='body1' sx={{ mt: 2, mb: 3 }}>
        Die Erfassung aller Ehen ist wichtig, um Ansprüche von Ex-Ehepartnern,
        Witwen oder Witwern sowie möglichen Kindern aus vorherigen Ehen zu
        klären.
        <br />
        Auf diese Weise wird sichergestellt, dass alle berechtigten Erben ihren
        gerechten Anteil erhalten.
      </Typography>

      {openMarriageDialog && (
        <MarriageRow
          key='marriage-new'
          idMarriage={-1}
          index={0}
          inheritObject={inheritObject}
          marriageArray={marriageArray}
          personArray={personArray}
          personHasDocument={personHasDocument}
          setMarriageArray={setMarriageArray}
          setPersonArray={setPersonArray}
          setPersonHasDocument={setPersonHasDocument}
          setIsOpenNew={setOpenMarriageDialog}
        />
      )}

      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={'20%'}>Nr.</TableCell>
            <TableCell width={'20%'}>Verheiratet</TableCell>
            <TableCell width={'20%'}>Geschieden</TableCell>
            <TableCell width={'20%'}>Name</TableCell>
            <TableCell width={'20%'}>Aktionen</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {marriageArray
            .filter(x => x.Person1_idPerson === personArray[0].idPerson)
            .sort((a, b) =>
              new Date(a.DateOfMarriage!) > new Date(b.DateOfMarriage!) ? 1 : -1
            )
            .map((x, i) => (
              <MarriageRow
                key={`marriage-${x.idMarriage}`}
                idMarriage={x.idMarriage}
                index={i}
                inheritObject={inheritObject}
                marriageArray={marriageArray}
                personArray={personArray}
                personHasDocument={personHasDocument}
                setMarriageArray={setMarriageArray}
                setPersonArray={setPersonArray}
                setPersonHasDocument={setPersonHasDocument}
              />
            ))}
        </TableBody>
        <Box mt={2}>
          <Button
            variant='contained'
            onClick={() => setOpenMarriageDialog(true)}
            sx={{ float: 'left' }}
          >
            Ehe(n) hinzufügen
          </Button>
        </Box>
      </Table>
    </>
  );
};
