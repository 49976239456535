import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TableCell,
  TableRow,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import { ILegalRole } from '../../../Interfaces/ILegalRole';
import { ILegalRole_canInvite } from '../../../Interfaces/ILegalRole_canInvite';

interface IProps {
  legalRoleObject: ILegalRole;
  legalRoleArray: ILegalRole[];
  legalRoleCanInviteArray: ILegalRole_canInvite[];
  setLegalRoleCanInviteArray: Function;
}

export const LegalRoleCanInviteRow: React.FC<IProps> = props => {
  const [legalRoleCanInviteArray, setLegalRoleCanInviteArray] = useState(
    props.legalRoleCanInviteArray.filter(
      x => x.From_idLegalRole === props.legalRoleObject.idLegalRole
    )
  );
  const [editLegalRoleCanInviteArray, setEditLegalRoleCanInviteArray] =
    useState(legalRoleCanInviteArray);
  //
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const handleOpen = () => {
    setEditLegalRoleCanInviteArray([...legalRoleCanInviteArray]);
    setIsOpenEdit(true);
  };

  const handleClick = (idLegalRole: number) => {
    const testObject = editLegalRoleCanInviteArray.find(
      x => x.To_idLegalRole === idLegalRole
    );

    if (testObject === undefined) {
      setEditLegalRoleCanInviteArray([
        ...editLegalRoleCanInviteArray,
        {
          From_idLegalRole: props.legalRoleObject.idLegalRole,
          To_idLegalRole: idLegalRole,
        } as ILegalRole_canInvite,
      ]);
    } else {
      setEditLegalRoleCanInviteArray([
        ...editLegalRoleCanInviteArray.filter(
          x => x.To_idLegalRole !== idLegalRole
        ),
      ]);
    }
  };

  const handleSave = () => {
    props.setLegalRoleCanInviteArray([
      ...props.legalRoleCanInviteArray.filter(
        x => x.From_idLegalRole !== props.legalRoleObject.idLegalRole
      ),
      ...editLegalRoleCanInviteArray,
    ]);
    setLegalRoleCanInviteArray([...editLegalRoleCanInviteArray]);
    setIsOpenEdit(false);
  };

  return (
    <>
      <Dialog open={isOpenEdit} onClose={() => setIsOpenEdit(false)}>
        <DialogTitle>
          Einladeerlaubnis: {props.legalRoleObject.LegalRole}
        </DialogTitle>
        <DialogContent>
          <>
            Die Rolle <i>{props.legalRoleObject.LegalRole}</i> darf eingeladene
            Entitäten die folgenden Rollen zuweisen:
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            >
              <>
                {props.legalRoleArray.map(x => (
                  <ListItem key={`to-${x.idLegalRole}`} disablePadding>
                    <ListItemButton
                      role={undefined}
                      onClick={() => handleClick(x.idLegalRole)}
                      dense
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge='start'
                          checked={
                            editLegalRoleCanInviteArray.find(
                              y => y.To_idLegalRole === x.idLegalRole
                            ) !== undefined
                          }
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            'aria-labelledby': String(x.idLegalRole),
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={`to-text-${x.idLegalRole}`}
                        primary={x.LegalRole}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </>
            </List>
          </>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setIsOpenEdit(false)}>
            Abbruch
          </Button>
          <Button variant='contained' onClick={handleSave}>
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>

      <TableRow>
        <TableCell>{props.legalRoleObject.LegalRole}</TableCell>
        <TableCell>
          {props.legalRoleArray
            .filter(
              x =>
                legalRoleCanInviteArray
                  .map(y => y.To_idLegalRole)
                  .indexOf(x.idLegalRole) > -1
            )
            .map(x => x.LegalRole)
            .join(', ')}
        </TableCell>
        <TableCell sx={{ width: 100 }}>
          <IconButton onClick={handleOpen}>
            <Edit />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
