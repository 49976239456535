import { Search } from '@mui/icons-material';
import L from 'leaflet';

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from 'react-leaflet';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import { IRealEstate } from '../../../../Interfaces/IRealEstate';
import { IPostcode } from '../../../../Interfaces/IPostcode';
import { getFetch } from '../../../../hooks/useFetch';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

const center = {
  lat: 52.7597,
  lng: 13.25391,
};

interface IPropsDraggaleMarker {
  postition: { lat: number; lng: number };
  setPostition: Function;

  setStreet: Function;
  setStreetNr: Function;
  setPostcode: Function;
  city: string;
  setCity: Function;
  setLat: Function;
  setLong: Function;
  setPostcodeObject: Function;
}

interface IPropsMapSearch {
  options?: readonly IPostcode[];
  realEstate: IRealEstate;
  setRealEstate: Function;
}

const DraggableMarker: React.FC<IPropsDraggaleMarker> = props => {
  const [draggable, setDraggable] = useState(true);
  const markerRef = useRef<any | null>(null);
  //
  const [postcode, setPostcode] = useState('');

  const map = useMapEvents({
    click: e => {
      props.setPostition({ lat: e.latlng.lat, lng: e.latlng.lng });
    },
  });

  useEffect(() => {
    props.setLat(props.postition.lat);
    props.setLong(props.postition.lng);

    fetch(
      `https://nominatim.openstreetmap.org/reverse?lat=${props.postition.lat}&lon=${props.postition.lng}&format=json`,
      {
        headers: {
          'User-Agent': 'DiNoPublic',
        },
      }
    )
      .then(res => res.json())
      .then(res => {
        props.setStreet(res.address.road || '');
        props.setStreetNr(res.address.house_number || '');
        props.setPostcode(res.address.postcode);
        setPostcode(res.address.postcode);

        if ('town' in res.address) {
          props.setCity(res.address.town);
        } else {
          props.setCity(res.address.city);
        }
      });
  }, [props.postition]);

  const getHandler = (localPostcodeArray: IPostcode[]) => {
    const testObject = localPostcodeArray.find(
      x => x.City.toLocaleLowerCase() === props.city.toLocaleLowerCase()
    );

    if (testObject !== undefined) {
      props.setPostcodeObject(testObject);
    } else if (localPostcodeArray.length > 0) {
      props.setPostcodeObject(localPostcodeArray[0]);
    }
  };

  useEffect(() => {
    if (postcode !== '' && props.city !== '') {
      getFetch(`/postcode/public/`, postcode, getHandler);
    }
  }, [postcode, props.city]);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          props.setPostition(marker.getLatLng()!);
        }
      },
    }),
    []
  );
  const toggleDraggable = useCallback(() => {
    setDraggable(d => !d);
  }, []);

  return (
    <Marker
      draggable={draggable}
      eventHandlers={eventHandlers}
      position={props.postition}
      ref={markerRef}
    >
      <Popup minWidth={90}>
        <>Sie können den Markierer auf die gewünschte Fläche bewegen</>
      </Popup>
    </Marker>
  );
};

export const MapSearch: React.FC<IPropsMapSearch> = props => {
  const [position, setPostition] = useState<{ lat: number; lng: number }>({
    lat: 52.7597,
    lng: 13.25391,
  });

  const [street, setStreet] = useState('');
  const [streetNr, setStreetNr] = useState('');
  const [postcode, setPostcode] = useState('');
  const [city, setCity] = useState('');
  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);
  //
  const [postcodeObject, setPostcodeObject] = useState<IPostcode | null>(null);
  //
  const [searchTerm, setSearchTerm] = useState('');
  const [reload, setReload] = useState(0);

  const handleAdd = () => {
    const updatedObject = {
      ...props.realEstate,
      Street: street,
      StreetAditional: null,
      StreetNr: streetNr,
      Longitude: lat,
      Latitude: long,
      City: city,
      Postcode: postcode,
      idPostcode: props.options?.find(x => x.Postcode === postcode)?.idPostcode,
    } as IRealEstate;

    props.setRealEstate(updatedObject);
  };

  const searchByText = (e: React.FormEvent) => {
    e.preventDefault();

    if (searchTerm !== '') {
      fetch(
        `https://nominatim.openstreetmap.org/search?q=${searchTerm}&format=json`,
        {
          headers: {
            'User-Agent': 'DiNoPublic',
          },
        }
      )
        .then(res => res.json())
        .then(res => {
          let localObject = res;

          if (Array.isArray(res) && res.length > 0) {
            localObject = res[0];
          }

          if ('lat' in localObject && 'lon' in localObject) {
            setPostition({ lat: localObject.lat, lng: localObject.lon });
            setReload(reload + 1);
          }
        });
      setSearchTerm('');
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <Button onClick={handleAdd} sx={{ float: 'right' }} variant='contained'>
          Übernehmen
        </Button>
      </Grid>

      <Grid item sm={12}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <b>Straße:</b>
              </TableCell>
              <TableCell>
                <i>{street}</i>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <b>Nr:</b>
              </TableCell>
              <TableCell>
                <i>{streetNr}</i>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <b>Stadt:</b>
              </TableCell>
              <TableCell>
                <i>{postcode}</i>, <i>{city}</i>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <b>Koordinaten:</b>
              </TableCell>
              <TableCell>
                <i>{lat}</i>, <i>{long}</i>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>

      <Grid item xs={12}>
        <form onSubmit={searchByText}>
          <Box sx={{ display: 'flex', mt: 5 }}>
            <TextField
              label='Suchen'
              value={searchTerm}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSearchTerm(event.target.value)
              }
              fullWidth
              size='small'
            />

            <IconButton type='submit'>
              <Search />
            </IconButton>
          </Box>
        </form>
      </Grid>

      <Grid item xs={12} sm={8}>
        <MapContainer
          key={`MapContainer-${reload}`}
          style={{
            height: 400,
            width: 800,
            alignContent: 'center',
            alignItems: 'center',
          }}
          center={position}
          zoom={16}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy;  DiNo von Holland & Kreußlein | Karteninformationen &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />
          <DraggableMarker
            postition={position}
            setPostition={setPostition}
            setStreet={setStreet}
            setStreetNr={setStreetNr}
            setPostcode={setPostcode}
            setCity={setCity}
            city={city}
            setLat={setLat}
            setLong={setLong}
            setPostcodeObject={setPostcodeObject}
          />
        </MapContainer>
      </Grid>
    </Grid>
  );
};
