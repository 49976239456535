import React, { useState } from 'react';
import { ICompanyFormation } from '../../../../Interfaces/ICompanyFormation';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import BaseInformation from '../BaseInformation';
import { ICompanyFormationAnswer } from '../../../../Interfaces/ICompanyFormationAnswer';
import { CompanyFormationAnswerOverview } from './CompanyFormationAnswerOverview';

interface IProps {
  companyFormationObject: ICompanyFormation;
  setcompanyFormationObject: Function;
  companyFormationAnswerArray: ICompanyFormationAnswer[];
  setCompanyFormationAnswerArray: Function;
}

export const DialogEditBaseInformation: React.FC<IProps> = props => {
  const [editCompanyFormationObject, setEditCompanyFormationObject] = useState(
    props.companyFormationObject
  );
  const [editCompanyFormationAnswerArray, setEditCompanyFormationAnswerArray] =
    useState(props.companyFormationAnswerArray);
  //
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setEditCompanyFormationObject(props.companyFormationObject);
    setEditCompanyFormationAnswerArray(props.companyFormationAnswerArray);
    setIsOpen(true);
  };

  const getIsDisabled = () => {
    return false;
  };

  const handleSave = () => {
    props.setcompanyFormationObject(editCompanyFormationObject);
    props.setCompanyFormationAnswerArray(editCompanyFormationAnswerArray);
    setIsOpen(false);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen} maxWidth='md' fullWidth>
        <DialogContent>
          <BaseInformation
            companyFormationObject={editCompanyFormationObject}
            setCompanyFormationObject={setEditCompanyFormationObject}
          />
          <CompanyFormationAnswerOverview
            companyFormation={editCompanyFormationObject}
            companyFormationAnswerArray={editCompanyFormationAnswerArray}
            setCompanyFormationAnswerArray={setEditCompanyFormationAnswerArray}
          />
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' onClick={() => setIsOpen(false)}>
            Abbruch
          </Button>
          <Button variant='contained' onClick={handleSave}>
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>

      <Button variant='outlined' sx={{ float: 'right' }} onClick={handleOpen}>
        Editieren
      </Button>
    </>
  );
};
