import React, { useEffect, useState } from 'react';
import { IDocument } from '../Interfaces/IDocument';
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useGetContacts, useGetDownloadedDocuments } from '../hooks/data';

interface IProps {
  documentObject: IDocument;
}

export const DocumentDownloaded: React.FC<IProps> = ({ documentObject }) => {
  const { contacts, isLoadingContacts } = useGetContacts();
  const { downloadedDocuments, isLoadingDownloadedDocuments } =
    useGetDownloadedDocuments();

  const [uniquideIdContactArray, setUniquideIdContactArray] = useState<
    number[]
  >([]);

  const timeStringWithoutTimezone = (currentDate: Date) => {
    const userTimezoneOffset = currentDate.getTimezoneOffset() * 60000;
    return new Date(
      currentDate.getTime() + userTimezoneOffset
    ).toLocaleString();
  };

  useEffect(() => {
    if (contacts && downloadedDocuments) {
      const tmpArray = [
        ...downloadedDocuments
          .filter(document => document.idDocument === documentObject.idDocument)
          .map(document => document.idContact),
      ];
      const unique = tmpArray.filter(
        (value, index, array) => array.indexOf(value) === index
      );
      setUniquideIdContactArray([...unique]);
    }
  }, [contacts, downloadedDocuments]);

  if (isLoadingContacts || isLoadingDownloadedDocuments) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        height='30vh'
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!contacts || !downloadedDocuments) {
    return <>Error</>;
  }

  return (
    <>
      <Typography>
        Das Dokument <i>{documentObject.FileName}</i> wurde insgesamt{' '}
        {
          downloadedDocuments.filter(
            document => document.idDocument === documentObject.idDocument
          ).length
        }{' '}
        runtergeladen:
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Mandant</TableCell>
              <TableCell>Frühster Download</TableCell>
              <TableCell>Spätester Download</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {uniquideIdContactArray.map(localIdContact => {
              const currentContact = contacts.find(
                contact => contact.idContact === localIdContact
              );
              const ownDownloaded = downloadedDocuments
                .filter(
                  document => document.idDocument === documentObject.idDocument
                )
                .filter(document => document.idContact === localIdContact);

              return (
                <TableRow>
                  <TableCell>
                    {currentContact?.FirstName} {currentContact?.LastName}
                  </TableCell>
                  <TableCell>
                    {ownDownloaded.length > 0 ? (
                      timeStringWithoutTimezone(
                        new Date(ownDownloaded[0].Timestamp)
                      )
                    ) : (
                      <>k.A.</>
                    )}
                  </TableCell>
                  <TableCell>
                    {ownDownloaded.length > 0 ? (
                      timeStringWithoutTimezone(
                        new Date(
                          ownDownloaded[ownDownloaded.length - 1].Timestamp
                        )
                      )
                    ) : (
                      <>k.A.</>
                    )}
                  </TableCell>
                  <TableCell>{ownDownloaded.length}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Typography>
    </>
  );
};
