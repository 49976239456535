import React, { useEffect, useState } from 'react';
import { IContact, ILegalTransactionHasContact } from '../Interfaces/IContact';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  deDE,
} from '@mui/x-data-grid';
import { ClientsEdit, getEmptyCompany } from '../Clients/ClientsEdit';
import { ILegalRole } from '../Interfaces/ILegalRole';
import { useParams } from 'react-router-dom';

interface IProps {
  allContactArray: IContact[];
  legalRoleArray: ILegalRole[];
  setContactArray: Function;
  chosenContactArray: ILegalTransactionHasContact[];
  setChosenContactArray: Function;
}

// Grid für die Mandanten suche
const columns: GridColDef[] = [
  { field: 'idContact', headerName: 'Nr.', flex: 1 },
  { field: 'FirstName', headerName: 'Vorname', flex: 1 },
  { field: 'LastName', headerName: 'Nachname', flex: 1 },
  { field: 'Email', headerName: 'E-Mail', flex: 1 },
];

export const LegalTransactionAddClientNew: React.FC<IProps> = props => {
  const [isOpenAddNew, setIsOpenAddNew] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredContacts, setFilteredContacts] = useState<IContact[]>([]);
  const params = useParams();
  const [idContact, setidContact] = useState<number | null>(
    params.idContact == undefined ? null : Number(params.idContact)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [wasSaved, setWasSaved] = useState(false);
  const [wasSavedSuccesfully, setWasSavedSuccesfully] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([
      ...props.chosenContactArray.map(x => x.idContact),
    ]);

  const [defaultIdLegalRole, setDefaultIdLegalRole] = useState<number | null>(
    null
  );
  useEffect(() => {
    if (props.legalRoleArray.length < 1) {
      setDefaultIdLegalRole(null);
    } else {
      setDefaultIdLegalRole(props.legalRoleArray[0].idLegalRole);
    }
  }, [props.legalRoleArray]);

  useEffect(() => {
    if (wasSavedSuccesfully && props.allContactArray.length > 0) {
      // Den neu hinzugefügten Kontakt finden
      const lastAddedContact = props.allContactArray[0];
      const tmpNewArray: ILegalTransactionHasContact = {
        ...lastAddedContact,
        idLegalRole: defaultIdLegalRole,
        CompanyObject: getEmptyCompany(),
        LegalTransactionArray: [],
      };

      props.setChosenContactArray([tmpNewArray, ...props.chosenContactArray]);

      setRowSelectionModel(prevSelection => [
        lastAddedContact.idContact, // ID des neuen Kontakts hinzufügen
        ...prevSelection,
      ]);
      setSearchTerm('');
      setWasSavedSuccesfully(false);
    }
  }, [wasSavedSuccesfully]);

  // open Mandanten zuordnen Dialog
  const handleOpen = () => {
    setRowSelectionModel([...props.chosenContactArray.map(x => x.idContact)]);
    setIsOpenAddNew(true);
  };

  const handleClose = () => {
    setSearchTerm('');
    setIsOpenAddNew(false);
  };
  // Add new Mandanten Function open
  const handleOpenNewContact = () => {
    setidContact(idContact != null && idContact == -1 ? idContact - 1 : -1);
  };

  useEffect(() => {
    const lowerSearchTerm = searchTerm.toLowerCase();
    if (!searchTerm) {
      setFilteredContacts([
        ...props.chosenContactArray.filter(x => x.idContact < 0),
        ...props.allContactArray,
      ]);
    } else {
      const filterFn = (x: IContact) =>
        x.FirstName.toLowerCase().includes(lowerSearchTerm) ||
        x.LastName.toLowerCase().includes(lowerSearchTerm) ||
        x.Email.toLowerCase().includes(lowerSearchTerm);

      setFilteredContacts([
        ...props.chosenContactArray.filter(x => x.idContact < 0 && filterFn(x)),
        ...props.allContactArray.filter(filterFn),
      ]);
    }
  }, [searchTerm, props.chosenContactArray.length]);

  const handleAdd = () => {
    const selectedContacts = props.allContactArray.filter(contact =>
      rowSelectionModel.includes(contact.idContact)
    );

    const tmpNewArray: ILegalTransactionHasContact[] = selectedContacts.map(
      contact => ({
        ...contact,
        idLegalRole: defaultIdLegalRole,
        CompanyObject: getEmptyCompany(),
        LegalTransactionArray: [],
      })
    );

    props.setChosenContactArray([...tmpNewArray]);
    setSearchTerm('');
    handleClose();
  };

  return (
    <>
      <Dialog
        open={idContact !== null}
        onClose={() => setidContact(null)}
        maxWidth='xl'
        fullWidth
      >
        <DialogTitle variant='h4'>
          Neuer Mandant
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity='info'>
                <AlertTitle>Information</AlertTitle>
                Wenn Sie über diesen Vorgang einen Mandanten anlegen, wird
                dieser sofort erstellt und dem Vorgang hinzugefügt
              </Alert>
            </Grid>
          </Grid>
        </DialogTitle>

        <ClientsEdit
          key={idContact}
          idContact={idContact}
          setidContact={setidContact}
          contactArray={props.allContactArray}
          setContactArray={props.setContactArray}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setWasSaved={setWasSaved}
          setWasSavedSuccesfully={setWasSavedSuccesfully}
          handleAfterRemove={() => {}}
          handleClose={() => setidContact(null)}
        />
      </Dialog>

      <Dialog open={isOpenAddNew} onClose={handleClose} maxWidth='lg' fullWidth>
        <DialogTitle variant='h4'>Mandanten zuordnen</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex' }}>
            <TextField
              size='small'
              sx={{ mt: 2 }}
              label='Suche'
              value={searchTerm}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSearchTerm(event.target.value)
              }
              fullWidth
            />
            <IconButton
              onClick={handleOpenNewContact}
              sx={{ float: 'right', mt: 1, ml: 2 }}
            >
              <Add />
            </IconButton>
          </Box>

          <DataGrid
            sx={{ mt: 2 }}
            autoHeight
            checkboxSelection
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            rows={filteredContacts}
            onRowSelectionModelChange={newRowSelectionModel => {
              if (searchTerm === '') {
                setRowSelectionModel(Array.from(new Set(newRowSelectionModel)));
              } else {
                const filteredSelection = rowSelectionModel.filter(
                  id =>
                    !filteredContacts.some(contact => contact.idContact === id)
                );
                const updatedSelection = [
                  ...filteredSelection,
                  ...newRowSelectionModel,
                ];
                setRowSelectionModel(Array.from(new Set(updatedSelection)));
              }
            }}
            rowSelectionModel={rowSelectionModel}
            columns={columns}
            getRowId={row => row.idContact}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 15,
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='contained'>
            Abbrechen
          </Button>
          <Button onClick={handleAdd} variant='contained'>
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>
      <IconButton onClick={handleOpen} sx={{ float: 'right' }}>
        <Add />
      </IconButton>
    </>
  );
};
