import React, { useState } from 'react';
import { IMeetingEmailTemplate } from '../../Interfaces/IMeetingEmailTemplate';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  deDE,
} from '@mui/x-data-grid';
import { Add, Check, Clear } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { MeetingEmailTemplateEdit } from './MeetingEmailTemplateEdit';

interface IProps {
  meetingEmailTemplateArray: IMeetingEmailTemplate[];
  setMeetingEmailTemplateArray: Function;
}

const columns: GridColDef[] = [
  {
    field: 'Title',
    headerName: 'Bezeichnung',
    flex: 1,
  },
  {
    field: 'sendOnCreation',
    headerName: 'Terminerstellung',
    width: 150,
    renderCell: (params: GridRenderCellParams) =>
      Boolean(params.row.sendOnCreation) ? <Check /> : <Clear />,
  },
  {
    field: 'sendOnReschedule',
    headerName: 'Terminänderung',
    width: 150,
    renderCell: (params: GridRenderCellParams) =>
      Boolean(params.row.sendOnReschedule) ? <Check /> : <Clear />,
  },
  {
    field: 'sendOnCancelling',
    headerName: 'Terminabsage',
    width: 150,
    renderCell: (params: GridRenderCellParams) =>
      Boolean(params.row.sendOnCancelling) ? <Check /> : <Clear />,
  },
  {
    field: 'DaysBeforeDate',
    headerName: 'Tage vor Termin',
    width: 150,
  },
  {
    field: 'sendWithCalenderFile',
    headerName: 'Kalenderdatei',
    width: 150,
    renderCell: (params: GridRenderCellParams) =>
      Boolean(params.row.sendWithCalenderFile) ? <Check /> : <Clear />,
  },
  {
    field: 'allowDataUpload',
    headerName: 'Datenupload',
    width: 150,
    renderCell: (params: GridRenderCellParams) =>
      Boolean(params.row.allowDataUpload) ? <Check /> : <Clear />,
  },
];

export const MeetingEmailTemplateOverview: React.FC<IProps> = props => {
  //const [meetingEmailTemplateArray,setMeetingEmailTemplateArray] = useState(props.meetingEmailTemplateArray);
  const [isNew, setIsNew] = useState(false);
  const [idMeetingEmailTemplate, setIdMeetingEmailTemplate] = useState<
    number | null
  >(null);

  return (
    <>
      <Dialog
        open={isNew}
        onClose={() => setIsNew(false)}
        maxWidth='lg'
        fullWidth
      >
        <DialogTitle>Neue Termin-E-Mail</DialogTitle>
        <DialogContent>
          {isNew && (
            <MeetingEmailTemplateEdit
              idMeetingEmailTemplate={-1}
              setIdMeetingEmailTemplate={() => {}}
              isNew={isNew}
              setIsNew={setIsNew}
              meetingEmailTemplateArray={props.meetingEmailTemplateArray}
              setMeetingEmailTemplateArray={props.setMeetingEmailTemplateArray}
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={idMeetingEmailTemplate !== null}
        onClose={() => setIdMeetingEmailTemplate(null)}
        maxWidth='lg'
        fullWidth
      >
        <DialogTitle>Termin-E-Mail bearbeiten</DialogTitle>
        <DialogContent>
          {idMeetingEmailTemplate !== null && (
            <MeetingEmailTemplateEdit
              idMeetingEmailTemplate={idMeetingEmailTemplate}
              setIdMeetingEmailTemplate={setIdMeetingEmailTemplate}
              isNew={false}
              setIsNew={() => {}}
              meetingEmailTemplateArray={props.meetingEmailTemplateArray}
              setMeetingEmailTemplateArray={props.setMeetingEmailTemplateArray}
            />
          )}
        </DialogContent>
      </Dialog>

      <Typography variant='h6' sx={{ mt: 5 }}>
        Termin-E-Mails
        <IconButton
          sx={{ float: 'right' }}
          onClick={() => {
            setIsNew(true);
          }}
        >
          <Add />
        </IconButton>
      </Typography>
      <br />

      <DataGrid
        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
        rows={props.meetingEmailTemplateArray}
        columns={columns}
        getRowId={row => row.idMeetingEmailTemplate}
        onRowClick={(params, event, details) =>
          setIdMeetingEmailTemplate(Number(params.id))
        }
        autoHeight
      />
    </>
  );
};
