import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  IAdminstrativeUnit,
  ICourt,
  ICourtAdminstrativeUnitAssignment,
  ICourtCourtRole,
  ICourtRole,
  IFederalState,
} from '../Interfaces/IAemter';
import { customFetcher } from '../hooks/useFetch';
import type { IPostcode } from '../Interfaces/IPostcode';

export const useAdministrativeUnits = () => {
  const {
    data: administrativeUnits,
    isLoading: isLoadingAdministrativeUnits,
    refetch: refetchAdministrativeUnits,
  } = useQuery<IAdminstrativeUnit[]>({
    queryKey: ['/administrativeunit'],
    queryFn: () => customFetcher({ endpoint: '/administrativeunit' }),
  });

  return {
    administrativeUnits,
    isLoadingAdministrativeUnits,
    refetchAdministrativeUnits,
  };
};

export const useFederalStates = () => {
  const {
    data: federalStates,
    isLoading: isLoadingFederalStates,
    refetch: refetchFederalStates,
  } = useQuery<IFederalState[]>({
    queryKey: ['/federalstate'],
    queryFn: () => customFetcher({ endpoint: '/federalstate' }),
  });

  return { federalStates, isLoadingFederalStates, refetchFederalStates };
};

export const useCourtCourtRoles = () => {
  const {
    data: courtCourtRoles,
    isLoading: isLoadingCourtCourtRoles,
    refetch: refetchCourtCourtRoles,
  } = useQuery<ICourtCourtRole[]>({
    queryKey: ['/courtcourtrole'],
    queryFn: () => customFetcher({ endpoint: '/courtcourtrole' }),
  });

  return { courtCourtRoles, isLoadingCourtCourtRoles, refetchCourtCourtRoles };
};

export const useCourtAdministrativeUnitAssignments = () => {
  const {
    data: courtAdministrativeUnitAssignments,
    isLoading: isLoadingCourtAdministrativeUnitAssignments,
    refetch: refetchCourtAdministrativeUnitAssignments,
  } = useQuery<ICourtAdminstrativeUnitAssignment[]>({
    queryKey: ['/courtadminstrativeunitassignment'],
    queryFn: () =>
      customFetcher({ endpoint: '/courtadminstrativeunitassignment' }),
  });

  return {
    courtAdministrativeUnitAssignments,
    isLoadingCourtAdministrativeUnitAssignments,
    refetchCourtAdministrativeUnitAssignments,
  };
};

export const useCourtRoles = () => {
  const {
    data: courtRoles,
    isLoading: isLoadingCourtRoles,
    refetch: refetchCourtRoles,
  } = useQuery<ICourtRole[]>({
    queryKey: ['/courtrole'],
    queryFn: () =>
      customFetcher({
        endpoint: '/courtrole',
      }),
  });

  return { courtRoles, isLoadingCourtRoles, refetchCourtRoles };
};

export const usePostCodes = () => {
  const { data: postcodes, isLoading: isLoadingPostcodes } = useQuery<
    IPostcode[]
  >({
    queryKey: ['postcode'],
    queryFn: () =>
      customFetcher({
        endpoint: '/postcode',
      }),
  });

  return { postcodes, isLoadingPostcodes };
};

export const useSearchPostcodes = ({
  searchTerm,
  initialData,
}: {
  searchTerm: string;
  initialData?: IPostcode[];
}) => {
  const { data: searchPostcodes, isLoading: isLoadingSearchPostcodes } =
    useQuery<IPostcode[]>({
      queryKey: ['postcode', searchTerm],
      queryFn: () =>
        customFetcher({
          endpoint: `/postcode/public/${searchTerm}`,
        }),
      enabled: !!searchTerm && searchTerm.length > 1,
      initialData: initialData || [],
    });

  return { searchPostcodes, isLoadingSearchPostcodes };
};

export const useCourts = () => {
  const {
    data: courts,
    isLoading: isLoadingCourts,
    refetch: refetchCourts,
  } = useQuery<ICourt[]>({
    queryKey: ['court'],
    queryFn: () =>
      customFetcher({
        endpoint: '/court',
      }),
  });

  return { courts, isLoadingCourts, refetchCourts };
};

/*export const useSearchCourts = ({
  searchTerm,
  initialData,
}: {
  searchTerm: string;
  initialData?: IPostcode[];
}) => {
  const { data: searchPostcodes, isLoading: isLoadingSearchPostcodes } =
    useQuery<IPostcode[]>({
      queryKey: ['court', searchTerm],
      queryFn: () =>
        customFetcher({
          endpoint: `/postcode/public/${searchTerm}`,
        }),
      enabled: !!searchTerm && searchTerm.length > 1,
      initialData: initialData || [],
    });

  return { searchPostcodes, isLoadingSearchPostcodes };
};*/

export const useDeleteCourtRole = () => {
  const { mutateAsync: deleteCourtRole, isPending: isPendingDeleteCourtRole } =
    useMutation({
      mutationFn: (id: number) =>
        customFetcher({
          endpoint: `/courtrole/${id}`,
          method: 'DELETE',
        }),
    });

  return { deleteCourtRole, isPendingDeleteCourtRole };
};

export const useDeleteAdministrativeUnit = () => {
  const { mutateAsync: deleteAdminUnit, isPending: isPendingDeleteAdminUnit } =
    useMutation({
      mutationFn: (id: number) =>
        customFetcher({
          endpoint: `/administrativeunit/${id}`,
          method: 'DELETE',
        }),
    });

  return { deleteAdminUnit, isPendingDeleteAdminUnit };
};

export const useCreateCourtAdminUnitAssignment = () => {
  const {
    mutateAsync: createCourtAdminUnitAssignment,
    isPending: isPendingCreateAdminUnitAssignment,
  } = useMutation({
    mutationFn: (assignment: ICourtAdminstrativeUnitAssignment) =>
      customFetcher({
        endpoint: '/courtadminstrativeunitassignment',
        method: 'POST',
        body: assignment,
      }),
  });

  return { createCourtAdminUnitAssignment, isPendingCreateAdminUnitAssignment };
};

export const useUpdateCourtAdminUnitAssignment = () => {
  const {
    mutateAsync: updateCourtAdminUnitAssignment,
    isPending: isPendingUpdateCourtAdminUnitAssignment,
  } = useMutation({
    mutationFn: (assignment: ICourtAdminstrativeUnitAssignment) =>
      customFetcher({
        endpoint: '/courtadminstrativeunitassignment',
        method: 'PUT',
        body: assignment,
      }),
  });

  return {
    updateCourtAdminUnitAssignment,
    isPendingUpdateCourtAdminUnitAssignment,
  };
};

export const useUpdateCourtAdminUnitAssignments = () => {
  const {
    mutateAsync: updateCourtAdminUnitAssignments,
    isPending: isPendingUpdateCourtAdminUnitAssignments,
  } = useMutation({
    mutationFn: (assignments: ICourtAdminstrativeUnitAssignment[]) =>
      customFetcher({
        endpoint: '/courtadminstrativeunitassignment/updateObjects',
        method: 'PUT',
        body: assignments,
      }),
  });

  return {
    updateCourtAdminUnitAssignments,
    isPendingUpdateCourtAdminUnitAssignments,
  };
};

export const useDeleteCourtAdminUnitAssignment = () => {
  const { mutateAsync: deleteCourtAdminstrativeUnitAssignment, isPending } =
    useMutation({
      mutationFn: (assignment: ICourtAdminstrativeUnitAssignment) =>
        customFetcher({
          endpoint: `/courtadminstrativeunitassignment/${assignment.idAssignment}`,
          method: 'DELETE',
        }),
    });

  return { deleteCourtAdminstrativeUnitAssignment, isPending };
};

export const useDeleteCourtCourtRoleAssignment = () => {
  const {
    mutateAsync: deleteCourtCourtRoleAssignment,
    isPending: isPendingDeleteCourtCourtRoleAssignment,
  } = useMutation({
    mutationFn: (courtRole: ICourtCourtRole) =>
      customFetcher({
        endpoint: `/courtcourtrole/${courtRole.idCourtRole}/${courtRole.idCourt}`,
        method: 'DELETE',
      }),
  });

  return {
    deleteCourtCourtRoleAssignment,
    isPendingDeleteCourtCourtRoleAssignment,
  };
};

export const useDeleteCourt = () => {
  const { mutateAsync: deleteCourt, isPending: isPendingDeleteCourt } =
    useMutation({
      mutationFn: (courtId: number) =>
        customFetcher({
          endpoint: `/court/${courtId}`,
          method: 'DELETE',
        }),
    });

  return { deleteCourt, isPendingDeleteCourt };
};

export const useCreateCourtRole = () => {
  const { isPending: isPendingCreateCourtRole, mutateAsync: createCourtRole } =
    useMutation({
      mutationFn: (courtRole: ICourtRole) =>
        customFetcher({
          endpoint: '/courtrole',
          method: 'POST',
          body: courtRole,
        }),
    });

  return { isPendingCreateCourtRole, createCourtRole };
};

export const useUpdateFederalState = () => {
  const {
    isPending: isPendingUpdateFederalState,
    mutateAsync: updateFederalState,
  } = useMutation({
    mutationFn: (updatedFederalState: IFederalState) =>
      customFetcher({
        endpoint: '/federalstate',
        method: 'PUT',
        body: updatedFederalState,
      }),
  });

  return { isPendingUpdateFederalState, updateFederalState };
};

export const useUpdateCourt = () => {
  const { isPending: isPendingUpdateCourt, mutateAsync: updateCourt } =
    useMutation({
      mutationFn: (court: ICourt) =>
        customFetcher({
          endpoint: '/court',
          method: 'PUT',
          body: court,
        }),
    });

  return { isPendingUpdateCourt, updateCourt };
};

export const useCreateCourt = () => {
  const { isPending: isPendingCreateCourt, mutateAsync: createCourt } =
    useMutation({
      mutationFn: (court: ICourt) =>
        customFetcher({
          endpoint: '/court',
          method: 'POST',
          body: court,
        }),
    });

  return { isPendingCreateCourt, createCourt };
};

export const useCreateCourtCourtRoleAssignment = () => {
  const {
    isPending: isPendingCreateCourtCourtRoleAssignment,
    mutateAsync: createCourtCourtRoleAssignment,
  } = useMutation({
    mutationFn: (courtRole: ICourtCourtRole) =>
      customFetcher({
        endpoint: '/courtcourtrole',
        method: 'POST',
        body: courtRole,
      }),
  });

  return {
    isPendingCreateCourtCourtRoleAssignment,
    createCourtCourtRoleAssignment,
  };
};

export const useCreateCourtCourtRoleAssignments = () => {
  const {
    isPending: isPendingCreateCourtCourtRoleAssignments,
    mutateAsync: createCourtCourtRoleAssignments,
  } = useMutation({
    mutationFn: (courtRole: ICourtCourtRole[]) =>
      customFetcher({
        endpoint: '/courtcourtrole/updateObjects',
        method: 'POST',
        body: courtRole,
      }),
  });

  return {
    isPendingCreateCourtCourtRoleAssignments,
    createCourtCourtRoleAssignments,
  };
};

export const useUpdateCourtCourtRoleAssignment = () => {
  const {
    isPending: isPendingUpdateCourtCourtRoleAssignment,
    mutateAsync: updateCourtCourtRoleAssignment,
  } = useMutation({
    mutationFn: (courtRole: ICourtCourtRole) =>
      customFetcher({
        endpoint: '/courtcourtrole',
        method: 'PUT',
        body: courtRole,
      }),
  });

  return {
    isPendingUpdateCourtCourtRoleAssignment,
    updateCourtCourtRoleAssignment,
  };
};

export const useUpdateAdminUnit = () => {
  const { isPending: isPendingUpdateAdminUnit, mutateAsync: updateAdminUnit } =
    useMutation({
      mutationFn: (updatedAdminUnit: IAdminstrativeUnit) =>
        customFetcher({
          endpoint: '/administrativeunit',
          method: 'PUT',
          body: updatedAdminUnit,
        }),
    });

  return { isPendingUpdateAdminUnit, updateAdminUnit };
};

export const useCreateAdminUnit = () => {
  const { isPending: isPendingCreateAdminUnit, mutateAsync: createAdminUnit } =
    useMutation({
      mutationFn: (adminUnit: IAdminstrativeUnit) =>
        customFetcher({
          endpoint: '/administrativeunit',
          method: 'POST',
          body: adminUnit,
        }),
    });

  return { isPendingCreateAdminUnit, createAdminUnit };
};
