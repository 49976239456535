import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useFetch } from '../hooks/useFetch';
import { IConfig } from '../Interfaces/IConfig';
import { CustomCircularProgress } from '../generic/CustomCircularProgress';

export const License: React.FC = () => {
  const [config, setConfig, wasSuccessfullyConfig] = useFetch<
    IConfig | undefined
  >('/config');

  if (config === undefined) {
    return <CustomCircularProgress />;
  } else {
    return (
      <>
        <Grid container>
          <Grid item sm={8}>
            <Typography variant='h5'>
              <img height={20} src='/dino.png' style={{ marginRight: 5 }} />
              DiNo-Edition
            </Typography>
            <table>
              <tbody>
                <tr>
                  <td>DiNo Pro</td>
                </tr>
                <tr>
                  <td>&#169; LeXtorByte UG (haftungsbeschränkt)</td>
                </tr>
              </tbody>
            </table>

            <Typography sx={{ mt: 5 }} variant='h5'>
              DiNo-Aktivierung
            </Typography>
            <table>
              <tbody>
                <tr>
                  <td colSpan={2}>DiNo ist aktiviert.</td>
                </tr>
                <tr>
                  <td style={{ minWidth: 200 }}>Lizenz:</td>
                  <td>{config.license_key}</td>
                </tr>
                <tr>
                  <td>Lizenznehmer:</td>
                  <td>{config.company_name}</td>
                </tr>
                <tr>
                  <td>Nächste Lizenzprüfung:</td>
                  <td>
                    {new Date(config.valide_token || '').toLocaleString()}
                  </td>
                </tr>
                <tr>
                  <td>Notfall Lizenzprüfung:</td>
                  <td>
                    {new Date(
                      config.valide_refresh_token || ''
                    ).toLocaleString()}
                  </td>
                </tr>

                <tr>
                  <td colSpan={2}>
                    <Box sx={{ mt: 2 }} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>Apps</td>
                </tr>
                <tr>
                  <td colSpan={2}>{config.App.join(', ')}</td>
                </tr>

                <tr>
                  <td colSpan={2}>
                    <Box sx={{ mt: 2 }} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>Module</td>
                </tr>
                <tr>
                  <td colSpan={2}>{config.Module.join(', ')}</td>
                </tr>
              </tbody>
            </table>
          </Grid>

          <Grid item sm={4}>
            <img style={{ float: 'right' }} height={100} src='/logo_v2.png' />
          </Grid>
        </Grid>
      </>
    );
  }
};
