import React, { useState } from 'react';
import { IDocument } from '../../Interfaces/IDocument';
import { TreeItem } from '@mui/x-tree-view';
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  TextField,
} from '@mui/material';
import { getFetch } from '../../hooks/useFetch';
import { Delete, Edit, Plagiarism } from '@mui/icons-material';
import { IDocumentDirectoryHasDocument } from '../../Interfaces/IDocumentDirectoryHasDocument';
import { DocumentAssistentMain } from '../../Document/DocumentAssistant/DocumentAssistentMain';

interface IProps {
  documentObject: IDocument;
  documentArray: IDocumentDirectoryHasDocument[];
  setDocumentArray: Function;
}

export const DocumentDirectoryDocument: React.FC<IProps> = props => {
  const [documentObject, setDocumentObject] = useState(props.documentObject);
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState(props.documentObject.FileName);
  //
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  //
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  //
  const [isOpenDocumentAssistent, setIsOpenDocumentAssistent] = useState(false);

  const handleRightClick = (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setMouseX(e.clientX);
    setMouseY(e.clientY);
    setIsMenuOpen(true);
  };

  const base64Download = (localDocumentObject: IDocument) => {
    if (localDocumentObject.Data !== undefined) {
      const byteCharacters = atob(localDocumentObject.Data);
      // Each character's code point (charCode) will be the value of the byte.
      // We can create an array of byte values by applying this using the .charCodeAt method for each character in the string.
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      //You can convert this array of byte values into a real typed byte array by passing it to the Uint8Array constructor.
      const byteArray = new Uint8Array(byteNumbers);
      // This in turn can be converted to a BLOB by wrapping it in an array and passing it to the Blob constructor.
      const blob = new Blob([byteArray], { type: 'text/csv' });
      // Erstelle Link zum BLOB
      const blobUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = localDocumentObject.FileName;
      a.click();
    }
  };

  const wrapperDownload = (localDocumentObject: IDocument) => {
    setDocumentObject(localDocumentObject);
    base64Download(localDocumentObject);
    setIsLoading(false);
  };

  const handleDownload = () => {
    if (documentObject.Data != undefined) {
      base64Download(documentObject);
    } else {
      setIsLoading(true);
      getFetch('/document/', documentObject.idDocument, wrapperDownload);
    }
  };

  const handleClickMenu = (fun: Function) => {
    setIsMenuOpen(false);
    fun();
  };

  const handleRemove = () => {
    props.setDocumentArray([
      ...props.documentArray.filter(
        x => x.idDocument !== props.documentObject.idDocument
      ),
    ]);
  };

  const handleEdit = () => {
    const updatedObject = {
      ...props.documentObject,
      FileName: fileName,
    };
    setDocumentObject(updatedObject);
    props.setDocumentArray([
      ...props.documentArray.map(x =>
        x.idDocument === props.documentObject.idDocument ? updatedObject : x
      ),
    ]);
    setIsOpenEdit(false);
  };

  return (
    <>
      <DocumentAssistentMain
        isOpen={isOpenDocumentAssistent}
        setIsOpen={setIsOpenDocumentAssistent}
        documentObject={documentObject}
        setDocumentObject={setDocumentObject}
      />

      <Dialog
        open={isOpenEdit}
        onClose={() => setIsOpenEdit(false)}
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle>Datei umbenennen</DialogTitle>
        <DialogContent>
          <Alert severity='info'>
            Änderung der Datenendung kann zu einer Beschädigung der Datei
            führen!
          </Alert>
          <TextField
            sx={{ mt: 3 }}
            label='Datenbezeichnung'
            value={fileName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setFileName(event.target.value)
            }
            size='small'
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setIsOpenEdit(false)}>
            Abbruch
          </Button>
          <Button
            variant='contained'
            disabled={fileName === ''}
            onClick={handleEdit}
          >
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isLoading} onClose={() => {}}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>

      <TreeItem
        nodeId={`idDocument-${documentObject.idDocument}`}
        label={documentObject.FileName}
        onClick={handleDownload}
        onContextMenu={handleRightClick}
      />

      <Menu
        key={`menu-idDocument-${props.documentObject.idDocument}`}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        anchorReference='anchorPosition'
        anchorPosition={{ top: mouseY, left: mouseX }}
      >
        <MenuList dense>
          {documentObject.idMIMEType === 1 && (
            <MenuItem
              onClick={() =>
                handleClickMenu(() => setIsOpenDocumentAssistent(true))
              }
            >
              <ListItemIcon>
                <Plagiarism />
              </ListItemIcon>
              <ListItemText>Interpretieren</ListItemText>
            </MenuItem>
          )}

          <MenuItem
            onClick={() =>
              handleClickMenu(() => {
                setFileName(props.documentObject.FileName);
                setIsOpenEdit(true);
              })
            }
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText>Umbenennen</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleClickMenu(() => handleRemove())}>
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText>Löschen</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};
