import React, { useState } from 'react';
import { ILegalTransaction_has_LegalTransactionSpecialFunction } from '../../../Interfaces/ILegalTransaction_has_LegalTransactionSpecialFunction';
import { ILegalPhaseFullObject } from '../../../Interfaces/ILegalPhase';
import { ILegalRequirementState } from '../../../Interfaces/ILegalRequirementState';
import {
  Alert,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { HandelsregisterSearch } from '../../../generic/Handelsregsiter/HandelsregisterSearch';
import { ChangeLegalPhaseCondition } from '../ChangeLegalPhaseCondition';

interface IProps {
  idLegalTransaction: number;
  legalTransaction_has_LegalTransactionSpecialFunctionArray: ILegalTransaction_has_LegalTransactionSpecialFunction[];
  setLegalTransaction_has_LegalTransactionSpecialFunctionArray: Function;
  legalPhaseFullObjectArray: ILegalPhaseFullObject[];
  legalReqState: ILegalRequirementState[];

  deleteSpecialFunction: Function;
  handleFieldChangeOnImport: (
    index: number,
    updates: { [key: string]: string }
  ) => void;
}

export const ShareholderListCheck: React.FC<IProps> = props => {
  const [hasError, setHasError] = useState(false);

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [
    wasSuccessfullImportedFromhandelsregister,
    setWasSuccessfullImportedFromhandelsregister,
  ] = useState(false);

  const handleChange = (index: number, field: string, value: string) => {
    const updatedArray =
      props.legalTransaction_has_LegalTransactionSpecialFunctionArray.map(
        (item, i) => {
          if (item.idSF === 3 && i === index) {
            const updatedItem = { ...item, [field]: value };
            updatedItem.Function_Field3 = '1';
            return { ...item, [field]: value };
          }
          return item;
        }
      );
    props.setLegalTransaction_has_LegalTransactionSpecialFunctionArray(
      updatedArray
    );
  };

  return (
    <Grid container spacing={2} style={{ paddingBottom: '20px' }}>
      <Grid item sm={12}>
        <Typography variant='h5'>Gesellschafterliste Nachverfolgung</Typography>
      </Grid>

      {hasError && (
        <Grid item sm={12}>
          <Alert severity='error'>
            Ein oder mehrere Einträge haben leere Pflichtfelder.
          </Alert>
        </Grid>
      )}

      {props.legalTransaction_has_LegalTransactionSpecialFunctionArray.map(
        (item, index) =>
          item.idSF === 3 && (
            <React.Fragment key={index}>
              {item.Function_Field3 === '0' && (
                <Grid item sm={12}>
                  <Alert severity='warning'>
                    Keine Nachverfolgung aktiviert
                  </Alert>
                </Grid>
              )}
              {item.Function_Field3 === '1' && (
                <Grid item sm={12}>
                  <Alert severity='info'>
                    Nachverfolgung aktiviert, noch nicht geändert
                  </Alert>
                </Grid>
              )}
              {item.Function_Field3 === '2' && (
                <Grid item sm={12}>
                  <Alert severity='success'>Neue Gesellschafterliste</Alert>
                </Grid>
              )}

              <Grid item sm={2}>
                <TextField
                  label='Datum'
                  size='small'
                  type='date'
                  fullWidth
                  disabled={true}
                  required={true}
                  InputLabelProps={{ shrink: true }}
                  value={item.Function_Field1} // Setzt das heutige Datum als Standardwert
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(index, 'Function_Field1', event.target.value)
                  }
                />
              </Grid>

              <Grid item sm={7}>
                <TextField
                  disabled={true}
                  label='Unternehmensname (wie im Handelsregister)'
                  size='small'
                  fullWidth
                  required={true}
                  InputLabelProps={{ shrink: true }}
                  value={item.Function_Field2}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(index, 'Function_Field2', event.target.value)
                  }
                />
              </Grid>

              <Grid item sm={2}>
                <TextField
                  label='Handelsregister Nr.'
                  size='small'
                  fullWidth
                  disabled={true}
                  required={false}
                  InputLabelProps={{ shrink: true }}
                  value={item.Res_Field1}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(index, 'Res_Field1', event.target.value)
                  }
                />
              </Grid>

              <Grid item sm={1}>
                <IconButton onClick={() => props.deleteSpecialFunction(index)}>
                  <Delete />
                </IconButton>
              </Grid>

              <Grid item sm={2}>
                <ChangeLegalPhaseCondition
                  idLegalTransaction={props.idLegalTransaction}
                  legalTransaction_has_LegalTransactionSpecialFunctionArray={
                    props.legalTransaction_has_LegalTransactionSpecialFunctionArray
                  }
                  setLegalTransaction_has_LegalTransactionSpecialFunctionArray={
                    props.setLegalTransaction_has_LegalTransactionSpecialFunctionArray
                  }
                  legalPhaseFullObjectArray={props.legalPhaseFullObjectArray}
                  legalReqState={props.legalReqState}
                  specialFunction={item}
                ></ChangeLegalPhaseCondition>
              </Grid>

              <Grid item sm={10}>
                <Button
                  variant={'outlined'}
                  onClick={() => setIsSearchOpen(true)}
                >
                  Suche im Handelsregister
                </Button>
              </Grid>

              <HandelsregisterSearch
                openImportFromHandelsregister={isSearchOpen}
                setOpenImportFromHandelsregister={setIsSearchOpen}
                wasSuccessfullImportedFromhandelsregister={
                  wasSuccessfullImportedFromhandelsregister
                }
                setWasSuccessfullImportedFromhandelsregister={
                  setWasSuccessfullImportedFromhandelsregister
                }
                handleFieldChangeOnImport={props.handleFieldChangeOnImport}
                index={index}
                isXMLImport={false}
              />
            </React.Fragment>
          )
      )}
    </Grid>
  );
};
