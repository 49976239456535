import React, { useEffect, useState } from 'react';
import { IFSScanResult } from './DocumentPortalOverview';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Dialog,
  DialogContent,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { IDocument } from '../Interfaces/IDocument';
import { getFetch, uploadFetch } from '../hooks/useFetch';
import { CustomCircularProgress } from '../generic/CustomCircularProgress';
import {
  ILegalTransaction,
  ILegalTransactionFullObject,
} from '../Interfaces/ILegalTransaction';
import { DataGrid, deDE, GridRowParams } from '@mui/x-data-grid';
import { columns } from '../LegalTransaction/LegalTransactionOverview';
import { CustomeTextField } from '../generic/CustomeTextField';
import { ILegalTransactionTransactionNumber } from '../Interfaces/ILegalTransactionTransactionNumber';
import { ILegalTransactionRecordNumber } from '../Interfaces/ILegalTransactionRecordNumber';
import { DocumentOverview } from '../Document/DocumentOverview';
import { IDocumentDirectory } from '../Interfaces/IDocumentDirectory';
import { ILegalRequirementState } from '../Interfaces/ILegalRequirementState';
import { ILegalRequirement } from '../Interfaces/ILegalRequirement';
import { ILegalPhaseFullObject } from '../Interfaces/ILegalPhase';
import { IDocumentDirectoryHasDocument } from '../Interfaces/IDocumentDirectoryHasDocument';
import { IFSDirectorySortedDocument } from '../Interfaces/IFSDirectorySortedDocument';
import { Divider } from 'verbum';
import { Delete, Search } from '@mui/icons-material';

interface IFSScanDocument extends IDocument {
  preview: string;
}

interface IProps {
  fsScanObject: IFSScanResult;
  fsScanArray: IFSScanResult[];
  setFsScanArray: Function;
  legalTransactionArray: ILegalTransaction[];
  legalReqState: ILegalRequirementState[];
  recordNumberArray: ILegalTransactionRecordNumber[];
  transactionNumberArray: ILegalTransactionTransactionNumber[];
  clickedElement: string;
  setClickedElement: Function;
}

interface IFSSelectObject {
  section: 'certificate' | 'inovice' | 'dms' | 'legalRequirements' | string;
  idDocumentDirectory: number;
  idLegalRequirement: number;
}

const getEmptryFSSelectObject = (fsScanObject: IFSScanResult) => {
  return {
    section: 'legalRequirements',
    idDocumentDirectory: -1,
    idLegalRequirement: -1,
    FSScanResult: fsScanObject,
  } as IFSSelectObject;
};

export const DocumentPortalScanElement: React.FC<IProps> = ({
  fsScanObject,
  fsScanArray,
  setFsScanArray,
  clickedElement,
  setClickedElement,
  legalTransactionArray,
  legalReqState,
  recordNumberArray,
  transactionNumberArray,
}) => {
  const theme = useTheme();

  const [isOpenSort, setIsOpenSort] = useState(false);
  const [currentDocument, setCurrentDocument] = useState<IDocument | null>(
    null
  );
  const [previewDocument, setPreviewDocument] =
    useState<IFSScanDocument | null>(null);
  //
  const [isLoadingLoad, setIsLoadingLoad] = useState(false);
  const [wasSuccessfullyLoad, setWasSuccessfullyLoad] = useState(true);
  //
  const [currentLegalTransaction, setCurrentLegalTransaction] =
    useState<ILegalTransactionFullObject | null>(null);
  const [orgLegalPhaseArray, setOrgLegalPhaseArray] = useState<
    ILegalPhaseFullObject[]
  >([]);
  const [isLoadingFullObject, setIsLoadingFullObject] = useState(false);
  //
  const [searchString, setSearchString] = useState('');
  //
  const [selectObject, setSelectObject] = useState<IFSSelectObject>(
    getEmptryFSSelectObject(fsScanObject)
  );
  //
  const [isLoadingSaveLegalTransaction, setIsLoadingSaveLegalTransaction] =
    useState(false);
  const [isLoadingRemove, ssetIsLoadingRemove] = useState(false);
  const [
    wasSuccessfullySaveLegalTransaction,
    setWasSuccessfullySaveLegalTransaction,
  ] = useState(true);
  const [isLoadingSaveSortedDocument, setIsLoadingSaveSortedDocument] =
    useState(false);
  const [
    wasSuccessfullySaveSortedDocument,
    setWasSuccessfullySaveSortedDocument,
  ] = useState(true);

  //
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  //
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);

  const handleRightClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setMouseX(e.clientX);
    setMouseY(e.clientY);
    setIsMenuOpen(true);
  };

  const handleClickMenu = (fun: Function) => {
    setIsMenuOpen(false);
    fun();
  };

  const handleClick = () => {
    setClickedElement(fsScanObject.fileName);
    setIsOpenSort(true);
    setCurrentLegalTransaction(null);
    setSelectObject(getEmptryFSSelectObject(fsScanObject));

    uploadFetch(
      '/fsdirectory/getDoc',
      true,
      fsScanObject,
      (res: IDocument) => setCurrentDocument(res),
      setWasSuccessfullyLoad,
      () => {},
      setIsLoadingLoad
    );
  };

  const handleChangeId = (params: GridRowParams<any>) => {
    setCurrentLegalTransaction(null);
    setIsLoadingFullObject(true);
    setSelectObject(getEmptryFSSelectObject(fsScanObject));
    getFetch(
      '/legaltransaction/fullObject/',
      params.row.idLegalTransaction,
      (res: ILegalTransactionFullObject) => {
        setCurrentLegalTransaction(res);
        setOrgLegalPhaseArray(res.LegalPhaseArray);
        setIsLoadingFullObject(false);
      }
    );
  };

  const concateParent = (currentDirectory: IDocumentDirectory): string => {
    const currentLocalString = '\\\\';

    if (currentLegalTransaction !== null) {
      if (currentDirectory.Parent_idDocumentDirectory !== null) {
        const testObject = currentLegalTransaction.DocumentDirectoryArray.find(
          x =>
            x.idDocumentDirectory ===
            currentDirectory.Parent_idDocumentDirectory
        );
        if (testObject !== undefined) {
          return concateParent(testObject) + ' \\ ' + currentDirectory.Title;
        }
      }
    }

    return currentDirectory.Title;
  };

  const handleAfterSortedDocument = (
    sortedDocument: IFSDirectorySortedDocument
  ) => {
    setFsScanArray([
      ...fsScanArray.map(x =>
        x.idFSDirectory === fsScanObject.idFSDirectory &&
        x.fileName === fsScanObject.fileName
          ? { ...fsScanObject, isSorted: true }
          : x
      ),
    ]);
    setIsOpenSort(false);
  };
  const handleAfterRemove = (sortedDocument: IFSDirectorySortedDocument) => {
    setFsScanArray([
      ...fsScanArray.filter(
        x =>
          x.idFSDirectory !== fsScanObject.idFSDirectory ||
          x.fileName !== fsScanObject.fileName
      ),
    ]);
    setIsOpenSort(false);
  };

  const handleAfterSaveTransaction = (
    updatedLegalTransaction: any,
    isHidden: boolean = false
  ) => {
    const sortedDocument: IFSDirectorySortedDocument = {
      idFSDirectorySortedDocument: -1,
      fileName: fsScanObject.fileName,
      idFSDirectory: fsScanObject.idFSDirectory,
    };

    if (isHidden) {
      sortedDocument.is_hidden = new Date().toISOString();
    }

    if (fsScanObject.isSorted && !isHidden) {
      setIsOpenSort(false);
    } else {
      uploadFetch(
        '/FSDirectorySortedDocument'.toLocaleLowerCase(),
        true,
        sortedDocument,
        isHidden ? handleAfterRemove : handleAfterSortedDocument,
        setWasSuccessfullySaveSortedDocument,
        () => {},
        isHidden ? ssetIsLoadingRemove : setIsLoadingSaveSortedDocument
      );
    }
  };

  const handleSave = () => {
    if (currentLegalTransaction !== null && currentDocument !== null) {
      const uploadLegalTransaction: ILegalTransactionFullObject = {
        ...currentLegalTransaction,
        UserInformed_LackOfActivity: Boolean(
          currentLegalTransaction.UserInformed_LackOfActivity
        ),
      };

      if (selectObject.section === 'legalRequirements') {
        uploadLegalTransaction.LegalPhaseArray =
          uploadLegalTransaction.LegalPhaseArray.map(phase => {
            const foundReq = phase.LegalRequirementArray.find(
              x => x.idLegalRequirement === selectObject.idLegalRequirement
            );
            if (foundReq === undefined) {
              return phase;
            } else {
              if (foundReq.DocumentArrayEmployees !== undefined) {
                foundReq.DocumentArrayEmployees = [
                  ...foundReq.DocumentArrayEmployees,
                  currentDocument,
                ];
              }
              return {
                ...phase,
                LegalRequirementArray: phase.LegalRequirementArray.map(y =>
                  y.idLegalRequirement === foundReq!.idLegalRequirement
                    ? foundReq!
                    : y
                ),
              };
            }
          });
      } else if (selectObject.section === 'certificate') {
        uploadLegalTransaction.CertificateArray = [
          ...uploadLegalTransaction.CertificateArray,
          currentDocument,
        ];
      } else if (selectObject.section === 'inovice') {
        uploadLegalTransaction.InvoiceArray = [
          ...uploadLegalTransaction.InvoiceArray,
          currentDocument,
        ];
      } else if (selectObject.section === 'dms') {
        const dataOnDirectory: IDocumentDirectoryHasDocument = {
          ...currentDocument,
          idDocumentDirectory: selectObject.idDocumentDirectory,
          idLegalTransaction: uploadLegalTransaction.idLegalTransaction,
        };
        uploadLegalTransaction.DocumentArray = [
          ...uploadLegalTransaction.DocumentArray,
          dataOnDirectory,
        ];

        if (selectObject.idDocumentDirectory < 0) {
          const newDocumentDirectory: IDocumentDirectory = {
            idDocumentDirectory: selectObject.idDocumentDirectory,
            idLegalTransaction: uploadLegalTransaction.idLegalTransaction,
            Parent_idDocumentDirectory: null,
            Title: 'Sortiert über Posteingang',
            Created_at: null,
          };
          uploadLegalTransaction.DocumentDirectoryArray = [
            ...uploadLegalTransaction.DocumentDirectoryArray,
            newDocumentDirectory,
          ];
        }
      }

      uploadFetch(
        '/legaltransaction/fullObject',
        false,
        uploadLegalTransaction,
        handleAfterSaveTransaction,
        setWasSuccessfullySaveLegalTransaction,
        () => {},
        setIsLoadingSaveLegalTransaction
      );
    }
  };

  async function waitAndCallBackend(maxSec: number): Promise<void> {
    // Zufällige Zeit zwischen 1 und 20 Sekunden
    const delayInSeconds = Math.floor(Math.random() * maxSec) + 1; // 1 bis 20 Sekunden
    const delayInMilliseconds = delayInSeconds * 1000;
    console.log(`Waiting for ${delayInSeconds} for next try seconds...`);
    // Warten
    await new Promise(resolve => setTimeout(resolve, delayInMilliseconds));
    // Backend-Aufruf
    await getFromBackend();
  }

  const handleGoestWrong = (state: boolean) => {
    if (state === false) {
      waitAndCallBackend(60);
    }
  };

  const getFromBackend = () => {
    uploadFetch(
      '/fsdirectory/getPreview',
      true,
      fsScanObject,
      (res: IFSScanDocument) => setPreviewDocument(res),
      handleGoestWrong,
      () => {},
      setIsLoadingLoad
    );
  };

  useEffect(() => {
    //waitAndCallBackend(20);
  }, []);

  const legalTransactionSelect = () => {
    if (isLoadingFullObject) {
      return <CustomCircularProgress size={50} />;
    } else if (currentLegalTransaction === null) {
      return <></>;
    } else {
      return (
        <Box sx={{ mt: 2 }}>
          <Card variant='outlined'>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label='Bezeichnung'
                    variant='standard'
                    value={currentLegalTransaction.Title}
                    size='small'
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label='Phase'
                    variant='standard'
                    value={currentLegalTransaction.LegalTransactionProgress}
                    size='small'
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label='Status'
                    variant='standard'
                    value={currentLegalTransaction.LegalTransactionState}
                    size='small'
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  {transactionNumberArray.filter(
                    x =>
                      x.idLegalTransaction ===
                      currentLegalTransaction.idLegalTransaction
                  ).length === 0 ? (
                    <TextField
                      label='Vorgangsnummer'
                      variant='standard'
                      size='small'
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  ) : (
                    transactionNumberArray
                      .filter(
                        x =>
                          x.idLegalTransaction ===
                          currentLegalTransaction.idLegalTransaction
                      )
                      .map(x => (
                        <TextField
                          label='Vorgangsnummer'
                          variant='standard'
                          value={x.TransactionNumberEntry}
                          size='small'
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      ))
                  )}
                </Grid>
                <Grid item xs={3}>
                  {recordNumberArray.filter(
                    x =>
                      x.idLegalTransaction ===
                      currentLegalTransaction.idLegalTransaction
                  ).length === 0 ? (
                    <TextField
                      label='Urkundennummer'
                      variant='standard'
                      size='small'
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  ) : (
                    recordNumberArray
                      .filter(
                        x =>
                          x.idLegalTransaction ===
                          currentLegalTransaction.idLegalTransaction
                      )
                      .map(x => (
                        <TextField
                          label='Urkundennummer'
                          variant='standard'
                          value={x.RecordNumberEntry}
                          size='small'
                          fullWidth
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      ))
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Box sx={{ mt: 5 }} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                //type="email"
                variant='outlined'
                label='Sektion'
                fullWidth
                value={selectObject.section}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSelectObject({
                    ...selectObject,
                    section: event.target.value,
                    idDocumentDirectory: -1,
                    idLegalRequirement: -1,
                  });
                  setCurrentLegalTransaction({
                    ...currentLegalTransaction,
                    LegalPhaseArray: orgLegalPhaseArray,
                  });
                }}
                select
                size='small'
              >
                <MenuItem
                  key={`fs-section-clegalRequirements`}
                  value='legalRequirements'
                >
                  Abwicklung / Maßnahmen
                </MenuItem>
                <MenuItem key={`fs-section-certificate`} value='certificate'>
                  Urkunden
                </MenuItem>
                <MenuItem key={`fs-section-inovice`} value='inovice'>
                  Rechnungen
                </MenuItem>
                <MenuItem key={`fs-section-dms`} value='dms'>
                  Interne Datenablage
                </MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <Collapse in={selectObject.section === 'legalRequirements'}>
                <Table size='small'>
                  <TableBody>
                    <Grid container spacing={1}>
                      {currentLegalTransaction.LegalPhaseArray.map(
                        legalPhase => (
                          <>
                            <Grid item xs={12}>
                              <Alert severity='info'>
                                {legalPhase.LegalPhase}
                              </Alert>
                            </Grid>
                            {legalPhase.LegalRequirementArray.map(req => (
                              <>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={10}>
                                  <Alert
                                    severity={
                                      req.idLegalRequirementState === 40
                                        ? 'success'
                                        : req.enableError &&
                                            req.idLegalRequirementState === 30
                                          ? 'error'
                                          : req.idLegalRequirementState ===
                                                15 ||
                                              req.idLegalRequirementState === 20
                                            ? 'warning'
                                            : 'info'
                                    }
                                    action={
                                      <>
                                        <Radio
                                          checked={
                                            selectObject.idLegalRequirement ===
                                            req.idLegalRequirement
                                          }
                                          onChange={() =>
                                            setSelectObject({
                                              ...selectObject,
                                              idLegalRequirement:
                                                req.idLegalRequirement,
                                            })
                                          }
                                          size='small'
                                        />
                                        <Box sx={{ width: 200 }}>
                                          <CustomeTextField
                                            label='Status'
                                            attr='idLegalRequirementState'
                                            object={req}
                                            setObject={(
                                              updatedReq: ILegalRequirement
                                            ) => {
                                              const updatePhase = {
                                                ...legalPhase,
                                                LegalRequirementArray:
                                                  legalPhase.LegalRequirementArray.map(
                                                    x =>
                                                      x.idLegalRequirement ===
                                                      updatedReq.idLegalRequirement
                                                        ? updatedReq
                                                        : x
                                                  ),
                                              };
                                              setCurrentLegalTransaction({
                                                ...currentLegalTransaction,
                                                LegalPhaseArray:
                                                  currentLegalTransaction.LegalPhaseArray.map(
                                                    x =>
                                                      x.idLegalPhase ===
                                                      updatePhase.idLegalPhase
                                                        ? updatePhase
                                                        : x
                                                  ),
                                              });
                                            }}
                                            type='select'
                                          >
                                            {legalReqState.map(x => (
                                              <MenuItem
                                                key={`idLegalRequirementState-${x.idLegalRequirementState}`}
                                                value={
                                                  x.idLegalRequirementState
                                                }
                                                disabled={
                                                  x.idLegalRequirementState ===
                                                    30 && !req.enableError
                                                }
                                              >
                                                {x.LegalRequirementState}
                                              </MenuItem>
                                            ))}
                                          </CustomeTextField>
                                        </Box>
                                      </>
                                    }
                                  >
                                    {req.LegalRequirement}
                                  </Alert>
                                </Grid>
                              </>
                            ))}
                          </>
                        )
                      )}
                    </Grid>
                  </TableBody>
                </Table>
              </Collapse>
            </Grid>

            <Grid item xs={12}>
              <Collapse
                in={selectObject.section === 'certificate'}
                sx={{ mt: -2 }}
              >
                <DocumentOverview
                  key='certification'
                  allowRemove={false}
                  allowUpload={false}
                  title='Urkunden'
                  documentArray={currentLegalTransaction.CertificateArray}
                  setDocumentArray={() => {}}
                  downloadedArray={[]}
                />
              </Collapse>
            </Grid>

            <Grid item xs={12}>
              <Collapse in={selectObject.section === 'inovice'} sx={{ mt: -4 }}>
                <DocumentOverview
                  key='invoice'
                  allowRemove={false}
                  allowUpload={false}
                  title='Rechnungen'
                  documentArray={currentLegalTransaction.InvoiceArray}
                  setDocumentArray={() => {}}
                  downloadedArray={[]}
                />
              </Collapse>
            </Grid>

            <Grid item xs={12}>
              <Collapse in={selectObject.section === 'dms'} sx={{ mt: -6 }}>
                <CustomeTextField
                  attr='idDocumentDirectory'
                  label='Ordner'
                  object={selectObject}
                  setObject={setSelectObject}
                  type='select'
                >
                  {[
                    {
                      Title: 'Neuen Ordner anlegen',
                      Created_at: null,
                      idDocumentDirectory: -1,
                      idLegalTransaction:
                        currentLegalTransaction.idLegalTransaction,
                      Parent_idDocumentDirectory: null,
                    } as IDocumentDirectory,
                    ...currentLegalTransaction.DocumentDirectoryArray,
                  ].map(x => (
                    <MenuItem
                      key={`fs-dir-${x.idDocumentDirectory}`}
                      value={x.idDocumentDirectory}
                    >
                      {concateParent(x)}
                    </MenuItem>
                  ))}
                </CustomeTextField>
                <Box sx={{ mt: 2 }} />
                <DocumentOverview
                  key='dms-ducmentenview'
                  allowRemove={false}
                  allowUpload={false}
                  title={
                    selectObject.idDocumentDirectory < 0
                      ? 'Neuen Ordner anlegen'
                      : String(
                          currentLegalTransaction.DocumentDirectoryArray.find(
                            x =>
                              x.idDocumentDirectory ===
                              selectObject.idDocumentDirectory
                          )?.Title
                        )
                  }
                  documentArray={currentLegalTransaction.DocumentArray.filter(
                    x =>
                      x.idDocumentDirectory === selectObject.idDocumentDirectory
                  )}
                  setDocumentArray={() => {}}
                  downloadedArray={[]}
                />
              </Collapse>
            </Grid>
          </Grid>
        </Box>
      );
    }
  };

  const getDialogContent = () => {
    if (!wasSuccessfullyLoad) {
      <Alert severity='error'>
        Fehler!
        <br />
        Die Datei kann nicht bezogen werden. Ist der freigegebene Ordner
        weiterhin verfügbar?
      </Alert>;
    } else if (
      isLoadingLoad ||
      currentDocument === null ||
      currentDocument.Data == undefined
    ) {
      return <CustomCircularProgress />;
    } else if (isLoadingSaveLegalTransaction || isLoadingSaveSortedDocument) {
      return (
        <>
          {isLoadingSaveLegalTransaction && (
            <Typography>Speichere Vorgang...</Typography>
          )}
          {isLoadingSaveSortedDocument && (
            <Typography>Speichere sortiertes Dokument...</Typography>
          )}
          <CustomCircularProgress />
        </>
      );
    } else {
      return (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Collapse
                in={
                  !wasSuccessfullySaveLegalTransaction ||
                  !wasSuccessfullySaveSortedDocument
                }
              >
                <Alert severity='error' sx={{ mb: 1 }}>
                  <b>Es ist ein Fehler aufgertetten!</b>
                  <br />
                  {(!wasSuccessfullySaveLegalTransaction &&
                    !wasSuccessfullySaveSortedDocument) ||
                  !wasSuccessfullySaveLegalTransaction ? (
                    <>
                      Das Speicher des Vorgangs und der Datei war nicht
                      erfolgreich!
                    </>
                  ) : (
                    <>
                      Die Datei konnte dem Vorgang zugeordnet werden. <br /> Die
                      Archivierung der Datei war nicht erfolgreich.
                    </>
                  )}
                </Alert>
              </Collapse>
            </Grid>
            <Grid item xs={6}>
              <iframe
                name={currentDocument.FileName}
                src={`data:application/pdf;headers=filename%3D${currentDocument.FileName};base64,${currentDocument.Data}`}
                width={700}
                height={800}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='h5'>Dokument zuordnen</Typography>
              <CustomeTextField
                label='Suche'
                attr='search'
                object={{ search: searchString }}
                setObject={(e: any) => setSearchString(e['search'])}
                type='string'
              />
              <Box sx={{ height: 350, mt: 2 }}>
                <DataGrid
                  localeText={
                    deDE.components.MuiDataGrid.defaultProps.localeText
                  }
                  rowHeight={25}
                  columnHeaderHeight={36}
                  rows={
                    searchString === ''
                      ? legalTransactionArray
                      : legalTransactionArray.filter(
                          x =>
                            x.Title?.toLocaleLowerCase().includes(
                              searchString.toLocaleLowerCase()
                            ) ||
                            recordNumberArray
                              .filter(
                                y =>
                                  y.idLegalTransaction === x.idLegalTransaction
                              )
                              .filter(y =>
                                y.RecordNumberEntry.toLocaleLowerCase().includes(
                                  searchString.toLocaleLowerCase()
                                )
                              ).length > 0 ||
                            transactionNumberArray
                              .filter(
                                y =>
                                  y.idLegalTransaction === x.idLegalTransaction
                              )
                              .filter(y =>
                                y.TransactionNumberEntry.toLocaleLowerCase().includes(
                                  searchString.toLocaleLowerCase()
                                )
                              ).length > 0
                        )
                  }
                  columns={columns.filter(
                    x => x.field !== 'idLegalTransaction'
                  )}
                  getRowId={row => row.idLegalTransaction}
                  onRowClick={(params, event, details) =>
                    handleChangeId(params)
                  }
                />
              </Box>
              {legalTransactionSelect()}
            </Grid>
          </Grid>

          <Button
            variant='contained'
            sx={{ float: 'right', ml: 2 }}
            disabled={
              selectObject.section === 'legalRequirements' &&
              selectObject.idLegalRequirement < 0
            }
            onClick={handleSave}
          >
            Hinzufügen
          </Button>
          <Button
            variant='outlined'
            sx={{ float: 'right' }}
            onClick={() => setIsOpenSort(false)}
          >
            Abbruch
          </Button>
        </>
      );
    }
  };

  return (
    <>
      <Dialog open={isLoadingRemove}>
        <DialogContent>
          <CustomCircularProgress />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isOpenSort}
        onClose={() => setIsOpenSort(false)}
        maxWidth='xl'
        fullWidth
      >
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              {getDialogContent()}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Menu
        key={`menu-idDocumentDirecotry-${fsScanObject.idFSDirectory}-${fsScanObject.fileName}`}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        anchorReference='anchorPosition'
        anchorPosition={{ top: mouseY, left: mouseX }}
      >
        <MenuList dense>
          <MenuItem onClick={() => handleClickMenu(handleClick)}>
            <ListItemIcon>
              <Search />
            </ListItemIcon>
            <ListItemText>Öffnen</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() =>
              handleClickMenu(() => {
                setIsLoadingSaveSortedDocument(true);
                handleAfterSaveTransaction({}, true);
              })
            }
          >
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText>Ausblenden</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>

      {/**


      <Box sx={{ m: 1 }}>
        <div onContextMenu={handleRightClick}>
          <Card
            variant='outlined'
            sx={{
              width: 150,
              height: 200,
              cursor: 'pointer',
              background:
                clickedElement === fsScanObject.fileName
                  ? '#eeeeee'
                  : undefined,
            }}
            onClick={handleClick}
          >
            <CardContent>
              <Box sx={{ float: 'center' }}>
                {previewDocument === null ? (
                  <CircularProgress sx={{ mt: 3, mb: 3.5, ml: 4.5 }} />
                ) : (
                  <>
                    {fsScanObject.isSorted && (
                      <Chip
                        sx={{
                          position: 'absolute',
                          ml: 6,
                          transform: 'rotate(28deg)',
                        }}
                        size='small'
                        label='Sortiert'
                        variant='outlined'
                        color='success'
                      />
                    )}
                    <img
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                      src={`data:image/png;base64,${previewDocument.preview}`}
                    />
                  </>
                )}
              </Box>

              <Typography
                fontSize={12}
                sx={{ mt: 2, float: 'center', textAlign: 'center' }}
              >
                {fsScanObject.fileName}
              </Typography>
            </CardContent>
          </Card>
        </div>
      </Box>
       */}

      <TableRow
        sx={{
          cursor: 'pointer',
          backgroundColor: fsScanObject.isSorted
            ? 'rgb(237, 247, 237)'
            : undefined, // Use the 'success' color
          color: fsScanObject.isSorted ? 'rgb(30, 70, 32)' : undefined, // Adjust text color for readability
        }}
        onClick={handleClick}
        onContextMenu={handleRightClick}
      >
        <TableCell>{fsScanObject.fileName}</TableCell>
        <TableCell>
          {fsScanObject.isSorted ? <>Sortiert</> : <>Unsortiert</>}
        </TableCell>
        <TableCell>{fsScanObject.isSorted && <>Ab v0.4.5</>}</TableCell>
        <TableCell>{fsScanObject.isSorted && <>Ab v0.4.5</>}</TableCell>
      </TableRow>
    </>
  );
};
