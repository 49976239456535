import React, { useState } from 'react';
import { useFetch } from '../hooks/useFetch';
import { ILegalTransaction } from '../Interfaces/ILegalTransaction';
import { ILegalTransactionState } from '../Interfaces/ILegalTransactionState';
import { ILegalTransactionType } from '../Interfaces/ILegalTransactionType';
import { CustomCircularProgress } from '../generic/CustomCircularProgress';
import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { KanbanBody } from './KanbanBody';

export const KanbanOverview: React.FC = () => {
  const [legalTransactionArray, setLegalTransactionArray, wasSuccessfully] =
    useFetch<ILegalTransaction[]>('/legaltransaction');
  const [legalStateArray, setLegalStateArray, wasSuccessfullyLegalStateArray] =
    useFetch<ILegalTransactionState[]>('/legaltransactionstate');
  const [
    LegalTransactionTypeArray,
    setLegalTransactionType,
    wasSuccessfullyLegalTransactionType,
  ] = useFetch<ILegalTransactionType[]>('/legaltransactiontype');

  const [idLegalTransactionType, setIdLegalTransactionType] = useState(1);

  if (
    !wasSuccessfully ||
    !wasSuccessfullyLegalStateArray ||
    !wasSuccessfullyLegalTransactionType
  ) {
    return <>Fehler!</>;
  } else if (
    legalTransactionArray == undefined ||
    legalStateArray === undefined ||
    LegalTransactionTypeArray === undefined
  ) {
    return <CustomCircularProgress />;
  } else {
    return (
      <>
        <Box sx={{ m: 2, mt: 0 }}>
          <Typography variant='h4'>
            Kanban
            <TextField
              sx={{ float: 'right', width: 200 }}
              select
              label='Art'
              size='small'
              value={idLegalTransactionType}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setIdLegalTransactionType(Number(event.target.value))
              }
            >
              {LegalTransactionTypeArray.map(x => (
                <MenuItem
                  key={`idLegalTransactionType-${x.idLegalTransactionType}`}
                  value={x.idLegalTransactionType}
                >
                  {x.LegalTransactionType}
                </MenuItem>
              ))}
            </TextField>
          </Typography>

          <KanbanBody
            key={`kanban-${idLegalTransactionType}`}
            idLegalTransactionType={idLegalTransactionType}
          />
        </Box>
      </>
    );
  }
};
