import React, { useEffect, useState } from 'react';
import { IUserFullObject } from '../../Interfaces/IUser';
import { IDayOffWork } from '../../Interfaces/IDayOffWork';
import { TableCell } from '@mui/material';
import { IHelpCalenderDay } from '../DayOffWorkOverviewTable';

export const calcDayOffSummaryByMonth = (
  userFullObject: IUserFullObject,
  dayOffWorkUserArray: IDayOffWork[],
  calenderHelpArray: IHelpCalenderDay[]
) => {
  let currentMonthVacation = 0;
  let currentMonthFreeVacation = 0;
  const currentMonthIll = 0;
  let currentIllTillMonth = 0;

  if (dayOffWorkUserArray.length !== 0) {
    const firstDayOfArray = calenderHelpArray[0].Date;
    const lastDayOffArray =
      calenderHelpArray[calenderHelpArray.length - 1].Date;
    // Bestimmen freien Urlaub über alle aktiven Arbeitsverträgen
    // bestimme freien urlaub
    const totalFreeDay = userFullObject.EmploymentContractArray.filter(
      x => x.End === null || new Date(x.End) <= new Date()
    )
      .map(x => x.VacationPerYear)
      .reduce((a, b) => a + b, 0);

    const filteredDayOffWork = dayOffWorkUserArray
      .filter(x => x.isConfirmed !== null && Boolean(x.isConfirmed) === true)
      .filter(
        x =>
          new Date(x.FromDate) >= firstDayOfArray &&
          new Date(x.ToDate) <= lastDayOffArray
      );

    // map: Berechnen der Urlaubstage im aktuellen Monat
    filteredDayOffWork
      .filter(x => x.idDayOffWorkCategory === 1)
      .map(x => {
        currentMonthVacation += x.DaysOfWork;
      });
    // end: map

    // map: Berechnen der Urlaubstage im aktuellen Monat
    filteredDayOffWork
      .filter(x => x.idDayOffWorkCategory >= 5)
      .map(x => {
        currentMonthVacation += x.DaysOfWork;
      });
    // end: map

    // map: Berechnen der freien Urlaubstage bis zum aktuellen Monat
    currentMonthFreeVacation =
      totalFreeDay -
      dayOffWorkUserArray
        .filter(x => x.isConfirmed !== null && Boolean(x.isConfirmed) === true)
        .filter(x => x.idDayOffWorkCategory === 1)
        .filter(x => new Date(x.ToDate) <= lastDayOffArray)
        .map(x => x.DaysOfWork)
        .reduce((a, b) => a + b, 0);
    // end: map

    // map: Berechnen der Krankheitstage bis zum aktuellen Monat
    currentIllTillMonth = dayOffWorkUserArray
      .filter(x => x.isConfirmed !== null && Boolean(x.isConfirmed) === true)
      .filter(x => x.idDayOffWorkCategory >= 5)
      .filter(x => new Date(x.ToDate) <= lastDayOffArray)
      .map(x => x.DaysOfWork)
      .reduce((a, b) => a + b, 0);
    // end: map
  }

  return [
    currentMonthVacation,
    currentMonthFreeVacation,
    currentMonthIll,
    currentIllTillMonth,
  ];
};

interface IProps {
  userFullObject: IUserFullObject | undefined;
  dayOffWorkUserArray: IDayOffWork[];
  calenderHelpArray: IHelpCalenderDay[];
}

export const DayOffWorkSummaryRows: React.FC<IProps> = ({
  userFullObject,
  dayOffWorkUserArray,
  calenderHelpArray,
}) => {
  const [currentMonthVacation, setCurrentMonthVacation] = useState(0);
  const [currentMonthFreeVacation, setCurrentMonthFreeVacation] = useState(0);
  const [currentMonthIll, setCurrentMonthIll] = useState(0);
  const [currentIllTillMonth, setCurrentIllTillMonth] = useState(0);

  useEffect(() => {
    if (userFullObject !== undefined) {
      const [
        localcurrentMonthVacation,
        localcurrentMonthFreeVacation,
        localcurrentMonthIll,
        localcurrentIllTillMonth,
      ] = calcDayOffSummaryByMonth(
        userFullObject,
        dayOffWorkUserArray,
        calenderHelpArray
      );
      setCurrentMonthVacation(localcurrentMonthVacation);
      setCurrentMonthFreeVacation(localcurrentMonthFreeVacation);
      setCurrentMonthIll(localcurrentMonthIll);
      setCurrentIllTillMonth(localcurrentIllTillMonth);
    }
  }, [userFullObject, dayOffWorkUserArray, calenderHelpArray]);

  if (userFullObject === undefined) {
    return (
      <>
        <TableCell sx={{ borderRight: 'solid 1px' }}>Load...</TableCell>
        <TableCell sx={{ borderRight: 'solid 1px' }}>Load...</TableCell>
        <TableCell sx={{ borderRight: 'solid 1px' }}>Load...</TableCell>
        <TableCell sx={{ borderRight: 'solid 1px' }}>Load...</TableCell>
      </>
    );
  } else {
    return (
      <>
        <TableCell sx={{ borderRight: 'solid 1px' }}>
          {currentMonthVacation}
        </TableCell>
        <TableCell sx={{ borderRight: 'solid 1px' }}>
          {currentMonthIll}
        </TableCell>
        <TableCell sx={{ borderRight: 'solid 1px' }}>
          {currentMonthFreeVacation}
        </TableCell>
        <TableCell sx={{ borderRight: 'solid 1px' }}>
          {currentIllTillMonth}
        </TableCell>
      </>
    );
  }
};
