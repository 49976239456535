import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICompanyFormationQuestion } from '../../Interfaces/ICompanyFormationQuestion';

const initialState = {
  storeCompanyFormationQuestion: [] as ICompanyFormationQuestion[],
};

const sliceCompanyFormationQuestion = createSlice({
  name: 'COMPANYFOMRATIONQuestionARRAY',
  initialState,
  reducers: {
    setCompanyFormationQuestionArray: (
      state,
      action: PayloadAction<ICompanyFormationQuestion[]>
    ) => {
      state.storeCompanyFormationQuestion = [...action.payload];
    },
    addUpdateCompanyFormationQuestionArray: (
      state,
      action: PayloadAction<ICompanyFormationQuestion>
    ) => {
      const testObject = state.storeCompanyFormationQuestion.find(
        x =>
          x.idCompanyFormationQuestion ===
          action.payload.idCompanyFormationQuestion
      );

      if (testObject) {
        state.storeCompanyFormationQuestion = [
          ...state.storeCompanyFormationQuestion.map(x =>
            x.idCompanyFormationQuestion ===
            action.payload.idCompanyFormationQuestion
              ? action.payload
              : x
          ),
        ];
      } else {
        state.storeCompanyFormationQuestion = [
          action.payload,
          ...state.storeCompanyFormationQuestion,
        ];
      }
    },
  },
});
export const {
  setCompanyFormationQuestionArray,
  addUpdateCompanyFormationQuestionArray,
} = sliceCompanyFormationQuestion.actions;
export default sliceCompanyFormationQuestion.reducer;
