import React, { useState } from 'react';
import { IFSScanResult } from './DocumentPortalOverview';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DocumentPortalScanElement } from './DocumentPortalScanElement';
import { ILegalTransaction } from '../Interfaces/ILegalTransaction';
import { ILegalRequirementState } from '../Interfaces/ILegalRequirementState';
import { ILegalTransactionRecordNumber } from '../Interfaces/ILegalTransactionRecordNumber';
import { ILegalTransactionTransactionNumber } from '../Interfaces/ILegalTransactionTransactionNumber';
import { ExpandMore } from '@mui/icons-material';

interface IProps {
  currentDate: Date;
  fsScanArray: IFSScanResult[];
  setFsScanArray: Function;
  legalTransactionArray: ILegalTransaction[];
  legalReqState: ILegalRequirementState[];
  recordNumberArray: ILegalTransactionRecordNumber[];
  transactionNumberArray: ILegalTransactionTransactionNumber[];
  clickedElement: string;
  setClickedElement: Function;
  openAsOpen?: boolean;
}

export const DocumentPortalAccordion: React.FC<IProps> = ({
  currentDate,
  fsScanArray,
  setFsScanArray,
  clickedElement,
  setClickedElement,
  legalTransactionArray,
  legalReqState,
  recordNumberArray,
  transactionNumberArray,
  openAsOpen,
}) => {
  const [isOpen, setIsOpen] = useState(
    openAsOpen !== undefined && openAsOpen === true
      ? true
      : fsScanArray
          .filter(x => {
            const dateOfDocument = new Date(x.created_at);
            return (
              dateOfDocument.getFullYear() === currentDate.getFullYear() &&
              dateOfDocument.getMonth() === currentDate.getMonth() &&
              dateOfDocument.getDate() === currentDate.getDate()
            );
          })
          .filter(x => x.isSorted == false).length > 0
  );

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return (
    <>
      <Accordion
        variant='outlined'
        expanded={isOpen}
        onChange={() => setIsOpen(!isOpen)}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            {currentDate.toLocaleDateString('de-DE', options)}
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            {
              fsScanArray
                .filter(x => {
                  const dateOfDocument = new Date(x.created_at);
                  return (
                    dateOfDocument.getFullYear() ===
                      currentDate.getFullYear() &&
                    dateOfDocument.getMonth() === currentDate.getMonth() &&
                    dateOfDocument.getDate() === currentDate.getDate()
                  );
                })
                .filter(x => x.isSorted == true).length
            }
            &nbsp;von&nbsp;
            {
              fsScanArray.filter(x => {
                const dateOfDocument = new Date(x.created_at);
                return (
                  dateOfDocument.getFullYear() === currentDate.getFullYear() &&
                  dateOfDocument.getMonth() === currentDate.getMonth() &&
                  dateOfDocument.getDate() === currentDate.getDate()
                );
              }).length
            }
            &nbsp;sortiert
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}
            >
              <Table size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ flex: 1 }}>Dokument</TableCell>
                    <TableCell sx={{ width: 100 }}>Status</TableCell>
                    <TableCell sx={{ width: 200 }}>Urkundennummer</TableCell>
                    <TableCell sx={{ flex: 1 }}>Ziel</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fsScanArray
                    .filter(x => {
                      const dateOfDocument = new Date(x.created_at);
                      return (
                        dateOfDocument.getFullYear() ===
                          currentDate.getFullYear() &&
                        dateOfDocument.getMonth() === currentDate.getMonth() &&
                        dateOfDocument.getDate() === currentDate.getDate()
                      );
                    })
                    .map(x => (
                      <DocumentPortalScanElement
                        key={`document-scan-${x.idFSDirectory}-${x.fileName}`}
                        fsScanObject={x}
                        fsScanArray={fsScanArray}
                        setFsScanArray={setFsScanArray}
                        legalTransactionArray={legalTransactionArray}
                        legalReqState={legalReqState}
                        recordNumberArray={recordNumberArray}
                        transactionNumberArray={transactionNumberArray}
                        clickedElement={clickedElement}
                        setClickedElement={setClickedElement}
                      />
                    ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
