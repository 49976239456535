import React, { useState } from 'react';
import {
  Alert,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { ShareHolderEdit } from './ShareHolder/ShareHolderEdit';
import { SharesSummaryTable } from './Summary/SharesSummaryTable';
import { RepresentationSummary } from './Summary/RepresentationSummary';
import { ICompanyFormation } from '../../../Interfaces/ICompanyFormation';
import { IShareHolderFullObject } from '../../../Interfaces/IShareHolder';
import { ICompanyFormationShare } from '../../../Interfaces/ICompanyFormationShare';
import { ICompanyFormationHasShareHolder } from '../../../Interfaces/ICompanyFormationHasShareHolder';
import { useSelector } from 'react-redux';
import { State } from '../../../redux/mainReducer';
import { DialogEditBaseInformation } from './Edit/DialogEditBaseInformation';
import { DialogEditRepresentation } from './Edit/DialogEditRepresentation';
import { CompanyFormationAnswerSummary } from './Summary/CompanyFormationAnswerSummary';
import { ICompanyFormationAnswer } from '../../../Interfaces/ICompanyFormationAnswer';

export interface IProps {
  companyFormationObject: ICompanyFormation;
  setCompanyFormationObject: Function;
  shareHolderArray: IShareHolderFullObject[];
  setShareHolderArray: Function;
  companyFormationShareArray: ICompanyFormationShare[];
  setCompanyFormationShareArray: Function;
  shareHolderRelation: ICompanyFormationHasShareHolder[];
  setShareHolderRelation: Function;
  companyFormationAnswerArray: ICompanyFormationAnswer[];
  setCompanyFormationAnswerArray: Function;
}

const checkString = (stringToCheck: string | undefined) => {
  if (stringToCheck == undefined || stringToCheck === '') {
    return 'k.A.';
  } else {
    return stringToCheck;
  }
};

export const Summary: React.FC<IProps> = props => {
  const genderArray = useSelector(
    (state: State) => state.genderArray.storeGender
  );
  const academicTitleArray = useSelector(
    (state: State) => state.academicTitleArray.storeAcademicTitle
  );
  const staticCountryArray = useSelector(
    (state: State) => state.countryArray.storeCountry
  );
  //
  const [idShareHolder, setIdShareHolder] = useState<number | null>(null);
  //
  const [isLoading, setIsLoading] = useState(false);
  const [wasSuccessfully, setWasSuccessfully] = useState(true);
  //
  const [isOpenCompanyFormation, setIsOpenCompanyFormation] = useState(false);

  return (
    <>
      <Dialog open={isLoading}>
        <DialogTitle>Bitte warten</DialogTitle>
        <DialogContent>
          Ihre Anfrage wird derzeitig hochgeladen.
          <Grid
            container
            sx={{ mt: 3 }}
            spacing={0}
            direction='column'
            alignItems='center'
            justifyContent='center'
          >
            <Grid item xs={3}>
              {wasSuccessfully ? (
                <CircularProgress />
              ) : (
                <Alert severity='error'>Es ist ein Fehler aufgetretten!</Alert>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={idShareHolder !== null}
        onClose={() => setIdShareHolder(null)}
        maxWidth='md'
        fullWidth
      >
        <DialogTitle>
          {idShareHolder === null || idShareHolder === 0 ? (
            <>Neue Person</>
          ) : (
            <>Person bearbeiten</>
          )}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            {idShareHolder !== null && (
              <ShareHolderEdit
                idShareHolder={idShareHolder}
                handleClose={() => setIdShareHolder(null)}
                shareHolderArray={props.shareHolderArray}
                setShareHolderArray={props.setShareHolderArray}
                disableSelectCompanyType={
                  props.shareHolderArray.find(
                    x => x.idShareHolder === idShareHolder
                  )?.idBusinessForm === 1
                }
              />
            )}
          </Box>
        </DialogContent>
      </Dialog>

      <Box sx={{ mt: 5 }} />
      <Typography variant='h6' sx={{ mb: 3 }}>
        Zusammenfassung
        <DialogEditBaseInformation
          companyFormationObject={props.companyFormationObject}
          setcompanyFormationObject={props.setCompanyFormationObject}
          companyFormationAnswerArray={props.companyFormationAnswerArray}
          setCompanyFormationAnswerArray={props.setCompanyFormationAnswerArray}
        />
      </Typography>
      <Grid container sx={{ pl: 10 }} spacing={2}>
        <Grid item sm={4}>
          <b>Firmennamen:</b>
        </Grid>
        <Grid item sm={8}>
          {checkString(props.companyFormationObject.CompanyName)}
        </Grid>

        <Grid item sm={4}>
          <b>Adresse:</b>
        </Grid>
        <Grid item sm={8}>
          {checkString(props.companyFormationObject.Street)}{' '}
          {checkString(props.companyFormationObject.StreetNr)}
        </Grid>

        <Grid item sm={4}></Grid>
        <Grid item sm={8}>
          {checkString(props.companyFormationObject.Postcode)}{' '}
          {checkString(props.companyFormationObject.City)}
        </Grid>

        <Grid item sm={4}>
          <b>Geschäftszweck:</b>
        </Grid>
        <Grid item sm={8}>
          {checkString(props.companyFormationObject.PurposeOfTheCompany)}
        </Grid>

        <Grid item sm={4}>
          <b>Beteiligte:</b>
        </Grid>
        <Grid item sm={8}>
          {props.shareHolderArray.length}
        </Grid>

        {(props.companyFormationObject.idBusinessForm === 8 ||
          props.companyFormationObject.idBusinessForm === 7) && (
          <>
            <Grid item sm={4}>
              <b>Stammkapital:</b>
            </Grid>
            <Grid item sm={8}>
              {props.companyFormationObject.ShareCapital.toLocaleString(
                'de-DE',
                {
                  style: 'currency',
                  currency: 'EUR',
                }
              )}
            </Grid>

            <Grid item sm={4}>
              <b>Anteile:</b>
            </Grid>
            <Grid item sm={8}>
              {props.companyFormationShareArray
                .map(x => x.AmountOfShares)
                .reduce((a, b) => a + b, 0)}
            </Grid>

            {/*
                        <Grid item sm={4}><b>Preis pro Anteil:</b></Grid>
                        <Grid item sm={8}>{props.companyFormationObject.ValuePerShare.toLocaleString("de-DE", {
                            style: "currency",
                            currency: "EUR"
                        })}</Grid>
                         */}
          </>
        )}

        {/*
                    // Bestimmen, ob musterprokoll möglich ist
                    (
                        // online-if: nur GmbH oder UG
                        (
                            (props.companyFormationObject.idBusinessForm === 7 || props.companyFormationObject.idBusinessForm === 8)
                            // max. 3 Gesellschafter
                            && (props.shareHolderRelation.filter(x => x.idShareHolderType === 1).length <= 3)
                            // genau ein 1 geschöftsführer
                            && (props.shareHolderRelation.filter(x => x.idShareHolderType === 2).length === 1)
                            // und kein Prokuristen
                            && (props.shareHolderRelation.filter(x => x.idShareHolderType === 3).length === 0)
                        ) &&
                        <>
                            <Grid item sm={4}><Box sx={{mt: 1}}><b>Musterprotokoll:</b></Box></Grid>
                            <Grid item sm={8}>
                                <TextField
                                    size="small"
                                    type="number"
                                    fullWidth
                                    value={(props.companyFormationObject.useSampleProtocol) ? "true" : "false"}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setCompanyFormationObject({
                                        ...props.companyFormationObject,
                                        useSampleProtocol: (event.target.value === "true")
                                    })}
                                    select
                                >
                                    <MenuItem key={`useSampleProtocol-true`} value="true">Ja</MenuItem>
                                    <MenuItem key={`useSampleProtocol-false`} value="false">Nein</MenuItem>
                                </TextField>
                            </Grid>
                        </>
                    )
                */}
      </Grid>

      <CompanyFormationAnswerSummary
        companyFormationAnswerArray={props.companyFormationAnswerArray}
      />

      <Box sx={{ mt: 10 }} />
      <Typography variant='h6' sx={{ mb: 3 }}>
        Beteiligte
        <DialogEditRepresentation
          companyFormationObject={props.companyFormationObject}
          shareHolderArray={props.shareHolderArray}
          setShareHolderArray={props.setShareHolderArray}
          shareHolderRelation={props.shareHolderRelation}
          setShareHolderRelation={props.setShareHolderRelation}
        />
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Titel</TableCell>
            <TableCell>Geschlecht</TableCell>
            <TableCell>Vorname</TableCell>
            <TableCell>Nachname</TableCell>
            <TableCell>Rollen</TableCell>
            <TableCell>Aktionen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.shareHolderArray.map(x => (
            <TableRow>
              {x.idBusinessForm === 1 ? (
                <>
                  <TableCell>
                    {x.idAcademicTitle === 1
                      ? ''
                      : checkString(
                          academicTitleArray.find(
                            y => y.idAcademicTitle === x.idAcademicTitle
                          )?.AcademicTitle
                        )}
                  </TableCell>
                  <TableCell>
                    {checkString(
                      genderArray.find(y => y.idGender === x.idGender)?.Gender
                    )}
                  </TableCell>

                  <TableCell>{x.FirstName}</TableCell>
                  <TableCell>{x.LastName}</TableCell>
                </>
              ) : (
                <>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell colSpan={2}>{x.CompanyObject?.Company}</TableCell>
                </>
              )}

              <TableCell>
                {props.shareHolderRelation
                  .filter(y => y.idShareHolder === x.idShareHolder)
                  .map(x =>
                    x.idShareHolderType === 1
                      ? 'Gesellschafter'
                      : x.idShareHolderType === 2
                        ? 'Geschäftsführer'
                        : x.idShareHolderType === 3
                          ? 'Prokurist'
                          : x.idShareHolderType === 4
                            ? 'GbR: Konkrete Vertretung'
                            : 'Fehler'
                  )
                  .join(', ')}
              </TableCell>
              <TableCell>
                <IconButton onClick={() => setIdShareHolder(x.idShareHolder)}>
                  <Search />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {(props.companyFormationObject.idBusinessForm === 8 ||
        props.companyFormationObject.idBusinessForm === 7) && (
        <>
          {props.companyFormationObject.isFounding && (
            <SharesSummaryTable
              companyFormationObject={props.companyFormationObject}
              shareHolderArray={props.shareHolderArray}
              shareHolderRelation={props.shareHolderRelation}
              companyFormationShareArray={props.companyFormationShareArray}
              setCompanyFormationObject={props.setCompanyFormationObject}
              setCompanyFormationShareArray={
                props.setCompanyFormationShareArray
              }
              setShareHolderArray={props.setShareHolderArray}
              setShareHolderRelation={props.setShareHolderRelation}
            />
          )}
          <RepresentationSummary
            shareHolderArray={props.shareHolderArray}
            shareHolderRelation={props.shareHolderRelation}
          />
        </>
      )}
      {/*(props.legalTransactionObject?.Remarks !== null && props.companyFormationObject.isFounding != true) &&
                <Box sx={{pt: 10}}>
                    <TextField
                        id="filled-multiline-static"
                        label="Bemerkungen zur Änderung"
                        multiline
                        fullWidth
                        disabled
                        rows={4}
                        defaultValue={props.legalTransactionObject.Remarks}
                        variant="outlined"
                    />

                </Box>

            */}
    </>
  );
};
