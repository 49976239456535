import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Box,
  DialogActions,
} from '@mui/material';
import { XMLBuilder, XMLParser } from 'fast-xml-parser';
import xmlFormatter from 'xml-formatter';
import { GmbH } from './Rechtsformen/GmbH';
import gerichte from './jsons/gerichte.json'; // Importiere die JSON-Datei
import {
  ICompanyAuthorizedRepresentatives,
  ICompanyBondingDeposits,
  ICompanyFormationFullObject,
} from '../Interfaces/ICompanyFormation';
import { IShareHolderFullObject } from '../Interfaces/IShareHolder';
import vertretungsregelungJson from './jsons/vertretungsregelung.json';
import { ExportCompanyFormation } from '../LegalTransaction/CompanyFormation/ExportCompanyFormation';

interface IProps {
  xmlString: string;
  setXmlString: Function;
}

const XmlEditor: React.FC<IProps> = props => {
  /**
   * XML Parser
   */
  // parser Options
  const parserOptions = {
    ignoreAttributes: false,
    parseTagValue: false,
  };

  // Erstelle eine Instanz von XMLParser
  const parser = new XMLParser(parserOptions);

  /**
   * XML Builder
   */
  const builderOptions = {
    ignoreAttributes: false,
    textNodeName: '#text', // Behandelt Textinhalte als Knoten, nicht als Attribute
    alwaysCreateTextNode: true,
    format: true, // Optional: Formatieren der Ausgabe
  };

  const builder = new XMLBuilder(builderOptions);

  //

  const vertretungMap = new Map(
    vertretungsregelungJson.daten.map(([code, wert]) => [code, wert])
  );

  //

  /**
   * Functions to set the useStates
   */
  // find all elements with key function
  const findAllElementsByKey = (
    obj: any,
    key: string,
    results: any[] = []
  ): any[] => {
    if (!obj || typeof obj !== 'object') {
      return results;
    }

    if (obj.hasOwnProperty(key)) {
      results.push(obj[key]);
    }

    for (const k in obj) {
      if (obj.hasOwnProperty(k)) {
        findAllElementsByKey(obj[k], key, results);
      }
    }

    return results;
  };

  // find one element with key function
  const findElementByKey = (obj: any, key: string): any => {
    if (!obj || typeof obj !== 'object') {
      return null;
    }

    if (obj.hasOwnProperty(key)) {
      return obj[key];
    }

    for (const k in obj) {
      if (obj.hasOwnProperty(k)) {
        const result = findElementByKey(obj[k], key);
        if (result !== null) {
          return result;
        }
      }
    }

    return null;
  };

  // find the Rechtsform function
  const getRechtsForm = (jsonObj: any) => {
    const rechtsformObj = findElementByKey(jsonObj, 'tns:rechtsform');
    return rechtsformObj?.['code'] || null;
  };

  // Formatieren des XML-Strings vor der Anzeige
  const formatXml = (xml: string) => {
    return xmlFormatter(xml, { collapseContent: true });
  };

  //

  //

  /**
   * All useStates
   */
  const [formattedEditedXmlString, setFormattedEditedXmlString] = useState(
    formatXml(props.xmlString)
  );
  const [jsonObj, setJsonObj] = useState(parser.parse(props.xmlString));
  //
  const [rechtsForm, setRechtsForm] = useState<string>(getRechtsForm(jsonObj));

  /**
   * All Consts
   */
  const erstellungszeitpunktConst =
    jsonObj[`tns:nachricht.reg.0400003`][`tns:nachrichtenkopf`][
      `tns:erstellungszeitpunkt`
    ];
  const xjustizVersion =
    jsonObj['tns:nachricht.reg.0400003']['tns:nachrichtenkopf'][
      '@_xjustizVersion'
    ];

  const absenderConst =
    jsonObj[`tns:nachricht.reg.0400003`][`tns:nachrichtenkopf`][
      `tns:auswahl_absender`
    ][`tns:absender.gericht`][`code`];
  const produktnameConst =
    jsonObj[`tns:nachricht.reg.0400003`][`tns:nachrichtenkopf`][
      `tns:herstellerinformation`
    ][`tns:nameDesProdukts`];
  const herstellerConst =
    jsonObj[`tns:nachricht.reg.0400003`][`tns:nachrichtenkopf`][
      `tns:herstellerinformation`
    ][`tns:herstellerDesProdukts`];
  const versionConst =
    jsonObj[`tns:nachricht.reg.0400003`][`tns:nachrichtenkopf`][
      `tns:herstellerinformation`
    ][`tns:version`];

  // Konvertiere die Datenstruktur in ein geeignetes Format für Map
  const gerichteMap = new Map(
    gerichte.daten.map(([code, wert]) => [code, wert])
  );

  const getBusinessForm = (rechtsForm2: string) => {
    switch (rechtsForm2) {
      case 'GmbH':
        return 8;
      case 'KG':
        return 6;
      case 'eGbR':
        return 3;
      case 'eK':
        return 4;
      case 'AG':
        return 9;
      case 'OHG':
        return 5;
      default:
        return 8;
    }
  };

  const dateString =
    findElementByKey(jsonObj, 'tns:satzungsdatum')?.[
      'tns:aktuellesSatzungsdatum'
    ] || '';

  const DateOfFormation = dateString ? new Date(dateString) : null;

  const createBondingDepositsArray = (
    data: any[] | any
  ): ICompanyBondingDeposits[] => {
    if (Array.isArray(data)) {
      return data.map((item: any) => {
        return {
          idShareholder: parseInt(item?.['tns:ref.rollennummer']),
          bondingDeposits: parseInt(item?.['tns:hafteinlage']['tns:zahl']),
          currency: item?.['tns:hafteinlage']?.['tns:waehrung']?.['code'],
        };
      });
    } else {
      const item = data;
      return [
        {
          idShareholder: parseInt(item?.['tns:ref.rollennummer']),
          bondingDeposits: parseInt(item?.['tns:hafteinlage']['tns:zahl']),
          currency: item?.['tns:hafteinlage']?.['tns:waehrung']?.['code'],
        },
      ];
    }
  };

  const createAuthorizedRepresentativesArray = (
    data: any[] | any
  ): ICompanyAuthorizedRepresentatives[] => {
    if (Array.isArray(data)) {
      // Wenn `data` ein Array ist, mappe es wie bisher
      return data.map((item: any) => {
        return {
          idShareholder: parseInt(item?.['tns:ref.rollennummer']),
          text:
            item?.['tns:besondereVertretungsregelung']?.[
              'tns:auswahl_vertretungsbefugnis'
            ]?.['tns:vertretungsbefugnisFreitext'] ||
            vertretungMap.get(
              item?.['tns:besondereVertretungsregelung']?.[
                'tns:auswahl_vertretungsbefugnis'
              ]?.['tns:vertretungsbefugnis']?.['code']
            ),
        };
      });
    } else {
      // Wenn `data` kein Array ist, behandle es als Einzelobjekt
      const item = data;
      return [
        {
          idShareholder: parseInt(item?.['tns:ref.rollennummer']),
          text:
            item?.['tns:besondereVertretungsregelung']?.[
              'tns:auswahl_vertretungsbefugnis'
            ]?.['tns:vertretungsbefugnisFreitext'] ||
            vertretungMap.get(
              item?.['tns:besondereVertretungsregelung']?.[
                'tns:auswahl_vertretungsbefugnis'
              ]?.['tns:vertretungsbefugnis']?.['code']
            ),
        },
      ];
    }
  };

  const createShareHolderArray = (
    data: any[] | any
  ): IShareHolderFullObject[] => {
    if (Array.isArray(data)) {
      // Wenn `data` ein Array ist, mappe es wie bisher
      return data.map((item: any) => {
        const naturalPerson =
          item['tns:beteiligter']['tns:auswahl_beteiligter'][
            'tns:natuerlichePerson'
          ];

        return {
          idShareHolder: parseInt(item?.['tns:rolle']?.['tns:rollennummer']),
          idGender: 0,
          idAcademicTitle: 0,
          idBusinessForm: 0,
          FirstName: naturalPerson['tns:vollerName']['tns:vorname'] || '',
          LastName: naturalPerson['tns:vollerName']['tns:nachname'] || '',
          Birthday: naturalPerson['tns:geburt']?.['tns:geburtsdatum'] || null,
          Birthplace: null,
          Street: item['tns:anschrift']?.['tns:strasse'] || '',
          StreetNr: item['tns:anschrift']?.['tns:hausnummer'] || '',
          StreetAditional: '',
          idPostcode: null,
          Company: null,
          idCompany: null,
          Deposit: 0,
          isPartPayment: false,
          isShareByNonCashResources: false,
          Shares: 0,
          Postcode: item['tns:anschrift']?.['tns:postleitzahl'] || '',
          City: item['tns:anschrift']?.['tns:ort'] || '',
        };
      });
    } else {
      // Wenn `data` kein Array ist, behandle es als Einzelobjekt
      const item = data;
      const naturalPerson =
        item['tns:beteiligter']['tns:auswahl_beteiligter'][
          'tns:natuerlichePerson'
        ];

      return [
        {
          idShareHolder: parseInt(item?.['tns:rolle']?.['tns:rollennummer']),
          idGender: 0,
          idAcademicTitle: 0,
          idBusinessForm: 0,
          FirstName: naturalPerson['tns:vollerName']['tns:vorname'] || '',
          LastName: naturalPerson['tns:vollerName']['tns:nachname'] || '',
          Birthday: naturalPerson['tns:geburt']?.['tns:geburtsdatum'] || null,
          Birthplace: null,
          Street: item['tns:anschrift']?.['tns:strasse'] || '',
          StreetNr: item['tns:anschrift']?.['tns:hausnummer'] || '',
          StreetAditional: '',
          idPostcode: null,
          Company: null,
          idCompany: null,
          Deposit: 0,
          isPartPayment: false,
          isShareByNonCashResources: false,
          Shares: 0,
          Postcode: item['tns:anschrift']?.['tns:postleitzahl'] || '',
          City: item['tns:anschrift']?.['tns:ort'] || '',
        },
      ];
    }
  };

  // Anwendung der Funktion
  const shareHolderArray = createShareHolderArray(
    findAllElementsByKey(jsonObj, 'tns:beteiligung')?.[0]?.filter(
      (x: any) =>
        x['tns:beteiligter']['tns:auswahl_beteiligter']['tns:natuerlichePerson']
    ) || []
  );

  const authorizedRepresentativesArray = createAuthorizedRepresentativesArray(
    findElementByKey(jsonObj, 'tns:vertretung')?.[
      'tns:vertretungsberechtigte'
    ] || []
  );

  const bondingDepositsArray = createBondingDepositsArray(
    findElementByKey(jsonObj, 'tns:zusatzKG')?.['tns:datenKommanditist'] || []
  );

  const [companyFormationFullObject, setCompanyFormationFullObject] =
    useState<ICompanyFormationFullObject>({
      idCompanyFormation: -1,
      idLegalTransaction: -1,
      isFounding: false,
      idBusinessForm: getBusinessForm(rechtsForm), // muss gesetzt werden
      CompanyName:
        jsonObj?.[`tns:nachricht.reg.0400003`]?.[`tns:grunddaten`]?.[
          `tns:verfahrensdaten`
        ]?.[`tns:beteiligung`]?.[0]?.[`tns:beteiligter`]?.[
          `tns:auswahl_beteiligter`
        ]?.[`tns:organisation`]?.[`tns:bezeichnung`]?.[
          `tns:bezeichnung.aktuell`
        ] || '',
      Street:
        jsonObj?.[`tns:nachricht.reg.0400003`]?.[`tns:grunddaten`]?.[
          `tns:verfahrensdaten`
        ]?.[`tns:beteiligung`]?.[0]?.[`tns:beteiligter`]?.[
          `tns:auswahl_beteiligter`
        ]?.[`tns:organisation`]?.[`tns:anschrift`]?.[`tns:strasse`] || '',
      StreetNr:
        jsonObj?.[`tns:nachricht.reg.0400003`]?.[`tns:grunddaten`]?.[
          `tns:verfahrensdaten`
        ]?.[`tns:beteiligung`]?.[0]?.[`tns:beteiligter`]?.[
          `tns:auswahl_beteiligter`
        ]?.[`tns:organisation`]?.[`tns:anschrift`]?.[`tns:hausnummer`] || '',
      idPostcode: -1,
      PurposeOfTheCompany:
        findElementByKey(jsonObj, 'tns:basisdatenRegister')?.[
          'tns:gegenstand'
        ] || '',
      DateOfFormation: DateOfFormation,
      ShareCapital:
        parseFloat(
          findElementByKey(jsonObj, 'tns:stammkapital')?.['tns:zahl']
        ) ||
        parseFloat(
          findElementByKey(jsonObj, 'tns:grundkapital')?.['tns:hoehe']?.[
            'tns:zahl'
          ]
        ) ||
        0, // muss gesetzt werden
      AmountShares: -1,
      ValuePerShare: -1,
      useSampleProtocol: false,
      AddressStreet: '',
      AddressStreetNr: '',
      Postcode_AddressidPostcode: -1,
      Postcode:
        jsonObj?.[`tns:nachricht.reg.0400003`]?.[`tns:grunddaten`]?.[
          `tns:verfahrensdaten`
        ]?.[`tns:beteiligung`]?.[0]?.[`tns:beteiligter`]?.[
          `tns:auswahl_beteiligter`
        ]?.[`tns:organisation`]?.[`tns:anschrift`]?.[`tns:postleitzahl`] || '',
      City:
        jsonObj?.[`tns:nachricht.reg.0400003`]?.[`tns:grunddaten`]?.[
          `tns:verfahrensdaten`
        ]?.[`tns:beteiligung`]?.[0]?.[`tns:beteiligter`]?.[
          `tns:auswahl_beteiligter`
        ]?.[`tns:organisation`]?.[`tns:anschrift`]?.[`tns:ort`] || '',

      ShareHolderArray: shareHolderArray,
      CompanyFormationShareArray: [], // bleibt leer
      CompanyFormationHasShareHolderArray: [], // bleibt leer
      CompanyFormationAnswerArray: [], // bleibt leer
      DocumentArray: [], // bleibt leer
      AuthorizedRepresentativesArray: authorizedRepresentativesArray,
      BondingDeposits: bondingDepositsArray,
    });

  //

  //

  /**
   * All functions
   */
  // download XML function
  const downloadXml = () => {
    const updatedXmlString = builder.build(jsonObj);
    if (updatedXmlString) {
      const blob = new Blob([updatedXmlString], { type: 'text/xml' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'downloaded.xml'; // Der Dateiname der heruntergeladenen Datei
      a.click();
      URL.revokeObjectURL(url); // Bereinigt die URL
    }
  };

  //
  /**
   * <Box mt={6}>
        <Accordion>
          <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
            <Typography>Nachrichtenkopf</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <TextField
                  label="Erstellungszeitupunkt"
                  value={erstellungszeitpunktConst}
                  fullWidth
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  label="xjustizVersion"
                  value={xjustizVersion}
                  fullWidth
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  label="Absender"
                  value={gerichteMap.get(absenderConst)}
                  fullWidth
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Box mt={2} ml={2}>
                {" "}
                <Typography variant="h6">HerstellerInformationen</Typography>
              </Box>
              <Grid item sm={12}>
                <TextField
                  label="Produktname"
                  value={produktnameConst}
                  fullWidth
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  label="Hersteller"
                  value={herstellerConst}
                  fullWidth
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  label="Version"
                  value={versionConst}
                  fullWidth
                  variant="outlined"
                  disabled
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
   */

  //

  /**
   * useEffects
   */
  //

  //
  useEffect(() => {
    const updatedXmlString = builder.build(jsonObj);
    setFormattedEditedXmlString(formatXml(updatedXmlString));
  }, [jsonObj]);

  //

  //
  const boolean = false;

  return (
    <Box mt={6} mb={6}>
      {boolean && (
        <>
          <Typography variant='h6' gutterBottom style={{ marginTop: '20px' }}>
            XML-String
          </Typography>
          <TextField
            value={formattedEditedXmlString}
            fullWidth
            multiline
            rows={20}
            variant='outlined'
            InputProps={{
              readOnly: true,
            }}
          />
        </>
      )}

      {(rechtsForm === 'GmbH' ||
        rechtsForm === 'eGbR' ||
        rechtsForm === 'eK' ||
        rechtsForm === 'AG' ||
        rechtsForm === 'KG' ||
        rechtsForm === 'OHG') && (
        <GmbH
          jsonObj={jsonObj}
          findElementByKey={findElementByKey}
          findAllElementsByKey={findAllElementsByKey}
          setJsonObj={setJsonObj}
          rechtsForm={rechtsForm}
          companyFormationFullObject={companyFormationFullObject}
          setCompanyFormationFullObject={setCompanyFormationFullObject}
          createShareHolderArray={createShareHolderArray}
          createAuthorizedRepresentativesArray={
            createAuthorizedRepresentativesArray
          }
          createBondingDepositsArray={createBondingDepositsArray}
        />
      )}
      <DialogActions sx={{ justifyContent: 'flex-end', marginTop: 2 }}>
        <Button onClick={downloadXml} variant='contained'>
          Download als XML
        </Button>
        <ExportCompanyFormation
          companyFormationFullObject={companyFormationFullObject}
        />
      </DialogActions>
    </Box>
  );
};

export default XmlEditor;
