import React, { useState } from 'react';
import { ISearchAPI } from '../../Interfaces/ISearchAPI';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { uploadFetch } from '../../hooks/useFetch';
import { State } from '../../redux/mainReducer';
import { useSelector } from 'react-redux';
import { CustomeTextField } from '../../generic/CustomeTextField';
import { CustomCircularProgress } from '../../generic/CustomCircularProgress';
import { IDocumentDirectory } from '../../Interfaces/IDocumentDirectory';
import { IDocumentDirectoryHasDocument } from '../../Interfaces/IDocumentDirectoryHasDocument';
import { MasterportalViewer } from '../../Masterportal/MasterportalViewer';

interface IPayload {
  idDistrictCourt: null | number;
  idLandMarking: null | number;
  Court: null | string;
  Marking: null | string;
  SearchString: null | string;
  DownloadArray: IResult[];
}

export interface IResult {
  Landregister: string;
  SheetNumber: string;
  Marking: string;
  CadastralDistrict: string;
  CadastralUnit: string;
  Location: string;
  OrderNumber: string;
  Ges: string;
  LastName: string;
  FirstName: string;
  Birthname: string;
  Birthday: string;
}

interface IResultPasswordShouldBeChanged {
  PasswordShouldBeChanged: boolean;
}

interface IResultDownload {
  DocumentDirectory: IDocumentDirectory;
  DocumentArray: IDocumentDirectoryHasDocument[];
}

interface IProps {
  idLegalTransaction: number;
  documentArray: IDocumentDirectoryHasDocument[];
  documentDirectoryArray: IDocumentDirectory[];
  setDocumentArray: Function;
  setDocumentDirectoryArray: Function;
}

export const LandregisterSerach: React.FC<IProps> = ({
  idLegalTransaction,
  documentArray,
  documentDirectoryArray,
  setDocumentArray,
  setDocumentDirectoryArray,
}) => {
  const is_enabled_landregister =
    localStorage.getItem('is_enabled_landregister') === 'true';
  // LAde vom BAckend
  const districtCourtArray = useSelector(
    (state: State) => state.districtCourtArray.storeDistrictCourt
  );
  const landMarkingArray = useSelector(
    (state: State) => state.landMarkingArray.storeLandMarking
  );
  //
  const [payloadObject, setPayloadObject] = useState<IPayload>({
    idDistrictCourt: null,
    idLandMarking: null,
    Court: null,
    Marking: null,
    SearchString: null,
    DownloadArray: [],
  });
  //
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  //
  const [passwordShouldBeChanged, setPasswordShouldBeChanged] = useState(false);
  //
  const [landregisterSearch, setLandregisterSearch] = useState({
    Payload: null,
    Results: null,
    Cookies: [],
    idLegalTransaction: idLegalTransaction,
  } as ISearchAPI);
  const [isResultLoading, setIsResultLoading] = useState(false);
  const [resultArray, setResultArray] = useState<IResult[] | null>(null);
  const [wasSuccessfully, setWasSuccessfully] = useState(true);
  //
  const [isOpenDownloadWarning, setIsOpenDownloadWarning] = useState(false);
  const [downloadPayload, setDownloadPayload] = useState<IResult[]>([]);
  const [downloadResult, setDownloadResult] = useState<IResultDownload | null>(
    null
  );
  //
  const [isOpenWasDownloaded, setIsOpenWasDownloaded] = useState(false);

  ///// Starten der Suche
  const handleLoadLandregister = () => {
    handleResetSearch();
    setIsOpenSearch(true);
  };

  ///// Starten der Suche
  const hanldeCloseDialog = () => {
    handleResetSearch();
    setIsOpenSearch(false);
  };

  ///// Suche und Verarbeiten der Rückgate
  const handleREsSearch = (res: IResult[] | IResultPasswordShouldBeChanged) => {
    if ('PasswordShouldBeChanged' in res) {
      setPasswordShouldBeChanged(true);
    } else {
      setResultArray(res);
    }
  };

  const handleSearch = () => {
    setResultArray(null);
    setDownloadPayload([]);
    setWasSuccessfully(true);

    const uplodObject = {
      ...landregisterSearch,
      Payload: payloadObject,
    };
    uploadFetch(
      '/landregister/search',
      true,
      uplodObject,
      handleREsSearch,
      setWasSuccessfully,
      () => {},
      setIsResultLoading
    );
  };

  ///// Zurücksetzen der Suche
  const handleResetSearch = () => {
    // Zurücksetzen der Suche-States
    setResultArray(null);
    setDownloadPayload([]);
    setPayloadObject({
      idDistrictCourt: null,
      idLandMarking: null,
      Court: null,
      Marking: null,
      SearchString: null,
      DownloadArray: [],
    });
    setDownloadResult(null);
    // Schließen der optionalen Dialoge
    setIsOpenDownloadWarning(false);
    setIsOpenWasDownloaded(false);
    // Zurücksetzten der Healthy-States
    setWasSuccessfully(true);
    setIsResultLoading(false);
  };

  ///// Download und Verarbeiten
  const handleResDownload = (
    res: IResultDownload | IResultPasswordShouldBeChanged
  ) => {
    setIsOpenDownloadWarning(false);
    setIsOpenWasDownloaded(true);

    if ('PasswordShouldBeChanged' in res) {
      setPasswordShouldBeChanged(true);
    } else {
      setDownloadResult(res);

      /// if: Suchen und ORdner ggf. hinzufügen
      const foundDirectory = documentDirectoryArray.find(
        x => x.idDocumentDirectory === res.DocumentDirectory.idDocumentDirectory
      );
      if (foundDirectory === undefined) {
        setDocumentDirectoryArray([
          ...documentDirectoryArray,
          res.DocumentDirectory,
        ]);
      }

      // Hinzufügen der (neuen) Documente
      setDocumentArray([...documentArray, ...res.DocumentArray]);
    }
  };

  const handleDownloadDocument = () => {
    const uplodObject = {
      ...landregisterSearch,
      Payload: {
        ...payloadObject,
        DownloadArray: downloadPayload,
      },
    };
    setIsOpenDownloadWarning(false);
    //setResultArray(null);
    uploadFetch(
      '/landregister/download',
      true,
      uplodObject,
      handleResDownload,
      setWasSuccessfully,
      () => {},
      setIsResultLoading
    );
  };

  return (
    <>
      <Dialog open={isOpenWasDownloaded} onClose={hanldeCloseDialog}>
        <DialogTitle>Download bestätigen</DialogTitle>
        <DialogContent>
          <Alert severity='success'>
            <AlertTitle>Grundbuchauszüge wurden bezogen!</AlertTitle>
            Es wurden die folgen Dokumente zum Ordner <i>Grundbuchauszüge</i> in
            der <u>internen Datenablage</u> hinzugefügt:
            <ul>
              {downloadResult?.DocumentArray.map(x => <li>{x.FileName}</li>)}
            </ul>
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={hanldeCloseDialog}>
            Schließen
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isOpenDownloadWarning}
        onClose={() => setIsOpenDownloadWarning(false)}
      >
        <DialogTitle>Download bestätigen</DialogTitle>
        <DialogContent>
          <Alert severity='warning'>
            <AlertTitle>Achtung: Kostenpflichtig!</AlertTitle>
            Für jeden Bezug eines Grundbucheintrags fallen kosten an.
            <br />
            Sind Sie sich sicher, dass Sie {downloadPayload.length}{' '}
            Grundbucheinträge beziehen wollen?
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleDownloadDocument}>
            Beziehen
          </Button>
          <Button
            variant='contained'
            onClick={() => setIsOpenDownloadWarning(false)}
          >
            Abbruch
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={passwordShouldBeChanged}
        onClose={() => setPasswordShouldBeChanged(false)}
      >
        <DialogContent>
          <Alert severity='info'>
            <AlertTitle>Die Zugangsdaten sind abgelaufen!</AlertTitle>
            Sie müssen in SolumWEB das Kennwort aktualisieren.
            <br /> Kontaktieren Sie dafür einen Administrator.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={() => setPasswordShouldBeChanged(false)}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isOpenSearch}
        onClose={hanldeCloseDialog}
        maxWidth='lg'
        fullWidth
      >
        <DialogTitle>Grundbuch</DialogTitle>
        <DialogContent>
          <Box sx={{ m: 2 }}>
            <Card variant='outlined'>
              <Typography variant='caption' sx={{ ml: 1 }}>
                Suche
              </Typography>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <CustomeTextField
                      label='Gericht'
                      attr='idDistrictCourt'
                      object={payloadObject}
                      disabled={
                        isResultLoading ||
                        (resultArray !== null && resultArray.length !== 0)
                      }
                      setObject={(payload: IPayload) => {
                        const testDistrictObject = districtCourtArray.find(
                          x => x.idCourt === payload.idDistrictCourt
                        );
                        if (testDistrictObject !== undefined) {
                          setPayloadObject({
                            ...payloadObject,
                            idDistrictCourt: testDistrictObject['idCourt'],
                            Court: testDistrictObject['Court'],
                            idLandMarking: null,
                            Marking: null,
                          });
                        } else if (payload.idDistrictCourt === null) {
                          setPayloadObject({
                            ...payloadObject,
                            idDistrictCourt: null,
                            Court: null,
                            idLandMarking: null,
                            Marking: null,
                          });
                        }
                      }}
                      type='select'
                      null
                      required
                    >
                      {districtCourtArray.map(x => (
                        <MenuItem key={`court-${x.idCourt}`} value={x.idCourt}>
                          {x.Court}
                        </MenuItem>
                      ))}
                    </CustomeTextField>
                  </Grid>

                  <Grid item xs={6}>
                    <CustomeTextField
                      label='Gemarkung'
                      attr='idLandMarking'
                      object={payloadObject}
                      disabled={
                        isResultLoading ||
                        (resultArray !== null && resultArray.length !== 0)
                      }
                      required
                      setObject={(payload: IPayload) => {
                        const testLandMarking = landMarkingArray.find(
                          x => x.idLandMarking === payload.idLandMarking
                        );

                        if (testLandMarking !== undefined) {
                          const testDistrictObject = districtCourtArray.find(
                            x => x.idCourt === testLandMarking?.idDistrictCourt
                          );
                          if (testDistrictObject !== undefined) {
                            setPayloadObject({
                              ...payloadObject,
                              idDistrictCourt: testDistrictObject['idCourt'],
                              Court: testDistrictObject['Court'],
                              idLandMarking: testLandMarking.idLandMarking,
                              Marking: testLandMarking.LandMarking,
                            });
                          }
                        } else if (payload.idLandMarking === null) {
                          setPayloadObject({
                            ...payloadObject,
                            idLandMarking: null,
                            Marking: null,
                          });
                        }
                      }}
                      type='select'
                      null
                    >
                      {landMarkingArray
                        .filter(
                          x =>
                            payloadObject.idDistrictCourt === null ||
                            x.idDistrictCourt === payloadObject.idDistrictCourt
                        )
                        .sort((a, b) =>
                          a.LandMarking > b.LandMarking ? 1 : -1
                        )
                        .map(x => (
                          <MenuItem
                            key={`landmarking-${x.idLandMarking}`}
                            value={x.idLandMarking}
                          >
                            {x.LandMarking}{' '}
                            {payloadObject.idDistrictCourt === null && (
                              <>
                                {' '}
                                (
                                {
                                  districtCourtArray.find(
                                    y => y.idCourt === x.idDistrictCourt
                                  )?.Court
                                }
                                )
                              </>
                            )}
                          </MenuItem>
                        ))}
                    </CustomeTextField>
                  </Grid>

                  <Grid item xs={12}>
                    <CustomeTextField
                      label='Suchbegriff'
                      attr='SearchString'
                      object={payloadObject}
                      setObject={setPayloadObject}
                      disabled={
                        isResultLoading ||
                        (resultArray !== null && resultArray.length !== 0)
                      }
                      type='string'
                      null
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', float: 'right' }}>
                      <Collapse
                        in={
                          isResultLoading ||
                          (resultArray !== null && resultArray.length !== 0)
                        }
                      >
                        <Button
                          variant='outlined'
                          sx={{ float: 'right', mr: 2 }}
                          onClick={handleResetSearch}
                          disabled={
                            payloadObject.SearchString === null ||
                            payloadObject.SearchString === '' ||
                            payloadObject.idDistrictCourt === null ||
                            isResultLoading
                          }
                        >
                          Suchen Zurücksetzen
                        </Button>
                      </Collapse>
                      <Button
                        variant='outlined'
                        sx={{ float: 'right' }}
                        onClick={handleSearch}
                        disabled={
                          payloadObject.SearchString === null ||
                          payloadObject.SearchString === '' ||
                          payloadObject.idDistrictCourt === null ||
                          payloadObject.idLandMarking === null ||
                          isResultLoading ||
                          (resultArray !== null && resultArray.length !== 0)
                        }
                      >
                        Suchen
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Collapse in={isResultLoading || resultArray !== null}>
              <Card variant='outlined' sx={{ mt: 3 }}>
                <Typography variant='caption' sx={{ ml: 1 }}>
                  Grundbucheinträge
                </Typography>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {!wasSuccessfully ? (
                        <Alert severity='error'>
                          Es ist ein Fehler aufgeretten!
                          <br />
                          Bitte kontaktieren Sie einen Administrator.
                        </Alert>
                      ) : isResultLoading ? (
                        <>
                          <Typography>
                            Die Suche/das Laden kann mehrere Minuten in Anspruch
                            nehmen, bitte haben Sie Geduld...
                          </Typography>
                          <CustomCircularProgress />
                        </>
                      ) : resultArray?.length === 0 ? (
                        <>
                          <Typography>Die Suche ergab kein Treffer!</Typography>
                        </>
                      ) : (
                        <>
                          <Typography>
                            Einträge mit gleichen Grundbuch und Blattnummer
                            werden gemeinsam ausgewählt.{' '}
                          </Typography>
                          <Table sx={{ mt: 3 }} size='small'>
                            <TableHead>
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Grundbuchbez.</TableCell>
                                <TableCell>Blattnr.</TableCell>
                                <TableCell>Gemarkung</TableCell>
                                <TableCell>Flur</TableCell>
                                <TableCell>Flurstück</TableCell>
                                <TableCell>Lage</TableCell>
                                <TableCell>Auf.Nr.</TableCell>
                                <TableCell>Ges</TableCell>
                                <TableCell>Vorrname</TableCell>
                                <TableCell>Nachname</TableCell>
                                <TableCell>Geburtsname</TableCell>
                                <TableCell>Geburtstag</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {resultArray?.map((resultObj, idx) => (
                                <TableRow key={`landregister-res-${idx}`}>
                                  <TableCell>
                                    <Checkbox
                                      checked={
                                        downloadPayload.find(
                                          x =>
                                            x.Landregister === x.Landregister &&
                                            x.SheetNumber === x.SheetNumber
                                        ) === undefined
                                          ? false
                                          : true
                                      }
                                      onChange={() => {
                                        if (
                                          downloadPayload.find(
                                            x =>
                                              x.Landregister ===
                                                x.Landregister &&
                                              x.SheetNumber === x.SheetNumber
                                          ) === undefined
                                        ) {
                                          setDownloadPayload([
                                            ...downloadPayload,
                                            resultObj,
                                          ]);
                                        } else {
                                          setDownloadPayload([
                                            ...downloadPayload.filter(
                                              x =>
                                                x.Landregister !==
                                                  x.Landregister &&
                                                x.SheetNumber !== x.SheetNumber
                                            ),
                                          ]);
                                        }
                                      }}
                                      inputProps={{
                                        'aria-label': 'controlled',
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {resultObj.Landregister}
                                  </TableCell>
                                  <TableCell>{resultObj.SheetNumber}</TableCell>
                                  <TableCell>{resultObj.Marking}</TableCell>
                                  <TableCell>
                                    {resultObj.CadastralDistrict}
                                  </TableCell>
                                  <TableCell>
                                    {resultObj.CadastralUnit}
                                  </TableCell>
                                  <TableCell>{resultObj.Location}</TableCell>
                                  <TableCell>{resultObj.OrderNumber}</TableCell>
                                  <TableCell>{resultObj.Ges}</TableCell>
                                  <TableCell>{resultObj.FirstName}</TableCell>
                                  <TableCell>{resultObj.LastName}</TableCell>
                                  <TableCell>{resultObj.Birthname}</TableCell>
                                  <TableCell>{resultObj.Birthday}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant='outlined'
                        sx={{ float: 'right' }}
                        onClick={() => setIsOpenDownloadWarning(true)}
                        disabled={
                          downloadPayload.length === 0 || isResultLoading
                        }
                      >
                        Download
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Collapse>
            <Box sx={{ mt: 5, alignItems: 'center' }}>
              <MasterportalViewer resultArray={resultArray} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={hanldeCloseDialog}>
            Schließen
          </Button>
        </DialogActions>
      </Dialog>

      <Button
        variant='outlined'
        sx={{ float: 'right', mr: 2 }}
        onClick={handleLoadLandregister}
        title={
          is_enabled_landregister
            ? 'Grundbuchauszug beziehen'
            : 'Sie haben keine Zugangsdaten für das Grundbuch hinterlegt. Bitte melden Sie sich ggf. bei Ihrem Administrator'
        }
        disabled={!is_enabled_landregister}
      >
        Grundbuch abfragen
      </Button>
    </>
  );
};
