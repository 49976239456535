import React, { useEffect, useRef, useState } from 'react';
import { IMortgage } from '../../../../Interfaces/IMortgage';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

interface IProps {
  mortgageObject: IMortgage;
  setMortgageObject: Function;
  isSavePossibleValue: Record<string, boolean>;
  setIsSavePossible: Function;
}

export const MortgageDetails: React.FC<IProps> = ({
  mortgageObject,
  setMortgageObject,
  isSavePossibleValue,
  setIsSavePossible,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [currentMortgageObject, setCurrentMortgageObject] =
    useState(mortgageObject);

  const handleSubmit = () => {
    setIsSavePossible({
      ...isSavePossibleValue,
      accordionMortgageDetails: true,
    } as Record<string, boolean>);
    setMortgageObject(currentMortgageObject);
  };

  /**
   * Form Function
   */
  const handleOnSave = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      handleSubmit();
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements).reverse();

      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  useEffect(() => {
    setIsSavePossible({
      ...isSavePossibleValue,
      accordionMortgageDetails:
        JSON.stringify(mortgageObject) ===
        JSON.stringify(currentMortgageObject),
    } as Record<string, boolean>);
  }, [currentMortgageObject]);

  useEffect(() => {
    setCurrentMortgageObject(mortgageObject);
  }, [mortgageObject]);

  return (
    <>
      <form ref={formRef}>
        <Box sx={{ mt: 4 }} />
        <Typography variant='h5'>Grundpfand</Typography>

        <Typography sx={{ mb: 2, mt: 6 }}>
          Neue/ zu löschende Grundschuld
        </Typography>
        <FormControl component='fieldset'>
          <RadioGroup
            row
            aria-label='for-who'
            name='who'
            value={currentMortgageObject.ToBeDeleted ? 'delete' : 'new'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setCurrentMortgageObject({
                ...currentMortgageObject,
                ToBeDeleted: event.target.value === 'delete',
              })
            }
          >
            <FormControlLabel
              value='delete'
              control={<Radio required />}
              label='Zu löschen'
            />
            <FormControlLabel
              value='new'
              control={<Radio required />}
              label='Neu'
            />
          </RadioGroup>
        </FormControl>

        <Typography sx={{ mb: 2, mt: 6 }}>
          Für wen soll die Grundschuld bestellt werden?
        </Typography>
        <FormControl component='fieldset'>
          <RadioGroup
            aria-label='for-who'
            name='who'
            value={currentMortgageObject.BeneficiaryType}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setCurrentMortgageObject({
                ...currentMortgageObject,
                BeneficiaryType: event.target.value,
              })
            }
          >
            <FormControlLabel
              value='INSTITUTION'
              control={<Radio required />}
              label='Eine Bank (auch [Bau-] Sparkasse) oder eine Versicherung'
            />
            <FormControlLabel
              value='PRIVATE'
              control={<Radio required />}
              label='Eine Privatperson oder ein sonstiges Unternehmen'
            />
          </RadioGroup>
        </FormControl>
        <Box sx={{ mt: 6 }} />
        <Typography sx={{ mb: 2 }}>
          Wird die Grundschuld zur Finanzierung eines Immobilienkaufs benötigt?
        </Typography>
        <FormControl component='fieldset'>
          <RadioGroup
            row
            aria-label='property'
            name='property-buy'
            value={currentMortgageObject.PropertyForBuying ? 'yes' : 'no'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.value === 'yes') {
                setCurrentMortgageObject({
                  ...currentMortgageObject,
                  PropertyForBuying: true,
                });
              } else {
                setCurrentMortgageObject({
                  ...currentMortgageObject,
                  PropertyForBuying: false,
                  PropertyOwned: null,
                  DocumentIndexNumber: '',
                  OperationNumber: '',
                });
              }
            }}
          >
            <FormControlLabel
              value='yes'
              control={<Radio required />}
              label='Ja'
            />
            <FormControlLabel
              value='no'
              control={<Radio required />}
              label='Nein'
            />
          </RadioGroup>
        </FormControl>
        {currentMortgageObject.PropertyForBuying && (
          <>
            <Box sx={{ mt: 6 }} />
            <Typography sx={{ mb: 2 }}>
              Ist der Immobilienkauf bereits erfolgt?
            </Typography>
            <FormControl component='fieldset'>
              <RadioGroup
                row
                aria-label='property-owned'
                name='property-owned'
                value={
                  currentMortgageObject.PropertyOwned === null ||
                  currentMortgageObject.PropertyForBuying === undefined
                    ? ''
                    : currentMortgageObject.PropertyOwned
                      ? 'yes'
                      : 'no'
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.value === 'yes') {
                    setCurrentMortgageObject({
                      ...currentMortgageObject,
                      PropertyOwned: true,
                    });
                  } else {
                    setCurrentMortgageObject({
                      ...currentMortgageObject,
                      PropertyOwned: false,
                      DocumentIndexNumber: '',
                      OperationNumber: '',
                    });
                  }
                }}
              >
                <FormControlLabel
                  value='yes'
                  control={<Radio required />}
                  label='Ja'
                />
                <FormControlLabel
                  value='no'
                  control={<Radio required />}
                  label='Nein'
                />
              </RadioGroup>
            </FormControl>
            {currentMortgageObject.PropertyOwned && (
              <>
                <Box sx={{ mt: 6 }} />
                <Typography sx={{ mb: 2 }}>
                  Geben Sie die Urkundenverzeichnisnummer ein (sog. UVZ-Nummer)?
                </Typography>
                <Grid item xs={6} sm={4}>
                  <TextField
                    label='Urkundenverzeichnisnummer'
                    size='small'
                    fullWidth
                    value={currentMortgageObject.DocumentIndexNumber}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setCurrentMortgageObject({
                        ...currentMortgageObject,
                        DocumentIndexNumber: event.target.value,
                      })
                    }
                    required
                  />
                </Grid>
                <Box sx={{ mt: 6 }} />
                <Typography sx={{ mb: 2 }}>
                  Geben Sie die Vorgangsnummer (sechsstellig, befindet sich
                  ausgegraut auf der ersten Seite des Entwurfs) an.
                </Typography>
                <Grid item xs={6} sm={4}>
                  <TextField
                    label='Vorgangsnummer'
                    size='small'
                    fullWidth
                    value={currentMortgageObject.OperationNumber}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setCurrentMortgageObject({
                        ...currentMortgageObject,
                        OperationNumber: event.target.value,
                      })
                    }
                    required
                  />
                </Grid>
              </>
            )}
          </>
        )}
        <Box sx={{ mt: 6 }} />
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            disabled={
              JSON.stringify(mortgageObject) ===
              JSON.stringify(currentMortgageObject)
            }
            variant='outlined'
            onClick={() => {
              setCurrentMortgageObject(mortgageObject);
              setIsSavePossible({
                ...isSavePossibleValue,
                accordionMortgageDetails: true,
              } as Record<string, boolean>);
            }}
            sx={{ mr: 1 }}
          >
            Abbrechen
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button
            disabled={
              JSON.stringify(mortgageObject) ===
              JSON.stringify(currentMortgageObject)
            }
            onClick={() => {
              handleOnSave();
            }}
            variant={'contained'}
          >
            Übernehmen
          </Button>
        </Box>
      </form>
    </>
  );
};
