import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';

export const ToolsOverview: React.FC = () => {
  const navigation = useNavigate();
  return (
    <>
      <Typography variant='h4'>Tools</Typography>

      <Divider />
      <List>
        <ListItemButton onClick={() => navigation('/xmlKonverter')}>
          <ListItemIcon>
            <ConstructionIcon />
          </ListItemIcon>
          <ListItemText>XML Editor</ListItemText>
        </ListItemButton>
      </List>
    </>
  );
};
