import React from 'react';
import { IUser } from '../../Interfaces/IUser';
import { IUserHasUserGroup } from '../../Interfaces/IUserHasUserGroup';
import { Checkbox, TableCell, TableRow } from '@mui/material';

interface IProps {
  userObject: IUser;
  userHasUserGroup: IUserHasUserGroup[];
  setUserHasUserGroup: Function;
}

export const UserHasGroupRow: React.FC<IProps> = ({
  userObject,
  userHasUserGroup,
  setUserHasUserGroup,
}) => {
  const handleChangeMember = () => {
    const testObject = userHasUserGroup.find(
      x => x.idUser === userObject.idUser
    );

    if (testObject === undefined) {
      setUserHasUserGroup([
        ...userHasUserGroup,
        {
          idUser: userObject.idUser,
          idUserGroup: -1,
          isGroupAdmin: false,
        } as IUserHasUserGroup,
      ]);
    } else {
      setUserHasUserGroup([
        ...userHasUserGroup.filter(x => x.idUser !== userObject.idUser),
      ]);
    }
  };

  const handleChangeAdmin = () => {
    const testObject = userHasUserGroup.find(
      x => x.idUser === userObject.idUser
    );

    if (testObject === undefined) {
      setUserHasUserGroup([
        ...userHasUserGroup,
        {
          idUser: userObject.idUser,
          idUserGroup: -1,
          isGroupAdmin: true,
        } as IUserHasUserGroup,
      ]);
    } else {
      setUserHasUserGroup([
        ...userHasUserGroup.map(x =>
          x.idUser !== userObject.idUser
            ? x
            : ({
                idUser: userObject.idUser,
                idUserGroup: -1,
                isGroupAdmin: !testObject?.isGroupAdmin,
              } as IUserHasUserGroup)
        ),
      ]);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>{userObject.Login}</TableCell>
        <TableCell>
          {userObject.FirstName} {userObject.LastName}
        </TableCell>
        <TableCell>
          <Checkbox
            checked={
              userHasUserGroup.find(x => x.idUser === userObject.idUser) !==
              undefined
            }
            disabled={
              userHasUserGroup.find(x => x.idUser === userObject.idUser)
                ?.isGroupAdmin
            }
            onChange={handleChangeMember}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            checked={
              userHasUserGroup.find(x => x.idUser === userObject.idUser)
                ?.isGroupAdmin == true
            }
            onChange={handleChangeAdmin}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </TableCell>
      </TableRow>
    </>
  );
};
