import React, { useState, useRef } from 'react';
import { Box, Typography, Alert, IconButton, Button } from '@mui/material';
import UploadIcon from '@mui/icons-material/UploadFile';
import XmlEditor from './XmlEditor';
import { HandelsregisterSearch } from '../generic/Handelsregsiter/HandelsregisterSearch';

const XML_Konverter: React.FC = () => {
  /**
   * useStates
   */
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [xmlString, setXmlString] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [
    wasSuccessfullImportedFromhandelsregister,
    setWasSuccessfullImportedFromhandelsregister,
  ] = useState(false);

  /**
   * All Functions
   */
  // handle upload Function
  const handleFileUpload = (file: File) => {
    setXmlString(null);
    if (file.type === 'text/xml' || file.name.endsWith('.xml')) {
      const reader = new FileReader();
      reader.onload = e => {
        const content = e.target?.result as string;
        setXmlString(content);
        setErrorMessage(null); // Löscht frühere Fehler
      };
      reader.readAsText(file);
    } else {
      setErrorMessage('Bitte laden Sie nur XML-Dateien hoch.');
      setXmlString(null); // Löscht frühere Inhalte
    }
  };

  // selcect file function
  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      handleFileUpload(files[0]);
    }
  };

  // drag over function
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.currentTarget.style.borderColor = '#000';
  };

  // drag leave function
  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.currentTarget.style.borderColor = '#ccc';
  };

  // drag drop function
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.currentTarget.style.borderColor = '#ccc';
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      handleFileUpload(files[0]);
    }
  };

  //

  //

  /**
   * useEffects
   */

  //

  //

  return (
    <Box>
      <Typography
        variant='h4'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        XML Editor
        <IconButton onClick={() => fileInputRef.current?.click()}>
          <UploadIcon />
        </IconButton>
      </Typography>

      <HandelsregisterSearch
        openImportFromHandelsregister={isSearchOpen}
        setOpenImportFromHandelsregister={setIsSearchOpen}
        wasSuccessfullImportedFromhandelsregister={
          wasSuccessfullImportedFromhandelsregister
        }
        setWasSuccessfullImportedFromhandelsregister={
          setWasSuccessfullImportedFromhandelsregister
        }
        isXMLImport={true}
        setXMLString={setXmlString}
      />

      <Box mt={2}>
        {errorMessage && (
          <Alert severity='error' sx={{ mt: 2 }}>
            {errorMessage}
          </Alert>
        )}

        <Box
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          sx={{
            width: '100%',
            height: 100,
            border: '2px dashed #ccc',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            borderColor: '#ccc',
          }}
        >
          <Box ml={2} mr={2}>
            <Typography variant='body2' sx={{ mb: 2 }}>
              Ziehen Sie eine XML-Datei hierher oder klicken Sie auf das
              Upload-Symbol, um eine Datei auszuwählen oder importieren Sie
              direkt aus dem Handelsregister.
            </Typography>
          </Box>
          <input
            type='file'
            accept='.xml'
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={handleFileSelection}
          />
        </Box>
      </Box>

      <Button
        variant={'outlined'}
        onClick={() => setIsSearchOpen(true)}
        style={{ marginTop: '20px' }} // Hier die gewünschte margin-top Höhe anpassen
      >
        Suche im Handelsregister
      </Button>

      {xmlString !== null && xmlString !== '' && (
        <XmlEditor xmlString={xmlString} setXmlString={setXmlString} />
      )}
    </Box>
  );
};

export default XML_Konverter;
