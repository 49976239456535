import {
  createContext,
  useContext,
  type SetStateAction,
  type Dispatch,
} from 'react';
import type { IDocumentAnalysisSubsectionEntryFullObject } from '../../Interfaces/IDocumentAnalysisSubsectionEntry';
import type { IDocumentAnalysisAttribute } from '../../Interfaces/IDocumentAnalysisAttribute';
import type { IDocumentAnalysisSection } from '../../Interfaces/IDocumentAnalysisSection';
import type { IDocumentAnalysisSubsection } from '../../Interfaces/IDocumentAnalysisSubsection';
import type { IDocumentAnalysisType } from '../../Interfaces/IDocumentAnalysisType';

export type CellObject = {
  id: string;
  content: string;
};

type DocumentAssistantContextType = {
  selectedCells: CellObject[];
  sectionEntries: IDocumentAnalysisSubsectionEntryFullObject[];
  documentAnalysisTypes: IDocumentAnalysisType[];
  documentAnalysisSections: IDocumentAnalysisSection[];
  documentAnalysisSubsections: IDocumentAnalysisSubsection[];
  documentAnalysisAttributes: IDocumentAnalysisAttribute[];

  setSelectedCells: (cells: CellObject[]) => void;
  clearSelectedCells: () => void;
  setSectionEntries: Dispatch<
    SetStateAction<IDocumentAnalysisSubsectionEntryFullObject[]>
  >;
};

type DocumentAssistantProviderProps = {
  children: React.ReactNode;
} & DocumentAssistantContextType;

const defaultContext: DocumentAssistantContextType = {
  selectedCells: [],
  sectionEntries: [],
  documentAnalysisTypes: [],
  documentAnalysisSections: [],
  documentAnalysisSubsections: [],
  documentAnalysisAttributes: [],

  setSelectedCells: () => {},
  clearSelectedCells: () => {},
  setSectionEntries: () => {},
};

export const DocumentAssistantContext =
  createContext<DocumentAssistantContextType>(defaultContext);

export const DocumentAssistantProvider = ({
  children,
  ...props
}: DocumentAssistantProviderProps) => {
  return (
    <DocumentAssistantContext.Provider value={{ ...defaultContext, ...props }}>
      {children}
    </DocumentAssistantContext.Provider>
  );
};

export const useDocumentAssistantContext = () => {
  return useContext(DocumentAssistantContext);
};
