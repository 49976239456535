import React, { useState } from 'react';
import { ILegalRequirement } from '../../Interfaces/ILegalRequirement';
import { IDocument } from '../../Interfaces/IDocument';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from '@mui/material';
import { ContactPage, InsertDriveFile } from '@mui/icons-material';
import { DocumentOverview } from '../../Document/DocumentOverview';
import { IDocumentDownloaded } from '../../Interfaces/IDocumentDownloaded';

interface IProps {
  legalRequirementObject: ILegalRequirement;
  setLegalRequirementObject: Function;
  isNotDisabled: boolean;
  downloadedArray?: IDocumentDownloaded[];
}

export const LegalRequirementDocuments: React.FC<IProps> = props => {
  const [documentArrayClient, setDocumentArrayClient] = useState<IDocument[]>(
    props.legalRequirementObject.DocumentArrayClient === undefined
      ? []
      : props.legalRequirementObject.DocumentArrayClient
  );
  const [documentArrayEmployees, setDocumentArrayEmployees] = useState<
    IDocument[]
  >(
    props.legalRequirementObject.DocumentArrayEmployees === undefined
      ? []
      : props.legalRequirementObject.DocumentArrayEmployees
  );
  //
  const [isOpenClient, setIsOpenClient] = useState(false);
  const [isOpenEmployees, setIsOpenEmployees] = useState(false);

  const hanldeOpenEmployees = () => {
    setDocumentArrayEmployees(
      props.legalRequirementObject.DocumentArrayEmployees === undefined
        ? []
        : props.legalRequirementObject.DocumentArrayEmployees
    );
    setIsOpenEmployees(true);
  };

  const handleSave = () => {
    props.setLegalRequirementObject({
      ...props.legalRequirementObject,
      DocumentArrayEmployees: documentArrayEmployees,
      DocumentArrayClient: documentArrayClient,
    } as ILegalRequirement);
    setIsOpenEmployees(false);
    setIsOpenClient(false);
  };

  return (
    <>
      <Dialog
        open={isOpenClient}
        onClose={() => setIsOpenClient(false)}
        maxWidth='lg'
        fullWidth
      >
        <DialogContent>
          <DocumentOverview
            key={`DocumentOverview-client`}
            documentArray={documentArrayClient}
            setDocumentArray={setDocumentArrayClient}
            allowRemove={true}
            allowUpload={false}
            title='Daten vom Mandanten'
            downloadedArray={props.downloadedArray}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setIsOpenClient(false)}>
            Abbruch
          </Button>
          <Button variant='contained' onClick={handleSave}>
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isOpenEmployees}
        onClose={() => setIsOpenEmployees(false)}
        maxWidth='lg'
        fullWidth
      >
        <DialogContent>
          <DocumentOverview
            key={`DocumentOverview-employees`}
            documentArray={documentArrayEmployees}
            setDocumentArray={setDocumentArrayEmployees}
            allowRemove={true}
            allowUpload={true}
            title='Für den Mandanten bereitgestellte Daten'
            downloadedArray={props.downloadedArray}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setIsOpenEmployees(false)}>
            Abbruch
          </Button>
          <Button variant='contained' onClick={handleSave}>
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>

      {documentArrayClient.length > 0 &&
        props.legalRequirementObject.canUploadFilesClient == true && (
          <IconButton
            size='small'
            title='Runterladen von Mandantendaten'
            onClick={() => {
              setIsOpenClient(true);
            }}
            disabled={!props.isNotDisabled}
          >
            <ContactPage />
          </IconButton>
        )}
      {props.legalRequirementObject.canUploadFilesEmployees == true && (
        <IconButton
          size='small'
          title='Daten für Mandanten hochladen'
          onClick={hanldeOpenEmployees}
          disabled={!props.isNotDisabled}
        >
          <InsertDriveFile />
        </IconButton>
      )}
    </>
  );
};
