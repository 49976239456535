import React, { useState } from 'react';
import { ILegalTransactionMeetingEmail } from '../../../Interfaces/ILegalTransactionMeetingEmail';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { LegalTransactionMeetingEmailRow } from './LegalTransactionMeetingEmailRow';
import { Add } from '@mui/icons-material';
import { LegalTransactionMeetingEmailEdit } from './LegalTransactionMeetingEmailEdit';

interface IProps {
  idLegalTransactionMeeting: number;
  meetingEmailArray: ILegalTransactionMeetingEmail[];
  setMeetingEmailArray: Function;
}

export const LegalTransactionMeetingEmailOverview: React.FC<IProps> = props => {
  const [isOpenNew, setIsOpenNew] = useState(false);

  return (
    <>
      <Dialog
        open={isOpenNew}
        onClose={() => setIsOpenNew(false)}
        maxWidth='lg'
        fullWidth
      >
        <DialogTitle>E-Mail bearbeite</DialogTitle>
        <DialogContent>
          {isOpenNew && (
            <LegalTransactionMeetingEmailEdit
              idLegalTransactionMeeting={props.idLegalTransactionMeeting}
              idLegalTransactionMeetingEmail={-1}
              setIdLegalTransactionMeetingEmail={() => {}}
              isNew={isOpenNew}
              setIsNew={() => setIsOpenNew(false)}
              meetingEmailArray={props.meetingEmailArray}
              setMeetingEmailArray={props.setMeetingEmailArray}
            />
          )}
        </DialogContent>
      </Dialog>

      <Typography variant='h6' sx={{ mt: 5 }}>
        Termin-E-Mails
        <IconButton sx={{ float: 'right' }} onClick={() => setIsOpenNew(true)}>
          <Add />
        </IconButton>
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: 300 }}>Titel</TableCell>
            <TableCell>Terminerstellung</TableCell>
            <TableCell>Terminänderung</TableCell>
            <TableCell>Terminabsage</TableCell>
            <TableCell>Tage vor Termin</TableCell>
            <TableCell>Kalenderdatei</TableCell>
            <TableCell>Datenupload</TableCell>
            <TableCell>Aktionen</TableCell>
          </TableRow>
        </TableHead>

        {props.meetingEmailArray.map(x => (
          <LegalTransactionMeetingEmailRow
            key={`idLegalTransactionMeetingEmail-${x.idLegalTransactionMeetingEmail}`}
            meetingEmailObject={x}
            idLegalTransactionMeeting={props.idLegalTransactionMeeting}
            meetingEmailArray={props.meetingEmailArray}
            setMeetingEmailArray={props.setMeetingEmailArray}
          />
        ))}
      </Table>
    </>
  );
};
