import React, { useState } from 'react';
import { ILegalTransactionMeetingEmail } from '../../../Interfaces/ILegalTransactionMeetingEmail';
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from '@mui/material';
import { Clear } from '@mui/icons-material';
import { CustomEditor } from '../../../core/CustomEditor';

interface IProps {
  idLegalTransactionMeeting: number;
  idLegalTransactionMeetingEmail: number;
  setIdLegalTransactionMeetingEmail: Function;
  isNew: boolean;
  setIsNew: Function;
  meetingEmailArray: ILegalTransactionMeetingEmail[];
  setMeetingEmailArray: Function;
}

const getMeetingEmail = (
  idLegalTransactionMeeting: number,
  idLegalTransactionMeetingEmail: number,
  meetingEmailArray: ILegalTransactionMeetingEmail[],
  isNew: boolean
) => {
  const testObject = meetingEmailArray.find(
    x => x.idLegalTransactionMeetingEmail === idLegalTransactionMeetingEmail
  );

  if (testObject !== undefined && isNew === false) {
    return testObject;
  } else {
    let tmpId = -1;

    if (meetingEmailArray.length > 0) {
      const tmpIdArray =
        Math.min(
          ...meetingEmailArray.map(x => x.idLegalTransactionMeetingEmail)
        ) - 1;

      if (tmpIdArray <= tmpId) {
        tmpId = tmpIdArray - 1;
      }
    }

    return {
      idLegalTransaction: -1,
      idLegalTransactionMeeting: idLegalTransactionMeeting,
      idLegalTransactionMeetingEmail: tmpId,
      Title: '',
      Subject: '',
      Body: '',
      allowDataUpload: false,
      sendOnCreation: false,
      DaysBeforeDate: null,
      sendOnCancelling: false,
      sendOnReschedule: false,
      sendWithCalenderFile: false,
    } as ILegalTransactionMeetingEmail;
  }
};

export const LegalTransactionMeetingEmailEdit: React.FC<IProps> = props => {
  const [meetingEmailObject, setMeetingEmailObject] = useState(
    getMeetingEmail(
      props.idLegalTransactionMeeting,
      props.idLegalTransactionMeetingEmail,
      props.meetingEmailArray,
      props.isNew
    )
  );

  const handeClose = () => {
    props.setIsNew(false);
    props.setIdLegalTransactionMeetingEmail(null);
  };

  const handleSave = () => {
    const testObject = props.meetingEmailArray.find(
      x =>
        x.idLegalTransactionMeetingEmail ===
        meetingEmailObject.idLegalTransactionMeetingEmail
    );

    if (testObject === undefined) {
      props.setMeetingEmailArray([
        ...props.meetingEmailArray,
        meetingEmailObject,
      ]);
    } else {
      props.setMeetingEmailArray([
        ...props.meetingEmailArray.map(x =>
          x.idLegalTransactionMeetingEmail ===
          meetingEmailObject.idLegalTransactionMeetingEmail
            ? meetingEmailObject
            : x
        ),
      ]);
    }
    handeClose();
  };

  return (
    <>
      <Box sx={{ mt: 2 }} />
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <TextField
            label='Titel (nur intern)'
            size='small'
            value={meetingEmailObject.Title}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setMeetingEmailObject({
                ...meetingEmailObject,
                Title: event.target.value,
              })
            }
            error={meetingEmailObject.Title === ''}
            fullWidth
          />
        </Grid>

        <Grid item sm={4}>
          <TextField
            label='Senden bei Terminerstellung'
            size='small'
            select
            value={meetingEmailObject.sendOnCreation ? 'true' : 'false'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setMeetingEmailObject({
                ...meetingEmailObject,
                sendOnCreation: event.target.value === 'true',
              })
            }
            fullWidth
          >
            <MenuItem key='sendOnCreation-true' value='true'>
              Ja
            </MenuItem>
            <MenuItem key='sendOnCreation-false' value='false'>
              Nein
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item sm={4}>
          <TextField
            label='Senden bei Terminänderung'
            size='small'
            select
            value={meetingEmailObject.sendOnReschedule ? 'true' : 'false'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setMeetingEmailObject({
                ...meetingEmailObject,
                sendOnReschedule: event.target.value === 'true',
              })
            }
            fullWidth
          >
            <MenuItem key='sendOnReschedule-true' value='true'>
              Ja
            </MenuItem>
            <MenuItem key='sendOnReschedule-false' value='false'>
              Nein
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item sm={4}>
          <TextField
            label='Senden bei Terminabsage (Löschung)'
            size='small'
            select
            value={meetingEmailObject.sendOnCancelling ? 'true' : 'false'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setMeetingEmailObject({
                ...meetingEmailObject,
                sendOnCancelling: event.target.value === 'true',
              })
            }
            fullWidth
          >
            <MenuItem key='sendOnCancelling-true' value='true'>
              Ja
            </MenuItem>
            <MenuItem key='sendOnCancelling-false' value='false'>
              Nein
            </MenuItem>
          </TextField>
        </Grid>

        <Grid item sm={6}>
          <Box sx={{ display: 'flex' }}>
            <TextField
              label='Senden Tage vor Termin'
              size='small'
              type='number'
              InputProps={{ inputProps: { min: 0 } }}
              value={
                meetingEmailObject.DaysBeforeDate === null
                  ? ''
                  : meetingEmailObject.DaysBeforeDate
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setMeetingEmailObject({
                  ...meetingEmailObject,
                  DaysBeforeDate:
                    Number(event.target.value) < 0
                      ? null
                      : Number(event.target.value),
                })
              }
              fullWidth
            />
            <IconButton
              onClick={() =>
                setMeetingEmailObject({
                  ...meetingEmailObject,
                  DaysBeforeDate: null,
                })
              }
            >
              <Clear />
            </IconButton>
          </Box>
        </Grid>

        <Grid item sm={3}>
          <TextField
            label='Kalenderdatei mitsenden'
            size='small'
            select
            value={meetingEmailObject.sendWithCalenderFile ? 'true' : 'false'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setMeetingEmailObject({
                ...meetingEmailObject,
                sendWithCalenderFile: event.target.value === 'true',
              })
            }
            fullWidth
          >
            <MenuItem key='allowDataUpload-true' value='true'>
              Ja
            </MenuItem>
            <MenuItem key='allowDataUpload-false' value='false'>
              Nein
            </MenuItem>
          </TextField>
        </Grid>

        {/*
                    <Grid item sm={3}>
                        <TextField
                            label="Datumupload erlauben"
                            size="small"
                            select
                            value={(meetingEmailObject.allowDataUpload) ? "true" : "false"}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setMeetingEmailObject({...meetingEmailObject, allowDataUpload: (event.target.value === "true")})}
                            fullWidth
                        >
                            <MenuItem key="allowDataUpload-true" value="true">Ja</MenuItem>
                            <MenuItem key="allowDataUpload-false" value="false">Nein</MenuItem>
                        </TextField>
                    </Grid>
                    */}

        <Grid item sm={12}>
          <TextField
            sx={{ mt: 3 }}
            label='Betreff'
            size='small'
            value={meetingEmailObject.Subject}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setMeetingEmailObject({
                ...meetingEmailObject,
                Subject: event.target.value,
              })
            }
            error={meetingEmailObject.Subject === ''}
            fullWidth
          />
        </Grid>

        <CustomEditor
          txt={meetingEmailObject.Body}
          setTxt={(txt: string) =>
            setMeetingEmailObject({ ...meetingEmailObject, Body: txt })
          }
          dictonaryIdsArray={[1, 2, 3]}
        />
      </Grid>

      <Button
        variant='contained'
        sx={{ float: 'right' }}
        onClick={handleSave}
        disabled={
          meetingEmailObject.Subject === '' || meetingEmailObject.Title === ''
        }
      >
        Übernehmen
      </Button>
      <Button
        variant='outlined'
        sx={{ mr: 2, float: 'right' }}
        onClick={handeClose}
      >
        Abbruch
      </Button>
    </>
  );
};
