import React, { useEffect, useState } from 'react';
import { ILegalTransactionFullObject } from '../Interfaces/ILegalTransaction';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
} from '@mui/material';
import {
  ILegalTransactionTypeFullObject,
  type ILegalTransactionType,
} from '../Interfaces/ILegalTransactionType';
import { IContact, ILegalTransactionHasContact } from '../Interfaces/IContact';
import { LegalTransactionClientsOverview } from './LegalTransactionClientsOverview';
import { IUser } from '../Interfaces/IUser';
import { ILegalRole } from '../Interfaces/ILegalRole';
import { IUserGroup } from '../Interfaces/IUserGroup';
import { Person, Group } from '@mui/icons-material';
import { useCreateLegalTransactionFullObject } from '../hooks/data';
import { useAlert } from '../context/AlertContext';
import { useQueryClient } from '@tanstack/react-query';

interface IProps {
  users: IUser[];
  userGroups: IUserGroup[];
  legalTransactionTypes: ILegalTransactionType[];
  contacts: IContact[];
  handleClose: () => void;
  handleFinished: (idLegalTransaction: number) => void;
}

export const LegalTransactionNew = ({
  users,
  userGroups,
  legalTransactionTypes,
  contacts,
  handleClose,
  handleFinished,
}: IProps) => {
  const queryClient = useQueryClient();

  const {
    createLegalTransactionFullObject,
    isPendingCreateLegalTransactionFullObject,
  } = useCreateLegalTransactionFullObject();

  const { showAlert } = useAlert();

  const combinedOptions = [
    ...users
      .filter(user => !user.isNotary)
      .map(user => ({
        type: 'Mitarbeiter',
        id: user.idUser,
        name: `${user.FirstName} ${user.LastName}`,
      })),
    ...userGroups.map(group => ({
      type: 'Gruppe',
      id: group.idUserGroup,
      name: group.UserGroup,
    })),
  ];
  const [selectedType, setSelectedType] = useState('user');
  const [idLegalTransactionType, setIdLegalTransactionType] = useState(0);

  const [title, setTitle] = useState('');
  const [idUser, setIdUser] = useState(
    users.filter(user => Boolean(user.isNotary)).length === 1
      ? users.filter(user => Boolean(user.isNotary))[0].idUser
      : null
  );
  const [employeeIdUser, setEmployeeIdUser] = useState<number | null>(() => {
    const idUser = Number(localStorage.getItem('idUser'));

    if (
      users
        .filter(user => Boolean(user.isNotary) === false)
        .map(user => user.idUser)
        .indexOf(idUser) > -1
    ) {
      return idUser;
    } else {
      return null;
    }
  });
  const [userGroupId, setUserGroupId] = useState<number | null>(null);
  const [contactArray, setContactArray] = useState<IContact[] | undefined>(
    undefined
  );

  const [chosenContactArray, setChosenContactArray] = useState<
    ILegalTransactionHasContact[]
  >([]);

  const [legalTransactionType, setLegalTransactionType] =
    useState<ILegalTransactionTypeFullObject | null>(null);
  const [legalRoleArray, setLegalRoleArray] = useState<ILegalRole[]>([]);

  console.log('legalTransactionType', legalTransactionType);

  const isFormValid =
    idLegalTransactionType !== 0 && idUser !== null && title !== '';

  useEffect(() => {
    if (legalTransactionType) {
      const tmpLegalRoleArray: ILegalRole[] = [];

      legalTransactionType.LegalRoleTemplateArray.map(legalRoleTemplate => {
        const tmpObject: ILegalRole = {
          ...legalRoleTemplate,
          idLegalRole: legalRoleTemplate.idLegalRoleTemplate,
          idLegalTransaction: -1,
          LegalRole: legalRoleTemplate.LegalRoleTemplate,
        };
        tmpLegalRoleArray.push(tmpObject);
      });

      setLegalRoleArray(tmpLegalRoleArray);
    }
  }, [legalTransactionType]);

  const handleCreateLegalTransaction = async () => {
    if (!idUser) {
      showAlert({
        severity: 'error',
        text: 'Kein Mitarbeiter ausgewählt!',
      });
      return;
    }

    try {
      const newLegalTransaction: ILegalTransactionFullObject = {
        idLegalTransactionType,
        idLegalTransactionState: 1,
        idLegalTransactionProgress: 10,
        Created_at: '',
        idLegalTransaction: -1,
        DataCollection: [],
        Title: title,
        ContactArray: chosenContactArray,
        LegalPhaseArray: [],
        LegalTransactionGwGArray: [],
        CertificateArray: [],
        DraftArray: [],
        DraftMessageArray: [],
        InvoiceArray: [],
        Notary_idUser: idUser,
        RecordNumberArray: [],
        TransactionNumberArray: [],
        LegalRoleArray: [],
        LegalRoleCanInviteArray: [],
        Remarks: null,
        Employee_idUser: employeeIdUser,
        idUserGroup: userGroupId,
        LastPhaseUpdate_at: null,
        Closed_at: null,
        NotarizationDate: null,
        StartEditDate: null,
        // Nur Intern
        DocumentArray: [],
        DocumentDirectoryArray: [],
        LegalTransactionMeetingArray: [],
        LegalTransactionMeetingEmailArray: [],
        LegalTransaction_has_LegalTransactionSpecialFunctionArray: [],
        UserInformed_LackOfActivity: false,
        LastActivity_at: null,
      };

      const newLegalTransactionFullObject =
        await createLegalTransactionFullObject(newLegalTransaction);

      queryClient.invalidateQueries({
        queryKey: ['legaltransaction', 'search'],
      });

      if (newLegalTransactionFullObject) {
        handleFinished(newLegalTransactionFullObject.idLegalTransaction);
      }

      showAlert({
        severity: 'success',
        text: 'Vorgang erfolgreich angelegt!',
      });
    } catch {
      showAlert({
        severity: 'error',
        text: 'Es liegt ein Fehler mit der Datenverarbeitung vor! Falls dies wiederholt auftritt, kontaktieren Sie uns bitte.',
      });
    }
  };

  return (
    <Dialog open onClose={handleClose} maxWidth='lg' fullWidth>
      <DialogTitle variant='h4'>Neuer Vorgang</DialogTitle>

      <DialogContent>
        <Box sx={{ mt: 2 }} />
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item sm={6}>
            <TextField
              label='Zugewiesener Notar'
              value={idUser === null ? '' : idUser}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setIdUser(Number(event.target.value))
              }
              error={idUser === null}
              size='small'
              fullWidth
              select
            >
              {users
                .filter(user => Boolean(user.isNotary))
                .map(user => (
                  <MenuItem
                    key={`new-idUser-${user.idUser}`}
                    value={user.idUser}
                  >
                    {user.FirstName} {user.LastName}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item sm={6}>
            <Autocomplete
              options={combinedOptions}
              groupBy={option => option.type}
              getOptionLabel={option => option.name}
              style={{ width: '100%' }}
              renderInput={params => (
                <TextField
                  {...params}
                  label={
                    selectedType === 'user' ? (
                      <span>
                        <Person
                          style={{
                            verticalAlign: 'middle',
                            marginRight: 8,
                          }}
                        />
                        Zugewiesener Mitarbeiter
                      </span>
                    ) : (
                      <span>
                        <Group
                          style={{
                            verticalAlign: 'middle',
                            marginRight: 8,
                          }}
                        />
                        Zugewiesene Gruppe
                      </span>
                    )
                  }
                  size='small'
                  variant='outlined'
                />
              )}
              renderOption={(props, option) => (
                <ListItem {...props} key={option.id}>
                  <ListItemText primary={option.name} />
                </ListItem>
              )}
              onChange={(_, newValue) => {
                if (newValue) {
                  if (newValue.type === 'Mitarbeiter') {
                    setEmployeeIdUser(newValue.id);
                    setUserGroupId(null);
                    setSelectedType('user');
                  } else if (newValue.type === 'Gruppe') {
                    setEmployeeIdUser(null);
                    setUserGroupId(newValue.id);
                    setSelectedType('group');
                  }
                } else {
                  setEmployeeIdUser(null);
                  setUserGroupId(null);
                }
              }}
              value={
                selectedType === 'user' && employeeIdUser
                  ? combinedOptions.find(
                      option =>
                        option.type === 'Mitarbeiter' &&
                        option.id === employeeIdUser
                    )
                  : selectedType === 'group' && userGroupId
                    ? combinedOptions.find(
                        option =>
                          option.type === 'Gruppe' && option.id === userGroupId
                      )
                    : null
              }
            />
          </Grid>

          <Grid item sm={4}>
            <TextField
              label='Art'
              value={idLegalTransactionType === 0 ? '' : idLegalTransactionType}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setIdLegalTransactionType(Number(event.target.value))
              }
              error={idLegalTransactionType === 0}
              size='small'
              fullWidth
              select
            >
              {legalTransactionTypes.map(legalTransactionType => (
                <MenuItem
                  key={`new-idLegalTransactionType-${legalTransactionType.idLegalTransactionType}`}
                  value={legalTransactionType.idLegalTransactionType}
                >
                  {legalTransactionType.LegalTransactionType}{' '}
                  {Boolean(legalTransactionType.withDialog) && (
                    <i> (Datenerf. auto. erstellt)</i>
                  )}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item sm={8}>
            <TextField
              label='Bezeichnung'
              value={title}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setTitle(event.target.value)
              }
              error={title === ''}
              size='small'
              fullWidth
            />
          </Grid>
        </Grid>

        <LegalTransactionClientsOverview
          allContactArray={contacts}
          setAllContactArray={setContactArray}
          legalRoleArray={legalRoleArray}
          chosenContactArray={chosenContactArray}
          setChosenContactArray={setChosenContactArray}
        />
      </DialogContent>

      <DialogActions>
        <Button variant='outlined' onClick={handleClose}>
          Abbruch
        </Button>
        <Button
          variant='contained'
          onClick={handleCreateLegalTransaction}
          disabled={!isFormValid || isPendingCreateLegalTransactionFullObject}
        >
          {isPendingCreateLegalTransactionFullObject ? (
            <Box display='flex' alignItems='center' gap={1}>
              <span>Vorgang anlegen</span>
              <CircularProgress size={20} />
            </Box>
          ) : (
            'Vorgang anlegen'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
