import React, { useState } from 'react';
import { uploadFetch, useFetch } from '../../hooks/useFetch';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import { ISetting } from '../../Interfaces/ISignature';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { ShowWasSaved } from '../../core/ShowWasSaved';
import { CustomEditor } from '../../core/CustomEditor';

export const EmailSignatureEdit: React.FC = () => {
  const [settingObject, setSettingObject, wasSuccessfully] = useFetch<ISetting>(
    '/setting/',
    20
  );
  //
  const [expanded, setExpanded] = useState(false);
  //
  const [isLoding, setIsLoding] = useState(false);
  const [wasSaved, setWasSaved] = useState(false);
  const [wasSavedSuccessfully, setWasSavedSuccessfully] = useState(true);

  const setText = (txt: string) => {
    setSettingObject({
      ...settingObject,
      SettingValue: txt,
    });
  };

  const handleSave = () => {
    uploadFetch(
      '/setting',
      false,
      {
        ...settingObject,
        syncPublic: Boolean(settingObject?.syncPublic),
      } as ISetting,
      setSettingObject,
      setWasSavedSuccessfully,
      setWasSaved,
      setIsLoding
    );
  };

  if (settingObject === undefined) {
    return <>Bitte warten...</>;
  } else if (!settingObject) {
    return (
      <Alert severity='error'>
        Bitte kontaktieren Sie einen Administrator!
      </Alert>
    );
  }
  return (
    <>
      <Typography variant='h5' sx={{ mt: 5, mb: 2 }}>
        Signatur
      </Typography>

      <ShowWasSaved
        wasSaved={wasSaved}
        setWasSaved={setWasSaved}
        wasSuccessfullySaved={wasSavedSuccessfully}
      />

      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
          Editor
        </AccordionSummary>
        <AccordionDetails>
          <>
            {isLoding && <LinearProgress />}

            <Grid container spacing={2}>
              <CustomEditor txt={settingObject.SettingValue} setTxt={setText} />
              <Grid item xs={12}>
                <Button
                  onClick={handleSave}
                  variant='contained'
                  sx={{ float: 'right' }}
                  disabled={isLoding}
                >
                  Speichern
                </Button>
                {/*
                                    <Button onClick={} variant="outlined"  sx={{mr: 2, float: "right"}}>Zurücksetzen</Button>
                                */}
              </Grid>
            </Grid>
          </>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
