import React, { useState } from 'react';
import md5 from 'md5';
import { Alert, Box, Button, Grid, TextField, Typography } from '@mui/material';
import { IConfig } from '../Interfaces/IConfig';

interface IProps {
  msg: string | null;
  configObject: IConfig;
}

const Login: React.FC<IProps> = props => {
  const [username, setUsername] = useState<string>('');
  const [pw, setPw] = useState<string>('');
  const [msg, setMsg] = useState<string | null>(props.msg);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    async function tryLogin() {
      if (username !== undefined && pw !== null) {
        await fetch('/api/oauth', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            Username: username,
            Password: md5(pw),
          } as any),
        })
          .then(res => {
            if (res.status == 200) {
              return res.json();
            } else {
              throw Error(String(res.status));
            }
          })
          .then(res => {
            localStorage.setItem('SessionToken', res['SessionToken']);
            localStorage.setItem('idUser', res['idUser']);
            localStorage.setItem('role', res['Role']);
            localStorage.setItem('scope', res['Scope']);
            // Sonderfuntionen
            localStorage.setItem(
              'is_enabled_landregister',
              res['is_enabled_landregister']
            );

            window.location.reload();
          })
          .catch(error => {
            if (String(error).includes('401')) {
              setMsg('Die Anmeldedaten scheinen nicht korrekt zu sein');
            } else if (String(error).includes('403')) {
              setMsg(`Ihr Konto wurde deaktiviert!`);
            } else {
              setMsg(
                `Es ist ein unbekannter Fehler aufgeretten. Möglicherweise ist der Dienst vorübergehend offline.`
              );
            }
            setUsername('');
            setPw('');
          });
      } else {
        setUsername('');
        setPw('');
      }
    }
    tryLogin();
  };

  return (
    <form onSubmit={handleSubmit} autoComplete='on'>
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '100vh' }}
      >
        <img
          width={250}
          src={`data:${props.configObject.logo_mime};base64,${props.configObject.logo}`}
        />
        <Typography sx={{ mt: 2 }}>
          - Internes DiNo -
          {props.configObject.is_testing && (
            <>
              <br />
              Demo- und Testing
            </>
          )}
        </Typography>
        <Box sx={{ mt: 10 }} />
        <Grid item xs={3} style={{ minWidth: 300 }}>
          <TextField
            id={'Benutzername'}
            label='Benutzername'
            value={username}
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setUsername(String(event.target.value))
            }
            required
          />
        </Grid>

        <Box sx={{ mt: 2 }} />
        <Grid item xs={3} style={{ minWidth: 300 }}>
          <TextField
            id={'Passwort'}
            type={'password'}
            label='Passwort'
            fullWidth
            value={pw}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setPw(String(event.target.value))
            }
            required
          />
        </Grid>

        <Box sx={{ mt: 3 }} />
        <Grid item xs={3} style={{ minWidth: 300 }}>
          <div style={{ float: 'right' }}>
            <Button
              id={'Anmelden'}
              size='small'
              variant='contained'
              color='primary'
              type='submit'
            >
              Anmelden
            </Button>
          </div>
        </Grid>

        <Box sx={{ mt: 3 }} />
        <Grid item xs={3} style={{ minWidth: 300 }}>
          {msg !== null && <Alert severity='error'>{msg}</Alert>}
        </Grid>
      </Grid>
    </form>
  );
};
export default Login;
