import React, { useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import { IShareHolderFullObject } from '../../../../Interfaces/IShareHolder';
import { IPostcode } from '../../../../Interfaces/IPostcode';
import { ICompany } from '../../../../Interfaces/ICompany';
import { useSelector } from 'react-redux';
import { State } from '../../../../redux/mainReducer';
import { PostcodeSearch } from '../../../../generic/PostcodeSearch';
import { getEmptyCompany } from '../../../../Clients/ClientsEdit';

interface IProps {
  idShareHolder: number;
  handleClose: Function;
  shareHolderArray: IShareHolderFullObject[];
  setShareHolderArray: Function;
  //
  rowSelectionModel?: GridRowSelectionModel;
  setRowSelectionModel?: Function;
  enableAutoslectNewShareHolder?: boolean;
  disableSelectCompanyType?: boolean;
}

const searchShareHolder = (
  idShareHolder: number,
  shareHolderArray: IShareHolderFullObject[]
) => {
  const foundObject = shareHolderArray.find(
    x => x.idShareHolder === idShareHolder
  );

  if (foundObject !== undefined) {
    return foundObject;
  } else {
    let tmpId = Math.min(...shareHolderArray.map(x => x.idShareHolder)) - 1;

    if (tmpId >= 0) {
      tmpId = -1;
    }

    return {
      idShareHolder: tmpId,
      Birthday: null,
      Birthplace: null,
      Deposit: 0,
      FirstName: '',
      idAcademicTitle: 1,
      idGender: -1,
      idPostcode: -1,
      isPartPayment: false,
      isShareByNonCashResources: false,
      LastName: '',
      Shares: 0,
      Street: '',
      StreetAditional: '',
      StreetNr: '',
      idBusinessForm: 1,
      CompanyObject: {
        idCompany: -1,
        Company: '',
        idBusinessForm: 1,
        idPostcode: -1,
        RegisterCourt: null,
        RegisterNumber: null,
        Street: '',
        StreetAditional: '',
        StreetNr: '',
        TaxNumber: null,
        SalesTaxNumber: null,
      } as ICompany,
    } as IShareHolderFullObject;
  }
};

const handlePostcode = (
  localObject: IShareHolderFullObject | ICompany | undefined
) => {
  if (localObject === undefined) {
    return null;
  } else if (
    localObject.idPostcode != null &&
    localObject.idPostcode !== null &&
    localObject.idPostcode > 0 &&
    localObject.City != undefined &&
    localObject.Postcode != undefined
  ) {
    return {
      idPostcode: localObject.idPostcode,
      City: localObject.City,
      Postcode: localObject.Postcode,
      idCountry: 1,
    } as IPostcode;
  } else {
    return null;
  }
};

export const ShareHolderEdit: React.FC<IProps> = props => {
  const mimeTypeArray = useSelector(
    (state: State) => state.mimeTypeArray.storeAcademicTitle
  );
  const genderArray = useSelector(
    (state: State) => state.genderArray.storeGender
  );
  const academicTitleArray = useSelector(
    (state: State) => state.academicTitleArray.storeAcademicTitle
  );
  const businessFormArray = useSelector(
    (state: State) => state.businessFormArray.storeBusinessForm
  );

  const [shareHolder, setShareHolder] = useState(
    searchShareHolder(props.idShareHolder, props.shareHolderArray)
  );
  const [companyObject, setCompanyObject] = useState(
    shareHolder.CompanyObject === undefined
      ? getEmptyCompany()
      : shareHolder.CompanyObject
  );
  //
  const [postcodeObject, setPostcodeObject] = useState<
    IPostcode | null | undefined
  >(handlePostcode(shareHolder));
  const [postcodeObjectCompany, setPostcodeObjectCompany] = useState<
    IPostcode | null | undefined
  >(handlePostcode(companyObject));

  const setPostcodeObjectWrapper = (
    postcodeObject: IPostcode | null | undefined
  ) => {
    if (postcodeObject != undefined) {
      setShareHolder({
        ...shareHolder,
        idPostcode: postcodeObject.idPostcode,
        Postcode: postcodeObject.Postcode,
        City: postcodeObject.City,
      } as IShareHolderFullObject);
    }
    setPostcodeObject(postcodeObject);
  };
  const setPostcodeObjectCompanyWrapper = (
    postcodeObject: IPostcode | null | undefined
  ) => {
    if (postcodeObject != undefined) {
      setCompanyObject({
        ...companyObject,
        idPostcode: postcodeObject.idPostcode,
        Postcode: postcodeObject.Postcode,
        City: postcodeObject.City,
      } as ICompany);
    } else {
      setCompanyObject({
        ...companyObject,
        idPostcode: null,
        Postcode: undefined,
        City: undefined,
      } as ICompany);
    }
    setPostcodeObjectCompany(postcodeObject);
  };

  const getIsDisabled = () => {
    return (
      shareHolder.FirstName === '' ||
      shareHolder.LastName === '' ||
      //|| shareHolder.Street === ""
      //|| shareHolder.StreetNr === ""
      shareHolder.idGender < 1 ||
      shareHolder.idPostcode === null ||
      shareHolder.idPostcode < 1 ||
      (shareHolder.idBusinessForm !== 1 &&
        (companyObject.Company === null || companyObject.Company === ''))
    );
  };

  const handleAdd = () => {
    const localShareHolder = {
      ...shareHolder,
      CompanyObject: {
        ...companyObject,
        idBusinessForm: shareHolder.idBusinessForm,
      } as ICompany,
    } as IShareHolderFullObject;

    const testObject = props.shareHolderArray.find(
      x => x.idShareHolder === props.idShareHolder
    );

    if (testObject === undefined) {
      props.setShareHolderArray([...props.shareHolderArray, localShareHolder]);
    } else {
      props.setShareHolderArray([
        ...props.shareHolderArray.map(x =>
          x.idShareHolder === props.idShareHolder ? localShareHolder : x
        ),
      ]);
    }

    if (
      testObject === undefined &&
      props.enableAutoslectNewShareHolder !== undefined &&
      props.enableAutoslectNewShareHolder === true &&
      props.rowSelectionModel !== undefined &&
      props.setRowSelectionModel
    ) {
      props.setRowSelectionModel([
        ...props.rowSelectionModel,
        localShareHolder.idShareHolder,
      ]);
    }

    props.handleClose();
  };

  const getCompanyEdit = () => {
    return (
      <>
        <Typography sx={{ mt: 2, mb: 1 }}>Firma</Typography>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <TextField
              label='Firma'
              size='small'
              required
              fullWidth
              value={companyObject.Company}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCompanyObject({
                  ...companyObject,
                  Company: event.target.value,
                })
              }
            />
          </Grid>

          <Grid item sm={4}>
            <TextField
              label='Handelsregisternummer'
              size='small'
              fullWidth
              value={
                companyObject.RegisterNumber === null
                  ? ''
                  : companyObject.RegisterNumber
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCompanyObject({
                  ...companyObject,
                  RegisterNumber:
                    event.target.value === '' ? null : event.target.value,
                })
              }
            />
          </Grid>
          <Grid item sm={8}>
            <TextField
              label='Handelsregistergericht'
              size='small'
              fullWidth
              value={
                companyObject.RegisterCourt === null
                  ? ''
                  : companyObject.RegisterCourt
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCompanyObject({
                  ...companyObject,
                  RegisterCourt:
                    event.target.value === '' ? null : event.target.value,
                })
              }
            />
          </Grid>

          <Grid item sm={6}>
            <TextField
              label='Steuernummer'
              size='small'
              fullWidth
              value={
                companyObject.TaxNumber === null ? '' : companyObject.TaxNumber
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCompanyObject({
                  ...companyObject,
                  TaxNumber:
                    event.target.value === '' ? null : event.target.value,
                })
              }
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              label='USt-IdNr.'
              size='small'
              fullWidth
              value={
                companyObject.SalesTaxNumber === null
                  ? ''
                  : companyObject.SalesTaxNumber
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCompanyObject({
                  ...companyObject,
                  SalesTaxNumber:
                    event.target.value === '' ? null : event.target.value,
                })
              }
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const getBirthday = () => {
    return (
      <>
        <Typography sx={{ mt: 2, mb: 1 }}>Geburtsdaten</Typography>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <TextField
              label='Geburtstag'
              size='small'
              type='date'
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={shareHolder.Birthday === null ? '' : shareHolder.Birthday}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setShareHolder({
                  ...shareHolder,
                  Birthday:
                    event.target.value === '' ? null : event.target.value,
                })
              }
            />
          </Grid>
          <Grid item sm={8}>
            <TextField
              label='Geburtsort'
              size='small'
              fullWidth
              value={
                shareHolder.Birthplace === null ? '' : shareHolder.Birthplace
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setShareHolder({
                  ...shareHolder,
                  Birthplace:
                    event.target.value === '' ? null : event.target.value,
                })
              }
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const getNameEdit = (title: string) => {
    return (
      <>
        <Typography sx={{ mt: 2, mb: 1 }}>{title}</Typography>
        <Grid container spacing={2}>
          <Grid item sm={2}>
            <TextField
              label='Titel'
              size='small'
              fullWidth
              value={shareHolder.idAcademicTitle}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setShareHolder({
                  ...shareHolder,
                  idAcademicTitle: Number(event.target.value),
                })
              }
              select
            >
              {academicTitleArray.map(x => (
                <MenuItem
                  key={`idAcademicTitle-${x.idAcademicTitle}`}
                  value={x.idAcademicTitle}
                >
                  {x.AcademicTitle}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item sm={2}>
            <TextField
              label='Geschlecht'
              size='small'
              fullWidth
              required
              value={shareHolder.idGender < 1 ? '' : shareHolder.idGender}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setShareHolder({
                  ...shareHolder,
                  idGender: Number(event.target.value),
                })
              }
              select
            >
              {genderArray.map(x => (
                <MenuItem key={`idGender-${x.idGender}`} value={x.idGender}>
                  {x.Gender}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item sm={4}>
            <TextField
              label='Vorname'
              size='small'
              required
              fullWidth
              value={shareHolder.FirstName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setShareHolder({
                  ...shareHolder,
                  FirstName: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              label='Nachname'
              size='small'
              required
              fullWidth
              value={shareHolder.LastName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setShareHolder({ ...shareHolder, LastName: event.target.value })
              }
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const getAdressEdit = (
    title: string,
    currentObject: IShareHolderFullObject | ICompany,
    setCurrentObject: Function,
    isRequired: boolean,
    localPostcodeObject: IPostcode | null | undefined,
    setLocalPostcodeObject: Function,
    key: string = 'normalpostcode'
  ) => {
    return (
      <>
        <Typography sx={{ mt: 2, mb: 1 }}>{title}</Typography>
        <Grid container spacing={2}>
          <Grid item sm={8}>
            <TextField
              label='Straße'
              size='small'
              required={isRequired}
              fullWidth
              value={currentObject.Street}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCurrentObject({
                  ...currentObject,
                  Street: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              label='Nr.'
              size='small'
              required={isRequired}
              fullWidth
              value={currentObject.StreetNr}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setCurrentObject({
                  ...currentObject,
                  StreetNr: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item sm={12}>
            <PostcodeSearch
              key={key}
              postcodeObject={localPostcodeObject}
              setPostcodeObject={setLocalPostcodeObject}
              required={true}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <TextField
            label='Form'
            size='small'
            fullWidth
            disabled={
              props.disableSelectCompanyType !== undefined &&
              props.disableSelectCompanyType !== false
            }
            value={shareHolder.idBusinessForm}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setShareHolder({
                ...shareHolder,
                idBusinessForm: Number(event.target.value),
                Company:
                  Number(event.target.value) === 1 ? null : shareHolder.Company,
              })
            }
            select
          >
            {businessFormArray.map(x => (
              <MenuItem
                key={`idBusinessForm-${x.idBusinessForm}`}
                value={x.idBusinessForm}
              >
                {x.BusinessForm}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <Collapse in={shareHolder.idBusinessForm === 1}>
        <>
          {getNameEdit('Stammdaten')}
          {getBirthday()}
          {getAdressEdit(
            'Anschrift',
            shareHolder,
            setShareHolder,
            false,
            postcodeObject,
            setPostcodeObjectWrapper
          )}
        </>
      </Collapse>

      <Collapse in={shareHolder.idBusinessForm !== 1}>
        <>
          {getCompanyEdit()}
          {getAdressEdit(
            'Firmensitz',
            companyObject,
            setCompanyObject,
            false,
            postcodeObjectCompany,
            setPostcodeObjectCompanyWrapper,
            'companypostcode'
          )}
          {getNameEdit('Ansprechpartner')}
          {getAdressEdit(
            'Anschrift',
            shareHolder,
            setShareHolder,
            true,
            postcodeObject,
            setPostcodeObjectWrapper
          )}
        </>
      </Collapse>

      <Box sx={{ mt: 5, float: 'right' }}>
        <Button
          variant='outlined'
          sx={{ mr: 2 }}
          onClick={() => props.handleClose()}
        >
          Abrruch
        </Button>
        <Button
          variant='contained'
          disabled={getIsDisabled()}
          onClick={handleAdd}
        >
          Übernehmen
        </Button>
      </Box>
    </>
  );
};
