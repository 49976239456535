import React, { useState } from 'react';
import { ILegalTransactionDataCollection } from '../Interfaces/ILegalTransaction';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { CompanyFormationMain } from './CompanyFormation/CompanyFormationMain';
import {
  ICompanyFormation,
  ICompanyFormationFullObject,
} from '../Interfaces/ICompanyFormation';
import { ArrowDownward } from '@mui/icons-material';
import { InheritMain } from './Inherit/InheritMain';
import { IInheritFullObject } from '../Interfaces/IInherit';
import { PurchaseContractMain } from './PurchaseContract/PurchaseContractMain';
import { IPurchaseContractFullObject } from '../Interfaces/IPurchaseContract';
import { MortgageMain } from './Mortgage/MortgageMain';
import { IMortgage, IMortgageFullObject } from '../Interfaces/IMortgage';
import { IInheritHasPerson } from '../Interfaces/IPerson';
import { IRealEstate } from '../Interfaces/IRealEstate';
import { ICompany } from '../Interfaces/ICompany';
import { useAlert } from '../context/AlertContext';

interface IProps {
  dataCollection: ILegalTransactionDataCollection[];
  setDataCollection: Function;
  isSavePossible: (boolean | Record<string, boolean>)[];
  setIsSavePossible: Function;
}

const DataCollectionAccordion: React.FC<{
  title: string;
  idx: number;
  children: React.ReactElement;
}> = ({ title, children, idx }) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDownward />}
        aria-controls='panel1-content'
        id={`datacollection-panal-${idx}`}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

const isCompanyFormation = (
  dataCollection: ILegalTransactionDataCollection
): dataCollection is ICompanyFormationFullObject => {
  return 'idCompanyFormation' in dataCollection;
};

const isInherit = (
  dataCollection: ILegalTransactionDataCollection
): dataCollection is IInheritFullObject => {
  return 'idInherit' in dataCollection;
};

const isPurchaseContract = (
  dataCollection: ILegalTransactionDataCollection
): dataCollection is IPurchaseContractFullObject => {
  return 'idPurchaseContract' in dataCollection;
};

const isMortgage = (
  dataCollection: ILegalTransactionDataCollection
): dataCollection is IMortgageFullObject => {
  return 'idMortgage' in dataCollection;
};

const dataCollectionTitle = (
  dataCollection: ILegalTransactionDataCollection
) => {
  if (isCompanyFormation(dataCollection)) {
    return companyFormationTitle(dataCollection);
  } else if (isInherit(dataCollection)) {
    return dataCollection.PersonArray.length > 0
      ? `Erbscheinsantrag - ${dataCollection.PersonArray[0].FirstName} ${dataCollection.PersonArray[0].LastName}`
      : 'Erbscheinsantrag';
  } else if (isPurchaseContract(dataCollection)) {
    return dataCollection.RealEstateArray.length > 0
      ? `Kaufvertrag - ${dataCollection.RealEstateArray[0].Street} ${dataCollection.RealEstateArray[0].StreetNr}`
      : 'Kaufvertrag';
  } else if (isMortgage(dataCollection)) {
    return dataCollection.RealEstate.Street &&
      dataCollection.RealEstate.StreetNr
      ? `Grundschuld - ${dataCollection.RealEstate.Street} ${dataCollection.RealEstate.StreetNr}`
      : 'Grundschuld';
  }
  return '';
};

const companyFormationTitle = (companyFormation: ICompanyFormation) => {
  let prefix = 'Unternehmens';
  let sufix = 'änderung';

  if (companyFormation.idBusinessForm === 12) {
    prefix = 'Vereins';
  }
  if (companyFormation.isFounding) {
    sufix = 'gründung';
  }
  return companyFormation.CompanyName
    ? `${prefix}${sufix} - ${companyFormation.CompanyName}`
    : `${prefix}${sufix}`;
};

export const LegalTransactionDataCollection: React.FC<IProps> = ({
  dataCollection,
  setDataCollection,
  isSavePossible,
  setIsSavePossible,
}) => {
  const { showAlert } = useAlert();
  const [isOpenNew, setIsOpenNew] = useState(false);
  const [currentDataCollectionOption, setCurrentDataCollectionOption] =
    useState('Unternehmensgründung');

  const changeArray = (updatedObject: any, idx: number) => {
    const tmpArray = [...dataCollection];
    tmpArray[idx] = updatedObject;
    setDataCollection(tmpArray);
  };

  const changeIsSavePossible = (updatedObject: any, idx: number) => {
    const tempArray = [...isSavePossible];
    tempArray[idx] = updatedObject;
    setIsSavePossible(tempArray);
  };

  const companyFormationTitle = (companyFormation: ICompanyFormation) => {
    let prefix = 'Unternehmens';
    let sufix = 'änderung';

    if (companyFormation.idBusinessForm === 12) {
      prefix = 'Vereins';
    }
    if (companyFormation.isFounding) {
      sufix = 'gründung';
    }
    return `${prefix}${sufix}`;
  };

  const addNewDataCollection = () => {
    if (
      currentDataCollectionOption === 'Unternehmensgründung' ||
      currentDataCollectionOption === 'Unternehmensänderung' ||
      currentDataCollectionOption === 'Vereinsgründung' ||
      currentDataCollectionOption === 'Vereinsänderung'
    ) {
      const newItem: ICompanyFormationFullObject = {
        AddressStreet: null,
        AddressStreetNr: null,
        AmountShares: 0,
        CompanyName: '',
        DateOfFormation: new Date(),
        idBusinessForm:
          currentDataCollectionOption === 'Vereinsgründung' ||
          currentDataCollectionOption === 'Vereinsänderung'
            ? 12
            : 8,
        idCompanyFormation: dataCollection.length * -1,
        idLegalTransaction: -1,
        idPostcode: 1,
        isFounding:
          currentDataCollectionOption === 'Unternehmensgründung' ||
          currentDataCollectionOption === 'Vereinsgründung',
        Postcode_AddressidPostcode: null,
        PurposeOfTheCompany: '',
        ShareCapital: 25000,
        Street: '',
        StreetNr: '',
        useSampleProtocol: false,
        ValuePerShare: 0,
        CompanyFormationAnswerArray: [],
        CompanyFormationHasShareHolderArray: [],
        CompanyFormationShareArray: [],
        DocumentArray: [],
        ShareHolderArray: [],
      };
      setDataCollection([...dataCollection, newItem]);
    } else if (currentDataCollectionOption === 'Erbscheinsantrag') {
      const newItem: IInheritFullObject = {
        ChildrenArray: [],
        hasTestament: false,
        idInherit: dataCollection.length * -1,
        idLegalTransaction: -1,
        idPerson: -1,
        MarriageArray: [],
        PersonArray: [
          {
            isStranger: false,
            FirstName: '',
            LastName: '',
            idGender: 1,
            idAcademicTitle: 1,
            idBusinessForm: 1,
            idCountry: 1,
            Birthday: '',
            idPerson: -1,
            Bank: null,
            BIC: null,
            Birthplace: '',
            Email: null,
            IBAN: null,
            idInherit: -1,
            idPostcode: null,
            PhoneNumber: null,
            Street: '',
            StreetAditional: null,
            StreetNr: '',
            Surname: null,
            TaxNumber: null,
          } as IInheritHasPerson,
        ],
        PersonHasDocument: [],
      };
      setDataCollection([...dataCollection, newItem]);
    } else if (currentDataCollectionOption === 'Kaufvertrag') {
      const newItem: IPurchaseContractFullObject = {
        DocumentArray: [],
        idLegalTransaction: -1,
        idPurchaseContract: dataCollection.length * -1,
        idPurchaseContractType: 1,
        PersonArray: [],
        RealEstateArray: [],
        RealEstateHasInventoryOptionArray: [],
        RealEstateHasPropertyTypeArray: [],
        ThirdPartyClauseText: null,
        withThirdPartyClause: false,
      };
      setDataCollection([...dataCollection, newItem]);
    } else if (currentDataCollectionOption === 'Grundschuld') {
      const IRealEstateObject = {
        idRealEstate: -1,

        Street: null,
        StreetNr: null,
        StreetAditional: null,
        idPostcode: null,
        // Adresse ODER Latitide als Pflich
        Latitude: null,
        Longitude: null,

        idCourt: -1,
        LandRegister: '',
        SheetLandRegister: '',
        Parcel: '',
        LandParcel: '',
        idPropertyType: -1,
        isBuild: false,

        Price: 100000,
        PriceInventoryOption: null,

        idRealEstateUseType: 1,
        idPurchaseContract: -1,

        Defect: null,
        Particularities: null,

        Area: null,
        BuildWith: null,
        EasementText: null,
        hasApartment: false,
        hasEasement: false,
        hasPartialArea: false,
        isMeasured: false,
        Manager_idPerson: null,
        OrderMeasurement_idPurchaseContractRelationType: null,
        PaymentMeasurement_idPurchaseContractRelationType: null,
      } as IRealEstate;
      const companyObject = {
        Company: '',
        RegisterCourt: null,
        RegisterNumber: null,
        SalesTaxNumber: null,
        Street: '',
        StreetAditional: '',
        StreetNr: '',
        TaxNumber: null,
        idBusinessForm: 1,
        idCompany: -1,
        idPostcode: -1,
      } as ICompany;

      const newItem: IMortgageFullObject = {
        PersonArray: [],
        PersonHasDocument: [],
        idMortgage: -1,
        idPerson: -1,
        idLegalTransaction: -1,
        RealEstate: IRealEstateObject,
        NewMortgage: false,
        MortgageInterest: 0,
        AmountMortgage: 0,
        BeneficiaryType: 'PRIVATE',
        BeneficiaryDetails: companyObject,
        PropertyForBuying: null,
        PropertyOwned: null,
        RealEstateLandRegistryData: '',
        Paragraph: false,
        id: 0,
        AdditionalServices: '',
        DocumentArray: [],
        ToBeDeleted: false,
      };
      setDataCollection([...dataCollection, newItem]);
      showAlert({
        text: 'Grundschuld erfolgreich angelegt.Legen Sie bitte einen Eigentümer und einen Begünstigten an um keinen Fehler bei der Speicherung zu erhalten!',
        duration: 10000,
      });
    }
    setIsOpenNew(false);
  };

  return (
    <>
      <Dialog open={isOpenNew} onClose={() => setIsOpenNew(false)}>
        <DialogTitle>Neue Datenerfassung hinzufügen</DialogTitle>
        <DialogContent>
          <TextField
            label='Art der Datenerfassung'
            value={currentDataCollectionOption}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setCurrentDataCollectionOption(event.target.value)
            }
            select
            size='small'
            fullWidth
            sx={{ mt: 2 }}
          >
            <MenuItem
              key='data-collection-Unternehmensgründung'
              value='Unternehmensgründung'
            >
              Unternehmensgründung
            </MenuItem>
            <MenuItem
              key='data-collection-Unternehmensänderung'
              value='Unternehmensänderung'
            >
              Unternehmensänderung
            </MenuItem>
            <MenuItem
              key='data-collection-Vereinsgründung'
              value='Vereinsgründung'
            >
              Vereinsgründung
            </MenuItem>
            <MenuItem
              key='data-collection-Vereinsänderung'
              value='Vereinsänderung'
            >
              Vereinsänderung
            </MenuItem>
            <MenuItem
              key='data-collection-Erbscheinsantrag'
              value='Erbscheinsantrag'
            >
              Erbscheinsantrag
            </MenuItem>
            <MenuItem key='data-collection-Kaufvertrag' value='Kaufvertrag'>
              Kaufvertrag
            </MenuItem>
            <MenuItem key='data-collection-Grundschuld' value='Grundschuld'>
              Grundschuld
            </MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setIsOpenNew(false)}>
            Abbruch
          </Button>
          <Button variant='contained' onClick={addNewDataCollection}>
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>

      <Typography variant='h5' sx={{ mb: 3 }}>
        Datenerfassung
        <Button
          sx={{ float: 'right' }}
          variant='outlined'
          onClick={() => setIsOpenNew(true)}
        >
          Hinzufügen
        </Button>
      </Typography>

      {dataCollection.length === 0 && (
        <>
          <i>Keine Datenerfassung vorhanden </i>
        </>
      )}

      {dataCollection.map((x, idx) => {
        if ('idCompanyFormation' in x) {
          return (
            <DataCollectionAccordion title={dataCollectionTitle(x)} idx={idx}>
              <CompanyFormationMain
                key={`datacollection-element-${idx}`}
                companyFormationObject={x}
                setCompanyFormationObject={(updatedObject: ICompanyFormation) =>
                  changeArray(updatedObject, idx)
                }
              />
            </DataCollectionAccordion>
          );
        } else if ('idInherit' in x) {
          return (
            <DataCollectionAccordion title={dataCollectionTitle(x)} idx={idx}>
              <InheritMain
                key={`datacollection-element-${idx}`}
                inheritObject={x}
                setInheritObject={(updatedObject: IInheritFullObject) =>
                  changeArray(updatedObject, idx)
                }
              />
            </DataCollectionAccordion>
          );
        } else if ('idPurchaseContract' in x) {
          return (
            <DataCollectionAccordion title={dataCollectionTitle(x)} idx={idx}>
              <PurchaseContractMain
                key={`datacollection-element-${idx}`}
                purchaseContractObject={x}
                setPurchaseContractObject={(
                  updatedObject: IPurchaseContractFullObject
                ) => changeArray(updatedObject, idx)}
              />
            </DataCollectionAccordion>
          );
        } else if ('idMortgage' in x) {
          return (
            <DataCollectionAccordion title={dataCollectionTitle(x)} idx={idx}>
              <MortgageMain
                isSavePossibleValue={
                  isSavePossible[idx] as Record<string, boolean>
                }
                setIsSavePossible={(updatedObject: any) =>
                  changeIsSavePossible(updatedObject, idx)
                }
                key={`datacollection-element-${idx}`}
                mortgageObject={x}
                setMortgageObject={(updatedObject: IMortgage) =>
                  changeArray(updatedObject, idx)
                }
              />
            </DataCollectionAccordion>
          );
        }
      })}
    </>
  );
};
