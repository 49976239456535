import { useMutation, useQuery } from '@tanstack/react-query';
import { customFetcher } from './useFetch';
import type { IMeetingEmailTemplate } from '../Interfaces/IMeetingEmailTemplate';
import type { IUserGroup } from '../Interfaces/IUserGroup';
import type { IUser } from '../Interfaces/IUser';
import type { ILegalTransactionType } from '../Interfaces/ILegalTransactionType';
import type { ILegalTransactionProgress } from '../Interfaces/ILegalTransactionProgress';
import type { ILegalRequirementState } from '../Interfaces/ILegalRequirementState';
import type { ILegalTransactionState } from '../Interfaces/ILegalTransactionState';
import type {
  ILegalTransaction,
  ILegalTransactionFullObject,
} from '../Interfaces/ILegalTransaction';
import type { ILegalRequirementTemplate } from '../Interfaces/ILegalRequirementTemplate';
import type { IDocumentDownloaded } from '../Interfaces/IDocumentDownloaded';
import type { IContact, IContactFullObject } from '../Interfaces/IContact';
import type { ILegalTransactionSpecialFunctions } from '../Interfaces/ILegalTransactionSpecialFunctions';
import type { ISendDocument } from '../Interfaces/ISendDocument';
import type { ISystemMail } from '../Interfaces/ISystemMail';
import type { IConfig } from '../Interfaces/IConfig';
import { IDocumentAnalysisAttribute } from '../Interfaces/IDocumentAnalysisAttribute';
import { IDocumentAnalysisSubsection } from '../Interfaces/IDocumentAnalysisSubsection';
import { IDocumentAnalysisSection } from '../Interfaces/IDocumentAnalysisSection';
import { IDocumentAnalysisType } from '../Interfaces/IDocumentAnalysisType';
import type { IDocument } from '../Interfaces/IDocument';

export const useGetLegalTransactionStates = () => {
  const {
    data: legalTransactionStates,
    isLoading: isLoadingLegalTransactionStates,
    refetch: refetchLegalTransactionStates,
  } = useQuery<ILegalTransactionState[]>({
    queryKey: ['legaltransactionstate'],
    queryFn: () => customFetcher({ endpoint: '/legaltransactionstate' }),
  });

  return {
    legalTransactionStates,
    isLoadingLegalTransactionStates,
    refetchLegalTransactionStates,
  };
};

export const useGetLegalTransactionTypes = () => {
  const {
    data: legalTransactionTypes,
    isLoading: isLoadingLegalTransactionTypes,
    refetch: refetchLegalTransactionTypes,
  } = useQuery<ILegalTransactionType[]>({
    queryKey: ['legaltransactiontype'],
    queryFn: () => customFetcher({ endpoint: '/legaltransactiontype' }),
  });

  return {
    legalTransactionTypes,
    isLoadingLegalTransactionTypes,
    refetchLegalTransactionTypes,
  };
};

export const useGetLegalTransactionProgresses = () => {
  const {
    data: legalTransactionProgresses,
    isLoading: isLoadingLegalTransactionProgresses,
    refetch: refetchLegalTransactionProgresses,
  } = useQuery<ILegalTransactionProgress[]>({
    queryKey: ['legaltransactionprogress'],
    queryFn: () => customFetcher({ endpoint: '/legaltransactionprogress' }),
  });

  return {
    legalTransactionProgresses,
    isLoadingLegalTransactionProgresses,
    refetchLegalTransactionProgresses,
  };
};

export const useGetLegalRequirementStates = () => {
  const {
    data: legalRequirementStates,
    isLoading: isLoadingLegalRequirementStates,
    refetch: refetchLegalRequirementStates,
  } = useQuery<ILegalRequirementState[]>({
    queryKey: ['legalrequirementstate'],
    queryFn: () => customFetcher({ endpoint: '/legalrequirementstate' }),
  });

  return {
    legalRequirementStates,
    isLoadingLegalRequirementStates,
    refetchLegalRequirementStates,
  };
};

export const useGetUsers = () => {
  const {
    data: users,
    isLoading: isLoadingUsers,
    refetch: refetchUsers,
  } = useQuery<IUser[]>({
    queryKey: ['userlist'],
    queryFn: () => customFetcher({ endpoint: '/userlist' }),
  });

  return { users, isLoadingUsers, refetchUsers };
};

export const useGetUserGroups = () => {
  const {
    data: userGroups,
    isLoading: isLoadingUserGroups,
    refetch: refetchUserGroups,
  } = useQuery<IUserGroup[]>({
    queryKey: ['usergroup'],
    queryFn: () => customFetcher({ endpoint: '/usergroup' }),
  });

  return { userGroups, isLoadingUserGroups, refetchUserGroups };
};

export const useGetMeetingEmailTemplates = () => {
  const {
    data: meetingEmailTemplates,
    isLoading: isLoadingMeetingEmailTemplates,
    refetch: refetchMeetingEmailTemplates,
  } = useQuery<IMeetingEmailTemplate[]>({
    queryKey: ['meetingemailtemplate'],
    queryFn: () => customFetcher({ endpoint: '/meetingemailtemplate' }),
  });

  return {
    meetingEmailTemplates,
    isLoadingMeetingEmailTemplates,
    refetchMeetingEmailTemplates,
  };
};

export const useGetHasDirectory = (fileId: number) => {
  const { data: hasDirectory, isLoading: isLoadingHasDirectory } =
    useQuery<boolean>({
      queryKey: ['fsdirectory/hasDir', fileId],
      queryFn: () =>
        customFetcher({ endpoint: `/fsdirectory/hasDir/${fileId}` }),
    });

  return { hasDirectory, isLoadingHasDirectory };
};

export const useGetLegalTransactionFullObject = (
  idLegalTransaction: number
) => {
  const {
    data: legalTransactionFullObject,
    isLoading: isLoadingLegalTransactionFullObject,
    refetch: refetchLegalTransactionFullObject,
  } = useQuery<ILegalTransactionFullObject>({
    queryKey: ['legaltransaction/fullObject', idLegalTransaction],
    queryFn: () =>
      customFetcher({
        endpoint: `/legaltransaction/fullObject/${idLegalTransaction}`,
      }),
  });

  return {
    legalTransactionFullObject,
    isLoadingLegalTransactionFullObject,
    refetchLegalTransactionFullObject,
  };
};

export const useGetLegalRequirementTemplates = () => {
  const {
    data: legalRequirementTemplates,
    isLoading: isLoadingLegalRequirementTemplates,
  } = useQuery<ILegalRequirementTemplate[]>({
    queryKey: ['legalrequirementtemplate'],
    queryFn: () => customFetcher({ endpoint: '/legalrequirementtemplate' }),
  });

  return {
    legalRequirementTemplates,
    isLoadingLegalRequirementTemplates,
  };
};

export const useGetDownloadedDocuments = () => {
  const { data: downloadedDocuments, isLoading: isLoadingDownloadedDocuments } =
    useQuery<IDocumentDownloaded[]>({
      queryKey: ['documentdownloaded'],
      queryFn: () => customFetcher({ endpoint: '/documentdownloaded' }),
    });

  return { downloadedDocuments, isLoadingDownloadedDocuments };
};

export const useGetContacts = () => {
  const { data: contacts, isLoading: isLoadingContacts } = useQuery<IContact[]>(
    {
      queryKey: ['contact'],
      queryFn: () => customFetcher({ endpoint: '/contact' }),
    }
  );

  return { contacts, isLoadingContacts };
};

export const useCreateLegalTransactionFullObject = () => {
  const {
    mutateAsync: createLegalTransactionFullObject,
    isPending: isPendingCreateLegalTransactionFullObject,
  } = useMutation({
    mutationFn: (uploadObject: ILegalTransactionFullObject) =>
      customFetcher({
        endpoint: '/legaltransaction/fullObject',
        body: uploadObject,
        method: 'POST',
      }),
  });

  return {
    createLegalTransactionFullObject,
    isPendingCreateLegalTransactionFullObject,
  };
};

export const useUpdateLegalTransactionFullObject = () => {
  const {
    mutateAsync: updateLegalTransactionFullObject,
    isPending: isPendingUpdateLegalTransactionFullObject,
  } = useMutation({
    mutationFn: (uploadObject: ILegalTransactionFullObject) =>
      customFetcher({
        endpoint: '/legaltransaction/fullObject',
        body: uploadObject,
        method: 'PUT',
      }),
  });

  return {
    updateLegalTransactionFullObject,
    isPendingUpdateLegalTransactionFullObject,
  };
};

export const useDeleteLegalTransaction = () => {
  const {
    mutateAsync: deleteLegalTransaction,
    isPending: isPendingDeleteLegalTransaction,
  } = useMutation({
    mutationFn: (idLegalTransaction: number) =>
      customFetcher({
        endpoint: `/legaltransaction/${idLegalTransaction}`,
        method: 'DELETE',
      }),
  });

  return { deleteLegalTransaction, isPendingDeleteLegalTransaction };
};

export const useSearchLegalTransactions = (searchUri?: string) => {
  const abortController = new AbortController();

  const {
    data: legalTransactions,
    isLoading: isLoadingLegalTransactions,
    refetch: refetchLegalTransactions,
  } = useQuery<ILegalTransaction[]>({
    queryKey: ['legaltransaction', 'search', searchUri],
    queryFn: () =>
      customFetcher({
        endpoint: `/legaltransaction?${searchUri}`,
        signal: abortController.signal,
      }),
    enabled: !!searchUri,
  });

  const abort = () => {
    if (isLoadingLegalTransactions) {
      abortController.abort();
    }
  };

  return {
    legalTransactions,
    isLoadingLegalTransactions,
    refetchLegalTransactions,
    abortSearchLegalTransactions: abort,
  };
};

export const useGetLegalTransactionSpecialFunctions = () => {
  const {
    data: legalTransactionSpecialFunctions,
    isLoading: isLoadingLegalTransactionSpecialFunctions,
  } = useQuery<ILegalTransactionSpecialFunctions[]>({
    queryKey: ['legaltransactionspecialfunctions'],
    queryFn: () =>
      customFetcher({ endpoint: '/legaltransactionspecialfunctions' }),
  });

  return {
    legalTransactionSpecialFunctions,
    isLoadingLegalTransactionSpecialFunctions,
  };
};

export const useExportPDF = () => {
  const { mutateAsync: exportPDF, isPending: isPendingExportPDF } = useMutation(
    {
      mutationFn: (uploadObject: ILegalTransactionFullObject) =>
        customFetcher({
          endpoint: '/legaltransaction/export/pdf',
          body: uploadObject,
          method: 'POST',
        }),
    }
  );

  return { exportPDF, isPendingExportPDF };
};

export const useSendDocuments = () => {
  const { mutateAsync: sendDocuments, isPending: isPendingSendDocuments } =
    useMutation({
      mutationFn: (uploadObject: ISendDocument) =>
        customFetcher({
          endpoint: '/legaltransaction/export/sendDocuments',
          body: uploadObject,
          method: 'POST',
        }),
    });

  return { sendDocuments, isPendingSendDocuments };
};

export const useGetSystemMail = (systemMailId: number) => {
  const { data: systemMail, isLoading: isLoadingSystemMail } =
    useQuery<ISystemMail>({
      queryKey: ['systemmail', systemMailId],
      queryFn: () => customFetcher({ endpoint: `/systemmail/${systemMailId}` }),
    });

  return { systemMail, isLoadingSystemMail };
};

export const useGetContactFullObject = (idContact?: number) => {
  const { data: contactFullObject, isLoading: isLoadingContactFullObject } =
    useQuery<IContactFullObject>({
      queryKey: ['contact/fullObject', idContact],
      queryFn: () =>
        customFetcher({ endpoint: `/contact/fullObject/${idContact}` }),
      enabled: !!idContact,
    });

  return { contactFullObject, isLoadingContactFullObject };
};

export const useGetConfig = () => {
  const { data: config, isLoading: isLoadingConfig } = useQuery<IConfig>({
    queryKey: ['config'],
    queryFn: () => customFetcher({ endpoint: '/config/public' }),
  });

  return { config, isLoadingConfig };
};

export const useDocumentAnalysisTypes = () => {
  const {
    data: documentAnalysisTypes,
    isLoading: isLoadingDocumentAnalysisTypes,
  } = useQuery<IDocumentAnalysisType[]>({
    queryKey: ['documentAnalysisType'],
    queryFn: () => customFetcher({ endpoint: '/documentanalysistype' }),
  });

  return { documentAnalysisTypes, isLoadingDocumentAnalysisTypes };
};

export const useDocumentAnalysisSections = () => {
  const {
    data: documentAnalysisSections,
    isLoading: isLoadingDocumentAnalysisSections,
  } = useQuery<IDocumentAnalysisSection[]>({
    queryKey: ['documentAnalysisSection'],
    queryFn: () => customFetcher({ endpoint: '/documentanalysissection' }),
  });

  return { documentAnalysisSections, isLoadingDocumentAnalysisSections };
};

export const useDocumentAnalysisSubsections = () => {
  const {
    data: documentAnalysisSubsections,
    isLoading: isLoadingDocumentAnalysisSubsections,
  } = useQuery<IDocumentAnalysisSubsection[]>({
    queryKey: ['documentAnalysisSubsection'],
    queryFn: () => customFetcher({ endpoint: '/documentanalysissubsection' }),
  });

  return { documentAnalysisSubsections, isLoadingDocumentAnalysisSubsections };
};

export const useDocumentAnalysisAttributes = () => {
  const {
    data: documentAnalysisAttributes,
    isLoading: isLoadingDocumentAnalysisAttributes,
  } = useQuery<IDocumentAnalysisAttribute[]>({
    queryKey: ['documentAnalysisAttribute'],
    queryFn: () => customFetcher({ endpoint: '/documentanalysisattribute' }),
  });

  return { documentAnalysisAttributes, isLoadingDocumentAnalysisAttributes };
};

export const useGetDocument = (idDocument: number) => {
  const { data: document, isLoading: isLoadingDocument } = useQuery<IDocument>({
    queryKey: ['document', idDocument],
    queryFn: () => customFetcher({ endpoint: `/document/${idDocument}` }),
  });

  return { document, isLoadingDocument };
};
