import React, {
  useCallback,
  useRef,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react';
import { IDocument } from '../Interfaces/IDocument';
import {
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DocumentRow } from './DocumentRow';
import { UploadFile } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { State } from '../redux/mainReducer';
import { useDropzone } from 'react-dropzone';
import { IMIMEType } from '../Interfaces/IMIMEType';
import { IDocumentDownloaded } from '../Interfaces/IDocumentDownloaded';

interface IProps {
  documentArray: IDocument[];
  setDocumentArray: Dispatch<SetStateAction<IDocument[]>>;
  allowUpload: boolean;
  allowRemove: boolean;
  title: string;
  downloadedArray?: IDocumentDownloaded[];
  children?: React.ReactElement;
  titleVariant?: 'h4' | 'h5' | 'h6';
  marginTop?: number;
  enableSidepanel?: boolean;
}

export const DocumentOverview: React.FC<IProps> = ({
  documentArray,
  setDocumentArray,
  allowUpload,
  allowRemove,
  title,
  downloadedArray,
  children,
  titleVariant,
  marginTop,
  enableSidepanel = false,
}) => {
  const mimeTypeArray = useSelector(
    (state: State) => state.mimeTypeArray.storeAcademicTitle
  );
  const [enableUpload, setEnableUpload] = useState(false);
  const inputUpload = useRef<HTMLInputElement | null>(null);

  async function fileToBase64(file: File) {
    const result_base64 = await new Promise(resolve => {
      const fileReader = new FileReader();
      fileReader.onload = () => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });
    return String(result_base64).split(',')[1];
  }

  const handleFilesUpload = async (acceptedFiles: File[]) => {
    const uploadArray: IDocument[] = [];
    let minIdOffset = -1;

    if (documentArray.length > 0) {
      const minIdArray = Math.min(
        ...documentArray.map(document => document.idDocument)
      );

      if (minIdArray <= minIdOffset) {
        minIdOffset = minIdArray - 1;
      }
    }

    let index = 0;

    for (const currentFile of acceptedFiles) {
      const fileName: string = String(currentFile['name']);
      const mimeType: IMIMEType | undefined = mimeTypeArray.find(
        mimeType => mimeType.MIMEType === currentFile['type']
      );

      if (mimeType) {
        uploadArray.push({
          idDocument: minIdOffset - index,
          FileName: fileName,
          idMIMEType: mimeType.idMIMEType,
          Data: await fileToBase64(currentFile),
          Created_at: new Date().toISOString(),
        } as IDocument);
      }
      index = index + 1;
    }

    setDocumentArray([...documentArray, ...uploadArray]);
  };

  const handleNormalUpload = (files: null | FileList) => {
    if (files) {
      const acceptedFiles: File[] = Array.from(files);
      handleFilesUpload(acceptedFiles);
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      handleFilesUpload(acceptedFiles);
      setEnableUpload(false);
    },
    [documentArray]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  });

  const dropZoneTSX = () => {
    return (
      <div>
        <input
          {...getInputProps()}
          accept={String(
            mimeTypeArray.map(mimeType => mimeType.MIMEType).join(', ')
          )}
        />
        <Card>
          <CardContent>
            <Typography variant='body2'>Dokument(e) hier reinziehen</Typography>
            Erlaubte Datentypen
            <br />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Datentyp</TableCell>
                  <TableCell>Datenendung</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mimeTypeArray.map(mimeType => (
                  <TableRow>
                    <TableCell>{mimeType.MIMEType}</TableCell>
                    <TableCell>{mimeType.FileExtension}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </div>
    );
  };

  return (
    <div {...getRootProps()}>
      <input
        hidden
        ref={inputUpload}
        type='file'
        accept={String(
          mimeTypeArray.map(mimeType => mimeType.MIMEType).join(', ')
        )}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleNormalUpload(event.target.files)
        }
      />

      <Typography
        variant={titleVariant || 'h5'}
        sx={{ mb: 2, mt: marginTop || 0 }}
      >
        {title}
        {allowUpload && (
          <IconButton
            title='Daten über Auswähl hochladen'
            onClick={() => inputUpload && inputUpload.current?.click()}
            sx={{ float: 'right' }}
          >
            <UploadFile />
          </IconButton>
        )}
        {allowUpload && (
          <>
            <br />
            <Typography variant='caption'>
              (Hier können Sie Ihre Dokumente per <i>Drag & Drop</i> hochladen)
            </Typography>
          </>
        )}
      </Typography>

      {(isDragActive || enableUpload) && allowUpload && dropZoneTSX()}

      {!children ? (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nr.</TableCell>
                {downloadedArray && <TableCell>Runtergeladen</TableCell>}
                <TableCell>Dateiname</TableCell>
                <TableCell>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documentArray.map(document => (
                <DocumentRow
                  key={`idDocument-${document.idDocument}`}
                  documentObject={document}
                  documentArray={documentArray}
                  setDocumentArray={setDocumentArray}
                  mimeTypeArray={mimeTypeArray}
                  allowRemove={allowRemove}
                  downloadedArray={downloadedArray}
                  enableSidepanel={enableSidepanel}
                />
              ))}
            </TableBody>
          </Table>
        </>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};
