import React, { useState } from 'react';
import { useFetch } from '../hooks/useFetch';
import { IUserGroup } from '../Interfaces/IUserGroup';
import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import { IUser } from '../Interfaces/IUser';
import { CustomCircularProgress } from '../generic/CustomCircularProgress';
import { DataGrid, GridColDef, deDE } from '@mui/x-data-grid';
import { Add } from '@mui/icons-material';
import { UserGroupEdit } from './UserGroupEdit';

const columns: GridColDef[] = [
  {
    field: 'idUserGroup',
    headerName: 'Nr.',
    width: 90,
  },
  {
    field: 'Email',
    headerName: 'E-Mail',
    width: 250,
  },
  {
    field: 'PhoneNumber',
    headerName: 'Rufnummer',
    width: 250,
  },
  {
    field: 'UserGroup',
    headerName: 'Gruppe',
    flex: 1,
  },
];

export const UserGroupOverview: React.FC = () => {
  const [userGroupArray, setUserGroupArray, waswasSuccessfully] =
    useFetch<IUserGroup[]>('/usergroup');
  const [userArray, setUserArray, waswasSuccessfullyUser] =
    useFetch<IUser[]>('/user');
  //
  const [idUserGroup, setIdUserGroup] = useState<number | null>(null);

  if (!waswasSuccessfully || !waswasSuccessfullyUser) {
    return <Alert severity='error'>Fehler!</Alert>;
  } else if (userGroupArray === undefined || userArray === undefined) {
    return <CustomCircularProgress />;
  } else {
    return (
      <>
        <Dialog
          open={idUserGroup !== null}
          onClose={() => setIdUserGroup(null)}
          maxWidth='md'
          fullWidth
        >
          <DialogContent>
            {idUserGroup !== null && (
              <UserGroupEdit
                idUserGroup={idUserGroup}
                setIdUserGroup={setIdUserGroup}
                userArray={userArray}
                userGroupArray={userGroupArray}
                setUserGroupArray={setUserGroupArray}
              />
            )}
          </DialogContent>
        </Dialog>

        <Box sx={{ m: 2, mt: 0 }}>
          <Typography variant='h4'>
            Benutzergruppen
            <IconButton
              sx={{ float: 'right' }}
              onClick={() => setIdUserGroup(-1)}
            >
              <Add />
            </IconButton>
          </Typography>

          <DataGrid
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            rows={userGroupArray}
            columns={columns}
            getRowId={row => row.idUserGroup}
            onRowClick={(params, event, details) =>
              setIdUserGroup(Number(params.id))
            }
            autoHeight
          />
        </Box>
      </>
    );
  }
};
