import React, { useEffect, useState } from 'react';
import { uploadFetch, useFetch } from '../hooks/useFetch';
import { IFSDirectoryType } from '../Interfaces/IFSDirectoryType';
import { IFSDirectoryProtocol } from '../Interfaces/IFSDirectoryProtocol';
import { IFSDirectory } from '../Interfaces/IFSDirectory';
import { CustomCircularProgress } from '../generic/CustomCircularProgress';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { CustomeTextField } from '../generic/CustomeTextField';
import { ILegalTransaction } from '../Interfaces/ILegalTransaction';
import { ILegalTransactionRecordNumber } from '../Interfaces/ILegalTransactionRecordNumber';
import { ILegalTransactionTransactionNumber } from '../Interfaces/ILegalTransactionTransactionNumber';
import { ILegalRequirementState } from '../Interfaces/ILegalRequirementState';
import { DocumentPortalAccordion } from './DocumentPortalAccordion';

interface ICurrentFilterSettings {
  idFSDirectoryType: number;
  idFSDirectoryArray: number[];
  create_time_filter: number;
  show_empty_directories: boolean;
  show_sorted_directories: boolean;
}

export interface IFSScanResult {
  fileName: string;
  idFSDirectory: number;
  path: string;
  created_at: string;
  //preview: string;
  isSorted: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const DocumentPortalOverview: React.FC = () => {
  const [fsDirTypeArray, setFsDirTypeArray, wasSuccessfullyfsDirType] =
    useFetch<IFSDirectoryType[]>('/FSDirectoryType'.toLocaleLowerCase());
  const [
    fsDirProtocollArray,
    setFsDirProtocollArray,
    wasSuccessfullyfsDirProtocoll,
  ] = useFetch<IFSDirectoryProtocol[]>(
    '/FSDirectoryProtocol'.toLocaleLowerCase()
  );
  const [fsDirArray, setFsDirArray, wasSuccessfullyfsDir] = useFetch<
    IFSDirectory[]
  >('/FSDirectory'.toLocaleLowerCase());
  //
  const [
    legalTransactionArray,
    setLegalTransactionArray,
    wasSuccessfullyLegalTranscation,
  ] = useFetch<ILegalTransaction[]>('/legaltransaction');
  const [
    recordNumberArray,
    setRecordNumberArray,
    wasSuccessfullyRecordNumberArray,
  ] = useFetch<ILegalTransactionRecordNumber[]>(
    '/legaltransaction/recordnumber'
  );
  const [
    transactionNumberArray,
    setTransactionNumberArray,
    wasSuccessfullyTransactionNumberArray,
  ] = useFetch<ILegalTransactionTransactionNumber[]>(
    '/legaltransaction/transactionnumber'
  );
  const [legalReqState, setLegalReqState, wasSuccessfullyLegalLegalReqState] =
    useFetch<ILegalRequirementState[]>('/legalrequirementstate');
  //
  const [filterObject, setFilterObject] = useState(
    localStorage.getItem('fs_directory_filter_object') !== null
      ? (JSON.parse(
          localStorage.getItem('fs_directory_filter_object')!
        ) as ICurrentFilterSettings)
      : ({
          idFSDirectoryType: 0,
          idFSDirectoryArray: [],
          create_time_filter: 1,
          show_empty_directories: true,
          show_sorted_directories: true,
        } as ICurrentFilterSettings)
  );
  //
  const [fsScanArray, setFsScanArray] = useState<IFSScanResult[]>([]);
  const [clickedElement, setClickedElement] = useState('');
  const [isLoadingScan, setIsLoadingScan] = useState(false);
  //
  const [searchTerm, setSearchTerm] = useState('');
  //
  const [dateHelpArray, setDateHelpArray] = useState<Date[]>(() => {
    let currenDate = new Date();
    const returnArray: Date[] = [];
    let i = 0;

    returnArray.push(new Date());

    while (i < 30) {
      returnArray.push(currenDate);
      currenDate = new Date(currenDate.setDate(currenDate.getDate() - 1));
      i++;
    }
    return returnArray;
  });

  const wrapperSetFilterObject = (
    updatedFilterObject: ICurrentFilterSettings
  ) => {
    localStorage.setItem(
      'fs_directory_filter_object',
      JSON.stringify(updatedFilterObject)
    );
    setFilterObject(updatedFilterObject);
  };

  useEffect(() => {
    if (fsDirArray !== undefined) {
      let localFilterObject = {
        ...filterObject,
        idFSDirectoryArray: fsDirArray.map(x => x.idFSDirectory),
      };
      if (localStorage.getItem('fs_directory_filter_object') !== null) {
        localFilterObject = JSON.parse(
          localStorage.getItem('fs_directory_filter_object')!
        ) as ICurrentFilterSettings;
      }
      wrapperSetFilterObject(localFilterObject);
      handleScan({}, localFilterObject);
    }
  }, [fsDirArray]);

  const handleChange = (
    event: SelectChangeEvent<typeof filterObject.idFSDirectoryArray>
  ) => {
    const {
      target: { value },
    } = event;
    if (typeof value !== 'string') {
      wrapperSetFilterObject({ ...filterObject, idFSDirectoryArray: value });
    }
  };

  const handleScan = (
    event: any,
    localFilterObject: undefined | ICurrentFilterSettings = undefined
  ) => {
    uploadFetch(
      '/fsdirectory/scan',
      true,
      localFilterObject === undefined ? filterObject : localFilterObject,
      setFsScanArray,
      undefined,
      undefined,
      setIsLoadingScan
    );
  };

  if (
    !wasSuccessfullyfsDirType ||
    !wasSuccessfullyfsDirProtocoll ||
    !wasSuccessfullyfsDir ||
    !wasSuccessfullyLegalTranscation ||
    !wasSuccessfullyRecordNumberArray ||
    !wasSuccessfullyTransactionNumberArray ||
    !wasSuccessfullyLegalLegalReqState
  ) {
    return <>Fehler!</>;
  } else if (
    fsDirTypeArray === undefined ||
    fsDirProtocollArray === undefined ||
    fsDirArray === undefined ||
    legalTransactionArray === undefined ||
    recordNumberArray === undefined ||
    transactionNumberArray === undefined ||
    legalReqState === undefined ||
    isLoadingScan
  ) {
    return <CustomCircularProgress />;
  } else {
    return (
      <>
        <Typography variant='h4'>Posteingang</Typography>
        (Rechter Mausklick wird unterstützt)
        <Box sx={{ mt: 2 }} />
        <Grid container spacing={2}>
          {fsDirArray.length === 0 && (
            <Grid item xs={12}>
              <Alert severity='warning'>
                <b>Es wurden kein eingebundener Ordner gefunden!</b>
                <br />
                Ein Administrator muss in der Ordnerverwaltung einen Ordner
                anlegen, dass dieser im Posteingang durchsuchbar ist.
              </Alert>
            </Grid>
          )}
          <Grid item xs={5}>
            <CustomeTextField
              label='Ordnerart'
              object={filterObject}
              setObject={(filteredObject: ICurrentFilterSettings) => {
                wrapperSetFilterObject({
                  ...filteredObject,
                  idFSDirectoryArray: fsDirArray
                    .filter(x =>
                      filteredObject.idFSDirectoryType === 0
                        ? true
                        : filteredObject.idFSDirectoryType ===
                          x.idFSDirectoryType
                    )
                    .map(fsDir => fsDir.idFSDirectory),
                });
                console.log('....');
                console.log(filteredObject);
                console.log({
                  ...filteredObject,
                  idFSDirectoryArray: fsDirArray
                    .filter(x =>
                      filteredObject.idFSDirectoryType === 0
                        ? true
                        : filteredObject.idFSDirectoryType ===
                          x.idFSDirectoryType
                    )
                    .map(fsDir => fsDir.idFSDirectory),
                });
              }}
              attr='idFSDirectoryType'
              type='select'
            >
              {[
                {
                  idFSDirectoryType: 0,
                  FSDirectoryType: 'Alle',
                } as IFSDirectoryType,
                ...fsDirTypeArray,
              ].map(x => (
                <MenuItem
                  key={`search-fstye-${x.idFSDirectoryType}`}
                  value={x.idFSDirectoryType}
                >
                  {x.FSDirectoryType}
                </MenuItem>
              ))}
            </CustomeTextField>
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth>
              <InputLabel id='demo-multiple-checkbox-label'>Ordner</InputLabel>
              <Select
                labelId='demo-multiple-checkbox-label'
                id='demo-multiple-checkbox'
                multiple
                value={filterObject.idFSDirectoryArray}
                onChange={handleChange}
                input={<OutlinedInput label='Tag' />}
                renderValue={selected =>
                  selected
                    .map(
                      x =>
                        fsDirArray
                          .filter(x =>
                            filterObject.idFSDirectoryType === 0
                              ? true
                              : filterObject.idFSDirectoryType ===
                                x.idFSDirectoryType
                          )
                          .find(y => y.idFSDirectory === x)?.Name
                    )
                    .join(', ')
                }
                MenuProps={MenuProps}
                size='small'
                fullWidth
              >
                {fsDirArray
                  .filter(x =>
                    filterObject.idFSDirectoryType === 0
                      ? true
                      : filterObject.idFSDirectoryType === x.idFSDirectoryType
                  )
                  .map(fsDir => (
                    <MenuItem
                      key={`fs-dir-${fsDir.idFSDirectory}`}
                      value={fsDir.idFSDirectory}
                    >
                      <Checkbox
                        checked={filterObject.idFSDirectoryArray.includes(
                          fsDir.idFSDirectory
                        )}
                      />
                      <ListItemText primary={fsDir.Name} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          {/*
          <Grid item xs={2}>
            <CustomeTextField
              label='Dokumente von'
              object={filterObject}
              attr='create_time_filter'
              setObject={setFilterObject}
              type='select'
            >
              <MenuItem key={`fs-timestamp-1`} value={1}>
                Heute
              </MenuItem>
              <MenuItem key={`fs-timestamp-2`} value={2}>
                Letzte 3 Tage
              </MenuItem>
              <MenuItem key={`fs-timestamp-3`} value={3}>
                Diese Woche
              </MenuItem>
              <MenuItem key={`fs-timestamp-4`} value={4}>
                Diesen Monat
              </MenuItem>
            </CustomeTextField>
          </Grid>
          */}

          <Grid item xs={2}>
            <Button onClick={handleScan} variant='outlined' fullWidth>
              Aktualisieren
            </Button>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label='Suche'
              value={searchTerm}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSearchTerm(event.target.value)
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    value={Boolean(filterObject.show_empty_directories)}
                    onChange={() =>
                      wrapperSetFilterObject({
                        ...filterObject,
                        show_empty_directories:
                          !filterObject.show_empty_directories,
                      })
                    }
                    defaultChecked={Boolean(
                      filterObject.show_empty_directories
                    )}
                  />
                }
                label='Leere Tage'
              />
              <FormControlLabel
                control={
                  <Switch
                    value={Boolean(filterObject.show_sorted_directories)}
                    onChange={() =>
                      wrapperSetFilterObject({
                        ...filterObject,
                        show_sorted_directories:
                          !filterObject.show_sorted_directories,
                      })
                    }
                    defaultChecked={Boolean(
                      filterObject.show_sorted_directories
                    )}
                  />
                }
                label='Voll sortierte Tage'
              />
            </FormGroup>
          </Grid>
        </Grid>
        <Box sx={{ mt: 5 }} />
        {searchTerm === '' ? (
          <>
            {dateHelpArray
              .filter(currentDate =>
                filterObject.show_empty_directories
                  ? true
                  : fsScanArray.filter(x => {
                      const dateOfDocument = new Date(x.created_at);
                      return (
                        dateOfDocument.getFullYear() ===
                          currentDate.getFullYear() &&
                        dateOfDocument.getMonth() === currentDate.getMonth() &&
                        dateOfDocument.getDate() === currentDate.getDate()
                      );
                    }).length > 0
              )
              .filter(currentDate =>
                filterObject.show_sorted_directories
                  ? true
                  : fsScanArray.filter(x => {
                        const dateOfDocument = new Date(x.created_at);
                        return (
                          dateOfDocument.getFullYear() ===
                            currentDate.getFullYear() &&
                          dateOfDocument.getMonth() ===
                            currentDate.getMonth() &&
                          dateOfDocument.getDate() === currentDate.getDate()
                        );
                      }).length === 0
                    ? true
                    : fsScanArray
                        .filter(x => {
                          const dateOfDocument = new Date(x.created_at);
                          return (
                            dateOfDocument.getFullYear() ===
                              currentDate.getFullYear() &&
                            dateOfDocument.getMonth() ===
                              currentDate.getMonth() &&
                            dateOfDocument.getDate() === currentDate.getDate()
                          );
                        })
                        .filter(x => x.isSorted === false).length > 0
              )
              .map(currentDate => (
                <DocumentPortalAccordion
                  key={`document-accordinion-${currentDate.toISOString()}`}
                  currentDate={currentDate}
                  fsScanArray={fsScanArray}
                  setFsScanArray={setFsScanArray}
                  legalTransactionArray={legalTransactionArray}
                  legalReqState={legalReqState}
                  recordNumberArray={recordNumberArray}
                  transactionNumberArray={transactionNumberArray}
                  clickedElement={clickedElement}
                  setClickedElement={setClickedElement}
                />
              ))}
          </>
        ) : (
          <>
            {fsScanArray
              .filter(x =>
                x.fileName
                  .toLocaleLowerCase()
                  .includes(searchTerm.toLocaleLowerCase())
              )
              .map(x => new Date(x.created_at))
              .map(currentDate => (
                <DocumentPortalAccordion
                  key={`document-accordinion-${currentDate.toISOString()}`}
                  currentDate={currentDate}
                  fsScanArray={fsScanArray.filter(x =>
                    x.fileName
                      .toLocaleLowerCase()
                      .includes(searchTerm.toLocaleLowerCase())
                  )}
                  setFsScanArray={setFsScanArray}
                  legalTransactionArray={legalTransactionArray}
                  legalReqState={legalReqState}
                  recordNumberArray={recordNumberArray}
                  transactionNumberArray={transactionNumberArray}
                  clickedElement={clickedElement}
                  setClickedElement={setClickedElement}
                  openAsOpen
                />
              ))}
          </>
        )}
      </>
    );
  }
};
