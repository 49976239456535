import React, { useEffect, useState } from 'react';
import { IconButton, TableCell, TableRow, TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { Add } from '@mui/icons-material';
import { SharesChildRow } from './SharesChildRow';
import { roundFunctionNormal, roundFunctionProz } from '../../help_functions';
import { IShareHolderFullObject } from '../../../../../Interfaces/IShareHolder';
import { ICompanyFormation } from '../../../../../Interfaces/ICompanyFormation';
import { ICompanyFormationShare } from '../../../../../Interfaces/ICompanyFormationShare';

export interface IProps {
  shareHolderObject: IShareHolderFullObject;
  companyFormationObject: ICompanyFormation;
  companyFormationShareArray: ICompanyFormationShare[];
  setCompanyFormationShareArray: Function;
  shareHolderArray: IShareHolderFullObject[];
  setShareHolderArray: Function;
}

export const SharesMainRow: React.FC<IProps> = props => {
  //const [shareHolderObject,setShareHolderObject] = useState(props.shareHolderObject);
  const [ownShares, setOwnShares] = useState(
    props.companyFormationShareArray.filter(
      x => x.idShareHolder === props.shareHolderObject.idShareHolder
    )
  );

  const setShareHolderObject = (shareHolderObject: IShareHolderFullObject) => {
    props.setShareHolderArray([
      ...props.shareHolderArray.map(x =>
        x.idShareHolder === shareHolderObject.idShareHolder
          ? shareHolderObject
          : x
      ),
    ]);
  };

  const handleChangeProz = (values: any, sourceInfo: any) => {
    const value = Math.round((Number(values.floatValue) / 100) * 1000) / 1000;

    if (ownShares.length === 1) {
      const valueTotal =
        Math.round(value * props.companyFormationObject.ShareCapital * 100) /
        100;
      const valuePerShare =
        Math.round((valueTotal / ownShares[0].AmountOfShares) * 1000) / 1000;
      setOwnShares([
        {
          ...ownShares[0],
          ValuePerShare: valuePerShare,
        },
      ]);
    }

    setShareHolderObject({
      ...props.shareHolderObject,
      Shares: value,
    });
  };

  const handleAddShare = () => {
    let minId =
      Math.min(
        ...props.companyFormationShareArray.map(x => x.idCompanyFormationShare)
      ) - 1;

    if (minId >= 0) {
      minId = -1;
    }

    // Upadte Parent über UseEffect
    setOwnShares([
      ...ownShares,
      {
        idCompanyFormationShare: minId,
        AmountOfShares: 0,
        idCompanyFormation: -1,
        idShareHolder: props.shareHolderObject.idShareHolder,
        ValuePerShare: 0,
      } as ICompanyFormationShare,
    ]);
  };

  useEffect(() => {
    props.setCompanyFormationShareArray([
      ...props.companyFormationShareArray.filter(
        x => x.idShareHolder !== props.shareHolderObject.idShareHolder
      ),
      ...ownShares,
    ]);
  }, [ownShares]);

  return (
    <>
      <TableRow>
        <TableCell>
          {props.shareHolderObject.idBusinessForm === 1
            ? `${props.shareHolderObject.FirstName} ${props.shareHolderObject.LastName}`
            : `${props.shareHolderObject.CompanyObject?.Company}`}
        </TableCell>
        <TableCell sx={{ width: 150 }}>
          <NumericFormat
            value={roundFunctionProz(props.shareHolderObject.Shares * 100)}
            suffix=' %'
            fixedDecimalScale
            thousandsGroupStyle='thousand'
            thousandSeparator='.'
            decimalSeparator=','
            fullWidth
            customInput={TextField}
            allowNegative={false}
            label='Gesamteinlage in %'
            size='small'
            onValueChange={handleChangeProz}
          />
        </TableCell>
        <TableCell sx={{ width: 200 }}>
          <NumericFormat
            value={roundFunctionNormal(
              props.shareHolderObject.Shares *
                props.companyFormationObject.ShareCapital
            )}
            suffix=' €'
            fixedDecimalScale
            thousandsGroupStyle='thousand'
            thousandSeparator='.'
            decimalSeparator=','
            fullWidth
            customInput={TextField}
            allowNegative={false}
            label='Gesamteinlage in EUR'
            size='small'
            disabled
            error={
              roundFunctionNormal(
                props.shareHolderObject.Shares *
                  props.companyFormationObject.ShareCapital
              ) %
                1 !==
              0
            }
          />
        </TableCell>

        <TableCell sx={{ width: 100 }}>
          {ownShares.map(x => x.AmountOfShares).reduce((a, b) => a + b, 0)}
        </TableCell>
        <TableCell sx={{ width: 200 }}>
          <NumericFormat
            value={ownShares
              .map(x => x.AmountOfShares * x.ValuePerShare)
              .reduce((a, b) => a + b, 0)}
            suffix=' €'
            fixedDecimalScale
            thousandsGroupStyle='thousand'
            thousandSeparator='.'
            decimalSeparator=','
            fullWidth
            customInput={TextField}
            allowNegative={false}
            label='Nennbetrag Anteil(e)'
            size='small'
            disabled
            error={
              roundFunctionNormal(
                ownShares
                  .map(x => x.AmountOfShares * x.ValuePerShare)
                  .reduce((a, b) => a + b, 0)
              ) !==
              roundFunctionNormal(
                props.shareHolderObject.Shares *
                  props.companyFormationObject.ShareCapital
              )
            }
          />
        </TableCell>

        <TableCell sx={{ width: 90 }}>
          <IconButton onClick={handleAddShare}>
            <Add />
          </IconButton>
        </TableCell>
      </TableRow>

      {ownShares.map((x, i) => (
        <SharesChildRow
          index={i}
          key={`childorw-${x.idCompanyFormationShare}`}
          shareObject={x}
          companyFormationShareArray={ownShares}
          setCompanyFormationShareArray={setOwnShares}
          totalValue={
            props.shareHolderObject.Shares *
            props.companyFormationObject.ShareCapital
          }
        />
      ))}
    </>
  );
};
