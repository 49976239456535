import React from 'react';
import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { State } from '../../../../redux/mainReducer';
import { ICompanyFormationAnswer } from '../../../../Interfaces/ICompanyFormationAnswer';

interface IProps {
  companyFormationAnswerArray: ICompanyFormationAnswer[];
}

export const CompanyFormationAnswerSummary: React.FC<IProps> = props => {
  const companyFormationQuestionArray = useSelector(
    (state: State) =>
      state.companyFormationQuestionArray.storeCompanyFormationQuestion
  );
  const companyFormationOptionArray = useSelector(
    (state: State) =>
      state.companyFormationOptionArray.storeCompanyFormationOption
  );

  return (
    <>
      <Box sx={{ mt: 2 }} />
      <Grid container sx={{ pl: 10 }} spacing={2}>
        {props.companyFormationAnswerArray.map(companyFormationAnswerObject => {
          const foundQuestion = companyFormationQuestionArray.find(
            x =>
              x.idCompanyFormationQuestion ===
              companyFormationAnswerObject.idCompanyFormationQuestion
          );
          const foundOption = companyFormationOptionArray.find(
            x =>
              x.idCompanyFormationOption ===
              companyFormationAnswerObject.idCompanyFormationOption
          );

          if (foundQuestion === undefined) {
            return (
              <Grid item sm={12}>
                <b>Fehler</b>
              </Grid>
            );
          } else if (
            foundOption === undefined ||
            foundOption.idCompanyFormationOption === -1
          ) {
            return (
              <>
                <Grid item sm={4}>
                  <b>{foundQuestion.CompanyFormationQuestion}:</b>
                </Grid>
                <Grid item sm={8}>
                  -
                </Grid>
              </>
            );
          } else {
            return (
              <>
                <Grid item sm={4}>
                  <b>{foundQuestion.CompanyFormationQuestion}:</b>
                </Grid>
                <Grid item sm={8}>
                  {foundOption.CompanyFormationOption}
                </Grid>
                {(Boolean(foundOption.allowNumberValue) ||
                  Boolean(foundOption.allowTextfield)) && (
                  <>
                    <Grid item sm={4}></Grid>
                    <Grid item sm={8}>
                      {foundOption.allowNumberValue
                        ? companyFormationAnswerObject.NumberValue
                        : companyFormationAnswerObject.TextValue}
                    </Grid>
                  </>
                )}
              </>
            );
          }
        })}
      </Grid>
    </>
  );
};
