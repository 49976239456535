import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DayOffWorkNew } from './Childs/DayOffWorkNew';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { DayOffWorkUserRow } from './Childs/DayOffWorkUserRow';
import { IDayOffWork } from '../Interfaces/IDayOffWork';
import { IDayOffWorkCategory } from '../Interfaces/IDayOffWorkCategory';
import { IUser } from '../Interfaces/IUser';
import { IEmploymentContract } from '../Interfaces/IEmploymentContract';
export interface IHelpCalenderDay {
  title: string;
  Date: Date;
}

const genHelpCanederArray = (baseDate: Date) => {
  const returnArray: IHelpCalenderDay[] = [];
  const currentDate = new Date(baseDate);
  const lastDayOfMonth = new Date(baseDate);
  // Sete Uhrzeit auf 00000
  currentDate.setHours(0, 0, 0, 0);
  lastDayOfMonth.setHours(0, 0, 0, 0);
  // Setze ersten Tag
  currentDate.setDate(1);
  // Setze letzten Tag
  lastDayOfMonth.setDate(1);
  lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1);
  lastDayOfMonth.setDate(lastDayOfMonth.getDate() - 1);
  //lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() +1)
  //lastDayOfMonth.setDate(0);

  while (currentDate <= lastDayOfMonth) {
    const dd = currentDate.getDate();
    const mm = currentDate.getMonth() + 1;
    //let dayOfWeek = currentDate.get
    returnArray.push({
      title: `${new Date(currentDate).toLocaleDateString('de-DE', { weekday: 'short' })} ${dd}.`,
      Date: new Date(currentDate),
    } as IHelpCalenderDay);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return returnArray;
};

const getMonthTitle = (baseDate: Date) => {
  let montString = '';
  const yearString = baseDate.getFullYear();
  const currentMonth = baseDate.getMonth() + 1;

  if (currentMonth === 1) {
    montString = 'Januar';
  } else if (currentMonth === 2) {
    montString = 'Februar';
  } else if (currentMonth === 3) {
    montString = 'März';
  } else if (currentMonth === 4) {
    montString = 'April';
  } else if (currentMonth === 5) {
    montString = 'Mai';
  } else if (currentMonth === 6) {
    montString = 'Juni';
  } else if (currentMonth === 7) {
    montString = 'Juli';
  } else if (currentMonth === 8) {
    montString = 'August';
  } else if (currentMonth === 9) {
    montString = 'September';
  } else if (currentMonth === 10) {
    montString = 'Oktober';
  } else if (currentMonth === 11) {
    montString = 'November';
  } else if (currentMonth === 12) {
    montString = 'Dezember';
  }

  return `${montString} ${yearString}`;
};

const getCurrentDateZeroTime = () => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  return new Date(currentDate);
};

interface IProps {
  userArray: IUser[];
  employmentContractArray: IEmploymentContract[];
  dayOffWorkCategoryArray: IDayOffWorkCategory[];
  dayOffWorkArray: IDayOffWork[];
  setDayOffWorkArray: Function;
}

export const DayOffWorkOverviewTable: React.FC<IProps> = ({
  userArray,
  employmentContractArray,
  dayOffWorkCategoryArray,
  dayOffWorkArray,
  setDayOffWorkArray,
}) => {
  const [baseDate, setBaseDate] = useState(getCurrentDateZeroTime());
  const [calenderHelpArray, setCalenderHelpArray] = useState<
    IHelpCalenderDay[]
  >(genHelpCanederArray(baseDate));
  const [isOpenNew, setIsOpenNew] = useState(false);

  const handleChange = (newValue: number) => {
    const tmpDate = new Date(baseDate);
    tmpDate.setDate(1);
    tmpDate.setMonth(tmpDate.getMonth() + newValue);
    setBaseDate(tmpDate);
  };

  useEffect(() => {
    setCalenderHelpArray(genHelpCanederArray(baseDate));
  }, [baseDate]);

  return (
    <>
      <Dialog
        open={isOpenNew}
        onClose={() => setIsOpenNew(false)}
        maxWidth='md'
        fullWidth
      >
        <DialogTitle>Neuer Fehltag</DialogTitle>
        <DialogContent>
          <DayOffWorkNew
            dayOffWorkCategoryArray={dayOffWorkCategoryArray}
            dayOffWorkArray={dayOffWorkArray}
            setDayOffWorkArray={setDayOffWorkArray}
            userArray={userArray}
            employmentContractArray={employmentContractArray}
            handleClose={() => setIsOpenNew(false)}
          />
        </DialogContent>
      </Dialog>

      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2} sx={{ borderRight: 'solid 1px' }}>
              Mitarbeiter
            </TableCell>
            <TableCell
              sx={{ borderRight: 'solid 1px' }}
              colSpan={calenderHelpArray.length}
            >
              <IconButton onClick={() => handleChange(-1)}>
                <ArrowLeft />
              </IconButton>
              {getMonthTitle(baseDate)}
              <Button
                sx={{ ml: 2 }}
                variant='outlined'
                onClick={() => setIsOpenNew(true)}
              >
                Neuer Fehltag
              </Button>
              <IconButton
                sx={{ float: 'right' }}
                onClick={() => handleChange(1)}
              >
                <ArrowRight />
              </IconButton>
            </TableCell>
            <TableCell sx={{ borderRight: 'solid 1px' }} rowSpan={2}>
              U.
            </TableCell>
            <TableCell sx={{ borderRight: 'solid 1px' }} rowSpan={2}>
              K.
            </TableCell>
            <TableCell sx={{ borderRight: 'solid 1px' }} rowSpan={2}>
              U frei
            </TableCell>
            <TableCell sx={{ borderRight: 'solid 1px' }} rowSpan={2}>
              K tot.
            </TableCell>
          </TableRow>
          <TableRow>
            {calenderHelpArray.map(x => (
              <TableCell sx={{ width: 5, borderRight: 'solid 1px' }}>
                <Box
                  sx={{
                    color:
                      x.Date.getDay() === 0 || x.Date.getDay() === 6
                        ? '#a0a0a0'
                        : undefined,
                  }}
                >
                  {x.title}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {userArray.map(x => (
            <DayOffWorkUserRow
              key={`dayyoffwork-idUser-${x.idUser}`}
              baseDate={baseDate}
              userObject={x}
              userArray={userArray}
              calenderHelpArray={calenderHelpArray}
              employmentContractArray={employmentContractArray}
              dayOffWorkArray={dayOffWorkArray}
              setDayOffWorkArray={setDayOffWorkArray}
              dayOffWorkCategoryArray={dayOffWorkCategoryArray}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
};
