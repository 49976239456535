import { Document, Page, pdfjs } from 'react-pdf';
import { Box, CircularProgress } from '@mui/material';

// /*
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url
// ).toString();
// */
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface IProps {
  pdfFile: string;
  numPages: number;
  pageNumber: number;
  setNumPages: (numPages: number) => void;
  scale: number;
}

export const PdfDocument = ({
  pdfFile,
  setNumPages,
  pageNumber,
  scale,
}: IProps) => {
  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  return (
    <Box>
      <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          pageNumber={pageNumber}
          loading={<CircularProgress />}
          scale={scale}
        />
      </Document>
    </Box>
  );
};
