import React from 'react';
import { ITextModuleCategory } from '../../Interfaces/ITextModuleCategory';
import { Alert, Box, Collapse, Grid, TextField } from '@mui/material';

interface IProps {
  textModuleCategory: ITextModuleCategory;
  setTextModuleCategory: Function;
}

export const TextModuleCategoryEdit: React.FC<IProps> = props => {
  return (
    <>
      <Collapse in={!props.textModuleCategory.isDeleteable}>
        <Alert severity='warning'>
          Sie können diese Kategorie nicht verändern!
        </Alert>
      </Collapse>

      <Box sx={{ mt: 2 }} />

      <Grid container spacing={2}>
        <Grid item sm={12}>
          <TextField
            label='Titel'
            value={props.textModuleCategory.TextModuleCategory}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setTextModuleCategory({
                ...props.textModuleCategory,
                TextModuleCategory: event.target.value,
              })
            }
            error={props.textModuleCategory.TextModuleCategory === ''}
            size='small'
            fullWidth
            inputProps={{ maxLength: 100 }}
            disabled={!props.textModuleCategory.isDeleteable}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            label='Infotext'
            value={
              props.textModuleCategory.InfoText === null
                ? ''
                : props.textModuleCategory.InfoText
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setTextModuleCategory({
                ...props.textModuleCategory,
                InfoText: event.target.value === '' ? null : event.target.value,
              })
            }
            size='small'
            fullWidth
            rows={3}
            multiline
            inputProps={{ maxLength: 200 }}
            disabled={!props.textModuleCategory.isDeleteable}
          />
        </Grid>
      </Grid>
    </>
  );
};
