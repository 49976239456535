import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { LegalTransactionOverview } from '../LegalTransaction/LegalTransactionOverview';
import { LegalTransactionTypeOverview } from '../LegalTransactionType/LegalTransactionTypeOverview';
import { ClientsOverview } from '../Clients/ClientsOverview';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import {
  actionCreatorsAcademicTitle,
  actionCreatorsGender,
  actionCreatorsCountry,
  actionCreatorsMIMType,
  actionCreatorsLegalRequirementState,
  actionCreatorsBusinessForm,
  actionCreatorsCompanyFormationQuestion,
  actionCreatorsCompanyFormationOption,
  actionCreatorsTextModuleCategory,
  actionCreatorsTextModule,
  actionCreatorsContactGroup,
  actionCreatorsDistrictCourt,
  actionCreatorsLandMarking,
  actionCreatorsDictionaryEntry,
  actionCreatorsFederalState,
} from '../redux/actionCreators';
import { useFetch } from '../hooks/useFetch';
import { IGender } from '../Interfaces/IGender';
import { Alert } from '@mui/material';
import { IAcademicTitle } from '../Interfaces/IAcademicTitle';
import { ICountry } from '../Interfaces/ICountry';
import { IMIMEType } from '../Interfaces/IMIMEType';
import { CustomCircularProgress } from '../generic/CustomCircularProgress';
import { KanbanOverview } from '../Kanban/KanbanOverview';
import { UserOverview } from '../User/UserOverview';
import { SystemMailOverview } from '../SystemMail/SystemMailOverview';
import { PublicServer } from '../Admin/PublicServer/PublicServer';
import { ILegalRequirementState } from '../Interfaces/ILegalRequirementState';
import { IBusinessForm } from '../Interfaces/IBusinessForm';
import { ICompanyFormationQuestion } from '../Interfaces/ICompanyFormationQuestion';
import { ICompanyFormationOption } from '../Interfaces/ICompanyFormationOption';
import { GlobalMailOverview } from '../Admin/GlobalMail/GlobalMailOverview';
import { DashboardMain } from '../Dashboard/DashboardMain';
import { TimeRecordOverview } from '../TimeRecord/Management/TimeRecordOverview';
import { ITextModuleCategory } from '../Interfaces/ITextModuleCategory';
import { ITextModule } from '../Interfaces/ITextModule';
import { TextModulSectionMain } from '../TextModule/TextModulSectionMain';
import { LanguageSectionOverview } from '../Admin/LanguageSection/LanguageSectionOverview';
import { License } from '../Admin/License';
import { NavBarV2 } from './NavBarV2';
import { UserGroupOverview } from '../UserGroup/UserGroupOverview';
import { DayOffWorkOverview } from '../DayOffWork/DayOffWorkOverview';
import { IConfig } from '../Interfaces/IConfig';
import { SysRegOverview } from '../Admin/SysReg/SysRegOverview.';
import { IContactGroup } from '../Interfaces/IContactGroup';
import { ContactGroupOverview } from '../ContactGroups/ContactGroupOverview';
import { ILandMarking } from '../Interfaces/ILandMarking';
import { AemterMain } from '../Aemter/AemterMain';
import XML_Konverter from '../Tools/XML_Konverter';
import { ToolsOverview } from '../Tools/ToolsOverview';
import { ICourt, IFederalState } from '../Interfaces/IAemter';
import { IDictionaryEntry } from '../Interfaces/IDictionaryEntry';
import { FSDirectoryOverview } from '../Admin/FSDirectory/FSDirectoryOverview';
import { DocumentPortalOverview } from '../DocumentPortal/DocumentPortalOverview';

interface IProps {
  configObject: IConfig;
}

export const PageRouter: React.FC<IProps> = ({ configObject }) => {
  const dispatch = useDispatch();
  // Contact
  const { setGenderArray } = bindActionCreators(actionCreatorsGender, dispatch);
  const { setAcademicTitleArray } = bindActionCreators(
    actionCreatorsAcademicTitle,
    dispatch
  );
  const { setCountryArray } = bindActionCreators(
    actionCreatorsCountry,
    dispatch
  );
  const { setBusinessFormArray } = bindActionCreators(
    actionCreatorsBusinessForm,
    dispatch
  );
  const { setContactGroupArray } = bindActionCreators(
    actionCreatorsContactGroup,
    dispatch
  );
  // Dokumente
  const { setMIMETypeArray } = bindActionCreators(
    actionCreatorsMIMType,
    dispatch
  );
  // Legal Transaction
  const { setLegalRequirementState } = bindActionCreators(
    actionCreatorsLegalRequirementState,
    dispatch
  );
  // CompanyFormation
  const { setCompanyFormationQuestionArray } = bindActionCreators(
    actionCreatorsCompanyFormationQuestion,
    dispatch
  );
  const { setCompanyFormationOptionArray } = bindActionCreators(
    actionCreatorsCompanyFormationOption,
    dispatch
  );
  // Text Module
  const { setTextModuleCategoryArray } = bindActionCreators(
    actionCreatorsTextModuleCategory,
    dispatch
  );
  const { setTextModuleArray } = bindActionCreators(
    actionCreatorsTextModule,
    dispatch
  );
  // Variable
  const { setDictionaryEntry } = bindActionCreators(
    actionCreatorsDictionaryEntry,
    dispatch
  );
  // Amtsgerichte
  const { setDistrictCourtArray } = bindActionCreators(
    actionCreatorsDistrictCourt,
    dispatch
  );
  const { setLandMarkingArray } = bindActionCreators(
    actionCreatorsLandMarking,
    dispatch
  );

  const { setFederalStateArray } = bindActionCreators(
    actionCreatorsFederalState,
    dispatch
  );

  //
  const [scopeArray, setCcopeArray] = React.useState(
    localStorage.getItem('scope')?.split(',')
  );
  // Laden aus dem Backend
  // Contact
  const [
    localGenderArray,
    setLocalGenderArray,
    wasLocalSuccessfullyGenderArray,
  ] = useFetch<IGender[]>('/gender');
  const [
    localAcademicTitleArray,
    setLocalAcademicTitleArray,
    wasLocalSuccessfullyAcademicTitleArray,
  ] = useFetch<IAcademicTitle[]>('/academictitle');
  const [
    localCountryArray,
    setlocalCountryArray,
    wasLocalSuccessfullyCountryArray,
  ] = useFetch<ICountry[]>('/country');
  const [
    localBusinessFormArray,
    setlocalBusinessFormArray,
    wasLocalSuccessfullyBusinessFormArray,
  ] = useFetch<IBusinessForm[]>('/businessform');
  const [
    localContactGroupArray,
    setlocalContactGroupArray,
    wasLocalSuccessfullyContactGroupArray,
  ] = useFetch<IContactGroup[]>('/contactgroup');
  // Dokumente
  const [
    localMIMETypeArray,
    setlocalMIMETypeArray,
    wasLocalSuccessfullyMIMETypeArray,
  ] = useFetch<IMIMEType[]>('/mimetype');
  // Legal Transactions
  const [
    localLegalRequirementStateArray,
    setlocalLegalRequirementStateArray,
    wasLocalSuccessfullyLegalRequirementStateArray,
  ] = useFetch<ILegalRequirementState[]>('/legalrequirementstate');
  // CompanyFormation
  const [
    localCompanyFormationQuestionArray,
    setlocalCompanyFormationQuestionArray,
    wasLocalSuccessfullyCompanyFormationQuestionArray,
  ] = useFetch<ICompanyFormationQuestion[]>('/companyformationquestion');
  const [
    localCompanyFormationOptionArray,
    setlocalCompanyFormationOptionArray,
    wasLocalSuccessfullyCompanyFormationOptionArray,
  ] = useFetch<ICompanyFormationOption[]>('/companyformationoption');
  // Text Module
  const [
    localTextModuleCategoryArray,
    setLocalTextModuleCategoryArray,
    wasLocalSuccessfullyTextModuleCategoryArray,
  ] = useFetch<ITextModuleCategory[]>('/textmodulecategory');
  const [
    localTextModuleArray,
    setLocalTextModuleArray,
    wasLocalSuccessfullyTextModuleArray,
  ] = useFetch<ITextModule[]>('/textmodule');
  // Variable
  const [
    localDictionaryEntryArray,
    setLocalDictionaryEntryArray,
    wasLocalSuccessfullyLocalDictionaryEntryArray,
  ] = useFetch<IDictionaryEntry[]>('/dictionaryentry');
  // Amtsgerichte
  const [
    localDistrictCourtArray,
    setLocalDistrictCourtArray,
    wasSuccessullyLocalDistrictCourtArray,
  ] = useFetch<ICourt[]>('/districtcourt');
  const [
    localLandMarkingArray,
    setLocalLandMarkingArray,
    wasSuccessfullyLocalLandMarkingArray,
  ] = useFetch<ILandMarking[]>('/landmarking');
  const [
    localFederalStateArray,
    setlocalFederalStateArray,
    wasSuccessfullylocalFederalStateArray,
  ] = useFetch<IFederalState[]>('/federalstate');

  // Speichern in Redux
  // Contact
  useEffect(() => {
    if (localGenderArray) {
      setGenderArray(localGenderArray);
    }
  }, [localGenderArray]);
  useEffect(() => {
    if (localAcademicTitleArray) {
      setAcademicTitleArray(localAcademicTitleArray);
    }
  }, [localAcademicTitleArray]);
  useEffect(() => {
    if (localCountryArray) {
      setCountryArray(localCountryArray);
    }
  }, [localCountryArray]);
  useEffect(() => {
    if (localBusinessFormArray) {
      setBusinessFormArray(localBusinessFormArray);
    }
  }, [localBusinessFormArray]);
  useEffect(() => {
    if (localContactGroupArray) {
      setContactGroupArray(localContactGroupArray);
    }
  }, [localContactGroupArray]);
  // Dokumente
  useEffect(() => {
    if (localMIMETypeArray) {
      setMIMETypeArray(localMIMETypeArray);
    }
  }, [localMIMETypeArray]);
  // Legal Transaction
  useEffect(() => {
    if (localLegalRequirementStateArray) {
      setLegalRequirementState(localLegalRequirementStateArray);
    }
  }, [localLegalRequirementStateArray]);
  // CompanyFormation
  useEffect(() => {
    if (localCompanyFormationQuestionArray) {
      setCompanyFormationQuestionArray(localCompanyFormationQuestionArray);
    }
  }, [localCompanyFormationQuestionArray]);
  useEffect(() => {
    if (localCompanyFormationOptionArray) {
      setCompanyFormationOptionArray(localCompanyFormationOptionArray);
    }
  }, [localCompanyFormationOptionArray]);
  // Text Module
  useEffect(() => {
    if (localTextModuleCategoryArray) {
      setTextModuleCategoryArray(localTextModuleCategoryArray);
    }
  }, [localTextModuleCategoryArray]);
  useEffect(() => {
    if (localTextModuleArray) {
      setTextModuleArray(localTextModuleArray);
    }
  }, [localTextModuleArray]);
  // Variable
  useEffect(() => {
    if (localDictionaryEntryArray) {
      setDictionaryEntry(localDictionaryEntryArray);
    }
  }, [localDictionaryEntryArray]);
  // Amtsgerichte
  useEffect(() => {
    if (localDistrictCourtArray) {
      setDistrictCourtArray(localDistrictCourtArray);
    }
  }, [localDistrictCourtArray]);
  useEffect(() => {
    if (localLandMarkingArray) {
      setLandMarkingArray(localLandMarkingArray);
    }
  }, [localLandMarkingArray]);
  useEffect(() => {
    if (localFederalStateArray) {
      setFederalStateArray(localFederalStateArray);
    }
  }, [localFederalStateArray]);

  const checlPermission = (permission: string, componente: React.ReactNode) => {
    if (scopeArray !== undefined && scopeArray.indexOf(permission) > -1) {
      return componente;
    } else {
      return <Alert severity='error'>Fehlende Berechtigung!</Alert>;
    }
  };

  if (
    localStorage === undefined ||
    !wasLocalSuccessfullyGenderArray ||
    !wasLocalSuccessfullyAcademicTitleArray ||
    !wasLocalSuccessfullyCountryArray ||
    !wasLocalSuccessfullyContactGroupArray ||
    // Documente
    !wasLocalSuccessfullyMIMETypeArray ||
    // CompanyFormation
    !wasLocalSuccessfullyCompanyFormationQuestionArray ||
    !wasLocalSuccessfullyCompanyFormationOptionArray ||
    //
    !wasLocalSuccessfullyLocalDictionaryEntryArray ||
    // Text Module
    !wasLocalSuccessfullyTextModuleCategoryArray ||
    !wasLocalSuccessfullyTextModuleArray
  ) {
    return (
      <Alert severity='error'>Es ist ein fataler Fehler aufgertetten!</Alert>
    );
  } else if (
    localGenderArray === undefined ||
    localAcademicTitleArray === undefined ||
    localCountryArray === undefined ||
    localContactGroupArray === undefined ||
    // Dokuemten
    localMIMETypeArray === undefined ||
    // CompanyFormation
    localCompanyFormationQuestionArray === undefined ||
    localCompanyFormationOptionArray === undefined ||
    // Text Module
    localTextModuleCategoryArray === undefined ||
    localTextModuleArray === undefined ||
    // Variable
    localDictionaryEntryArray === undefined ||
    // Amtsgericht
    localDistrictCourtArray === undefined ||
    localLandMarkingArray === undefined ||
    localFederalStateArray === undefined
  ) {
    return <CustomCircularProgress />;
  } else {
    return (
      <>
        <Router>
          <NavBarV2 configObject={configObject}>
            <Routes>
              <Route path='/' element={<DashboardMain />} />

              <Route path='/kanban' element={<KanbanOverview />} />

              <Route
                path='/legaltransaction'
                element={checlPermission(
                  'legaltransaction',
                  <LegalTransactionOverview />
                )}
              />
              <Route
                path='/legaltransaction/:idLegalTransaction'
                element={checlPermission(
                  'legaltransaction',
                  <LegalTransactionOverview />
                )}
              />

              <Route
                path='/legaltransactiontypes'
                element={checlPermission(
                  'legaltransaction',
                  <LegalTransactionTypeOverview />
                )}
              />
              <Route
                path='/textmodules'
                element={checlPermission(
                  'legaltransaction',
                  <TextModulSectionMain />
                )}
              />
              <Route
                path='/contactgroups'
                element={checlPermission(
                  'legaltransaction',
                  <ContactGroupOverview />
                )}
              />
              <Route
                path='/aemter'
                element={checlPermission('legaltransaction', <AemterMain />)}
              />

              <Route
                path='/clients'
                element={checlPermission(
                  'legaltransaction',
                  <ClientsOverview />
                )}
              />
              <Route
                path='/clients/:idContact'
                element={checlPermission(
                  'legaltransaction',
                  <ClientsOverview />
                )}
              />

              <Route
                path='/clients/:idContact'
                element={checlPermission(
                  'legaltransaction',
                  <ClientsOverview />
                )}
              />

              <Route
                path='/document_portal'
                element={checlPermission(
                  'legaltransaction',
                  <DocumentPortalOverview />
                )}
              />

              <Route
                path='/users'
                element={checlPermission('user', <UserOverview />)}
              />
              <Route
                path='/usergroups'
                element={checlPermission('user', <UserGroupOverview />)}
              />
              <Route
                path='/daysoffwork'
                element={checlPermission('user', <DayOffWorkOverview />)}
              />

              <Route
                path='/timerecording'
                element={checlPermission(
                  'timerecording',
                  <TimeRecordOverview />
                )}
              />
              <Route
                path='/systemmails'
                element={checlPermission('systemmails', <SystemMailOverview />)}
              />
              <Route
                path='/xmlKonverter'
                element={checlPermission('legaltransaction', <XML_Konverter />)}
              />

              <Route
                path='/tools'
                element={checlPermission('legaltransaction', <ToolsOverview />)}
              />

              <Route
                path='/admin/fsdirectory'
                element={checlPermission('admin', <FSDirectoryOverview />)}
              />
              <Route
                path='/admin/language'
                element={checlPermission('admin', <LanguageSectionOverview />)}
              />
              <Route
                path='/admin/public'
                element={checlPermission('admin', <PublicServer />)}
              />
              <Route
                path='/admin/globalmail'
                element={checlPermission('admin', <GlobalMailOverview />)}
              />
              <Route
                path='/admin/sysreg'
                element={checlPermission('admin', <SysRegOverview />)}
              />

              <Route
                path='/systeminfo'
                element={checlPermission('all', <License />)}
              />
            </Routes>
          </NavBarV2>
        </Router>
      </>
    );
  }
};
