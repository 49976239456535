import React, { useEffect, useRef, useState } from 'react';
import { Button, DialogActions } from '@mui/material';
import { InheritPerson } from '../Components/InheritPerson';
import { IRequiredFiles } from '../Components/InheritDocument';
import { IInheritHasPerson } from '../../../../Interfaces/IPerson';
import { IPersonHasDocument } from '../../../../Interfaces/IPersonHasDocument';
import {
  FamilyTreePersonNodeType,
  FamilyTreeService,
} from '../../../../services/familyTree.service';
import { deepEqual } from '../../../../services/dialog.service';
import { IInherit } from '../../../../Interfaces/IInherit';
import { getInheritPerson } from '../../../../services/dialog.services.ts/inheritDialog.service';

interface IProps {
  inheritObject: IInherit;
  setInheritObject: Function;
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
}

export const Inherit: React.FC<IProps> = ({
  personArray,
  setPersonArray,
  inheritObject,
  setInheritObject,
  personHasDocument,
  setPersonHasDocument,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [currentInherit, setCurrentInherit] = useState(
    getInheritPerson(
      Number(
        personArray.find(x => x.idPerson === personArray[0].idPerson)?.idPerson
      ),
      personArray
    )
  );
  const [isDisabled, setIsDisabled] = useState(true);

  /**
   * Form Function
   */
  const handleSave = () => {
    setPersonArray([
      ...personArray.map(x =>
        x.idPerson === currentInherit.idPerson ? currentInherit : x
      ),
    ]);
    setIsDisabled(true);
  };

  const handleStop = () => {
    const updatedPerson = getInheritPerson(
      Number(
        personArray.find(x => x.idPerson === personArray[0].idPerson)?.idPerson
      ),
      personArray
    );
    setCurrentInherit(updatedPerson);
  };

  const handleOnSave = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      handleSave();
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements);
      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  useEffect(() => {
    const Inherit = getInheritPerson(
      Number(
        personArray.find(x => x.idPerson === personArray[0].idPerson)?.idPerson
      ),
      personArray
    );

    if (deepEqual(currentInherit, Inherit)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [currentInherit]);

  useEffect(() => {
    const updatedPerson = getInheritPerson(
      Number(
        personArray.find(x => x.idPerson === personArray[0].idPerson)?.idPerson
      ),
      personArray
    );
    setCurrentInherit(updatedPerson);
  }, [personArray]);

  return (
    <>
      <form ref={formRef}>
        <InheritPerson
          currentPerson={currentInherit}
          setCurrentPerson={setCurrentInherit}
          inheritObject={inheritObject}
          setInheritObject={setInheritObject}
          personArray={personArray}
          title={FamilyTreeService.getGenderNaming(
            FamilyTreePersonNodeType.TESTATOR_NODE,
            currentInherit.idGender
          )}
          requiredFiles={
            [
              {
                Title: 'Sterbeurkunde',
                isOptional: false,
                idPersonDocumentType: 1,
              },
            ] as IRequiredFiles[]
          }
          personHasDocument={personHasDocument}
          setPersonHasDocument={setPersonHasDocument}
          titleAdress='Letzter Wohnsitz'
          forceDeathSettings={true}
          showDeathSettings={false}
          forceAdress
          disableDeathDateWarning
        />
        <DialogActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            disabled={isDisabled}
            variant='contained'
            onClick={handleStop}
          >
            Abbrechen
          </Button>
          <Button
            disabled={isDisabled}
            variant='contained'
            onClick={handleOnSave}
          >
            Speichern
          </Button>
        </DialogActions>
      </form>
    </>
  );
};
