import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';
import {
  ILegalRequirementTemplate,
  ILegalRequirementTemplateFullObject,
} from '../Interfaces/ILegalRequirementTemplate';
import { ILegalPhaseTemplateFullObject } from '../Interfaces/ILegalPhaseTemplate';
import { ILegalRequirementTemplateDeadline } from '../Interfaces/ILegalRequirementTemplate_deadlines';
import { PopupAlert } from '../core/PopupAlert';

interface AddPrerequisiteDeadlineProps {
  currentLegalRequirementTemplate: ILegalRequirementTemplateFullObject;
  setDeadline: Function;
  legalPhaseTemplateArray?: ILegalPhaseTemplateFullObject[];
  legalRequirementTemplateArray?: ILegalRequirementTemplate[];
  open: boolean;
  onClose: () => void;
}

const newDeadline = (): ILegalRequirementTemplateDeadline => ({
  idDeadline: -1,
  idLegalRequirementTemplate: undefined,
  idLegalPhaseTemplate: undefined,
  idLegalRequirementState: undefined,
  idLegalTransactionState: undefined,
  LegalPhaseTemplate: '',
  LegalRequirementTemplate: '',
  LegalRequirementState: '',
  LegalTransactionState: '',
  Weighting: 0,
});

export const AddPrerequisiteDeadline: React.FC<
  AddPrerequisiteDeadlineProps
> = ({
  currentLegalRequirementTemplate,
  legalPhaseTemplateArray,
  setDeadline,
  open,
  onClose,
}) => {
  const [selectionType, setSelectionType] = useState<
    'phase' | 'requirement' | 'state' | 'startdate' | 'undefined'
  >('undefined');

  const [legalTransactionLoaded, setLegalTransactionLoaded] =
    useState<boolean>(false);

  const [showError, setShowError] = useState<boolean>(false);

  const [legalPhasePosition, setLegalPhasePosition] = useState<number>(0);
  const [deadlineEdit, setDeadlineEdit] =
    useState<ILegalRequirementTemplateDeadline>(newDeadline());

  const handleClose = useCallback(() => {
    setSelectionType('undefined');
    setDeadlineEdit(newDeadline());
    onClose();
  }, [onClose]);

  const getLegalRequirementName = useCallback(
    (idLegalRequirementTemplate: number) => {
      return legalPhaseTemplateArray
        ? legalPhaseTemplateArray
            .map(
              legalPhaseTemplate =>
                legalPhaseTemplate.LegalRequirementArray &&
                legalPhaseTemplate.LegalRequirementArray.find(
                  legalRequirementTemplate =>
                    legalRequirementTemplate.idLegalRequirementTemplate ===
                    idLegalRequirementTemplate
                )
            )
            .filter(x => x)[0]?.LegalRequirementTemplate || ''
        : '';
    },
    [legalPhaseTemplateArray]
  );

  const getLegalPhaseName = useCallback(
    (idLegalPhaseTemplate: number) => {
      return legalPhaseTemplateArray?.find(
        legalPhaseTemplate =>
          legalPhaseTemplate.idLegalPhaseTemplate === idLegalPhaseTemplate
      )?.LegalPhaseTemplate;
    },
    [legalPhaseTemplateArray]
  );

  const getLegalStateName = useCallback((idLegalRequirementState: number) => {
    switch (idLegalRequirementState) {
      case 10:
        return 'Offen';
      case 15:
        return 'Prüfung Mitarbeiter';
      case 20:
        return 'Prüfung Notar';
      case 40:
        return 'Erledigt';
      default:
        return '';
    }
  }, []);

  const getLegalTransactionStateName = useCallback(
    (idLegalTransactionState: number) => {
      switch (idLegalTransactionState) {
        case 1:
          return 'Entwurf';
        case 5:
          return 'Laufend';
        case 10:
          return 'Inaktiv';
        case 50:
          return 'Storniert';
        default:
          return '';
      }
    },
    []
  );

  const onSave = useCallback(() => {
    setDeadline({
      idDeadline: -1,
      idLegalPhaseTemplate:
        selectionType === 'phase'
          ? deadlineEdit.idLegalPhaseTemplate
          : undefined,
      idLegalRequirementTemplate:
        selectionType === 'requirement'
          ? deadlineEdit.idLegalRequirementTemplate
          : undefined,
      idLegalRequirementState:
        selectionType === 'requirement'
          ? deadlineEdit.idLegalRequirementState
          : undefined,
      idLegalTransactionState:
        selectionType === 'state'
          ? deadlineEdit.idLegalTransactionState
          : undefined,
      LegalPhaseTemplate:
        selectionType === 'phase'
          ? getLegalPhaseName(deadlineEdit.idLegalPhaseTemplate || -1)
          : '',
      LegalRequirementTemplate:
        selectionType === 'requirement'
          ? getLegalRequirementName(
              deadlineEdit.idLegalRequirementTemplate || -1
            )
          : '',
      LegalRequirementState:
        selectionType === 'requirement'
          ? getLegalStateName(deadlineEdit.idLegalRequirementState || -1)
          : '',
      LegalTransactionState:
        selectionType === 'state'
          ? getLegalTransactionStateName(
              deadlineEdit.idLegalTransactionState || -1
            )
          : '',
      Weighting: deadlineEdit.Weighting,
    } as ILegalRequirementTemplateDeadline);
    handleClose();
  }, [
    deadlineEdit,
    setDeadline,
    selectionType,
    getLegalPhaseName,
    getLegalRequirementName,
    getLegalStateName,
    getLegalTransactionStateName,
    handleClose,
  ]);

  const currentLegalRequirementDontHasLegalRequirementPhaseAsPrerequisite = (
    legalRequirement: ILegalRequirementTemplateFullObject
  ) => {
    return !currentLegalRequirementTemplate.DeadlineArray?.find(
      x => x.idLegalPhaseTemplate === legalRequirement.idLegalPhaseTemplate
    );
  };

  const legalRequirementDontHasCurrentAsPrerequisite = (
    legalRequirement: ILegalRequirementTemplateFullObject
  ) => {
    return !legalRequirement.DeadlineArray?.map(
      x => x.idLegalRequirementTemplate
    ).includes(currentLegalRequirementTemplate.idLegalRequirementTemplate);
  };

  const legalRequirementAfterCurrent = (
    legalRequirement: ILegalRequirementTemplateFullObject
  ) => {
    if (!legalPhaseTemplateArray) {
      return false;
    }
    return (
      legalPhaseTemplateArray
        ?.map(x => {
          if (
            x.idLegalPhaseTemplate === legalRequirement.idLegalPhaseTemplate &&
            x.Position > legalPhasePosition
          ) {
            return x;
          } else {
            return null;
          }
        })
        .filter(x => x).length > 0 ||
      (legalRequirement.idLegalPhaseTemplate ===
        currentLegalRequirementTemplate.idLegalPhaseTemplate &&
        legalRequirement.Position > currentLegalRequirementTemplate.Position)
    );
  };

  const showRequirement = (
    legalRequirement: ILegalRequirementTemplateFullObject
  ) => {
    return (
      currentLegalRequirementTemplate.DeadlineArray?.find(
        x =>
          x.idLegalRequirementTemplate ===
          legalRequirement.idLegalRequirementTemplate
      ) === undefined &&
      !legalRequirementAfterCurrent(legalRequirement) &&
      legalRequirementDontHasCurrentAsPrerequisite(legalRequirement) &&
      currentLegalRequirementDontHasLegalRequirementPhaseAsPrerequisite(
        legalRequirement
      ) &&
      legalRequirement.idLegalRequirementTemplate !==
        currentLegalRequirementTemplate.idLegalRequirementTemplate
    );
  };

  const showRequirementSelect = () => {
    if (legalPhaseTemplateArray) {
      return legalPhaseTemplateArray.find(legalPhaseTemplate =>
        legalPhaseTemplate.LegalRequirementArray?.find(legalRequirement =>
          showRequirement(legalRequirement)
        )
      )
        ? true
        : false;
    }
    return false;
  };

  const currentLegalRequirementDontHasLegaLRequirementFromPhaseAsPrerequisite =
    (legalPhase: ILegalPhaseTemplateFullObject) => {
      return !currentLegalRequirementTemplate.DeadlineArray?.find(x =>
        legalPhase.LegalRequirementArray?.find(
          y => y.idLegalRequirementTemplate === x.idLegalRequirementTemplate
        )
      );
    };

  const phaseDontHasCurrentAsPrerequisite = (
    legalPhase: ILegalPhaseTemplateFullObject
  ) => {
    return !legalPhase.LegalRequirementArray?.map(
      x => x.idLegalRequirementTemplate
    ).includes(currentLegalRequirementTemplate.idLegalRequirementTemplate);
  };

  const phaseAfterCurrent = (legalPhase: ILegalPhaseTemplateFullObject) => {
    return legalPhase.Position > legalPhasePosition;
  };

  const showPhase = (legalPhase: ILegalPhaseTemplateFullObject) => {
    return (
      !currentLegalRequirementTemplate.DeadlineArray?.map(
        x => x.idLegalPhaseTemplate
      ).includes(legalPhase.idLegalPhaseTemplate) &&
      !phaseAfterCurrent(legalPhase) &&
      phaseDontHasCurrentAsPrerequisite(legalPhase) &&
      currentLegalRequirementDontHasLegaLRequirementFromPhaseAsPrerequisite(
        legalPhase
      ) &&
      legalPhase.idLegalPhaseTemplate !==
        currentLegalRequirementTemplate.idLegalPhaseTemplate
    );
  };

  const showPhaseSelect = () => {
    if (legalPhaseTemplateArray) {
      return legalPhaseTemplateArray.find(legalPhaseTemplate =>
        showPhase(legalPhaseTemplate)
      )
        ? true
        : false;
    }
    return false;
  };

  const showStateSelect = () => {
    return currentLegalRequirementTemplate.DeadlineArray?.find(
      x => x.idLegalTransactionState !== undefined
    ) === undefined
      ? true
      : false;
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='md'>
      <PopupAlert
        show={showError}
        setShow={setShowError}
        severity='error'
        text='Beim Laden der Vorgänge ist ein Fehler aufgetreten'
      />
      <DialogTitle>Startbedingung für die Frist hinzufügen</DialogTitle>
      <DialogContent>
        <Divider>
          <Typography variant='body1'>Bedinungsoptionen:</Typography>
        </Divider>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                value={selectionType || ''}
                onChange={e =>
                  setSelectionType(
                    e.target.value as
                      | 'phase'
                      | 'requirement'
                      | 'state'
                      | 'startdate'
                      | 'undefined'
                  )
                }
                fullWidth
                select
                label='Bedinungstyp'
              >
                {showRequirementSelect() && (
                  <MenuItem value='requirement'>Maßnahme</MenuItem>
                )}
                {showPhaseSelect() && <MenuItem value='phase'>Phase</MenuItem>}
                {showStateSelect() && <MenuItem value='state'>Status</MenuItem>}
              </TextField>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Divider>
              <Typography variant='body1'>Bedingung:</Typography>
            </Divider>
          </Grid>

          {selectionType === 'requirement' && (
            <>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id='idLegalRequirementLabel'>Maßnahme</InputLabel>
                  <Select
                    labelId='idLegalRequirementLabel'
                    id='idLegalRequirement'
                    value={deadlineEdit.idLegalRequirementTemplate || ''}
                    onChange={e =>
                      setDeadlineEdit({
                        ...deadlineEdit,
                        idLegalRequirementTemplate: e.target.value as
                          | number
                          | undefined,
                      })
                    }
                    label='Maßnahme'
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    {legalPhaseTemplateArray &&
                      legalPhaseTemplateArray.map(legalPhaseTemplate =>
                        legalPhaseTemplate.LegalRequirementArray?.map(
                          legalRequirementTemplate =>
                            showRequirement(legalRequirementTemplate) && (
                              <MenuItem
                                key={
                                  legalRequirementTemplate.idLegalRequirementTemplate
                                }
                                value={
                                  legalRequirementTemplate.idLegalRequirementTemplate
                                }
                              >
                                {
                                  legalRequirementTemplate.LegalRequirementTemplate
                                }
                              </MenuItem>
                            )
                        )
                      )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id='idLegalRequirementStateLabel'>
                    Bei Status
                  </InputLabel>
                  <Select
                    id='idLegalRequirementState'
                    labelId='idLegalRequirementStateLabel'
                    value={deadlineEdit.idLegalRequirementState || ''}
                    onChange={e =>
                      setDeadlineEdit({
                        ...deadlineEdit,
                        idLegalRequirementState: e.target.value as
                          | number
                          | undefined,
                      })
                    }
                    label='Maßnahmenstatus'
                  >
                    <MenuItem value={15}>Prüfung Mitarbeiter</MenuItem>
                    <MenuItem value={20}>Prüfung Notar</MenuItem>
                    <MenuItem value={40}>Erledigt</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
          {legalPhaseTemplateArray && selectionType === 'phase' && (
            <>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id='idLegalPhasePrerequisite'>
                    Legal Phase Template Prerequisite
                  </InputLabel>
                  <Select
                    labelId='idLegalPhasePrerequisite'
                    id='idLegalPhasePrerequisite'
                    value={deadlineEdit.idLegalPhaseTemplate || ''}
                    onChange={e =>
                      setDeadlineEdit({
                        ...deadlineEdit,
                        idLegalPhaseTemplate: e.target.value as
                          | number
                          | undefined,
                      })
                    }
                    label='Phase'
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    {legalPhaseTemplateArray?.map(
                      legalPhase =>
                        showPhase(legalPhase) && (
                          <MenuItem
                            key={legalPhase.idLegalPhaseTemplate}
                            value={legalPhase.idLegalPhaseTemplate}
                          >
                            {legalPhase.LegalPhaseTemplate}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
          {selectionType === 'state' && (
            <>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id='state'>Vorgangsstatus</InputLabel>
                  <Select
                    labelId='state'
                    id='state'
                    value={deadlineEdit.idLegalTransactionState || ''}
                    onChange={e =>
                      setDeadlineEdit({
                        ...deadlineEdit,
                        idLegalTransactionState: e.target.value as
                          | number
                          | undefined,
                      })
                    }
                    label='Status'
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={1}>Entwurf</MenuItem>
                    <MenuItem value={5}>Laufend</MenuItem>
                    <MenuItem value={10}>Inaktiv</MenuItem>
                    <MenuItem value={50}>Storniert</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
          {selectionType && (
            <Grid item xs={12}>
              <TextField
                label='Gewichtung'
                type='number'
                value={deadlineEdit.Weighting || ''}
                onChange={e => {
                  setDeadlineEdit({
                    ...deadlineEdit,
                    Weighting: Number(e.target.value),
                  });
                }}
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              variant='outlined'
              onClick={handleClose}
              style={{ marginRight: '10px' }}
            >
              Schließen
            </Button>
            <Button
              variant='contained'
              color='success'
              onClick={onSave}
              disabled={
                (selectionType === 'requirement' &&
                  !deadlineEdit.idLegalRequirementTemplate &&
                  !deadlineEdit.idLegalRequirementState) ||
                (selectionType === 'phase' &&
                  !deadlineEdit.idLegalPhaseTemplate) ||
                (selectionType === 'state' &&
                  !deadlineEdit.idLegalTransactionState) ||
                !deadlineEdit.Weighting
              }
            >
              Speichern
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
