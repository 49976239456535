import React, { useEffect, useState } from 'react';
import { IDocument } from '../../Interfaces/IDocument';
import { DocumentOverview } from '../../Document/DocumentOverview';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { IDocumentDirectoryTemplateHasDocument } from '../../Interfaces/IDocumentDirectoryTemplateHasDocument';

interface IProps {
  documentArray: IDocumentDirectoryTemplateHasDocument[];
  setDocumentArray: Function;
  isOpen: boolean;
  setIsOpen: Function;
  idDocumentDirecotry: number;
}

export const DocumentDirectoryTemplateDocumentNew: React.FC<IProps> = props => {
  const [documentArray, setDocumentArray] = useState<IDocument[]>([]);

  useEffect(() => {
    if (props.isOpen) {
      setDocumentArray([]);
    }
  }, [props.isOpen]);

  const handleAddDocument = () => {
    let minId = -1;

    if (props.documentArray.length > 0) {
      const minIdArray =
        Math.min(
          ...props.documentArray.map(x => x.idDocumentDirectoryTemplate)
        ) - 1;

      if (minIdArray <= minId) {
        minId = minIdArray - 1;
      }
    }

    props.setDocumentArray([
      ...props.documentArray,
      ...documentArray.map((x, i) => {
        return {
          ...x,
          idDocument: minId - i,
          idDocumentDirectoryTemplate: props.idDocumentDirecotry,
          idLegalTransactionType: -1,
        } as IDocumentDirectoryTemplateHasDocument;
      }),
    ]);

    props.setIsOpen(false);
  };

  return (
    <>
      <Dialog open={props.isOpen} onClose={() => props.setIsOpen(false)}>
        <DialogContent>
          <DocumentOverview
            title='Datei(en) hinzufügen'
            allowRemove
            allowUpload
            documentArray={documentArray}
            setDocumentArray={setDocumentArray}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => props.setIsOpen(false)}>
            Abbruch
          </Button>
          <Button variant='contained' onClick={handleAddDocument}>
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
