import React, { useState } from 'react';
import { useFetch } from '../hooks/useFetch';
import { ITimeRecording } from '../Interfaces/ITimeRecording';
import {
  Alert,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material';
import { TimeRecordOwn } from './TimeRecordOwn';

export const TimeRecordMenuItem: React.FC = () => {
  const [timeRecordArray, setTimeRecordArray, wasSuccessfully] =
    useFetch<ITimeRecording[]>('/timerecording');
  //
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  if (!wasSuccessfully) {
    return <Alert severity='error'>Fehler!</Alert>;
  } else if (timeRecordArray === undefined) {
    return (
      <>
        <MenuItem>
          <CircularProgress />
        </MenuItem>
      </>
    );
  } else {
    return (
      <>
        <Dialog
          open={isOpenDialog}
          onClose={() => setIsOpenDialog(false)}
          maxWidth='lg'
          fullWidth
        >
          <DialogTitle>Zeiterfassung</DialogTitle>
          <DialogContent>
            <TimeRecordOwn
              isBookCurrentDay
              timeRecordArray={timeRecordArray}
              setTimeRecordArray={setTimeRecordArray}
              setIsOpenDialog={setIsOpenDialog}
            />
          </DialogContent>
        </Dialog>

        <MenuItem onClick={() => setIsOpenDialog(true)}>Zeiterfassung</MenuItem>
      </>
    );
  }
};
