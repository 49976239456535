import { useState } from 'react';
import { ChildrenAddDialog } from './ChildrenAddDialog';
import { IInherit } from '../../../../../Interfaces/IInherit';
import { IInheritHasPerson } from '../../../../../Interfaces/IPerson';
import { IPersonHasDocument } from '../../../../../Interfaces/IPersonHasDocument';
import { IChildren } from '../../../../../Interfaces/IChildren';
import { IMarriage } from '../../../../../Interfaces/IMarriage';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ChildrenRow } from './ChildrenRow';
import { Add } from '@mui/icons-material';
import {
  getNextGeneration,
  hasDeceasedChildren,
} from '../../../../../services/dialog.services.ts/inheritDialog.service';

interface IProps {
  degreeOfKinship: number;
  childrens: IChildren[];
  parents: IChildren[];
  inheritObject: IInherit;
  idPersonInherit: number;
  firstParentArray: IInheritHasPerson[];
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  childrenArray: IChildren[];
  setChildrenArray: Function;
  marraigeArray: IMarriage[];
  getTitle: (degreeOfKinship: number) => string;
  getAddButtonTitle: (degreeOfKinship: number) => string;
}

export const ChildrenTable: React.FC<IProps> = ({
  degreeOfKinship,
  childrens,
  parents,
  inheritObject,
  idPersonInherit,
  firstParentArray,
  personArray,
  setPersonArray,
  personHasDocument,
  setPersonHasDocument,
  childrenArray,
  setChildrenArray,
  marraigeArray,
  getTitle,
  getAddButtonTitle,
}) => {
  const [openAddChildrenDialog, setOpenAddChildrenDialog] = useState(false);

  return (
    <>
      {openAddChildrenDialog && (
        <ChildrenAddDialog
          parents={parents}
          degreeOfKinship={degreeOfKinship}
          inheritObject={inheritObject}
          parentPersonArray={firstParentArray}
          setIsOpenNew={setOpenAddChildrenDialog}
          personArray={personArray}
          setPersonArray={setPersonArray}
          personHasDocument={personHasDocument}
          setPersonHasDocument={setPersonHasDocument}
          childrenArray={childrenArray}
          setChildrenArray={setChildrenArray}
          marriageArray={marraigeArray}
        />
      )}

      <Typography mt={6} variant='h6'>
        {getTitle(degreeOfKinship)}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '20%' }}>Nr.</TableCell>
            <TableCell sx={{ width: '20%' }}>Name</TableCell>
            <TableCell sx={{ width: '20%' }}>Elternteil 1</TableCell>
            <TableCell sx={{ width: '20%' }}>Elternteil 2</TableCell>
            <TableCell sx={{ width: '20%' }}>Aktionen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {childrens.map((x, idx) => (
            <ChildrenRow
              inheritObject={inheritObject}
              idPersonInherit={idPersonInherit}
              key={`idChildren-${x.idChildren}`}
              index={idx}
              currentChildren={x}
              personArray={personArray}
              setPersonArray={setPersonArray}
              personHasDocument={personHasDocument}
              setPersonHasDocument={setPersonHasDocument}
              childrenArray={childrenArray}
              setChildrenArray={setChildrenArray}
            />
          ))}
        </TableBody>
        <Box mt={2}>
          <Button
            variant='contained'
            onClick={() => setOpenAddChildrenDialog(true)}
            sx={{
              float: 'left',
              '& .MuiButton-startIcon': {
                marginRight: '2px', // Adjust the margin-right here
              },
            }}
            startIcon={
              <Add
                sx={{
                  fontSize: 22,
                  position: 'relative',
                  bottom: 1,
                  marginRight: '0px',
                }}
              />
            }
          >
            {getAddButtonTitle(degreeOfKinship)}
          </Button>
        </Box>
      </Table>

      {hasDeceasedChildren(personArray, childrens) && degreeOfKinship < 4 && (
        <ChildrenTable
          childrens={getNextGeneration(childrens, childrenArray)}
          parents={childrens}
          inheritObject={inheritObject}
          idPersonInherit={idPersonInherit}
          firstParentArray={firstParentArray}
          personArray={personArray}
          setPersonArray={setPersonArray}
          personHasDocument={personHasDocument}
          setPersonHasDocument={setPersonHasDocument}
          childrenArray={childrenArray}
          setChildrenArray={setChildrenArray}
          marraigeArray={marraigeArray}
          degreeOfKinship={degreeOfKinship + 1}
          getTitle={getTitle}
          getAddButtonTitle={getAddButtonTitle}
        />
      )}
    </>
  );
};
