import React, { useEffect, useState } from 'react';
import { ICompanyFormation } from '../../../../Interfaces/ICompanyFormation';
import { ICompanyFormationAnswer } from '../../../../Interfaces/ICompanyFormationAnswer';
import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { State } from '../../../../redux/mainReducer';
import { ICompanyFormationQuestion } from '../../../../Interfaces/ICompanyFormationQuestion';
import { CompanyFormationAnswerEdit } from './CompanyFormationAnswerEdit';

interface IProps {
  companyFormation: ICompanyFormation;
  companyFormationAnswerArray: ICompanyFormationAnswer[];
  setCompanyFormationAnswerArray: Function;
}

export const CompanyFormationAnswerOverview: React.FC<IProps> = props => {
  const companyFormationQuestionArray = useSelector(
    (state: State) =>
      state.companyFormationQuestionArray.storeCompanyFormationQuestion
  );

  const [currentQuestionArray, setCurrentQuestionArray] = useState<
    ICompanyFormationQuestion[]
  >([]);

  useEffect(() => {
    const idBusinessForm = props.companyFormation.idBusinessForm;
    const tmpArray = [
      ...companyFormationQuestionArray.filter(
        x => x.idBusinessForm === idBusinessForm
      ),
    ];

    // wenn UG (7), dann ebenfalls für GmbH filter
    if (idBusinessForm === 7) {
      tmpArray.push(
        ...companyFormationQuestionArray.filter(x => x.idBusinessForm === 8)
      );
    }
    setCurrentQuestionArray(tmpArray);
  }, [props.companyFormation.idBusinessForm]);

  return (
    <>
      <Typography variant='h6' sx={{ mt: 5, mb: 3 }}>
        Gründungsoptionen
      </Typography>

      <Grid container spacing={2}>
        {currentQuestionArray.map(x => (
          <CompanyFormationAnswerEdit
            key={`idCompanyFormationQuestion-${x.idCompanyFormationQuestion}`}
            currentQuestion={x}
            companyFormationAnswerArray={props.companyFormationAnswerArray}
            setCompanyFormationAnswerArray={
              props.setCompanyFormationAnswerArray
            }
          />
        ))}
      </Grid>
    </>
  );
};
