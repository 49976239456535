import React, { useEffect, useState } from 'react';
import { IDocument } from '../../Interfaces/IDocument';
import { DocumentOverview } from '../../Document/DocumentOverview';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { IDocumentDirectoryHasDocument } from '../../Interfaces/IDocumentDirectoryHasDocument';

interface IProps {
  documentArray: IDocumentDirectoryHasDocument[];
  setDocumentArray: Function;
  isOpen: boolean;
  setIsOpen: Function;
  idDocumentDirecotry: number;
}

export const DocumentDirectoryDocumentNew: React.FC<IProps> = props => {
  const [documentArray, setDocumentArray] = useState<IDocument[]>([]);

  useEffect(() => {
    if (props.isOpen) {
      setDocumentArray([]);
    }
  }, [props.isOpen]);

  const handleAddDocument = () => {
    let minId = -1;

    if (props.documentArray.length > 0) {
      const minIdArray =
        Math.min(...props.documentArray.map(x => x.idDocument)) - 1;

      if (minIdArray <= minId) {
        minId = minIdArray - 1;
      }
    }

    props.setDocumentArray([
      ...props.documentArray,
      ...documentArray.map((x, i) => {
        return {
          ...x,
          idDocument: minId - i,
          idDocumentDirectory: props.idDocumentDirecotry,
        } as IDocumentDirectoryHasDocument;
      }),
    ]);

    props.setIsOpen(false);
  };

  return (
    <>
      <Dialog open={props.isOpen} onClose={() => props.setIsOpen(false)}>
        <DialogContent>
          <DocumentOverview
            title='Date hinzufügen'
            allowRemove
            allowUpload
            documentArray={documentArray}
            setDocumentArray={setDocumentArray}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => props.setIsOpen(false)}>
            Abbruch
          </Button>
          <Button variant='contained' onClick={handleAddDocument}>
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
