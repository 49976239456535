import React, { useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { IDocumentDirectoryTemplate } from '../../Interfaces/IDocumentDirectoryTemplate';
import { getDocumentDir } from './DocumentDirectoryTemplateOverview';

interface IProps {
  isOpen: boolean;
  setIsOpen: Function;
  documentDirectoryArray: IDocumentDirectoryTemplate[];
  setDocumentDirectoryArray: Function;
  parent_idDocumentDirectory: number | null;
  currentObject?: IDocumentDirectoryTemplate;
}

export const DocumentDirectoryTemplateEdit: React.FC<IProps> = props => {
  const [newDocumentenDirecotry, setNewDocumentenDirecotry] = useState(
    props.currentObject !== undefined
      ? props.currentObject
      : getDocumentDir(
          -1,
          props.documentDirectoryArray,
          true,
          props.parent_idDocumentDirectory
        )
  );

  useEffect(() => {
    if (props.isOpen) {
      if (props.currentObject) {
        setNewDocumentenDirecotry(props.currentObject);
      } else {
        setNewDocumentenDirecotry(
          getDocumentDir(
            -1,
            props.documentDirectoryArray,
            true,
            props.parent_idDocumentDirectory
          )
        );
      }
    }
  }, [props.isOpen]);

  const handleAddDirecotry = () => {
    if (props.currentObject === undefined) {
      props.setDocumentDirectoryArray([
        ...props.documentDirectoryArray,
        newDocumentenDirecotry,
      ]);
    } else {
      props.setDocumentDirectoryArray([
        ...props.documentDirectoryArray.map(x =>
          x.idDocumentDirectoryTemplate ===
          newDocumentenDirecotry.idDocumentDirectoryTemplate
            ? newDocumentenDirecotry
            : x
        ),
      ]);
    }

    props.setIsOpen(false);
  };

  return (
    <>
      <Dialog open={props.isOpen} onClose={() => props.setIsOpen(false)}>
        <DialogTitle>
          {props.currentObject === undefined ? (
            <>Neuer Ordner</>
          ) : (
            <>Umbennen</>
          )}
        </DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mt: 3 }}
            label='Ordnername'
            value={newDocumentenDirecotry.Title}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setNewDocumentenDirecotry({
                ...newDocumentenDirecotry,
                Title: event.target.value,
              })
            }
            size='small'
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => props.setIsOpen(false)}>
            Abbruch
          </Button>
          <Button
            variant='contained'
            disabled={newDocumentenDirecotry.Title === ''}
            onClick={handleAddDirecotry}
          >
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
