import React, { useState } from 'react';
import { useFetch } from '../../hooks/useFetch';
import { ILanguageSection } from '../../Interfaces/ILanguageSection';
import { Alert, Typography } from '@mui/material';
import { CustomCircularProgress } from '../../generic/CustomCircularProgress';
import { DataGrid, GridColDef, deDE } from '@mui/x-data-grid';
import { Check, Close } from '@mui/icons-material';
import { LanguageSectionWrapper } from './LanguageSectionWrapper';

const columns: GridColDef[] = [
  { field: 'idLanguageSection', headerName: 'Nr.', width: 90 },
  {
    field: 'isPublic',
    headerName: 'Öffentlich',
    width: 150,
    renderCell: params =>
      Boolean(params.row.isPublic) ? <Check /> : <Close />,
  },
  { field: 'LanguageSection', headerName: 'Bezeichnung', flex: 1 },
];

export const LanguageSectionOverview: React.FC = () => {
  const [languageSectionArray, setLanguageSectionArray, wasSuccessfully] =
    useFetch<ILanguageSection[]>('/languagesection');
  //
  const [idLanguageSection, setIdLanguageSection] = useState<number | null>(
    null
  );

  if (!wasSuccessfully) {
    return <Alert severity='error'>Fehler!</Alert>;
  } else if (languageSectionArray === undefined) {
    return <CustomCircularProgress />;
  } else {
    return (
      <>
        {idLanguageSection !== null && (
          <LanguageSectionWrapper
            idLanguageSection={idLanguageSection}
            setIdLanguageSection={setIdLanguageSection}
          />
        )}

        <Typography variant='h5' sx={{ mt: 5 }}>
          Sprachpakete/-sektionen
        </Typography>

        <DataGrid
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          rows={languageSectionArray}
          columns={columns}
          getRowId={row => row.idLanguageSection}
          onRowClick={(params, event, details) =>
            setIdLanguageSection(Number(params.id))
          }
          autoHeight
        />
      </>
    );
  }
};
