import React, { useState } from 'react';
import { IDocumentDirectory } from '../../Interfaces/IDocumentDirectory';
import { Box, Button, Typography } from '@mui/material';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { DocumentDirectoryTreeEntry } from './DocumentDirectoryTreeEntry';
import { TreeView } from '@mui/x-tree-view';
import { DocumentDirectoryEdit } from './DocumentDirectoryEdit';
import { IDocumentDirectoryHasDocument } from '../../Interfaces/IDocumentDirectoryHasDocument';
import { LandregisterSerach } from './LandregisterSerach';
import { ILegalTransaction_has_LegalTransactionSpecialFunction } from '../../Interfaces/ILegalTransaction_has_LegalTransactionSpecialFunction';

interface IProps {
  idLegalTransaction: number;
  documentArray: IDocumentDirectoryHasDocument[];
  documentDirectoryArray: IDocumentDirectory[];
  setDocumentArray: Function;
  setDocumentDirectoryArray: Function;

  legalTransaction_has_LegalTransactionSpecialFunctionArray?: ILegalTransaction_has_LegalTransactionSpecialFunction[];
  setLegalTransaction_has_LegalTransactionSpecialFunctionArray?: Function;
}

export const getDocumentDir = (
  targetId: number,
  documentDirectoryArray: IDocumentDirectory[],
  forceNew: boolean = false,
  Parent_idDocumentDirectory: number | null = null
) => {
  const testObject = documentDirectoryArray.find(
    x => x.idDocumentDirectory === targetId
  );

  if (testObject !== undefined && forceNew === false) {
    return testObject;
  } else {
    let tmpId = -1;

    if (documentDirectoryArray.length > 0) {
      const tmpIdArray =
        Math.min(...documentDirectoryArray.map(x => x.idDocumentDirectory)) - 1;

      if (tmpIdArray <= tmpId) {
        tmpId = tmpIdArray - 1;
      }
    }

    return {
      idDocumentDirectory: tmpId,
      Parent_idDocumentDirectory: Parent_idDocumentDirectory,
      idLegalTransaction: -1,
      Title: '',
      Created_at: null,
    } as IDocumentDirectory;
  }
};

export const DocumentDirectoryOverview: React.FC<IProps> = props => {
  const [isOpenNew, setIsOpenNew] = useState(false);

  const handleAddDirecotry = () => {
    setIsOpenNew(true);
  };
  return (
    <>
      <DocumentDirectoryEdit
        isOpen={isOpenNew}
        setIsOpen={setIsOpenNew}
        documentDirectoryArray={props.documentDirectoryArray}
        setDocumentDirectoryArray={props.setDocumentDirectoryArray}
        parent_idDocumentDirectory={null}
      />
      <Typography variant='h5'>
        Interne Datenablage
        <Button
          variant='outlined'
          sx={{ float: 'right' }}
          onClick={handleAddDirecotry}
        >
          Neuer Ordner
        </Button>
        <LandregisterSerach
          idLegalTransaction={props.idLegalTransaction}
          documentDirectoryArray={props.documentDirectoryArray}
          documentArray={props.documentArray}
          setDocumentArray={props.setDocumentArray}
          setDocumentDirectoryArray={props.setDocumentDirectoryArray}
        />
      </Typography>
      (Rechter Mausklick wird unterstützt)
      <Box sx={{ mt: 2, minHeight: 220, flexGrow: 1, maxWidth: 300 }}>
        <TreeView
          aria-label='multi-select'
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
          multiSelect
        >
          {props.documentDirectoryArray
            .filter(x => x.Parent_idDocumentDirectory === null)
            .map(x => (
              <DocumentDirectoryTreeEntry
                key={`idDocumentDirectory-${x.idDocumentDirectory}`}
                documentDirecotryObject={x}
                documentArray={props.documentArray}
                documentDirectoryArray={props.documentDirectoryArray}
                setDocumentArray={props.setDocumentArray}
                setDocumentDirectoryArray={props.setDocumentDirectoryArray}
              />
            ))}
        </TreeView>
      </Box>
    </>
  );
};
