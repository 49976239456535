import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICourt } from '../../Interfaces/IAemter';

const initialState = { storeDistrictCourt: [] as ICourt[] };

const sliceDistrictCourt = createSlice({
  name: 'DISTRICTCOURTARRAY',
  initialState,
  reducers: {
    setDistrictCourtArray: (state, action: PayloadAction<ICourt[]>) => {
      state.storeDistrictCourt = [...action.payload];
    },
    addUpdateDistrictCourtArray: (state, action: PayloadAction<ICourt>) => {
      const testObject = state.storeDistrictCourt.find(
        x => x.idCourt === action.payload.idCourt
      );

      if (testObject) {
        state.storeDistrictCourt = [
          ...state.storeDistrictCourt.map(x =>
            x.idCourt === action.payload.idCourt ? action.payload : x
          ),
        ];
      } else {
        state.storeDistrictCourt = [
          action.payload,
          ...state.storeDistrictCourt,
        ];
      }
    },
  },
});
export const { setDistrictCourtArray, addUpdateDistrictCourtArray } =
  sliceDistrictCourt.actions;
export default sliceDistrictCourt.reducer;
