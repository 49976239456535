import React, { SyntheticEvent, useState } from 'react';

import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { ICourt } from '../Interfaces/IAemter';
import { useCourts } from '../Aemter/hooks';
import { getAmtsgerichtLabel } from '../services/dialog.service';

interface IProps {
  courtId: number | null;
  setCourtObjectWrapper: Function;
}

export const CourtSearch: React.FC<IProps> = ({
  courtId,
  setCourtObjectWrapper,
}) => {
  const [open, setOpen] = useState(false);
  const { courts, isLoadingCourts } = useCourts();

  function onChange(_event: SyntheticEvent, CourtObject: ICourt | null) {
    console.log(CourtObject);
    setCourtObjectWrapper(CourtObject);
  }

  return !isLoadingCourts && courts ? (
    <Autocomplete
      id='searchbar-districtCourt'
      fullWidth
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={
        courtId
          ? courts.find(courtObject => courtObject.idCourt === courtId)
          : null
      }
      getOptionLabel={option => getAmtsgerichtLabel(option)}
      loadingText='Laden...'
      options={courts}
      isOptionEqualToValue={(option, value) =>
        option.idPostcode === value.idPostcode
      }
      loading={isLoadingCourts}
      onChange={onChange}
      renderInput={params => (
        <TextField
          {...params}
          label='Amtsgericht'
          size='small'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoadingCourts ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  ) : null;
};
