import React, { useCallback, useEffect } from 'react';
import { DataGrid, deDE, GridColDef } from '@mui/x-data-grid';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { ILegalTransactionHasContact } from '../../Interfaces/IContact';
import {
  ISendDocument,
  ISendDocumentDocumentType,
} from '../../Interfaces/ISendDocument';
import { ExpandMore } from '@mui/icons-material';
import { CustomEditor } from '../../core/CustomEditor';
import { useGetSystemMail } from '../../hooks/data';

interface ISendDocumentProps {
  contactArray: ILegalTransactionHasContact[];
  sendDocument: ISendDocument;
  setSendDocument: React.Dispatch<React.SetStateAction<ISendDocument>>;
}

export const SendDocument: React.FC<ISendDocumentProps> = ({
  sendDocument,
  contactArray,
  setSendDocument,
}) => {
  const systemMailId = 31;

  const { systemMail, isLoadingSystemMail } = useGetSystemMail(systemMailId);

  // Funktion zum Aktualisieren der ausgewählten Kontakte im sendDocument Objekt
  const handleRowSelectionChange = (newSelection: number[]) => {
    sendDocument.contactArray = contactArray.filter(contact =>
      newSelection.includes(contact.idContact)
    );
    setSendDocument({ ...sendDocument });
  };

  const handleDocumentSelection = (documentType: ISendDocumentDocumentType) => {
    const updatedSelectedDocuments = sendDocument.documentTypeArray.includes(
      documentType
    )
      ? sendDocument.documentTypeArray.filter(
          docType => docType !== documentType
        )
      : [...sendDocument.documentTypeArray, documentType];

    sendDocument.documentTypeArray = [...updatedSelectedDocuments];
    setSendDocument({ ...sendDocument });
  };

  const setEmailText = useCallback(
    (txt: string) => {
      sendDocument.emailText = txt;
      setSendDocument({ ...sendDocument });
    },
    [sendDocument, setSendDocument]
  );

  const setEmailSubject = useCallback(
    (subject: string) => {
      sendDocument.subject = subject;
      setSendDocument({ ...sendDocument });
    },
    [sendDocument, setSendDocument]
  );

  useEffect(() => {
    if (systemMail && sendDocument.emailText === undefined) {
      setEmailText(systemMail.Body);
      setEmailSubject(systemMail.Subject);
    }
  }, [systemMail, sendDocument.emailText, setEmailText, setEmailSubject]);

  const columns: GridColDef[] = [
    { field: 'idContact', headerName: 'Nr.', width: 70 },
    { field: 'FirstName', headerName: 'Vorname', width: 130 },
    { field: 'LastName', headerName: 'Nachname', width: 130 },
    { field: 'Email', headerName: 'E-Mail-Adresse', flex: 1 },
  ];

  if (isLoadingSystemMail) {
    return (
      <Box
        sx={{
          height: '30vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!systemMail) {
    return (
      <Box
        sx={{
          height: '30vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography>
          Es gab einen Fehler beim Laden der automatischen Email
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ display: 'flex', padding: 2, flexDirection: 'column' }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls='selected-contacts-content'
            id='selected-contacts-header'
          >
            <Typography>
              Ausgewählte Mandanten ({sendDocument.contactArray.length})
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DataGrid
              sx={{
                mt: 2,
                '& .MuiDataGrid-row': {
                  '&.Mui-selected': {
                    backgroundColor: '#ffe6e6',
                  },
                },
              }}
              autoHeight
              localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
              rows={contactArray}
              columns={columns}
              getRowId={row => row.idContact}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 100,
                  },
                },
              }}
              disableRowSelectionOnClick
              checkboxSelection
              // Prop für die manuelle Auswahlsteuerung
              rowSelectionModel={sendDocument.contactArray.map(
                contact => contact.idContact
              )}
              onRowSelectionModelChange={newSelection =>
                handleRowSelectionChange(newSelection as number[])
              }
            />
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls='selected-contacts-content'
            id='selected-contacts-header'
          >
            <Typography>
              Ausgewählte Unterlagen ({sendDocument.documentTypeArray.length})
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display={'flex'} flexDirection={'column'}>
              {Object.keys(ISendDocumentDocumentType).map(key => {
                const documentType =
                  ISendDocumentDocumentType[
                    key as keyof typeof ISendDocumentDocumentType
                  ];
                return (
                  <FormControlLabel
                    key={documentType}
                    control={
                      <Checkbox
                        checked={sendDocument.documentTypeArray.includes(
                          documentType
                        )}
                        onChange={() => handleDocumentSelection(documentType)}
                      />
                    }
                    label={documentType}
                  />
                );
              })}
            </Box>
          </AccordionDetails>
        </Accordion>
        {sendDocument.emailText !== undefined && (
          <CustomEditor
            txt={sendDocument.emailText}
            setTxt={(txt: string) => setEmailText(txt)}
            dictonaryIdsArray={[1, 2, 3]}
          />
        )}
      </Box>
    </>
  );
};
