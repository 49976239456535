import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import * as WMS from 'leaflet.wms';

export interface IAlkisResult {
  CadastralDistrict: string;
  CadastralUnit: string;
  Marking: string;
  Location: string;
  Area: string;
  TerritorialAffiliation: string;
}

class MySource extends WMS.Source {
  handleInfoResults: Function = () => {};

  mappingDict: { [key: string]: keyof IAlkisResult } = {
    Flurstücksnummer: 'CadastralDistrict',
    Flur: 'CadastralUnit',
    Gemarkung: 'Marking',
    Lage: 'Location',
    'amtliche Fläche': 'Area',
    Gebietszugehörigkeit: 'TerritorialAffiliation',
  };

  constructor(
    url: string,
    options: WMS.WmsParamsOptions,
    handleInfoResults: Function
  ) {
    super(url, options);
    this.handleInfoResults = handleInfoResults;
  }

  castInfoHtmlToAlkisResult(info: string): IAlkisResult {
    // Casten Txt zum Dom, Object
    const parser = new DOMParser();
    const doc = parser.parseFromString(info, 'text/html');

    const returnValue: IAlkisResult = {
      Area: '-',
      CadastralDistrict: '-',
      CadastralUnit: '-',
      Location: '-',
      Marking: '-',
      TerritorialAffiliation: '-',
    };

    const rows = doc.querySelectorAll('table tr');
    rows.forEach(row => {
      const cells = row.querySelectorAll('td');
      if (cells.length === 2) {
        const key = String(cells[0].textContent?.trim());
        const value = String(cells[1].textContent?.trim());

        if (this.mappingDict.hasOwnProperty(String(key))) {
          returnValue[this.mappingDict[key]] = value;
        }
      }
    });

    return returnValue;
  }

  showFeatureInfo(latlng: L.LatLng, info: any): void {
    this.handleInfoResults(latlng, this.castInfoHtmlToAlkisResult(info));
    //super.showFeatureInfo(latlng, info)
  }

  onRemove(): this {
    this._overlay.remove();
    return this;
  }
}

function CustomWMSLayer(props: {
  url: any;
  options: any;
  layers: any;
  handleInfoResults: Function;
}) {
  const { url, options, layers, handleInfoResults } = props;
  const map = useMap();

  /*
    const source = new WMS.Source(
        url,
        options
    );
    */
  useEffect(() => {
    const source = new MySource(url, options, handleInfoResults);

    for (const name of layers) {
      source.getLayer(name).addTo(map);
    }
  }, []);

  return null;
}

export default CustomWMSLayer;
