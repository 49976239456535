import React, { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material';
import { deleteFetch, uploadFetch, useFetch } from '../../hooks/useFetch';
import { IFSDirectoryType } from '../../Interfaces/IFSDirectoryType';
import { IFSDirectoryProtocol } from '../../Interfaces/IFSDirectoryProtocol';
import { IFSDirectoryAuthentication } from '../../Interfaces/IFSDirectoryAuthentication';
import { IFSDirectory } from '../../Interfaces/IFSDirectory';
import { CustomCircularProgress } from '../../generic/CustomCircularProgress';
import { Add, Delete } from '@mui/icons-material';
import { DataGrid, deDE, GridColDef } from '@mui/x-data-grid';
import { FSDirectoryEdit } from './FSDirectoryEdit';
import { ShowWasSaved } from '../../core/ShowWasSaved';

interface ITestingFs {
  wasSuccessfully: boolean;
  text: string[];
}

const columns: GridColDef[] = [
  { field: 'idFSDirectory', headerName: 'Nr.', width: 90 },
  { field: 'FSDirectoryType', headerName: 'Ordnerart', width: 150 },
  { field: 'FSDirectoryProtocol', headerName: 'Protokoll', width: 150 },
  {
    field: 'FSDirectoryAuthentication',
    headerName: 'Authentifizierung',
    width: 150,
  },
  { field: 'Name', headerName: 'Name', flex: 1 },
];

const getEmptyFsDir = () => {
  return {
    idFSDirectoryAuthentication: 1,
    idFSDirectoryProtocol: 1,
    idFSDirectoryType: 1,
    idFSDirectory: -1,
    Name: '',
    OriginPath: '',
    Password: '',
    Server: '',
    User: '',
  } as IFSDirectory;
};

export const FSDirectoryOverview: React.FC = () => {
  const [fsDirTypeArray, setFsDirTypeArray, wasSuccessfullyfsDirType] =
    useFetch<IFSDirectoryType[]>('/FSDirectoryType'.toLocaleLowerCase());
  const [
    fsDirProtocollArray,
    setFsDirProtocollArray,
    wasSuccessfullyfsDirProtocoll,
  ] = useFetch<IFSDirectoryProtocol[]>(
    '/FSDirectoryProtocol'.toLocaleLowerCase()
  );
  const [fsDirAuthArray, setFsDirAuthArray, wasSuccessfullyfsDirAuth] =
    useFetch<IFSDirectoryAuthentication[]>(
      '/FSDirectoryAuthentication'.toLocaleLowerCase()
    );
  const [fsDirArray, setFsDirArray, wasSuccessfullyfsDir] = useFetch<
    IFSDirectory[]
  >('/FSDirectory'.toLocaleLowerCase());
  //
  const [isOpenFsDir, setIsOpenFsDir] = useState(false);
  const [editDirObj, setEditDirObj] = useState(getEmptyFsDir());
  const [wasValidated, setWasValidated] = useState(false);
  //
  const [isLoadingTesting, setIsLoadingTesting] = useState(false);
  const [isOpenTestingRes, setIsOpenTestingRes] = useState(false);
  const [testingRes, setTestingRes] = useState({
    wasSuccessfully: true,
    text: [],
  } as ITestingFs);
  //
  const [wasSuccessfullySaved, setWasSuccessfullySaved] = useState(false);
  //
  const [isOpenDeleteConfirmation, setIsOpenDeleteConfirmation] =
    useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [wasRemoved, setWasRemoved] = useState(false);
  const [wasRemovedSuccessfully, setWasRemovedSuccessfully] = useState(true);

  const handleOpenNew = () => {
    setWasValidated(false);
    setEditDirObj(getEmptyFsDir());
    setIsOpenFsDir(true);
  };

  const handleAfterTssting = (res: ITestingFs) => {
    setTestingRes(res);
    setIsOpenTestingRes(true);
    setWasValidated(res.wasSuccessfully);
  };

  const handleTesting = () => {
    uploadFetch(
      '/fsdirectory/checkDirectory',
      true,
      editDirObj,
      handleAfterTssting,
      () => {},
      () => {},
      setIsLoadingTesting
    );
  };

  const handleAfterSave = (res: IFSDirectory) => {
    if (fsDirArray !== undefined) {
      const foundObject = fsDirArray?.find(
        x => x.idFSDirectory === res.idFSDirectory
      );

      if (foundObject === undefined) {
        setFsDirArray([...fsDirArray, res]);
      } else {
        setFsDirArray([
          ...fsDirArray.map(x =>
            x.idFSDirectory === res.idFSDirectory ? res : x
          ),
        ]);
      }
    }
    setIsOpenFsDir(false);
  };

  const handleSaveDirectory = () => {
    uploadFetch(
      '/fsdirectory',
      editDirObj.idFSDirectory < 0,
      editDirObj,
      handleAfterSave,
      setWasSuccessfullySaved,
      () => {},
      setIsLoadingTesting
    );
  };

  const handleRowClick = (idFSDirectory: number) => {
    setWasValidated(false);
    const testObject = fsDirArray?.find(x => x.idFSDirectory === idFSDirectory);

    if (testObject !== undefined) {
      setEditDirObj(testObject);
      setIsOpenFsDir(true);
    }
  };

  const handleAfterDelete = (wasSuccesfully: boolean) => {
    setIsLoadingDelete(false);
    setWasRemovedSuccessfully(true);
    setWasRemoved(true);
    setIsOpenDeleteConfirmation(false);
    setIsOpenFsDir(false);
    if (fsDirArray !== undefined) {
      setFsDirArray([
        ...fsDirArray.filter(x => x.idFSDirectory !== editDirObj.idFSDirectory),
      ]);
    }
  };

  const handleDelete = () => {
    setIsLoadingDelete(true);
    deleteFetch('/fsdirectory', editDirObj.idFSDirectory, handleAfterDelete);
  };

  if (
    !wasSuccessfullyfsDirType ||
    !wasSuccessfullyfsDirProtocoll ||
    !wasSuccessfullyfsDirAuth ||
    !wasSuccessfullyfsDir
  ) {
    return <>Fehler!</>;
  } else if (
    fsDirTypeArray === undefined ||
    fsDirProtocollArray === undefined ||
    fsDirAuthArray === undefined ||
    fsDirArray === undefined
  ) {
    return <CustomCircularProgress />;
  } else {
    return (
      <>
        <ShowWasSaved
          wasSaved={wasRemoved}
          setWasSaved={setWasRemoved}
          wasSuccessfullySaved={wasRemovedSuccessfully}
          text='Erfolgreich gelöscht!'
        />

        <Dialog
          open={isOpenDeleteConfirmation}
          onClose={() => setIsOpenDeleteConfirmation(false)}
        >
          <DialogTitle>Löschen</DialogTitle>
          <DialogContent>
            {isLoadingDelete && <LinearProgress />}
            Sind Sie sich sicher, dass Sie die aktuelle Ordner-Anbindung löschen
            wollen?
          </DialogContent>
          <DialogActions>
            <Button
              disabled={isLoadingDelete}
              onClick={() => setIsOpenDeleteConfirmation(false)}
            >
              Abbruch
            </Button>
            <Button disabled={isLoadingDelete} onClick={handleDelete}>
              Löschen
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isOpenTestingRes}
          onClose={() => setIsOpenTestingRes(false)}
        >
          <DialogContent>
            <Alert
              severity={testingRes.wasSuccessfully ? 'success' : 'warning'}
            >
              Die Prüfung war {!testingRes.wasSuccessfully && 'nicht'}{' '}
              erfolgreich!
              <br />
              <ul>
                {testingRes.text.map(x => (
                  <li>{x}</li>
                ))}
              </ul>
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              onClick={() => setIsOpenTestingRes(false)}
            >
              Okay
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isOpenFsDir}
          onClose={() => setIsOpenFsDir(false)}
          maxWidth='sm'
          fullWidth
        >
          <DialogTitle>
            {editDirObj.idFSDirectory < 0 ? (
              <>Neuen Ordner anlegen</>
            ) : (
              <>Ordner bearbeiten</>
            )}
            {editDirObj.idFSDirectory > 0 && (
              <IconButton
                onClick={() => setIsOpenDeleteConfirmation(true)}
                sx={{ float: 'right' }}
              >
                <Delete />
              </IconButton>
            )}
          </DialogTitle>
          <DialogContent>
            <>
              {isLoadingTesting && <LinearProgress />}
              <Box sx={{ mt: 2 }} />
              <FSDirectoryEdit
                fsDirObject={editDirObj}
                setFsDirObject={setEditDirObj}
                fsDirAuthArray={fsDirAuthArray}
                fsDirProtocollArray={fsDirProtocollArray}
                fsDirTypeArray={fsDirTypeArray}
              />
            </>
          </DialogContent>
          <DialogActions>
            <Button
              variant='outlined'
              disabled={isLoadingTesting}
              onClick={() => setIsOpenFsDir(false)}
            >
              Abbruch
            </Button>
            <Button
              variant='contained'
              disabled={isLoadingTesting}
              onClick={handleTesting}
            >
              Prüfen
            </Button>
            <Button
              variant='contained'
              disabled={!wasValidated || isLoadingTesting}
              onClick={handleSaveDirectory}
            >
              Speichern
            </Button>
          </DialogActions>
        </Dialog>

        <Typography variant='h5' sx={{ mt: 5 }}>
          Ordnerverwaltung
          <IconButton sx={{ float: 'right' }} onClick={handleOpenNew}>
            <Add />
          </IconButton>
        </Typography>
        <Box sx={{ mt: 2 }} />

        <DataGrid
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          rows={fsDirArray}
          columns={columns}
          getRowId={row => row.idFSDirectory}
          onRowClick={(params, event, details) =>
            handleRowClick(Number(params.id))
          }
        />
      </>
    );
  }
};
