import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { IRealEstate } from '../../../../Interfaces/IRealEstate';
import { MortgageRealEstateLandRegister } from '../Components/MortgageRealEstateLandRegister';
import { MortgageRealEstateAddress } from '../Components/MortgageRealEstateAddress';

interface IProps {
  realEstate: IRealEstate;
  setRealEstate: Function;
  isSavePossibleValue: Record<string, boolean>;
  setIsSavePossible: Function;
}

export const RealEstate: React.FC<IProps> = ({
  realEstate,
  setRealEstate,
  isSavePossibleValue,
  setIsSavePossible,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  //
  const [currentRealEstateObject, setCurrentRealEstateObject] =
    useState(realEstate);

  const [hasAddress, setHasAddress] = useState<string>(
    currentRealEstateObject.Street && currentRealEstateObject.Street !== ''
      ? 'no'
      : 'yes'
  );

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'no') {
      setCurrentRealEstateObject({
        ...currentRealEstateObject,
        LandRegister: '',
        Court: undefined,
        LandParcel: '',
        Parcel: '',
        SheetLandRegister: '',
        idCourt: null,
      });
    } else if (event.target.value === 'yes') {
      setCurrentRealEstateObject({
        ...currentRealEstateObject,
        City: '',
        Postcode: '',
        Street: '',
        StreetNr: '',
        StreetAditional: '',
        Latitude: null,
        Longitude: null,
        idPostcode: null,
      });
    }
    setHasAddress(event.target.value);
  };

  const handleSubmit = () => {
    setIsSavePossible({
      ...isSavePossibleValue,
      accordionRealEstate: true,
    } as Record<string, boolean>);

    setRealEstate(currentRealEstateObject);
  };

  /**
   * Form Function
   */
  const handleOnSave = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      handleSubmit();
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements).reverse();

      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  useEffect(() => {
    setIsSavePossible({
      ...isSavePossibleValue,
      accordionRealEstate:
        JSON.stringify(realEstate) === JSON.stringify(currentRealEstateObject),
    } as Record<string, boolean>);
  }, [currentRealEstateObject]);

  return (
    <>
      <form ref={formRef}>
        <Typography variant='h6' sx={{ mb: 2 }}>
          Geben Sie die Grundbuchdaten Ihres Grundstücks an, das mit der
          Grundschuld belastet ist bzw. werden soll. Falls Ihnen diese Daten
          nicht vorliegen geben Sie bitte die Adresse des Grundstücks ein.
        </Typography>
        <Typography variant='body1' sx={{ mb: 2 }}>
          Liegen Grundbuchdaten vor?
        </Typography>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <FormControl component='fieldset'>
              <RadioGroup
                row
                aria-label='property-owned'
                name='property-owned'
                value={hasAddress}
                onChange={handleToggle}
              >
                <FormControlLabel
                  value='yes'
                  control={<Radio required />}
                  label='Ja'
                />
                <FormControlLabel
                  value='no'
                  control={<Radio required />}
                  label='Nein'
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item sx={{ mt: 1 }}>
            {hasAddress === 'no' && (
              <MortgageRealEstateAddress
                currentObject={currentRealEstateObject}
                setCurrentObject={setCurrentRealEstateObject}
              />
            )}
            {hasAddress === 'yes' && (
              <MortgageRealEstateLandRegister
                key={currentRealEstateObject.idCourt}
                currentObject={currentRealEstateObject}
                setCurrentObject={setCurrentRealEstateObject}
              />
            )}
          </Grid>
        </Grid>
        <Box sx={{ mt: 6 }} />
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            variant='outlined'
            disabled={
              JSON.stringify(realEstate) ===
              JSON.stringify(currentRealEstateObject)
            }
            onClick={() => {
              setCurrentRealEstateObject(realEstate);
              setIsSavePossible({
                ...isSavePossibleValue,
                accordionRealEstate: true,
              } as Record<string, boolean>);
              setHasAddress(
                realEstate.Street && realEstate.Street !== '' ? 'no' : 'yes'
              );
            }}
            sx={{ mr: 1 }}
          >
            Abbrechen
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button
            onClick={() => {
              handleOnSave();
            }}
            disabled={
              JSON.stringify(realEstate) ===
              JSON.stringify(currentRealEstateObject)
            }
            variant={'contained'}
          >
            Übernehmen
          </Button>
        </Box>
      </form>
    </>
  );
};
