import { IInheritHasPerson } from '../../../../../Interfaces/IPerson';
import { IPersonHasDocument } from '../../../../../Interfaces/IPersonHasDocument';
import { IChildren } from '../../../../../Interfaces/IChildren';
import { IInherit } from '../../../../../Interfaces/IInherit';
import { IMarriage } from '../../../../../Interfaces/IMarriage';
import { ChildrenTable } from './ChildrenTable';
import { Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

export interface IChildrenTableColDef {
  titleFirstParent: string;
  isSecondParentEnabled: boolean;
}

interface IProps {
  inheritObject: IInherit;
  idPersonInherit: number;
  firstParentArray: IInheritHasPerson[];
  personArray: IInheritHasPerson[];
  setPersonArray: Dispatch<SetStateAction<IInheritHasPerson[]>>;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Dispatch<SetStateAction<IPersonHasDocument[]>>;
  childrenArray: IChildren[];
  setChildrenArray: Dispatch<SetStateAction<IChildren[]>>;
  marraigeArray: IMarriage[];
  getTitle: (degreeOfKinship: number) => string;
  getAddButtonTitle: (degreeOfKinship: number) => string;
  title: string;
  children: IChildren[];
  parents: IChildren[];
}

export const ChildrenDriver: React.FC<IProps> = ({
  inheritObject,
  idPersonInherit,
  firstParentArray,
  personArray,
  setPersonArray,
  personHasDocument,
  setPersonHasDocument,
  childrenArray,
  setChildrenArray,
  marraigeArray,
  getTitle,
  getAddButtonTitle,
  title,
  children,
  parents,
}) => {
  return (
    <>
      <Typography variant='body1' sx={{ mt: 2, mb: 3 }}>
        {title}
      </Typography>

      <ChildrenTable
        childrens={children}
        inheritObject={inheritObject}
        idPersonInherit={idPersonInherit}
        firstParentArray={firstParentArray}
        personArray={personArray}
        setPersonArray={setPersonArray}
        personHasDocument={personHasDocument}
        setPersonHasDocument={setPersonHasDocument}
        childrenArray={childrenArray}
        setChildrenArray={setChildrenArray}
        marraigeArray={marraigeArray}
        degreeOfKinship={1}
        parents={parents}
        getTitle={getTitle}
        getAddButtonTitle={getAddButtonTitle}
      />
    </>
  );
};
