import React, { useEffect, useState } from 'react';
import { IShareHolder } from '../../../../Interfaces/IShareHolder';
import { ICompanyFormationHasShareHolder } from '../../../../Interfaces/ICompanyFormationHasShareHolder';
import { Alert, AlertTitle, Collapse, Grid } from '@mui/material';

interface IProps {
  shareHolderArray: IShareHolder[];
  shareHolderRelationArray: ICompanyFormationHasShareHolder[];
}

export const ShareHolderRepresentationWarning: React.FC<IProps> = props => {
  //
  const [hasWarning, setHasWarning] = useState(false);

  useEffect(() => {
    setHasWarning(
      props.shareHolderRelationArray
        .filter(x => x.idShareHolderType !== 1)
        .filter(x =>
          props.shareHolderArray
            .filter(y => y.idBusinessForm !== 1)
            .filter(y => y.idShareHolder === x.idShareHolder).length > 0
            ? true
            : false
        ).length > 0
    );
  }, [props.shareHolderArray, props.shareHolderRelationArray]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Collapse in={hasWarning}>
            <Alert severity='warning'>
              <AlertTitle>Warnung</AlertTitle>
              Juristische Personen dürfen nur als <b>Gesselschafter</b>{' '}
              beteiligt sein!
            </Alert>
          </Collapse>
        </Grid>
      </Grid>
    </>
  );
};
