import {
  Grid,
  Autocomplete,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  ILegalTransactionType,
  ILegalTransactionTypeFullObject,
} from '../../../Interfaces/ILegalTransactionType';
import { getFetch } from '../../../hooks/useFetch';
import { LegalPhaseTable } from './copy/LegalPhaseTable';
import { ILegalPhaseFullObject } from '../../../Interfaces/ILegalPhase';
import { ILegalPhaseTemplateFullObject } from '../../../Interfaces/ILegalPhaseTemplate';
import { ILegalRequirementFullObject } from '../../../Interfaces/ILegalRequirement';
import { ILegalRequirementTemplateDeadline } from '../../../Interfaces/ILegalRequirementTemplate_deadlines';
import { ILegalRequirementDeadline } from '../../../Interfaces/ILegalRequirement_deadlines';

interface CopyFromTransactionTypeProps {
  transactionTypes: ILegalTransactionType[];
  open: boolean;
  onClose: () => void;
  legalPhaseArray: ILegalPhaseFullObject[];
  setLegalPhaseArray: Function;
  idLegalTransaction: number;
}

export const CopyFromTransactionType: React.FC<
  CopyFromTransactionTypeProps
> = ({
  transactionTypes,
  open,
  onClose,
  legalPhaseArray,
  setLegalPhaseArray,
  idLegalTransaction,
}) => {
  const [selectedTransactionType, setSelectedTransactionType] =
    useState<ILegalTransactionType | null>(null);

  const [fullObject, setFullObject] = useState<
    ILegalTransactionTypeFullObject | undefined
  >(undefined);

  const [choosenPhases, setChoosenphases] = useState<
    ILegalPhaseTemplateFullObject[]
  >([]);

  const getNewReq = (id: number) => {
    return {
      idLegalPhase: -1,
      idLegalRequirement: id,
      canUploadFilesClient: false,
      canUploadFilesEmployees: false,
      hasError: false,
      hasErrorFreeText: false,
      LegalRequirement: '',
      sendMail: false,
      EmailBody: null,
      EmailSubject: null,
      Predecessor_idLegalRequirement: null,
      TextOfError: null,
      Position: 0,
      enableError: false,
      enableFreeTextError: false,
      isComplete: false,
      idLegalRequirementState: 10,
      User_idUser: null,
      InfoText: null,
      initialProcessingTime: 14,
      ManualProcessingTime: false,
      UserDeadlineNotified: false,
    } as ILegalRequirementFullObject;
  };

  const getNewPhase = (id: number) => {
    return {
      idLegalPhase: id,
      InfoText: null,
      isComplete: false,
      LegalPhase: '',
      LegalRequirementArray: [],
      Position: 0,
      PreconditionArray: [],
      idAdministrativeUnit: null,
      AdministrativeUnit: null,
    } as ILegalPhaseFullObject;
  };

  const handleCopy = () => {
    let phasesToKeep = [...choosenPhases];
    let newPhases: ILegalPhaseFullObject[] = [];

    // Erstellen von Zuordnungen für neue IDs
    const phaseIdMapping: { [key: number]: number } = {}; // Map von Template-ID zu neuer ID
    const requirementIdMapping: { [key: number]: number } = {}; // Map von Template-ID zu neuer ID

    phasesToKeep = phasesToKeep?.sort(x => x.Position);
    let legalPhaseId = -1;
    let legalRequirementId = -1;

    if (phasesToKeep !== undefined) {
      for (let i = 0; i < phasesToKeep.length; i++) {
        // Neue Phase erstellen
        const newPhase = getNewPhase(legalPhaseId);
        phaseIdMapping[phasesToKeep[i].idLegalPhaseTemplate] = legalPhaseId; // Mappe alte zu neuer ID
        legalPhaseId--; // ID für nächste Phase dekrementieren

        newPhase.Position = i;
        newPhase.idAdministrativeUnit = phasesToKeep[i].idAdminstrativeUnit;
        newPhase.InfoText = phasesToKeep[i].InfoText;
        newPhase.LegalPhase = phasesToKeep[i].LegalPhaseTemplate;

        // Anforderungen der Phase kopieren
        const lrArray = phasesToKeep[i].LegalRequirementArray.sort(
          lr => lr.Position
        );
        for (let z = 0; z < lrArray.length; z++) {
          // Neues Requirement erstellen
          const newReq = getNewReq(legalRequirementId);
          requirementIdMapping[lrArray[z].idLegalRequirementTemplate] =
            legalRequirementId; // Mappe alte zu neuer ID
          legalRequirementId--; // ID für nächstes Requirement dekrementieren

          newReq.Position = z;
          newReq.LegalRequirement = lrArray[z].LegalRequirementTemplate;
          newReq.canUploadFilesClient = lrArray[z].canUploadFilesClient;
          newReq.canUploadFilesEmployees = lrArray[z].canUploadFilesEmployees;
          newReq.sendMail = lrArray[z].sendMail;
          newReq.EmailBody = lrArray[z].EmailBody;
          newReq.EmailSubject = lrArray[z].EmailSubject;
          newReq.TextOfError = lrArray[z].TextOfError;
          newReq.hasError = lrArray[z].hasError;
          newReq.enableFreeTextError = lrArray[z].hasErrorFreeText;
          newReq.idLegalRequirementTemplate =
            lrArray[z].idLegalRequirementTemplate;
          newReq.idLegalRequirementState = lrArray[z].idLegalRequirementState;
          newReq.InfoText = lrArray[z].InfoText;
          newReq.initialProcessingTime = lrArray[z].initialProcessingTime || 14;
          newReq.ManualProcessingTime = false;
          newReq.UserDeadlineNotified = false;
          newReq.isComplete = false;
          newReq.User_idUser = lrArray[z].idUser;
          newReq.actualProcessingTime = undefined;
          newReq.Court = lrArray[z].Court || '';
          newReq.CourtRole = lrArray[z].CourtRole || '';
          newReq.idCourt = lrArray[z].idCourt || undefined;
          newReq.idCourtRole = lrArray[z].idCourtRole || undefined;

          // Deadlines umwandeln und zuweisen
          if (lrArray[z].DeadlineArray !== undefined) {
            const newDeadlines = mapDeadlines(
              lrArray[z].DeadlineArray!,
              phaseIdMapping,
              requirementIdMapping
            );
            newReq.DeadlineArray = newDeadlines; // Deadlines setzen
          } else {
            newReq.DeadlineArray = [];
          }

          // Requirement zur Phase hinzufügen
          newPhase.LegalRequirementArray.push(newReq);
        }

        // Neue Phase zur Liste hinzufügen
        newPhases.push(newPhase);
      }
    }

    // Update des legalPhaseArray mit den neuen Phasen
    //get the max position of the existing phases
    const maxPosition = Math.max(
      ...newPhases.map(x => x.Position),
      ...legalPhaseArray.map(x => x.Position)
    );
    //shift the positions of the new phases by the max position
    newPhases = newPhases.map(x => {
      x.Position += maxPosition + 1;
      return x;
    });
    setLegalPhaseArray([...legalPhaseArray, ...newPhases]);
    onClose();
  };

  const mapDeadlines = (
    templateDeadlines: ILegalRequirementTemplateDeadline[],
    phaseIdMapping: { [key: number]: number },
    requirementIdMapping: { [key: number]: number }
  ): ILegalRequirementDeadline[] => {
    const newDeadlines: ILegalRequirementDeadline[] = [];

    templateDeadlines.forEach(templateDeadline => {
      let newPhaseId = undefined;
      if (
        templateDeadline.idLegalPhaseTemplate !== undefined &&
        templateDeadline.idLegalPhaseTemplate !== null
      ) {
        newPhaseId = phaseIdMapping[templateDeadline.idLegalPhaseTemplate];
        if (newPhaseId === undefined) {
          return;
        }
      }
      let newRequirementId = undefined;
      if (
        templateDeadline.idLegalRequirementTemplate !== undefined &&
        templateDeadline.idLegalRequirementTemplate !== null
      ) {
        newRequirementId =
          requirementIdMapping[templateDeadline.idLegalRequirementTemplate];
        if (newRequirementId === undefined) {
          return;
        }
      }

      // Wenn Phase und/oder Requirement übernommen wurden (neue IDs existieren)

      newDeadlines.push({
        idDeadline: templateDeadline.idDeadline,
        idLegalRequirement: newRequirementId, // Verwende die neue Requirement-ID
        idLegalPhase: newPhaseId, // Verwende die neue Phase-ID
        idLegalRequirementState: templateDeadline.idLegalRequirementState,
        Weighting: templateDeadline.Weighting || 0,
        LegalRequirement: templateDeadline.LegalRequirementTemplate,
        LegalPhase: templateDeadline.LegalPhaseTemplate,
        LegalRequirementState: templateDeadline.LegalRequirementState,
        Startdate: undefined, // Standardwert oder Anpassungen
        DeadlineTriggeredAt: undefined,
        LegalTransactionTitle: undefined,
        idLegalTransactionState: templateDeadline.idLegalTransactionState,
        LegalTransactionState: templateDeadline.LegalTransactionState,
        idLegalTransaction: idLegalTransaction,
      });
    });

    return newDeadlines;
  };

  useEffect(() => {
    if (selectedTransactionType) {
      getFetch(
        '/legaltransactiontype/fullObject/',
        selectedTransactionType.idLegalTransactionType,
        (data: ILegalTransactionTypeFullObject) => {
          setFullObject(data);
          setChoosenphases(data.LegalPhaseTemplateArray);
        }
      );
    }
  }, [selectedTransactionType]);

  return (
    <Dialog open={open} fullWidth maxWidth='lg' onClose={onClose}>
      <DialogTitle>Rechtsgeschäft kopieren</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} mt={2}>
            <Autocomplete
              fullWidth
              options={transactionTypes}
              getOptionLabel={option => option.LegalTransactionType}
              onChange={(event, value) => {
                setSelectedTransactionType(value);
              }}
              renderInput={params => (
                <TextField {...params} label='Rechtsgeschäft' />
              )}
            />
          </Grid>

          {/* Accordion für Phasen und Maßnahmen */}
          {fullObject && (
            <>
              <LegalPhaseTable
                legalPhaseTemplateArray={fullObject.LegalPhaseTemplateArray}
                choosenPhases={choosenPhases}
                setChoosenPhases={setChoosenphases}
              ></LegalPhaseTable>
            </>
          )}

          {/* Copy Button */}
          <Grid item xs={12} mt={2} display='flex' justifyContent='flex-end'>
            <Button
              variant='contained'
              color='primary'
              onClick={handleCopy}
              disabled={!selectedTransactionType || !choosenPhases?.length}
            >
              Kopieren
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Abbrechen</Button>
      </DialogActions>
    </Dialog>
  );
};
