import { IDayOffWork } from '../Interfaces/IDayOffWork';
import { IEmploymentContract } from '../Interfaces/IEmploymentContract';
import { compageDateFirstOneSmallerEqual } from '../core/generic_helper';

export const getTotalVacation = (
  idUser: number,
  employmentContractArray: IEmploymentContract[]
) => {
  const returnValue = employmentContractArray
    .filter(x => x.idUser === idUser)
    .filter(
      x =>
        x.End === null ||
        compageDateFirstOneSmallerEqual(new Date(x.End), new Date())
    )
    .map(x => x.VacationPerYear)
    .reduce((a, b) => a + b, 0);

  return returnValue;
};

export const getDaysOffWorkByCategory = (
  idUser: number,
  idDayOffWorkCategory: number,
  dayOffWorkArray: IDayOffWork[],
  isGreaterQual: boolean = false
) => {
  const resultValue = dayOffWorkArray
    .filter(x => x.idUser === idUser)
    .filter(x => x.isConfirmed === null || Boolean(x.isConfirmed) === true)
    .filter(
      x => new Date(x.FromDate).getFullYear() === new Date().getFullYear()
    )
    .filter(x =>
      isGreaterQual
        ? x.idDayOffWorkCategory >= idDayOffWorkCategory
        : x.idDayOffWorkCategory === idDayOffWorkCategory
    )
    .map(x => x.DaysOfWork)
    .reduce((a, b) => a + b, 0);

  return resultValue;
};

export const getFreeVacation = (
  idUser: number,
  employmentContractArray: IEmploymentContract[],
  dayOffWorkArray: IDayOffWork[]
) => {
  const totalVacation = getTotalVacation(idUser, employmentContractArray);
  const usedVacation = getDaysOffWorkByCategory(idUser, 1, dayOffWorkArray);

  return totalVacation - usedVacation;
};
