import React from 'react';
import { useFetch } from '../../hooks/useFetch';
import {
  Alert,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { CustomCircularProgress } from '../../generic/CustomCircularProgress';
import { SysRegRow } from './SysRegRow';
import { ISetting } from '../../Interfaces/ISignature';

export const SysRegOverview: React.FC = () => {
  const [settingArray, setSettingArray, wasSuccessfully] =
    useFetch<ISetting[]>('/setting');

  if (!wasSuccessfully) {
    return <Alert severity='error'>Zugriff verweigert</Alert>;
  } else if (settingArray === undefined) {
    return <CustomCircularProgress />;
  } else {
    return (
      <>
        <Typography variant='h5' sx={{ mt: 5 }}>
          System Registry
        </Typography>

        <Alert severity='warning' sx={{ mt: 2, mb: 5 }}>
          <b>Achtung: System Registry!</b>
          <br />
          Änderungen können das System irreversibel beschädigen!
        </Alert>

        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Key</TableCell>
              <TableCell>Value</TableCell>
              <TableCell sx={{ width: 100 }}>Aktionen</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {settingArray.map(x => (
              <SysRegRow
                key={`idSettign-${x.idSetting}`}
                settingObject={x}
                settingArray={settingArray}
                setSettingArray={setSettingArray}
              />
            ))}
          </TableBody>
        </Table>
      </>
    );
  }
};
