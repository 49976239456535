import React, { useEffect, useRef, useState } from 'react';
import { IMortgage } from '../../../../Interfaces/IMortgage';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import CompanyModifiedBaseInformation from './Beneficiary/CompanyModifiedBaseInformation';
import { IPerson } from '../../../../Interfaces/IPerson';
import { ICompany } from '../../../../Interfaces/ICompany';
import { GridBaseData } from '../../../../Person/GridBaseData';
import { NumericFormat } from 'react-number-format';
import {
  getNewCompany,
  getNewPerson,
} from '../../../../services/dialog.service';

interface IProps {
  mortgageObject: IMortgage;
  setMortgageObject: Function;
  isSavePossibleValue: Record<string, boolean>;
  setIsSavePossible: Function;
}

const transcriptOptions = [
  'simple_transcription',
  'certified_copy',
  'simple_execution',
  'enforceable_copy',
] as const;

type TranscriptOption = (typeof transcriptOptions)[number];

export const Beneficiary: React.FC<IProps> = ({
  mortgageObject,
  setMortgageObject,
  isSavePossibleValue,
  setIsSavePossible,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  //
  const [currentMortgageObject, setCurrentMortgageObject] =
    useState(mortgageObject);
  const [isPrivatePerson, setIsPrivatePerson] = useState<boolean | null>(
    currentMortgageObject.BeneficiaryDetails &&
      'FirstName' in currentMortgageObject.BeneficiaryDetails &&
      currentMortgageObject.BeneficiaryDetails.FirstName !== null &&
      currentMortgageObject.BeneficiaryDetails.FirstName !== ''
      ? true
      : false
  );
  const [creditor, setCreditor] = useState<IPerson | ICompany>(
    currentMortgageObject.BeneficiaryDetails
  );

  const initialCheckedOptions: Record<TranscriptOption, boolean> = {
    simple_transcription:
      currentMortgageObject.Transcripts?.includes('simple_transcription') ||
      false,
    certified_copy:
      currentMortgageObject.Transcripts?.includes('certified_copy') || false,
    simple_execution:
      currentMortgageObject.Transcripts?.includes('simple_execution') || false,
    enforceable_copy:
      currentMortgageObject.Transcripts?.includes('enforceable_copy') || false,
  };
  const [checkedOptions, setCheckedOptions] = useState(initialCheckedOptions);

  /**
   * Functions
   */

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'true') {
      setIsPrivatePerson(true);
      setCreditor(getNewPerson);
    } else if (event.target.value === 'false') {
      setIsPrivatePerson(false);
      setCreditor(getNewCompany);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedOptions({
      ...checkedOptions,
      [event.target.name as TranscriptOption]: event.target.checked,
    });
  };

  const handleSubmit = () => {
    setIsSavePossible({
      ...isSavePossibleValue,
      accordionBeneficiary: true,
    } as Record<string, boolean>);
    setMortgageObject(currentMortgageObject);
  };

  const handleCancel = () => {
    setIsSavePossible({
      ...isSavePossibleValue,
      accordionBeneficiary: true,
    } as Record<string, boolean>);

    setCurrentMortgageObject(mortgageObject);

    const initialIsPrivatePerson =
      mortgageObject.BeneficiaryDetails &&
      'FirstName' in mortgageObject.BeneficiaryDetails &&
      mortgageObject.BeneficiaryDetails.FirstName !== null &&
      mortgageObject.BeneficiaryDetails.FirstName !== '';

    setIsPrivatePerson(initialIsPrivatePerson ? true : false);

    setCreditor(mortgageObject.BeneficiaryDetails || null);

    const initialCheckedOptions: Record<TranscriptOption, boolean> = {
      simple_transcription:
        mortgageObject.Transcripts?.includes('simple_transcription') || false,
      certified_copy:
        mortgageObject.Transcripts?.includes('certified_copy') || false,
      simple_execution:
        mortgageObject.Transcripts?.includes('simple_execution') || false,
      enforceable_copy:
        mortgageObject.Transcripts?.includes('enforceable_copy') || false,
    };
    setCheckedOptions(initialCheckedOptions);
  };

  const getLabelForOption = (option: TranscriptOption) => {
    switch (option) {
      case 'simple_transcription':
        return 'Einfache Abschrift';
      case 'certified_copy':
        return 'Beglaubigte Abschrift';
      case 'simple_execution':
        return 'Einfache Ausfertigung';
      case 'enforceable_copy':
        return 'Vollstreckbare Ausfertigung';
      default:
        return '';
    }
  };

  const updateCreditor = (creditorObject: IPerson | ICompany) => {
    console.log(creditorObject);
    if (creditorObject !== null) {
      setCreditor(creditorObject);
      setCurrentMortgageObject({
        ...currentMortgageObject,
        BeneficiaryDetails: creditorObject,
      });
    }
  };

  /**
   * useEffect
   */

  useEffect(() => {
    const selectedTranscripts = transcriptOptions.filter(
      option => checkedOptions[option]
    );
    if (creditor !== null) {
      setCurrentMortgageObject({
        ...currentMortgageObject,
        Transcripts: selectedTranscripts,
      });
    }
  }, [checkedOptions]);

  useEffect(() => {
    setIsSavePossible({
      ...isSavePossibleValue,
      accordionBeneficiary:
        JSON.stringify(mortgageObject) ===
        JSON.stringify(currentMortgageObject),
    } as Record<string, boolean>);
  }, [currentMortgageObject]);

  useEffect(() => {
    setCurrentMortgageObject(mortgageObject);
  }, [mortgageObject]);

  /**
   * Form Function
   */
  const handleOnSave = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      setCurrentMortgageObject({
        ...currentMortgageObject,
        BeneficiaryDetails: creditor,
      });

      setTimeout(() => {
        handleSubmit();
      }, 0);
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements).reverse();
      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  return (
    <>
      <form ref={formRef}>
        <Typography sx={{ mb: 2, mt: 2 }}>
          Geben Sie den Namen und die Adresse (ggf. Geburtsdatum) des Gläubigers
          (Kreditgebers) ein. Ist der Kreditgeber ein Unternehmen, geben Sie
          bitte den Firmennamen und die Adresse ein.
        </Typography>
        <FormControl component='fieldset'>
          <RadioGroup
            value={isPrivatePerson === null ? '' : isPrivatePerson?.toString()}
            onChange={handleRadioChange}
          >
            <Alert severity={'info'} icon={false} sx={{ mb: 2 }}>
              <FormControlLabel
                value='false'
                control={<Radio required />}
                label={
                  <Typography component='span'>
                    Gläubiger ist ein Unternehmen.
                  </Typography>
                }
              />
            </Alert>
            <Alert severity={'info'} icon={false}>
              <FormControlLabel
                value='true'
                control={<Radio required />}
                label={
                  <Typography component='span'>
                    Gläubiger ist eine Privatperson.
                  </Typography>
                }
              />
            </Alert>
          </RadioGroup>
        </FormControl>
        <Box sx={{ mt: 6 }} />
        {isPrivatePerson && (
          <GridBaseData
            key={creditor.Postcode}
            personObject={creditor as IPerson}
            personArray={[]}
            setPersonObject={updateCreditor}
            disableContactInformations={true}
            showDeathSettings={false}
            forceDeathSettings={false}
            forceAdress={true}
            disableDeathDateWarning={true}
          />
        )}
        {!isPrivatePerson && (
          <CompanyModifiedBaseInformation
            key={currentMortgageObject.id}
            companyObject={creditor as ICompany}
            setCompanyObject={updateCreditor}
          />
        )}
        <Box sx={{ mt: 6 }} />
        <Typography sx={{ mb: 2 }}>
          Über welchen Betrag soll die Grundschuld bestellt werden?
        </Typography>
        <Grid item xs={6} sm={4}>
          <NumericFormat
            label='Betrag der Grundschuld'
            size='small'
            suffix='€'
            fixedDecimalScale
            decimalScale={2}
            thousandsGroupStyle='thousand'
            thousandSeparator='.'
            decimalSeparator=','
            fullWidth
            required
            value={currentMortgageObject.AmountMortgage}
            customInput={TextField}
            inputRef={inputRef}
            onValueChange={values => {
              const { floatValue } = values;
              const cursorPosition = inputRef.current?.selectionStart;
              setCurrentMortgageObject({
                ...currentMortgageObject,
                AmountMortgage: floatValue !== undefined ? floatValue : null,
              });
              setTimeout(() => {
                if (inputRef.current && cursorPosition) {
                  inputRef.current.setSelectionRange(
                    cursorPosition,
                    cursorPosition
                  );
                }
              }, 0);
            }}
            allowNegative={false}
          />
        </Grid>
        <Box sx={{ mt: 6 }} />
        <Typography sx={{ mb: 2 }}>
          Wie hoch sind die sog. Grundschuldzinsen (in %)?
        </Typography>
        <Grid item xs={6} sm={4}>
          <NumericFormat
            value={currentMortgageObject.MortgageInterest}
            suffix='%'
            decimalSeparator='.'
            label='Grundschuldzinsen'
            size='small'
            fullWidth
            required
            customInput={TextField}
            decimalScale={2}
            fixedDecimalScale={true}
            allowNegative={false}
            isAllowed={values => {
              const { floatValue } = values;

              if (floatValue && floatValue >= 100) {
                return false;
              }
              return true;
            }}
            onValueChange={values => {
              const { floatValue } = values;
              setCurrentMortgageObject({
                ...currentMortgageObject,
                MortgageInterest: floatValue !== undefined ? floatValue : null,
              });
            }}
          />
        </Grid>
        <Box sx={{ mt: 6 }} />
        <Typography sx={{ mb: 2 }}>Gibt es Nebenleistungen?</Typography>
        <Grid item xs={6} sm={4}>
          <TextField
            rows={3}
            multiline
            label='Nebenleistungen'
            size='small'
            fullWidth
            value={currentMortgageObject.AdditionalServices}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setCurrentMortgageObject({
                ...currentMortgageObject,
                AdditionalServices: event.target.value,
              })
            }
          />
        </Grid>
        <Box sx={{ mt: 6 }}>
          <Typography sx={{ mb: 2 }}>
            Welche Abschriften soll der Gläubiger erhalten?
          </Typography>
          <FormGroup>
            {transcriptOptions.map(option => (
              <FormControlLabel
                key={option}
                control={
                  <Checkbox
                    checked={checkedOptions[option]}
                    onChange={handleChange}
                    name={option}
                  />
                }
                label={getLabelForOption(option)} // Diese Funktion liefert das Label je nach Option
              />
            ))}
          </FormGroup>
        </Box>
        <Box sx={{ mt: 6 }} />
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            variant='outlined'
            onClick={() => handleCancel()}
            disabled={
              JSON.stringify(mortgageObject) ===
              JSON.stringify(currentMortgageObject)
            }
            sx={{ mr: 1 }}
          >
            Abbrechen
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button
            onClick={() => {
              handleOnSave();
            }}
            disabled={
              JSON.stringify(mortgageObject) ===
              JSON.stringify(currentMortgageObject)
            }
            variant={'contained'}
          >
            Übernehmen
          </Button>
        </Box>
      </form>
    </>
  );
};
