import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { AddRealEstateInventoryOption } from '../InventoryOption/AddRealEstateInventoryOption';
import { RealEstateInventoryOptionRow } from '../InventoryOption/RealEstateInventoryOptionRow';
import { IRealEstateHasInventoryOption } from '../../../../Interfaces/IRealEstateHasInventoryOption';
import { IInventoryOption } from '../../../../Interfaces/IInventoryOption';

interface IProps {
  idRealEstate: number;
  realEstateHasInventroyOptionArray: IRealEstateHasInventoryOption[];
  setRealEstateHasInventroyOptionArray: Function;
  inventoryOptionArray: IInventoryOption[];
  marginTop?: number;
}

export const RealEstateInventoryOptions: React.FC<IProps> = props => {
  return (
    <>
      <Typography
        variant='h5'
        sx={{ mt: props.marginTop === undefined ? 2 : props.marginTop, mb: 1 }}
      >
        Anlagen
        <br />
        <Typography>
          Neben dem eigentlichen Grundstück können ebenfalls{' '}
          <u>Inventar und Anlagen mitverkauft</u> werden. Bitte erfassen Sie
          sämtliches Inventar (Einbauküche, Möbel etc.) und Anlagen
          (Fotovoltaikanlagen, Bootssteg etc.), welche mitverkauft werden.
        </Typography>
      </Typography>

      <AddRealEstateInventoryOption
        idRealEstate={props.idRealEstate}
        inventoryOptionArray={props.inventoryOptionArray}
        realEstateHasInventroyOptionArray={
          props.realEstateHasInventroyOptionArray
        }
        setRealEstateHasInventroyOptionArray={
          props.setRealEstateHasInventroyOptionArray
        }
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Anzahl</TableCell>
            <TableCell>Bezeichnung</TableCell>
            <TableCell>Beschreibung</TableCell>
            <TableCell>Aktionen</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.realEstateHasInventroyOptionArray.map(x => (
            <RealEstateInventoryOptionRow
              key={`idInventoryOption-${x.idInventoryOption}`}
              currentObject={x}
              inventoryOptionArray={props.inventoryOptionArray}
              realEstateHasInventroyOptionArray={
                props.realEstateHasInventroyOptionArray
              }
              setRealEstateHasInventroyOptionArray={
                props.setRealEstateHasInventroyOptionArray
              }
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
};
