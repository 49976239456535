import React, { useState } from 'react';
import { useFetch } from '../hooks/useFetch';
import {
  Alert,
  Dialog,
  DialogContent,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { CustomCircularProgress } from '../generic/CustomCircularProgress';
import { ShowWasSaved } from '../core/ShowWasSaved';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  deDE,
} from '@mui/x-data-grid';
import { ISystemMail } from '../Interfaces/ISystemMail';
import { SystemMailEdit } from './SystemMailEdit';
import { Box } from '@mui/system';
import { EmailSignatureEdit } from '../Admin/Settings/EmailSignatureEdit';
import { ISystemMailCategory } from '../Interfaces/ISystemMailCategory';
import { IDictionary } from '../Interfaces/IDictionary';
import { ISystemMail_has_Dictionary } from '../Interfaces/ISystemMail_has_Dictionary';
import { Check } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { State } from '../redux/mainReducer';

export const SystemMailOverview: React.FC = () => {
  const dictionaryEntryArray = useSelector(
    (state: State) => state.dictionaryEntryArray.storeDictionaryEntry
  );
  //
  const [mailArray, setMailArray, wasSuccessfullyMailArray] =
    useFetch<ISystemMail[]>('/systemmail');
  const [categoryArray, setCategoryArray, wasSuccessfullyCategoryArray] =
    useFetch<ISystemMailCategory[]>('/systemmailcategory');
  const [dictonaryArray, setDictonaryArray, wasSuccessfullyDictonary] =
    useFetch<IDictionary[]>('/dictionary');
  const [
    mailHasDictionaryArray,
    setMailHasDictionary,
    wasSuccessfullyDictonaryMailHasDictionary,
  ] = useFetch<ISystemMail_has_Dictionary[]>('/systemmail_has_dictionary');
  //
  const [showPublic, setShowPublic] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);
  //
  const [idSystemMail, setIdSystemMail] = useState<number | null>(null);
  //
  const [wasSaved, setWasSaved] = useState(false);
  const [wasSavedSuccesfully, setWasSavedSuccesfully] = useState(false);
  //
  const [scopeArray, setCcopeArray] = React.useState(
    localStorage.getItem('scope')?.split(',')
  );

  const columns: GridColDef[] = [
    { field: 'idSystemMail', headerName: 'Nr.', width: 90 },
    {
      field: 'idSystemMailCategory',
      headerName: 'Kategorie',
      valueGetter: (params: GridValueGetterParams) =>
        categoryArray?.find(
          x => x.idSystemMailCategory === params.row.idSystemMailCategory
        )?.SystemMailCategory,
      width: 200,
    },
    {
      field: 'isPublic',
      headerName: 'Öffentlicher Server',
      renderCell: params => (Boolean(params.row.isPublic) ? <Check /> : ''),
      width: 150,
    },
    {
      field: 'Variable',
      headerName: 'Variable',
      valueGetter: (params: GridValueGetterParams) =>
        mailHasDictionaryArray
          ?.filter(x => x.idSystemMail === params.row.idSystemMail)
          .map(dictonary =>
            dictionaryEntryArray === undefined
              ? 0
              : dictionaryEntryArray?.filter(
                  x => x.idDictionary === dictonary.idDictionary
                ).length
          )
          .reduce((a, b) => a + b, 0),

      width: 100,
    },
    { field: 'Title', headerName: 'Bezeichnung', flex: 1 },
  ];

  if (
    !wasSuccessfullyMailArray ||
    !wasSuccessfullyDictonary ||
    !wasSuccessfullyDictonaryMailHasDictionary
  ) {
    return <Alert severity='error'>Fehler!</Alert>;
  } else if (mailArray === undefined || mailHasDictionaryArray === undefined) {
    return <CustomCircularProgress />;
  } else {
    return (
      <>
        <ShowWasSaved
          wasSaved={wasSaved}
          setWasSaved={setWasSaved}
          wasSuccessfullySaved={wasSavedSuccesfully}
        />

        <Dialog
          open={idSystemMail !== null}
          onClose={() => setIdSystemMail(null)}
          maxWidth='lg'
          fullWidth
        >
          <DialogContent>
            {idSystemMail !== null && (
              <SystemMailEdit
                key={`idSystemMail-${idSystemMail}`}
                currentId={idSystemMail}
                setCurrentId={setIdSystemMail}
                currentArray={mailArray}
                setCurrentArray={setMailArray}
                setWasSaved={setWasSaved}
                setWasSavedSuccesfully={setWasSavedSuccesfully}
                systemMailHasDictionaryArray={mailHasDictionaryArray}
              />
            )}
          </DialogContent>
        </Dialog>

        <Typography variant='h4'>Systemnachrichten</Typography>

        {scopeArray !== undefined && scopeArray.indexOf('admin') > -1 && (
          <EmailSignatureEdit />
        )}

        <Typography variant='h5' sx={{ mt: 5 }}>
          E-Mail-Vorlagen
          <TextField
            label='Kategorie'
            size='small'
            select
            sx={{ float: 'right', width: 200 }}
            value={selectedCategory}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSelectedCategory(Number(event.target.value))
            }
          >
            <MenuItem key={`systemmailcategory-0`} value={0}>
              Alle
            </MenuItem>
            {categoryArray?.map(x => (
              <MenuItem
                key={`systemmailcategory-${x.idSystemMailCategory}`}
                value={x.idSystemMailCategory}
              >
                {x.SystemMailCategory}
              </MenuItem>
            ))}
          </TextField>
        </Typography>

        <Box sx={{ mt: 2 }} />
        <DataGrid
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          rows={mailArray
            .filter(x => x.isHidden == false)
            .filter(x =>
              selectedCategory === 0
                ? true
                : x.idSystemMailCategory === selectedCategory
            )}
          columns={columns}
          getRowId={row => row.idSystemMail}
          onRowClick={(params, event, details) =>
            setIdSystemMail(Number(params.id))
          }
        />
      </>
    );
  }
};
