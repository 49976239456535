import React, { useState } from 'react';
import { IUser } from '../../Interfaces/IUser';
import {
  ITimeRecording,
  ITimeRecordingFullObject,
} from '../../Interfaces/ITimeRecording';
import { ITimeRecordingBreak } from '../../Interfaces/ITimeRecordingBreak';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
} from '@mui/material';
import {
  castWithZeroTimezone,
  getHfromMinutes,
} from '../../core/generic_helper';
import { Add, Search } from '@mui/icons-material';
import { DataGrid, GridColDef, deDE } from '@mui/x-data-grid';
import { TimeRecordOwn } from '../TimeRecordOwn';

interface IProps {
  currentUser: IUser;
  timeRecordArray: ITimeRecording[];
  timeRecordBreakArray: ITimeRecordingBreak[];
}

export const TimeRecordEmployeeRow: React.FC<IProps> = props => {
  const [timeRecordArray, setTimeRecordArray] = useState(
    props.timeRecordArray.filter(x => x.idUser === props.currentUser.idUser)
  );
  const [timeRecordBreakArray, setTimeRecordBreakArray] = useState(() => {
    const testArray = timeRecordArray.map(x => x.idTimeRecording);
    return props.timeRecordBreakArray.filter(
      x => testArray.indexOf(x.idTimeRecording) > -1
    );
  });
  //
  const [isViewOpen, setIsViewOpen] = useState(false);
  //
  const [idTimeRecording, setIdTimeRecording] = useState<number | null>(null);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const columns: GridColDef[] = [
    { field: 'idTimeRecording', headerName: 'Nr.', width: 90 },
    {
      field: 'FromTime',
      headerName: 'Von',
      width: 250,
      renderCell: params => (
        <>
          {params.row.FromTime === null
            ? '-'
            : new Date(
                castWithZeroTimezone(params.row.FromTime)!
              ).toLocaleString()}
        </>
      ),
    },
    {
      field: 'ToTime',
      headerName: 'Bis',
      width: 250,
      renderCell: params => (
        <>
          {params.row.ToTime === null
            ? '-'
            : new Date(
                castWithZeroTimezone(params.row.ToTime)!
              ).toLocaleString()}
        </>
      ),
    },
    {
      field: 'Breaks',
      headerName: 'Pausen',
      width: 150,
      renderCell: params => (
        <>
          {
            timeRecordBreakArray.filter(x => x.idTimeRecording === params.id)
              .length
          }
        </>
      ),
    },
    {
      field: 'Total',
      headerName: 'Total',
      flex: 1,
      renderCell: params => (
        <>
          {params.row.Total === null ? '-' : getHfromMinutes(params.row.Total)}
        </>
      ),
    },
  ];

  const tsxTableCellWithBg = (
    currentValue: number,
    warningValueInHour?: number
  ) => {
    let currenBg: string | undefined = undefined;

    if (
      warningValueInHour !== undefined &&
      currentValue < warningValueInHour * 60
    ) {
      currenBg = '#FF7F7F';
    }

    return (
      <TableCell sx={{ backgroundColor: currenBg }}>
        {getHfromMinutes(currentValue)}
      </TableCell>
    );
  };

  const getCurrentDay = () => {
    const currentDate = new Date();
    const testObject = timeRecordArray.find(
      x => new Date(String(x.DateOfRecord)).getDate() === currentDate.getDate()
    );

    if (testObject === undefined || testObject.Total === null) {
      return tsxTableCellWithBg(0, 8);
    } else {
      return tsxTableCellWithBg(testObject.Total, 8);
    }
  };

  const getThisWeek = () => {
    let totalWekk = 0;

    const today = new Date();
    const dayOfWeek = today.getDay(); // Aktueller Wochentag (0 = Sonntag, 1 = Montag, ..., 6 = Samstag)
    const daysUntilMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Anzahl der Tage bis Montag
    const start = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - daysUntilMonday
    );
    start.setHours(0, 0, 0, 0);

    const end = new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate() + 6
    );
    end.setHours(23, 59, 59, 59);

    timeRecordArray
      .filter(
        x =>
          new Date(x.DateOfRecord) >= start && new Date(x.DateOfRecord) <= end
      )
      .map(x => (totalWekk = totalWekk + Number(x.Total)));

    return tsxTableCellWithBg(totalWekk, 40);
  };

  const getThisMonth = () => {
    let totalWekk = 0;

    const today = new Date();
    const start = new Date(today.getFullYear(), today.getMonth(), 1);
    start.setHours(0, 0, 0, 0);

    const end = new Date(start.getFullYear(), start.getMonth() + 1);
    end.setDate(end.getDate() - 1);
    end.setHours(23, 59, 59, 59);

    timeRecordArray
      .filter(
        x =>
          new Date(x.DateOfRecord) >= start && new Date(x.DateOfRecord) <= end
      )
      .map(x => (totalWekk = totalWekk + Number(x.Total)));

    return getHfromMinutes(totalWekk);
  };

  const getThisYear = () => {
    let total = 0;
    const today = new Date();

    timeRecordArray
      .filter(
        x => new Date(x.DateOfRecord).getFullYear() === today.getFullYear()
      )
      .map(x => (total = total + Number(x.Total)));
    return getHfromMinutes(total);
  };

  const saveWrapper = (savedObject: ITimeRecordingFullObject[]) => {
    if (idTimeRecording !== null) {
      const currentObject = savedObject[0];

      setTimeRecordArray([
        ...timeRecordArray.filter(
          x => x.idTimeRecording !== currentObject.idTimeRecording
        ),
        currentObject,
      ]);
      setTimeRecordBreakArray([
        ...timeRecordBreakArray.filter(
          x => x.idTimeRecording !== currentObject.idTimeRecording
        ),
        ...currentObject.TimeRecordBreakArray,
      ]);
      setIdTimeRecording(null);
    }
  };

  return (
    <>
      <Dialog
        open={isViewOpen}
        onClose={() => setIsViewOpen(false)}
        maxWidth='lg'
        fullWidth
      >
        <DialogTitle>
          Zeiterfassung: {props.currentUser.FirstName}{' '}
          {props.currentUser.LastName}
          <IconButton
            size='small'
            sx={{ float: 'right' }}
            onClick={() => setIdTimeRecording(-1)}
          >
            <Add />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DataGrid
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            rows={timeRecordArray}
            columns={columns}
            getRowId={row => row.idTimeRecording}
            autoHeight
            onRowClick={(params, event, details) =>
              setIdTimeRecording(Number(params.id))
            }
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setIsViewOpen(false)}>
            Schließen
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={idTimeRecording !== null}
        onClose={() => setIsOpenDialog(false)}
        maxWidth='lg'
        fullWidth
      >
        <DialogTitle>Zeiterfassung</DialogTitle>
        <DialogContent>
          {idTimeRecording !== null && (
            <TimeRecordOwn
              isBookCurrentDay={false}
              currentTimeRecordObjectId={idTimeRecording}
              idUser={props.currentUser.idUser}
              enableDateEdit
              timeRecordArray={[]}
              setTimeRecordArray={saveWrapper}
              setIsOpenDialog={() => setIdTimeRecording(null)}
            />
          )}
        </DialogContent>
      </Dialog>

      <TableRow>
        <TableCell>
          {props.currentUser.FirstName} {props.currentUser.LastName}
        </TableCell>
        {getCurrentDay()}
        {getThisWeek()}
        <TableCell>{getThisMonth()}</TableCell>
        <TableCell>{getThisYear()}</TableCell>
        <TableCell>
          <IconButton size='small' onClick={() => setIsViewOpen(true)}>
            <Search />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
