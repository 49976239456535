import React, { useState } from 'react';
import {
  Typography,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Box,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import {
  DataGrid,
  deDE,
  GridColDef,
  GridExpandMoreIcon,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import { NumericFormat } from 'react-number-format';
import { MapSearch } from './MapSearch';
import { IRealEstate } from '../../../../Interfaces/IRealEstate';
import { IPostcode } from '../../../../Interfaces/IPostcode';
import { useFetch } from '../../../../hooks/useFetch';
import { IPropertyType } from '../../../../Interfaces/IPropertyType';
import { IInventoryOption } from '../../../../Interfaces/IInventoryOption';
import { IRealEstateUseType } from '../../../../Interfaces/IRealEstateUseType';
import { CustomCircularProgress } from '../../../../generic/CustomCircularProgress';
import { PostcodeSearch } from '../../../../generic/PostcodeSearch';
import { ICourt } from '../../../../Interfaces/IAemter';
import { CourtSearch } from '../../../../generic/CourtSearch';

interface IProps {
  idRealEstate: number;
  setIdRealEstate: Function;
  handleClose: Function;
  realEstateArray: IRealEstate[];
  setRealEstateArray: Function;
}

export const searchRealEstate = (
  idRealEstate: number,
  realEstateArray: IRealEstate[],
  forceNew = false
) => {
  const foundObject = realEstateArray.find(
    x => x.idRealEstate === idRealEstate
  );
  if (forceNew === false && foundObject !== undefined) {
    return foundObject;
  } else {
    let tmpId = Math.min(...realEstateArray.map(x => x.idRealEstate)) - 1;

    if (tmpId >= 0) {
      tmpId = -1;
    }
    return {
      idRealEstate: tmpId,

      Street: null,
      StreetNr: null,
      StreetAditional: null,
      idPostcode: null,
      // Adresse ODER Latitide als Pflich
      Latitude: null,
      Longitude: null,

      idCourt: -1,
      LandRegister: '',
      SheetLandRegister: '',
      Parcel: '',
      LandParcel: '',
      idPropertyType: -1,
      isBuild: false,

      Price: 100000,
      PriceInventoryOption: null,

      idRealEstateUseType: 1,
      idPurchaseContract: -1,

      Defect: null,
      Particularities: null,

      Area: null,
      BuildWith: null,
      EasementText: null,
      hasApartment: false,
      hasEasement: false,
      hasPartialArea: false,
      isMeasured: false,
      Manager_idPerson: null,
      OrderMeasurement_idPurchaseContractRelationType: null,
      PaymentMeasurement_idPurchaseContractRelationType: null,
    } as IRealEstate;
  }
};

const handlePostcode = (localObject: IRealEstate) => {
  if (
    localObject.idPostcode != null &&
    localObject.idPostcode > 0 &&
    localObject.City != undefined &&
    localObject.Postcode != undefined
  ) {
    return {
      idPostcode: localObject.idPostcode,
      City: localObject.City,
      Postcode: localObject.Postcode,
      idCountry: 1,
    } as IPostcode;
  } else {
    return null;
  }
};

const columnsPropertyType: GridColDef[] = [
  { field: 'idPropertyType', headerName: 'ID', width: 90 },
  {
    field: 'PropertyType',
    headerName: 'Beschreibung',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 500,
  },
];

const columnsInventoryOption: GridColDef[] = [
  { field: 'idInventoryOption', headerName: 'ID', width: 90 },
  {
    field: 'InventoryOption',
    headerName: 'Beschreibung',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 500,
  },
];

export const PurchaseContractRealEstateEdit: React.FC<IProps> = props => {
  const [realEstate, setRealEstate] = useState(
    searchRealEstate(props.idRealEstate, props.realEstateArray)
  );
  const [postcodeObject, setPostcodeObject] = useState<
    IPostcode | null | undefined
  >(handlePostcode(realEstate));
  const [
    propertyTypeArray,
    setPropertyTypeArray,
    wasSuccessfullyPropertyTypeArray,
  ] = useFetch<IPropertyType[]>('/propertytype');
  const [
    inventoryOptionArray,
    setInventoryOption,
    wasSuccessfullyInventoryOption,
  ] = useFetch<IInventoryOption[]>('/inventoryoption');
  const [
    realEstateUseTypeArray,
    setRealEstateUseTypeArray,
    wasSuccessfullyRealEstateUseTypeArray,
  ] = useFetch<IRealEstateUseType[]>('/realestateusetype');

  const [selectedPropertyTypeRows, setSelectedPropertyTypeRows] = useState<
    IPropertyType[]
  >([]);
  const [selectedInventoryOptionRows, setSelectedInventoryOptionRows] =
    useState<IInventoryOption[]>([]);

  const onRowsSelectionHandlerPropertyType = (ids: GridRowSelectionModel) => {
    if (propertyTypeArray != undefined) {
      const tmpArray: IPropertyType[] = [];
      ids.map(currentId => {
        const foundObject = propertyTypeArray.find(
          x => x.idPropertyType === Number(currentId)
        );

        if (foundObject !== undefined) {
          tmpArray.push(foundObject);
        }
      });
      setSelectedPropertyTypeRows([...tmpArray]);
    }
  };

  const onRowsSelectionHandlerInventoryOption = (
    ids: GridRowSelectionModel
  ) => {
    if (inventoryOptionArray != undefined) {
      const tmpArray: IInventoryOption[] = [];
      ids.map(currentId => {
        const foundObject = inventoryOptionArray.find(
          x => x.idInventoryOption === Number(currentId)
        );

        if (foundObject !== undefined) {
          tmpArray.push(foundObject);
        }
      });
      setSelectedInventoryOptionRows([...tmpArray]);
    }
  };

  const handleAdd = () => {
    props.setRealEstateArray([...props.realEstateArray, realEstate]);
    props.setIdRealEstate(null);
  };

  const setPostcodeObjectWrapper = (
    postcodeObject: IPostcode | null | undefined
  ) => {
    if (postcodeObject != undefined) {
      setRealEstate({
        ...realEstate,
        idPostcode: postcodeObject.idPostcode,
        Postcode: postcodeObject.Postcode,
        City: postcodeObject.City,
      } as IRealEstate);
    }
    setPostcodeObject(postcodeObject);
  };

  const setDistrictCourtObjectWrapper = (
    districtCourtObject: ICourt | null | undefined
  ) => {
    if (districtCourtObject != undefined) {
      setRealEstate({
        ...realEstate,
        idCourt: districtCourtObject.idCourt,
      } as IRealEstate);
    }
    setPostcodeObject(postcodeObject);
  };

  if (
    !wasSuccessfullyInventoryOption ||
    !wasSuccessfullyPropertyTypeArray ||
    !wasSuccessfullyRealEstateUseTypeArray
  ) {
    return <>Fehler!</>;
  } else if (
    inventoryOptionArray === undefined ||
    propertyTypeArray === undefined ||
    realEstateUseTypeArray === undefined
  ) {
    return <CustomCircularProgress />;
  } else {
    return (
      <>
        <Typography sx={{ mt: 2, mb: 1 }}>
          Anschrift des Objektes
          <br />
          <Typography variant='caption'>
            Sie müssen entweder die Adresse oder Längen- und Breitengrade
            angeben. Auch beide Informationen können angebeben werden.
          </Typography>
        </Typography>
        <Grid container spacing={2}>
          <Grid item sm={5}>
            <TextField
              label='Straße'
              size='small'
              required={
                realEstate.Latitude === null && realEstate.Longitude === null
              }
              fullWidth
              value={realEstate.Street === null ? '' : realEstate.Street}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setRealEstate({
                  ...realEstate,
                  Street: event.target.value === '' ? null : event.target.value,
                })
              }
            />
          </Grid>
          <Grid item sm={5}>
            <TextField
              label='Adresszusatz'
              size='small'
              required={
                realEstate.Latitude === null && realEstate.Longitude === null
              }
              fullWidth
              value={
                realEstate.StreetAditional === null
                  ? ''
                  : realEstate.StreetAditional
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setRealEstate({
                  ...realEstate,
                  StreetAditional:
                    event.target.value === '' ? null : event.target.value,
                })
              }
            />
          </Grid>
          <Grid item sm={2}>
            <TextField
              label='Hausnummer'
              size='small'
              required={
                realEstate.Latitude === null && realEstate.Longitude === null
              }
              fullWidth
              value={realEstate.StreetNr === null ? '' : realEstate.StreetNr}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setRealEstate({
                  ...realEstate,
                  StreetNr:
                    event.target.value === '' ? null : event.target.value,
                })
              }
            />
          </Grid>
          <Grid item sm={12}>
            <PostcodeSearch
              postcodeObject={postcodeObject}
              setPostcodeObject={setPostcodeObjectWrapper}
              required={
                realEstate.Latitude === null && realEstate.Longitude === null
              }
            />
          </Grid>

          <Grid item sm={12}>
            <Box sx={{ mt: 1 }} />
          </Grid>

          <Grid item sm={6}>
            <TextField
              label='Breitengrad'
              size='small'
              required={realEstate.Longitude !== null}
              fullWidth
              value={realEstate.Latitude === null ? '' : realEstate.Latitude}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setRealEstate({
                  ...realEstate,
                  Latitude:
                    event.target.value === ''
                      ? null
                      : Number(event.target.value),
                })
              }
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              label='Längengrad'
              size='small'
              required={realEstate.Latitude !== null}
              fullWidth
              value={realEstate.Longitude === null ? '' : realEstate.Longitude}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setRealEstate({
                  ...realEstate,
                  Longitude:
                    event.target.value === ''
                      ? null
                      : Number(event.target.value),
                })
              }
            />
          </Grid>

          <Grid item sm={12}>
            <Box sx={{ mt: 1 }} />
          </Grid>

          <Grid item sm={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography>Adresse suchen</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <MapSearch
                  realEstate={realEstate}
                  setRealEstate={setRealEstate}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Typography sx={{ mt: 2, mb: 1 }}>Grundbuchdaten</Typography>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <CourtSearch
              setCourtObjectWrapper={setDistrictCourtObjectWrapper}
              courtId={realEstate.idCourt}
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              label='Grundbuch'
              size='small'
              required
              fullWidth
              value={realEstate.LandRegister}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setRealEstate({
                  ...realEstate,
                  LandRegister: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              label='Blatt'
              size='small'
              required
              fullWidth
              value={realEstate.SheetLandRegister}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setRealEstate({
                  ...realEstate,
                  SheetLandRegister: event.target.value,
                })
              }
            />
          </Grid>

          <Grid item sm={12}>
            <FormControlLabel
              sx={{ float: 'left' }}
              control={
                <Switch
                  checked={realEstate.isBuild}
                  onChange={() => {
                    setRealEstate({
                      ...realEstate,
                      isBuild: !realEstate.isBuild,
                    });
                  }}
                  name='gilad'
                />
              }
              label='Grundstück ist bebaut'
            />
          </Grid>

          {realEstate.isBuild && (
            <Grid item sm={12}>
              <TextField
                label='Bebeaut mit'
                size='small'
                rows={5}
                multiline
                fullWidth
                value={realEstate.BuildWith}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setRealEstate({
                    ...realEstate,
                    BuildWith: event.target.value,
                  })
                }
              />
            </Grid>
          )}

          <Grid item sm={4}>
            <NumericFormat
              value={realEstate.Price}
              suffix=' €'
              fixedDecimalScale
              thousandsGroupStyle='thousand'
              thousandSeparator='.'
              decimalSeparator=','
              fullWidth
              customInput={TextField}
              onValueChange={(values, sourceInfo) => {
                if (values.floatValue) {
                  setRealEstate({
                    ...realEstate,
                    Price: values.floatValue,
                  });
                }
              }}
              allowNegative={false}
              label='Kaufpreis'
              size='small'
            />
          </Grid>

          <Grid item sm={12}>
            <>
              <Typography>Grundstücksart</Typography>
              {propertyTypeArray != undefined && (
                <DataGrid
                  sx={{ mt: 2 }}
                  autoHeight
                  localeText={
                    deDE.components.MuiDataGrid.defaultProps.localeText
                  }
                  rows={propertyTypeArray}
                  columns={columnsPropertyType}
                  getRowId={row => row.idPropertyType}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  disableRowSelectionOnClick
                  checkboxSelection
                  onRowSelectionModelChange={ids =>
                    onRowsSelectionHandlerPropertyType(ids)
                  }
                />
              )}
            </>
          </Grid>

          <Grid item sm={12}>
            <>
              <Typography>Inventar</Typography>
              {inventoryOptionArray != undefined && (
                <DataGrid
                  sx={{ mt: 2 }}
                  autoHeight
                  localeText={
                    deDE.components.MuiDataGrid.defaultProps.localeText
                  }
                  rows={inventoryOptionArray}
                  columns={columnsInventoryOption}
                  getRowId={row => row.idInventoryOption}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  disableRowSelectionOnClick
                  checkboxSelection
                  onRowSelectionModelChange={ids =>
                    onRowsSelectionHandlerInventoryOption(ids)
                  }
                />
              )}
            </>
          </Grid>

          <Grid item sm={2}>
            <TextField
              label='Vermietet/Verpachtet'
              size='small'
              fullWidth
              required
              value={
                realEstate.idRealEstateUseType < 1
                  ? ''
                  : realEstate.idRealEstateUseType
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setRealEstate({
                  ...realEstate,
                  idRealEstateUseType: Number(event.target.value),
                })
              }
              select
            >
              {realEstateUseTypeArray !== undefined &&
                realEstateUseTypeArray?.map(x => (
                  <MenuItem
                    key={`idRealEstateUseType-${x.idRealEstateUseType}`}
                    value={x.idRealEstateUseType}
                  >
                    {x.RealEstateUseType}
                  </MenuItem>
                ))}
              )
            </TextField>
          </Grid>

          <Grid item sm={12}>
            <TextField
              label='Mängel'
              size='small'
              rows={5}
              multiline
              fullWidth
              value={realEstate.Defect}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setRealEstate({ ...realEstate, Defect: event.target.value })
              }
            />
          </Grid>

          <Grid item sm={12}>
            <TextField
              label='Besonderheiten'
              size='small'
              rows={5}
              multiline
              fullWidth
              value={realEstate.Particularities}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setRealEstate({
                  ...realEstate,
                  Particularities: event.target.value,
                })
              }
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 5, float: 'right' }}>
          <Button
            variant='outlined'
            onClick={() => {
              props.setIdRealEstate(null);
            }}
          >
            Abbruch
          </Button>
          <Button variant='contained' onClick={() => handleAdd()}>
            Übernehmen
          </Button>
        </Box>
      </>
    );
  }
};
