import React, { useEffect, useState } from 'react';
import { ILanguageEntry } from '../../../Interfaces/ILanguageEntry';
import { IconButton, TableCell, TableRow, TextField } from '@mui/material';
import { Delete } from '@mui/icons-material';

interface IProps {
  languageEntry: ILanguageEntry;
  languageEntryArray: ILanguageEntry[];
  setLanguageEntryArray: Function;
}

export const LanguageEntryRow: React.FC<IProps> = props => {
  const [languageEntry, setLanguageEntry] = useState(props.languageEntry);

  useEffect(() => {
    props.setLanguageEntryArray([
      ...props.languageEntryArray.map(x =>
        x.idLanguageEntry === languageEntry.idLanguageEntry ? languageEntry : x
      ),
    ]);
  }, [languageEntry]);

  const handleRemove = () => {
    props.setLanguageEntryArray([
      ...props.languageEntryArray.filter(
        x => x.idLanguageEntry !== languageEntry.idLanguageEntry
      ),
    ]);
  };

  return (
    <>
      <TableRow key={`row-languageEntry-${languageEntry.idLanguageEntry}`}>
        <TableCell>
          {languageEntry.idLanguageEntry > 0 ? (
            <>{languageEntry.KeyValue}</>
          ) : (
            <TextField
              label='Schlüssel'
              value={languageEntry.KeyValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setLanguageEntry({
                  ...languageEntry,
                  KeyValue: event.target.value,
                })
              }
              error={languageEntry.KeyValue === ''}
              size='small'
              fullWidth
            />
          )}
        </TableCell>
        <TableCell>
          <TextField
            value={languageEntry.de}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setLanguageEntry({
                ...languageEntry,
                de: event.target.value,
              })
            }
            error={languageEntry.de === ''}
            size='small'
            fullWidth
            multiline
          />
        </TableCell>
        <TableCell>
          <TextField
            value={languageEntry.en}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setLanguageEntry({
                ...languageEntry,
                en: event.target.value,
              })
            }
            error={languageEntry.en === ''}
            size='small'
            fullWidth
            multiline
          />
        </TableCell>
        <TableCell>
          <TextField
            value={languageEntry.fr}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setLanguageEntry({
                ...languageEntry,
                fr: event.target.value,
              })
            }
            error={languageEntry.fr === ''}
            size='small'
            fullWidth
            multiline
          />
        </TableCell>
        <TableCell>
          <IconButton
            onClick={handleRemove}
            disabled={languageEntry.idLanguageEntry > 0}
          >
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
