import React, { useState } from 'react';
import { Box, Tab } from '@mui/material';
import { DocumentAnalysisSectionView } from './DocumentAnalysisSectionView';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useDocumentAssistantContext } from '../DocumentAssistant/DocumentAssistantContext';

export const DocumentAnalysisMain = () => {
  const { documentAnalysisSections } = useDocumentAssistantContext();

  // ID for Grundbuch
  const documentAnalysisTypeId = 1;

  const [value, setValue] = useState(
    documentAnalysisSections
      .filter(
        section => section.idDocumentAnalysisType === documentAnalysisTypeId
      )[0]
      .idDocumentAnalysisSection.toString()
  );

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box>
      <TabContext value={value}>
        <Box>
          <TabList
            onChange={handleChange}
            variant='scrollable'
            scrollButtons='auto'
          >
            {documentAnalysisSections
              .filter(
                section =>
                  section.idDocumentAnalysisType === documentAnalysisTypeId
              )
              .map(section => (
                <Tab
                  key={`tab-${section.idDocumentAnalysisSection}`}
                  label={section.DocumentAnalysisSection}
                  value={section.idDocumentAnalysisSection.toString()}
                />
              ))}
          </TabList>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          {documentAnalysisSections
            .filter(
              section =>
                section.idDocumentAnalysisType === documentAnalysisTypeId
            )
            .map(section => (
              <TabPanel
                value={section.idDocumentAnalysisSection.toString()}
                sx={{ p: 0, pt: 2 }}
              >
                <DocumentAnalysisSectionView
                  key={`section-view-${section.idDocumentAnalysisSection}`}
                  documentAnalysisSection={section}
                />
              </TabPanel>
            ))}
        </Box>
      </TabContext>
    </Box>
  );
};
