import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItemText,
  MenuItem,
  TextField,
} from '@mui/material';
import { IContact } from '../Interfaces/IContact';
import { PostcodeSearch } from '../generic/PostcodeSearch';
import { IPostcode } from '../Interfaces/IPostcode';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import de from 'date-fns/locale/de';
import { ICompany } from '../Interfaces/ICompany';
import { useSelector } from 'react-redux';
import { State } from '../redux/mainReducer';
import { CustomCircularProgress } from '../generic/CustomCircularProgress';
import { Help, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { getIsEmail } from '../core/generic_helper';

interface IProps {
  currentContactObject: IContact | undefined;
  setCurrentContactObject: Function;
  companyObject: ICompany;
  setCompanyObject: Function;
}

const handlePostcode = (companyObject: ICompany) => {
  if (
    companyObject.idPostcode != null &&
    companyObject.idPostcode > 0 &&
    companyObject.City != undefined &&
    companyObject.Postcode != undefined
  ) {
    return {
      idPostcode: companyObject.idPostcode,
      City: companyObject.City,
      Postcode: companyObject.Postcode,
      idCountry: 1,
    } as IPostcode;
  } else {
    return null;
  }
};

////////////////////////////////////////////////////////////////////////////////////
export const ClientBaseData: React.FC<IProps> = props => {
  const contactGroupArray = useSelector(
    (state: State) => state.contactGroup.storeContactGroup
  );
  const genderArray = useSelector(
    (state: State) => state.genderArray.storeGender
  );
  const academicTitleArray = useSelector(
    (state: State) => state.academicTitleArray.storeAcademicTitle
  );
  ///////////////////////////////////////////////
  //Postcode
  const [postcodeObject, setPostcodeObject] = useState<
    IPostcode | null | undefined
  >(handlePostcode(props.companyObject));
  //
  const [isExpanted, setIsExpanted] = useState(false);
  //
  const [isOpenHelpEmail, setIsOpenHelpEmail] = useState(false);

  const setPostcodeObjectWrapper = (
    postcodeObject: IPostcode | null | undefined
  ) => {
    if (postcodeObject != undefined) {
      props.setCompanyObject({
        ...props.companyObject,
        idPostcode: postcodeObject.idPostcode,
        Postcode: postcodeObject.Postcode,
        City: postcodeObject.City,
      } as ICompany);
    }
    setPostcodeObject(postcodeObject);
  };
  ////////////////////////////////////////////////////

  ///////////////////////////////////////////////
  //Birthday
  const handleBirthday = (event: Date | null) => {
    if (props.currentContactObject != undefined) {
      if (typeof event === 'object' && event !== null) {
        const inputDate = new Date(event);
        const userTimezoneOffset = new Date(event).getTimezoneOffset() * 60000;

        props.setCurrentContactObject({
          ...props.currentContactObject,
          Birthday: new Date(inputDate.getTime() - userTimezoneOffset)
            .toISOString()
            .split('T')[0],
        });
      } else {
        props.setCurrentContactObject({
          ...props.currentContactObject,
          Birthday: null,
        });
      }
    }
  };
  ///////////////////////////////////////////////

  if (props.currentContactObject == undefined) {
    return <>Bitte Mandant auswählen!</>;
  } else if (
    props.currentContactObject == undefined ||
    props.companyObject == undefined
  ) {
    return (
      <>
        <Box sx={{ display: 'flex' }}>
          <CustomCircularProgress />
        </Box>
      </>
    );
  }
  return (
    <>
      <Dialog open={isOpenHelpEmail} onClose={() => setIsOpenHelpEmail(false)}>
        <DialogTitle>Hilfe zum Empfang von E-Mails</DialogTitle>
        <DialogContent>
          Der Empfang von E-Mails bestimmt, ob der Mandant Nachrichten aus
          Vorgängen erhält. Diese Nachrichten informieren den Mandanten über
          verschiedene wichtige Ereignisse, wie zum Beispiel angepasste
          Maßnahmen, hochgeladene Urkunden oder hinterlegte Kommentare.
          <List>
            <ListItemText>
              Standard: Wenn das Konto neu erstellt wird, ist der Empfang von
              E-Mails ausgeschaltet. Das bedeutet, dass der Mandant zunächst
              keine E-Mails aus Vorgängen bekommen.
            </ListItemText>
            <ListItemText>
              Automatische Aktivierung: Sobald der Mandant sich das erste Mal im
              Mandantenportal anmelden, wird der Empfang von E-Mails automatisch
              eingeschaltet.
            </ListItemText>
          </List>
          Hinweis: Sie können den Empfang von E-Mails jederzeit manuell
          anpassen, falls der Mandant entscheiden, dass er keine E-Mails mehr
          erhalten möchten.
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => setIsOpenHelpEmail(false)}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={2}>
        <Grid item xs={2}>
          <TextField
            select
            label='Akademischer Grad'
            size='small'
            value={props.currentContactObject.idAcademicTitle}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentContactObject({
                ...props.currentContactObject,
                idAcademicTitle: Number(event.target.value),
              })
            }
            fullWidth
          >
            {academicTitleArray.map(x => (
              <MenuItem
                key={`idAcademicTitle-${x.idAcademicTitle}`}
                value={x.idAcademicTitle}
              >
                {x.AcademicTitle}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={2}>
          <TextField
            select
            label='Geschlecht'
            size='small'
            value={props.currentContactObject.idGender}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentContactObject({
                ...props.currentContactObject,
                idGender: Number(event.target.value),
              })
            }
            fullWidth
          >
            {genderArray.map(x => (
              <MenuItem value={x.idGender}>{x.Gender}</MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={4}>
          <TextField
            label='Vorname'
            name='string-FirstName'
            size='small'
            error={props.currentContactObject.FirstName === ''}
            value={props.currentContactObject.FirstName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentContactObject({
                ...props.currentContactObject,
                FirstName: event.target.value,
              })
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            label='Nachname'
            name='string-LastName'
            size='small'
            value={props.currentContactObject.LastName}
            error={props.currentContactObject.LastName === ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentContactObject({
                ...props.currentContactObject,
                LastName: event.target.value,
              })
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            select
            label='Gruppe'
            size='small'
            value={
              props.currentContactObject.idContactGroup === null
                ? -1
                : props.currentContactObject.idContactGroup
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentContactObject({
                ...props.currentContactObject,
                idContactGroup:
                  Number(event.target.value) === -1
                    ? null
                    : Number(event.target.value),
              })
            }
            fullWidth
          >
            <MenuItem key={`idContactGroup-null}`} value={-1}>
              <i>Keine Gruppe zugeordnet</i>
            </MenuItem>
            {contactGroupArray.map(x => (
              <MenuItem
                key={`idContactGroup-${x.idContactGroup}`}
                value={x.idContactGroup}
              >
                {x.ContactGroup}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={8}>
          <TextField
            label='E-Mail-Adresse'
            name='string-Email'
            size='small'
            value={props.currentContactObject.Email}
            error={
              props.currentContactObject.Email === '' ||
              !getIsEmail(props.currentContactObject.Email)
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentContactObject({
                ...props.currentContactObject,
                Email: event.target.value,
              })
            }
            fullWidth
          />
        </Grid>

        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={props.currentContactObject.idContact < 0}
                inputProps={{ 'aria-label': 'controlled' }}
                checked={props.currentContactObject.isReceivingEmailEnabled}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  props.setCurrentContactObject({
                    ...props.currentContactObject,
                    isReceivingEmailEnabled: event.target.checked,
                  });
                }}
              />
            }
            label='E-Mails empfangen'
          />
          <IconButton onClick={() => setIsOpenHelpEmail(true)}>
            <Help />
          </IconButton>
        </Grid>

        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                disabled={props.currentContactObject.idContact < 0}
                checked={
                  props.currentContactObject.idContact < 0
                    ? props.currentContactObject.loginPossible
                    : props.currentContactObject.resetPassword === undefined
                      ? false
                      : props.currentContactObject.resetPassword
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.checked) {
                    props.setCurrentContactObject({
                      ...props.currentContactObject,
                      resetPassword: true,
                      passwordResetRequired: true,
                      loginPossible: true,
                    } as IContact);
                  } else {
                    props.setCurrentContactObject({
                      ...props.currentContactObject,
                      resetPassword: false,
                    } as IContact);
                  }
                }}
              />
            }
            label='Neues Passwort generieren'
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={
                  props.currentContactObject.idContact < 0 ||
                  (props.currentContactObject.resetPassword !== undefined &&
                    props.currentContactObject.resetPassword == true)
                }
                inputProps={{ 'aria-label': 'controlled' }}
                checked={
                  props.currentContactObject.idContact < 0
                    ? props.currentContactObject.loginPossible
                    : props.currentContactObject.passwordResetRequired
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  props.setCurrentContactObject({
                    ...props.currentContactObject,
                    passwordResetRequired: event.target.checked,
                  })
                }
              />
            }
            label='Mandant muss Passwort ändern'
          />
        </Grid>
        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={
                  props.currentContactObject.resetPassword !== undefined &&
                  props.currentContactObject.resetPassword == true
                }
                inputProps={{ 'aria-label': 'controlled' }}
                checked={props.currentContactObject.loginPossible}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.checked) {
                    props.setCurrentContactObject({
                      ...props.currentContactObject,
                      loginPossible: true,
                      passwordResetRequired: true,
                    } as IContact);
                  } else {
                    props.setCurrentContactObject({
                      ...props.currentContactObject,
                      loginPossible: false,
                    } as IContact);
                  }
                }}
              />
            }
            label='Login möglich'
          />
        </Grid>
      </Grid>

      <Card sx={{ mt: 3 }} variant='outlined'>
        <CardHeader
          title='Erweiterte Informationen'
          action={
            <IconButton>
              {isExpanted ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          }
          onClick={() => setIsExpanted(!isExpanted)}
        />
        <Collapse in={isExpanted}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label='Telefon'
                  name='string-PhoneNumber'
                  size='small'
                  value={props.currentContactObject.PhoneNumber}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.setCurrentContactObject({
                      ...props.currentContactObject,
                      PhoneNumber: event.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={de}
                >
                  <DesktopDatePicker
                    label='Geburtstag'
                    value={
                      props.currentContactObject.Birthday == undefined
                        ? null
                        : new Date(props.currentContactObject.Birthday)
                    }
                    onChange={(newValue: Date | null) => {
                      handleBirthday(newValue);
                    }}
                    slotProps={{
                      textField: { fullWidth: true, size: 'small' },
                    }}
                    openTo='year'
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label='Geburtsort'
                  name='string-Birthplace'
                  size='small'
                  value={props.currentContactObject.Birthplace}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.setCurrentContactObject({
                      ...props.currentContactObject,
                      Birthplace: event.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label='Straße'
                  name='string-street'
                  size='small'
                  value={props.companyObject.Street}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.setCompanyObject({
                      ...props.companyObject,
                      Street: event.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label='Hausnummer'
                  name='string-StreetNr'
                  size='small'
                  value={props.companyObject.StreetNr}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.setCompanyObject({
                      ...props.companyObject,
                      StreetNr: event.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label='Adresszusatz'
                  name='string-StreetAditional'
                  size='small'
                  value={props.companyObject.StreetAditional}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.setCompanyObject({
                      ...props.companyObject,
                      StreetAditional: event.target.value,
                    })
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <PostcodeSearch
                  postcodeObject={postcodeObject}
                  setPostcodeObject={setPostcodeObjectWrapper}
                  required={false}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};
