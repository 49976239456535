import React, { useState } from 'react';
import { IUser } from '../Interfaces/IUser';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  Typography,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { CustomeTextField } from '../generic/CustomeTextField';
import { uploadFetch } from '../hooks/useFetch';

interface IProps {
  userEdit: IUser;
  setUserEdit: Function;
}

interface IResultLogin {
  Successfully: boolean;
  PasswordShouldBeChanged: boolean;
}

export const UserAccounts: React.FC<IProps> = ({ userEdit, setUserEdit }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  //
  const [showReturnTestLogin, setShowReturnTestLogin] = useState(false);
  const [isAllInformations, setIsAllInformations] = useState(true);
  const [wasTestLoginSuccessfully, setWasTestLoginSuccessfully] =
    useState(true);
  const [passwordShouldChange, setPasswordShouldChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleResult = (returnObject: IResultLogin) => {
    setShowReturnTestLogin(true);
    setWasTestLoginSuccessfully(returnObject.Successfully);
    setPasswordShouldChange(returnObject.PasswordShouldBeChanged);
  };

  const handleCheckLogin = () => {
    setIsLoading(false);
    setShowReturnTestLogin(false);
    setWasTestLoginSuccessfully(true);
    setIsAllInformations(true);

    if (
      userEdit.LandRegister_Username === null ||
      userEdit.LandRegister_Username === '' ||
      userEdit.LandRegister_Editor === null ||
      userEdit.LandRegister_Editor === '' ||
      userEdit.LandRegister_PW == undefined
    ) {
      setShowReturnTestLogin(true);
      setWasTestLoginSuccessfully(false);
      setIsAllInformations(false);
    } else {
      const uploadObject = {
        ...userEdit,
        isBlocked: Boolean(userEdit.isBlocked),
        isNotary: Boolean(userEdit.isNotary),
        isUserEditEnabled: Boolean(userEdit.isNotary),
        ShowNotificationsAtLogin: Boolean(userEdit.ShowNotificationsAtLogin),
        Password: '',
      };
      setIsAllInformations(true);
      setIsLoading(true);
      uploadFetch(
        '/landregister/checklogin',
        true,
        uploadObject,
        handleResult,
        setWasTestLoginSuccessfully,
        setShowReturnTestLogin,
        setIsLoading
      );
    }
  };

  return (
    <div>
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            Grundbuch
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Zugang zur SolumWEB-Anbindung
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ m: 2 }}>
            <Collapse in={showReturnTestLogin}>
              {wasTestLoginSuccessfully ? (
                <Alert severity='success'>
                  <AlertTitle>
                    Die Zugangsdaten konnten erfolgreich überprüft werden!
                  </AlertTitle>
                </Alert>
              ) : passwordShouldChange ? (
                <Alert severity='info'>
                  <AlertTitle>Die Zugangsdaten sind abgelaufen!</AlertTitle>
                  Sie müssen in SolumWEB das Kennwort aktualisieren.
                  <br /> Kontaktieren Sie dafür einen Administrator.
                </Alert>
              ) : (
                <Alert severity='warning'>
                  <AlertTitle>
                    Die Zugangsdaten konnten nicht überprüft werden.
                  </AlertTitle>

                  {isAllInformations ? (
                    <>
                      Bitte kontrollieren Sie Ihre Zugangsdaten.
                      <br />
                      Bitte beachten Sie, dass Sie in SolumWEB nur eine
                      begrenzte Anzahl von Versuchen haben (i.d.R. 3 Versuche)!
                      <br />
                      Werden Ihre Versuche überschritten, wird Ihr Konto
                      gesperrt.
                    </>
                  ) : (
                    <>
                      Bitte stellen Sie sicher, dass Sie alle Informationen
                      angegeben haben!
                    </>
                  )}
                </Alert>
              )}
            </Collapse>
            <Collapse in={isLoading}>
              <Alert severity='info'>
                <AlertTitle>Zugangsdaten werden geprüft...</AlertTitle>
                Dies kann mehrere Minuten in Anspruch nehmen, bitte haben Sie
                Geduld!
              </Alert>
              <CircularProgress sx={{ mt: 2 }} />
            </Collapse>

            <Button
              sx={{ float: 'right', mt: 1 }}
              disabled={isLoading}
              variant='outlined'
              onClick={handleCheckLogin}
            >
              Zugangsdaten prüfen
            </Button>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomeTextField
                  label='Benutzerkennung'
                  attr='LandRegister_Username'
                  object={userEdit}
                  setObject={setUserEdit}
                  type='string'
                  null
                />
              </Grid>
              <Grid item xs={6}>
                <CustomeTextField
                  label='Bearbeiterkennzeichen'
                  attr='LandRegister_Editor'
                  object={userEdit}
                  setObject={setUserEdit}
                  type='string'
                  null
                />
              </Grid>
              <Grid item xs={12}>
                <CustomeTextField
                  label='Kennwort'
                  attr='LandRegister_PW'
                  object={
                    userEdit.LandRegister_PW === undefined
                      ? { ...userEdit, LandRegister_PW: '' }
                      : userEdit
                  }
                  setObject={(localUserEdit: IUser) =>
                    setUserEdit({
                      ...userEdit,
                      LandRegister_PW:
                        localUserEdit.LandRegister_PW === ''
                          ? undefined
                          : localUserEdit.LandRegister_PW,
                    })
                  }
                  type='password'
                />
              </Grid>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
