import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box } from '@mui/material';

interface IProps {
  isOpen: boolean;
  onClose: Function;
  children: React.ReactElement;
  title: string;
  customCloseBtnFunc?: Function;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const FullScreenDialog: React.FC<IProps> = props => {
  return (
    <Dialog
      fullScreen
      open={props.isOpen}
      onClose={() => props.onClose()}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative', height: '64px' }}>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            onClick={() => {
              if (props.customCloseBtnFunc) {
                props.customCloseBtnFunc();
              } else {
                props.onClose();
              }
            }}
            aria-label='close'
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            {props.title}
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ p: 2 }}>{props.children}</Box>
    </Dialog>
  );
};
