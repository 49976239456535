import {
  Alert,
  Button,
  CardContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { uploadFetch } from '../hooks/useFetch';
import { FSDirectorySearchRow } from './FSDirectorySearchRow';
import { IDocument } from '../Interfaces/IDocument';

interface IProps {
  searchItem: 'certificate' | 'invoice' | 'scans';
  searchArray: string[];
  documentArray: IDocument[];
  setDocumentArray: Function;
}

export const FSDirectorySearch: React.FC<IProps> = ({
  searchItem,
  searchArray,
  documentArray,
  setDocumentArray,
}) => {
  const [searchResult, setSearchResult] = useState<
    null | { FileName: string; Data: string }[]
  >(null);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [wasSuccessfully, setWasSuccessfully] = useState(true);

  const handelAfterSearch = (res: { FileName: string; Data: string }[]) => {
    setSearchResult(res);
  };

  const handleSearch = () => {
    if (searchArray.length !== 0) {
      setIsLoadingSearch(true);
      const uploadObject = {
        searchItem: searchItem,
        searchArray: searchArray,
      };
      uploadFetch(
        '/fsdirectory/searchfiles',
        true,
        uploadObject,
        handelAfterSearch,
        setWasSuccessfully,
        () => {},
        setIsLoadingSearch
      );
    }
  };

  if (searchArray.length === 0)
    return (
      <>
        <Alert sx={{ mt: 5 }} severity='info'>
          Fügen Sie Urkundennummern hinzu, sodass die verhandene Ordner
          automatisch durchsucht werden.
        </Alert>
      </>
    );
  else {
    return (
      <>
        <CardContent>
          <Typography variant='h6'>
            {/*Ordnersuche*/}
            <Button
              variant='outlined'
              sx={{ float: 'right' }}
              onClick={handleSearch}
              disabled={isLoadingSearch}
            >
              Suche
            </Button>
          </Typography>

          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell sx={{ width: 150 }}>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoadingSearch ? (
                <TableRow>
                  <TableCell colSpan={2}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : !wasSuccessfully ? (
                <TableRow>
                  <TableCell colSpan={2}>
                    <i>
                      Dieser Dienst steht derzeitig nicht zur Verfügung. Bitte
                      versuchen Sie es später erneut oder kontaktieren Sie einen
                      Administrator
                    </i>
                  </TableCell>
                </TableRow>
              ) : searchResult === null ? (
                <TableRow>
                  <TableCell colSpan={2}>
                    <i>Bitte Suche starten...</i>
                  </TableCell>
                </TableRow>
              ) : searchResult.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={2}>
                    <i>Keine Dokumente gefunden.</i>
                  </TableCell>
                </TableRow>
              ) : (
                searchResult.map((x, i) => (
                  <FSDirectorySearchRow
                    key={`search-file-${i}`}
                    resultElement={x}
                    documentArray={documentArray}
                    setDocumentArray={setDocumentArray}
                    searchResult={searchResult}
                    setSearchResults={setSearchResult}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </CardContent>
      </>
    );
  }
};
