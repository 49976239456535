import React, { useState } from 'react';
import { ILegalRoleTemplate } from '../../Interfaces/ILegalRoleTemplate';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Delete } from '@mui/icons-material';

interface IProps {
  idLegalRoleRemplate: number;
  setIdLegalRoleRemplate: Function;
  legalRoleTemplateArray: ILegalRoleTemplate[];
  setLegalRoleTemplateArray: Function;
}

const getLegalRole = (
  idLegalRoleRemplate: number,
  legalRoleTemplateArray: ILegalRoleTemplate[]
) => {
  const testObject = legalRoleTemplateArray.find(
    x => x.idLegalRoleTemplate === idLegalRoleRemplate
  );

  if (testObject !== undefined) {
    return testObject;
  } else {
    let minId =
      Math.min(...legalRoleTemplateArray.map(x => x.idLegalRoleTemplate)) - 1;

    if (minId >= -1) {
      minId = -2;
    }

    return {
      idLegalRoleTemplate: minId,
      idLegalTransactionType: -1,
      LegalRoleTemplate: '',
      canInvite: false,
      canUpload: false,
      viewCertificate: false,
      viewDataCollection: false,
      viewDate: false,
      viewDraft: false,
      viewInvoice: false,
      viewTransaction: false,
    } as ILegalRoleTemplate;
  }
};

export const LegalRoleTemplateEdit: React.FC<IProps> = props => {
  const [currentObject, setCurrentObject] = useState(
    getLegalRole(props.idLegalRoleRemplate, props.legalRoleTemplateArray)
  );
  const [editObject, setEditObject] = useState(currentObject);

  const handleSave = () => {
    const testObject = props.legalRoleTemplateArray.find(
      x => x.idLegalRoleTemplate === editObject.idLegalRoleTemplate
    );

    if (testObject === undefined) {
      props.setLegalRoleTemplateArray([
        ...props.legalRoleTemplateArray,
        editObject,
      ]);
    } else {
      props.setLegalRoleTemplateArray([
        ...props.legalRoleTemplateArray.map(x =>
          x.idLegalRoleTemplate === editObject.idLegalRoleTemplate
            ? editObject
            : x
        ),
      ]);
    }

    props.setIdLegalRoleRemplate(null);
  };

  const removeMe = () => {
    props.setLegalRoleTemplateArray([
      ...props.legalRoleTemplateArray.filter(
        x => x.idLegalRoleTemplate !== props.idLegalRoleRemplate
      ),
    ]);
    props.setIdLegalRoleRemplate(null);
  };

  return (
    <>
      <Typography variant='h6' sx={{ mb: 3 }}>
        {props.idLegalRoleRemplate === -1
          ? 'Neue Rolle'
          : currentObject.LegalRoleTemplate}
        {props.idLegalRoleRemplate !== -1 && (
          <IconButton sx={{ float: 'right' }} onClick={removeMe}>
            <Delete />
          </IconButton>
        )}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label='Bezeichnung'
            size='small'
            value={editObject.LegalRoleTemplate}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setEditObject({
                ...editObject,
                LegalRoleTemplate: event.target.value,
              })
            }
            error={editObject.LegalRoleTemplate === ''}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} />

        <Grid item xs={3}>
          Schreiben
        </Grid>
        <Grid item xs={9}>
          <FormControlLabel
            label='Einladen'
            control={
              <Checkbox
                checked={editObject.canInvite}
                onChange={() =>
                  setEditObject({
                    ...editObject,
                    canInvite: !editObject.canInvite,
                  })
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />
          <FormControlLabel
            label='Hochladen'
            control={
              <Checkbox
                checked={editObject.canUpload}
                onChange={() =>
                  setEditObject({
                    ...editObject,
                    canUpload: !editObject.canUpload,
                  })
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />
        </Grid>

        <Grid item xs={3}>
          Lesen
        </Grid>
        <Grid item xs={9}>
          <FormControlLabel
            label='Datenerfassung'
            control={
              <Checkbox
                checked={editObject.viewDataCollection}
                onChange={() =>
                  setEditObject({
                    ...editObject,
                    viewDataCollection: !editObject.viewDataCollection,
                  })
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />
          <FormControlLabel
            label='Entwurf'
            control={
              <Checkbox
                checked={editObject.viewDraft}
                onChange={() =>
                  setEditObject({
                    ...editObject,
                    viewDraft: !editObject.viewDraft,
                  })
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />
          <FormControlLabel
            label='Termine'
            control={
              <Checkbox
                checked={editObject.viewDate}
                onChange={() =>
                  setEditObject({
                    ...editObject,
                    viewDate: !editObject.viewDate,
                  })
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />
          <FormControlLabel
            label='Abwicklung'
            control={
              <Checkbox
                checked={editObject.viewTransaction}
                onChange={() =>
                  setEditObject({
                    ...editObject,
                    viewTransaction: !editObject.viewTransaction,
                  })
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />
          <FormControlLabel
            label='Urkunden'
            control={
              <Checkbox
                checked={editObject.viewCertificate}
                onChange={() =>
                  setEditObject({
                    ...editObject,
                    viewCertificate: !editObject.viewCertificate,
                  })
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />
          <FormControlLabel
            label='Rechnungen'
            control={
              <Checkbox
                checked={editObject.viewInvoice}
                onChange={() =>
                  setEditObject({
                    ...editObject,
                    viewInvoice: !editObject.viewInvoice,
                  })
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 3 }} />

      <Button
        sx={{ float: 'right' }}
        disabled={editObject.LegalRoleTemplate === ''}
        variant='contained'
        onClick={handleSave}
      >
        Übernehmen
      </Button>
      <Button
        sx={{ float: 'right', mr: 2 }}
        variant='outlined'
        onClick={() => props.setIdLegalRoleRemplate(null)}
      >
        Abbruch
      </Button>
    </>
  );
};
