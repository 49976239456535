import React, { useState } from 'react';
import { IDraftMessageFullObject } from '../../Interfaces/IDraftMessage';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { AddTask, Delete, Reply } from '@mui/icons-material';
import { blue, green, grey, red, yellow } from '@mui/material/colors';
import { DocumentRow } from '../../Document/DocumentRow';
import { useSelector } from 'react-redux';
import { State } from '../../redux/mainReducer';

interface IProps {
  draftMessageObject: IDraftMessageFullObject;
  draftMessageArray: IDraftMessageFullObject[];
  setDraftMessageArray: Function;
  setIdDraftMessageParent: Function;
  enableStateEdit?: boolean;
}

export const ViewDraftMessage: React.FC<IProps> = props => {
  const ownIdUser = localStorage.getItem('idUser');
  const ownIdContact = localStorage.getItem('idContact');

  const mimeTypeArray = useSelector(
    (state: State) => state.mimeTypeArray.storeAcademicTitle
  );

  const [isOpenChangeState, setIsOpenChangeState] = useState(false);
  const [editIdDraftMessageState, setEditIdDraftMessageState] = useState(
    props.draftMessageObject.idDraftMessageState
  );

  //
  const [flaotPaper, setFlaotPaper] = useState(
    props.draftMessageObject.idUser !== null &&
      ownIdUser !== null &&
      ownIdUser !== '' &&
      props.draftMessageObject.idUser === Number(ownIdUser)
      ? 'right'
      : props.draftMessageObject.idContact !== null &&
          ownIdContact !== null &&
          ownIdContact !== '' &&
          props.draftMessageObject.idContact === Number(ownIdContact)
        ? 'right'
        : 'left'
    //(props.draftMessageObject.idUser === null) ? "right" : "left"
  );

  const [flaotElement, setFlaotElement] = useState('right');
  // Größe
  const matchesSmall = useMediaQuery('(min-width:600px)');
  const matchesLarge = useMediaQuery('(min-width:1000px)');
  const matchesExtraLarge = useMediaQuery('(min-width:1500px)');
  // Partens
  const [parentObject, setParentObject] = useState(
    props.draftMessageObject.Partent_idDraftMessage === null
      ? undefined
      : props.draftMessageArray.find(
          x =>
            x.idDraftMessage === props.draftMessageObject.Partent_idDraftMessage
        )
  );

  const handleDelete = () => {
    props.setDraftMessageArray([
      ...props.draftMessageArray.filter(
        x => x.idDraftMessage !== props.draftMessageObject.idDraftMessage
      ),
    ]);
  };

  const handleOpenDialogStateChange = () => {
    setEditIdDraftMessageState(props.draftMessageObject.idDraftMessageState);
    setIsOpenChangeState(true);
  };

  const handleSaveChangeState = () => {
    const tmpObject = {
      ...props.draftMessageObject,
      idDraftMessageState: editIdDraftMessageState,
    };
    props.setDraftMessageArray([
      ...props.draftMessageArray.map(x =>
        x.idDraftMessage === tmpObject.idDraftMessage ? tmpObject : x
      ),
    ]);
    setIsOpenChangeState(false);
  };

  const getBcColor = () => {
    return props.draftMessageObject.idDraftMessageState === 1
      ? blue[50]
      : props.draftMessageObject.idDraftMessageState === 10
        ? blue[100]
        : props.draftMessageObject.idDraftMessageState === 20
          ? green[100]
          : props.draftMessageObject.idDraftMessageState === 30
            ? yellow[50]
            : red[50];
  };

  const setDate = (draftMessageObject: IDraftMessageFullObject) => {
    if (draftMessageObject.idDraftMessage < 0) {
      return new Date(
        String(props.draftMessageObject.Created_at_locale_time)
      ).toLocaleString();
    } else {
      return new Date(
        String(props.draftMessageObject.Created_at)
      ).toLocaleString();
    }
  };

  return (
    <>
      <Dialog
        open={isOpenChangeState}
        onClose={() => setIsOpenChangeState(false)}
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle>Status ändern</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mt: 2 }}
            label={'Status'}
            value={editIdDraftMessageState}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setEditIdDraftMessageState(Number(event.target.value))
            }
            size='small'
            fullWidth
            select
          >
            <MenuItem key='idDraftMessageState-1' value={1}>
              Neu
            </MenuItem>
            <MenuItem key='idDraftMessageState-10' value={10}>
              In Bearbeitung
            </MenuItem>
            <MenuItem key='idDraftMessageState-20' value={20}>
              Erledigt
            </MenuItem>
            <MenuItem key='idDraftMessageState-30' value={30}>
              Zurückgewiesen
            </MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={() => setIsOpenChangeState(false)}
          >
            Abbruch
          </Button>
          <Button variant='contained' onClick={handleSaveChangeState}>
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>

      <Paper
        elevation={3}
        sx={{
          backgroundColor: getBcColor(),
          minWidth: !matchesSmall
            ? 300
            : !matchesLarge
              ? 400
              : !matchesExtraLarge
                ? 700
                : 1200,
          float: flaotPaper,
        }}
      >
        <IconButton
          onClick={() =>
            props.setIdDraftMessageParent(
              props.draftMessageObject.idDraftMessage
            )
          }
          sx={{ float: flaotElement }}
          size='small'
        >
          <Reply />
        </IconButton>

        {props.draftMessageObject.idDraftMessage < 0 && (
          <IconButton
            onClick={handleDelete}
            sx={{ float: flaotElement }}
            size='small'
          >
            <Delete />
          </IconButton>
        )}

        {props.enableStateEdit && (
          <IconButton
            onClick={handleOpenDialogStateChange}
            sx={{ float: flaotElement }}
            size='small'
          >
            <AddTask />
          </IconButton>
        )}

        <br />
        <Box sx={{ ml: 2 }}>
          {parentObject !== undefined && (
            <>
              <Paper
                variant='outlined'
                sx={{ m: 2, backgroundColor: grey[50] }}
              >
                <Box sx={{ m: 2 }}>
                  Antwort zu:
                  <br />
                  <i>{parentObject.DraftMessage}</i>
                </Box>
              </Paper>
            </>
          )}

          {props.draftMessageObject.DraftMessage}

          {props.draftMessageObject.DocumentArray.length > 0 && (
            <>
              <Card
                variant='outlined'
                sx={{ mt: 5, mr: 2, backgroundColor: getBcColor() }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      Anhänge
                    </Grid>
                    {props.draftMessageObject.DocumentArray.map(x => (
                      <>
                        <Grid item xs={8} sm={10}>
                          {x.FileName}
                        </Grid>
                        <Grid item xs={4} sm={2}>
                          <DocumentRow
                            documentObject={x}
                            allowRemove={false}
                            documentArray={[]}
                            setDocumentArray={() => {}}
                            mimeTypeArray={mimeTypeArray}
                            justButtons={true}
                          />
                        </Grid>
                      </>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            </>
          )}
        </Box>

        <br />
        <Box sx={{ float: flaotElement, mr: 2 }}>
          <Typography variant='caption'>
            {flaotPaper === 'right' ? (
              <>Von mir</>
            ) : props.draftMessageObject.idUser === null ? (
              <>
                Von {props.draftMessageObject.ContactFirstName}{' '}
                {props.draftMessageObject.ContactLastName} (Mandant/in)
              </>
            ) : (
              <>
                Von {props.draftMessageObject.UserFirstName}{' '}
                {props.draftMessageObject.UserLastName} (Mitarbeiter/in)
              </>
            )}
          </Typography>
        </Box>
        <br />

        <Box sx={{ ml: 2, mb: 0 }}>
          {props.draftMessageObject.idDraftMessageState === 20 ? (
            <Typography variant='caption'>(Erledigt)</Typography>
          ) : (
            props.draftMessageObject.idDraftMessageState === 30 && (
              <Typography variant='caption'>(Zurückgewiesen)</Typography>
            )
          )}
          <Box sx={{ float: flaotElement, mr: 2 }}>
            <Typography variant='caption'>
              {setDate(props.draftMessageObject)}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </>
  );
};
