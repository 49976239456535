import React, { useEffect, useState } from 'react';
import { ITimeRecordingBreak } from '../../Interfaces/ITimeRecordingBreak';
import { IconButton, TableCell, TableRow, TextField } from '@mui/material';
import { Check, Clear, Delete } from '@mui/icons-material';

interface IProps {
  timeRecordingBreakObject: ITimeRecordingBreak;
  timeRecordingBreakArray: ITimeRecordingBreak[];
  setTimeRecordingBreakArray: Function;
}

export const TimeRecordBreakRow: React.FC<IProps> = props => {
  const [timeRecordingBreakObject, setIimeRecordingBreakObject] = useState(
    props.timeRecordingBreakObject
  );
  //
  const [totalValue, setTotalValue] = useState<string>(
    String(timeRecordingBreakObject.Total)
  );

  const handleTotalValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setTotalValue('');
      setIimeRecordingBreakObject({ ...timeRecordingBreakObject, Total: 0 });
    } else {
      let testNumberString = event.target.value;
      testNumberString = String(parseInt(testNumberString));

      if (Number(testNumberString) < 0) {
        testNumberString = '0';
      }

      setTotalValue(testNumberString);
      setIimeRecordingBreakObject({
        ...timeRecordingBreakObject,
        Total: Number(testNumberString),
      });
    }
  };

  const handleRemoveBerak = () => {
    props.setTimeRecordingBreakArray([
      ...props.timeRecordingBreakArray.filter(
        x =>
          x.idTimeRecordingBreak !==
          timeRecordingBreakObject.idTimeRecordingBreak
      ),
    ]);
  };

  useEffect(() => {
    props.setTimeRecordingBreakArray([
      ...props.timeRecordingBreakArray.map(x =>
        x.idTimeRecordingBreak === timeRecordingBreakObject.idTimeRecordingBreak
          ? timeRecordingBreakObject
          : x
      ),
    ]);
  }, [timeRecordingBreakObject]);

  return (
    <>
      <TableRow>
        <TableCell>
          {timeRecordingBreakObject.isRegular ? <Check /> : <Clear />}
        </TableCell>
        <TableCell>
          <TextField
            label='Bezeichnung'
            value={timeRecordingBreakObject.Title}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setIimeRecordingBreakObject({
                ...timeRecordingBreakObject,
                Title: event.target.value,
              })
            }
            fullWidth
            size='small'
          />
        </TableCell>
        <TableCell>
          <TextField
            label='Dauer (in Minuten)'
            type='number'
            value={totalValue}
            onChange={handleTotalValue}
            fullWidth
            size='small'
            error={totalValue === ''}
            InputProps={{ inputProps: { min: 0, max: 10 } }}
          />
        </TableCell>
        <TableCell>
          <IconButton
            onClick={handleRemoveBerak}
            disabled={timeRecordingBreakObject.isRegular}
          >
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
