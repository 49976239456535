import React, { useState } from 'react';
import {
  ILegalRequirementTemplate,
  ILegalRequirementTemplateFullObject,
} from '../Interfaces/ILegalRequirementTemplate';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { CopyAll } from '@mui/icons-material';
import { DataGrid, GridRowSelectionModel, deDE } from '@mui/x-data-grid';
import { columnsLegalRequirementTemplate } from './LegalTransactionTypeOverview';

interface IProps {
  idLegalPhaseTemplate: number;
  startId: number;
  setArray: Function;
  legalRequirementTemplateArray: ILegalRequirementTemplateFullObject[];
}

export const LegalPhaseCopyRequirement: React.FC<IProps> = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<GridRowSelectionModel | null>(
    null
  );

  const handleAdd = () => {
    if (selectedRow !== null) {
      const returnArray: ILegalRequirementTemplate[] = [];
      let tmpId = props.startId - 1;

      if (tmpId > 0) {
        tmpId = -1;
      }

      selectedRow.map((id, idx) => {
        const testObject = props.legalRequirementTemplateArray.find(
          x => x.idLegalRequirementTemplate === id
        );

        if (testObject !== undefined) {
          const tmpObject = {
            ...testObject,
            idLegalPhaseTemplate: props.idLegalPhaseTemplate,
            idLegalRequirementTemplate: tmpId - idx,
            currentProcessingTime: testObject.idLegalRequirementTemplate,
            isTemplate: false,
          };
          returnArray.push(tmpObject);
        }
      });
      props.setArray([...returnArray]);
    }
    setIsOpen(false);
  };

  if (props.legalRequirementTemplateArray === undefined) {
    return (
      <IconButton sx={{ float: 'right' }} size='small' onClick={() => {}}>
        <CircularProgress size={24}></CircularProgress>
      </IconButton>
    );
  }
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        maxWidth='lg'
        fullWidth
      >
        <DialogContent>
          <DialogTitle>Maßnahme kopieren</DialogTitle>
          <DataGrid
            localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
            rows={props.legalRequirementTemplateArray.filter(x => x.isTemplate)}
            columns={columnsLegalRequirementTemplate}
            getRowId={row => row.idLegalRequirementTemplate}
            checkboxSelection
            autoHeight
            onRowSelectionModelChange={(params: GridRowSelectionModel) =>
              setSelectedRow(params)
            }
            /*
                        onRowClick={(params,event,details) => {
                            let testObject = requirementArray.find(x => x.idLegalRequirementTemplate === Number(params.id));
                            if (testObject === undefined) {
                                setCurrentRequirementTemplate(null);
                            } else {
                                setCurrentRequirementTemplate(testObject);
                            }
                        }}
                        */
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setIsOpen(false)}>
            Schließen
          </Button>
          <Button variant='contained' onClick={handleAdd}>
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>

      <Tooltip title='Phase kopieren und einfügen'>
        <IconButton
          sx={{ float: 'right' }}
          size='small'
          onClick={() => {
            setSelectedRow(null);
            setIsOpen(true);
          }}
        >
          <CopyAll />
        </IconButton>
      </Tooltip>
    </>
  );
};
