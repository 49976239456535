import React, { useEffect, useState } from 'react';
import { ILegalPhaseTemplateFullObject } from '../Interfaces/ILegalPhaseTemplate';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { Add, ArrowDownward, ArrowUpward } from '@mui/icons-material';
import {
  ILegalRequirementTemplate,
  ILegalRequirementTemplateFullObject,
} from '../Interfaces/ILegalRequirementTemplate';
import { LegalRequirementRow } from './LegalRequirementRow';
import { ConfirmDelete } from '../core/ConfirmDelete';
import { LegalPhaseEdit } from './LegalPhaseEdit';
import { LegalPhaseCopyRequirement } from './LegalPhaseCopyRequirement';
import { IAdminstrativeUnit, IFederalState } from '../Interfaces/IAemter';
import { ILegalRequirementTemplateDeadline } from '../Interfaces/ILegalRequirementTemplate_deadlines';
import { LegalRequirementEdit } from './LegalRequirementEdit';

interface IProps {
  currentPhase: ILegalPhaseTemplateFullObject;
  legalPhaseTemplateArray: ILegalPhaseTemplateFullObject[];
  setLegalPhaseTemplateArray: Function;
  legalRequirementTemplateArray: ILegalRequirementTemplate[];
  adminUnitArray: IAdminstrativeUnit[];
  federalStateArray: IFederalState[];
}

const getEmptyReqTemplate = (currentPhase: ILegalPhaseTemplateFullObject) => {
  let tmpId = -1;

  if (currentPhase.LegalRequirementArray.length > 0) {
    const tmpIdArry = Math.min(
      ...currentPhase.LegalRequirementArray.map(
        x => x.idLegalRequirementTemplate
      )
    );

    if (tmpIdArry <= tmpId) {
      tmpId = tmpIdArry - 1;
    }
  }

  //const tmpObject = { ...currentPhase };
  const tmpObject: ILegalRequirementTemplateFullObject = {
    idLegalPhaseTemplate: -1,
    idLegalRequirementTemplate: tmpId,
    canUploadFilesClient: false,
    canUploadFilesEmployees: false,
    hasError: false,
    hasErrorFreeText: false,
    LegalRequirementTemplate: '',
    sendMail: false,
    EmailBody: null,
    EmailSubject: null,
    Predecessor_idLegalRequirementTemplate: null,
    TextOfError: null,
    Position:
      currentPhase.LegalRequirementArray.length === 0
        ? 1
        : Math.max(...currentPhase.LegalRequirementArray.map(x => x.Position)) +
          1,
    idLegalRequirementState: 10,
    idUser: null,
    isTemplate: false,
    InfoText: null,
    //Deadline: undefined,
    DeadlineArray: [],
    initialProcessingTime: 14,
    currentProcessingTime: null,
    idCourt: null,
    idCourtRole: null,
    Court: null,
    CourtRole: null,
  };
  return tmpObject;

  /*
  props.setLegalPhaseTemplateArray([
    ...props.legalPhaseTemplateArray.map(x =>
      x.idLegalPhaseTemplate === tmpObject.idLegalPhaseTemplate
        ? tmpObject
        : x
    ),
  ]);
  */
};

export const LegalPhaseRow: React.FC<IProps> = props => {
  const [currentPhaseEdit, setCurrentPhaseEdit] = useState(props.currentPhase);
  const [currentPreconditionArray, setCurrentPreconditionArray] = useState(
    props.currentPhase.PreconditionArray
  );
  //
  //const [isOpenEdit, setIsOpenEdit] = useState(props.currentPhase.idLegalPhaseTemplate < 0 && props.currentPhase.LegalPhaseTemplate === "");
  const [isOpenNew, setIsOpenNew] = useState(false);
  const [newLegalReq, setNewLegalReq] = useState(
    getEmptyReqTemplate(props.currentPhase)
  );

  const updateArry = (
    localLegalReqArray: ILegalRequirementTemplateFullObject[]
  ) => {
    const tmpObject = { ...props.currentPhase };
    tmpObject.LegalRequirementArray = localLegalReqArray;
    props.setLegalPhaseTemplateArray([
      ...props.legalPhaseTemplateArray.map(x =>
        x.idLegalPhaseTemplate === tmpObject.idLegalPhaseTemplate
          ? tmpObject
          : x
      ),
    ]);
  };
  const updateCopyArray = (
    localLegalReqArray: ILegalRequirementTemplateFullObject[]
  ) => {
    const newArray: ILegalRequirementTemplateFullObject[] = [];
    const maxPosition =
      Math.max(
        ...props.currentPhase.LegalRequirementArray.map(x => x.Position)
      ) + 1;

    localLegalReqArray.forEach((x, i) => {
      x.Position = maxPosition + i;
      const newDeadlineArray: ILegalRequirementTemplateDeadline[] = [];
      x.DeadlineArray?.forEach(deadline => {
        if (
          deadline.idLegalRequirementTemplate !== null &&
          deadline.idLegalRequirementTemplate !== undefined
        ) {
          if (
            props.legalPhaseTemplateArray.some(
              legalPhase =>
                legalPhase.Position <= props.currentPhase.Position &&
                legalPhase.LegalRequirementArray.some(
                  legalReq =>
                    legalReq.idLegalRequirementTemplate ===
                    deadline.idLegalRequirementTemplate
                )
            )
          ) {
            newDeadlineArray.push({
              ...deadline,
            });
          }
        } else if (
          deadline.idLegalPhaseTemplate !== null &&
          deadline.idLegalPhaseTemplate !== undefined
        ) {
          if (
            props.legalPhaseTemplateArray.some(
              legalPhase =>
                legalPhase.Position <= props.currentPhase.Position &&
                legalPhase.idLegalPhaseTemplate ===
                  deadline.idLegalPhaseTemplate
            )
          ) {
            newDeadlineArray.push({
              ...deadline,
            });
          }
        } else if (
          deadline.idLegalTransactionState !== null &&
          deadline.idLegalTransactionState !== undefined
        ) {
          newDeadlineArray.push({
            ...deadline,
          });
        }
      });
      newArray.push(x);
    });
    updateArry([...props.currentPhase.LegalRequirementArray, ...newArray]);
  };

  const isFirstDisabled = () => {
    const currentIndex: number = props.legalPhaseTemplateArray
      .map(x => x.idLegalPhaseTemplate)
      .indexOf(props.currentPhase.idLegalPhaseTemplate);
    return currentIndex === 0;
  };
  const isLastDisabled = () => {
    const currentIndex: number = props.legalPhaseTemplateArray
      .map(x => x.idLegalPhaseTemplate)
      .indexOf(props.currentPhase.idLegalPhaseTemplate);
    return currentIndex === props.legalPhaseTemplateArray.length - 1;
  };

  const getUp = () => {
    const copyOfArray = [...props.legalPhaseTemplateArray];
    const targetPositionn = currentPhaseEdit.Position - 1;
    const toChangeObject = copyOfArray.find(
      x => x.Position === targetPositionn
    );
    const currentObject = {
      ...currentPhaseEdit,
      Position: currentPhaseEdit.Position - 1,
    };

    if (toChangeObject !== undefined) {
      const currentIndex = props.legalPhaseTemplateArray
        .map(x => x.idLegalPhaseTemplate)
        .indexOf(currentPhaseEdit.idLegalPhaseTemplate);
      const targetIndex = props.legalPhaseTemplateArray
        .map(x => x.idLegalPhaseTemplate)
        .indexOf(toChangeObject.idLegalPhaseTemplate);
      copyOfArray[currentIndex].Position =
        copyOfArray[currentIndex].Position - 1;
      copyOfArray[targetIndex].Position = copyOfArray[targetIndex].Position + 1;

      setCurrentPhaseEdit(currentObject);
      props.setLegalPhaseTemplateArray(copyOfArray);
    }
  };

  const getDown = () => {
    const copyOfArray = [...props.legalPhaseTemplateArray];
    const targetPositionn = currentPhaseEdit.Position + 1;
    const toChangeObject = copyOfArray.find(
      x => x.Position === targetPositionn
    );
    const currentObject = {
      ...currentPhaseEdit,
      Position: currentPhaseEdit.Position + 1,
    };

    if (toChangeObject !== undefined) {
      const currentIndex = props.legalPhaseTemplateArray
        .map(x => x.idLegalPhaseTemplate)
        .indexOf(currentPhaseEdit.idLegalPhaseTemplate);
      const targetIndex = props.legalPhaseTemplateArray
        .map(x => x.idLegalPhaseTemplate)
        .indexOf(toChangeObject.idLegalPhaseTemplate);

      copyOfArray[currentIndex].Position =
        copyOfArray[currentIndex].Position + 1;
      copyOfArray[targetIndex].Position = copyOfArray[targetIndex].Position - 1;

      setCurrentPhaseEdit(currentObject);
      props.setLegalPhaseTemplateArray(copyOfArray);
    }
  };

  const handleDelete = () => {
    props.setLegalPhaseTemplateArray([
      ...props.legalPhaseTemplateArray.filter(
        x => x.idLegalPhaseTemplate !== props.currentPhase.idLegalPhaseTemplate
      ),
    ]);
  };

  const handleNewReq = () => {
    setNewLegalReq(getEmptyReqTemplate(props.currentPhase));
    setIsOpenNew(true);
  };
  const handleAddNewReq = () => {
    updateArry([...props.currentPhase.LegalRequirementArray, newLegalReq]);
    setIsOpenNew(false);
  };

  useEffect(() => {
    props.setLegalPhaseTemplateArray([
      ...props.legalPhaseTemplateArray.map(x =>
        x.idLegalPhaseTemplate === currentPhaseEdit.idLegalPhaseTemplate
          ? currentPhaseEdit
          : x
      ),
    ]);
  }, [currentPhaseEdit]);

  return (
    <>
      <Dialog
        open={isOpenNew}
        onClose={() => setIsOpenNew(false)}
        maxWidth='lg'
        fullWidth
      >
        <DialogTitle>Voraussetzung anpassen</DialogTitle>
        <DialogContent>
          <LegalRequirementEdit
            currentRequirement={newLegalReq}
            setCurrentRequirement={setNewLegalReq}
            legalRequirements={props.currentPhase.LegalRequirementArray}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setIsOpenNew(false)}>
            Abbruch
          </Button>
          <Button
            variant='contained'
            onClick={handleAddNewReq}
            disabled={newLegalReq.LegalRequirementTemplate === ''}
          >
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>

      <TableRow>
        <TableCell colSpan={2}>
          {props.currentPhase.LegalPhaseTemplate}
        </TableCell>
        <TableCell>
          {props.currentPhase.PreconditionArray.map(x =>
            props.legalPhaseTemplateArray
              .filter(y => y.idLegalPhaseTemplate === x.idLegalPhaseTemplate)
              .map(y => (
                <>
                  <Typography sx={{ mr: 3 }} variant='caption'>
                    {y.LegalPhaseTemplate}
                  </Typography>
                  <br />
                </>
              ))
          )}
        </TableCell>
        <TableCell>
          <Tooltip title='Phase nach unten verschieben'>
            <span>
              <IconButton
                sx={{ float: 'right' }}
                size='small'
                disabled={isLastDisabled()}
                onClick={getDown}
              >
                <ArrowDownward />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title='Phase nach oben verschieben'>
            <span>
              <IconButton
                sx={{ float: 'right' }}
                size='small'
                disabled={isFirstDisabled()}
                onClick={getUp}
              >
                <ArrowUpward />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title='Neue Maßnahme hinzufügen'>
            <IconButton
              sx={{ float: 'right' }}
              size='small'
              onClick={handleNewReq}
            >
              <Add />
            </IconButton>
          </Tooltip>

          <LegalPhaseCopyRequirement
            idLegalPhaseTemplate={props.currentPhase.idLegalPhaseTemplate}
            startId={Math.min(
              ...props.currentPhase.LegalRequirementArray.map(
                x => x.idLegalRequirementTemplate
              )
            )}
            setArray={updateCopyArray}
            legalRequirementTemplateArray={props.legalRequirementTemplateArray}
          />
        </TableCell>
        <TableCell sx={{ width: 100 }} />
        <TableCell sx={{ width: 100 }} />
        <TableCell sx={{ width: 100 }} />
        <TableCell sx={{ width: 150 }}>
          <LegalPhaseEdit
            currentPhase={props.currentPhase}
            currentPhaseEdit={currentPhaseEdit}
            setCurrentPhaseEdit={setCurrentPhaseEdit}
            legalPhaseTemplateArray={props.legalPhaseTemplateArray}
            setLegalPhaseTemplateArray={props.setLegalPhaseTemplateArray}
          />
          <ConfirmDelete
            itemText='die aktulle Phase und deren Bedingungen'
            functionToDelete={handleDelete}
          />
        </TableCell>
      </TableRow>

      {props.currentPhase.LegalRequirementArray.sort((a, b) =>
        a.Position < b.Position ? -1 : 1
      ).map(x => (
        <LegalRequirementRow
          key={`idLegalRequirementTemplate-${x.idLegalRequirementTemplate}`}
          currentRequirement={x}
          legalRequirementArray={props.currentPhase.LegalRequirementArray}
          setLegalRequirementArray={updateArry}
          legalPhaseTemplateArray={props.legalPhaseTemplateArray}
        />
      ))}
    </>
  );
};
