import React from 'react';
import { useFetch } from '../../hooks/useFetch';
import { IUser } from '../../Interfaces/IUser';
import { ITimeRecording } from '../../Interfaces/ITimeRecording';
import { ITimeRecordingBreak } from '../../Interfaces/ITimeRecordingBreak';
import {
  Alert,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from '@mui/material';
import { TimeRecordEmployeeRow } from './TimeRecordEmployeeRow';

export const TimeRecordOverview: React.FC = () => {
  const [userArray, setUserArray, wasSuccessfullyUserArray] =
    useFetch<IUser[]>('/user');
  const [timeRecordArray, setTimeRecordArray, wasSuccessfullyTimeRecordArray] =
    useFetch<ITimeRecording[]>('/timerecording/getAll');
  const [
    timeRecordBreakArray,
    setTimeRecordBreakArray,
    wasSuccessfullyTimeRecordBreakArray,
  ] = useFetch<ITimeRecordingBreak[]>('/timerecordingbreak');

  if (
    !wasSuccessfullyUserArray ||
    !wasSuccessfullyTimeRecordArray ||
    !wasSuccessfullyTimeRecordBreakArray
  ) {
    return <Alert severity='error'>Ein Fehler ist aufgetretten!</Alert>;
  } else if (
    userArray === undefined ||
    timeRecordArray === undefined ||
    timeRecordBreakArray === undefined
  ) {
    return <CircularProgress />;
  } else {
    return (
      <>
        <Typography variant='h5'>Zeiterfassung</Typography>

        <Table>
          <TableHead>
            <TableCell>Mitarbeiter</TableCell>
            <TableCell>Heute</TableCell>
            <TableCell>Woche</TableCell>
            <TableCell>Monat</TableCell>
            <TableCell>Jahr</TableCell>
            <TableCell>Aktionen</TableCell>
          </TableHead>

          <TableBody>
            {userArray.map(x => (
              <TimeRecordEmployeeRow
                key={`timerecord-idUser-${x.idUser}`}
                currentUser={x}
                timeRecordArray={timeRecordArray}
                timeRecordBreakArray={timeRecordBreakArray}
              />
            ))}
          </TableBody>
        </Table>
      </>
    );
  }
};
