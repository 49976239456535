import { Grid, CircularProgress } from '@mui/material';

interface IProps {
  size?: number;
}

export const CustomCircularProgress: React.FC<IProps> = ({ size }) => {
  return (
    <>
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '50vh' }}
      >
        <Grid item xs={3}>
          <CircularProgress size={size === undefined ? 300 : size} />
        </Grid>
      </Grid>
    </>
  );
};
