import React from 'react';
import { ILegalRoleTemplate } from '../../Interfaces/ILegalRoleTemplate';
import { ILegalRoleTemplate_canInvite } from '../../Interfaces/ILegalRoleTemplate_canInvite';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { LegalRoleTemplateCanInviteRow } from './LegalRoleTemplateCanInviteRow';

interface IProps {
  legalRoleTemplateArray: ILegalRoleTemplate[];
  legalRoleTemplateCanInviteArray: ILegalRoleTemplate_canInvite[];
  setLegalRoleTemplateCanInviteArray: Function;
}

export const LegalRoleTemplateCanInviteTable: React.FC<IProps> = props => {
  const contextTsx = () => {
    const filteredArray = props.legalRoleTemplateArray.filter(x =>
      Boolean(x.canInvite)
    );

    if (filteredArray.length === 0) {
      return (
        <>
          <i>Keine Rollen mit Einladeerlaubnis vorhanden.</i>
        </>
      );
    } else {
      return (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Rolle</TableCell>
                <TableCell>Darf Zuweisen</TableCell>
                <TableCell sx={{ width: 100 }}>Aktionen</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredArray.map(x => (
                <LegalRoleTemplateCanInviteRow
                  key={`idLegalRoleTemplate-${x.idLegalRoleTemplate}`}
                  legalRoleTemplateObject={x}
                  legalRoleTemplateArray={props.legalRoleTemplateArray}
                  legalRoleTemplateCanInviteArray={
                    props.legalRoleTemplateCanInviteArray
                  }
                  setLegalRoleTemplateCanInviteArray={
                    props.setLegalRoleTemplateCanInviteArray
                  }
                />
              ))}
            </TableBody>
          </Table>
        </>
      );
    }
  };

  return (
    <>
      <Typography variant='h6' sx={{ mt: 5 }}>
        Einladerelationen
      </Typography>
      <br />

      {contextTsx()}
    </>
  );
};
