import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ILegalTransaction,
  ILegalTransactionFullObject,
} from '../Interfaces/ILegalTransaction';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ICompanyFormationFullObject } from '../Interfaces/ICompanyFormation';
import { ILegalPhaseFullObject } from '../Interfaces/ILegalPhase';
import { LegalPhaseMain } from './LegalPhase/LegalPhaseMain';
import { IContact, ILegalTransactionHasContact } from '../Interfaces/IContact';
import { LegalTransactionClientsOverview } from './LegalTransactionClientsOverview';
import { IDocument } from '../Interfaces/IDocument';
import { DocumentOverview } from '../Document/DocumentOverview';
import { IInheritFullObject } from '../Interfaces/IInherit';
import { Add, AddCircle, Delete, Group, Person } from '@mui/icons-material';
import { ILegalRole } from '../Interfaces/ILegalRole';
import { ILegalRole_canInvite } from '../Interfaces/ILegalRole_canInvite';
import { LegalTransactionDraftOverview } from './LegalTransactionDraftOverview';
import { IDraftMessageFullObject } from '../Interfaces/IDraftMessage';
import { IPurchaseContractFullObject } from '../Interfaces/IPurchaseContract';
import { IDocumentDirectory } from '../Interfaces/IDocumentDirectory';
import { DocumentDirectoryOverview } from './DocumentDirectory/DocumentDirectoryOverview';
import { IDocumentDirectoryHasDocument } from '../Interfaces/IDocumentDirectoryHasDocument';
import { ILegalTransactionLog } from '../Interfaces/ILegalTransactionLog';
import { ILegalTransactionMeeting } from '../Interfaces/ILegalTransactionMeeting';
import { LegalTransactionMeetingOverview } from './LegalTransactionMeeting/LegalTransactionMeetingOverview';
import { ILegalTransactionMeetingEmail } from '../Interfaces/ILegalTransactionMeetingEmail';
import { ILegalTransaction_has_LegalTransactionSpecialFunction } from '../Interfaces/ILegalTransaction_has_LegalTransactionSpecialFunction';
import { SpecialFunctionOverview } from './LegalTransactionFunctions/SpecialFunctionOverview';
import { ILegalTransactionRecordNumber } from '../Interfaces/ILegalTransactionRecordNumber';
import { ILegalTransactionTransactionNumber } from '../Interfaces/ILegalTransactionTransactionNumber';
import { ILegalTransactionGwGFullObject } from '../Interfaces/ILegalTransactionGwG';
import { IMortgageFullObject } from '../Interfaces/IMortgage';
import { LegalTransactionDataCollection } from './LegalTransactionDataCollection';
import { SendDocument } from './SendDocuments/SendDocument';
import {
  ISendDocument,
  ISendDocumentSendOption,
} from '../Interfaces/ISendDocument';
import { isEqual } from 'lodash';
import { FSDirectorySearch } from '../FSDirectory/FSDirectorySearch';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import {
  useCreateLegalTransactionFullObject,
  useDeleteLegalTransaction,
  useExportPDF,
  useGetDownloadedDocuments,
  useGetHasDirectory,
  useGetLegalRequirementStates,
  useGetLegalRequirementTemplates,
  useGetLegalTransactionFullObject,
  useGetLegalTransactionProgresses,
  useGetLegalTransactionStates,
  useGetMeetingEmailTemplates,
  useSendDocuments,
  useUpdateLegalTransactionFullObject,
} from '../hooks/data';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../context/AlertContext';
import type { IUser } from '../Interfaces/IUser';
import type { IUserGroup } from '../Interfaces/IUserGroup';
import type { ILegalTransactionType } from '../Interfaces/ILegalTransactionType';
import { useQueryClient } from '@tanstack/react-query';
import type { ILegalTransactionCustomId } from '../Interfaces/ILegalTransactionCustomId';
import { AddCustomIdDialog } from './AddCustomIdDialog';
import type { State } from '../redux/mainReducer';
import { useSelector } from 'react-redux';
import { SidepanelView } from './SidepanelView';

interface IProps {
  idLegalTransaction: number;
  handleClose: (isEdited: boolean) => void;
  users: IUser[];
  userGroups: IUserGroup[];
  legalTransactionTypes: ILegalTransactionType[];
  contacts: IContact[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const SidepanelDocumentContext = createContext<{
  selectedSidepanelDocument: IDocument | undefined;
  setSelectedSidepanelDocument: (document: IDocument) => void;
}>({
  selectedSidepanelDocument: undefined,
  setSelectedSidepanelDocument: () => {},
});

export const useSidepanelDocumentContext = () => {
  return useContext(SidepanelDocumentContext);
};

const convertToBoolean = (value: unknown) => {
  if (value === 'true') {
    return true;
  } else if (value === 'false') {
    return false;
  } else if (typeof value === 'number') {
    return Boolean(value);
  } else {
    return !!value;
  }
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const getEmptyLegalTransaction = () => {
  return {
    idLegalTransaction: -1,
    Created_at: '2020-02-02',
    Closed_at: null,
    idContact: -1,
    idLegalTransactionState: 1,
    idLegalTransactionType: -1,
    idLegalTransactionProgress: 10,
    idUserGroup: null,
    UserGroup: '',
    Title: '',
    Notary_idUser: -1,
    Remarks: null,
    Employee_idUser: null,
    LastPhaseUpdate_at: null,
    NotarizationDate: null,
    StartEditDate: null,
    UserInformed_LackOfActivity: false,
    LastActivity_at: null,
  } as ILegalTransaction;
};

export const LegalTransactionEdit = ({
  idLegalTransaction,
  handleClose,
  users,
  userGroups,
  legalTransactionTypes,
  contacts,
}: IProps) => {
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const queryClient = useQueryClient();

  const {
    hasDirectory: hasDirCertificate,
    isLoadingHasDirectory: isLoadingHasDirCertificate,
  } = useGetHasDirectory(1);
  const {
    hasDirectory: hasDirInvoice,
    isLoadingHasDirectory: isLoadingHasDirInvoice,
  } = useGetHasDirectory(2);

  const { legalTransactionStates, isLoadingLegalTransactionStates } =
    useGetLegalTransactionStates();

  const { legalTransactionProgresses, isLoadingLegalTransactionProgresses } =
    useGetLegalTransactionProgresses();

  const { legalRequirementStates, isLoadingLegalRequirementStates } =
    useGetLegalRequirementStates();

  const { meetingEmailTemplates, isLoadingMeetingEmailTemplates } =
    useGetMeetingEmailTemplates();

  const {
    createLegalTransactionFullObject,
    isPendingCreateLegalTransactionFullObject,
  } = useCreateLegalTransactionFullObject();

  const {
    updateLegalTransactionFullObject,
    isPendingUpdateLegalTransactionFullObject,
  } = useUpdateLegalTransactionFullObject();

  const {
    legalTransactionFullObject,
    isLoadingLegalTransactionFullObject,
    refetchLegalTransactionFullObject,
  } = useGetLegalTransactionFullObject(idLegalTransaction);

  const { legalRequirementTemplates, isLoadingLegalRequirementTemplates } =
    useGetLegalRequirementTemplates();
  const { downloadedDocuments, isLoadingDownloadedDocuments } =
    useGetDownloadedDocuments();

  const { deleteLegalTransaction, isPendingDeleteLegalTransaction } =
    useDeleteLegalTransaction();

  const { sendDocuments, isPendingSendDocuments } = useSendDocuments();
  const { exportPDF, isPendingExportPDF } = useExportPDF();

  const mimeTypes = useSelector(
    (state: State) => state.mimeTypeArray.storeAcademicTitle
  );

  const combinedOptions = [
    ...users
      .filter(user => !user.isNotary)
      .map(user => ({
        type: 'Mitarbeiter',
        id: user.idUser,
        name: `${user.FirstName} ${user.LastName}`,
      })),
    ...userGroups.map(group => ({
      type: 'Gruppe',
      id: group.idUserGroup,
      name: group.UserGroup,
    })),
  ];

  const [isClosed, setIsClosed] = useState(false);
  const [dataCollectionArray, setDataCollectionArray] = useState<
    (
      | ICompanyFormationFullObject
      | IPurchaseContractFullObject
      | IInheritFullObject
      | IMortgageFullObject
    )[]
  >([]);
  const [recordNumberArray, setRecordNumberArray] = useState<
    ILegalTransactionRecordNumber[]
  >([]);
  const [transactionNumberArray, setTransactionNumberArray] = useState<
    ILegalTransactionTransactionNumber[]
  >([]);
  const [legalTransactionObject, setLegalTransactionObject] = useState(
    getEmptyLegalTransaction()
  );
  const [certificationArray, setCertificationArray] = useState<IDocument[]>([]);
  const [invoiceArray, setInvoiceArray] = useState<IDocument[]>([]);
  const [draftArray, setDraftArray] = useState<IDocument[]>([]);
  const [draftMessageArray, setDraftMessageArray] = useState<
    IDraftMessageFullObject[]
  >([]);
  const [documentArray, setDocumentArray] = useState<
    IDocumentDirectoryHasDocument[]
  >([]);
  const [documentDirectoryArray, setDocumentDirectoryArray] = useState<
    IDocumentDirectory[]
  >([]);
  const [meetingArray, setMeetingArray] = useState<ILegalTransactionMeeting[]>(
    []
  );
  const [meetingEmailArray, setMeetingEmailArray] = useState<
    ILegalTransactionMeetingEmail[]
  >([]);

  const [logArray, setLogArray] = useState<ILegalTransactionLog[]>([]);

  const [legalPhaseFullObjectArray, setLegalPhaseFullObjectArray] = useState<
    ILegalPhaseFullObject[]
  >([]);
  const [legalRoleArray, setLegalRoleArray] = useState<ILegalRole[]>([]);
  const [legalRoleCanInviteArray, setLegalRoleCanInviteArray] = useState<
    ILegalRole_canInvite[]
  >([]);
  const [gwgArray, setGwgArray] = useState<ILegalTransactionGwGFullObject[]>(
    []
  );
  const [currentTab, setCurrentTab] = useState(3);

  const [showSendDocumentsDialog, setShowSendDocumentsDialog] = useState(false);
  const [sendDocument, setSendDocument] = useState<ISendDocument>({
    contactArray: [],
    documentTypeArray: [],
    emailText: undefined,
    subject: '',
    sendOption: ISendDocumentSendOption.Documentportal,
    idLegalTransaction: idLegalTransaction,
  });

  const [
    showSendDocumentsDialogConfirmation,
    setShowSendDocumentsDialogConfirmation,
  ] = useState(false);

  const [contactArray, setContactArray] = useState<
    ILegalTransactionHasContact[]
  >([]);
  const [newContactArray, setNewContactArray] = useState<
    ILegalTransactionHasContact[]
  >([]);
  const [customIds, setCustomIds] = useState<ILegalTransactionCustomId[]>([]);
  const [isOpenChangeType, setIsOpenChangeType] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [
    legalTransaction_has_LegalTransactionSpecialFunctionArray,
    setLegalTransaction_has_LegalTransactionSpecialFunctionArray,
  ] = useState<ILegalTransaction_has_LegalTransactionSpecialFunction[]>([]);

  const [selectedType, setSelectedType] = useState('user');

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const [showAddCustomIdDialog, setShowAddCustomIdDialog] = useState(false);

  const prevLegalTransactionRef = useRef<ILegalTransaction | undefined>();

  const [isSavePossible, setIsSavePossible] = useState<
    (boolean | Record<string, boolean>)[]
  >([]);

  const isInitialized = isSavePossible.length > 0;

  useEffect(() => {
    if (dataCollectionArray.length > 0 && !isInitialized) {
      const newSaveState = dataCollectionArray.map(item => {
        if ('idMortgage' in item) {
          return {
            accordionOwners: true,
            accordionRealEstate: true,
            accordionMortgageDetails: true,
            accordionBeneficiary: true,
          };
        } else {
          return true;
        }
      });

      setIsSavePossible(newSaveState);
    }
  }, [dataCollectionArray]);

  useEffect(() => {
    isButtonDisabled();
  }, [isSavePossible]);

  const isButtonDisabled = () => {
    // Iteriere durch das Array
    for (const item of isSavePossible) {
      if (typeof item === 'boolean') {
        // Wenn es ein boolean ist, überprüfen, ob es true ist
        if (!item) {
          return true; // Ein false bedeutet, dass der Button deaktiviert werden soll
        }
      } else if (typeof item === 'object' && item !== null) {
        // Wenn es ein Record ist, überprüfe, ob alle Werte true sind
        const allTrue = Object.values(item).every(value => value === true);
        if (!allTrue) {
          return true; // Ein false in einem der Werte bedeutet, dass der Button deaktiviert werden soll
        }
      }
    }

    // Wenn wir hier sind, bedeutet das, dass alle Werte true sind
    return false; // Wenn alles `true` ist, soll der Button aktiv bleiben
  };

  useEffect(() => {
    if (legalTransactionFullObject) {
      legalTransactionFullObject.UserInformed_LackOfActivity = convertToBoolean(
        legalTransactionFullObject.UserInformed_LackOfActivity
      );

      setIsClosed(
        legalTransactionFullObject.Closed_at !== null ||
          legalTransactionFullObject.idLegalTransactionState === 100
      );
      setDataCollectionArray(legalTransactionFullObject.DataCollection);

      setNewContactArray([]);

      setLegalTransactionObject(legalTransactionFullObject);
      setSelectedType(() => {
        return legalTransactionFullObject.Employee_idUser !== null ||
          legalTransactionFullObject.idUserGroup === null
          ? 'user'
          : 'group';
      });

      setGwgArray(legalTransactionFullObject.LegalTransactionGwGArray);
      setContactArray(legalTransactionFullObject.ContactArray);
      setLegalRoleArray(legalTransactionFullObject.LegalRoleArray);
      setLegalRoleCanInviteArray(
        legalTransactionFullObject.LegalRoleCanInviteArray
      );

      setCertificationArray(legalTransactionFullObject.CertificateArray);
      setInvoiceArray(legalTransactionFullObject.InvoiceArray);
      setDraftArray(legalTransactionFullObject.DraftArray);

      setDraftMessageArray(legalTransactionFullObject.DraftMessageArray);
      // Setzen der Ablage
      setDocumentDirectoryArray(
        legalTransactionFullObject.DocumentDirectoryArray
      );
      setDocumentArray(legalTransactionFullObject.DocumentArray);
      // Termine
      setMeetingArray(legalTransactionFullObject.LegalTransactionMeetingArray);
      setMeetingEmailArray(
        legalTransactionFullObject.LegalTransactionMeetingEmailArray
      );

      if (legalTransactionFullObject.customIds) {
        setCustomIds(legalTransactionFullObject.customIds);
      }

      //Recordnumber und Transactionnumber
      //prüfe ob Recordnumber und Transactionnumber vorhanden sind, wenn nicht, dann füge ein leeres Element hinzu
      if (legalTransactionFullObject.RecordNumberArray.length === 0) {
        setRecordNumberArray([
          {
            RecordNumberEntry: '',
            idLegalTransaction: idLegalTransaction,
          },
        ]);
      } else {
        setRecordNumberArray(legalTransactionFullObject.RecordNumberArray);
      }
      if (legalTransactionFullObject.TransactionNumberArray.length === 0) {
        setTransactionNumberArray([
          {
            TransactionNumberEntry: '',
            idLegalTransaction: idLegalTransaction,
          },
        ]);
      } else {
        setTransactionNumberArray(
          legalTransactionFullObject.TransactionNumberArray
        );
      }
      setLegalTransaction_has_LegalTransactionSpecialFunctionArray(
        legalTransactionFullObject.LegalTransaction_has_LegalTransactionSpecialFunctionArray
      );

      // Prüfen nach Log
      if (legalTransactionFullObject.LogArray !== undefined) {
        setLogArray(legalTransactionFullObject.LogArray);
      }

      setLegalPhaseFullObjectArray([
        ...legalTransactionFullObject.LegalPhaseArray.map(
          (currentLegalPhase, idx) =>
            currentLegalPhase.Position !== null
              ? currentLegalPhase
              : { ...currentLegalPhase, Position: idx + 1 }
        ),
      ]);

      prevLegalTransactionRef.current = legalTransactionFullObject;
    }
  }, [legalTransactionFullObject]);

  const checkIfEdited = () => {
    const currentLegalTransactionObject = createLegalTransactionSaveObject();
    currentLegalTransactionObject.migrateType =
      prevLegalTransactionRef.current?.migrateType;

    // Tiefe Kopien beider Objekte erstellen
    const prevObject = JSON.parse(
      JSON.stringify(prevLegalTransactionRef.current)
    );
    const currentObject = JSON.parse(
      JSON.stringify(currentLegalTransactionObject)
    );

    return prevObject && currentObject && !isEqual(currentObject, prevObject);
  };

  const createLegalTransactionSaveObject = () => {
    const uploadObject: ILegalTransactionFullObject = {
      ...legalTransactionObject,
      UserInformed_LackOfActivity: Boolean(
        legalTransactionObject.UserInformed_LackOfActivity
      ),
      DataCollection: dataCollectionArray,
      LegalPhaseArray: legalPhaseFullObjectArray,
      LegalTransactionGwGArray: gwgArray,
      ContactArray: [...contactArray, ...newContactArray],
      CertificateArray: certificationArray,
      InvoiceArray: invoiceArray,
      DraftArray: draftArray,
      DraftMessageArray: draftMessageArray,
      LegalRoleArray: legalRoleArray,
      LegalRoleCanInviteArray: legalRoleCanInviteArray,
      LegalTransactionMeetingArray: meetingArray,
      LegalTransactionMeetingEmailArray: meetingEmailArray,
      DocumentArray: documentArray,
      DocumentDirectoryArray: documentDirectoryArray,
      LegalTransaction_has_LegalTransactionSpecialFunctionArray:
        legalTransaction_has_LegalTransactionSpecialFunctionArray,
      //remove empty recordNumbers
      RecordNumberArray: recordNumberArray.filter(
        recordNumber => recordNumber.RecordNumberEntry !== ''
      ),
      TransactionNumberArray: transactionNumberArray.filter(
        transactionNumber => transactionNumber.TransactionNumberEntry !== ''
      ),
      customIds,
    };
    return uploadObject;
  };

  const transactionInputRef = useRef<HTMLInputElement>(null);
  const recordInputRef = useRef<HTMLInputElement>(null);
  const [focusTransaction, setFocusTransaction] = useState(false);
  const [focusRecord, setFocusRecord] = useState(false);
  const [selectedSidepanelDocument, setSelectedSidepanelDocument] = useState<
    IDocument | undefined
  >(undefined);

  const isPending =
    isPendingCreateLegalTransactionFullObject ||
    isPendingUpdateLegalTransactionFullObject ||
    isPendingDeleteLegalTransaction;

  const customIdsWithContact = customIds.map(customId => {
    const contact = contacts.find(
      contact => contact.idContact === customId.idContact
    );
    return { ...customId, contact: contact! };
  });

  const hasMoreCustomIds = customIdsWithContact.length < contactArray.length;

  useEffect(() => {
    if (focusTransaction && transactionInputRef.current) {
      transactionInputRef.current.focus();
      setFocusTransaction(false);
    }
  }, [focusTransaction]);

  useEffect(() => {
    if (focusRecord && recordInputRef.current) {
      recordInputRef.current.focus();
      setFocusRecord(false);
    }
  }, [focusRecord]);

  const handleSave = async (migrateType?: boolean) => {
    setIsOpenChangeType(false);

    const uploadObject = createLegalTransactionSaveObject();
    uploadObject.migrateType = migrateType;

    try {
      if (legalTransactionObject.idLegalTransaction < 0) {
        await createLegalTransactionFullObject(uploadObject);
      } else {
        await updateLegalTransactionFullObject(uploadObject);
      }

      refetchLegalTransactionFullObject();

      queryClient.invalidateQueries({
        queryKey: ['legaltransaction', 'search'],
      });

      showAlert({
        severity: 'success',
        text: 'Vorgang erfolgreich gespeichert!',
      });
    } catch {
      showAlert({
        severity: 'error',
        text: 'Fehler beim Speichern des Vorgangs!',
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteLegalTransaction(idLegalTransaction);

      queryClient.invalidateQueries({
        queryKey: ['legaltransaction', 'search'],
      });

      navigate(`/legaltransaction`);

      showAlert({
        severity: 'success',
        text: 'Vorgang erfolgreich gelöscht!',
      });
    } catch {
      showAlert({
        severity: 'error',
        text: 'Fehler beim Löschen des Vorgangs!',
      });
    }
  };

  const handleDeleteRecordNumber = (index: number) => {
    if (recordNumberArray.length > 1) {
      const newArray = recordNumberArray.filter((_, i) => i !== index);
      setRecordNumberArray(newArray);
    }
  };

  const handleAddRecordNumber = () => {
    if (
      recordNumberArray[recordNumberArray.length - 1].RecordNumberEntry !== ''
    ) {
      setRecordNumberArray([
        ...recordNumberArray,
        { RecordNumberEntry: '', idLegalTransaction: idLegalTransaction },
      ]);
      setFocusRecord(true);
    }
  };

  const handleChangeRecordNumber = (index: number, value: string) => {
    setRecordNumberArray(
      recordNumberArray.map((recordNumber, i) =>
        i === index
          ? { ...recordNumber, RecordNumberEntry: value }
          : recordNumber
      )
    );
  };

  const handleExportPdf = async () => {
    if (!legalTransactionFullObject) {
      return;
    }

    try {
      const uploadObject = {
        ...legalTransactionFullObject,
        UserInformed_LackOfActivity: Boolean(
          legalTransactionFullObject.UserInformed_LackOfActivity
        ),
      };

      const response = await exportPDF(uploadObject);

      const linkSource = `data:application/pdf;base64,${response.pdf}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = response.fileName;
      downloadLink.click();
    } catch (error) {
      showAlert({
        severity: 'error',
        text: 'Fehler beim Exportieren des PDF',
      });
    }
  };

  const handleSendDocuments = async () => {
    if (
      sendDocument.contactArray.length > 0 &&
      sendDocument.documentTypeArray.length > 0 &&
      sendDocument.emailText !== ''
    ) {
      try {
        await sendDocuments(sendDocument);

        showAlert({
          severity: 'success',
          text: 'Dokumente erfolgreich versendet',
        });
      } catch (error) {
        showAlert({
          severity: 'error',
          text: 'Fehler beim Versenden der Dokumente',
        });
      }
    }
  };

  const handleDeleteTransactionNumber = (index: number) => {
    if (transactionNumberArray.length > 1) {
      const newArray = transactionNumberArray.filter((_, i) => i !== index);
      setTransactionNumberArray(newArray);
    }
  };

  const handleChangeTransactionNumber = (index: number, value: string) => {
    setTransactionNumberArray(
      transactionNumberArray.map((transactionNumber, i) =>
        i === index
          ? { ...transactionNumber, TransactionNumberEntry: value }
          : transactionNumber
      )
    );
  };

  const handleChangeCustomIds = (contactId: number, customId: string) => {
    setCustomIds(prevCustomIds => {
      if (prevCustomIds.find(item => item.idContact === contactId)) {
        return prevCustomIds.map(item =>
          item.idContact === contactId ? { ...item, customId: customId } : item
        );
      } else {
        return [
          ...prevCustomIds,
          {
            idLegalTransaction: idLegalTransaction,
            idContact: contactId,
            customId,
          },
        ];
      }
    });
  };

  const handleDeleteCustomId = (contactId: number) => {
    setCustomIds(prevCustomIds =>
      prevCustomIds.filter(
        customId =>
          customId.idContact !== contactId &&
          customId.idLegalTransaction === idLegalTransaction
      )
    );
  };

  const handleAddTransactionNumber = () => {
    if (
      transactionNumberArray[transactionNumberArray.length - 1]
        .TransactionNumberEntry !== ''
    ) {
      setTransactionNumberArray([
        ...transactionNumberArray,
        {
          TransactionNumberEntry: '',
          idLegalTransaction: idLegalTransaction,
        },
      ]);
      setFocusTransaction(true);
    }
  };

  if (
    isLoadingLegalTransactionFullObject ||
    isLoadingLegalRequirementTemplates ||
    isLoadingDownloadedDocuments ||
    isLoadingHasDirCertificate ||
    isLoadingHasDirInvoice ||
    isLoadingLegalTransactionStates ||
    isLoadingLegalTransactionProgresses ||
    isLoadingLegalRequirementStates ||
    isLoadingMeetingEmailTemplates ||
    isLoadingLegalRequirementTemplates ||
    isLoadingLegalTransactionFullObject
  ) {
    return (
      <Dialog open maxWidth='xl' fullWidth onClose={() => handleClose(false)}>
        <DialogContent
          sx={{
            height: '80vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  if (
    !legalTransactionFullObject ||
    !legalRequirementTemplates ||
    !downloadedDocuments ||
    !legalTransactionStates ||
    !legalTransactionProgresses ||
    !legalRequirementStates ||
    !meetingEmailTemplates
  ) {
    return (
      <Dialog open maxWidth='xl' fullWidth onClose={() => handleClose(false)}>
        <DialogContent
          sx={{
            height: '80vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography textAlign='center'>
            Ein Fehler ist aufgetreten! Bitte versuchen Sie es später erneut.
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <SidepanelDocumentContext.Provider
      value={{
        selectedSidepanelDocument,
        setSelectedSidepanelDocument,
      }}
    >
      <Dialog
        open
        onClose={() => handleClose(checkIfEdited())}
        maxWidth='xl'
        fullWidth
        scroll='paper'
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: 1,
            borderColor: 'divider',
            p: 2,
          }}
        >
          <Typography variant='h5'>
            {idLegalTransaction > 0 && legalTransactionFullObject
              ? legalTransactionFullObject.Title
              : 'Neues Rechtsgeschäft'}
          </Typography>

          <Box display='flex' gap={1}>
            <Button
              variant='outlined'
              onClick={() => {
                if (checkIfEdited()) {
                  showAlert({
                    severity: 'error',
                    text: 'Es gibt ungespeicherte Änderungen. Bitte speichern Sie zuerst alle Änderungen, bevor Sie diese Funktion nutzen.',
                  });
                } else {
                  setShowSendDocumentsDialog(true);
                }
              }}
            >
              {isPendingSendDocuments ? (
                <Box display='flex' alignItems='center' gap={1}>
                  <span>Unterlagen senden</span>
                  <CircularProgress size={20} />
                </Box>
              ) : (
                'Unterlagen senden'
              )}
            </Button>

            <Button
              variant='outlined'
              onClick={() => {
                if (checkIfEdited()) {
                  showAlert({
                    severity: 'error',
                    text: 'Es gibt ungespeicherte Änderungen. Bitte speichern Sie zuerst alle Änderungen, bevor Sie diese Funktion nutzen.',
                  });
                } else {
                  handleExportPdf();
                }
              }}
            >
              {isPendingExportPDF ? (
                <Box display='flex' alignItems='center' gap={1}>
                  <span>Export Nebenakte</span>
                  <CircularProgress size={20} />
                </Box>
              ) : (
                'Export Nebenakte'
              )}
            </Button>
            {idLegalTransaction > 0 && legalTransactionFullObject && (
              <IconButton
                disabled={isClosed}
                onClick={() => setIsOpenDelete(true)}
              >
                <Delete />
              </IconButton>
            )}
          </Box>
        </Box>
        <DialogContent>
          {isPending && <LinearProgress sx={{ mt: 1 }} />}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            {isClosed && (
              <Alert severity='warning'>
                Dieser Vorgang ist bereits geschlossen und kann nicht mehr
                angepasst werden.
              </Alert>
            )}

            {legalTransactionObject &&
              legalTransactionObject.idLegalTransactionState === 1 && (
                <Alert severity='warning'>
                  Im Status "Entwurf" kann der Mandant im Mandantenportal nicht
                  auf diesen Vorgang zugreifen und erhält keine E-Mails dazu.
                </Alert>
              )}

            {legalTransactionObject &&
              legalTransactionFullObject &&
              legalTransactionObject.idLegalTransactionType !==
                legalTransactionFullObject.idLegalTransactionType && (
                <Alert severity='warning'>
                  Sie haben die Rechtsgechäftsart verändert. Dieser Vorgang muss
                  zur neuen Rechtsgechäftsart migriert werden!
                </Alert>
              )}
          </Box>

          <Box
            sx={{
              display: selectedSidepanelDocument ? 'flex' : 'block',
              gap: 4,
              overflowX: 'hidden',
            }}
          >
            {selectedSidepanelDocument && (
              <Box
                sx={{
                  flexBasis: '35%',
                  maxWidth: '35%',
                  flexGrow: 0,
                  flexShrink: 0,
                }}
              >
                <SidepanelView
                  document={selectedSidepanelDocument}
                  mimeTypes={mimeTypes}
                  onClose={() => setSelectedSidepanelDocument(undefined)}
                />
              </Box>
            )}
            <Box
              sx={{
                flexShrink: 1,
                flexGrow: 0,
                maxWidth: selectedSidepanelDocument ? '61%' : 'auto',
                flexBasis: selectedSidepanelDocument ? '61%' : 'auto',
              }}
            >
              <Grid container spacing={2} mt={1}>
                <Grid item sm={12}>
                  <TextField
                    label='Bezeichnung'
                    value={legalTransactionObject.Title}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setLegalTransactionObject({
                        ...legalTransactionObject,
                        Title: event.target.value,
                      })
                    }
                    size='small'
                    fullWidth
                    disabled={isClosed}
                  />
                </Grid>

                <Grid item sm={3}>
                  <TextField
                    label='Art'
                    value={legalTransactionObject.idLegalTransactionType}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setLegalTransactionObject({
                        ...legalTransactionObject,
                        idLegalTransactionType: Number(event.target.value),
                      })
                    }
                    size='small'
                    fullWidth
                    select
                  >
                    {legalTransactionTypes.map(legalTransactionType => (
                      <MenuItem
                        key={`idLegalTransactionType-${legalTransactionType.idLegalTransactionType}`}
                        value={legalTransactionType.idLegalTransactionType}
                      >
                        {legalTransactionType.LegalTransactionType}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item sm={3}>
                  <TextField
                    label='Zugewiesener Notar'
                    value={legalTransactionObject.Notary_idUser}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setLegalTransactionObject({
                        ...legalTransactionObject,
                        Notary_idUser: Number(event.target.value),
                      })
                    }
                    size='small'
                    fullWidth
                    select
                    disabled={isClosed}
                  >
                    {users
                      .filter(user => Boolean(user.isNotary))
                      .map(user => (
                        <MenuItem
                          key={`Notary_idUser-${user.idUser}`}
                          value={user.idUser}
                        >
                          {user.FirstName} {user.LastName}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>

                <Grid item sm={3}>
                  <Autocomplete
                    options={combinedOptions}
                    groupBy={option => option.type}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={
                          selectedType === 'user' ? (
                            <span>
                              <Person
                                style={{
                                  verticalAlign: 'middle',
                                  marginRight: 8,
                                }}
                              />
                              Zugewiesener Mitarbeiter
                            </span>
                          ) : (
                            <span>
                              <Group
                                style={{
                                  verticalAlign: 'middle',
                                  marginRight: 8,
                                }}
                              />
                              Zugewiesene Gruppe
                            </span>
                          )
                        }
                        size='small'
                        variant='outlined'
                      />
                    )}
                    renderOption={(props, option) => (
                      <ListItem {...props} key={option.id}>
                        <ListItemText primary={option.name} />
                      </ListItem>
                    )}
                    onChange={(_, newValue) => {
                      if (newValue) {
                        if (newValue.type === 'Mitarbeiter') {
                          setLegalTransactionObject({
                            ...legalTransactionObject,
                            Employee_idUser: newValue.id,
                            idUserGroup: null,
                          });
                          setSelectedType('user');
                        } else if (newValue.type === 'Gruppe') {
                          setLegalTransactionObject({
                            ...legalTransactionObject,
                            idUserGroup: newValue.id,
                            Employee_idUser: null,
                          });
                          setSelectedType('group');
                        }
                      } else {
                        setLegalTransactionObject({
                          ...legalTransactionObject,
                          Employee_idUser: null,
                          idUserGroup: null,
                        });
                      }
                    }}
                    value={
                      selectedType === 'user' &&
                      legalTransactionObject.Employee_idUser !== null
                        ? combinedOptions.find(
                            option =>
                              option.type === 'Mitarbeiter' &&
                              option.id ===
                                legalTransactionObject.Employee_idUser
                          )
                        : selectedType === 'group' &&
                            legalTransactionObject.idUserGroup !== null
                          ? combinedOptions.find(
                              option =>
                                option.type === 'Gruppe' &&
                                option.id === legalTransactionObject.idUserGroup
                            )
                          : null
                    }
                  />
                </Grid>
                <Grid item sm={3}>
                  <TextField
                    label='Datum der Beurkundung'
                    placeholder=''
                    type='date'
                    value={legalTransactionObject.NotarizationDate}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setLegalTransactionObject({
                        ...legalTransactionObject,
                        NotarizationDate: event.target.value,
                      })
                    }
                    size='small'
                    disabled={isClosed}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </Grid>

                <Grid item sm={3}>
                  <TextField
                    label='Phase'
                    value={legalTransactionObject.idLegalTransactionProgress}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setLegalTransactionObject({
                        ...legalTransactionObject,
                        idLegalTransactionProgress: Number(event.target.value),
                      })
                    }
                    size='small'
                    fullWidth
                    select
                    disabled={isClosed}
                  >
                    {legalTransactionProgresses.map(
                      legalTransactionProgress => (
                        <MenuItem
                          key={`idLegalTransactionProgress-${legalTransactionProgress.idLegalTransactionProgress}`}
                          value={
                            legalTransactionProgress.idLegalTransactionProgress
                          }
                        >
                          {legalTransactionProgress.LegalTransactionProgress}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                </Grid>

                <Grid item sm={3}>
                  <TextField
                    label='Status'
                    value={legalTransactionObject.idLegalTransactionState}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setLegalTransactionObject({
                        ...legalTransactionObject,
                        idLegalTransactionState: Number(event.target.value),
                      })
                    }
                    size='small'
                    fullWidth
                    select
                    disabled={isClosed}
                  >
                    {legalTransactionStates.map(legalTransactionState => (
                      <MenuItem
                        key={`idLegalTransactionState-${legalTransactionState.idLegalTransactionState}`}
                        value={legalTransactionState.idLegalTransactionState}
                      >
                        {legalTransactionState.LegalTransactionState}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item sm={3}>
                  {transactionNumberArray.map((transactionNumber, index) => (
                    <Box
                      key={`TransactionNumber-${index}`}
                      display='flex'
                      alignItems='center'
                    >
                      <TextField
                        label={`Vorgangsnummer`}
                        id={`TransactionNumber-${index}`}
                        value={transactionNumber.TransactionNumberEntry}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          handleChangeTransactionNumber(
                            index,
                            event.target.value
                          )
                        }
                        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                          if (event.target.value === '') {
                            handleDeleteTransactionNumber(index);
                          }
                        }}
                        size='small'
                        fullWidth
                        disabled={isClosed}
                        inputRef={
                          index === transactionNumberArray.length - 1
                            ? transactionInputRef
                            : null
                        }
                      />
                      {transactionNumberArray.length > 1 &&
                        index < transactionNumberArray.length - 1 &&
                        transactionNumberArray[index + 1]
                          ?.TransactionNumberEntry !== '' && (
                          <IconButton
                            onClick={() => handleDeleteTransactionNumber(index)}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      {index === transactionNumberArray.length - 1 &&
                        transactionNumber.TransactionNumberEntry !== '' && (
                          <IconButton onClick={handleAddTransactionNumber}>
                            <AddCircle />
                          </IconButton>
                        )}
                    </Box>
                  ))}
                </Grid>

                <Grid item sm={3}>
                  {recordNumberArray.map((recordNumber, index) => (
                    <Box
                      key={`RecordNumber-${index}`}
                      display='flex'
                      alignItems='center'
                    >
                      <TextField
                        label={`Urkundennummer`}
                        id={`RecordNumber-${index}`}
                        value={recordNumber.RecordNumberEntry}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          handleChangeRecordNumber(index, event.target.value)
                        }
                        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                          if (event.target.value === '') {
                            handleDeleteRecordNumber(index);
                          }
                        }}
                        size='small'
                        fullWidth
                        disabled={isClosed}
                        inputRef={
                          index === recordNumberArray.length - 1
                            ? recordInputRef
                            : null
                        }
                      />
                      {recordNumberArray.length > 1 &&
                        index < recordNumberArray.length - 1 &&
                        recordNumberArray[index + 1].RecordNumberEntry !==
                          '' && (
                          <IconButton
                            onClick={() => handleDeleteRecordNumber(index)}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      {index === recordNumberArray.length - 1 &&
                        recordNumber.RecordNumberEntry !== '' && (
                          <IconButton onClick={handleAddRecordNumber}>
                            <AddCircle />
                          </IconButton>
                        )}
                    </Box>
                  ))}
                </Grid>
                <Grid item sm={12}>
                  <Box
                    gap={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant='h6'>Mandantenkennungen</Typography>
                    <Tooltip
                      title={
                        !hasMoreCustomIds
                          ? 'Es wurde bereits jedem Mandanten eine Kennung zugewiesen.'
                          : undefined
                      }
                    >
                      <Box>
                        <Button
                          variant='text'
                          onClick={() => setShowAddCustomIdDialog(true)}
                          startIcon={<Add />}
                          disabled={!hasMoreCustomIds}
                        >
                          Hinzufügen
                        </Button>
                      </Box>
                    </Tooltip>
                  </Box>
                  <Grid item container sm={12} spacing={2} mt={1}>
                    {customIdsWithContact.map(customIdWithContact => (
                      <Grid
                        item
                        sm={4}
                        key={`CustomId-${customIdWithContact.idLegalTransaction}-${customIdWithContact.idContact}`}
                      >
                        <TextField
                          key={`CustomId-${customIdWithContact.idLegalTransaction}-${customIdWithContact.idContact}`}
                          label={`Mandantenkennung: ${customIdWithContact.contact?.FirstName} ${customIdWithContact.contact?.LastName}`}
                          value={customIdWithContact.customId}
                          size='small'
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            handleChangeCustomIds(
                              customIdWithContact.idContact,
                              event.target.value
                            )
                          }
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <Tooltip title='Kennung löschen'>
                                <IconButton
                                  onClick={() =>
                                    handleDeleteCustomId(
                                      customIdWithContact.idContact
                                    )
                                  }
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                            ),
                          }}
                        >
                          {customIdWithContact.customId}
                        </TextField>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>

              <Box sx={{ my: 5 }}>
                <Stepper
                  activeStep={
                    legalTransactionFullObject?.idLegalTransactionProgress
                  }
                  alternativeLabel
                >
                  {legalTransactionProgresses.map(legalTransactionProgress => (
                    <Step
                      key={legalTransactionProgress.idLegalTransactionProgress}
                      completed={
                        Number(
                          legalTransactionFullObject?.idLegalTransactionProgress
                        ) > legalTransactionProgress.idLegalTransactionProgress
                      }
                      active={
                        Number(
                          legalTransactionFullObject?.idLegalTransactionProgress
                        ) ===
                        legalTransactionProgress.idLegalTransactionProgress
                      }
                    >
                      <StepLabel>
                        {legalTransactionProgress.LegalTransactionProgress}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>

              <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
                <Tabs
                  value={currentTab}
                  onChange={handleChange}
                  variant='scrollable'
                >
                  <Tab label='Datenerfassung' {...a11yProps(0)} />
                  <Tab label='Entwurf' {...a11yProps(1)} />
                  <Tab label='Termine' {...a11yProps(2)} />
                  <Tab label='Abwicklung' {...a11yProps(3)} />
                  <Tab label='Mandanten' {...a11yProps(4)} />
                  <Tab label='Urkunden' {...a11yProps(5)} />
                  <Tab label='Rechnungen' {...a11yProps(6)} />
                  <Tab label='Interne Datenablage' {...a11yProps(7)} />
                  <Tab label='Funktionen' {...a11yProps(8)} />
                  {logArray.length > 0 && <Tab label='Log' {...a11yProps(9)} />}
                </Tabs>
              </Box>
              <CustomTabPanel value={currentTab} index={0}>
                <LegalTransactionDataCollection
                  isSavePossible={isSavePossible}
                  setIsSavePossible={setIsSavePossible}
                  dataCollection={dataCollectionArray}
                  setDataCollection={setDataCollectionArray}
                />
              </CustomTabPanel>

              <CustomTabPanel value={currentTab} index={1}>
                <LegalTransactionDraftOverview
                  draftArray={draftArray}
                  setDraftArray={setDraftArray}
                  draftMessageArray={draftMessageArray}
                  setDraftMessageArray={setDraftMessageArray}
                />
              </CustomTabPanel>

              <CustomTabPanel value={currentTab} index={2}>
                <LegalTransactionMeetingOverview
                  idLegalTransactionType={
                    legalTransactionObject.idLegalTransactionType
                  }
                  meetingEmailTemplateArray={meetingEmailTemplates}
                  meetingArray={meetingArray}
                  setMeetingArray={setMeetingArray}
                  meetingEmailArray={meetingEmailArray}
                  setMeetingEmailArray={setMeetingEmailArray}
                />
              </CustomTabPanel>

              <CustomTabPanel value={currentTab} index={3}>
                <LegalPhaseMain
                  legalPhaseFullObjectArray={legalPhaseFullObjectArray}
                  setLegalPhaseFullObjectArray={setLegalPhaseFullObjectArray}
                  legalReqState={legalRequirementStates}
                  legalRequirementTemplateArray={legalRequirementTemplates}
                  legalRoleArray={legalRoleArray}
                  setLegalRoleArray={setLegalRoleArray}
                  legalRoleCanInviteArray={legalRoleCanInviteArray}
                  setLegalRoleCanInviteArray={setLegalRoleCanInviteArray}
                  downloadedArray={downloadedDocuments}
                  legaltransactionId={idLegalTransaction}
                  startdatedeadline={
                    legalTransactionObject.NotarizationDate &&
                    legalTransactionObject.NotarizationDate
                      ? new Date(legalTransactionObject.NotarizationDate)
                      : legalTransactionObject.StartEditDate &&
                          legalTransactionObject.StartEditDate
                        ? new Date(legalTransactionObject.StartEditDate)
                        : undefined
                  }
                />
              </CustomTabPanel>
              <CustomTabPanel value={currentTab} index={4}>
                <LegalTransactionClientsOverview
                  allContactArray={contacts}
                  setAllContactArray={() => {}}
                  legalRoleArray={legalRoleArray}
                  setLegalRoleArray={setLegalRoleArray}
                  chosenContactArray={contactArray}
                  setChosenContactArray={setContactArray}
                  legalRoleCanInviteArray={legalRoleCanInviteArray}
                  setLegalRoleCanInviteArray={setLegalRoleCanInviteArray}
                />
              </CustomTabPanel>

              <CustomTabPanel value={currentTab} index={5}>
                <DocumentOverview
                  key='certification'
                  allowRemove={true}
                  allowUpload={true}
                  title='Urkunden'
                  documentArray={certificationArray}
                  setDocumentArray={setCertificationArray}
                  downloadedArray={downloadedDocuments}
                  enableSidepanel={true}
                />
                {hasDirCertificate && legalTransactionFullObject && (
                  <Accordion sx={{ mt: 5 }}>
                    <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
                      Ordnersuche
                    </AccordionSummary>
                    <AccordionDetails>
                      <FSDirectorySearch
                        key='certification-fs-search'
                        searchArray={recordNumberArray
                          .filter(
                            recordNumber =>
                              recordNumber.RecordNumberEntry !== ''
                          )
                          .map(recordNumber => recordNumber.RecordNumberEntry)}
                        searchItem='certificate'
                        documentArray={certificationArray}
                        setDocumentArray={setCertificationArray}
                      />
                    </AccordionDetails>
                  </Accordion>
                )}
              </CustomTabPanel>

              <CustomTabPanel value={currentTab} index={6}>
                <>
                  <DocumentOverview
                    key='invoice'
                    allowRemove={true}
                    allowUpload={true}
                    title='Rechnungen'
                    documentArray={invoiceArray}
                    setDocumentArray={setInvoiceArray}
                    downloadedArray={downloadedDocuments}
                    enableSidepanel={true}
                  />
                  {hasDirInvoice && legalTransactionFullObject && (
                    <Accordion sx={{ mt: 5 }}>
                      <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
                        Ordnersuche
                      </AccordionSummary>
                      <AccordionDetails>
                        <FSDirectorySearch
                          key='invoice-fs-search'
                          searchArray={recordNumberArray
                            .filter(
                              recordNumber =>
                                recordNumber.RecordNumberEntry !== ''
                            )
                            .map(
                              recordNumber => recordNumber.RecordNumberEntry
                            )}
                          searchItem='invoice'
                          documentArray={invoiceArray}
                          setDocumentArray={setInvoiceArray}
                        />
                      </AccordionDetails>
                    </Accordion>
                  )}
                </>
              </CustomTabPanel>

              <CustomTabPanel value={currentTab} index={7}>
                <DocumentDirectoryOverview
                  idLegalTransaction={idLegalTransaction}
                  documentArray={documentArray}
                  documentDirectoryArray={documentDirectoryArray}
                  setDocumentArray={setDocumentArray}
                  setDocumentDirectoryArray={setDocumentDirectoryArray}
                />
              </CustomTabPanel>

              <CustomTabPanel index={8} value={currentTab}>
                <SpecialFunctionOverview
                  idLegalTransaction={idLegalTransaction}
                  legalTransaction_has_LegalTransactionSpecialFunctionArray={
                    legalTransaction_has_LegalTransactionSpecialFunctionArray
                  }
                  setLegalTransaction_has_LegalTransactionSpecialFunctionArray={
                    setLegalTransaction_has_LegalTransactionSpecialFunctionArray
                  }
                  gwgArray={gwgArray}
                  setGwgArray={setGwgArray}
                  legalPhaseFullObjectArray={legalPhaseFullObjectArray}
                  legalReqState={legalRequirementStates}
                />
              </CustomTabPanel>

              <CustomTabPanel value={currentTab} index={9}>
                <Typography variant='h6'>Log</Typography>
                <Grid sx={{ mt: 2 }} container spacing={1}>
                  {logArray.map(log => (
                    <>
                      <Grid item xs={4} sm={2}>
                        {new Date(log.Timestamp).toLocaleString('de-DE')}
                      </Grid>
                      <Grid item xs={8} sm={10}>
                        {log.Log}
                      </Grid>
                    </>
                  ))}
                </Grid>
              </CustomTabPanel>
            </Box>
          </Box>
          {isButtonDisabled() && (
            <Alert severity='warning' sx={{ marginBottom: 2 }}>
              Es existieren ungespeicherte Daten.
            </Alert>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            p: 2,
            borderTop: 1,
            borderColor: 'divider',
          }}
        >
          <Button
            variant={isClosed ? 'contained' : 'outlined'}
            onClick={() => handleClose(checkIfEdited())}
          >
            Schließen
          </Button>
          {!isClosed && (
            <>
              <Button
                variant='contained'
                onClick={() =>
                  legalTransactionObject &&
                  legalTransactionFullObject &&
                  legalTransactionObject.idLegalTransactionType !==
                    legalTransactionFullObject.idLegalTransactionType
                    ? setIsOpenChangeType(true)
                    : handleSave()
                }
                disabled={isPending || isButtonDisabled()}
              >
                {isPending ? (
                  <Box display='flex' alignItems='center' gap={1}>
                    <span>Speichern</span>
                    <CircularProgress size={20} />
                  </Box>
                ) : (
                  'Speichern'
                )}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={showSendDocumentsDialog}
        onClose={() => setShowSendDocumentsDialog(false)}
        maxWidth='md'
        fullWidth
      >
        <DialogTitle>Unterlagen senden</DialogTitle>
        <DialogContent>
          <SendDocument
            contactArray={contactArray}
            sendDocument={sendDocument}
            setSendDocument={setSendDocument}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowSendDocumentsDialog(false)}
            color='primary'
            variant='outlined'
          >
            Abbrechen
          </Button>
          <Button
            color='primary'
            variant='contained'
            disabled={
              sendDocument.contactArray.length === 0 ||
              sendDocument.documentTypeArray.length === 0
            }
            onClick={() => {
              setShowSendDocumentsDialogConfirmation(true);
            }}
          >
            Senden
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showSendDocumentsDialogConfirmation}
        onClose={() => setShowSendDocumentsDialogConfirmation(false)}
      >
        <DialogTitle>
          Wie sollen die Unterlagen bereitgestellt werden?
        </DialogTitle>
        <DialogContent>
          <FormControl
            fullWidth
            variant='outlined'
            error={false}
            margin='dense'
          >
            <InputLabel id='send-documents-label'>
              Bereitstellungsart
            </InputLabel>
            <Select
              labelId='send-documents-label'
              value={sendDocument.sendOption}
              onChange={event =>
                setSendDocument({
                  ...sendDocument,
                  sendOption: event.target.value as ISendDocumentSendOption,
                })
              }
              label='Bereitstellungsart'
            >
              <MenuItem value={ISendDocumentSendOption.Documentportal}>
                DiNo-Dokumentenportal
              </MenuItem>
              <MenuItem value={ISendDocumentSendOption.Email}>
                E-Mail
                <Typography
                  component='span'
                  sx={{ fontStyle: 'italic', fontSize: '0.8em', ml: 1 }}
                >
                  (Nicht GNotDS-konform!)
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowSendDocumentsDialogConfirmation(false);
            }}
            color='primary'
            variant='outlined'
          >
            Abbrechen
          </Button>
          <Button
            color='primary'
            variant='contained'
            onClick={() => {
              handleSendDocuments();
              setShowSendDocumentsDialogConfirmation(false);
              setShowSendDocumentsDialog(false);
            }}
          >
            Senden
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isOpenDelete} onClose={() => setIsOpenDelete(false)}>
        <DialogTitle>Löschen</DialogTitle>
        <DialogContent>
          Sind Sie sich sicher, dass Sie den aktuellen Vorgang löschen wollen?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpenDelete(false)}>Abbruch</Button>
          <Button
            variant='contained'
            onClick={handleDelete}
            disabled={isPendingDeleteLegalTransaction}
          >
            {isPendingDeleteLegalTransaction ? (
              <Box display='flex' alignItems='center' gap={1}>
                <span>Löschen</span>
                <CircularProgress size={20} />
              </Box>
            ) : (
              'Löschen'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isOpenChangeType}
        onClose={() => setIsOpenChangeType(false)}
      >
        <DialogTitle>Veränderung der Rechtsgeschäftsart</DialogTitle>
        <DialogContent>
          Wollen Sie den aktuellen Vollzugsstand (Abwicklung) und das bestehende
          Rollenschema der Mandanten beibehalten oder auf den Vollzugsstand und
          das Rollenschema der neuen Rechtsgeschäftsart migrieren?
          <br />
          Bei einer Migration geht Ihr aktueller Stand der Abwicklung sowie die
          Rollenverteilung der Mandanten verloren.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpenChangeType(false)}>Abbruch</Button>
          <Button onClick={() => handleSave()}>Beibehalten</Button>
          <Button onClick={() => handleSave(true)}>Migrieren</Button>
        </DialogActions>
      </Dialog>

      {showAddCustomIdDialog && (
        <AddCustomIdDialog
          contacts={contactArray.filter(
            contact =>
              !customIdsWithContact.some(
                item => item.idContact === contact.idContact
              )
          )}
          onAddCustomId={(contactId: number, customId: string) => {
            handleChangeCustomIds(contactId, customId);
            setShowAddCustomIdDialog(false);
          }}
          onClose={() => setShowAddCustomIdDialog(false)}
        />
      )}
    </SidepanelDocumentContext.Provider>
  );
};
