import React, { useState } from 'react';
import { ILegalPhaseFullObject } from '../../Interfaces/ILegalPhase';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { LegalPhaseRow } from './LegalPhaseRow';
import { FullScreenDialog } from '../../core/FullScreenDialog';
import { LegalPhaseTableEdit } from './Edit/LegalPhaseTableEdit';
import { ILegalRequirementState } from '../../Interfaces/ILegalRequirementState';
import { ILegalRole } from '../../Interfaces/ILegalRole';
import { ILegalRole_canInvite } from '../../Interfaces/ILegalRole_canInvite';
import { IDocumentDownloaded } from '../../Interfaces/IDocumentDownloaded';
import { ILegalRequirementTemplateFullObject } from '../../Interfaces/ILegalRequirementTemplate';
import {
  useAdministrativeUnits,
  useCourtCourtRoles,
  useFederalStates,
} from '../../Aemter/hooks';

interface IProps {
  legalPhaseFullObjectArray: ILegalPhaseFullObject[];
  setLegalPhaseFullObjectArray: Function;
  legalReqState: ILegalRequirementState[];
  legalRequirementTemplateArray: ILegalRequirementTemplateFullObject[];
  //
  legalRoleArray: ILegalRole[];
  setLegalRoleArray: Function;
  legalRoleCanInviteArray: ILegalRole_canInvite[];
  setLegalRoleCanInviteArray: Function;
  downloadedArray?: IDocumentDownloaded[];
  legaltransactionId: number;
  startdatedeadline: Date | undefined;
}

export const LegalPhaseMain: React.FC<IProps> = props => {
  const [legalPhaseEditArray, setLegalPhaseEditArray] = useState([
    ...props.legalPhaseFullObjectArray,
  ]);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);

  const { administrativeUnits, isLoadingAdministrativeUnits } =
    useAdministrativeUnits();

  const { federalStates, isLoadingFederalStates } = useFederalStates();

  const { courtCourtRoles, isLoadingCourtCourtRoles } = useCourtCourtRoles();

  const handleOpenEdit = () => {
    setLegalPhaseEditArray([...props.legalPhaseFullObjectArray]);
    setIsOpenEditDialog(true);
  };

  if (
    isLoadingAdministrativeUnits ||
    isLoadingFederalStates ||
    isLoadingCourtCourtRoles
  ) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          height: '20vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!administrativeUnits || !federalStates || !courtCourtRoles) {
    return (
      <Box
        sx={{
          m: 2,
        }}
      >
        <Typography>Fehler beim Laden der Daten</Typography>
      </Box>
    );
  }

  return (
    <>
      <FullScreenDialog
        isOpen={isOpenEditDialog}
        onClose={() => setIsOpenEditDialog(false)}
        title='Rechtsgeschäftsart'
      >
        <LegalPhaseTableEdit
          legalPhaseArray={legalPhaseEditArray}
          setLegalPhaseArray={setLegalPhaseEditArray}
          setLegalPhaseFullObjectArray={props.setLegalPhaseFullObjectArray}
          setIsOpenEditDialog={setIsOpenEditDialog}
          legalRoleArray={props.legalRoleArray}
          setLegalRoleArray={props.setLegalRoleArray}
          legalRoleCanInviteArray={props.legalRoleCanInviteArray}
          setLegalRoleCanInviteArray={props.setLegalRoleCanInviteArray}
          legalRequirementTemplateArray={props.legalRequirementTemplateArray}
          adminUnitArray={administrativeUnits}
          federalStateArray={federalStates}
          courtcourtroleArray={courtCourtRoles}
          legaltransactionId={props.legaltransactionId}
        />
      </FullScreenDialog>

      <Typography variant='h5' sx={{ mb: 3 }}>
        Aktueller Statusverlauf
        <Tooltip title='Maßnahmen und Phasen anpassen'>
          <Button
            sx={{ float: 'right' }}
            onClick={handleOpenEdit}
            variant='outlined'
          >
            Anpassen
          </Button>
        </Tooltip>
      </Typography>

      <Grid container spacing={1}>
        {props.legalPhaseFullObjectArray
          .sort((a, b) => (a.Position < b.Position ? -1 : 1))
          .map(x => (
            <LegalPhaseRow
              key={`idLegalPhase-${x.idLegalPhase}-${x.LegalRequirementArray.length}`}
              legalPhaseFullObject={x}
              legalPhaseFullObjectArray={props.legalPhaseFullObjectArray}
              setLegalPhaseFullObjectArray={props.setLegalPhaseFullObjectArray}
              legalReqState={props.legalReqState}
              legalRequirementTemplateArray={
                props.legalRequirementTemplateArray
              }
              downloadedArray={props.downloadedArray}
              startdatedeadline={props.startdatedeadline}
            />
          ))}
      </Grid>
    </>
  );
};
