import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICompanyFormationOption } from '../../Interfaces/ICompanyFormationOption';

const initialState = {
  storeCompanyFormationOption: [] as ICompanyFormationOption[],
};

const sliceCompanyFormationOption = createSlice({
  name: 'COMPANYFOMRATIONOPTIONARRAY',
  initialState,
  reducers: {
    setCompanyFormationOptionArray: (
      state,
      action: PayloadAction<ICompanyFormationOption[]>
    ) => {
      state.storeCompanyFormationOption = [...action.payload];
    },
    addUpdateCompanyFormationOptionArray: (
      state,
      action: PayloadAction<ICompanyFormationOption>
    ) => {
      const testObject = state.storeCompanyFormationOption.find(
        x =>
          x.idCompanyFormationOption === action.payload.idCompanyFormationOption
      );

      if (testObject) {
        state.storeCompanyFormationOption = [
          ...state.storeCompanyFormationOption.map(x =>
            x.idCompanyFormationOption ===
            action.payload.idCompanyFormationOption
              ? action.payload
              : x
          ),
        ];
      } else {
        state.storeCompanyFormationOption = [
          action.payload,
          ...state.storeCompanyFormationOption,
        ];
      }
    },
  },
});
export const {
  setCompanyFormationOptionArray,
  addUpdateCompanyFormationOptionArray,
} = sliceCompanyFormationOption.actions;
export default sliceCompanyFormationOption.reducer;
