import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';

export const MPSearchField: React.FC<{ setSelectedResult: Function }> = ({
  setSelectedResult,
}) => {
  const handleSelectResult = (res: any) => {
    setSelectedResult(res);
  };
  /*const provider = new MapBoxProvider({
      params: {
        access_token: apiKey,
      },
    });*/
  const provider = new OpenStreetMapProvider({
    params: {
      email: 'team@lextorbyte.de',
    },
  });
  // @ts-expect-error TODO: remove the need for ts-expect-error
  const searchControl = new GeoSearchControl({
    provider: provider,

    updateMap: false,
  });

  const map = useMap();

  useEffect(() => {
    map.addControl(searchControl);
    map.on('geosearch/showlocation', handleSelectResult);
    //return () => map.removeControl(searchControl);
  }, []);

  return null;
};
