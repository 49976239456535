import React, { useEffect, useState } from 'react';
import { IDocumentAnalysisSubsectionEntryFullObject } from '../../Interfaces/IDocumentAnalysisSubsectionEntry';
import { IconButton, TableCell, TableRow, useTheme } from '@mui/material';
import { DocumentAnalysisAttributeValue } from './DocumentAnalysisAttributeValue';
import { CustomeTextField } from '../../generic/CustomeTextField';
import { Delete } from '@mui/icons-material';
import { useDocumentAssistantContext } from '../DocumentAssistant/DocumentAssistantContext';

interface IProps {
  sectionEntry: IDocumentAnalysisSubsectionEntryFullObject;
  disableBtn?: boolean;
}

export const DocumentAnalysisSubsectionEntry: React.FC<IProps> = ({
  sectionEntry,
  disableBtn,
}) => {
  const theme = useTheme();
  const { setSectionEntries } = useDocumentAssistantContext();

  const [editSubsectionEntry, setEditSubsectionEntry] = useState(sectionEntry);
  const [entryValueArray, setEntryValueArray] = useState(
    sectionEntry.DocumentAnalysisAttributeValueArray
  );

  useEffect(() => {
    setSectionEntries(prevSectionEntries => [
      ...prevSectionEntries.map(sectionEntry =>
        sectionEntry.idDocumentAnalysisSubsectionEntry ===
        editSubsectionEntry.idDocumentAnalysisSubsectionEntry
          ? {
              ...editSubsectionEntry,
              DocumentAnalysisAttributeValueArray: entryValueArray,
            }
          : sectionEntry
      ),
    ]);
  }, [editSubsectionEntry, entryValueArray]);

  const handleDelete = () => {
    setSectionEntries(prevSectionEntries => [
      ...prevSectionEntries.filter(
        sectionEntry =>
          sectionEntry.idDocumentAnalysisSubsectionEntry !==
          editSubsectionEntry.idDocumentAnalysisSubsectionEntry
      ),
    ]);
  };

  return (
    <TableRow>
      <TableCell
        sx={{ border: `1px solid ${theme.palette.divider}`, m: 0, p: 0 }}
      >
        <CustomeTextField
          attr='isDeleted'
          label=''
          object={editSubsectionEntry}
          setObject={setEditSubsectionEntry}
          type='boolean'
        />
      </TableCell>
      {sectionEntry.DocumentAnalysisAttributeValueArray.map(entry => (
        <DocumentAnalysisAttributeValue
          key={`subsection-${sectionEntry.idDocumentAnalysisSubsectionEntry}-entryvalue-${entry.idDocumentAnalysisAttributeValue}`}
          attrValue={entry}
          setAttributeValues={setEntryValueArray}
        />
      ))}
      <TableCell
        sx={{ border: `1px solid ${theme.palette.divider}`, m: 0, p: 0 }}
      >
        {!disableBtn && (
          <IconButton onClick={handleDelete}>
            <Delete />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};
