import React from 'react';

import { Checkbox, TableCell, TableRow } from '@mui/material';

import { ILegalPhaseTemplateFullObject } from '../../../../Interfaces/ILegalPhaseTemplate';
import { ILegalRequirementTemplate } from '../../../../Interfaces/ILegalRequirementTemplate';
import { LegalRequirementRow } from './LegalRequirementRow';

interface IProps {
  currentPhase: ILegalPhaseTemplateFullObject;
  legalPhaseTemplateArray: ILegalPhaseTemplateFullObject[];
  choosenPhases: ILegalPhaseTemplateFullObject[];
  setChoosenPhases: Function;
}

export const LegalPhaseRow: React.FC<IProps> = ({
  currentPhase,
  choosenPhases,
  setChoosenPhases,
}) => {
  const addPhaseWithRequirements = (phase: ILegalPhaseTemplateFullObject) => {
    setChoosenPhases((prev: ILegalPhaseTemplateFullObject[]) => [
      ...prev,
      phase,
    ]);
  };

  // Funktion, um eine Phase und alle Requirements zu entfernen
  const removePhaseWithRequirements = (phaseId: number) => {
    setChoosenPhases((prev: ILegalPhaseTemplateFullObject[]) =>
      prev.filter(phase => phase.idLegalPhaseTemplate !== phaseId)
    );
  };

  // Funktion, um ein Requirement zu einer Phase hinzuzufügen
  const addRequirementToPhase = (
    phase: ILegalPhaseTemplateFullObject,
    requirement: ILegalRequirementTemplate
  ) => {
    setChoosenPhases((prev: ILegalPhaseTemplateFullObject[]) => {
      const existingPhase = prev.find(
        p => p.idLegalPhaseTemplate === phase.idLegalPhaseTemplate
      );

      if (existingPhase) {
        // Wenn die Phase existiert, Requirement hinzufügen
        const updatedPhase = {
          ...existingPhase,
          LegalRequirementArray: [
            ...existingPhase.LegalRequirementArray,
            requirement,
          ],
        };

        return prev.map(p =>
          p.idLegalPhaseTemplate === phase.idLegalPhaseTemplate
            ? updatedPhase
            : p
        );
      } else {
        // Wenn die Phase nicht existiert, Phase und Requirement hinzufügen
        return [
          ...prev,
          {
            ...phase,
            LegalRequirementArray: [requirement],
          },
        ];
      }
    });
  };

  // Funktion, um ein Requirement aus einer Phase zu entfernen
  const removeRequirementFromPhase = (
    phaseId: number,
    requirementId: number
  ) => {
    setChoosenPhases((prev: ILegalPhaseTemplateFullObject[]) => {
      return prev
        .map(phase => {
          if (phase.idLegalPhaseTemplate === phaseId) {
            const updatedRequirements = phase.LegalRequirementArray.filter(
              req => req.idLegalRequirementTemplate !== requirementId
            );

            if (updatedRequirements.length === 0) {
              // Wenn keine Requirements mehr vorhanden sind, Phase entfernen
              return null;
            }

            return {
              ...phase,
              LegalRequirementArray: updatedRequirements,
            };
          }
          return phase;
        })
        .filter(Boolean) as ILegalPhaseTemplateFullObject[]; // Filtert null-Werte raus
    });
  };

  // Handler für das An- und Abwählen einer Phase
  const handlePhaseSelection = (isChecked: boolean) => {
    if (isChecked) {
      addPhaseWithRequirements(currentPhase);
    } else {
      removePhaseWithRequirements(currentPhase.idLegalPhaseTemplate);
    }
  };

  // Handler für das An- und Abwählen eines Requirements
  const handleRequirementSelection = (
    requirement: ILegalRequirementTemplate,
    isChecked: boolean
  ) => {
    if (isChecked) {
      addRequirementToPhase(currentPhase, requirement);
    } else {
      removeRequirementFromPhase(
        currentPhase.idLegalPhaseTemplate,
        requirement.idLegalRequirementTemplate
      );
    }
  };

  // Prüfe, ob die Phase bereits ausgewählt ist
  const isPhaseChecked = choosenPhases.some(
    phase => phase.idLegalPhaseTemplate === currentPhase.idLegalPhaseTemplate
  );

  // Funktion, um den Status eines Requirements zu prüfen
  const isRequirementChecked = (requirementId: number) => {
    const phase = choosenPhases.find(
      p => p.idLegalPhaseTemplate === currentPhase.idLegalPhaseTemplate
    );
    return (
      phase?.LegalRequirementArray.some(
        req => req.idLegalRequirementTemplate === requirementId
      ) || false
    );
  };

  return (
    <>
      <TableRow>
        <TableCell colSpan={2}>
          <Checkbox
            checked={isPhaseChecked}
            onChange={e => handlePhaseSelection(e.target.checked)}
          />
          {currentPhase.LegalPhaseTemplate}
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>

      {currentPhase.LegalRequirementArray.sort((a, b) =>
        a.Position < b.Position ? -1 : 1
      ).map(requirement => (
        <LegalRequirementRow
          key={requirement.idLegalRequirementTemplate}
          currentRequirement={requirement}
          handleChoosenRequirementChange={handleRequirementSelection}
          isChecked={isRequirementChecked(
            requirement.idLegalRequirementTemplate
          )}
        />
      ))}
    </>
  );
};
