import { combineReducers } from '@reduxjs/toolkit';
// Contact
import slice_Gender from './slices/gender';
import slice_AcademicTitle from './slices/academicTitle';
import slice_Country from './slices/country';
import slice_BusinessForm from './slices/businessForm';
import slice_ContactGroup from './slices/contactGroup';
// Dokumente
import slice_MIMEType from './slices/mimeType';
import slice_legalRequirementState from './slices/legalRequirementState';
// CompanyFormation
import slice_companyFormationOption from './slices/companyFormationOption';
import slice_companyFormationQuestion from './slices/companyFormationQuestion';
// Text Module
import slice_textModuleCategory from './slices/textModuleCategory';
import slice_textModule from './slices/textModule';
// Variable
import slice_dictionaryEntry from './slices/dictionaryEntry';
// Amtsgerichte
import slice_districtCourt from './slices/districtCourt';
import slice_landMarking from './slices/landMarking';

import slice_federalState from './slices/federalState';

const mainReducer = combineReducers({
  // Contact
  genderArray: slice_Gender,
  academicTitleArray: slice_AcademicTitle,
  countryArray: slice_Country,
  businessFormArray: slice_BusinessForm,
  contactGroup: slice_ContactGroup,
  // Dokuemten
  mimeTypeArray: slice_MIMEType,
  // Legal Transaction
  legalRequirementStateArray: slice_legalRequirementState,
  // CompanyFormation
  companyFormationQuestionArray: slice_companyFormationQuestion,
  companyFormationOptionArray: slice_companyFormationOption,
  // Text Module
  textModuleCategoryArray: slice_textModuleCategory,
  textModuleArray: slice_textModule,
  // Variable
  dictionaryEntryArray: slice_dictionaryEntry,
  // Amtsgerichte
  districtCourtArray: slice_districtCourt,
  landMarkingArray: slice_landMarking,
  // FederalState
  federalStateArray: slice_federalState,
});
export default mainReducer;
export type State = ReturnType<typeof mainReducer>;
