import React, { useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Tab,
  TableCell,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';

import { Delete, Edit } from '@mui/icons-material';
import { InheritPerson } from '../InheritPerson';
import { IRequiredFiles } from '../InheritDocument';
import { IInheritHasPerson } from '../../../../../Interfaces/IPerson';
import { IChildren } from '../../../../../Interfaces/IChildren';
import { IPersonHasDocument } from '../../../../../Interfaces/IPersonHasDocument';
import { IInherit } from '../../../../../Interfaces/IInherit';
import {
  FamilyTreePersonNodeType,
  FamilyTreeService,
} from '../../../../../services/familyTree.service';
import {
  getInheritPerson,
  getRequiredFilesArray,
  handleRemoveRecursive,
  initializeSecondParent,
} from '../../../../../services/dialog.services.ts/inheritDialog.service';
import CustomTabPanel from '../../../../../core/CustomTabPanel';
import { a11yProps } from '../../../../../services/dialog.service';

interface IProps {
  idPersonInherit: number;
  index: number;
  inheritObject: IInherit;
  currentChildren: IChildren;
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  childrenArray: IChildren[];
  setChildrenArray: Function;
}

export const ChildrenRow: React.FC<IProps> = ({
  idPersonInherit,
  index,
  inheritObject,
  currentChildren,
  personArray,
  setPersonArray,
  personHasDocument,
  setPersonHasDocument,
  childrenArray,
  setChildrenArray,
}) => {
  const [page, setPage] = useState(2);
  //
  const firstParent = getInheritPerson(
    currentChildren.Parent1_idPerson,
    personArray
  );
  const secondParent = initializeSecondParent(currentChildren, personArray);
  //
  const [editChildren, setEditChildren] = useState(currentChildren);
  const [editChildrenPerson, setEditChildrenPerson] = useState(
    getInheritPerson(currentChildren.Children_idPerson, personArray)
  );
  //
  const [isDeath, setIsDeath] = useState(false);
  const [openEditPersonDialog, setOpenEditPersonDialog] = useState(false);
  //
  const formRef = useRef<HTMLFormElement>(null);

  /**
   * Dialog Functions
   */
  const handleOpen = (children: IChildren) => {
    setEditChildren(children);
    setEditChildrenPerson(
      getInheritPerson(children.Children_idPerson, personArray)
    );
    setOpenEditPersonDialog(true);
  };

  const handleRemove = () => {
    const currentPersonArray = [...personArray];
    const currentChildrenArray = [...childrenArray];
    const currentPersonHasDocument = [...personHasDocument];

    const [resPersonArray, resChildrenArray, resPersonHasDocument] =
      handleRemoveRecursive(
        editChildrenPerson.idPerson,
        currentPersonArray,
        currentChildrenArray,
        currentPersonHasDocument
      );

    setPersonHasDocument([...resPersonHasDocument]);

    setPersonArray([...resPersonArray]);

    setChildrenArray([...resChildrenArray]);
    setOpenEditPersonDialog(false);
  };

  const handleSave = () => {
    let currentPersonArray = [...personArray];
    currentPersonArray = currentPersonArray.map(x =>
      x.idPerson === editChildrenPerson.idPerson ? editChildrenPerson : x
    );

    setPersonArray([...currentPersonArray]);
    setChildrenArray([
      ...childrenArray.map(x =>
        x.idChildren === editChildren.idChildren ? editChildren : x
      ),
    ]);
    setOpenEditPersonDialog(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (formRef.current && formRef.current.checkValidity()) {
      setPage(newValue);
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements).reverse();
      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  /**
   * Form Function
   */
  const handleOnSave = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      handleSave();
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements).reverse();
      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  return (
    <>
      <Dialog
        open={openEditPersonDialog}
        onClose={() => {
          setOpenEditPersonDialog(false);
        }}
        maxWidth='lg'
        fullWidth
      >
        <DialogContent>
          <form ref={formRef}>
            <Tabs
              sx={{ mt: 5 }}
              value={page}
              onChange={handleChange}
              aria-label='basic tabs example'
            >
              <Tab label='Elternteil 1' {...a11yProps(0)} />
              <Tab label='Elternteil 2' {...a11yProps(1)} />
              <Tab label='Kind' {...a11yProps(2)} />
            </Tabs>

            <CustomTabPanel value={page} index={0}>
              <Typography variant='body1' sx={{ mb: 5 }}>
                Diese Darstellung dient der Übersicht und gestattet keine
                Anpassungen des ersten Elternteiles.
              </Typography>
              <InheritPerson
                inheritObject={inheritObject}
                personArray={personArray}
                key='firstparent'
                disableEdit
                currentPerson={firstParent}
                setCurrentPerson={() => {}}
                personHasDocument={personHasDocument}
                setPersonHasDocument={() => {}}
                title='Erster Elternteil'
                showDeathSettings
                requiredFiles={
                  [
                    {
                      Title: 'Sterbeurkunde',
                      isOptional: false,
                      idPersonDocumentType: 1,
                    },
                  ] as IRequiredFiles[]
                }
              />
            </CustomTabPanel>

            <CustomTabPanel value={page} index={1}>
              <Typography variant='body1' sx={{ mb: 5 }}>
                Diese Darstellung dient der Übersicht und gestattet keine
                Anpassungen des zweiten Elternteiles.
              </Typography>
              {secondParent ? (
                <InheritPerson
                  inheritObject={inheritObject}
                  personArray={personArray}
                  key='secondparent'
                  disableEdit
                  currentPerson={secondParent}
                  setCurrentPerson={() => {}}
                  personHasDocument={personHasDocument}
                  setPersonHasDocument={() => {}}
                  title='Elternteil 2'
                  requiredFiles={[]}
                />
              ) : (
                <Typography variant='body1'>Keine Daten vorhanden</Typography>
              )}
            </CustomTabPanel>

            <CustomTabPanel value={page} index={2}>
              <InheritPerson
                inheritObject={inheritObject}
                personArray={personArray}
                key='child'
                currentPerson={editChildrenPerson}
                setCurrentPerson={setEditChildrenPerson}
                personHasDocument={personHasDocument}
                setPersonHasDocument={setPersonHasDocument}
                title={FamilyTreeService.getGenderNaming(
                  FamilyTreePersonNodeType.CHILD_NODE,
                  editChildrenPerson.idGender
                )}
                showDeathSettings
                requiredFiles={getRequiredFilesArray(isDeath)}
                setIsDeath={setIsDeath}
              />
            </CustomTabPanel>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={() => {
              setEditChildrenPerson(
                getInheritPerson(currentChildren.Children_idPerson, personArray)
              );
              setOpenEditPersonDialog(false);
            }}
          >
            Abbruch
          </Button>
          <Button variant='contained' onClick={handleOnSave}>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>

      <TableRow
        sx={{
          backgroundColor:
            editChildrenPerson.idPerson === idPersonInherit
              ? '#f2f2f2'
              : undefined,
        }}
      >
        {/*Nr. */}
        <TableCell>{index + 1}</TableCell>
        {/*Name */}
        <TableCell>
          {`${editChildrenPerson.FirstName} ${editChildrenPerson.LastName}`}
        </TableCell>
        {/*Elternteil 1 */}
        <TableCell>
          {firstParent === null ? (
            <>ERROR</>
          ) : (
            `${firstParent.FirstName} ${firstParent.LastName}`
          )}
        </TableCell>
        {/*Elternteil 2 */}
        <TableCell>
          {secondParent === null ? (
            <>keine Daten vorhanden</>
          ) : (
            `${secondParent.FirstName} ${secondParent.LastName}`
          )}
        </TableCell>

        {/*Aktionen */}
        <TableCell>
          <IconButton onClick={() => handleOpen(currentChildren)}>
            <Edit />
          </IconButton>
          <IconButton onClick={handleRemove}>
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
