import React, { useState } from 'react';
import {
  Box,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { ILegalTransaction_has_LegalTransactionSpecialFunction } from '../../Interfaces/ILegalTransaction_has_LegalTransactionSpecialFunction';
import { HandelsregisterCheck } from './HandelsregisterCheck/HandelsregisterCheck';
import { ILegalTransactionGwGFullObject } from '../../Interfaces/ILegalTransactionGwG';
import { ILegalPhaseFullObject } from '../../Interfaces/ILegalPhase';
import { GwGMain } from './GwG/GwGMain';
import { ILegalRequirementState } from '../../Interfaces/ILegalRequirementState';
import { ShareholderListCheck } from './ShareHolderList/ShareHolderListCheck';
import { LandRegisterCheck } from './LandRegisterCheck/LandRegisterCheck';
import { useGetLegalTransactionSpecialFunctions } from '../../hooks/data';

interface IProps {
  idLegalTransaction: number;
  legalTransaction_has_LegalTransactionSpecialFunctionArray: ILegalTransaction_has_LegalTransactionSpecialFunction[];
  setLegalTransaction_has_LegalTransactionSpecialFunctionArray: Function;
  gwgArray: ILegalTransactionGwGFullObject[];
  setGwgArray: Function;
  legalPhaseFullObjectArray: ILegalPhaseFullObject[];
  legalReqState: ILegalRequirementState[];
}

const getEmptySpecialFunction = (idLegalTransaction: number) => {
  return {
    idLegalTransaction_has_LegalTransactionSpecialFunction: -1,
    idLegalTransaction: idLegalTransaction,
    idLegalRequirement: -1,
    idLegalRequirementState: -1,
    idSF: -1,
    Function_Field1: new Date().toISOString().split('T')[0],
    Function_Field2: '',
    Function_Field3: '',
    Function_Field4: '',
    Function_Field5: '',
    Res_Field1: '',
  } as ILegalTransaction_has_LegalTransactionSpecialFunction;
};

export const SpecialFunctionOverview: React.FC<IProps> = props => {
  const {
    legalTransactionSpecialFunctions,
    isLoadingLegalTransactionSpecialFunctions,
  } = useGetLegalTransactionSpecialFunctions();

  const [isOpen, setIsOpen] = useState(false);
  const [addedSpecialFunction, setaddedSpecialFunction] =
    useState<ILegalTransaction_has_LegalTransactionSpecialFunction>(
      getEmptySpecialFunction(props.idLegalTransaction)
    );

  const handleClose = () => {
    setaddedSpecialFunction(getEmptySpecialFunction(props.idLegalTransaction));
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setaddedSpecialFunction({
      ...addedSpecialFunction,
      idSF: Number(event.target.value),
    });
  };

  const addSpecialFunction = (idSF: number) => {
    let minId = -1;
    if (
      props.legalTransaction_has_LegalTransactionSpecialFunctionArray.length > 0
    ) {
      const minIdArray =
        Math.min(
          ...props.legalTransaction_has_LegalTransactionSpecialFunctionArray.map(
            x => x.idLegalTransaction_has_LegalTransactionSpecialFunction
          )
        ) - 1;

      if (minIdArray <= minId) {
        minId = minIdArray - 1;
      }
    }

    const newObject = {
      idLegalTransaction_has_LegalTransactionSpecialFunction: minId,
      idLegalTransaction: props.idLegalTransaction,
      idLegalRequirement: -1,
      idLegalRequirementState: -1,
      idSF: idSF,
      Function_Field1: new Date().toISOString().split('T')[0],
      Function_Field2: '',
      Function_Field3: '',
      Function_Field4: '',
      Function_Field5: '',
      Function_Field6: '',
      Res_Field1: '',
    } as ILegalTransaction_has_LegalTransactionSpecialFunction;

    props.setLegalTransaction_has_LegalTransactionSpecialFunctionArray([
      ...props.legalTransaction_has_LegalTransactionSpecialFunctionArray,
      newObject,
    ]);
  };

  const deleteSpecialFunction = (index: number) => {
    const updatedArray =
      props.legalTransaction_has_LegalTransactionSpecialFunctionArray.filter(
        (_, i) => i !== index
      );
    props.setLegalTransaction_has_LegalTransactionSpecialFunctionArray(
      updatedArray
    );
  };

  const handleFieldChangeOnImport = (
    index: number,
    updates: { [key: string]: string }
  ) => {
    const updatedArray =
      props.legalTransaction_has_LegalTransactionSpecialFunctionArray.map(
        (item, i) => {
          if (i === index) {
            const updatedItem = { ...item, ...updates };
            if (updatedItem.idSF === 3) {
              updatedItem.Function_Field3 = '1';
            }
            if (updatedItem.idSF === 1) {
              const followUpState =
                Number(updatedItem.Function_Field3) === 2 ? 2 : 1;
              updatedItem.Function_Field3 = followUpState.toString();
            }
            return updatedItem;
          }
          return item;
        }
      );
    props.setLegalTransaction_has_LegalTransactionSpecialFunctionArray(
      updatedArray
    );
  };

  const handleSave = () => {
    const { idSF } = addedSpecialFunction;

    if (idSF === 1 || idSF === 3 || idSF === 4) {
      addSpecialFunction(idSF);
    } else if (idSF === 2) {
      let minId = -1;
      if (props.gwgArray.length > 0) {
        const minIdArray =
          Math.min(...props.gwgArray.map(x => x.idLegalTransactionGwG)) - 1;

        if (minIdArray <= minId) {
          minId = minIdArray - 1;
        }
      }
      const newObject = {
        idLegalTransactionGwG: minId,
        idLegalTransaction: -1,
        idLegalRequirement: null,
        LegalTransactionGwGEntryArray: [],
        total: 0,
        title: 'GwG Kaufvertrag',
      };
      props.setGwgArray([...props.gwgArray, newObject]);
    }
    handleClose();
  };

  if (isLoadingLegalTransactionSpecialFunctions) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          height: '20vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!legalTransactionSpecialFunctions) {
    return (
      <Box
        sx={{
          m: 2,
        }}
      >
        <Typography>Fehler beim Laden der Daten</Typography>
      </Box>
    );
  }

  return (
    <>
      <Dialog onClose={handleClose} open={isOpen}>
        <DialogTitle>Funktion hinzufügen</DialogTitle>
        <DialogContent>
          Bitte wählen sie eine Funktion aus
          <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel id='demo-simple-select-label'>Funktion</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              label='Funktion'
              value={addedSpecialFunction.idSF.toString()}
              onChange={handleChange}
            >
              {legalTransactionSpecialFunctions.map(item => (
                <MenuItem key={item.idSF} value={item.idSF}>
                  {item.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose}>
            schliessen
          </Button>
          <Button variant='contained' onClick={handleSave}>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={handleOpen}>Funktion hinzufügen</Button>
      </Box>

      {props.legalTransaction_has_LegalTransactionSpecialFunctionArray.filter(
        x => x.idSF === 1
      ).length > 0 && (
        <HandelsregisterCheck
          idLegalTransaction={props.idLegalTransaction}
          legalTransaction_has_LegalTransactionSpecialFunctionArray={
            props.legalTransaction_has_LegalTransactionSpecialFunctionArray
          }
          setLegalTransaction_has_LegalTransactionSpecialFunctionArray={
            props.setLegalTransaction_has_LegalTransactionSpecialFunctionArray
          }
          legalPhaseFullObjectArray={props.legalPhaseFullObjectArray}
          legalReqState={props.legalReqState}
          deleteSpecialFunction={deleteSpecialFunction}
          handleFieldChangeOnImport={handleFieldChangeOnImport}
        ></HandelsregisterCheck>
      )}
      {props.gwgArray.length > 0 && (
        <GwGMain
          gwgArray={props.gwgArray}
          setGwgArray={props.setGwgArray}
          legalPhaseFullObjectArray={props.legalPhaseFullObjectArray}
        />
      )}

      {props.legalTransaction_has_LegalTransactionSpecialFunctionArray.filter(
        x => x.idSF === 3
      ).length > 0 && (
        <ShareholderListCheck
          idLegalTransaction={props.idLegalTransaction}
          legalTransaction_has_LegalTransactionSpecialFunctionArray={
            props.legalTransaction_has_LegalTransactionSpecialFunctionArray
          }
          setLegalTransaction_has_LegalTransactionSpecialFunctionArray={
            props.setLegalTransaction_has_LegalTransactionSpecialFunctionArray
          }
          legalPhaseFullObjectArray={props.legalPhaseFullObjectArray}
          legalReqState={props.legalReqState}
          deleteSpecialFunction={deleteSpecialFunction}
          handleFieldChangeOnImport={handleFieldChangeOnImport}
        />
      )}
      {props.legalTransaction_has_LegalTransactionSpecialFunctionArray.filter(
        x => x.idSF === 4
      ).length > 0 && (
        <LandRegisterCheck
          idLegalTransaction={props.idLegalTransaction}
          legalTransaction_has_LegalTransactionSpecialFunctionArray={
            props.legalTransaction_has_LegalTransactionSpecialFunctionArray
          }
          setLegalTransaction_has_LegalTransactionSpecialFunctionArray={
            props.setLegalTransaction_has_LegalTransactionSpecialFunctionArray
          }
          legalPhaseFullObjectArray={props.legalPhaseFullObjectArray}
          legalReqState={props.legalReqState}
          deleteSpecialFunction={deleteSpecialFunction}
          handleFieldChangeOnImport={handleFieldChangeOnImport}
        />
      )}
    </>
  );
};
