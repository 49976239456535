import { Checkbox, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { BoolDictionary } from './DocumentAssistentMain';
import { CellObject } from './DocumentAssistantContext';
interface IProps {
  selectDict: BoolDictionary;
  setSelectDict: Function;
  id: string;
  currentCell: string;
  onSelectChange: (cell: CellObject) => void;
  selectedCells: CellObject[];
}

export const DocumentAssistentCell: React.FC<IProps> = ({
  id,
  currentCell,
  onSelectChange,
  selectedCells,
}) => {
  const theme = useTheme();

  const isSelected = selectedCells.some(
    cell => cell.content === currentCell && cell.id === id
  );

  const handleSelectChange = () => {
    onSelectChange({ id, content: currentCell });
  };

  return (
    <Grid
      item
      xs={1}
      sx={{
        border: '0.5px solid #e0e0e0',
        p: 0.5,
        backgroundColor: isSelected ? theme.palette.grey[100] : 'white',
        cursor: 'pointer',
      }}
      onClick={handleSelectChange}
    >
      <Checkbox
        checked={isSelected}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Typography variant='body2' style={{ overflowWrap: 'break-word' }}>
        {currentCell}
      </Typography>
    </Grid>
  );
};
