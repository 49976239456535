import React, { useState } from 'react';
import { IDialogLegalTransactionTypeMapping } from '../../../Interfaces/IDialogLegalTransactionTypeMapping';
import { ILegalTransactionType } from '../../../Interfaces/ILegalTransactionType';
import { IconButton, MenuItem, TableCell, TableRow } from '@mui/material';
import { CustomeTextField } from '../../../generic/CustomeTextField';
import { Save, SettingsBackupRestore } from '@mui/icons-material';
import { ShowWasSaved } from '../../../core/ShowWasSaved';
import { uploadFetch } from '../../../hooks/useFetch';

interface IProps {
  legalTransactionTypeArray: ILegalTransactionType[];
  currentMapping: IDialogLegalTransactionTypeMapping;
  dialogMappingArray: IDialogLegalTransactionTypeMapping[];
  setDialogMappingArray: Function;
}

export const DialogMappingEntry: React.FC<IProps> = ({
  legalTransactionTypeArray,
  currentMapping,
  dialogMappingArray,
  setDialogMappingArray,
}) => {
  const [editMapping, setEditMapping] = useState(currentMapping);
  //
  const [isLoading, setIsLoading] = useState(false);
  const [wasSaved, setWasSaved] = useState(false);
  const [wasSuccessfully, setWasSuccessfully] = useState(true);

  const handleAfterSave = (
    updatedObject: IDialogLegalTransactionTypeMapping
  ) => {
    setDialogMappingArray([
      ...dialogMappingArray.map(x =>
        x.idDialogLegalTransactionTypeMapping ===
        updatedObject.idDialogLegalTransactionTypeMapping
          ? updatedObject
          : x
      ),
    ]);
  };

  const handleSave = () => {
    setWasSuccessfully(true);
    uploadFetch(
      '/dialoglegaltransactiontypemapping',
      false,
      editMapping,
      handleAfterSave,
      setWasSuccessfully,
      setWasSaved,
      setIsLoading
    );
  };

  return (
    <>
      <ShowWasSaved
        setWasSaved={setWasSaved}
        wasSaved={wasSaved}
        wasSuccessfullySaved={wasSuccessfully}
      />

      <TableRow>
        <TableCell>{currentMapping.Dialog}</TableCell>
        <TableCell>
          <CustomeTextField
            label='Rechtsgeschäftsart'
            attr='idLegalTransactionType'
            object={editMapping}
            setObject={setEditMapping}
            type='select'
            disabled={isLoading}
          >
            {legalTransactionTypeArray.map(x => (
              <MenuItem
                key={`mapping-type-${x.idLegalTransactionType}`}
                value={x.idLegalTransactionType}
              >
                {x.LegalTransactionType}
              </MenuItem>
            ))}
          </CustomeTextField>
        </TableCell>
        <TableCell>
          <IconButton
            onClick={handleSave}
            disabled={
              isLoading ||
              editMapping.idLegalTransactionType ===
                currentMapping.idLegalTransactionType
            }
            title='Speichern'
          >
            <Save />
          </IconButton>

          <IconButton
            onClick={() =>
              setEditMapping({
                ...editMapping,
                idLegalTransactionType: currentMapping.idLegalTransactionType,
              })
            }
            disabled={
              isLoading ||
              editMapping.idLegalTransactionType ===
                currentMapping.idLegalTransactionType
            }
            title='Zurücksetzen'
          >
            <SettingsBackupRestore />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
