import React, { useState } from 'react';
import { IDocumentDirectory } from '../../Interfaces/IDocumentDirectory';
import { TreeItem } from '@mui/x-tree-view';
import { DocumentDirectoryEdit } from './DocumentDirectoryEdit';
import { DocumentDirectoryDocumentNew } from './DocumentDirectoryDocumentNew';
import { DocumentDirectoryDocument } from './DocumentDirectoryDocument';
import { IDocumentDirectoryHasDocument } from '../../Interfaces/IDocumentDirectoryHasDocument';
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from '@mui/material';
import { CreateNewFolder, Delete, Edit, UploadFile } from '@mui/icons-material';

interface IProps {
  documentDirecotryObject: IDocumentDirectory;
  documentArray: IDocumentDirectoryHasDocument[];
  documentDirectoryArray: IDocumentDirectory[];
  setDocumentArray: Function;
  setDocumentDirectoryArray: Function;
}

export const DocumentDirectoryTreeEntry: React.FC<IProps> = props => {
  const [isOpenNewDirectory, setIsOpenNewDirectory] = useState(false);
  const [isOpenDocument, setIsOpenDocument] = useState(false);
  //
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  //
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  //
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);

  const handleRightClick = (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setMouseX(e.clientX);
    setMouseY(e.clientY);
    setIsMenuOpen(true);
  };

  const handleClickMenu = (fun: Function) => {
    setIsMenuOpen(false);
    fun();
  };

  const removeMe = (
    idDocumentDirecory: number,
    tmpDocumentDirectoryArray: IDocumentDirectory[],
    tmpDocumentArray: IDocumentDirectoryHasDocument[]
  ): [IDocumentDirectory[], IDocumentDirectoryHasDocument[]] => {
    tmpDocumentDirectoryArray
      .filter(x => x.Parent_idDocumentDirectory === idDocumentDirecory)
      .map(x => {
        [tmpDocumentDirectoryArray, tmpDocumentArray] = removeMe(
          x.idDocumentDirectory,
          tmpDocumentDirectoryArray,
          tmpDocumentArray
        );
      });

    return [
      tmpDocumentDirectoryArray.filter(
        x => x.idDocumentDirectory !== idDocumentDirecory
      ),
      tmpDocumentArray.filter(
        x => x.idDocumentDirectory !== idDocumentDirecory
      ),
    ];
  };

  const handleRemove = () => {
    let tmpDocumentDirectoryArray = [...props.documentDirectoryArray];
    let tmpDocumentArray = [...props.documentArray];
    [tmpDocumentDirectoryArray, tmpDocumentArray] = removeMe(
      props.documentDirecotryObject.idDocumentDirectory,
      tmpDocumentDirectoryArray,
      tmpDocumentArray
    );
    props.setDocumentArray([...tmpDocumentArray]);
    props.setDocumentDirectoryArray([...tmpDocumentDirectoryArray]);
  };

  return (
    <>
      <DocumentDirectoryEdit
        isOpen={isOpenNewDirectory}
        setIsOpen={setIsOpenNewDirectory}
        documentDirectoryArray={props.documentDirectoryArray}
        setDocumentDirectoryArray={props.setDocumentDirectoryArray}
        parent_idDocumentDirectory={
          props.documentDirecotryObject.idDocumentDirectory
        }
      />

      <DocumentDirectoryDocumentNew
        documentArray={props.documentArray}
        setDocumentArray={props.setDocumentArray}
        isOpen={isOpenDocument}
        setIsOpen={setIsOpenDocument}
        idDocumentDirecotry={props.documentDirecotryObject.idDocumentDirectory}
      />

      <DocumentDirectoryEdit
        isOpen={isOpenEdit}
        setIsOpen={setIsOpenEdit}
        documentDirectoryArray={props.documentDirectoryArray}
        setDocumentDirectoryArray={props.setDocumentDirectoryArray}
        parent_idDocumentDirectory={
          props.documentDirecotryObject.idDocumentDirectory
        }
        currentObject={props.documentDirecotryObject}
      />

      <Menu
        key={`menu-idDocumentDirecotry-${props.documentDirecotryObject.idDocumentDirectory}`}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        anchorReference='anchorPosition'
        anchorPosition={{ top: mouseY, left: mouseX }}
      >
        <MenuList dense>
          <MenuItem
            onClick={() => handleClickMenu(() => setIsOpenDocument(true))}
          >
            <ListItemIcon>
              <UploadFile />
            </ListItemIcon>
            <ListItemText>Datei(en) hochladen</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => handleClickMenu(() => setIsOpenNewDirectory(true))}
          >
            <ListItemIcon>
              <CreateNewFolder />
            </ListItemIcon>
            <ListItemText>Neuer Unterordner</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => handleClickMenu(() => setIsOpenEdit(true))}>
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText>Umbenennen</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleClickMenu(() => handleRemove())}>
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText>Löschen</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
      <TreeItem
        nodeId={`idDocumentDirectory-${props.documentDirecotryObject.idDocumentDirectory}`}
        label={props.documentDirecotryObject.Title}
        onContextMenu={handleRightClick}
      >
        {props.documentDirectoryArray
          .filter(
            x =>
              x.Parent_idDocumentDirectory ===
              props.documentDirecotryObject.idDocumentDirectory
          )
          .map(x => (
            <DocumentDirectoryTreeEntry
              key={`idDocumentDirectory-${x.idDocumentDirectory}`}
              documentDirecotryObject={x}
              documentArray={props.documentArray}
              documentDirectoryArray={props.documentDirectoryArray}
              setDocumentArray={props.setDocumentArray}
              setDocumentDirectoryArray={props.setDocumentDirectoryArray}
            />
          ))}
        {props.documentArray
          .filter(
            x =>
              x.idDocumentDirectory ==
              props.documentDirecotryObject.idDocumentDirectory
          )
          .map(x => (
            <DocumentDirectoryDocument
              documentObject={x}
              documentArray={props.documentArray}
              setDocumentArray={props.setDocumentArray}
            />
          ))}
        {/*
                <TreeItem 
                    nodeId={`new-directory-${props.documentDirecotryObject.idDocumentDirectory}`} 
                    label="Ordner hinzufügen...."
                    sx={{fontStyle: "italic"}}
                    onClick={() => setIsOpenNewDirectory(true)}
                />
                <TreeItem 
                    nodeId={`new-file-${props.documentDirecotryObject.idDocumentDirectory}`} 
                    label="Datei hinzufügen...."
                    sx={{fontStyle: "italic"}}
                    onClick={() => setIsOpenDocument(true)}
                />
                 */}
      </TreeItem>
    </>
  );
};
