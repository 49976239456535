import React, { useEffect, useState } from 'react';
import { ILegalPhaseFullObject } from '../../Interfaces/ILegalPhase';
import { Alert, Grid, Typography } from '@mui/material';
import { LegalRequirementRow } from './LegalRequirementRow';
import { ILegalRequirementState } from '../../Interfaces/ILegalRequirementState';
import { IDocumentDownloaded } from '../../Interfaces/IDocumentDownloaded';
import { ILegalRequirement } from '../../Interfaces/ILegalRequirement';
import { LegalInfoText } from './LegalInfoText';
import { ILegalRequirementTemplate } from '../../Interfaces/ILegalRequirementTemplate';

interface IProps {
  legalPhaseFullObject: ILegalPhaseFullObject;
  legalPhaseFullObjectArray: ILegalPhaseFullObject[];
  setLegalPhaseFullObjectArray: Function;
  legalReqState: ILegalRequirementState[];
  legalRequirementTemplateArray: ILegalRequirementTemplate[];
  downloadedArray?: IDocumentDownloaded[];
  startdatedeadline: Date | undefined;
}

export const LegalPhaseRow: React.FC<IProps> = props => {
  //const [legalPhaseObject,setLegalPhaseObject] = useState(props.legalPhaseFullObject);
  //const [legalReqArray,setLegalReqArray] = useState(props.legalPhaseFullObject.LegalRequirementArray);
  //
  const [isNotDisabled, setIsNotDisabled] = useState(true);

  const setLegalPhaseObject = (updatedObject: ILegalPhaseFullObject) => {
    props.setLegalPhaseFullObjectArray([
      ...props.legalPhaseFullObjectArray.map(x =>
        x.idLegalPhase === updatedObject.idLegalPhase ? updatedObject : x
      ),
    ]);
  };

  const setLegalReqArray = (updatedArray: ILegalRequirement[]) => {
    setLegalPhaseObject({
      ...props.legalPhaseFullObject,
      isComplete: updatedArray.every(x => x.idLegalRequirementState == 40),
      LegalRequirementArray: updatedArray,
    });
  };

  /*
    useEffect(() => {
        setLegalPhaseObject({
            ...props.legalPhaseFullObject,
            isComplete: legalReqArray.every(x => x.idLegalRequirementState == 40),
            LegalRequirementArray: legalReqArray
        })
    },[legalReqArray])
    */

  /*
    useEffect(() => {
        props.setLegalPhaseFullObjectArray([
            ...props.legalPhaseFullObjectArray.map(x => x.idLegalPhase === legalPhaseObject.idLegalPhase ? legalPhaseObject : x)
        ])
    },[legalPhaseObject])
    */

  useEffect(() => {
    setIsNotDisabled(
      props.legalPhaseFullObject.PreconditionArray.length === 0 ||
        props.legalPhaseFullObject.PreconditionArray.every(x => {
          const testObject = props.legalPhaseFullObjectArray.find(
            y => y.idLegalPhase === x.idLegalPhase
          );

          if (testObject === undefined) {
            return false;
          } else {
            return testObject.isComplete == true;
          }
        })
    );
  }, [props.legalPhaseFullObjectArray]);

  return (
    <>
      <Grid item sm={12}>
        <Alert
          variant={isNotDisabled ? 'standard' : 'outlined'}
          severity={props.legalPhaseFullObject.isComplete ? 'success' : 'info'}
          action={
            <>
              <LegalInfoText currentObject={props.legalPhaseFullObject} />
            </>
          }
        >
          {props.legalPhaseFullObject.LegalPhase}
          {props.legalPhaseFullObject.AdministrativeUnit !== null &&
            props.legalPhaseFullObject.AdministrativeUnit !== undefined && (
              <>
                <br />
                <Typography variant='caption' mb={1} mt={1}>
                  Zugewiesene Gemarkung:{' '}
                  {props.legalPhaseFullObject.AdministrativeUnit}
                </Typography>
              </>
            )}

          {props.legalPhaseFullObject.PreconditionArray.length !== 0 && (
            <>
              <br />
              <Typography variant='caption'>Vorgänger:</Typography>{' '}
            </>
          )}
          {
            <Typography variant='caption'>
              {props.legalPhaseFullObject.PreconditionArray.map(
                x => x.LegalPhase
              ).join(', ')}
            </Typography>
          }
        </Alert>
      </Grid>

      {props.legalPhaseFullObject.LegalRequirementArray.map(x => (
        <LegalRequirementRow
          key={`idLegalRequirement-${x.idLegalRequirement}`}
          legalRequirement={x}
          legalRequirementArray={
            props.legalPhaseFullObject.LegalRequirementArray
          }
          setLegalRequirementArray={setLegalReqArray}
          isNotDisabled={isNotDisabled}
          legalReqState={props.legalReqState}
          downloadedArray={props.downloadedArray}
          startDeadlineDate={props.startdatedeadline}
        />
      ))}
    </>
  );
};
