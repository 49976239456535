import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  ILegalRequirementTemplate,
  ILegalRequirementTemplateFullObject,
} from '../Interfaces/ILegalRequirementTemplate';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { useSelector } from 'react-redux';
import { State } from '../redux/mainReducer';
import { TextModuleSelector } from '../TextModule/TextModuleSelector';
import { CustomEditor } from '../core/CustomEditor';
import { ICourtCourtRole } from '../Interfaces/IAemter';
import { useFetch } from '../hooks/useFetch';
import { LegalRequirementAddCourt } from './LegalRequirementAddCourt';
import { Edit } from '@mui/icons-material';
import { ILegalPhaseTemplateFullObject } from '../Interfaces/ILegalPhaseTemplate';
import { ILegalRequirementTemplateDeadline } from '../Interfaces/ILegalRequirementTemplate_deadlines';
import { AddPrerequisiteDeadline } from './AddPrerequisiteDeadline';
import { LegalRequirementDeadlineTable } from './LegalRequirementDeadlineTable';

interface IProps {
  currentRequirement: ILegalRequirementTemplateFullObject;
  setCurrentRequirement: Function;
  legalRequirements: ILegalRequirementTemplateFullObject[];
  legalPhaseTemplateArray?: ILegalPhaseTemplateFullObject[];
}

export const LegalRequirementEdit: React.FC<IProps> = props => {
  // Redux
  const legalRequirementStateArray = useSelector(
    (state: State) =>
      state.legalRequirementStateArray.storeLegalRequirementState
  );

  const [
    courtcourtRoleArray,
    setCourtCourtRoleArray,
    wasSuccessfullyLoadCourt,
  ] = useFetch<ICourtCourtRole[]>('/courtcourtrole');

  const [courtcourtroleDialogOpen, setCourtcourtroleDialogOpen] =
    useState(false);

  const [addPrerequisteDeadlineOpen, setAddPrerequisteDeadlineOpen] =
    useState(false);

  const getCourtCourtRoleCurrentProcessingTime = useCallback(
    (idCourt: number, idCourtRole: number) => {
      if (courtcourtRoleArray === undefined) return null;
      const courtCourtRole = courtcourtRoleArray.find(
        x => x.idCourt === idCourt && x.idCourtRole === idCourtRole
      );
      return courtCourtRole?.currentProcessingTime
        ? courtCourtRole.currentProcessingTime
        : courtCourtRole?.initalProcessingTime;
    },
    [courtcourtRoleArray]
  );

  const handleChangePrerequisteDeadline = useCallback(
    (changedPrequiste: ILegalRequirementTemplateDeadline) => {
      const newArray = props.currentRequirement.DeadlineArray
        ? props.currentRequirement.DeadlineArray
        : [];

      if (changedPrequiste.idDeadline === -1) {
        changedPrequiste.idDeadline = Math.floor(Math.random() * 1000000);
        newArray.push(changedPrequiste);
      } else {
        const index = newArray.findIndex(
          x => x.idDeadline === changedPrequiste.idDeadline
        );
        if (index === -1) {
          newArray.push(changedPrequiste);
        } else {
          newArray[index] = changedPrequiste;
        }
      }
      props.setCurrentRequirement({
        ...props.currentRequirement,
        DeadlineArray: newArray,
      });
    },
    [props]
  );

  const handleDeleteDeadline = useCallback(
    (index: number) => {
      const newArray = props.currentRequirement.DeadlineArray
        ? props.currentRequirement.DeadlineArray
        : [];
      newArray.splice(index, 1);
      props.setCurrentRequirement({
        ...props.currentRequirement,
        DeadlineArray: newArray,
      });
    },
    [props]
  );

  // Normale State

  /*
    const [editorState, setEditorState] = useState<EditorState>(EditorState.createWithContent(
        (props.currentRequirement.EmailBody === null)
        ? ContentState.createFromText("")
        : ContentState.createFromBlockArray(htmlToDraft(props.currentRequirement.EmailBody).contentBlocks)
      ),);

    const onEditorStateChange = (editorState: EditorState) => {
        setEditorState(editorState);

        if (props.currentRequirement) {
            props.setCurrentRequirement({
                ...props.currentRequirement,
                EmailBody: draftToHtml(convertToRaw(editorState.getCurrentContent()))
            })
        }
    };
    */
  if (!wasSuccessfullyLoadCourt || !wasSuccessfullyLoadCourt) {
    return <div>Fehler beim Laden der Daten</div>;
  } else if (courtcourtRoleArray === undefined) {
    return (
      <div>
        <CircularProgress></CircularProgress>
      </div>
    );
  }
  return (
    <Box sx={{ m: 2 }}>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <TextField
            label='Bedingung'
            value={props.currentRequirement.LegalRequirementTemplate}
            error={props.currentRequirement.LegalRequirementTemplate === ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                LegalRequirementTemplate: event.target.value,
              })
            }
            size='small'
            fullWidth
          />
        </Grid>

        <Grid item sm={6}>
          <TextField
            label='Mitarbeiter kann Dateien hochladen'
            value={
              props.currentRequirement.canUploadFilesEmployees == true
                ? 'true'
                : 'false'
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                canUploadFilesEmployees: event.target.value === 'true',
              })
            }
            size='small'
            fullWidth
            select
          >
            <MenuItem value='true'>Ja</MenuItem>
            <MenuItem value='false'>Nein</MenuItem>
          </TextField>
        </Grid>
        <Grid item sm={6}>
          <TextField
            label='Mandant kann Dateien hochladen'
            value={
              props.currentRequirement.canUploadFilesClient == true
                ? 'true'
                : 'false'
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                canUploadFilesClient: event.target.value === 'true',
              })
            }
            size='small'
            fullWidth
            select
          >
            <MenuItem value='true'>Ja</MenuItem>
            <MenuItem value='false'>Nein</MenuItem>
          </TextField>
        </Grid>

        <Grid item sm={5}>
          <TextField
            label='Startstatus'
            value={props.currentRequirement.idLegalRequirementState}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                idLegalRequirementState: Number(event.target.value),
              })
            }
            size='small'
            fullWidth
            select
          >
            {legalRequirementStateArray.map(x => (
              <MenuItem
                key={`idLegalRequirementState-${x.idLegalRequirementState}`}
                value={x.idLegalRequirementState}
              >
                {x.LegalRequirementState}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item sm={3}>
          <TextField
            label='Vorlage im MA-Katalog'
            disabled={props.currentRequirement.idLegalPhaseTemplate === null}
            value={
              props.currentRequirement.isTemplate == true ? 'true' : 'false'
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                isTemplate: event.target.value === 'true',
              })
            }
            size='small'
            fullWidth
            select
          >
            <MenuItem value='true'>Ja</MenuItem>
            <MenuItem value='false'>Nein</MenuItem>
          </TextField>
        </Grid>

        <Grid item sm={4}>
          <TextField
            label='Vorgänger (Bedingung)'
            value={
              props.currentRequirement.Predecessor_idLegalRequirementTemplate ==
              undefined
                ? ''
                : props.currentRequirement
                    .Predecessor_idLegalRequirementTemplate
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                Predecessor_idLegalRequirementTemplate: Number(
                  event.target.value
                ),
              })
            }
            size='small'
            fullWidth
            select
          >
            {props.legalRequirements.map(x => (
              <MenuItem
                key={`predecessor-idLegalRequirementTemplate-${x.idLegalRequirementTemplate}`}
                value={x.idLegalRequirementTemplate}
              >
                {x.LegalRequirementTemplate}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item sm={12}>
          <Box
            component='fieldset'
            sx={{
              borderColor: '#ccc',
              border: 1,
              borderRadius: 4,
              padding: 2,
              marginTop: 4,
              position: 'relative',
            }}
          >
            <Typography
              component='legend'
              sx={{ fontSize: '0.875rem', padding: '0 8px' }}
            >
              Fristen und Gerichte
            </Typography>
            <Grid container spacing={2} justifyContent='center'>
              {props.currentRequirement.idCourt === null &&
                props.currentRequirement.idCourtRole === null && (
                  <>
                    <Grid
                      item
                      xs={12}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                    >
                      <Tooltip title='Die geschätzte Bearbeitungszeit ist die geschätzte Zeit für den Abschluss dieser Maßnahme unter normalen Umständen. Sollten Sie die geschätzte Bearbeitungszeit ändern, wird nach dem Speichern eine neue Kalkulation der berechneten Bearbeitungszeit auf Grundlage der historischen Daten und der geschätzen Bearbeitungszeit durchgeführt. Sie sehen das neue Ergebnis, wenn Sie diese Maßnahme erneut laden'>
                        <TextField
                          label='Geschätzte Bearbeitungszeit'
                          type='number'
                          value={
                            props.currentRequirement.initialProcessingTime
                              ? props.currentRequirement.initialProcessingTime
                              : ''
                          }
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) =>
                            props.setCurrentRequirement({
                              ...props.currentRequirement,
                              initialProcessingTime: Number(event.target.value),
                            })
                          }
                          size='small'
                          sx={{ width: '50%' }}
                        />
                      </Tooltip>
                      <Tooltip title='Die berechnete Bearbeitungszeit ist die durchschnittliche Zeit, die für die Bearbeitung dieser Maßnahme benötigt wird. Die Berechnung basiert auf den Daten bereits abgeschlossener Maßnahmen. Sollten noch keine Daten vorliegen, wird die geschätzte Bearbeitungszeit angezeigt.'>
                        <Typography
                          variant='body1'
                          color='text.secondary'
                          sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            textAlign: 'right',
                          }}
                        >
                          Berechnete Bearbeitungszeit:{' '}
                          {props.currentRequirement.currentProcessingTime !==
                          null
                            ? `${props.currentRequirement.currentProcessingTime} Tage`
                            : props.currentRequirement.initialProcessingTime !==
                                null
                              ? `${props.currentRequirement.initialProcessingTime} Tage`
                              : 'noch nicht festgelegt'}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      display='flex'
                      alignItems='center'
                      justifyContent='space-between'
                    ></Grid>
                    <Tooltip title='Alternativ können Sie auch ein zuständiges Amt für diese Maßnahme festlegen. Die Bearbeitungszeit wird dann automatisch basierend auf den Daten des zuständigen Amts kaluliert.'>
                      <Button
                        variant='contained'
                        color='primary'
                        sx={{ mt: 1 }}
                        onClick={() => {
                          setCourtcourtroleDialogOpen(true);
                        }}
                      >
                        Zuständiges Amt festlegen
                      </Button>
                    </Tooltip>
                  </>
                )}
              <Grid item xs={12}>
                <Box>
                  {props.currentRequirement.idCourt !== null &&
                    props.currentRequirement.idCourtRole !== null && (
                      <>
                        <Grid
                          item
                          xs={12}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <Tooltip title='Das Amt, welches für die Bearbeitung dieser Maßnahme zuständig ist.'>
                            <Typography variant='body1'>
                              Amt: {props.currentRequirement.CourtRole}{' '}
                              {props.currentRequirement.Court}
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <Tooltip title='Die berechnete Bearbeitungszeit ist die durchschnittliche Zeit, die für die Bearbeitung durch das zuständige Amt benötigt wird. Die Berechnung basiert auf den Daten bereits abgeschlossener Maßnahmen. Sollten noch keine Daten vorliegen, wird die geschätzte Bearbeitungszeit des Gerichts angezeigt.'>
                            <Typography variant='subtitle1'>
                              Berechnete Bearbeitungszeit:{' '}
                              {getCourtCourtRoleCurrentProcessingTime(
                                props.currentRequirement.idCourt,
                                props.currentRequirement.idCourtRole
                              )}{' '}
                              Tage
                            </Typography>
                          </Tooltip>
                        </Grid>
                        <Box display='flex' gap={2}>
                          <Tooltip title='Ändern Sie die Zuweisung zu einem anderen Amt'>
                            <Button
                              variant='contained'
                              color='primary'
                              sx={{ mt: 1 }}
                              onClick={() => {
                                setCourtcourtroleDialogOpen(true);
                              }}
                            >
                              Zuweisung ändern
                            </Button>
                          </Tooltip>
                          <Tooltip title='Alternativ können Sie auch die Zuweisung zu einem Amt löschen. Die Bearbeitungszeit wird dann basierend auf historischen Daten der Maßnahme berechnet.'>
                            <Button
                              variant='contained'
                              color='warning'
                              sx={{ mt: 1 }}
                              onClick={() => {
                                props.setCurrentRequirement({
                                  ...props.currentRequirement,
                                  idCourt: null,
                                  idCourtRole: null,
                                  Court: null,
                                  CourtRole: null,
                                });
                              }}
                            >
                              Zuweisung löschen
                            </Button>
                          </Tooltip>
                        </Box>
                      </>
                    )}

                  <Divider>
                    <Typography variant='subtitle1'>
                      Startbedingungen
                    </Typography>
                  </Divider>
                  <Grid item xs={12} display={'flex'} justifyContent={'right'}>
                    <Tooltip title='Bearbeiten Sie die Startbedingungen'>
                      <IconButton
                        onClick={() => setAddPrerequisteDeadlineOpen(true)}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <LegalRequirementDeadlineTable
                    deadlineArray={props.currentRequirement.DeadlineArray}
                    setDeadline={handleChangePrerequisteDeadline}
                    deleteDeadline={handleDeleteDeadline}
                  ></LegalRequirementDeadlineTable>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item sm={12}>
          <TextModuleSelector
            key={`type-legalrequirementedit`}
            floatRight
            idTextModuleCategory={4}
            txt={props.currentRequirement.InfoText}
            setTxt={(txt: string) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                InfoText: txt,
              })
            }
          />
          <TextField
            label='Infotext'
            value={
              props.currentRequirement.InfoText === null
                ? ''
                : props.currentRequirement.InfoText
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                InfoText: event.target.value === '' ? null : event.target.value,
              })
            }
            size='small'
            fullWidth
            multiline
            rows={3}
          />
        </Grid>

        <Grid item sm={4}>
          <TextField
            label='Auto. E-Mail versenden (wenn erfüllt)'
            value={props.currentRequirement.sendMail == true ? 'true' : 'false'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                sendMail: event.target.value === 'true',
              })
            }
            size='small'
            fullWidth
            select
          >
            <MenuItem value='true'>Ja</MenuItem>
            <MenuItem value='false'>Nein</MenuItem>
          </TextField>
        </Grid>

        <Grid item sm={8}>
          <TextField
            label='E-Mail Betreff'
            value={props.currentRequirement.EmailSubject}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                EmailSubject: event.target.value,
              })
            }
            size='small'
            fullWidth
          />
        </Grid>

        <CustomEditor
          txt={
            props.currentRequirement.EmailBody === null
              ? ''
              : props.currentRequirement.EmailBody
          }
          setTxt={(txt: string) =>
            props.setCurrentRequirement({
              ...props.currentRequirement,
              EmailBody: txt === '' ? null : txt,
            })
          }
          idTextModuleCategory={3}
          dictonaryIdsArray={[1, 2, 3]}
        />

        <Grid item sm={6}>
          <TextField
            label='Fehler möglich'
            value={props.currentRequirement.hasError == true ? 'true' : 'false'}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                hasError: event.target.value === 'true',
              })
            }
            size='small'
            fullWidth
            select
          >
            <MenuItem value='true'>Ja</MenuItem>
            <MenuItem value='false'>Nein</MenuItem>
          </TextField>
        </Grid>
        <Grid item sm={6}>
          <TextField
            label='Freitext bei Fehler erlauben'
            value={
              props.currentRequirement.hasErrorFreeText == true
                ? 'true'
                : 'false'
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                hasErrorFreeText: event.target.value === 'true',
              })
            }
            size='small'
            fullWidth
            select
          >
            <MenuItem value='true'>Ja</MenuItem>
            <MenuItem value='false'>Nein</MenuItem>
          </TextField>
        </Grid>

        <Grid item sm={12}>
          <TextField
            label='Fehlermeldung'
            value={props.currentRequirement.TextOfError}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentRequirement({
                ...props.currentRequirement,
                TextOfError: event.target.value,
              })
            }
            size='small'
            fullWidth
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
      <LegalRequirementAddCourt
        legalRequirement={props.currentRequirement}
        courtCourtRoleArray={courtcourtRoleArray}
        open={courtcourtroleDialogOpen}
        onSave={(newLegalRequirement: ILegalRequirementTemplate) => {
          props.setCurrentRequirement(newLegalRequirement);
          setCourtcourtroleDialogOpen(false);
        }}
        onClose={() => setCourtcourtroleDialogOpen(false)}
      />
      <AddPrerequisiteDeadline
        open={addPrerequisteDeadlineOpen}
        currentLegalRequirementTemplate={props.currentRequirement}
        legalRequirementTemplateArray={props.legalRequirements}
        setDeadline={handleChangePrerequisteDeadline}
        onClose={() => setAddPrerequisteDeadlineOpen(false)}
        legalPhaseTemplateArray={props.legalPhaseTemplateArray}
      />
    </Box>
  );
};
