//This component is used to deactivate a user or a rolechange happens. It is used in the UserFullEdit component. It will be shown, when the user is saved.
//It will show a dialog, with all the legaltransactions of the edited user.
//The user, who is editing the user, can then choose, if he wants to change all the legaltransactions to another user, or pick them one by one.

import React, { useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { IUser } from '../Interfaces/IUser';
import { ILegalTransaction } from '../Interfaces/ILegalTransaction';
import { PopupAlert } from '../core/PopupAlert';
import { UserReassignSingle } from './Childs/UserReassignSingle';
import { IUserGroup } from '../Interfaces/IUserGroup';

interface IProps {
  userId: number;
  userArray: IUser[];
  usergroupArray: IUserGroup[];
  legalTransactionArray: ILegalTransaction[];
  setLegalTransactionArray: Function;
  isNotary: boolean;
  isRoleChange?: boolean;
}

export const UserReassign: React.FC<IProps> = props => {
  //const [filteredUser, setFilteredUser] = useState<IUser[]>([]);
  const [newUser, setNewUser] = useState<number>(-2);
  const [userType, setUserType] = useState<
    'Mitarbeiter' | 'Gruppe' | 'Keine Zuweisung' | 'Notar'
  >('Mitarbeiter');
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showAll, setShowAll] = useState<boolean>(false);

  const handleUserChange = (
    idUser: number,
    userType: 'Mitarbeiter' | 'Gruppe' | 'Keine Zuweisung' | 'Notar',
    index: number
  ) => {
    const newLegalTransactionArray = [...props.legalTransactionArray];

    if ('Keine Zuweisung' === userType) {
      newLegalTransactionArray[index].idUserGroup = idUser;

      newLegalTransactionArray[index].Employee_idUser = idUser;

      props.setLegalTransactionArray(newLegalTransactionArray);
      return;
    }
    if (userType === 'Gruppe') {
      newLegalTransactionArray[index].idUserGroup = idUser;
    } else {
      const userKey = props.isNotary ? 'Notary_idUser' : 'Employee_idUser';
      newLegalTransactionArray[index][userKey] = idUser;
    }
    props.setLegalTransactionArray(newLegalTransactionArray);
  };

  const handleRessignAll = (
    idUser: number,
    userType: 'Mitarbeiter' | 'Gruppe' | 'Keine Zuweisung' | 'Notar'
  ) => {
    for (const legalTransaction of props.legalTransactionArray) {
      const userToCheck =
        userType === 'Mitarbeiter' || userType === 'Notar'
          ? props.isNotary
            ? legalTransaction.Notary_idUser
            : legalTransaction.Employee_idUser
          : userType === 'Gruppe'
            ? legalTransaction.idUserGroup
            : null;

      if (userToCheck !== props.userId) {
        setNewUser(idUser);
        setUserType(userType);
        setShowDialog(true);
        return;
      }
    }
    reassignAll(idUser, userType, true);
  };

  const reassignAll = (
    idUser: number,
    userType: 'Mitarbeiter' | 'Gruppe' | 'Keine Zuweisung' | 'Notar',
    overwrite: boolean
  ) => {
    setNewUser(idUser);
    const newLegalTransactionArray = props.legalTransactionArray.map(
      legalTransaction => {
        if (
          'Keine Zuweisung' === userType &&
          (legalTransaction.idUserGroup !== idUser ||
            legalTransaction.Employee_idUser !== idUser) &&
          overwrite
        ) {
          legalTransaction.idUserGroup = idUser;
          legalTransaction.Employee_idUser = idUser;
          return legalTransaction;
        } else if (
          userType === 'Gruppe' &&
          legalTransaction.idUserGroup !== idUser &&
          overwrite
        ) {
          legalTransaction.idUserGroup = idUser;
          legalTransaction.Employee_idUser = null;
          return legalTransaction;
        } else if (userType === 'Mitarbeiter') {
          //const userKey = props.isNotary ? 'Notary_idUser' : 'Employee_idUser';
          const currentUser = legalTransaction.Employee_idUser;
          if (
            (overwrite && currentUser !== idUser) ||
            currentUser === props.userId
          ) {
            legalTransaction.Employee_idUser = idUser;

            legalTransaction.idUserGroup = null;
          }

          return legalTransaction;
        } else if (userType === 'Notar') {
          const currentUser = legalTransaction.Notary_idUser;
          if (
            (overwrite && currentUser !== idUser) ||
            currentUser === props.userId
          ) {
            legalTransaction.Notary_idUser = idUser;
          }
        }
        return legalTransaction;
      }
    );
    props.setLegalTransactionArray(newLegalTransactionArray);
  };

  if (props.legalTransactionArray.length < 1 || props.userArray.length < 1) {
    return (
      <PopupAlert
        show={true}
        setShow={() => {}}
        severity='error'
        text='Fehler beim Laden der dem Nutzer zugeordneten Vorgänge.'
      />
    );
  } else {
    return (
      <>
        <Grid container spacing={2} paddingLeft={4} paddingRight={4}>
          <Grid item xs={12} sm={12}>
            <Box display='flex' justifyContent='flex-end' alignItems='center'>
              <Typography variant='subtitle1'>Einzeln zuweisen</Typography>
              <Switch checked={showAll} onChange={() => setShowAll(!showAll)} />
            </Box>
          </Grid>
          {!showAll && (
            <>
              <Grid item xs={6} sm={6} display='flex' alignItems='center'>
                <Typography variant='subtitle1'>
                  Alle Vorgänge folgendem{' '}
                  {props.isNotary ? 'Notar' : 'Mitarbeiter'} zuweisen
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Box display='flex' alignItems='center'>
                  <UserReassignSingle
                    userArray={props.userArray}
                    userGroupArray={props.usergroupArray}
                    title={props.isNotary ? 'Neuer Notar' : 'Neuer Mitarbeiter'}
                    currentUserId={newUser === -2 ? '' : newUser}
                    reassignUser={handleRessignAll}
                    isNotary={props.isNotary}
                  ></UserReassignSingle>
                </Box>
              </Grid>
            </>
          )}
          {showAll && (
            <>
              <Grid item xs={6}>
                <Typography
                  variant='subtitle1'
                  gutterBottom
                  style={{ fontWeight: 'bold' }}
                >
                  Name des Vorgangs
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant='subtitle1'
                  gutterBottom
                  style={{ fontWeight: 'bold' }}
                >
                  Neuer {props.isNotary ? 'Notar' : 'Mitarbeiter'}
                </Typography>
              </Grid>

              {props.legalTransactionArray.map((legalTransaction, index) => (
                <Grid
                  container
                  item
                  xs={12}
                  key={legalTransaction.idLegalTransaction}
                >
                  <Grid item xs={6}>
                    <Typography
                      variant='subtitle1'
                      style={{
                        overflow: 'auto',
                      }}
                    >
                      {legalTransaction.Title}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <UserReassignSingle
                      userArray={props.userArray}
                      userGroupArray={props.usergroupArray}
                      title={
                        props.isNotary ? 'Neuer Notar' : 'Neuer Mitarbeiter'
                      }
                      currentUserId={
                        props.isNotary
                          ? props.userId === legalTransaction.Notary_idUser
                            ? ''
                            : legalTransaction.Notary_idUser
                          : legalTransaction.Employee_idUser !== null
                            ? props.userId === legalTransaction.Employee_idUser
                              ? ''
                              : legalTransaction.Employee_idUser
                            : legalTransaction.idUserGroup
                      }
                      reassignUser={handleUserChange}
                      index={index}
                      isNotary={props.isNotary}
                    ></UserReassignSingle>
                  </Grid>
                </Grid>
              ))}
            </>
          )}
        </Grid>
        <Dialog open={showDialog}>
          <DialogTitle>
            Sollen auch bereits vorgenommene Einzelzuweisungen überschrieben
            werden?
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                setShowDialog(false);
                reassignAll(newUser, userType, false);
              }}
              color='primary'
            >
              Nein
            </Button>
            <Button
              onClick={() => {
                setShowDialog(false);
                reassignAll(newUser, userType, true);
              }}
              color='primary'
            >
              Ja
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
};
