import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
} from '@mui/material';
import { useState } from 'react';
import type { IContact } from '../Interfaces/IContact';
import { useAlert } from '../context/AlertContext';

interface IProps {
  contacts: IContact[];
  onAddCustomId: (contactId: number, customId: string) => void;
  onClose: () => void;
}

export const AddCustomIdDialog = ({
  contacts,
  onAddCustomId,
  onClose,
}: IProps) => {
  const { showAlert } = useAlert();
  const [selectedContact, setSelectedContact] = useState<IContact | null>(null);
  const [customId, setCustomId] = useState<string>('');

  const handleSave = () => {
    if (selectedContact && customId) {
      onAddCustomId(selectedContact.idContact, customId);
    } else {
      showAlert({
        text: 'Bitte wählen Sie einen Mandanten und geben Sie eine Kennung ein.',
        severity: 'error',
      });
    }
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth='sm'>
      <DialogTitle>Mandantenkennung hinzufügen</DialogTitle>
      <DialogContent>
        <TextField
          label='Kontakt'
          sx={{ mt: 1 }}
          value={selectedContact?.idContact}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setSelectedContact(
              contacts.find(
                contact => contact.idContact === Number(event.target.value)
              ) ?? null
            )
          }
          fullWidth
          select
        >
          {contacts.map(contact => (
            <MenuItem
              key={`contact-${contact.idContact}`}
              value={contact.idContact}
            >
              {contact.FirstName} {contact.LastName}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          value={customId}
          onChange={e => setCustomId(e.target.value)}
          label='Mandantenkennung'
          variant='outlined'
          fullWidth
          margin='normal'
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Abbrechen</Button>
        <Button variant='contained' onClick={handleSave}>
          Hinzufügen
        </Button>
      </DialogActions>
    </Dialog>
  );
};
