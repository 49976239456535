import React, { useState } from 'react';
import { ILegalTransactionMeetingEmail } from '../../../Interfaces/ILegalTransactionMeetingEmail';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
} from '@mui/material';
import { Check, Close, Delete, Edit } from '@mui/icons-material';
import { LegalTransactionMeetingEmailEdit } from './LegalTransactionMeetingEmailEdit';

interface IProps {
  idLegalTransactionMeeting: number;
  meetingEmailObject: ILegalTransactionMeetingEmail;
  meetingEmailArray: ILegalTransactionMeetingEmail[];
  setMeetingEmailArray: Function;
}

export const LegalTransactionMeetingEmailRow: React.FC<IProps> = props => {
  const [isEditOpen, setIsEditOpen] = useState(false);

  const handleRemoveMe = () => {
    props.setMeetingEmailArray([
      ...props.meetingEmailArray.filter(
        x =>
          x.idLegalTransactionMeetingEmail !==
          props.meetingEmailObject.idLegalTransactionMeetingEmail
      ),
    ]);
  };

  return (
    <>
      <Dialog
        open={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        maxWidth='lg'
        fullWidth
      >
        <DialogTitle>E-Mail bearbeite</DialogTitle>
        <DialogContent>
          {isEditOpen && (
            <LegalTransactionMeetingEmailEdit
              idLegalTransactionMeeting={props.idLegalTransactionMeeting}
              idLegalTransactionMeetingEmail={
                props.meetingEmailObject.idLegalTransactionMeetingEmail
              }
              setIdLegalTransactionMeetingEmail={(a: any) =>
                setIsEditOpen(false)
              }
              isNew={false}
              setIsNew={() => {}}
              meetingEmailArray={props.meetingEmailArray}
              setMeetingEmailArray={props.setMeetingEmailArray}
            />
          )}
        </DialogContent>
      </Dialog>

      <TableRow>
        <TableCell>{props.meetingEmailObject.Title}</TableCell>
        <TableCell>
          {Boolean(props.meetingEmailObject.sendOnCreation) ? (
            <Check />
          ) : (
            <Close />
          )}
        </TableCell>
        <TableCell>
          {Boolean(props.meetingEmailObject.sendOnReschedule) ? (
            <Check />
          ) : (
            <Close />
          )}
        </TableCell>
        <TableCell>
          {Boolean(props.meetingEmailObject.sendOnCancelling) ? (
            <Check />
          ) : (
            <Close />
          )}
        </TableCell>
        <TableCell>{props.meetingEmailObject.DaysBeforeDate}</TableCell>
        <TableCell>
          {Boolean(props.meetingEmailObject.sendWithCalenderFile) ? (
            <Check />
          ) : (
            <Close />
          )}
        </TableCell>
        <TableCell>
          {Boolean(props.meetingEmailObject.allowDataUpload) ? (
            <Check />
          ) : (
            <Close />
          )}
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex' }}>
            <IconButton size='small' onClick={() => setIsEditOpen(true)}>
              <Edit />
            </IconButton>
            <IconButton size='small' onClick={handleRemoveMe}>
              <Delete />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};
