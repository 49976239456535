import { Delete, Edit } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import rollenbezeichnung from '../jsons/rollenbezeichnung.json';
import geschlechtJson from '../jsons/geschlecht.json';
import countryJson from '../jsons/country.json';
import vertretungsregelungJson from '../jsons/vertretungsregelung.json';

interface IProps {
  currentPerson: any;
  vertretung: any;
  setVertretung: Function;
  alleBeteiligungen: any;
}
export const VertretungsBerechtigteRow: React.FC<IProps> = props => {
  // Funktion zum Umwandeln des Arrays in ein Objekt
  const convertToObject = (datenArray: string[][]): Record<string, string> => {
    const result: Record<string, string> = {};
    datenArray.forEach(([code, name]) => {
      result[code] = name;
    });
    return result;
  };
  const convertToObject2 = (
    datenArray: (string | null)[][]
  ): Record<string, string> => {
    const result: Record<string, string> = {};
    datenArray.forEach(([code, nr, name]) => {
      if (code && name) {
        result[code] = name;
      }
    });
    return result;
  };
  const rollenbezeichnungObjekt = convertToObject(rollenbezeichnung.daten);
  const geschlechtObjekt = convertToObject(geschlechtJson.daten);
  const countryObjekt = convertToObject2(countryJson.daten);
  const vertretungsObjekt = convertToObject(vertretungsregelungJson.daten);
  //
  const rollenbezeichnungMap = new Map(
    rollenbezeichnung.daten.map(([code, wert]) => [code, wert])
  );
  const geschlechtMap = new Map(
    geschlechtJson.daten.map(([code, wert]) => [code, wert])
  );
  const countryMap = new Map(
    countryJson.daten.map(([code, nr, wert]) => [code, wert])
  );
  const vertretungMap = new Map(
    vertretungsregelungJson.daten.map(([code, wert]) => [code, wert])
  );
  //
  const valueToKeyRollenbezeichnungMap = new Map(
    Array.from(rollenbezeichnungMap.entries()).map(([key, value]) => [
      value,
      key,
    ])
  );
  const valueToKeyGeschlechtMap = new Map(
    Array.from(geschlechtMap.entries()).map(([key, value]) => [value, key])
  );
  const valueToKeyCountryMap = new Map(
    Array.from(countryMap.entries()).map(([key, value]) => [value, key])
  );
  const valueToKeyVertretungsregelungMap = new Map(
    Array.from(vertretungMap.entries()).map(([key, value]) => [value, key])
  );
  const [isOpen, setIsOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const [currentPerson, setCurrentPerson] = useState(props.currentPerson);
  useEffect(() => {
    setCurrentPerson(props.currentPerson);
  }, [props.currentPerson]);
  useEffect(() => {}, [currentPerson]);
  const [editedPerson, setEditedPerson] = useState(
    currentPerson ? JSON.parse(JSON.stringify(currentPerson)) : {}
  );
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [allgemeineVertretungsregelung, setAllgemeineVertretungsregelung] =
    useState(
      props.currentPerson?.['tns:besondereVertretungsregelung']?.[
        'tns:auswahl_vertretungsbefugnis'
      ]?.['tns:vertretungsbefugnis']?.['code'] || ''
    );
  const [
    allgemeineVertretungsregelungFreitext,
    setAllgemeineVertretungsregelungFreitext,
  ] = useState(
    props.currentPerson?.['tns:besondereVertretungsregelung']?.[
      'tns:auswahl_vertretungsbefugnis'
    ]?.['tns:vertretungsbefugnisFreitext'] || ''
  );

  const [currentPersonFullName, setCurrentPersonFullName] = useState<string>(
    `${
      props.alleBeteiligungen[0].find(
        (x: any) =>
          x?.['tns:rolle']?.['tns:rollennummer'] ===
          props.currentPerson?.['tns:ref.rollennummer']
      )?.['tns:beteiligter']?.['tns:auswahl_beteiligter']?.[
        'tns:natuerlichePerson'
      ]?.['tns:vollerName']?.['tns:vorname'] ||
      props.alleBeteiligungen[0].find(
        (x: any) =>
          x?.['tns:rolle']?.['tns:rollennummer'] ===
          props.currentPerson?.['tns:ref.rollennummer']
      )?.['tns:beteiligter']?.['tns:auswahl_beteiligter']?.[
        'tns:organisation'
      ]?.['tns:bezeichnung']?.['tns:bezeichnung.aktuell']
    } ${
      props.alleBeteiligungen[0].find(
        (x: any) =>
          x?.['tns:rolle']?.['tns:rollennummer'] ===
          props.currentPerson?.['tns:ref.rollennummer']
      )?.['tns:beteiligter']?.['tns:auswahl_beteiligter']?.[
        'tns:natuerlichePerson'
      ]?.['tns:vollerName']?.['tns:nachname'] || ''
    }`
  );

  const [selectedRollennummer, setSelectedRollennummer] = useState<
    string | null
  >(props.currentPerson?.['tns:ref.rollennummer']);

  //

  //

  /**
   * All Fuctions
   */

  // handleOpen Function
  const handleOpen = () => {
    setAllgemeineVertretungsregelung(
      currentPerson?.['tns:besondereVertretungsregelung']?.[
        'tns:auswahl_vertretungsbefugnis'
      ]?.['tns:vertretungsbefugnis']?.['code'] || ''
    );
    setCurrentPersonFullName(
      `${
        props.alleBeteiligungen[0].find(
          (x: any) =>
            x?.['tns:rolle']?.['tns:rollennummer'] ===
            props.currentPerson?.['tns:ref.rollennummer']
        )?.['tns:beteiligter']?.['tns:auswahl_beteiligter']?.[
          'tns:natuerlichePerson'
        ]?.['tns:vollerName']?.['tns:vorname'] ||
        props.alleBeteiligungen[0].find(
          (x: any) =>
            x?.['tns:rolle']?.['tns:rollennummer'] ===
            props.currentPerson?.['tns:ref.rollennummer']
        )?.['tns:beteiligter']?.['tns:auswahl_beteiligter']?.[
          'tns:organisation'
        ]?.['tns:bezeichnung']?.['tns:bezeichnung.aktuell']
      } ${
        props.alleBeteiligungen[0].find(
          (x: any) =>
            x?.['tns:rolle']?.['tns:rollennummer'] ===
            props.currentPerson?.['tns:ref.rollennummer']
        )?.['tns:beteiligter']?.['tns:auswahl_beteiligter']?.[
          'tns:natuerlichePerson'
        ]?.['tns:vollerName']?.['tns:nachname'] || ''
      }`
    );
    if (currentPerson) {
      setEditedPerson(JSON.parse(JSON.stringify(currentPerson)));
    } else {
      setEditedPerson([]); // Set to an empty array if currentPerson is undefined
    }
    setIsOpen(true);
  };
  // remove Function
  const handleRemove = () => {
    const updatedVertretung = props.vertretung;
    let updated;
    if (Array.isArray(updatedVertretung?.['tns:vertretungsberechtigte'])) {
      updated = updatedVertretung['tns:vertretungsberechtigte'].filter(
        (x: any) =>
          x['tns:ref.rollennummer'] !==
          props.currentPerson['tns:ref.rollennummer']
      );
    } else {
      updated = '';
    }

    setSaveButtonDisabled(true);
    props.setVertretung({
      ...props.vertretung,
      'tns:vertretungsberechtigte': updated,
    });

    setIsOpen(false);
  };

  // handle Save function
  const handleSave = () => {
    const updatedVertretungsberechtigte =
      props.vertretung['tns:vertretungsberechtigte'];
    let updated;
    if (updatedVertretungsberechtigte.length > 1) {
      updated = updatedVertretungsberechtigte.map((x: any) =>
        x['tns:ref.rollennummer'] === currentPerson['tns:ref.rollennummer']
          ? editedPerson
          : x
      );
    } else {
      updated = editedPerson;
    }
    props.setVertretung({
      ...props.vertretung,
      'tns:vertretungsberechtigte': updated,
    });
    setCurrentPerson(editedPerson);
    setSaveButtonDisabled(true);
    setIsOpen(false);
  };

  /**
   * Form Function
   */
  const handleOnSave = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      handleSave();
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements);
      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  const handleOnStop = () => {
    setSaveButtonDisabled(true);
    setIsOpen(false);
  };

  //

  //

  /**
   * useEffects
   */

  //
  useEffect(() => {
    if (editedPerson?.['tns:ref.rollennummer']) {
      editedPerson['tns:ref.rollennummer'] = selectedRollennummer;
      if (
        editedPerson?.['tns:besondereVertretungsregelung']?.[
          'tns:auswahl_vertretungsbefugnis'
        ]?.['tns:vertretungsbefugnis']?.['code']
      ) {
        editedPerson['tns:besondereVertretungsregelung'][
          'tns:auswahl_vertretungsbefugnis'
        ]['tns:vertretungsbefugnis']['code'] = allgemeineVertretungsregelung;
      }
      if (
        editedPerson?.['tns:besondereVertretungsregelung']?.[
          'tns:auswahl_vertretungsbefugnis'
        ]?.['tns:vertretungsbefugnisFreitext']
      ) {
        editedPerson['tns:besondereVertretungsregelung'][
          'tns:auswahl_vertretungsbefugnis'
        ]['tns:vertretungsbefugnisFreitext'] =
          allgemeineVertretungsregelungFreitext;
      }
    }
  }, [
    currentPersonFullName,
    allgemeineVertretungsregelung,
    allgemeineVertretungsregelungFreitext,
  ]);

  //

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        maxWidth='lg'
        fullWidth
      >
        <DialogContent>
          <form ref={formRef}>
            <Grid container spacing={2} mt={2}>
              <Grid item sm={12}>
                <Typography variant='h5'>Vertretungsberechtigter</Typography>
              </Grid>
              <Grid item sm={12}>
                <TextField
                  label='Name'
                  select
                  fullWidth
                  value={currentPersonFullName} // sicherstellen, dass der value korrekt ist
                  onChange={e => {
                    setCurrentPersonFullName(e.target.value);
                    setSaveButtonDisabled(false);
                  }}
                  variant='outlined'
                >
                  {props.alleBeteiligungen[0]
                    .filter(
                      (y: any) =>
                        y?.['tns:beteiligter']?.['tns:auswahl_beteiligter']?.[
                          'tns:natuerlichePerson'
                        ]
                    )
                    .map((x: any) => {
                      const fullName =
                        (x?.['tns:beteiligter']?.['tns:auswahl_beteiligter']?.[
                          'tns:natuerlichePerson'
                        ]?.['tns:vollerName']?.['tns:vorname'] || '') +
                        ' ' +
                        (x?.['tns:beteiligter']?.['tns:auswahl_beteiligter']?.[
                          'tns:natuerlichePerson'
                        ]?.['tns:vollerName']?.['tns:nachname'] || '');

                      return (
                        <MenuItem
                          key={x?.['tns:rolle']?.['tns:rollennummer']}
                          value={fullName}
                          onClick={() => {
                            setSelectedRollennummer(
                              x?.['tns:rolle']?.['tns:rollennummer']
                            );
                          }}
                        >
                          {fullName}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Grid>
              {props.currentPerson?.['tns:besondereVertretungsregelung']?.[
                'tns:auswahl_vertretungsbefugnis'
              ]?.['tns:vertretungsbefugnisFreitext'] && (
                <Grid item sm={12}>
                  <TextField
                    label='Allgemeine Vertretungsregelung Freitext'
                    value={allgemeineVertretungsregelungFreitext}
                    onChange={e => {
                      setAllgemeineVertretungsregelungFreitext(e.target.value);
                      setSaveButtonDisabled(false);
                    }}
                    rows={3}
                    fullWidth
                    variant='outlined'
                    multiline
                  />
                </Grid>
              )}

              {(props.currentPerson?.['tns:besondereVertretungsregelung']?.[
                'tns:auswahl_vertretungsbefugnis'
              ]?.['tns:vertretungsbefugnis']?.['code'] ||
                props.currentPerson?.['tns:besondereVertretungsregelung']?.[
                  'tns:auswahl_vertretungsbefugnis'
                ]?.['tns:vertretungsbefugnis']) && (
                <Grid item sm={12}>
                  <Autocomplete
                    options={Object.values(vertretungsObjekt)} // Array von Strings für die Optionen
                    getOptionLabel={option => option}
                    value={vertretungMap.get(allgemeineVertretungsregelung)}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setAllgemeineVertretungsregelung(
                          valueToKeyVertretungsregelungMap.get(newValue)
                        );
                        setSaveButtonDisabled(false);
                      } else {
                        setAllgemeineVertretungsregelung(''); // Optional: handle the case where no value is selected
                        setSaveButtonDisabled(false);
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Allgemeine Vertretungsregelung'
                        variant='outlined'
                        fullWidth
                        multiline
                        rows={2}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleOnStop}>
            Abbruch
          </Button>
          <Button
            variant='contained'
            onClick={handleOnSave}
            disabled={saveButtonDisabled}
          >
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
      <TableRow>
        <TableCell>
          {rollenbezeichnungMap.get(
            props.alleBeteiligungen[0].find(
              (x: any) =>
                x?.['tns:rolle']?.['tns:rollennummer'] ===
                props.currentPerson?.['tns:ref.rollennummer']
            )?.['tns:rolle']?.['tns:rollenbezeichnung']?.['code']
          )}
        </TableCell>
        <TableCell>
          {props.alleBeteiligungen[0].find(
            (x: any) =>
              x?.['tns:rolle']?.['tns:rollennummer'] ===
              props.currentPerson?.['tns:ref.rollennummer']
          )?.['tns:beteiligter']?.['tns:auswahl_beteiligter']?.[
            'tns:natuerlichePerson'
          ]?.['tns:vollerName']?.['tns:vorname'] ||
            props.alleBeteiligungen[0].find(
              (x: any) =>
                x?.['tns:rolle']?.['tns:rollennummer'] ===
                props.currentPerson?.['tns:ref.rollennummer']
            )?.['tns:beteiligter']?.['tns:auswahl_beteiligter']?.[
              'tns:organisation'
            ]?.['tns:bezeichnung']?.['tns:bezeichnung.aktuell']}{' '}
          {
            props.alleBeteiligungen[0].find(
              (x: any) =>
                x?.['tns:rolle']?.['tns:rollennummer'] ===
                props.currentPerson?.['tns:ref.rollennummer']
            )?.['tns:beteiligter']?.['tns:auswahl_beteiligter']?.[
              'tns:natuerlichePerson'
            ]?.['tns:vollerName']?.['tns:nachname']
          }
        </TableCell>
        <TableCell>
          {vertretungMap.get(
            props.currentPerson?.['tns:besondereVertretungsregelung']?.[
              'tns:auswahl_vertretungsbefugnis'
            ]?.['tns:vertretungsbefugnis']?.['code']
          ) ||
            props.currentPerson?.['tns:besondereVertretungsregelung']?.[
              'tns:auswahl_vertretungsbefugnis'
            ]?.['tns:vertretungsbefugnisFreitext']}
        </TableCell>
        {/*Aktionen */}
        <TableCell>
          <IconButton
            //disabled={childrenPerson.idPerson === props.idPersonInherit}
            onClick={handleOpen}
            disabled={
              !props.currentPerson?.['tns:besondereVertretungsregelung']?.[
                'tns:auswahl_vertretungsbefugnis'
              ]?.['tns:vertretungsbefugnis']?.['code'] &&
              !props.currentPerson?.['tns:besondereVertretungsregelung']?.[
                'tns:auswahl_vertretungsbefugnis'
              ]?.['tns:vertretungsbefugnisFreitext']
            }
          >
            <Edit />
          </IconButton>
          <IconButton
            //disabled={childrenPerson.idPerson === props.idPersonInherit}
            onClick={handleRemove}
          >
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
