import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITextModuleCategory } from '../../Interfaces/ITextModuleCategory';

const initialState = { storeTextModuleCategory: [] as ITextModuleCategory[] };

const sliceTextModuleCategory = createSlice({
  name: 'TextModuleCategoryARRAY',
  initialState,
  reducers: {
    setTextModuleCategoryArray: (
      state,
      action: PayloadAction<ITextModuleCategory[]>
    ) => {
      state.storeTextModuleCategory = [...action.payload];
    },
    addUpdateTextModuleCategoryArray: (
      state,
      action: PayloadAction<ITextModuleCategory>
    ) => {
      const testObject = state.storeTextModuleCategory.find(
        x => x.idTextModuleCategory === action.payload.idTextModuleCategory
      );

      if (testObject) {
        state.storeTextModuleCategory = [
          ...state.storeTextModuleCategory.map(x =>
            x.idTextModuleCategory === action.payload.idTextModuleCategory
              ? action.payload
              : x
          ),
        ];
      } else {
        state.storeTextModuleCategory = [
          ...state.storeTextModuleCategory,
          action.payload,
        ];
      }
    },
  },
});
export const { setTextModuleCategoryArray, addUpdateTextModuleCategoryArray } =
  sliceTextModuleCategory.actions;
export default sliceTextModuleCategory.reducer;
