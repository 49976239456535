import React from 'react';
import { ITextModule } from '../Interfaces/ITextModule';
import { Alert, Box, Collapse, Grid, MenuItem, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { State } from '../redux/mainReducer';

interface IProps {
  textModuleObject: ITextModule;
  setTextModuleObject: Function;
}

export const TextModuleEdit: React.FC<IProps> = props => {
  const textModuleCategoryArray = useSelector(
    (state: State) => state.textModuleCategoryArray.storeTextModuleCategory
  );

  return (
    <>
      <Box sx={{ mt: 2 }} />

      <Collapse
        sx={{ mb: 2 }}
        in={
          textModuleCategoryArray.find(
            x =>
              x.idTextModuleCategory ===
              props.textModuleObject.idTextModuleCategory
          )?.InfoText != undefined
        }
      >
        <Alert severity='info'>
          {
            textModuleCategoryArray.find(
              x =>
                x.idTextModuleCategory ===
                props.textModuleObject.idTextModuleCategory
            )?.InfoText
          }
        </Alert>
      </Collapse>

      <Grid container spacing={2}>
        <Grid item sm={12}>
          <TextField
            label='Kategorie'
            value={props.textModuleObject.idTextModuleCategory}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setTextModuleObject({
                ...props.textModuleObject,
                idTextModuleCategory: Number(event.target.value),
              })
            }
            size='small'
            fullWidth
            select
          >
            {textModuleCategoryArray.map(x => (
              <MenuItem
                key={`idTextModuleCategory-${x.idTextModuleCategory}`}
                value={x.idTextModuleCategory}
              >
                {x.TextModuleCategory}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item sm={12}>
          <TextField
            label='Titel'
            value={props.textModuleObject.TextModule}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setTextModuleObject({
                ...props.textModuleObject,
                TextModule: event.target.value,
              })
            }
            error={props.textModuleObject.TextModule === ''}
            size='small'
            fullWidth
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            label='Text'
            value={props.textModuleObject.Text}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setTextModuleObject({
                ...props.textModuleObject,
                Text: event.target.value,
              })
            }
            size='small'
            fullWidth
            rows={5}
            multiline
          />
        </Grid>
      </Grid>
    </>
  );
};
