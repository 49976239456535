import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { ILegalTransaction_has_LegalTransactionSpecialFunction } from '../../Interfaces/ILegalTransaction_has_LegalTransactionSpecialFunction';
import { ILegalPhaseFullObject } from '../../Interfaces/ILegalPhase';
import { ILegalRequirement } from '../../Interfaces/ILegalRequirement';
import { ILegalRequirementState } from '../../Interfaces/ILegalRequirementState';

interface IProps {
  idLegalTransaction: number;
  legalTransaction_has_LegalTransactionSpecialFunctionArray: ILegalTransaction_has_LegalTransactionSpecialFunction[];
  setLegalTransaction_has_LegalTransactionSpecialFunctionArray: Function;
  legalPhaseFullObjectArray: ILegalPhaseFullObject[];
  legalReqState: ILegalRequirementState[];
  specialFunction: ILegalTransaction_has_LegalTransactionSpecialFunction;
}

export const ChangeLegalPhaseCondition: React.FC<IProps> = props => {
  const [open, setOpen] = React.useState(false);
  const [legalRequirementsArray, setLegalRequirementsArray] = useState<
    ILegalRequirement[]
  >([]);
  const [localSpecialFunction, setLocalSpecialFunction] =
    useState<ILegalTransaction_has_LegalTransactionSpecialFunction>(
      props.specialFunction
    );

  useEffect(() => {
    setLocalSpecialFunction(props.specialFunction);
  }, [props.specialFunction]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDelete = () => {
    setLocalSpecialFunction({
      ...localSpecialFunction,
      idLegalRequirement: -1,
      idLegalRequirementState: -1,
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
  };

  useEffect(() => {
    const allLegalRequirements: ILegalRequirement[] = [];
    props.legalPhaseFullObjectArray.forEach(item => {
      allLegalRequirements.push(...item.LegalRequirementArray);
    });
    setLegalRequirementsArray(allLegalRequirements);
  }, []);

  useEffect(() => {
    const temp = localSpecialFunction;
    const updatedArray =
      props.legalTransaction_has_LegalTransactionSpecialFunctionArray.map(
        item =>
          item.idLegalTransaction_has_LegalTransactionSpecialFunction ===
          temp.idLegalTransaction_has_LegalTransactionSpecialFunction
            ? temp
            : item
      );
    props.setLegalTransaction_has_LegalTransactionSpecialFunctionArray(
      updatedArray
    );
  }, [localSpecialFunction]);

  return (
    <>
      <Button variant='outlined' onClick={handleClickOpen}>
        Automatisierung
      </Button>

      <Dialog onClose={handleDelete} open={open}>
        <DialogTitle style={{ minWidth: '600px' }}>Automatisierung</DialogTitle>
        <DialogContent>
          <Typography>Bitte wählen sie eine Maßnahme aus</Typography>
          <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel id='demo-simple-select-label'>Maßnahme</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              label='Maßnahme'
              size='small'
              value={localSpecialFunction.idLegalRequirement}
              onChange={(event: SelectChangeEvent<number>) =>
                setLocalSpecialFunction({
                  ...localSpecialFunction,
                  idLegalRequirement: Number(event.target.value),
                })
              }
            >
              {legalRequirementsArray.map(item => (
                <MenuItem
                  key={item.idLegalRequirement}
                  value={item.idLegalRequirement}
                >
                  {item.LegalRequirement}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Typography>
            Wenn die Sonderfunktion abgeschlossen ist, die oben genannte
            Maßnahme auf den folgenden Status setzen
          </Typography>

          <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel id='legal-req-state-select-label'>Status</InputLabel>
            <Select
              labelId='legal-req-state-select-label'
              id='legal-req-state-select'
              label='Legal Requirement State'
              size='small'
              value={localSpecialFunction.idLegalRequirementState}
              onChange={(event: SelectChangeEvent<number>) =>
                setLocalSpecialFunction({
                  ...localSpecialFunction,
                  idLegalRequirementState: Number(event.target.value),
                })
              }
            >
              {props.legalReqState.map(state => (
                <MenuItem
                  key={state.idLegalRequirementState}
                  value={state.idLegalRequirementState}
                >
                  {state.LegalRequirementState}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose}>
            schliessen
          </Button>
          <Button variant='outlined' onClick={handleDelete}>
            löschen
          </Button>
          <Button variant='contained' onClick={handleSave}>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
