import React, { useEffect, useState } from 'react';
import { ILegalTransactionGwGEntry } from '../../../Interfaces/ILegalTransactionGwGEntry';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { CustomeTextField } from '../../../generic/CustomeTextField';
import { Delete, Edit, Task } from '@mui/icons-material';
import { DocumentOverview } from '../../../Document/DocumentOverview';

interface IProps {
  gwgEntryObject: ILegalTransactionGwGEntry;
  gwgEntryArray: ILegalTransactionGwGEntry[];
  setGwgEntryArray: Function;
}

export const GwGEntry: React.FC<IProps> = ({
  gwgEntryObject,
  gwgEntryArray,
  setGwgEntryArray,
}) => {
  const [editGwgEntry, setEditGwgEntry] = useState(gwgEntryObject);
  const [documentArray, setDocumentArray] = useState(
    gwgEntryObject.DocumentArray
  );
  //
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  useEffect(() => {
    setGwgEntryArray([
      ...gwgEntryArray.map(x =>
        x.idLegalTransactionGwGEntry === editGwgEntry.idLegalTransactionGwGEntry
          ? { ...editGwgEntry, DocumentArray: documentArray }
          : x
      ),
    ]);
  }, [editGwgEntry, documentArray]);

  const removeMew = () => {
    setGwgEntryArray([
      ...gwgEntryArray.filter(
        x =>
          x.idLegalTransactionGwGEntry !==
          editGwgEntry.idLegalTransactionGwGEntry
      ),
    ]);
  };

  return (
    <>
      <Dialog
        open={isOpenEdit}
        onClose={() => setIsOpenEdit(false)}
        maxWidth='md'
        fullWidth
      >
        <DialogContent>
          <CustomeTextField
            label='Titel'
            attr='title'
            object={editGwgEntry}
            setObject={setEditGwgEntry}
            type='string'
          />
          <Box sx={{ mt: 5 }} />
          <DocumentOverview
            key={`gwg-document-${editGwgEntry.idLegalTransactionGwGEntry}`}
            documentArray={documentArray}
            setDocumentArray={setDocumentArray}
            allowRemove
            allowUpload
            title='Dokumente'
          />
          <Box sx={{ mb: 8 }} />
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => setIsOpenEdit(false)}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>

      <Card variant='outlined' sx={{ mt: 1 }}>
        <Typography sx={{ ml: 1, mt: 1 }} variant='caption'>
          {editGwgEntry.title}
        </Typography>
        {documentArray.length > 0 && (
          <IconButton disabled sx={{ color: 'black' }}>
            <Task sx={{ color: 'black', fontSize: 12, float: 'right' }} />
          </IconButton>
        )}

        <IconButton sx={{ float: 'right' }} onClick={removeMew}>
          <Delete sx={{ fontSize: 12, float: 'right' }} />
        </IconButton>
        <IconButton sx={{ float: 'right' }} onClick={() => setIsOpenEdit(true)}>
          <Edit sx={{ fontSize: 12 }} />
        </IconButton>

        <CardContent>
          <Grid container>
            <Grid item sm={editGwgEntry.isPaid ? 12 : 4}>
              <CustomeTextField
                label={editGwgEntry.isPaid ? 'Einzahlung' : 'Ablösebetrag'}
                attr='currentValue'
                object={editGwgEntry}
                setObject={setEditGwgEntry}
                type='euro'
              />
            </Grid>
            {editGwgEntry.isPaid == false && (
              <>
                <Grid item sm={3}>
                  <CustomeTextField
                    label='Tagessatz'
                    attr='currentRate'
                    object={editGwgEntry}
                    setObject={setEditGwgEntry}
                    type='euro'
                  />
                </Grid>
                <Grid item sm={3}>
                  <CustomeTextField
                    label='Nebenkosten'
                    attr='extraCosts'
                    object={editGwgEntry}
                    setObject={setEditGwgEntry}
                    type='euro'
                  />
                </Grid>
                <Grid item sm={2}>
                  <Checkbox
                    checked={editGwgEntry.wasPaid}
                    onChange={() =>
                      setEditGwgEntry({
                        ...editGwgEntry,
                        wasPaid: !editGwgEntry.wasPaid,
                      })
                    }
                    sx={{ float: 'right' }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
