import React, { SyntheticEvent, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { IPostcode } from '../Interfaces/IPostcode';
import { useSearchPostcodes } from '../Aemter/hooks';

interface IProps {
  postcodeObject: IPostcode | null | undefined;
  setPostcodeObject: Function;
  required?: boolean;
}

export const PostcodeSearch: React.FC<IProps> = ({
  postcodeObject,
  setPostcodeObject,
  required,
}) => {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  //
  const { searchPostcodes, isLoadingSearchPostcodes } = useSearchPostcodes({
    searchTerm,
    initialData: [],
  });

  const onChange = (_event: SyntheticEvent, newObject: IPostcode | null) => {
    setPostcodeObject(newObject);
  };

  const onInputChange = (_event: SyntheticEvent | null, value: string) => {
    if (value) {
      const timeOutId = setTimeout(() => setSearchTerm(value), 300);
      return () => clearTimeout(timeOutId);
    }
  };

  const uniquePostcodes = Array.from(
    new Map(
      (searchPostcodes || []).map(item => [item.idPostcode, item])
    ).values()
  );

  return (
    <Autocomplete
      id='searchbar-postcode'
      fullWidth
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={postcodeObject}
      isOptionEqualToValue={(option, value) =>
        option.idPostcode === value.idPostcode
      }
      getOptionLabel={option => `${option.Postcode} ${option.City}`}
      loadingText='Laden...'
      options={
        uniquePostcodes.length > 0
          ? uniquePostcodes
          : postcodeObject
            ? [postcodeObject]
            : []
      }
      loading={!isLoadingSearchPostcodes}
      onInputChange={onInputChange}
      onChange={onChange}
      renderInput={params => (
        <TextField
          {...params}
          label='Postleitzahl'
          size='small'
          required={required === undefined ? true : required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoadingSearchPostcodes ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
