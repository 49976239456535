import React, { useState } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { StrangersRow } from './StrangersRow';
import { IInherit } from '../../../../../Interfaces/IInherit';
import { IInheritHasPerson } from '../../../../../Interfaces/IPerson';
import { IPersonHasDocument } from '../../../../../Interfaces/IPersonHasDocument';
import { getInheritPerson } from '../../../../../services/dialog.services.ts/inheritDialog.service';

interface IProps {
  inheritObject: IInherit;
  personArray: IInheritHasPerson[];
  setPersoArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
}

export const StrangersTable: React.FC<IProps> = props => {
  const [newPerson, setNewPerson] = useState(
    getInheritPerson(-1, props.personArray, true)
  );
  const [isNewOpen, setIsNewOpen] = useState(false);

  const handleOpenNew = () => {
    setNewPerson(getInheritPerson(-1, props.personArray, true));
    setIsNewOpen(true);
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nummer</TableCell>
            <TableCell>Vorname</TableCell>
            <TableCell>Nachname</TableCell>
            <TableCell>Geburtstag</TableCell>
            <TableCell>Aktionen</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {isNewOpen && (
            <StrangersRow
              key={`stranger-new`}
              index={0}
              currentPerson={newPerson}
              inheritObject={props.inheritObject}
              personArray={props.personArray}
              setPersoArray={props.setPersoArray}
              personHasDocument={props.personHasDocument}
              setPersonHasDocument={props.setPersonHasDocument}
              setIsOpenNew={setIsNewOpen}
            />
          )}

          {props.personArray
            .filter(x => x.isStranger)
            .map((x, i) => (
              <StrangersRow
                key={`stranger-${x.idPerson}-${i}`}
                index={i}
                currentPerson={x}
                inheritObject={props.inheritObject}
                personArray={props.personArray}
                setPersoArray={props.setPersoArray}
                personHasDocument={props.personHasDocument}
                setPersonHasDocument={props.setPersonHasDocument}
              />
            ))}
        </TableBody>
      </Table>
      <br></br>
      <Button
        variant='contained'
        onClick={handleOpenNew}
        sx={{ float: 'left' }}
      >
        Person hinzufügen
      </Button>
    </>
  );
};
