import React, {
  useState,
  useRef,
  type Dispatch,
  type SetStateAction,
} from 'react';
import { IDocumentAnalysisAttributeValue } from '../../Interfaces/IDocumentAnalysisAttributeValue';
import { TableCell, TextField } from '@mui/material';
import { useDocumentAssistantContext } from '../DocumentAssistant/DocumentAssistantContext';

interface IProps {
  attrValue: IDocumentAnalysisAttributeValue;
  setAttributeValues: Dispatch<
    SetStateAction<IDocumentAnalysisAttributeValue[]>
  >;
}

export const DocumentAnalysisAttributeValue = ({
  attrValue,
  setAttributeValues,
}: IProps) => {
  const { selectedCells, clearSelectedCells } = useDocumentAssistantContext();
  const [localValue, setLocalValue] = useState(attrValue.Value);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (newValue: string) => {
    const cursorPosition = inputRef.current?.selectionStart || 0;
    setLocalValue(newValue);

    setAttributeValues(prevAttrValueArray =>
      prevAttrValueArray.map(attributeValue =>
        attributeValue.idDocumentAnalysisAttributeValue ===
        attrValue.idDocumentAnalysisAttributeValue
          ? { ...attributeValue, Value: localValue }
          : attributeValue
      )
    );

    // Preserve cursor position after updating local state
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
      }
    }, 0);
  };

  const handleFocus = () => {
    if (selectedCells.length > 0) {
      const newValue = selectedCells.map(x => x.content).join(' ');
      setLocalValue(newValue);
      clearSelectedCells();
    }
  };

  return (
    <TableCell sx={{ m: 0, p: 0 }}>
      <TextField
        type='text'
        multiline
        rows={3}
        sx={{
          width: '100%',
          '& .MuiInputBase-root': { p: 0 },
          '& .MuiInputBase-input': { p: 1, fontSize: 14 },
        }}
        inputRef={inputRef}
        value={localValue}
        onChange={e => handleChange(e.target.value)}
        onFocus={handleFocus}
      />
    </TableCell>
  );
};
