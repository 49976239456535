import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../redux/mainReducer';
import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from '@mui/material';
import { StickyNote2 } from '@mui/icons-material';
import { DataGrid, GridColDef, deDE } from '@mui/x-data-grid';

const columns: GridColDef[] = [
  { field: 'TextModuleCategory', headerName: 'Kategorie', width: 100 },
  { field: 'TextModule', headerName: 'Bezeichnung', width: 100 },
  { field: 'Text', headerName: 'Text', flex: 1 },
];

interface IProps {
  txt: string | null;
  setTxt: Function;
  idTextModuleCategory?: number;
  floatRight?: boolean;
}

export const TextModuleSelector: React.FC<IProps> = props => {
  const textModuleArray = useSelector(
    (state: State) => state.textModuleArray.storeTextModule
  );
  const textModuleCategoryArray = useSelector(
    (state: State) => state.textModuleCategoryArray.storeTextModuleCategory
  );
  //
  const [idTextModuleCategory, setIdTextModuleCategory] = useState(
    props.idTextModuleCategory ? props.idTextModuleCategory : 0
  );
  //
  const [isOpen, setIsOpen] = useState(false);
  const [idTextModule, setIdTextModule] = useState<number | null>(null);

  const handleClose = () => {
    setIdTextModuleCategory(
      props.idTextModuleCategory ? props.idTextModuleCategory : 0
    );
    setIdTextModule(null);
    setIsOpen(false);
  };

  const handleAddText = () => {
    if (idTextModule !== null) {
      const textModule = textModuleArray.find(
        x => x.idTextModule === idTextModule
      );

      if (textModule !== undefined) {
        if (props.txt === null || props.txt === '') {
          props.setTxt(textModule.Text);
        } else {
          props.setTxt(`${props.txt} ${textModule.Text}`);
        }
      }

      handleClose();
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth='sm' fullWidth>
        <DialogTitle>Textbaustein einfügen</DialogTitle>
        <DialogContent>
          <>
            <Box sx={{ mt: 2 }} />

            <Collapse
              sx={{ mb: 2 }}
              in={
                textModuleCategoryArray.find(
                  x => x.idTextModuleCategory === idTextModuleCategory
                )?.InfoText != undefined
              }
            >
              <Alert severity='info'>
                {
                  textModuleCategoryArray.find(
                    x => x.idTextModuleCategory === idTextModuleCategory
                  )?.InfoText
                }
              </Alert>
            </Collapse>

            <Grid container spacing={2}>
              <Grid item sm={12}>
                <TextField
                  label='Kategorie'
                  value={idTextModuleCategory}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setIdTextModuleCategory(Number(event.target.value))
                  }
                  size='small'
                  fullWidth
                  select
                >
                  <MenuItem key={`idTextModuleCategory-0`} value={0}>
                    Alle
                  </MenuItem>
                  {textModuleCategoryArray.map(x => (
                    <MenuItem
                      key={`idTextModuleCategory-${x.idTextModuleCategory}`}
                      value={x.idTextModuleCategory}
                    >
                      {x.TextModuleCategory}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item sm={12}>
                <DataGrid
                  localeText={
                    deDE.components.MuiDataGrid.defaultProps.localeText
                  }
                  rows={textModuleArray.filter(x =>
                    idTextModuleCategory === 0
                      ? true
                      : x.idTextModuleCategory === idTextModuleCategory
                  )}
                  columns={columns}
                  getRowId={row => row.idTextModule}
                  onRowClick={(params, event, details) =>
                    setIdTextModule(Number(params.id))
                  }
                  autoHeight
                />
              </Grid>
            </Grid>
          </>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose}>
            Abbruch
          </Button>
          <Button
            variant='contained'
            disabled={idTextModule === null}
            onClick={handleAddText}
          >
            Einfügen
          </Button>
        </DialogActions>
      </Dialog>

      <IconButton
        title='Textbausteine'
        onClick={() => setIsOpen(true)}
        sx={{ float: props.floatRight ? 'right' : undefined }}
      >
        <StickyNote2 />
      </IconButton>
    </>
  );
};
