import React from 'react';
import { Box, Button, Collapse, Grid, Typography } from '@mui/material';
import { IRequiredFiles, InheritDocument } from './InheritDocument';
import { IPersonHasDocument } from '../../../../Interfaces/IPersonHasDocument';
import { IInheritHasPerson } from '../../../../Interfaces/IPerson';
import { GridBaseData } from '../../../../Person/GridBaseData';
import { IInherit } from '../../../../Interfaces/IInherit';

interface IProps {
  title: string;
  currentPerson: IInheritHasPerson;
  setCurrentPerson: Function;
  inheritObject?: IInherit;
  setInheritObject?: Function;
  requiredFiles: IRequiredFiles[];
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  titleAdress?: string;
  showDeathSettings?: boolean;
  forceDeathSettings?: boolean;
  setIsDeath?: Function;
  disableEdit?: boolean;
  personArray: IInheritHasPerson[];
  forceAdress?: boolean;
  disableDeathDateWarning?: boolean;
}

export const InheritPerson: React.FC<IProps> = props => {
  return (
    <>
      <Typography variant='h5'>{props.title}</Typography>
      <GridBaseData
        personObject={props.currentPerson}
        personArray={props.personArray}
        setPersonObject={props.setCurrentPerson}
        showDeathSettings={props.showDeathSettings}
        forceDeathSettings={props.forceDeathSettings}
        titleAdress={props.titleAdress}
        setIsDeath={props.setIsDeath}
        disableEdit={props.disableEdit}
        forceAdress={props.forceAdress}
        disableDeathDateWarning={props.disableDeathDateWarning}
        minimalRequiremend={
          !(props.currentPerson.idPerson === props.personArray[0].idPerson)
        }
      />

      {props.setInheritObject !== undefined && (
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Typography variant='h5' sx={{ mt: 10 }}>
              Testament
            </Typography>
          </Grid>

          <Grid item sm={12}>
            Liegt ein Testament vor?
          </Grid>

          <Grid item sm={12}>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                variant={
                  props.inheritObject?.hasTestament ? 'outlined' : 'contained'
                }
                onClick={() =>
                  props.setInheritObject!({
                    ...props.inheritObject,
                    hasTestament: false,
                  })
                }
                sx={{ mr: 1 }}
              >
                Nein
              </Button>

              <Box sx={{ flex: '1 1 auto' }} />
              <Button
                variant={
                  props.inheritObject?.hasTestament ? 'contained' : 'outlined'
                }
                onClick={() =>
                  props.setInheritObject!({
                    ...props.inheritObject,
                    hasTestament: true,
                  })!
                }
              >
                Ja
              </Button>
            </Box>
          </Grid>

          <Grid item sm={12}>
            <Collapse in={props.inheritObject?.hasTestament}>
              <InheritDocument
                key={`InheritDocument-testament`}
                idPerson={props.currentPerson.idPerson}
                requiredFile={
                  {
                    idPersonDocumentType: 5,
                    isOptional: false,
                    Title: 'Testament',
                  } as IRequiredFiles
                }
                personHasDocument={props.personHasDocument}
                setPersonHasDocument={props.setPersonHasDocument}
                disableEdit={props.disableEdit}
              />
            </Collapse>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2}>
        {props.requiredFiles.length > 0 && (
          <>
            <Grid item sm={12}>
              <Typography variant='h5' sx={{ mt: 10 }}>
                Benötigte Dokumente
              </Typography>
            </Grid>
            {props.requiredFiles.map((x, i) => (
              <InheritDocument
                key={`InheritDocument-${i}`}
                idPerson={props.currentPerson.idPerson}
                requiredFile={x}
                personHasDocument={props.personHasDocument}
                setPersonHasDocument={props.setPersonHasDocument}
                disableEdit={props.disableEdit}
              />
            ))}
          </>
        )}
      </Grid>
      <Box sx={{ mt: 10 }} />
    </>
  );
};
