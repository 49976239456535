import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { InheritPerson } from '../InheritPerson';
import { IInheritHasPerson } from '../../../../../Interfaces/IPerson';
import { IPersonHasDocument } from '../../../../../Interfaces/IPersonHasDocument';
import { IMarriage } from '../../../../../Interfaces/IMarriage';
import { IInherit } from '../../../../../Interfaces/IInherit';
import {
  FamilyTreePersonNodeType,
  FamilyTreeService,
} from '../../../../../services/familyTree.service';
import {
  getInheritPerson,
  getMarriage,
  getRequiredMarriageFilesArray,
} from '../../../../../services/dialog.services.ts/inheritDialog.service';

interface IProps {
  index: number;
  setIsOpenNew?: Function;
  idMarriage: number;
  inheritObject: IInherit;
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  marriageArray: IMarriage[];
  setMarriageArray: Function;
}

export const MarriageRow: React.FC<IProps> = ({
  index,
  setIsOpenNew,
  idMarriage,
  inheritObject,
  personArray,
  setPersonArray,
  personHasDocument,
  setPersonHasDocument,
  marriageArray,
  setMarriageArray,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [currentMarriage, setCurrentMarriage] = useState(
    getMarriage(
      setIsOpenNew !== undefined,
      idMarriage,
      marriageArray,
      personArray[0].idPerson
    )
  );
  const [currentPerson, setCurrentPerson] = useState(
    getInheritPerson(
      currentMarriage.Person2_idPerson,
      personArray,
      setIsOpenNew !== undefined
    )
  );
  const [editMarriage, setEditMarriage] = useState(currentMarriage);
  const [editPerson, setEditPerson] = useState(currentPerson);
  const [openMarriageDialog, setOpenMarriageDialog] = useState(
    setIsOpenNew !== undefined
  );

  const handleOpen = () => {
    setEditMarriage(currentMarriage);
    setEditPerson(currentPerson);
    setOpenMarriageDialog(true);
  };

  const handleClose = () => {
    if (setIsOpenNew !== undefined) {
      handleRemove();
      setIsOpenNew(false);
    }
    setOpenMarriageDialog(false);
  };

  const handleRemove = () => {
    setPersonHasDocument([
      ...personHasDocument.filter(x => x.idPerson !== currentPerson.idPerson),
    ]);
    setPersonArray([
      ...personArray.filter(x => x.idPerson !== currentPerson.idPerson),
    ]);
    setMarriageArray([
      ...marriageArray.filter(x => x.idMarriage !== currentMarriage.idMarriage),
    ]);
    setOpenMarriageDialog(false);
  };

  useEffect(() => {
    const currentPerson2 = getInheritPerson(
      currentMarriage.Person2_idPerson,
      personArray,
      setIsOpenNew !== undefined
    );
    setCurrentPerson(currentPerson2);
  }, [currentMarriage.Person2_idPerson, personArray]);

  /**
   * Form Function
   */
  const handleSave = () => {
    if (setIsOpenNew !== undefined) {
      const newMarriage = {
        ...editMarriage,
        Person2_idPerson: currentPerson.idPerson,
      } as IMarriage;
      setMarriageArray([...marriageArray, newMarriage]);
      setPersonArray([...personArray, editPerson]);
      setIsOpenNew(false);
    } else {
      setMarriageArray([
        ...marriageArray.map(x =>
          x.idMarriage === editMarriage.idMarriage ? editMarriage : x
        ),
      ]);
      setPersonArray([
        ...personArray.map(x =>
          x.idPerson === editPerson.idPerson ? editPerson : x
        ),
      ]);
      setCurrentMarriage(editMarriage);
      setCurrentPerson(editPerson);
    }
    setOpenMarriageDialog(false);
  };

  const handleOnSave = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      handleSave();
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements);

      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  return (
    <>
      <Dialog
        open={openMarriageDialog}
        onClose={handleClose}
        maxWidth='lg'
        fullWidth
      >
        <DialogContent>
          <form ref={formRef}>
            <Box sx={{ mt: 2 }} />
            <Typography variant='h5'>Ehestatus</Typography>

            <Box sx={{ mt: 3, mb: 10 }}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <TextField
                    label='Datum der Eheschließung'
                    size='small'
                    type='date'
                    fullWidth
                    required
                    InputLabelProps={{ shrink: true }}
                    value={editMarriage.DateOfMarriage}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setEditMarriage({
                        ...editMarriage,
                        DateOfMarriage: event.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item sm={12}>
                  <FormControlLabel
                    sx={{ float: 'left' }}
                    control={
                      <Switch
                        checked={editMarriage.isDivorced}
                        onChange={() => {
                          if (editMarriage.isDivorced === true) {
                            setEditMarriage({
                              ...editMarriage,
                              DateOfDevorce: null,
                              isDivorced: false,
                            });
                          } else {
                            setEditMarriage({
                              ...editMarriage,
                              isDivorced: true,
                            });
                          }
                        }}
                        name='gilad'
                      />
                    }
                    label='Geschieden'
                  />
                </Grid>
                {editMarriage.isDivorced === true && (
                  <Grid item sm={12}>
                    <TextField
                      label='Datum der Scheidung'
                      size='small'
                      type='date'
                      fullWidth
                      required
                      InputLabelProps={{ shrink: true }}
                      value={editMarriage.DateOfDevorce}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setEditMarriage({
                          ...editMarriage,
                          DateOfDevorce: event.target.value,
                        })
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </Box>

            <InheritPerson
              inheritObject={inheritObject}
              currentPerson={editPerson}
              setCurrentPerson={setEditPerson}
              personArray={personArray}
              personHasDocument={personHasDocument}
              setPersonHasDocument={setPersonHasDocument}
              requiredFiles={getRequiredMarriageFilesArray(editMarriage)}
              title={FamilyTreeService.getGenderNaming(
                FamilyTreePersonNodeType.MARRIAGE_NODE,
                editPerson.idGender
              )}
              showDeathSettings={true}
              setIsDeath={() => {}}
            />
          </form>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' onClick={handleClose}>
            Abbruch
          </Button>
          <Button variant='contained' onClick={handleOnSave}>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>

      <TableRow>
        <TableCell>{index + 1}</TableCell>
        <TableCell>
          {currentMarriage.DateOfMarriage !== '' &&
          currentMarriage.DateOfMarriage
            ? new Date(currentMarriage.DateOfMarriage).toLocaleDateString(
                'de-DE',
                {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }
              )
            : 'k.A'}
        </TableCell>
        <TableCell>
          {!currentMarriage.isDivorced
            ? '-'
            : currentMarriage.DateOfDevorce !== '' &&
                currentMarriage.DateOfDevorce
              ? new Date(currentMarriage.DateOfDevorce).toLocaleDateString(
                  'de-DE',
                  {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  }
                )
              : 'k.A'}
        </TableCell>
        <TableCell>
          {`${currentPerson.FirstName} ${currentPerson.LastName}`}
        </TableCell>
        <TableCell>
          <IconButton onClick={handleOpen}>
            <Edit />
          </IconButton>
          <IconButton onClick={handleRemove}>
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};
