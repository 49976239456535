import React, { useRef, useState } from 'react';
import { InheritPerson } from '../InheritPerson';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { IInheritHasPerson } from '../../../../../Interfaces/IPerson';
import { IChildren } from '../../../../../Interfaces/IChildren';
import { IPersonHasDocument } from '../../../../../Interfaces/IPersonHasDocument';
import { IInherit } from '../../../../../Interfaces/IInherit';
import { IMarriage } from '../../../../../Interfaces/IMarriage';
import {
  getChildren,
  getInheritPerson,
  getRequiredFilesArray,
} from '../../../../../services/dialog.services.ts/inheritDialog.service';

interface IProps {
  parents: IChildren[];
  degreeOfKinship: number;
  inheritObject: IInherit;
  setIsOpenNew: Function;
  parentPersonArray: IInheritHasPerson[];
  personArray: IInheritHasPerson[];
  setPersonArray: Function;
  childrenArray: IChildren[];
  setChildrenArray: Function;
  personHasDocument: IPersonHasDocument[];
  setPersonHasDocument: Function;
  marriageArray: IMarriage[];
}

export const ChildrenAddDialog: React.FC<IProps> = ({
  parents,
  degreeOfKinship,
  inheritObject,
  setIsOpenNew,
  parentPersonArray,
  personArray,
  setPersonArray,
  childrenArray,
  setChildrenArray,
  personHasDocument,
  setPersonHasDocument,
  marriageArray,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [idPersonParrent, setIdPersonParrent] = useState<number | null>(null);
  const [idPersonParrentSecond, setIdPersonParrentSecond] = useState<
    number | null
  >(null);
  const [currentPage, setCurrentPage] = useState(
    idPersonParrent === null ? 0 : 1
  );
  const [currentChildren, setCurrentChildren] = useState(
    getChildren(true, -1, childrenArray)
  );
  const [currentPerson, setCurrentPerson] = useState(
    getInheritPerson(-1, personArray, true)
  );
  const [currentPersonHasDocument, setCurrentPersonHasDocument] = useState<
    IPersonHasDocument[]
  >([]);
  const [isDeath, setIsDeath] = useState(false);

  const handleNext = () => {
    if (currentPage === 3 && idPersonParrent !== null) {
      const newChild = {
        ...currentChildren,
        Parent1_idPerson: idPersonParrent,
        Parent2_idPerson: idPersonParrentSecond,
        Children_idPerson: currentPerson.idPerson,
      } as IChildren;
      setChildrenArray([...childrenArray, newChild]);
      setPersonArray([...personArray, currentPerson]);
      setPersonHasDocument([...personHasDocument, ...currentPersonHasDocument]);
      setIsOpenNew(false);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  /**
   * Form Function
   */
  const handleOnSave = () => {
    if (formRef.current && formRef.current.checkValidity()) {
      handleNext();
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements);

      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={() => {}}
        maxWidth={currentPage < 3 ? 'sm' : 'lg'}
        fullWidth
      >
        <DialogTitle>Neues Kind</DialogTitle>
        <DialogContent>
          <form ref={formRef}>
            <Box sx={{ mt: 2 }} />

            <Fade in={currentPage === 0} mountOnEnter unmountOnExit>
              <Box>
                <Typography variant='body1' sx={{ flaot: 'center' }}>
                  Bitte wählen Sie das Elternteil/ die Elternteile aus.
                </Typography>
                <TextField
                  sx={{ mt: 2 }}
                  label='Elternteil(e)'
                  select
                  value={
                    idPersonParrent === null
                      ? ''
                      : idPersonParrentSecond === null
                        ? idPersonParrent
                        : `${idPersonParrent}_${idPersonParrentSecond}`
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const splitTest = String(event.target.value).split('_');

                    if (splitTest.length === 2) {
                      setIdPersonParrent(Number(splitTest[0]));
                      setIdPersonParrentSecond(Number(splitTest[1]));
                    } else {
                      setIdPersonParrent(Number(event.target.value));
                      setIdPersonParrentSecond(null);
                    }
                  }}
                  size='small'
                  fullWidth
                  required
                >
                  {degreeOfKinship === 1
                    ? parents.length === 1 && parents[0].Parent2_idPerson
                      ? [
                          <MenuItem
                            key={`idPerson-${parents[0].idChildren}`}
                            value={`${parents[0].Parent1_idPerson}_${parents[0].Parent2_idPerson}`}
                          >
                            {
                              personArray.find(
                                person =>
                                  person.idPerson ===
                                  parents[0].Parent1_idPerson
                              )?.FirstName
                            }{' '}
                            {
                              personArray.find(
                                person =>
                                  person.idPerson ===
                                  parents[0].Parent1_idPerson
                              )?.LastName
                            }{' '}
                            und{' '}
                            {
                              personArray.find(
                                person =>
                                  person.idPerson ===
                                  parents[0].Parent2_idPerson
                              )?.FirstName
                            }{' '}
                            {
                              personArray.find(
                                person =>
                                  person.idPerson ===
                                  parents[0].Parent2_idPerson
                              )?.LastName
                            }
                          </MenuItem>,
                          <MenuItem
                            key={`idPerson-${parents[0].Parent1_idPerson}`}
                            value={parents[0].Parent1_idPerson}
                          >
                            {
                              personArray.find(
                                person =>
                                  person.idPerson ===
                                  parents[0].Parent1_idPerson
                              )?.FirstName
                            }{' '}
                            {
                              personArray.find(
                                person =>
                                  person.idPerson ===
                                  parents[0].Parent1_idPerson
                              )?.LastName
                            }
                          </MenuItem>,
                          <MenuItem
                            key={`idPerson-${parents[0].Parent2_idPerson}`}
                            value={parents[0].Parent2_idPerson}
                          >
                            {
                              personArray.find(
                                person =>
                                  person.idPerson ===
                                  parents[0].Parent2_idPerson
                              )?.FirstName
                            }{' '}
                            {
                              personArray.find(
                                person =>
                                  person.idPerson ===
                                  parents[0].Parent2_idPerson
                              )?.LastName
                            }
                          </MenuItem>,
                        ]
                      : [
                          ...marriageArray
                            .filter(
                              x =>
                                parentPersonArray
                                  .map(x => x.idPerson)
                                  .indexOf(x.Person1_idPerson) > -1 ||
                                parentPersonArray
                                  .map(x => x.idPerson)
                                  .indexOf(x.Person2_idPerson) > -1
                            )
                            .map(x => {
                              const firstPerson = personArray.find(
                                y => y.idPerson === x.Person1_idPerson
                              );
                              const secondPerson = personArray.find(
                                y => y.idPerson === x.Person2_idPerson
                              );

                              return (
                                <MenuItem
                                  key={`idMarriage-${x.idMarriage}`}
                                  value={`${x.Person1_idPerson}_${x.Person2_idPerson}`}
                                >
                                  {firstPerson?.FirstName}{' '}
                                  {firstPerson?.LastName} und{' '}
                                  {secondPerson?.FirstName}{' '}
                                  {secondPerson?.LastName}
                                </MenuItem>
                              );
                            }),
                          ...parentPersonArray.map(x => (
                            <MenuItem
                              key={`idPerson-${x.idPerson}`}
                              value={x.idPerson}
                            >
                              {x.FirstName} {x.LastName}
                            </MenuItem>
                          )),
                        ]
                    : parents.map(parent => (
                        <MenuItem
                          key={`idPerson-${parent.Children_idPerson}`}
                          value={parent.Children_idPerson}
                        >
                          {
                            personArray.find(
                              person =>
                                person.idPerson === parent.Children_idPerson
                            )?.FirstName
                          }{' '}
                          {
                            personArray.find(
                              person =>
                                person.idPerson === parent.Children_idPerson
                            )?.LastName
                          }
                        </MenuItem>
                      ))}
                </TextField>
              </Box>
            </Fade>

            <Fade in={currentPage === 1} mountOnEnter unmountOnExit>
              <Box
                display='flex'
                height={80}
                alignItems='center'
                justifyContent='center'
              >
                <Typography variant='body1' sx={{ flaot: 'center' }}>
                  Ist das Kind adoptiert?
                  <Box
                    display='flex'
                    alignItems='right'
                    justifyContent='right'
                    sx={{ flaot: 'right' }}
                  >
                    <Button
                      onClick={() => {
                        setCurrentChildren({
                          ...currentChildren,
                          isAdopted: true,
                        });
                        setCurrentPage(3);
                      }}
                    >
                      Ja
                    </Button>
                    <Button
                      onClick={() => {
                        setCurrentChildren({
                          ...currentChildren,
                          isAdopted: false,
                        });
                        setCurrentPage(2);
                      }}
                    >
                      Nein
                    </Button>
                  </Box>
                </Typography>
              </Box>
            </Fade>

            <Fade in={currentPage === 2} mountOnEnter unmountOnExit>
              <Box
                display='flex'
                height={80}
                alignItems='center'
                justifyContent='center'
              >
                <Typography variant='body1' sx={{ flaot: 'center' }}>
                  Wurde das Kind zur Adoption freigegeben?
                  <Box
                    display='flex'
                    alignItems='right'
                    justifyContent='right'
                    sx={{ flaot: 'right' }}
                  >
                    <Button
                      onClick={() => {
                        setCurrentChildren({
                          ...currentChildren,
                          isPutUpForAdoption: true,
                        });
                        setCurrentPage(3);
                      }}
                    >
                      Ja
                    </Button>
                    <Button
                      onClick={() => {
                        setCurrentChildren({
                          ...currentChildren,
                          isPutUpForAdoption: false,
                        });
                        setCurrentPage(3);
                      }}
                    >
                      Nein
                    </Button>
                  </Box>
                </Typography>
              </Box>
            </Fade>

            <Fade in={currentPage === 3} mountOnEnter unmountOnExit>
              <Box>
                <InheritPerson
                  inheritObject={inheritObject}
                  currentPerson={currentPerson}
                  setCurrentPerson={setCurrentPerson}
                  personArray={personArray}
                  personHasDocument={currentPersonHasDocument}
                  setPersonHasDocument={setCurrentPersonHasDocument}
                  title=''
                  showDeathSettings
                  requiredFiles={getRequiredFilesArray(isDeath)}
                  setIsDeath={setIsDeath}
                />
              </Box>
            </Fade>
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setIsOpenNew(false)}>
            Abbruch
          </Button>
          {!(currentPage === 1) && !(currentPage === 2) && (
            <Button variant='contained' onClick={handleOnSave}>
              {currentPage === 3 ? 'Speichern' : 'Weiter'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
