import React, { useCallback, useEffect, useState } from 'react';
import { IUserGroup, IUserGroupFullObject } from '../Interfaces/IUserGroup';
import { uploadFetch, useFetch } from '../hooks/useFetch';
import { IUser } from '../Interfaces/IUser';
import { IUserHasUserGroup } from '../Interfaces/IUserHasUserGroup';
import {
  Alert,
  Box,
  Button,
  Collapse,
  Grid,
  LinearProgress,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import { CustomCircularProgress } from '../generic/CustomCircularProgress';
import { CustomeTextField } from '../generic/CustomeTextField';
import { UserHasGroupRow } from './Childs/UserHasGroupRow';
import { UserGroupDelete } from './Childs/UserGroupDelete';
import { ITopic } from '../Interfaces/ITopic';
import { NotificationPreferences } from '../User/Childs/NotificationPreferences';

interface IProps {
  idUserGroup: number;
  setIdUserGroup: Function;
  userArray: IUser[];
  userGroupArray: IUserGroup[];
  setUserGroupArray: Function;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const getEmptyUserGroup = () => {
  return {
    idUserGroup: -1,
    UserGroup: '',
    Email: null,
    PhoneNumber: null,
    NotifcationOptionBitmask: undefined,
    UserHasUserGroupArray: [],
  } as IUserGroupFullObject;
};

export const UserGroupEdit: React.FC<IProps> = ({
  idUserGroup,
  setIdUserGroup,
  userArray,
  userGroupArray,
  setUserGroupArray,
}) => {
  const [userGroupFullObject, setUserGroupFullObject, wasSuccessfullyLoad] =
    useFetch<IUserGroupFullObject>('/usergroup/fullObject/', idUserGroup);
  const [userGroupObject, setUserGroupObject] = useState(getEmptyUserGroup());
  const [userHasGroupArray, setUserHasGroupArray] = useState<
    IUserHasUserGroup[]
  >([]);
  const [topics, setTopics, wasSuccessfullyLoadTopics] =
    useFetch<ITopic[]>('/topic');
  const [notificationPreferences, setNotificationPreferences] = useState<{
    [key: number]: boolean;
  }>({});

  //
  const [isLoading, setIsLoading] = useState(false);
  const [wasSuccessfullySaved, setWasSuccessfullySaved] = useState(true);
  //
  const [value, setValue] = useState(0);

  //Initialisiere den Userchange, indem die dem Nutzer zugeordneten Vorgänge geladen werden

  const calcNotifcationOption = useCallback((options: number[]): number => {
    let abonnements = 0;
    for (const option of options) {
      abonnements |= 1 << (option - 1);
    }
    return abonnements;
  }, []);

  const calcNotifcationOptionArray = useCallback(
    (notificationBitNumber: number): number[] => {
      const optionen: number[] = [];
      let bitPosition = 0;

      while (notificationBitNumber !== 0) {
        if ((notificationBitNumber & 1) !== 0) {
          optionen.push(bitPosition + 1);
        }
        notificationBitNumber >>= 1;
        bitPosition++;
      }

      return optionen;
    },
    []
  );

  useEffect(() => {
    if (wasSuccessfullyLoadTopics && userGroupFullObject !== undefined) {
      if (
        userGroupFullObject.NotifcationOptionBitmask !== null &&
        userGroupFullObject.NotifcationOptionBitmask !== undefined
      ) {
        setNotificationPreferences(
          calcNotifcationOptionArray(
            userGroupFullObject.NotifcationOptionBitmask
          ).reduce(
            (acc, cur) => {
              acc[cur] = true;
              return acc;
            },
            {} as { [key: number]: boolean }
          )
        );
      }
    }
  }, [
    wasSuccessfullyLoadTopics,
    userGroupFullObject,
    calcNotifcationOptionArray,
  ]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (userGroupFullObject !== undefined) {
      setUserGroupObject(userGroupFullObject);
      setUserHasGroupArray(userGroupFullObject.UserHasUserGroupArray);
    }
  }, [userGroupFullObject]);

  const handleAfterSave = (res: IUserGroupFullObject) => {
    const foundObject = userGroupArray.find(
      x => x.idUserGroup === res.idUserGroup
    );

    if (foundObject === undefined) {
      setUserGroupArray([...userGroupArray, res]);
    } else {
      setUserGroupArray([
        ...userGroupArray.map(x =>
          x.idUserGroup === res.idUserGroup ? res : x
        ),
      ]);
    }
    setIdUserGroup(null);
  };

  const handleSave = () => {
    const uploadObject: IUserGroupFullObject = {
      ...userGroupObject,
      UserHasUserGroupArray: userHasGroupArray,
    };
    if (notificationPreferences !== undefined) {
      const notificationBitmask = calcNotifcationOption(
        Object.keys(notificationPreferences)
          .map(x => Number(x))
          .filter(x => notificationPreferences[x])
      );

      if (notificationBitmask !== userGroupObject.NotifcationOptionBitmask) {
        uploadObject.NotifcationOptionBitmask = notificationBitmask;
      } else {
        //remove from uploadObject
        uploadObject.NotifcationOptionBitmask = undefined;
      }
    }
    uploadFetch(
      '/usergroup/fullObject',
      idUserGroup < 0,
      uploadObject,
      handleAfterSave,
      setWasSuccessfullySaved,
      () => {},
      setIsLoading
    );
  };

  if ((idUserGroup > 0 && !wasSuccessfullyLoad) || !wasSuccessfullyLoadTopics) {
    return <Alert severity='error'>Fehler!</Alert>;
  } else if (
    (idUserGroup > 0 && userGroupFullObject === undefined) ||
    topics === undefined
  ) {
    return <CustomCircularProgress />;
  } else {
    return (
      <Box>
        <Typography>
          Benutzergruppe
          {userGroupFullObject !== undefined && (
            <UserGroupDelete
              idUserGroup={idUserGroup}
              setIdUserGroup={setIdUserGroup}
              userGroupFullObject={userGroupFullObject}
              userGroupArray={userGroupArray}
              setUserGroupArray={setUserGroupArray}
            />
          )}
        </Typography>
        <Collapse in={isLoading}>
          <LinearProgress />
        </Collapse>

        <Collapse in={!wasSuccessfullySaved}>
          <Alert severity='error'>
            Die Benutzergruppe konnte nicht gespeichert werden!
          </Alert>
        </Collapse>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item sm={12}>
            <CustomeTextField
              label='Gruppenbezeichnung'
              type='string'
              attr='UserGroup'
              object={userGroupObject}
              setObject={setUserGroupObject}
              required
            />
          </Grid>

          <Grid item sm={6}>
            <CustomeTextField
              label='E-Mail'
              type='string'
              attr='Email'
              object={userGroupObject}
              setObject={setUserGroupObject}
              null
            />
          </Grid>
          <Grid item sm={6}>
            <CustomeTextField
              label='Telefonnummer'
              type='string'
              attr='PhoneNumber'
              object={userGroupObject}
              setObject={setUserGroupObject}
              null
            />
          </Grid>
        </Grid>

        <Box sx={{ mt: 5, borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab label='Mitglieder' {...a11yProps(0)} />
            <Tab label='Benachrichtigungen' {...a11yProps(1)} />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <Typography variant='h5'>Mitglieder</Typography>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>Benutzer</TableCell>
                <TableCell>Vor- und Nachname</TableCell>
                <TableCell sx={{ width: 100 }}>Mitglied</TableCell>
                <TableCell sx={{ width: 100 }}>Gruppenadmin</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userArray.map(x => (
                <UserHasGroupRow
                  key={`idUser-${x.idUser}`}
                  userObject={x}
                  userHasUserGroup={userHasGroupArray}
                  setUserHasUserGroup={setUserHasGroupArray}
                />
              ))}
            </TableBody>
          </Table>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <NotificationPreferences
            topics={topics}
            preferences={notificationPreferences}
            setPreferences={setNotificationPreferences}
          />
        </CustomTabPanel>

        <Box sx={{ mt: 10 }}>
          <Button
            sx={{ float: 'right', ml: 2 }}
            variant='contained'
            onClick={handleSave}
          >
            Speichern
          </Button>
          <Button
            sx={{ float: 'right' }}
            variant='outlined'
            onClick={() => setIdUserGroup(null)}
          >
            Abbruch
          </Button>
        </Box>
      </Box>
    );
  }
};
