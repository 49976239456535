export interface INotification {
  idNotification: number;
  ReferenceType: string;
  Created_at: string;
  idUser: number;
  idTopic: number;
  TopicName: string;
  Description: string;
  idReference: number;
  Title: string;
  Content: INotificationContentItem[];
  isRead: boolean;
  Action: INotifcationAction;
}

export interface INotificationWithLink extends INotification {
  goto: string;
}

export enum INotifcationAction {
  GOTO = 'GOTO',
}

export interface INotificationContentItem {
  type: string;
  value?: string;
  items?: string[];
}

export interface INotificationSettings {
  NotificationOptions: number[];
  ShowNotificationsAtLogin: boolean;
}
