import React, { useEffect, useState } from 'react';
import { Node } from 'reactflow';
import { FamilyTreePersonNode } from '../../../../../services/familyTree.service';
import { GridBaseData } from '../../../../../Person/GridBaseData';
import { IInheritHasPerson, IPerson } from '../../../../../Interfaces/IPerson';

interface FormMitarbeiterProps {
  setCurrentNode: (formData: Node<FamilyTreePersonNode>) => void;
  currentNode: Node<FamilyTreePersonNode>;
}

const PersonEditForm: React.FC<FormMitarbeiterProps> = ({
  setCurrentNode,
  currentNode,
}) => {
  const [currentPerson, setCurrentPerson] = useState<
    IInheritHasPerson | IPerson
  >(currentNode.data.person);

  useEffect(() => {
    const currentNode2 = currentNode.data.person;
    setCurrentPerson(currentNode2);
  }, [currentNode.data.person]);

  useEffect(() => {
    if (currentPerson) {
      setCurrentNode({
        ...currentNode,
        data: {
          ...currentNode.data,
          // TODO: allPersons updaten! wird noch nicht gemacht!
          person: currentPerson,
        },
      });
    }
  }, [currentNode, currentPerson, setCurrentNode]);

  return (
    <>
      <GridBaseData
        personObject={currentPerson}
        personArray={currentNode.data.allPersons}
        setPersonObject={setCurrentPerson}
        showDeathSettings={
          currentNode.data.person.idPerson !==
          currentNode.data.allPersons[0].idPerson
        }
        forceDeathSettings={
          currentNode.data.person.idPerson ===
          currentNode.data.allPersons[0].idPerson
        }
        disableEdit={false}
        forceAdress={
          currentNode.data.person.idPerson ===
          currentNode.data.allPersons[0].idPerson
        }
        disableDeathDateWarning={false}
        minimalRequiremend={
          currentNode.data.person.idPerson !==
          currentNode.data.allPersons[0].idPerson
        }
      />
    </>
  );
};

export default PersonEditForm;
