import React, { useEffect } from 'react';
import { IContact } from '../Interfaces/IContact';
import { Alert, AlertTitle, Collapse } from '@mui/material';

interface IProps {
  currentContact: IContact | undefined;
  contactArray: IContact[];
  hasDuplicateEmailError: boolean;
  setHasDuplicateEmailError: Function;
}

export const ClientEmailWarning: React.FC<IProps> = ({
  currentContact,
  contactArray,
  hasDuplicateEmailError,
  setHasDuplicateEmailError,
}) => {
  useEffect(() => {
    if (currentContact !== null && currentContact?.Email !== '') {
      setHasDuplicateEmailError(
        contactArray
          // Sicher selber rausfiltern
          .filter(x => x.idContact !== currentContact!.idContact)
          .map(x => x.Email.replace(' ', '').toLocaleLowerCase())
          .indexOf(currentContact!.Email.replace(' ', '').toLocaleLowerCase()) >
          -1
      );
    }
  }, [currentContact?.Email]);

  return (
    <>
      <Collapse in={hasDuplicateEmailError}>
        <Alert severity='warning'>
          <AlertTitle>Warnung: E-Mail-Adresse</AlertTitle>
          Die ausgewählte E-Mail-Adresse existiert bereits im System und kann
          nicht verwendet werden!
        </Alert>
      </Collapse>
    </>
  );
};
