import React, { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { SelectShareHolderEdit } from '../../SelectShareHolderEdit';
import { roundFunctionNormal } from '../../help_functions';
import {
  IShareHolder,
  IShareHolderFullObject,
} from '../../../../../Interfaces/IShareHolder';
import { ICompanyFormationHasShareHolder } from '../../../../../Interfaces/ICompanyFormationHasShareHolder';
import { ICompanyFormationShare } from '../../../../../Interfaces/ICompanyFormationShare';
import { ICompanyFormation } from '../../../../../Interfaces/ICompanyFormation';

interface IProps {
  idShareHolderType: number;
  shareHolderArray: IShareHolderFullObject[];
  setShareHolderArray: Function;
  shareHolderRelation: ICompanyFormationHasShareHolder[];
  setShareHolderRelation: Function;
  //
  companyFormationObject: ICompanyFormation;
  companyFormationShareArray: ICompanyFormationShare[];
  setCompanyFormationShareArray: Function;
}

export const SelectShareHolderDialog: React.FC<IProps> = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [editShareHolderRelation, setEditShareHolderRelation] = useState(
    props.shareHolderRelation.filter(
      x => x.idShareHolderType === props.idShareHolderType
    )
  );

  const handleOpen = () => {
    setEditShareHolderRelation(
      props.shareHolderRelation.filter(
        x => x.idShareHolderType === props.idShareHolderType
      )
    );
    setIsOpen(true);
  };

  const handleSave = () => {
    let totalPerPerson = 0;
    let prozPerPerson = 0;
    const arrayDiff =
      editShareHolderRelation.length - props.companyFormationShareArray.length;

    const tmpArray: ICompanyFormationHasShareHolder[] = [];
    const tmpArrayShareHolder: IShareHolder[] = [...props.shareHolderArray];
    const tmpArrayShare: ICompanyFormationShare[] = [
      ...props.companyFormationShareArray,
    ];

    /// if: wenn total wert darunter, dann verteile restlichen wert auf neue
    if (
      arrayDiff > 0 &&
      props.shareHolderArray.map(x => x.Shares).reduce((a, b) => a + b, 0) < 1
    ) {
      const freeShare =
        1 -
        props.shareHolderArray.map(x => x.Shares).reduce((a, b) => a + b, 0);
      prozPerPerson = freeShare / arrayDiff;
      totalPerPerson =
        props.companyFormationObject.ShareCapital * prozPerPerson;
    }

    editShareHolderRelation.map(x => {
      const testObjectRelation = props.shareHolderRelation.find(
        y => y.idShareHolder === x.idShareHolder
      );

      /// if: nur hinzufügen, wenn noch keine Share vorhanden
      if (testObjectRelation === undefined) {
        tmpArray.push(x);

        // Aktualisere den Shareholder
        const testObject = tmpArrayShareHolder.find(
          y => y.idShareHolder === x.idShareHolder
        );

        if (testObject !== undefined) {
          testObject.Shares = prozPerPerson;
          tmpArrayShareHolder.filter(y =>
            y.idShareHolder === x.idShareHolder ? testObject : x
          );
        }

        if (
          tmpArrayShare.filter(y => y.idShareHolder === x.idShareHolder)
            .length === 0
        ) {
          let minId =
            Math.min(...tmpArrayShare.map(y => y.idCompanyFormationShare)) - 1;

          if (minId >= 0) {
            minId = -1;
          }

          tmpArrayShare.push({
            AmountOfShares: 1,
            ValuePerShare: roundFunctionNormal(totalPerPerson),
            idCompanyFormation: -1,
            idCompanyFormationShare: minId,
            idShareHolder: x.idShareHolder,
          } as ICompanyFormationShare);
        }
      }
      /// end: if
    });

    /*
        let totalPerPerson = props.companyFormationObject.ShareCapital / editShareHolderRelation.length;
        let prozPerPerson = totalPerPerson / props.companyFormationObject.ShareCapital;

        let tmpArray:ICompanyFormationHasShareHolder[] = [];
        let tmpArrayShareHolder:IShareHolder[] = [...props.shareHolderArray];
        let tmpArrayShareArray:ICompanyFormationShare[] = [...props.companyFormationShareArray];

        editShareHolderRelation.map(x => {
            let testObject = tmpArrayShareHolder.find(y => y.idShareHolder === x.idShareHolder);

            if (testObject !== undefined) {
                testObject.Shares = prozPerPerson;
                tmpArrayShareHolder.filter(y => y.idShareHolder === x.idShareHolder ? testObject : x);
            }

            if (tmpArrayShareArray.filter(y => y.idShareHolder === x.idShareHolder).length === 0) {
                let minId = Math.min(...tmpArrayShareArray.map(y => y.idCompanyFormationShare)) -1;

                if (minId >= 0) {
                    minId = -1;
                }

                tmpArrayShareArray.push({
                    AmountOfShares: 1,
                    ValuePerShare: totalPerPerson,
                    idCompanyFormation: -1,
                    idCompanyFormationShare: minId,
                    idShareHolder: x.idShareHolder
                } as ICompanyFormationShare)
            }
        }) 
        */
    props.setShareHolderRelation([
      ...props.shareHolderRelation.filter(
        x => x.idShareHolderType !== props.idShareHolderType
      ),
      ...editShareHolderRelation,
    ]);
    props.setShareHolderArray([...tmpArrayShareHolder]);
    props.setCompanyFormationShareArray([
      ...tmpArrayShare.filter(
        x =>
          editShareHolderRelation
            .map(y => y.idShareHolder)
            .indexOf(x.idShareHolder) > -1
      ),
    ]);
    setIsOpen(false);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        maxWidth='lg'
        fullWidth
      >
        <DialogTitle>Person zuordnen</DialogTitle>
        <DialogContent>
          <SelectShareHolderEdit
            idShareHolderType={props.idShareHolderType}
            shareHolderArray={props.shareHolderArray}
            setShareHolderArray={props.setShareHolderArray}
            shareHolderRelation={editShareHolderRelation}
            setShareHolderRelation={setEditShareHolderRelation}
            enableAutoslectNewShareHolder
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setIsOpen(false)}>
            Abbruch
          </Button>
          <Button variant='contained' onClick={() => handleSave()}>
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>

      <Button
        sx={{ mt: 3, float: 'right' }}
        variant='contained'
        onClick={handleOpen}
      >
        Person zuordnen
      </Button>
    </>
  );
};
