import { Box, Button, Menu, MenuItem } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import ReactFlow, {
  Background,
  Connection,
  Controls,
  Edge,
  MiniMap,
  Node,
  ReactFlowProvider,
  addEdge,
  getRectOfNodes,
  getTransformForBounds,
  useEdgesState,
  useNodesState,
} from 'reactflow';

import 'reactflow/dist/style.css';

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import NodePositionService from '../services/nodePosition.service';
import { DialogStructure } from './DialogStructure';
import { toPng } from 'html-to-image';

export interface FormConfig {
  form: React.FC<any>;
  title: string | Function;
}

export interface EditableNode {
  onNodeEdit: Function;
  onNodeDelete: Function;
}

export interface EditableEdge {
  onNodeEdit: Function;
}

interface IProps<T = unknown, G = unknown, E = unknown> {
  coreNode?: Node<E>;
  nodeData: Node<T>[];
  edgeData: Edge<G>[];
  customNodeComponents?: { [key: string]: React.FC<any> }; // TODO: Correct type
  customEdgeComponents?: { [key: string]: React.FC<any> }; // TODO: Correct type
  forms?: { [key: string]: FormConfig };
  showAddButton?: boolean; // Neue Eigenschaft für den Add-Button
  showDownloadButton?: boolean;
  allowEdgeDoubleClickEdit?: boolean; // Neue Eigenschaft für Doppelklick-Edge-Bearbeitung
  allowOnConnect?: boolean;
  handleEditNodeOnSaveOutside?: (currentNode: Node) => void;
  creatPngOfTree?: (nodes: Node[]) => void;
  handleAddNodeOnSaveOutside?: (addNode: Node) => void;
  handleEditEdgeOnSaveOutside?: (currentEdge: Edge) => void;
  handleAddEdgeOnSaveOutside?: (currentEdge: Edge) => void;
  addNodeArray?: any;
}

export const TreeEditor: React.FC<IProps> = props => {
  const {
    coreNode,
    nodeData,
    edgeData,
    customNodeComponents,
    customEdgeComponents,
    forms,
    showAddButton = true, // Default true
    showDownloadButton = false, // Default false
    allowEdgeDoubleClickEdit = true, // Default true
    allowOnConnect = true, // Default true
    creatPngOfTree = () => {},
    handleEditNodeOnSaveOutside = () => {}, // Default empty function
    handleAddNodeOnSaveOutside = () => {}, // Default empty function
    handleEditEdgeOnSaveOutside = () => {}, // Default empty function
    handleAddEdgeOnSaveOutside = () => {}, // Default empty function
    addNodeArray,
  } = props;
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [currentForm, setCurrentForm] = useState<React.ReactNode>(null);
  const [formTitle, setFormTitle] = useState('');
  const [addNode, setAddNode] = useState<Nullable<Node>>(null);
  const [updatedAddNode, setUpdatedAddNode] = useState<Nullable<Node>>(null);
  const [currentNode, setCurrentNode] = useState<Nullable<Node>>(null);
  const [updatedCurrentNode, setUpdatedCurrentNode] =
    useState<Nullable<Node>>(null);
  const [currentEdge, setCurrentEdge] = useState<Nullable<Edge>>(null);
  const [updatedCurrentEdge, setUpdatedCurrentEdge] =
    useState<Nullable<Edge>>(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(() => {
    // Aggregate nodes
    const allNodes = [...(coreNode ? [coreNode] : []), ...nodeData];

    // Add internal functions to nodes
    const allConvertedNodes = allNodes.map((node: Node) => {
      return {
        ...node,
        data: {
          ...node.data,
          onNodeEdit: nodeEdit,
          onNodeDelete: nodeDelete,
        },
      };
    });

    // Add internal functions to edges
    const allConvertedEdges = edgeData.map((edge: Edge) => {
      return {
        ...edge,
        data: {
          ...edge.data,
          onEdgeEdit: edgeEdit,
          onEdgeDelete: edgeDelete,
        },
      };
    });

    // Calculate Position of nodes
    const allFormattedNodes = NodePositionService.calculatePositions({
      nodes: allConvertedNodes,
      edges: allConvertedEdges,
    });

    setNodes(allFormattedNodes);
    setEdges(allConvertedEdges);
  }, [coreNode, nodeData, edgeData]);

  useEffect(() => {
    creatPngOfTree(nodes);
  }, [nodes]);

  /**
   * Edit and Delete Functions to convert the Nodes and Edges
   */
  const nodeEdit = (node: Node) => {
    onNodeEdit(node);
  };

  const nodeDelete = (node: Node) => {
    setNodes(currentNodes => currentNodes.filter(n => n.id !== node.id));
  };

  const edgeEdit = (edge: Edge) => {
    onEdgeEdit(edge);
  };

  const edgeDelete = (edge: Edge) => {};

  const onConnect = useCallback((params: Connection | Edge) => {
    setEdges(edge => addEdge(params, edge));

    handleAddEdgeOnSaveOutside(params as Edge);
  }, []);

  /*const onNodeEdit = (node: Node) => {
      const selectedNode = nodes.find((nde) => nde.id === node.id) || null;

      if (node.type && forms) {
        const form = forms[node.type];
        let formTitle = "";

        if (typeof form.title === "string") {
          formTitle = form.title;
        } else if (typeof form.title === "function") {
          formTitle = form.title(node);
        }
        setFormTitle(formTitle);
      }
      setCurrentNode(selectedNode);
    };*/

  const onNodeEdit = (node: Node) => {
    // Use functional update to ensure we get the latest state
    setNodes(prevNodes => {
      const selectedNode = prevNodes.find(nde => nde.id === node.id) || null;

      if (node.type && forms) {
        const form = forms[node.type];
        let formTitle = '';

        if (typeof form.title === 'string') {
          formTitle = form.title;
        } else if (typeof form.title === 'function') {
          formTitle = form.title(node);
        }
        setFormTitle(formTitle);
      }
      setCurrentNode(selectedNode);

      return prevNodes; // Return the same state, we're only interested in setting currentNode here
    });
  };

  /**
   * Edge Functions
   */
  //...Edit-Edge....................................................
  const onEdgeDoubleClick = (event: React.MouseEvent, edge: Edge) => {
    event.stopPropagation();
    onEdgeEdit(edge);
  };

  const onEdgeEdit = (edge: Edge) => {
    setCurrentEdge(edge);
    if (edge.type && forms) {
      const form = forms[edge.type];
      let formTitle = '';

      if (typeof form.title === 'string') {
        formTitle = form.title;
      } else if (typeof form.title === 'function') {
        formTitle = form.title(edge);
      }

      setFormTitle(formTitle);
    }
  };

  /**
   * Add Functions
   */
  const onOpenAddMitarbeiterDialog = (node: Node, title: string) => {
    setFormTitle(title);
    setAddNode(node);
  };

  const [nodeId, setNodeId] = useState(6);

  /**
   * UseEffect-for-currentEdge/currentNode/addNode
   */
  useEffect(() => {
    if (currentEdge) {
      if (forms) {
        if (currentEdge.type) {
          const FormComponent = forms[currentEdge.type].form;
          if (FormComponent) {
            setCurrentForm(
              React.createElement(FormComponent, {
                currentEdge: currentEdge,
                setCurrentEdge: setUpdatedCurrentEdge,
              })
            );
          }
        }
      }
      setOpenDialog(true);
    } else if (currentNode) {
      if (forms) {
        if (currentNode.type) {
          const FormComponent = forms[currentNode.type].form;
          if (FormComponent) {
            setCurrentForm(
              React.createElement(FormComponent, {
                currentNode: currentNode,
                setCurrentNode: setUpdatedCurrentNode,
              })
            );
          }
        }
      }
      setOpenDialog(true);
    } else if (addNode) {
      if (forms) {
        if (addNode.type) {
          const FormComponent = forms[addNode.type].form;
          if (FormComponent) {
            setCurrentForm(
              React.createElement(FormComponent, {
                currentNode: addNode,
                setCurrentNode: setUpdatedAddNode,
              })
            );
          }
        }
      }
      setOpenDialog(true);
    }
  }, [currentEdge, currentNode]);

  /**
   * Save Function for Node and Edges
   */
  const onSave = () => {
    // Save Edit-Edges TODO:
    if (updatedCurrentEdge) {
      handleEditEdgeOnSaveOutside(updatedCurrentEdge);

      setEdges(edges =>
        edges.map(e =>
          e.id === updatedCurrentEdge.id ? updatedCurrentEdge : e
        )
      );
    }
    // Save Edit-Nodes
    else if (updatedCurrentNode) {
      const editNodes = nodes.map(node =>
        node.id === updatedCurrentNode.id ? updatedCurrentNode : node
      );
      setNodes(editNodes);
      handleEditNodeOnSaveOutside(updatedCurrentNode);
      /*setNodes((currentNodes) =>
              currentNodes.map((node) =>
                node.id === updatedCurrentNode.id ? updatedCurrentNode : node
              )
            );*/
    }
    // Save Add-Nodes
    else if (updatedAddNode) {
      handleAddNodeOnSaveOutside(updatedAddNode);

      setNodes(prevNodes => [
        ...NodePositionService.calculatePositions({
          nodes: [...prevNodes, { ...updatedAddNode }],
          edges: edges,
        }),
      ]);
      setNodeId(nodeId + 1);
    }
    handleCloseDialog();
  };

  /**
   * Close-Dialogs Function
   */
  const handleCloseDialog = () => {
    setOpenDialog(false);
    if (openDialog === false) setFormTitle('Form');
    setCurrentEdge(null);
    setUpdatedCurrentEdge(null);
    setCurrentNode(null);
    setUpdatedCurrentNode(null);
    setAddNode(null);
    setUpdatedAddNode(null);
    setCurrentForm(null);
  };

  //................................................................

  const nodeTypes = customNodeComponents ? customNodeComponents : {};
  const edgeTypes = customEdgeComponents ? customEdgeComponents : {};

  //................................................................

  function downloadImage(dataUrl: any) {
    const a = document.createElement('a');

    a.setAttribute('download', 'reactflow.png');
    a.setAttribute('href', dataUrl);
    a.click();
  }

  const imageWidth = 1024;
  const imageHeight = 768;

  const onClick = () => {
    const nodesBounds = getRectOfNodes(nodes);
    const transform = getTransformForBounds(
      nodesBounds,
      imageWidth,
      imageHeight,
      0.5,
      2
    );

    const viewportElement = document.querySelector(
      '.react-flow__viewport'
    ) as HTMLElement;
    if (viewportElement) {
      toPng(viewportElement, {
        backgroundColor: 'white',
        width: imageWidth,
        height: imageHeight,
        style: {
          width: `${imageWidth}px`,
          height: `${imageHeight}px`,
          transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
        },
      }).then(downloadImage);
    }
  };

  return (
    <Box width={'100%'} height={'100%'}>
      {showAddButton && (
        <PopupState variant='popover' popupId='demo-popup-menu'>
          {popupState => (
            <React.Fragment>
              <Button variant='contained' {...bindTrigger(popupState)}>
                Add...
              </Button>
              <Menu {...bindMenu(popupState)}>
                {addNodeArray.map((item: any, index: any) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      onOpenAddMitarbeiterDialog(item.node, item.title);
                      popupState.close();
                    }}
                  >
                    {item.title}
                  </MenuItem>
                ))}
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      )}
      {showDownloadButton && (
        <Button variant='contained' sx={{ float: 'right' }} onClick={onClick}>
          Download as Image
        </Button>
      )}

      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={allowOnConnect ? onConnect : undefined}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onEdgeDoubleClick={
            allowEdgeDoubleClickEdit
              ? (event, edge) => onEdgeDoubleClick(event, edge)
              : undefined
          }
          fitView
        >
          <Controls />
          <MiniMap />
          <Background gap={10} size={1} />
        </ReactFlow>
        <DialogStructure
          open={openDialog}
          title={formTitle}
          children={currentForm}
          onSave={onSave}
          onCancel={handleCloseDialog}
        />
      </ReactFlowProvider>
    </Box>
  );
};
