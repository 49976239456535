import { CircularProgress, Tooltip } from '@mui/material';

import { Box, Typography, IconButton } from '@mui/material';
import {
  ArrowBack,
  ArrowForward,
  Close,
  ZoomIn,
  ZoomOut,
} from '@mui/icons-material';
import { PdfDocument } from '../Document/PdfDocument';
import type { IDocument } from '../Interfaces/IDocument';
import { useState } from 'react';
import type { IMIMEType } from '../Interfaces/IMIMEType';

interface IProps {
  document: IDocument;
  mimeTypes: IMIMEType[];
  onClose: () => void;
}

export const SidepanelView = ({ document, mimeTypes, onClose }: IProps) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [scale, setScale] = useState(1);

  const zoomIn = () => {
    setScale(scale + 0.1);
  };

  const zoomOut = () => {
    setScale(scale - 0.1);
  };

  const documentMimeType = mimeTypes.find(
    mimeType => mimeType.idMIMEType === document.idMIMEType
  );

  if (!document.Data) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '70vh',
          border: 1,
          borderColor: 'divider',
          borderRadius: 2,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        border: 1,
        borderColor: 'divider',
        borderRadius: 2,
        minHeight: '70vh',
        maxHeight: '70vh',
      }}
    >
      <Box
        sx={{
          p: 2,
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant='body1'>{document.FileName}</Typography>
          <Tooltip title='Dokument schließen'>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Tooltip>
        </Box>

        {documentMimeType?.MIMEType === 'application/pdf' && (
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 0.5 }}>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                justifyContent: 'space-between',
                alignItems: 'center',
                border: 1,
                borderColor: 'divider',
                borderRadius: 1,
                p: 0.25,
              }}
            >
              <IconButton
                onClick={() => setPageNumber(pageNumber - 1)}
                disabled={pageNumber === 1}
                size='small'
              >
                <ArrowBack />
              </IconButton>
              <Typography variant='body2'>
                Seite {pageNumber} von {numberOfPages}
              </Typography>

              <IconButton
                onClick={() => setPageNumber(pageNumber + 1)}
                disabled={pageNumber === numberOfPages}
                size='small'
              >
                <ArrowForward />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                border: 1,
                borderColor: 'divider',
                borderRadius: 1,
                p: 0.25,
              }}
            >
              <IconButton
                onClick={zoomOut}
                disabled={scale === 0.5}
                size='small'
              >
                <ZoomOut />
              </IconButton>
              <Typography variant='body2'>
                {(scale * 100).toFixed(0)}%
              </Typography>
              <IconButton
                onClick={zoomIn}
                disabled={scale === 3.0}
                size='small'
              >
                <ZoomIn />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
      <Box>
        {documentMimeType?.MIMEType === 'application/pdf' ? (
          <Box
            sx={{
              height: '70vh',
              overflow: 'auto',
            }}
          >
            <PdfDocument
              pdfFile={`data:application/pdf;base64,${document.Data}`}
              numPages={numberOfPages}
              pageNumber={pageNumber}
              setNumPages={numPages => setNumberOfPages(numPages)}
              scale={scale}
            />
          </Box>
        ) : (
          <Box
            component='img'
            src={`data:${documentMimeType?.MIMEType};base64,${document.Data}`}
            alt={document.FileName}
            sx={{
              width: '100%',
              height: 'auto',
              borderRadius: 1,
              boxShadow: 2,
            }}
          />
        )}
      </Box>
    </Box>
  );
};
