import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { MapSearch } from './MapSearch';
import { IRealEstate } from '../../../../Interfaces/IRealEstate';
import { IPostcode } from '../../../../Interfaces/IPostcode';
import { PostcodeSearch } from '../../../../generic/PostcodeSearch';

interface IProps {
  currentObject: IRealEstate;
  setCurrentObject: Function;
  marginTop?: number;
}

export const RealEstateAddress: React.FC<IProps> = props => {
  const handlePostcode = (localObject: IRealEstate) => {
    if (
      localObject.idPostcode != null &&
      localObject.idPostcode > 0 &&
      localObject.City != undefined &&
      localObject.Postcode != undefined
    ) {
      return {
        idPostcode: localObject.idPostcode,
        City: localObject.City,
        Postcode: localObject.Postcode,
        idCountry: 1,
      } as IPostcode;
    } else {
      return null;
    }
  };

  const setPostcodeObjectWrapper = (
    postcodeObject: IPostcode | null | undefined
  ) => {
    if (postcodeObject != undefined) {
      props.setCurrentObject({
        ...props.currentObject,
        idPostcode: postcodeObject.idPostcode,
        Postcode: postcodeObject.Postcode,
        City: postcodeObject.City,
      } as IRealEstate);
    }
    setPostcodeObject(postcodeObject);
  };

  const [postcodeObject, setPostcodeObject] = useState<
    IPostcode | null | undefined
  >(handlePostcode(props.currentObject));

  return (
    <>
      <Typography
        variant='h5'
        sx={{ mt: props.marginTop === undefined ? 2 : props.marginTop, mb: 1 }}
      >
        Anschrift des Objektes
        <br />
        <Typography>
          Sie müssen entweder die Adresse oder Längen- und Breitengrade angeben.
          Auch beide Informationen können angebeben werden.
        </Typography>
      </Typography>
      <Grid container spacing={2}>
        <Grid item sm={5}>
          <TextField
            label='Straße'
            size='small'
            required={
              props.currentObject.Latitude === null &&
              props.currentObject.Longitude === null
            }
            fullWidth
            value={
              props.currentObject.Street === null
                ? ''
                : props.currentObject.Street
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                Street: event.target.value === '' ? null : event.target.value,
              })
            }
          />
        </Grid>
        <Grid item sm={5}>
          <TextField
            label='Adresszusatz'
            size='small'
            fullWidth
            value={
              props.currentObject.StreetAditional === null
                ? ''
                : props.currentObject.StreetAditional
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                StreetAditional:
                  event.target.value === '' ? null : event.target.value,
              })
            }
          />
        </Grid>
        <Grid item sm={2}>
          <TextField
            label='Hausnummer'
            size='small'
            required={
              props.currentObject.Latitude === null &&
              props.currentObject.Longitude === null
            }
            fullWidth
            value={
              props.currentObject.StreetNr === null
                ? ''
                : props.currentObject.StreetNr
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                StreetNr: event.target.value === '' ? null : event.target.value,
              })
            }
          />
        </Grid>
        <Grid item sm={12}>
          <PostcodeSearch
            postcodeObject={postcodeObject}
            setPostcodeObject={setPostcodeObjectWrapper}
            required={
              props.currentObject.Latitude === null &&
              props.currentObject.Longitude === null
            }
          />
        </Grid>

        <Grid item sm={12}>
          <Box sx={{ mt: 1 }} />
        </Grid>

        <Grid item sm={6}>
          <TextField
            label='Breitengrad'
            size='small'
            required={props.currentObject.Longitude !== null}
            fullWidth
            value={
              props.currentObject.Latitude === null
                ? ''
                : props.currentObject.Latitude
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                Latitude:
                  event.target.value === '' ? null : Number(event.target.value),
              })
            }
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            label='Längengrad'
            size='small'
            required={props.currentObject.Latitude !== null}
            fullWidth
            value={
              props.currentObject.Longitude === null
                ? ''
                : props.currentObject.Longitude
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.setCurrentObject({
                ...props.currentObject,
                Longitude:
                  event.target.value === '' ? null : Number(event.target.value),
              })
            }
          />
        </Grid>

        <Grid item sm={12}>
          <Box sx={{ mt: 1 }} />
        </Grid>

        <Grid item sm={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<GridExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography>Adresse / Koordinaten suchen</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <MapSearch
                realEstate={props.currentObject}
                setRealEstate={props.setCurrentObject}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};
