import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { IRealEstateHasInventoryOption } from '../../../../Interfaces/IRealEstateHasInventoryOption';
import { IInventoryOption } from '../../../../Interfaces/IInventoryOption';
import {
  RealEstateInventoryOptionEdit,
  searchRealEstateInventoryOption,
} from './RealEstateInventoryOptionEdit';

interface IProps {
  idRealEstate: number;
  realEstateHasInventroyOptionArray: IRealEstateHasInventoryOption[];
  setRealEstateHasInventroyOptionArray: Function;
  inventoryOptionArray: IInventoryOption[];
  marginTop?: number;
}

export const AddRealEstateInventoryOption: React.FC<IProps> = props => {
  const [newObject, setNewObject] = useState(
    searchRealEstateInventoryOption(
      -1,
      props.idRealEstate,
      props.realEstateHasInventroyOptionArray,
      true
    )
  );
  //
  const [isOpenNew, setIsOpenNew] = useState(false);

  const handleOpen = () => {
    setNewObject(
      searchRealEstateInventoryOption(
        -1,
        props.idRealEstate,
        props.realEstateHasInventroyOptionArray,
        true
      )
    );
    setIsOpenNew(true);
  };

  const handleAdd = () => {
    props.setRealEstateHasInventroyOptionArray([
      ...props.realEstateHasInventroyOptionArray,
      newObject,
    ]);
    setIsOpenNew(false);
  };

  return (
    <>
      <Dialog
        open={isOpenNew}
        onClose={() => setIsOpenNew(false)}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>Anlage hinzufügen</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }} />
          <RealEstateInventoryOptionEdit
            currentObject={newObject}
            setCurrentObject={setNewObject}
            inventoryOptionArray={props.inventoryOptionArray}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setIsOpenNew(false)}>
            Abbruch
          </Button>
          <Button
            variant='contained'
            disabled={newObject.idInventoryOption < 0}
            onClick={handleAdd}
          >
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>

      <Button variant='contained' sx={{ float: 'right' }} onClick={handleOpen}>
        Hinzufügen
      </Button>
    </>
  );
};
