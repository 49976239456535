import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { SelectShareHolderEdit } from '../SelectShareHolderEdit';
import { ShareHolderEdit } from '../ShareHolder/ShareHolderEdit';
import { IShareHolderFullObject } from '../../../../Interfaces/IShareHolder';
import { ICompanyFormationHasShareHolder } from '../../../../Interfaces/ICompanyFormationHasShareHolder';
import { ShareHolderRepresentationWarning } from '../ShareHolder/ShareHolderRepresentationWarning';
import { ICompanyFormation } from '../../../../Interfaces/ICompanyFormation';

interface IProps {
  companyFormationObject: ICompanyFormation;
  shareHolderArray: IShareHolderFullObject[];
  setShareHolderArray: Function;
  shareHolderRelation: ICompanyFormationHasShareHolder[];
  setShareHolderRelation: Function;
}

export const Representation: React.FC<IProps> = props => {
  const [idShareHolder, setIdShareHolder] = useState<number | null>(null);

  return (
    <>
      <Dialog
        open={idShareHolder !== null}
        onClose={() => setIdShareHolder(null)}
        maxWidth='md'
        fullWidth
      >
        <DialogTitle>
          {idShareHolder === null || idShareHolder === 0 ? (
            <>Neue Person</>
          ) : (
            <>Person bearbeiten</>
          )}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            {idShareHolder !== null && (
              <ShareHolderEdit
                idShareHolder={idShareHolder}
                handleClose={() => setIdShareHolder(null)}
                shareHolderArray={props.shareHolderArray}
                setShareHolderArray={props.setShareHolderArray}
              />
            )}
          </Box>
        </DialogContent>
      </Dialog>

      <Box sx={{ mt: 5 }} />
      <Typography variant='h4' sx={{ mb: 3 }}>
        Vertretung
      </Typography>
      <Button
        variant='contained'
        sx={{ float: 'right', mt: 3 }}
        onClick={() => {
          setIdShareHolder(0);
        }}
      >
        Person zur Auswahl hinzufügen
      </Button>

      <ShareHolderRepresentationWarning
        shareHolderArray={props.shareHolderArray}
        shareHolderRelationArray={props.shareHolderRelation}
      />

      <Box sx={{ mt: 10 }} />
      <Typography variant='h6' sx={{ mb: 1 }}>
        Geschäftsführer
      </Typography>
      <SelectShareHolderEdit
        idShareHolderType={2}
        shareHolderArray={props.shareHolderArray}
        setShareHolderArray={props.setShareHolderArray}
        shareHolderRelation={props.shareHolderRelation}
        setShareHolderRelation={props.setShareHolderRelation}
        disableAddNewButton
      />

      <Box sx={{ mt: 10 }} />
      {props.companyFormationObject.idBusinessForm === 3 ? (
        <>
          <Typography variant='h6' sx={{ mb: 1 }}>
            GbR: Konkrete Vertretung
          </Typography>
          <SelectShareHolderEdit
            idShareHolderType={4}
            shareHolderArray={props.shareHolderArray}
            setShareHolderArray={props.setShareHolderArray}
            shareHolderRelation={props.shareHolderRelation}
            setShareHolderRelation={props.setShareHolderRelation}
            disableAddNewButton
          />
        </>
      ) : (
        <>
          <Typography variant='h6' sx={{ mb: 1 }}>
            Prokuristen
          </Typography>
          <SelectShareHolderEdit
            idShareHolderType={3}
            shareHolderArray={props.shareHolderArray}
            setShareHolderArray={props.setShareHolderArray}
            shareHolderRelation={props.shareHolderRelation}
            setShareHolderRelation={props.setShareHolderRelation}
            disableAddNewButton
          />
        </>
      )}
    </>
  );
};
