import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { XMLParser } from 'fast-xml-parser';
import { useEffect, useRef, useState } from 'react';
import rollenbezeichnung from '../jsons/rollenbezeichnung.json';
import geschlechtJson from '../jsons/geschlecht.json';
import countryJson from '../jsons/country.json';

interface IProps {
  setOpenPersonDialog: Function;
  setAlleBeteiligungen: Function;
  alleBeteiligungen: any;
}

export const getNewPerson = (alleBeteiligungen: any) => {
  /**
   * XML Parser
   */
  // parser Options
  const parserOptions = {
    ignoreAttributes: false,
    parseTagValue: false,
  };

  // Erstelle eine Instanz von XMLParser
  const parser = new XMLParser(parserOptions);
  const xmlContent = `
  <tns:rolle>
      <tns:rollennummer>""</tns:rollennummer>
      <tns:rollenID>
          <tns:id></tns:id>
          <tns:ref.instanznummer>0</tns:ref.instanznummer>
      </tns:rollenID>
      <tns:rollenbezeichnung listVersionID="3.3" listURI="urn:xoev-de:xjustiz:codeliste:gds.rollenbezeichnung">
          <code></code>
      </tns:rollenbezeichnung>
  </tns:rolle>
  <tns:beteiligter>
      <tns:beteiligtennummer></tns:beteiligtennummer>
      <tns:auswahl_beteiligter>
          <tns:natuerlichePerson>
              <tns:vollerName>
                  <tns:vorname></tns:vorname>
                  <tns:nachname></tns:nachname>
              </tns:vollerName>
              <tns:geburt>
                  <tns:geburtsdatum></tns:geburtsdatum>
              </tns:geburt>
              <tns:geschlecht listVersionID="2.1" listURI="urn:xoev-de:xjustiz:codeliste:gds.geschlecht">
                  <code></code>
              </tns:geschlecht>
              <tns:anschrift>
                  <tns:anschriftstyp listVersionID="3.0" listURI="urn:xoev-de:xjustiz:codeliste:gds.anschriftstyp">
                      <code>003</code>
                  </tns:anschriftstyp>
                  <tns:strasse></tns:strasse>
                  <tns:hausnummer></tns:hausnummer>
                  <tns:postleitzahl></tns:postleitzahl>
                  <tns:ort></tns:ort>
                  <tns:staat>
                      <tns:auswahl_staat>
                          <tns:staat listVersionID="6" listURI="urn:xoev-de:kosit:codeliste:country-codes">
                              <code></code>
                          </tns:staat>
                      </tns:auswahl_staat>
                  </tns:staat>
              </tns:anschrift>
          </tns:natuerlichePerson>
      </tns:auswahl_beteiligter>
  </tns:beteiligter>
 `;

  const parsedXmlContent = parser.parse(xmlContent);
  const beteiligtennummer = (
    parseInt(
      alleBeteiligungen[0][alleBeteiligungen[0].length - 1]?.[
        'tns:beteiligter'
      ]['tns:beteiligtennummer']
    ) + 1 || 0
  ).toString();

  const newRollennummer = (
    parseInt(
      alleBeteiligungen[0][alleBeteiligungen[0].length - 1]?.['tns:rolle'][
        'tns:rollennummer'
      ]
    ) + 1 || 0
  ).toString();
  //
  const resetPerson = (newPerson: any) => {
    newPerson['tns:rolle']['tns:rollennummer'] = newRollennummer;
    //
    newPerson['tns:beteiligter']['tns:beteiligtennummer'] = beteiligtennummer;
    return newPerson;
  };
  return resetPerson(parsedXmlContent);
};

export const PersonAddDialog: React.FC<IProps> = props => {
  /**
   * All useStates
   */
  const formRef = useRef<HTMLFormElement>(null);
  const [newPerson, setNewPerson] = useState(
    getNewPerson(props.alleBeteiligungen)
  );
  const [vorname, setVorname] = useState('');
  const [nachname, setNachname] = useState('');
  const [bezeichnung, setBezeichnung] = useState('');
  const [geschlecht, setGeschlecht] = useState('');
  const [geburtstag, setGeburtstag] = useState('');
  const [strasse, setStrasse] = useState('');
  const [hausnummer, setHausnummer] = useState('');
  const [postleitzahl, setPostleitzahl] = useState('');
  const [ort, setOrt] = useState('');
  const [staat, setStaat] = useState('');
  //
  // Funktion zum Umwandeln des Arrays in ein Objekt
  const convertToObject = (
    datenArray: (string | null)[][]
  ): Record<string, string> => {
    const result: Record<string, string> = {};
    datenArray.forEach(([code, name]) => {
      if (code && name) {
        result[code] = name;
      }
    });
    return result;
  };

  const convertToObject2 = (
    datenArray: (string | null)[][]
  ): Record<string, string> => {
    const result: Record<string, string> = {};
    datenArray.forEach(([code, nr, name]) => {
      if (code && name) {
        result[code] = name;
      }
    });
    return result;
  };

  // Usage without needing to filter beforehand
  const rollenbezeichnungObjekt = convertToObject(rollenbezeichnung.daten);
  const geschlechtObjekt = convertToObject(geschlechtJson.daten);
  const countryObjekt = convertToObject2(countryJson.daten);

  //
  const rollenbezeichnungMap = new Map(
    rollenbezeichnung.daten.map(([code, wert]) => [code, wert])
  );
  const geschlechtMap = new Map(
    geschlechtJson.daten.map(([code, wert]) => [code, wert])
  );
  const countryMap = new Map(
    countryJson.daten.map(([code, nr, wert]) => [code, wert])
  );
  //
  const valueToKeyRollenbezeichnungMap = new Map(
    Array.from(rollenbezeichnungMap.entries()).map(([key, value]) => [
      value,
      key,
    ])
  );
  const valueToKeyGeschlechtMap = new Map(
    Array.from(geschlechtMap.entries()).map(([key, value]) => [value, key])
  );
  const valueToKeyCountryMap = new Map(
    Array.from(countryMap.entries()).map(([key, value]) => [value, key])
  );
  /**
   * All Functions
   */
  const handleSave = (newPerson2: any) => {
    const updatedAlleBeteiligten = props.alleBeteiligungen[0];
    const updated = [...updatedAlleBeteiligten, newPerson2];
    props.setAlleBeteiligungen([
      ...props.alleBeteiligungen.map((x: any, id: any) =>
        id === 0 ? updated : x
      ),
    ]);
    props.setOpenPersonDialog(false);
  };
  /**
   * Form Function
   */
  const handleOnSave = () => {
    const personToSave = deepCopy(newPerson); // Tiefe Kopie erstellen
    if (formRef.current && formRef.current.checkValidity()) {
      handleSave(personToSave);
    } else if (formRef.current && !formRef.current.checkValidity()) {
      const elements = Array.from(formRef.current.elements);
      elements.forEach((element: any) => {
        if (element.reportValidity()) {
          element.reportValidity();
        }
      });
    }
  };

  //

  //

  // Funktion zum Erstellen einer tiefen Kopie des Objekts
  const deepCopy = (obj: any) => {
    return JSON.parse(JSON.stringify(obj));
  };

  // In `useEffect` eine tiefe Kopie von `newPerson` erstellen und dann das Objekt aktualisieren.
  useEffect(() => {
    const updatedPerson = deepCopy(newPerson); // Tiefe Kopie des Objekts
    updatedPerson['tns:beteiligter']['tns:auswahl_beteiligter'][
      'tns:natuerlichePerson'
    ]['tns:vollerName']['tns:vorname'] = vorname;

    updatedPerson['tns:beteiligter']['tns:auswahl_beteiligter'][
      'tns:natuerlichePerson'
    ]['tns:vollerName']['tns:nachname'] = nachname;

    updatedPerson['tns:beteiligter']['tns:auswahl_beteiligter'][
      'tns:natuerlichePerson'
    ]['tns:geschlecht']['code'] = geschlecht;

    updatedPerson['tns:beteiligter']['tns:auswahl_beteiligter'][
      'tns:natuerlichePerson'
    ]['tns:geburt']['tns:geburtsdatum'] = geburtstag;

    updatedPerson['tns:beteiligter']['tns:auswahl_beteiligter'][
      'tns:natuerlichePerson'
    ]['tns:anschrift']['tns:ort'] = ort;
    updatedPerson['tns:beteiligter']['tns:auswahl_beteiligter'][
      'tns:natuerlichePerson'
    ]['tns:anschrift']['tns:staat']['tns:auswahl_staat']['tns:staat']['code'] =
      staat;

    updatedPerson['tns:beteiligter']['tns:auswahl_beteiligter'][
      'tns:natuerlichePerson'
    ]['tns:anschrift']['tns:strasse'] = strasse;

    updatedPerson['tns:beteiligter']['tns:auswahl_beteiligter'][
      'tns:natuerlichePerson'
    ]['tns:anschrift']['tns:hausnummer'] = hausnummer;

    updatedPerson['tns:beteiligter']['tns:auswahl_beteiligter'][
      'tns:natuerlichePerson'
    ]['tns:anschrift']['tns:postleitzahl'] = postleitzahl;

    updatedPerson['tns:rolle']['tns:rollenbezeichnung']['code'] = bezeichnung;

    setNewPerson(updatedPerson); // Die aktualisierte Kopie speichern
  }, [vorname, nachname, bezeichnung, geschlecht, geburtstag]);

  //

  //

  return (
    <>
      <Dialog open={true} onClose={() => {}} maxWidth={'lg'} fullWidth>
        <DialogContent>
          <form ref={formRef}>
            <Grid container spacing={2} mt={2}>
              <Grid item sm={12}>
                <Typography variant='h5'>Personendaten</Typography>
              </Grid>
              <Grid item sm={4}>
                <Autocomplete
                  options={Object.values(geschlechtObjekt)} // Array von Strings für die Optionen
                  getOptionLabel={option => option}
                  value={geschlechtMap.get(geschlecht)}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setGeschlecht(
                        valueToKeyGeschlechtMap.get(newValue) || ''
                      );
                    } else {
                      setGeschlecht(''); // Optional: handle the case where no value is selected
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label='Geschlecht'
                      variant='outlined'
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  label='Vorname'
                  value={vorname}
                  onChange={e => {
                    setVorname(e.target.value);
                  }}
                  fullWidth
                  required
                  variant='outlined'
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  label='Nachname'
                  value={nachname}
                  onChange={e => {
                    setNachname(e.target.value);
                  }}
                  fullWidth
                  required
                  variant='outlined'
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  label='Geburtstag'
                  type='date'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={geburtstag}
                  onChange={e => {
                    setGeburtstag(e.target.value);
                  }}
                  variant='outlined'
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  label='Straße'
                  value={strasse}
                  onChange={e => {
                    setStrasse(e.target.value);
                  }}
                  fullWidth
                  variant='outlined'
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  label='Hausnummer'
                  value={hausnummer}
                  onChange={e => {
                    setHausnummer(e.target.value);
                  }}
                  fullWidth
                  variant='outlined'
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  label='Postleitzahl'
                  value={postleitzahl}
                  onChange={e => {
                    setPostleitzahl(e.target.value);
                  }}
                  fullWidth
                  variant='outlined'
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  label='Ort'
                  value={ort}
                  onChange={e => {
                    setOrt(e.target.value);
                  }}
                  fullWidth
                  variant='outlined'
                />
              </Grid>
              <Grid item sm={6}>
                <Autocomplete
                  options={Object.values(countryObjekt)} // Array von Strings für die Optionen
                  getOptionLabel={option => option}
                  value={countryMap.get(staat)}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setStaat(valueToKeyCountryMap.get(newValue) || '');
                    } else {
                      setStaat(''); // Optional: handle the case where no value is selected
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Staat'
                      variant='outlined'
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item sm={12}>
                <Autocomplete
                  options={Object.values(rollenbezeichnungObjekt)} // Array von Strings für die Optionen
                  getOptionLabel={option => option}
                  value={rollenbezeichnungMap.get(bezeichnung)}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setBezeichnung(
                        valueToKeyRollenbezeichnungMap.get(newValue) || ''
                      );
                    } else {
                      setBezeichnung(''); // Optional: handle the case where no value is selected
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      required
                      label='Bezeichnung'
                      variant='outlined'
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={() => props.setOpenPersonDialog(false)}
          >
            Abbruch
          </Button>

          <Button variant='contained' onClick={handleOnSave}>
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
