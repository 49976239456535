import React, { useState, type SetStateAction, type Dispatch } from 'react';
import { IDocument } from '../Interfaces/IDocument';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import {
  Delete,
  Download,
  FindInPage,
  Search,
  VerticalSplit,
} from '@mui/icons-material';
import { getFetch } from '../hooks/useFetch';
import { IDocumentDownloaded } from '../Interfaces/IDocumentDownloaded';
import { DocumentDownloaded } from './DocumentDownloaded';
import { IMIMEType } from '../Interfaces/IMIMEType';
import { useSidepanelDocumentContext } from '../LegalTransaction/LegalTransactionEdit';

interface IProps {
  documentObject: IDocument;
  documentArray: IDocument[];
  setDocumentArray: Dispatch<SetStateAction<IDocument[]>>;
  mimeTypeArray: IMIMEType[];
  allowRemove: boolean;
  downloadedArray?: IDocumentDownloaded[];
  justButtons?: boolean;
  enableSidepanel?: boolean;
}

const base64Download = (
  localDocumentObject: IDocument,
  mimeTypes: IMIMEType[]
) => {
  const currentMimeType = mimeTypes.find(
    x => x.idMIMEType === localDocumentObject.idMIMEType
  );

  if (localDocumentObject.Data && currentMimeType) {
    const byteCharacters = atob(localDocumentObject.Data);
    // Each character's code point (charCode) will be the value of the byte.
    // We can create an array of byte values by applying this using the .charCodeAt method for each character in the string.
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    //You can convert this array of byte values into a real typed byte array by passing it to the Uint8Array constructor.
    const byteArray = new Uint8Array(byteNumbers);
    // This in turn can be converted to a BLOB by wrapping it in an array and passing it to the Blob constructor.
    const blob = new Blob([byteArray], { type: currentMimeType.MIMEType });
    const blobUrl = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = localDocumentObject.FileName;
    a.click();
  }
};

interface IPropsPreview {
  open: boolean;
  localDocumentObject: IDocument;
  onClose: () => void;
}

interface IPropsDownloaded {
  open: boolean;
  onClose: () => void;
  localDocumentObject: IDocument;
}

export const DocumentPreviewDialog: React.FC<IPropsPreview> = ({
  open,
  localDocumentObject,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogContent>
        {!localDocumentObject.Data ? (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            height='50vh'
          >
            <CircularProgress />
          </Box>
        ) : (
          <iframe
            name={localDocumentObject.FileName}
            src={`data:application/pdf;headers=filename%3D${localDocumentObject.FileName};base64,${localDocumentObject.Data}`}
            width={850}
            height={600}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={onClose}>
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const DocumentDownloadedDialog: React.FC<IPropsDownloaded> = ({
  open,
  onClose,
  localDocumentObject,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogContent>
        <DocumentDownloaded documentObject={localDocumentObject} />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={onClose}>
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const DocumentRow: React.FC<IProps> = ({
  documentObject,
  documentArray,
  setDocumentArray,
  mimeTypeArray,
  allowRemove,
  downloadedArray,
  justButtons,
  enableSidepanel = false,
}) => {
  const { setSelectedSidepanelDocument } = useSidepanelDocumentContext();

  const [localDocumentObject, setLocalDocumentObject] =
    useState(documentObject);
  const [isOpenDownloaded, setIsOpenDownloaded] = useState(false);
  const [isOpenPreview, setisOpenPreview] = useState(false);

  const handleDownload = async () => {
    if (localDocumentObject.Data) {
      base64Download(localDocumentObject, mimeTypeArray);
    } else {
      getFetch(
        '/document/',
        localDocumentObject.idDocument,
        (localDocumentObject: IDocument) => {
          setLocalDocumentObject(localDocumentObject);
          base64Download(localDocumentObject, mimeTypeArray);
        }
      );
    }
  };

  const handleOpenPreview = () => {
    if (!localDocumentObject.Data) {
      getFetch(
        '/document/',
        localDocumentObject.idDocument,
        setLocalDocumentObject
      );
    }
    setisOpenPreview(true);
  };

  const handleRemove = () => {
    setDocumentArray([
      ...documentArray.filter(
        document => document.idDocument !== localDocumentObject.idDocument
      ),
    ]);
  };

  if (justButtons) {
    return (
      <>
        <DocumentDownloadedDialog
          open={isOpenDownloaded}
          onClose={() => setIsOpenDownloaded(false)}
          localDocumentObject={localDocumentObject}
        />
        <DocumentPreviewDialog
          open={isOpenPreview}
          localDocumentObject={localDocumentObject}
          onClose={() => setisOpenPreview(false)}
        />
        <IconButton title='Vorschau' onClick={handleOpenPreview}>
          <FindInPage />
        </IconButton>
        {localDocumentObject.idDocument > 0 && (
          <IconButton
            title='Anzahl Downloads'
            onClick={() => setIsOpenDownloaded(true)}
          >
            <Search />
          </IconButton>
        )}
        <IconButton title='Datei herunterladen' onClick={handleDownload}>
          <Download />
        </IconButton>
        {allowRemove && (
          <IconButton title='Datei löschen' onClick={handleRemove}>
            <Delete />
          </IconButton>
        )}
      </>
    );
  } else {
    return (
      <>
        <DocumentDownloadedDialog
          open={isOpenDownloaded}
          onClose={() => setIsOpenDownloaded(false)}
          localDocumentObject={localDocumentObject}
        />
        <DocumentPreviewDialog
          open={isOpenPreview}
          localDocumentObject={localDocumentObject}
          onClose={() => setisOpenPreview(false)}
        />
        <TableRow>
          <TableCell>
            {localDocumentObject.idDocument < 0 ? (
              <>Neu</>
            ) : (
              localDocumentObject.idDocument
            )}
          </TableCell>
          {downloadedArray && (
            <TableCell>
              {
                downloadedArray.filter(
                  document =>
                    document.idDocument === localDocumentObject.idDocument
                ).length
              }
            </TableCell>
          )}
          <TableCell>{localDocumentObject.FileName}</TableCell>
          <TableCell>
            {enableSidepanel && (
              <Tooltip title='Seitenansicht öffnen'>
                <IconButton
                  onClick={async () => {
                    if (localDocumentObject.Data) {
                      setSelectedSidepanelDocument(localDocumentObject);
                    } else {
                      setSelectedSidepanelDocument(localDocumentObject);

                      getFetch(
                        '/document/',
                        localDocumentObject.idDocument,
                        (localDocumentObject: IDocument) => {
                          setLocalDocumentObject(localDocumentObject);
                          setSelectedSidepanelDocument(localDocumentObject);
                        }
                      );
                    }
                  }}
                >
                  <VerticalSplit />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title='Vorschau'>
              <IconButton onClick={handleOpenPreview}>
                <FindInPage />
              </IconButton>
            </Tooltip>
            {localDocumentObject.idDocument > 0 && (
              <Tooltip title='Anzahl Downloads'>
                <IconButton onClick={() => setIsOpenDownloaded(true)}>
                  <Search />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title='Datei herunterladen'>
              <IconButton onClick={handleDownload}>
                <Download />
              </IconButton>
            </Tooltip>

            {(allowRemove || localDocumentObject.idDocument < 0) && (
              <Tooltip title='Datei löschen'>
                <IconButton onClick={handleRemove}>
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
          </TableCell>
        </TableRow>
      </>
    );
  }
};
