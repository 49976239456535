import { Button } from '@mui/material';
import React from 'react';
import { IDocument } from '../../Interfaces/IDocument';
import { ICompanyFormationFullObject } from '../../Interfaces/ICompanyFormation';

interface IProps {
  idCompanyFormation?: number;
  companyFormationFullObject?: ICompanyFormationFullObject;
}

export const ExportCompanyFormation: React.FC<IProps> = props => {
  const castBlobToFile = (documentObject: IDocument | undefined) => {
    if (documentObject != undefined && documentObject.Data != undefined) {
      const currentType = 'application/xml';
      // Link -> https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
      // decode a Base64-encoded string into a new string with a character for each byte of the binary data.
      const byteCharacters = atob(documentObject?.Data);
      // Each character's code point (charCode) will be the value of the byte.
      // We can create an array of byte values by applying this using the .charCodeAt method for each character in the string.
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      //You can convert this array of byte values into a real typed byte array by passing it to the Uint8Array constructor.
      const byteArray = new Uint8Array(byteNumbers);
      // This in turn can be converted to a BLOB by wrapping it in an array and passing it to the Blob constructor.
      const blob = new Blob([byteArray], { type: currentType });
      // Erstelle Link zum BLOB
      const blobUrl = URL.createObjectURL(blob);
      // Erstelle html-Objekt für den Download
      const a = document.createElement('a');
      // setze link auf den neu erstellen Link zum Blolb
      a.href = blobUrl;
      // Setze Downloadtitle für den Datenname
      a.download = documentObject.FileName;
      // Click
      a.click();
    }
  };

  async function getExport() {
    if (props.idCompanyFormation != undefined) {
      fetch(`/api/companyformation/export/${props.idCompanyFormation}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('SessionToken')}`,
        },
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else {
            throw Error(`ERROR GET: ${res.status}`);
          }
        })
        .then(res => {
          castBlobToFile(res);
        })
        .catch(error => {
          console.error(error);
        });
    }
    if (props.companyFormationFullObject != undefined) {
      fetch(`/api/companyformation/exportXML`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('SessionToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(props.companyFormationFullObject),
      })
        .then(res => {
          if (res.status === 200) {
            return res.json();
          } else {
            throw Error(`ERROR GET: ${res.status}`);
          }
        })
        .then(res => {
          castBlobToFile(res);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  return (
    <>
      <Button
        variant='outlined'
        onClick={() => {
          getExport();
        }}
      >
        Export (ArnoTop)
      </Button>
    </>
  );
};
